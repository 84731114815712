//REACT
import React, { useContext, useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'

//PACKAGES
import { Button } from 'primereact/button'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

//COMPONENTS
import MainNavBar from '../components/MainNavBar'
import { validateUsername } from '../../utility-functions/validateUsername'
import { validatePassword } from '../../utility-functions/validatePassword'
import PasswordInput from '../PasswordInput'
import '../../scss/pages/SignUp.scss'
import { Link, NavLink, useParams } from 'react-router-dom'
import CodeVerification from './CodeVerification'
import PasswordMessage from '../PasswordMessage'
import AsaLoginButton from '../AsaLoginButton'
import ReCAPTCHA from 'react-google-recaptcha'

import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Toast } from 'primereact/toast'

export default function SignUp(props) {
  const { t } = props
  const { general, navbar } = useSelector((state) => state)
  const defaultEmail = navbar.username
  const { isSmallScreen } = general
  const { promoCode } = useParams()

  const recaptchaRef = useRef()

  //SIGN UP
  const [email, setEmail] = useState(defaultEmail ? defaultEmail : '')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [agree, setAgree] = useState(false)

  //UTILS
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState('signup') //signup -> codeverify
  const [showPasswordErrors, setShowPasswordErrors] = useState(false)
  const [captchaCheck, setCaptchaCheck] = useState(false)
  // useEffect(() => {
  //   resetMessages()
  // }, [page])

  // const [userForm, setUserForm] = useState({})
  // const toastErrorMsg = useRef()

  // const monthNavigatorTemplate = (e) => {
  //   return (
  //     <Dropdown
  //       value={e.value}
  //       options={e.options}
  //       onChange={(event) => e.onChange(event.originalEvent, event.value)}
  //       style={{ lineHeight: 1 }}
  //     />
  //   )
  // }

  // // year template for the prime react ui calander
  // const yearNavigatorTemplate = (e) => {
  //   return (
  //     <Dropdown
  //       value={e.value}
  //       options={e.options}
  //       onChange={(event) => e.onChange(event.originalEvent, event.value)}
  //       className='p-ml-2'
  //       style={{ lineHeight: 1 }}
  //     />
  //   )
  // }

  // const handleBirthdayInputChange = (name, value) => {
  //   setUserForm((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }))
  // }

  // const parseBirthday = (birthday) => {
  //   const date = new Date(birthday)
  //   handleBirthdayInputChange('birthday', birthday)
  //   handleBirthdayInputChange('birthdayDay', date.getDate())
  //   handleBirthdayInputChange('birthdayMonth', date.getMonth() + 1)
  //   handleBirthdayInputChange('birthdayYear', date.getFullYear())
  // }

  // const handleUserFormInputChange = (event) => {
  //   let { name, value } = event.target

  //   setUserForm((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }))
  // }

  // // check if the user is at least 18 years old before create the account
  // const isAtLeast18YearsOld = (year, month, day) => {
  //   const currentDate = new Date()
  //   const inputDate = new Date(year, month - 1, day) // JavaScript months are 0-based (0 = January, 1 = February, etc.)

  //   const ageDifferenceInMilliseconds = currentDate - inputDate
  //   const ageInYears =
  //     ageDifferenceInMilliseconds / (365.25 * 24 * 60 * 60 * 1000)

  //   return ageInYears >= 18
  // }

  // const validateUserForm = () => {
  //   const { first_name, last_name, birthdayDay, birthdayMonth, birthdayYear } =
  //     userForm

  //   // step 1 form validateion for empty fields

  //   return true
  // }

  const validateForm = () => {
    // const { first_name, last_name, birthdayDay, birthdayMonth, birthdayYear } =
    //   userForm

    // if (
    //   !first_name ||
    //   first_name?.trim() === '' ||
    //   !last_name ||
    //   last_name?.trim() === '' ||
    //   !birthdayDay ||
    //   !birthdayMonth ||
    //   !birthdayYear ||
    //   !phone ||
    //   !agree
    // ) {
    //   toastErrorMsg.current.show({
    //     severity: 'error',
    //     summary: 'Incompleted',
    //     detail: 'Please make sure all required fields are filled',
    //     life: 3000,
    //   })
    //   return false
    // }

    // // not age 18 or older
    // if (!isAtLeast18YearsOld(birthdayYear, birthdayMonth, birthdayDay)) {
    //   toastErrorMsg.current.show({
    //     severity: 'error',
    //     summary: 'Birthday Invalid',
    //     detail: 'You need to be at least 18 years old',
    //     life: 3000,
    //   })
    //   return false
    // }

    // if (!validateUsername(email)) {
    //   toastErrorMsg.current.show({
    //     severity: 'error',
    //     summary: 'Email Invalid',
    //     detail: 'Please enter a valid email address',
    //     life: 3000,
    //   })
    //   return false
    // }

    // if (validatePassword(password)) {
    //   setPage('codeverify')
    //   resetMessages()
    // } else {
    //   // setErrorMsg(t('passwordmustbegreaterthan8characters'))
    //   setShowPasswordErrors(true)
    //   toastErrorMsg.current.show({
    //     severity: 'error',
    //     summary: 'Password Invalid',
    //     detail: 'Please enter a valid password',
    //     life: 3000,
    //   })
    //   return false
    //   //setErrorMsg(t('pleaseentervalidpassword'))
    // }

    if (validateUsername(email)) {
      if (phone) {
        if (validatePassword(password)) {
          if (agree) {
            setPage('codeverify')
            resetMessages()
          } else {
            setErrorMsg(t('pleaseagreetoprivacypolicyandeula'))
          }
        } else {
          // setErrorMsg(t('passwordmustbegreaterthan8characters'))
          setShowPasswordErrors(true)
          //setErrorMsg(t('pleaseentervalidpassword'))
        }
      } else {
        setErrorMsg(t('pleaseenterphonenumber'))
      }
    } else {
      setErrorMsg(t('pleaseentervalidemail'))
    }
  }

  const resetMessages = () => {
    setErrorMsg('')
    setSuccessMsg('')
  }

  const goBack = (msg) => {
    setPage('signup')
    setErrorMsg(msg)
  }

  const onChange = (e) => {
    console.log(e)
    console.log(recaptchaRef.value)
    setCaptchaCheck(true)
  }

  const showSignUpForm = () => {
    return (
      <>
        {/* <div className='space-bottom'>
          <AsaLoginButton t={t} />
        </div>
        <p className='or-text space-bottom space-top'>{t('or')}</p> */}
        <input
          placeholder={t('email')}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value?.toLowerCase())
          }}
          name='username'
          autoComplete='username'
          className='signup-input'
        />
        <PhoneInput
          containerClass='phone-input-container space-top space-bottom'
          inputClass='phone-input'
          // disableDropdown
          country={'us'}
          value={phone}
          onChange={(e) => setPhone(e)}
          placeholder={t('phone')}
        />

        {/* <div style={{ marginBottom: '1rem', display: 'flex', gap: '1rem' }}>
          <InputText
            id='first_name'
            name='first_name'
            value={userForm?.first_name}
            placeholder='First name '
            onChange={handleUserFormInputChange}
          />
          <InputText
            id='last_name'
            name='last_name'
            placeholder='Last name'
            value={userForm?.last_name}
            onChange={handleUserFormInputChange}
          />
        </div>

        <Calendar
          value={userForm?.birthday}
          onChange={(e) => parseBirthday(e.target.value)}
          dateFormat='mm/dd/yy'
          name='birthday'
          showIcon
          icon='pi pi-calendar'
          placeholder='Date of Birth'
          monthNavigator={true}
          yearNavigator={true}
          monthNavigatorTemplate={monthNavigatorTemplate}
          yearNavigatorTemplate={yearNavigatorTemplate}
          yearRange={`1900:${new Date().getFullYear()}`}
          className='calendar-input'
          style={{ marginBottom: '1rem', width: '100%' }}
        ></Calendar> */}

        <PasswordInput
          password={password}
          setPassword={setPassword}
          placeHolder={t('password')}
          className={'signup-input'}
        />
        {showPasswordErrors && <PasswordMessage t={t} password={password} />}

        <div className='check__container space-top'>
          <input
            type='checkbox'
            onChange={() => setAgree((prevState) => !prevState)}
            className='agreement-checkbox'
            checked={agree}
          />
          <span>
            {t('iagreetothe')}{' '}
            <Link
              to='/Legal/ForeSCITE-Privacy-Policy.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              {t('privacypolicy')}
            </Link>{' '}
            {' & '}
            <a href='/Legal/EULA.pdf' className='link' target='_blank'>
              {t('eula')}
            </a>
          </span>
        </div>

        {/* <div className='recaptcha-container'>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey='6LfgQpMjAAAAAHqdVpA6qtw3gpwik3jARl_NCZLL'
            onChange={onChange}
          />
        </div> */}
        <div className='signup-btn-container'>
          <Button
            className='signup-button'
            disabled={false}
            label={t('signup')}
            onClick={() => {
              resetMessages()
              validateForm()
            }}
            loading={loading}
          />
        </div>
        <p className='signup-footer space-top'>
          <NavLink to={'/signin'} exact={true} className='signin-link'>
            {t('signin')}
          </NavLink>{' '}
          {t('toyourinvestallaccount')}
        </p>
        {/* <Toast ref={toastErrorMsg} position='top-right' /> */}
      </>
    )
  }

  return (
    <>
      {' '}
      <MainNavBar
        isSmallScreen={isSmallScreen}
        t={props.t}
        whiteBorder={true}
      />
      <div className='signup-container'>
        <div className='signup-form-container'>
          <p className='signup-header'>{t('signup')}</p>
          <div className='signup-form'>
            {page === 'signup' && showSignUpForm()}
            {page === 'codeverify' && (
              <CodeVerification
                email={email}
                password={password}
                t={t}
                phone={phone}
                setUser={props.setUser}
                goBack={goBack}
                promoCode={promoCode}
                user={props.user}
                // userForm={userForm}
              />
            )}
            {errorMsg && <p className='error'>{errorMsg}</p>}
            {successMsg && <p className='success'>{successMsg}</p>}
          </div>
        </div>
      </div>
    </>
  )
}
