import React, { Component, PropTypes } from 'react'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import '../../scss/components/WireDepositDialog.scss'
import Axios from 'axios'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import Endpoint from '../Endpoint'
import Loader from '../../components/components/accountInfo/Loader'
import { getSelectedAccountsInformation } from '../../services/userServices'
export default class WireDepositDialog extends Component {
  constructor(props) {
    super()
    this.state = {
      selectedBankAccount: {
        ...props.selectedAccount,
      },
      dwAccounts: [],
      wireDetails: {},
      loading: false,
      printing: false,
    }
  }

  componentDidMount() {
    this.getSelectedAccountsInformation(this.props.selectedAccount?.accountId)
    let dwAccounts = []
    if (this.props.accounts) {
      this.props.accounts.forEach((account) => {
        if (account.inst === 'DriveWealth') {
          // let name = "";
          // switch (account.product_assigned) {
          //   case "equity":
          //     name = "STOCK";
          //     break;
          //   case "goal":
          //     name = "GOAL";
          //     break;
          //   case "group":
          //     name = "AI STOCK TRADER";
          //     break;
          //   case "robo":
          //     name = "ROBO";
          //     break;
          //   default:
          //     break;
          // }
          let name = this.getName(account.product_assigned)
          dwAccounts.push({
            // label: `${account.name_unmasked} (${name})`,
            // label: `${name} Account`,
            label: this.props.t(`${name.toLowerCase()}account`),
            value: account,
          })
        }
      })
    }
    this.setState({
      dwAccounts: dwAccounts,
    })
  }

  getName = (product_assigned) => {
    switch (product_assigned) {
      case 'equity':
        return 'STOCK'
      case 'goal':
        return 'GOAL'
      case 'group':
        return 'AI STOCK TRADER'
      case 'robo':
        return 'ROBO'
      default:
        return '-'
    }
  }
  // getSelectedAccountsInformation = async (accountId) => {
  //   this.setState({ loading: true })
  //   Axios.get(`${Endpoint}/mobile/wire/vaccount?account_id=${accountId}`, {
  //     headers: {
  //       Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
  //     },
  //   })
  //     .then((res) => {
  //       if (res?.data?.data?.wireDetails) {
  //         this.setState({ wireDetails: res.data.data.wireDetails })
  //       }
  //       this.setState({ loading: false })
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //       this.setState({ loading: false })
  //     })
  // }

  getSelectedAccountsInformation = async (accountId) => {
    this.setState({ loading: true })
    const response = await getSelectedAccountsInformation(
      this.props.user?.idToken?.jwtToken,
      accountId
    )
    if (response?.data?.wireDetails) {
      this.setState({ wireDetails: response.data.wireDetails })
    }
    this.setState({ loading: false })
  }

  handleBankAccountChange = (value) => {
    this.getSelectedAccountsInformation(value.accountId)
    this.setState({ selectedBankAccount: value })
  }

  printDocument() {
    try {
      this.setState({ printing: true })
      const input = document.getElementById('divToPrint')
      const item = document.createElement('h1')
      const content = document.createTextNode('Investall Wire Instructions')
      item.appendChild(content)
      // input.prepend(item)
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        const pdf = new jsPDF()
        pdf.addImage(imgData, 'JPEG', this.props.xPadding, this.props.yPadding)
        pdf.save('download.pdf')
      })
      // input.removeChild(input.firstElementChild)
      input.classList.remove('padding')
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    if (this.state.dwAccounts && this.props.t) {
      if (this.state.loading) {
        return (
          <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false} />
        )
      } else {
        return (
          <>
            <div id='divToPrint'>
              <p className='wire-deposit-header'>
                {this.props.t('internationalwiredepositinstructions')}
              </p>
              <div className='notice'>
                {/* <p className='notice-info important'>
                  &#x0002A;{this.props.t('wiredepositinstructions1')}
                </p> */}
                <p className='notice-info'>
                  &#x0002A;{this.props.t('wiredepositinstructions2')}
                </p>
                <p className='notice-info'>
                  &#x0002A;{this.props.t('wiredepositinstructions3')}
                </p>
                <p className='notice-info'>
                  &#x0002A;{this.props.t('wiredepositinstructions4')}
                </p>
              </div>
              <div className='remittance-info'>
                <p className='remit-header'>
                  {this.props.t('remittanceinformation')}
                </p>
                <div className='remit-row'>
                  <p className='label'>{this.props.t('amount')}</p>
                  <p className='value'>{this.props.t('usdollaramount')}</p>
                </div>
                <div className='remit-row colored'>
                  <p className='label'>{this.props.t('recipientbankname')}</p>
                  <p className='value'>
                    {this.state.wireDetails?.bankName
                      ? this.state.wireDetails?.bankName
                      : '-'}
                  </p>
                </div>
                <div className='remit-row'>
                  <p className='label'>{this.props.t('bankaddress')}</p>
                  <p className='value'>
                    {this.state.wireDetails?.bankAddress
                      ? `${this.state.wireDetails?.bankAddress?.addressLine1}, ${this.state.wireDetails?.bankAddress?.city}, ${this.state.wireDetails?.bankAddress?.stateProvince} ${this.state.wireDetails?.bankAddress?.zipPostalCode}`
                      : '-'}
                  </p>
                </div>
                {/* <div className='remit-row colored'>
                  <p className='label'>{this.props.t('abanumber')}</p>
                  <p className='value'>
                    {this.state.wireDetails?.bankRoutingNumber
                      ? this.state.wireDetails?.bankRoutingNumber
                      : '-'}
                  </p>
                </div> */}
                <div className='remit-row colored'>
                  <p className='label'>{this.props.t('swiftcode')}</p>
                  <p className='value'>
                    {this.state.wireDetails?.bankSwitftCode
                      ? this.state.wireDetails?.bankSwitftCode
                      : '-'}
                  </p>
                </div>
                <div className='remit-row'>
                  <p className='label'>{this.props.t('bankaccountnumber')}</p>
                  <p className='value'>
                    {this.state.wireDetails?.bankAccountNumber
                      ? this.state.wireDetails?.bankAccountNumber
                      : '-'}
                  </p>
                </div>
                <div className='remit-row colored'>
                  <p className='label'>{this.props.t('beneficiaryname')}</p>
                  <p className='value'>
                    {this.state.wireDetails?.beneficiaryName
                      ? this.state.wireDetails?.beneficiaryName
                      : '-'}
                  </p>
                </div>
                <div className='remit-row'>
                  <p className='label'>{this.props.t('beneficiaryaddress')}</p>
                  <p className='value'>
                    {this.state.wireDetails?.beneficiaryAddress
                      ? `${this.state.wireDetails?.beneficiaryAddress?.addressLine1}, ${this.state.wireDetails?.beneficiaryAddress?.city}, ${this.state.wireDetails?.beneficiaryAddress?.stateProvince} ${this.state.wireDetails?.beneficiaryAddress?.zipPostalCode}`
                      : '-'}
                  </p>
                </div>
                <div className='remit-row colored'>
                  <p className='label'>
                    &#x0002A;{this.props.t('selectyouraccounttodeposit')}
                  </p>
                  <p className='value'>
                    <Dropdown
                      value={this.state.selectedBankAccount}
                      options={this.state.dwAccounts}
                      onChange={(e) => this.handleBankAccountChange(e.value)}
                      placeholder={this.props.t('selectaccounttoview')}
                    />
                  </p>
                </div>
                <div className='remit-row'>
                  <p className='label'>{this.props.t('accountnumber')}</p>
                  <p className='value highlight'>
                    {this.state.selectedBankAccount?.name_unmasked}
                  </p>
                </div>
                <p className='notice-info-small important'>
                  &#x0002A;{this.props.t('wiredepositinstructions1')}
                </p>
              </div>
            </div>
            <div className='download-btn-container'>
              <Button
                label={this.props.t('clickheretodownload')}
                onClick={() => {
                  this.printDocument()
                }}
                className='download-btn'
              />
            </div>
          </>
        )
      }
    } else return null
  }
}
