import React from 'react'
import LinkToPlaid from '../LinkToPlaid'
import CreateDwAcc from './CreateDwAcc'
import RoiChart from '../roiChart'
import TradeGroup from './TradeGroup'
import AreYouSure from '../areYouSureModal'

import ENDPOINT from '../Endpoint'

import ConnectDW from '../../assets/newUI/connectDW.svg'
import LinkNew from '../../assets/newUI/linkNew.svg'
import UseLinked from '../../assets/newUI/useLinked.svg'
import StartInvest from '../../assets/newUI/startInvest.svg'
import GroupTrader from '../../assets/newUI/GroupTrader.svg'
import StockTrader from '../../assets/newUI/StockTrader.svg'
import Robo from '../../assets/newUI/Robo.svg'
import Spinner from 'react-bootstrap/Spinner'
import SubModal from '../SubModal'
import SearchEndpoint from '../SearchEndpoint'

const brandColor = '#00A99D'

export default class Invest extends React.Component {
  state = {
    stratData: false,
    selectedStrat: '',
    customPorts: this.props.customPorts,
    dob: 'yyyy-mm-dd',
    reason: false,
    timeframe: false,
    risk: false,
    experience: false,
    step: -1,
    step: this.props.invStep,
    hover: false,
    sameInst: {},
    strategy: 'U.S. Domestic Composite',
    initial: 5000,
    reccuring: 10,
    product: this.props.prod,
    selected: [],
    groupName: 'ForeSCITE Trade Group',
    groupAmount: 0.85,
    sureCheck: [],
    needQuestionnaire: true,
    submitClicked: false,
    showSub: false,
    canTrade: true,
  }

  componentDidMount() {
    this.getMarketStatus()
    this.needQuestionnaire()
    // this.fetchARstrategies()
    // this.goalsTEST()
  }

  getMarketStatus = () => {
    // console.log('inside can trade')
    fetch(`${SearchEndpoint}/istradinghour`, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((responseJson) => {
        console.log('can trade resp: ', responseJson)
        this.setState({ canTrade: responseJson })
      })
  }

  handleOnSuccess = (public_token, metadata) => {
    // send token to client server
    // fetch("/auth/public_token", {
    //   public_token: public_token
    // });
    let accountId = metadata.account_id
    let itemId = metadata.institution.institution_id
    this.linkAccount(public_token, metadata)
    let resp = this.findOtherPortfoliosSameInstitution(itemId)
    if (resp.length > 0) {
      this.addOtherPortSameInstituion(accountId, itemId)
    }
    // this.setState({respMeta:metadata, respToken:public_token})
  }

  handleOnExit() {
    console.log('handling exit')
    // handle the case when your user exits Link
    // For the sake of this tutorial, we're not going to be doing anything here.
  }

  fetchOneStrategy(name) {
    // fetch(`${ENDPOINT}/risk-monkey/portfolios/${name}`,{
    fetch(`${ENDPOINT}/risk-monkey/portfolios/test2`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) =>
        console.log('one custom saved result: ', responseJson)
      )
  }

  deleteOneStrategy = (id) => {
    fetch(`${ENDPOINT}/risk-monkey/portfolios/delete/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => console.log('responseJson:', responseJson))
      .then(() => {
        let strats = { ...this.state.customPorts }
        delete strats[id]
        this.setState({ customPorts: strats })
      })
  }

  findOtherPortfoliosSameInstitution = (instName) => {
    console.log('looking for others')
    let itemId = this.getItemId(instName)
    fetch(
      `${ENDPOINT}/plaid/accounts?unconnectedAccounts=true&itemId=${itemId}`,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((respJSON) => {
        console.log('respJSON: ', respJSON)
        this.setState({
          sameInst: {
            ...this.state.sameInst,
            [this.state.selectedInst]: respJSON,
          },
        })
      })
      .catch((err) => console.log('catching: ', err))
  }

  fetchARstrategies = () => {
    fetch(`${ENDPOINT}/strategies`, {
      'Access-Control-Allow-Headers': {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        this.setState({ stratData: responseJson })
      })
  }

  showFactSheet = (displayName) => {
    switch (displayName) {
      case 'US Domestic':
      //code
      case 'US Domestic AI':
      //code
      case 'Alpha Overlay':
      //code
    }
  }

  setHref = (displayName) => {
    if (displayName === 'US Domestic') {
      return '../../assets/factsheets/US-Domestic-Fact-Sheet.pdf'
    } else if (displayName === 'US Domestic AI') {
      return '../../assets/factsheets/US-Dynamic-Leverage-Fact-Sheet.pdf'
    } else {
      return null
    }
  }

  showOneCustom = (title, data) => {
    // console.log('got to custom')
    // console.log('title', title)
    // console.log('data', data)
    let arr = []
    let stockRow = data.forEach((ele) => {
      arr.push(
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            border: 'solid',
            borderWidth: 0.25,
            borderColor: '#ddd',
          }}
        >
          <div
            style={{
              width: '50%',
              borderRight: 'solid',
              borderWidth: 0.25,
              borderColor: '#ddd',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p>{ele.symbol}</p>
          </div>
          <div
            style={{
              width: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p>
              {ele.symbol === '$$'
                ? parseFloat(ele.quantity).toFixed(2)
                : ele.quantity}
            </p>
          </div>
        </div>
      )
    })
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'solid',
            borderWidth: 0.25,
            borderColor: '#ddd',
          }}
        >
          <div
            style={{ width: '80%', marginLeft: '10%', cursor: 'pointer' }}
            onClick={() =>
              this.setState({
                selectedStrat: this.state.selectedStrat === title ? '' : title,
              })
            }
          >
            <p>{title}</p>
          </div>
          <div
            style={{
              width: '20%',
              borderLeft: 'solid',
              borderWidth: 0.25,
              borderColor: '#ddd',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => this.deleteOneStrategy(title)}
          >
            <p>Delete {title}</p>
          </div>
        </div>
        {this.state.selectedStrat === title ? (
          <div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                border: 'solid',
                borderWidth: 0.25,
                borderColor: '#ddd',
              }}
            >
              <div
                style={{
                  width: '50%',
                  borderRight: 'solid',
                  borderWidth: 0.25,
                  borderColor: '#ddd',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p>Symbol</p>
              </div>
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <p>Number of Shares</p>
              </div>
            </div>
            <div style={{ width: '100%' }}>
              <div>{arr}</div>
            </div>
          </div>
        ) : null}
      </div>
    )
  }

  showStrategy = (ob) => {
    return (
      <div style={{ height: 300 }}>
        <div
          style={{ cursor: 'pointer' }}
          // onClick={() => window.open(this.setHref(ob.displayName))}
        >
          <h4 style={{ color: '' }}>{ob.displayName}</h4>
          {/* <a style={{color:brandColor, fontSize:24}} href={this.setHref(ob.displayName)} target={ob.displayName === 'US Domestic' ||ob.displayName === 'US Domestic AI' ?'_blank':null}>{ob.displayName}</a> */}
        </div>
        <div>
          <p>{ob.description}</p>
        </div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p style={{ fontWeight: 'bold' }}>Performance</p>
          </div>
          <div>
            {this.state.stratData ? (
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div>
                  <p>MTD</p>
                  <p style={{ color: brandColor }}>
                    {(ob.mtd * 100).toFixed(2)}
                  </p>
                </div>
                <div>
                  <p>YTD</p>
                  <p style={{ color: brandColor }}>
                    {(ob.ytd * 100).toFixed(2)}
                  </p>
                </div>
                <div>
                  <p>LTD</p>
                  <p style={{ color: brandColor }}>
                    {(ob.ltd * 100).toFixed(2)}
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  getItemId = (instName) => {
    let id = ''
    this.props.linkedPorts.forEach((ele) => {
      if (ele.instName === instName) {
        id = ele.itemId
      }
    })
    return id
  }

  linkModal = () => {
    let arr = []
    this.props.linkedPorts.forEach((ele) => {
      arr.push(
        <div
          style={{
            width: '100%',
            padding: 4,
            borderTop: 'solid',
            borderWidth: 0.5,
            borderColor: '#ddd',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (!this.state.sameInst[ele.instName]) {
                this.findOtherPortfoliosSameInstitution(ele.instName)
              }
              if (this.state.selectedInst !== ele.instName) {
                this.setState({ selectedInst: ele.instName })
              } else {
                this.setState({ selectedInst: '' })
              }
            }}
          >
            {ele.instName}
          </div>
          <div>{this.showOthers(ele.instName)}</div>
        </div>
      )
    })
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ width: '95%', margin: 'auto', marginBottom: 25 }}>
          <h4 style={{ color: 'rgb(17,17,17)' }}>Link</h4>
        </div>
        <div style={{ backgroundColor: 'rgb(255,255,255)', borderRadius: 12 }}>
          <div
            style={{
              display: 'flex',
              margin: 0,
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 20,
              marginTop: 20,
            }}
          >
            <LinkToPlaid
              button={true}
              user={this.props.user}
              numAccounts={this.props.linkedPorts.length}
            />
          </div>
          <div
            style={{
              display: 'flex',
              margin: 0,
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: 'auto' }}>or</p>
          </div>
          <div
            style={{
              display: 'flex',
              margin: 0,
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 20,
            }}
          >
            <p style={{ margin: 'auto' }}>Another account from:</p>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              border: 'solid',
              borderWidth: 0.25,
              borderColor: '#ddd',
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            {arr}
          </div>
          <div style={{ marginBottom: 20 }}>{this.linkChecked()}</div>
        </div>
      </div>
    )
  }

  hasManaged = () => {
    this.props.accounts.some((acc) => acc.ria === true)
  }

  portfolioOptions = () => {
    console.log('showing portfolio options')
    let str = ''
    if (this.state.product === 'group') {
      this.state.selected.forEach((ele, i) => {
        str += ele.symbol
        if (i !== this.state.selected.length - 1) {
          str += ', '
        }
      })
    }
    console.log('str', str)
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ width: '95%', margin: 'auto', marginBottom: 25 }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <img src={StartInvest} />
            <h4 style={{ color: 'rgb(17,17,17)', marginTop: 20 }}>
              START INVESTING
            </h4>
            {this.state.product === 'group' ? (
              <h6>
                Trade Group Created. Choose an account for your trade group
              </h6>
            ) : null}
            {this.state.product === 'robo' && this.state.needQuestionnaire ? (
              <h6>
                Questionnaire Submitted. Choose which account to dedicate to the
                robo
              </h6>
            ) : null}
            {this.state.product === 'robo' || this.state.product === 'group' ? (
              <h6>
                WARNING: This will liquidate any current positions in the
                account and buy into whichever{' '}
                {this.state.product === 'robo'
                  ? 'strategy our AI thinks is best for you'
                  : str}
                . You will not be able to use this account for anything besides
                the {this.state.product === 'robo' ? 'robo' : 'trade group'}{' '}
                unless you unlink the{' '}
                {this.state.product === 'robo' ? 'robo' : 'trade group'}.{' '}
              </h6>
            ) : null}
          </div>
        </div>
        <div
          style={{
            width: '95%',
            margin: 'auto',
            display: 'flex',
            justifyContent: this.props.isSmallScreen ? '' : 'space-around',
            flexDirection: this.props.isSmallScreen ? 'column' : '',
          }}
        >
          {this.props.linkedPorts.length > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 20,
                width: this.props.isSmallScreen ? '100%' : '25%',
                backgroundColor: 'rgb(110,183,181)',
                marginBottom: 25,
                paddingTop: '10%',
                paddingBottom: '10%',
                cursor: 'pointer',
              }}
              onClick={() => this.setState({ step: 3.1 })}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={UseLinked} />
              </div>
              <div style={{ width: '100%', marginTop: 50 }}>
                <p
                  style={{
                    margin: 0,
                    fontSize: 22,
                    color: 'rgb(255,255,255)',
                    textAlign: 'center',
                  }}
                >
                  {this.state.product === 'group'
                    ? 'Use a Linked Drive Wealth Account'
                    : 'Use a Linked Account'}
                </p>
                <p
                  style={{
                    margin: 0,
                    fontSize: 22,
                    color: 'rgb(255,255,255)',
                    textAlign: 'center',
                  }}
                >
                  &nbsp;
                </p>
              </div>
            </div>
          ) : null}

          {this.state.product === 'group' ? null : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 20,
                width: this.props.isSmallScreen ? '100%' : '25%',
                backgroundColor: 'rgb(95,128,244)',
                marginBottom: 25,
                paddingTop: '10%',
                paddingBottom: '10%',
                cursor: 'pointer',
              }}
              onClick={() => this.setState({ step: 3.2 })}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={LinkNew} />
              </div>
              <div style={{ width: '100%', marginTop: 50 }}>
                <p
                  style={{
                    margin: 0,
                    fontSize: 22,
                    color: 'rgb(255,255,255)',
                    textAlign: 'center',
                  }}
                >
                  {'Link a New Account'}
                </p>
                <p
                  style={{
                    margin: 0,
                    fontSize: 22,
                    color: 'rgb(255,255,255)',
                    textAlign: 'center',
                  }}
                >{` `}</p>
              </div>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: 20,
              width: this.props.isSmallScreen ? '100%' : '25%',
              backgroundColor: 'rgb(133,131,198)',
              marginBottom: 25,
              paddingTop: '10%',
              paddingBottom: '10%',
              cursor: 'pointer',
            }}
            onClick={() => this.setState({ step: 3.3 })}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={ConnectDW} />
            </div>
            <div style={{ width: '100%', marginTop: 50 }}>
              <p
                style={{
                  margin: 0,
                  fontSize: 22,
                  color: 'rgb(255,255,255)',
                  textAlign: 'center',
                }}
              >
                Create a{' '}
              </p>
              <p
                style={{
                  margin: 0,
                  fontSize: 22,
                  color: 'rgb(255,255,255)',
                  textAlign: 'center',
                }}
              >
                Brokerage Account{' '}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  subModal = () => {
    if (this.state.showSub) {
      return (
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            position: 'fixed',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'auto',
            zIndex: 99999,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: 'auto',
              backgroundColor: 'rgb(255,255,255)',
              borderRadius: 10,
              width: this.props.isSmallScreen ? '90%' : '40%',
              height: '95%',
              paddingBottom: 25,
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'rgb(37,42,55)',
                paddingLeft: 20,
                paddingRight: 20,
                alignItems: 'center',
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <p
                style={{
                  color: 'rgb(255,255,255)',
                  fontWeight: 'bold',
                  fontSize: 20,
                  margin: 0,
                }}
              >
                {' '}
                Subscribe
              </p>
              <p
                style={{
                  margin: 0,
                  fontWeight: 'bold',
                  color: 'rgb(255,255,255)',
                  cursor: 'pointer',
                }}
                onClick={() => this.setState({ showSub: false })}
              >
                {' '}
                X{' '}
              </p>
            </div>
            <div style={{ width: '100%' }}>
              <SubModal
                updatePaymentStatus={this.props.updatePaymentStatus}
                modal={true}
                user={this.props.user}
              />
            </div>
          </div>
        </div>
      )
    }
  }

  accountType = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ width: '95%', margin: 'auto', marginBottom: 25 }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <img src={StartInvest} />
            <h4 style={{ color: 'rgb(17,17,17)', marginTop: 20 }}>
              START INVESTING
            </h4>
          </div>
        </div>
        <div
          style={{
            width: '95%',
            margin: 'auto',
            display: 'flex',
            justifyContent: this.props.isSmallScreen ? '' : 'space-around',
            flexDirection: this.props.isSmallScreen ? 'column' : '',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: 20,
              width: this.props.isSmallScreen ? '100%' : '30%',
              backgroundColor: 'rgb(133,131,198)',
              marginBottom: 25,
              paddingTop: '10%',
              paddingBottom: '10%',
              cursor: 'pointer',
            }}
            onClick={
              this.props.subStatus
                ? () => this.setState({ product: 'robo', step: 0.1 })
                : () => this.setState({ showSub: true })
            }
          >
            <div style={{}}>
              <div style={{}}>{/* <p style={{margin:0}}>Pro</p> */}</div>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={Robo} />
            </div>
            <div style={{ width: '95%', margin: 'auto', marginTop: 50 }}>
              <p
                style={{
                  margin: 0,
                  fontSize: 22,
                  color: 'rgb(255,255,255)',
                  textAlign: 'center',
                }}
              >
                ForeSCITE Robo{' '}
              </p>
              <p
                style={{
                  margin: 0,
                  marginTop: 20,
                  fontSize: 18,
                  color: 'rgb(255,255,255)',
                  textAlign: 'center',
                }}
              >
                Answer a few questions and we will pick one of our strategies
                that best suits your investment goals and risk level.
              </p>
              <p
                style={{
                  margin: 0,
                  marginTop: 20,
                  fontSize: 18,
                  color: 'rgb(255,255,255)',
                  textAlign: 'center',
                }}
              >
                {' '}
                All of our strategies are highly liquid and rebalanced monthly
                to keep you positioned to minimize losses and maximize gains.
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: 20,
              width: this.props.isSmallScreen ? '100%' : '30%',
              backgroundColor: 'rgb(110,183,181)',
              marginBottom: 25,
              paddingTop: '10%',
              paddingBottom: '10%',
              cursor: 'pointer',
            }}
            onClick={
              this.props.subStatus
                ? () => this.setState({ product: 'group', step: 0.2 })
                : () => this.setState({ showSub: true })
            }
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={GroupTrader} />
            </div>
            <div style={{ width: '95%', margin: 'auto', marginTop: 50 }}>
              <p
                style={{
                  margin: 0,
                  fontSize: 22,
                  color: 'rgb(255,255,255)',
                  textAlign: 'center',
                }}
              >
                ForeSCITE Group Trader
              </p>
              <p
                style={{
                  margin: 0,
                  marginTop: 20,
                  fontSize: 18,
                  color: 'rgb(255,255,255)',
                  textAlign: 'center',
                }}
              >
                Select up to four stocks for our AI to day trade on your behalf.
                All day trades occur at based on price momentum shifts detected
                by our AI.
              </p>
              {/* <p style={{margin:0, marginTop:35, fontSize: 14, color:'rgb(255,255,255)', textAlign:'center'}}>*Due to SEC regulations this feature is only available if your account total value is over $25,000 at time of creation.</p> */}
              <p
                style={{
                  margin: 0,
                  marginTop: 20,
                  fontSize: 14,
                  color: 'rgb(255,255,255)',
                  textAlign: 'center',
                }}
              >
                *This feature is only available using a DriveWealth Account
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              borderRadius: 20,
              width: this.props.isSmallScreen ? '100%' : '30%',
              backgroundColor: 'rgb(95,128,244)',
              marginBottom: 25,
              paddingTop: '10%',
              paddingBottom: '10%',
              cursor: 'pointer',
            }}
            onClick={() => this.setState({ product: 'normal', step: 0.1 })}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={StockTrader} />
            </div>
            <div style={{ width: '95%', margin: 'auto', marginTop: 50 }}>
              <p
                style={{
                  margin: 0,
                  fontSize: 22,
                  color: 'rgb(255,255,255)',
                  textAlign: 'center',
                }}
              >
                ForeSCITE Self-Directed Investing{' '}
              </p>
              <p
                style={{
                  margin: 0,
                  marginTop: 20,
                  fontSize: 18,
                  color: 'rgb(255,255,255)',
                  textAlign: 'center',
                }}
              >
                Link or open a brokerage account and to track and trade stocks.{' '}
              </p>
              <p
                style={{
                  margin: 0,
                  marginTop: 20,
                  fontSize: 18,
                  color: 'rgb(255,255,255)',
                  textAlign: 'center',
                }}
              >
                Create a DriveWealth account and trade right here!
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  addCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  linkToRobo = (accountId) => {
    console.log('accountId:', accountId)
    fetch(`${ENDPOINT}/trackers/live`, {
      method: 'POST',
      headers: {
        // "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
      body: JSON.stringify({
        accountId: accountId,
      }),
    })
      .then((response) => response.json())
      .then((respJSON) => console.log('link2robo resp', respJSON))
      .then(() => window.location.reload(false))
      .catch((err) => console.log('err', err))
  }

  accountPlusProduct = (id, total) => {
    // console.log('prod: ', this.state.product)
    switch (this.state.product) {
      case 'robo':
        window.location.reload(false)
        //   this.linkToRobo(id)
        break
      case 'group':
        this.createTG(id, total)
        break
      default:
        window.location.reload(false)
        break
    }
  }

  createTG = (id, total) => {
    console.log('creating trade group!!')
    fetch(`${ENDPOINT}/mobile/trade-groups/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
      body: JSON.stringify({
        groupName: this.state.groupName,
        dw_account_id: id,
      }),
    })
      .then((response) => response.json())
      .then((respJSON) => console.log('create resp', respJSON))
      .then(() => this.addToTG(id, total))
      .catch((err) => console.log('createTg error: ', err))
  }

  addToTG = (account_id, total) => {
    console.log('in add2TG...')
    let stocks = this.state.selected.map((ele) => ele.symbol)
    let amount = total

    console.log('adding to trade group!!')
    fetch(`${ENDPOINT}/mobile/trade-groups/addstocks`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        account_id,
        amount,
        stocks,
        status: 'inactive',
      }),
    })
      .then((response) => response.json())
      .then((respJSON) => console.log('add to TG resp', respJSON))
      .then(() => this.tgInitialBuy(account_id, total))
      .catch((err) => console.log('add2tg error: ', err))
  }

  tgInitialBuy = (id, total) => {
    console.log('in initial buy...')
    let stocks = this.parseFromSelected()
    fetch(`${ENDPOINT}/mobile/trade-groups/initial-buy`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        group_name: this.state.groupName,
        stocks,
        amount: total,
        dwAccountID: id,
      }),
    })
      .then((response) => response.json())
      .then((respJSON) => console.log('add to TG resp', respJSON))
      .then(() => window.location.reload(false))
      .catch((err) => console.log('tgInitialBuy error: ', err))
  }

  parseFromSelected = () => {
    let stocks = []
    this.state.selected.forEach((ele) => {
      stocks.push({
        sym: ele.symbol,
        curr: ele.direction,
        prev: ele.prevDirection,
        price: ele.priceUSD,
      })
    })
    return stocks
  }

  goalsTEST = () => {
    console.log('goals test!!')
    fetch(`${ENDPOINT}/mobile/goal-planning`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        goalData: {
          compoundInterest: 'Compound Monthly',
          contributionFrequency: 'Monthly',
          contributionAmount: '55',
          startingAmount: '1000',
          targetedAmount: '10000',
          yearsToAccumulate: '6',
          roi: '0.0969',
          totalTaxRate: '0.2868',
          inflationRate: '0.029',
        },
        option: {
          action: 'calculate',
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((err) => console.log('err: ', err))
  }

  accRow = (inner, outer) => {
    let total
    if (inner.balances[0].current === null) {
      total = 0
    } else {
      total = inner.balances[0].current
    }
    return (
      <div
        style={{
          padding: 25,
          borderRadius: 8,
          margin: 10,
          height: '85%',
          backgroundColor:
            this.state.hover === inner.itemId ? '#F8F9FA' : 'rgb(248,249,250)',
          textDecoration: 'none',
          cursor: 'pointer',
        }}
        onClick={() => {
          this.setState({
            sureCheck: {
              id: inner.accountId,
              total,
              acc: inner,
              instName: outer.instName,
            },
          })
        }}
      >
        <div
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 8,
            textDecoration: 'none',
          }}
        >
          <div
            style={{
              width: this.props.isSmallScreen ? '25%' : '30%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: 0 }}>{outer.instName}</p>
          </div>
          <div
            style={{
              width: this.props.isSmallScreen ? '25%' : '30%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: 0 }}>{inner.name}</p>
          </div>
          {inner.ria ? (
            <div
              style={{
                width: this.props.isSmallScreen ? '15%' : '10%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ margin: 0 }}>
                {inner.product ? inner.product : 'RIA Managed'}
              </p>
            </div>
          ) : (
            <div
              style={{
                width: this.props.isSmallScreen ? '15%' : '10%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ margin: 0 }}>Self Directed</p>
            </div>
          )}
          <div
            style={{
              width: this.props.isSmallScreen ? '10%' : '10%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: 0 }}>{inner.mask}</p>
          </div>
          <div
            style={{
              width: this.props.isSmallScreen ? '25%' : '20%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: 0 }}>
              <strong style={{ fontSize: 20 }}>
                $
                {inner.balances[0].current
                  ? this.addCommas(inner.balances[0].current.toFixed())
                  : '0.00'}
              </strong>{' '}
              {inner.balances[0].isoCurrencyCode}
            </p>
          </div>
        </div>
      </div>
    )
  }

  useLinked = () => {
    let mapped = [
      <div
        style={{
          padding: 25,
          width: '100%',
          borderRadius: 8,
          margin: 10,
          height: '85%',
          textDecoration: 'none',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 8,
            textDecoration: 'none',
          }}
        >
          <div
            style={{
              width: this.props.isSmallScreen ? '25%' : '30%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: 0, fontSize: 20 }}>
              {this.props.isSmallScreen ? 'Inst' : 'Institution'}
            </p>
          </div>
          <div
            style={{
              width: this.props.isSmallScreen ? '25%' : '30%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: 0, fontSize: 20 }}>
              {this.props.isSmallScreen ? 'Name' : 'Name'}
            </p>
          </div>
          <div
            style={{
              width: this.props.isSmallScreen ? '15%' : '10%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: 0, fontSize: 20 }}>
              {this.props.isSmallScreen ? 'Prod' : 'Product'}
            </p>
          </div>
          <div
            style={{
              width: this.props.isSmallScreen ? '10%' : '10%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: 0, fontSize: 20 }}>
              {this.props.isSmallScreen ? 'Acc # ' : 'Account #'}
            </p>
          </div>
          <div
            style={{
              width: this.props.isSmallScreen ? '25%' : '20%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: 0, fontSize: 20 }}>
              {this.props.isSmallScreen ? 'Value' : 'Account Value'}
            </p>
          </div>
        </div>
      </div>,
    ]
    this.props.linkedPorts.forEach((outer) => {
      outer.accounts.forEach((inner) => {
        if (!(inner.name === 'Checking' || inner.name === 'Depository')) {
          if (
            this.state.product === 'group' &&
            outer.instName === 'DriveWealth' &&
            !inner.ria
          ) {
            mapped.push(this.accRow(inner, outer))
          } else if (this.state.product === 'normal') {
            mapped.push(this.accRow(inner, outer))
          } else if (
            this.state.product === 'robo' &&
            inner.ria &&
            inner.balances[0].current &&
            inner.balances[0].current > 99.9 &&
            inner.status === 'OPEN'
          ) {
            mapped.push(this.accRow(inner, outer))
          }
        }
      })
    })
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ width: '95%', margin: 'auto', marginBottom: 25 }}>
          <h4 style={{ color: 'rgb(17,17,17)' }}>Start Investing</h4>
        </div>
        <div
          style={{
            width: '95%',
            margin: 'auto',
            backgroundColor: 'rgb(255,255,255)',
            borderRadius: 12,
          }}
        >
          {mapped}
        </div>
        {/* add back button */}
      </div>
    )
  }

  linkChecked = () => {
    let linkXmore = 0
    if (this.state.sameInst[this.state.selectedInst]) {
      this.state.sameInst[this.state.selectedInst].forEach((ele) => {
        if (ele.checked) {
          linkXmore += 1
        }
      })
    }
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'solid',
            borderColor: '#3b8e8c',
            borderRadius: 8,
            padding: 10,
            width: '45%',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (linkXmore > 0) {
              // console.log('this.state.massAddTable', this.state.massAddTable)
              this.addOtherPortSameInstituion()
              this.setState({ showLinkModal: false })
            }
          }}
        >
          <p style={{ margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
            {linkXmore > 0
              ? `Link ${linkXmore} portfolio${linkXmore === 1 ? '' : 's'}`
              : 'Select Accounts Above'}
          </p>
        </div>
      </div>
    )
  }

  showOthers = (name) => {
    let arr = []
    if (name === this.state.selectedInst) {
      if (this.state.sameInst[this.state.selectedInst]) {
        this.state.sameInst[this.state.selectedInst].forEach((ele) => {
          arr.push(
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderTop: 'solid',
                borderWidth: 0.25,
                borderColor: '#ddd',
                backgroundColor: ele.checked ? '#eeeeee' : '',
              }}
            >
              <div
                style={{ display: 'flex', alignItems: 'center', width: '5%' }}
              >
                <input
                  type='checkbox'
                  value={ele.checked}
                  onChange={() => this.checkOne(ele.id)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '60%',
                  justifyContent: 'flex-start',
                }}
              >
                <p style={{ margin: 0 }}>
                  {ele.name}-{ele.mask}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '35%',
                  justifyContent: 'flex-end',
                  marginRight: 1,
                }}
              >
                {/* <p style={{margin:0}}>${ele.balance.toFixed(2)}</p> */}
                <p style={{ margin: 0 }}>${this.addCommas(ele.balance)}</p>
              </div>
            </div>
          )
        })
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>{arr}</div>
        )
      }
    }
  }

  submitQuestionaire = () => {
    this.setState({ submitClicked: true })
    let dob = this.state.dob.split('-')

    // console.log('submittings Questionnaire !!')
    fetch(`${ENDPOINT}/mobile/dw/quiz`, {
      method: 'POST',
      headers: {
        // "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
      body: JSON.stringify({
        birthdayDay: dob[2],
        birthdayMonth: dob[1],
        birthdayYear: dob[0],
        investmentMainReason: this.state.reason,
        investmentPeriod: this.state.timeframe,
        riskTolerance: this.state.risk,
        investmentExperience: this.state.experience,
        investmentObjectives: null,
      }),
    })
      .then((response) => response.json())
      .then((respJSON) => {
        if (respJSON.success && this.state.product === 'group') {
          this.setState({ step: 0.2 })
        } else if (respJSON.success) {
          this.setState({ step: 2 })
        } else {
          console.log('missing field')
        }
      })
  }

  needQuestionnaire = () => {
    let url = `${ENDPOINT}/mobile/questionnaire-status`
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        console.log('q check resp:', responseJson)
        if (responseJson.Status === 'Complete') {
          this.setState({ needQuestionnaire: false })
        }
      })
  }

  questions = () => {
    if (!this.state.needQuestionnaire && this.state.product === 'group') {
      this.setState({ step: 0.2 })
    } else if (!this.state.needQuestionnaire) {
      this.setState({ step: 2 })
    } else {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ width: '95%', margin: 'auto', marginBottom: 25 }}>
            <h4 style={{ color: 'rgb(17,17,17)' }}>Investment Questionnaire</h4>
          </div>
          <div style={{ width: '95%', margin: 'auto' }}>
            <div
              style={{
                backgroundColor: 'rgb(255,255,255)',
                borderRadius: 8,
                marginBottom: 50,
                padding: 30,
              }}
            >
              <p style={{ fontSize: 20 }}>What is your date of birth?</p>
              <input
                type='date'
                value={this.state.dob}
                onChange={(e) => {
                  this.setState({ dob: e.target.value })
                }}
              />
            </div>
            <div
              style={{
                backgroundColor: 'rgb(255,255,255)',
                borderRadius: 8,
                marginBottom: 50,
                padding: 30,
              }}
            >
              <p style={{ fontSize: 20 }}>
                What is the main reason for your investment?
              </p>
              <input
                type='radio'
                id='SHORT_TERM'
                name='reason'
                checked={this.state.reason === 'SHORT_TERM'}
                onChange={() => this.setState({ reason: 'SHORT_TERM' })}
              />
              <label style={{ marginLeft: 5 }}>
                Short Term (Emergency Cash Fund)
              </label>
              <br />
              <input
                type='radio'
                id='MEDIUM_TERM'
                name='reason'
                checked={this.state.reason === 'MEDIUM_TERM'}
                onChange={() => this.setState({ reason: 'MEDIUM_TERM' })}
              />
              <label style={{ marginLeft: 5 }}>
                Medium Term (Down Payment)
              </label>
              <br />
              <input
                type='radio'
                id='LONG_TERM'
                name='reason'
                checked={this.state.reason === 'LONG_TERM'}
                onChange={() => this.setState({ reason: 'LONG_TERM' })}
              />
              <label style={{ marginLeft: 5 }}>
                Long Term (College or Retirement Fund)
              </label>
            </div>
            <div
              style={{
                backgroundColor: 'rgb(255,255,255)',
                borderRadius: 8,
                marginBottom: 50,
                padding: 30,
              }}
            >
              <p style={{ fontSize: 20 }}>
                What is your prior investment experience?
              </p>
              <input
                type='radio'
                id='NONE'
                name='experience'
                checked={this.state.experience === 'NONE'}
                onChange={() => this.setState({ experience: 'NONE' })}
              />
              <label style={{ marginLeft: 5 }}>No experience</label>
              <br />
              <input
                type='radio'
                id='SOME'
                name='experience'
                checked={this.state.experience === 'SOME'}
                onChange={() => this.setState({ experience: 'SOME' })}
              />
              <label style={{ marginLeft: 5 }}>Some experience</label>
              <br />
              <input
                type='radio'
                id='CONSIDERABLE'
                name='experience'
                checked={this.state.experience === 'CONSIDERABLE'}
                onChange={() => this.setState({ experience: 'CONSIDERABLE' })}
              />
              <label style={{ marginLeft: 5 }}>Considerable experience</label>
            </div>
            <div
              style={{
                backgroundColor: 'rgb(255,255,255)',
                borderRadius: 8,
                marginBottom: 50,
                padding: 30,
              }}
            >
              <p style={{ fontSize: 20 }}>What is your risk tolerance?</p>
              <input
                type='radio'
                id='LOW'
                name='risk'
                checked={this.state.risk === 'LOW'}
                onChange={() => this.setState({ risk: 'LOW' })}
              />
              <label style={{ marginLeft: 5 }}>Minimize Loses</label>
              <br />
              <input
                type='radio'
                id='MEDIUM'
                name='risk'
                checked={this.state.risk === 'MEDIUM'}
                onChange={() => this.setState({ risk: 'MEDIUM' })}
              />
              <label style={{ marginLeft: 5 }}>
                Maintain gains while limiting losses
              </label>
              <br />
              <input
                type='radio'
                id='GAINS'
                name='risk'
                checked={this.state.risk === 'GAINS'}
                onChange={() => this.setState({ risk: 'GAINS' })}
              />
              <label style={{ marginLeft: 5 }}>Maximize Gains</label>
            </div>
            {/* <div style={{backgroundColor:'rgb(255,255,255)', borderRadius:8, marginBottom:50, padding:30}}>
                             <p style={{fontSize:20}}>How do you plan on using this for?</p>
                             <input type="radio" id="LONG_TERM" name="investmentObjectives" checked={this.state.investmentObjectives === "LONG_TERM"} onChange={()=>this.setState({investmentObjectives:'LONG_TERM'})}/>
                             <label style={{marginLeft: 5,}}>Long Term Investing</label>
                             <input type="radio" id="INFREQUENT" name="investmentObjectives" checked={this.state.investmentObjectives === "INFREQUENT"} onChange={()=>this.setState({investmentObjectives:'INFREQUENT'})}/>
                             <label style={{marginLeft: 5,}}>Infrequent Investing</label><br/>
                             <input type="radio" id="FREQUENT" name="investmentObjectives"checked={this.state.investmentObjectives === "FREQUENT"} onChange={()=>this.setState({investmentObjectives:'FREQUENT'})}/>
                             <label style={{marginLeft: 5,}}>Regular Trading</label><br/>
                             <input type="radio" id="ACTIVE_DAILY" name="investmentObjectives" checked={this.state.investmentObjectives === "ACTIVE_DAILY"} onChange={()=>this.setState({investmentObjectives:'ACTIVE_DAILY'})}/>
                             <label style={{marginLeft: 5,}}>Day Trading</label><br/>
                             <input type="radio" id="NEW" name="investmentObjectives" checked={this.state.investmentObjectives === "NEW"} onChange={()=>this.setState({investmentObjectives:'NEW'})}/>
                             <label style={{marginLeft: 5,}}>10 years or More</label><br/>
                         </div> */}
            <div
              style={{
                backgroundColor: 'rgb(255,255,255)',
                borderRadius: 8,
                marginBottom: 50,
                padding: 30,
              }}
            >
              <p style={{ fontSize: 20 }}>How long do you plan on investing?</p>
              <input
                type='radio'
                id='1'
                name='timeframe'
                checked={this.state.timeframe === '1'}
                onChange={() => this.setState({ timeframe: '1' })}
              />
              <label style={{ marginLeft: 5 }}>Less than 1 Year</label>
              <br />
              <input
                type='radio'
                id='3'
                name='timeframe'
                checked={this.state.timeframe === '3'}
                onChange={() => this.setState({ timeframe: '3' })}
              />
              <label style={{ marginLeft: 5 }}>3 years</label>
              <br />
              <input
                type='radio'
                id='5'
                name='timeframe'
                checked={this.state.timeframe === '5'}
                onChange={() => this.setState({ timeframe: '5' })}
              />
              <label style={{ marginLeft: 5 }}>5 years</label>
              <br />
              <input
                type='radio'
                id='10'
                name='timeframe'
                checked={this.state.timeframe === '10'}
                onChange={() => this.setState({ timeframe: '10' })}
              />
              <label style={{ marginLeft: 5 }}>10 years or More</label>
              <br />
            </div>

            <div
              style={{
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 7,
                  paddingBottom: 7,
                  borderRadius: 8,
                  border: 'solid',
                  borderColor: '#3b8e8c',
                  borderWidth: '.25px',
                  fontSize: 'smaller',
                  minWidth: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  this.submitQuestionaire()
                }}
              >
                {this.state.submitClicked ? (
                  <Spinner
                    animation='border'
                    id='spinner'
                    variant='dark'
                    role='status'
                  >
                    <span className='sr-only'>Loading...</span>
                  </Spinner>
                ) : (
                  <p
                    style={{
                      color: '#3b8e8c',
                      margin: 0,
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}
                  >
                    Submit
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  whichAccount = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'rgb(255,255,255)',
          padding: 20,
          borderRadius: 12,
          marginBottom: 25,
        }}
      >
        <div style={{ width: '95%', margin: 'auto', marginBottom: 25 }}>
          {/*                     
                        <img
                            src={}
                        /> 
                    */}
          <h3 style={{ color: 'rgb(17,17,17)', textAlign: 'center' }}>
            Invest
          </h3>
          <h5 style={{ color: 'rgb(17,17,17)', textAlign: 'center' }}>
            Leverage the power of a RoboAdvisor
          </h5>
        </div>
        <hr></hr>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ width: '47%' }}>
            <p style={{ fontSize: 18 }}>
              {' '}
              • Answer 5 questions, and choose which account you want to connect
              to
            </p>
            <p style={{ fontSize: 18 }}>
              {' '}
              • Our AI will decide which of our strategies is the best fit for
              you and your goals
            </p>
            <p style={{ fontSize: 18 }}>
              {' '}
              • Our strategies are liquid and diverse making your money easily
              accessible and spread across every sector
            </p>
            <p style={{ fontSize: 18, margin: 0 }}>
              {' '}
              • Each strategy adjusts monthly to keep you positioned for success
              no matter what is happening in the market
            </p>
          </div>

          <div style={{ width: '47%' }}>
            <div style={{ width: '100%', margin: 'auto' }}>
              <p>Your Complete Financial Picture</p>
            </div>
            <div>
              <RoiChart
                strategy={this.state.strategy}
                initial={this.state.initial}
                reccuring={this.state.reccuring}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '80%',
                margin: 'auto',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>
                  <p style={{ margin: 0 }}>Strategy</p>
                  <select
                    onChange={(e) =>
                      this.setState({ strategy: e.target.value })
                    }
                    value={this.state.strategy}
                  >
                    <option
                      value='U.S. Domestic Composite'
                      selected={
                        this.state.strategy === 'U.S. Domestic Composite'
                      }
                    >
                      {'U.S. Domestic Composite'}
                    </option>
                    <option
                      value='Diversified International Composite'
                      selected={
                        this.state.strategy ===
                        'Diversified International Composite'
                      }
                    >
                      {'Diversified International Composite'}
                    </option>
                    {/* <option value="Global Dynamic Blend" selected={this.state.strategy === "Global Dynamic Blend"}>{"Global Dynamic Blend"}</option> */}
                  </select>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                  }}
                >
                  <p style={{ margin: 0, textAlign: 'right' }}>
                    Initial Investment (USD)
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    $
                    <input
                      type='text'
                      placeholder='xxxxx'
                      value={this.state.initial}
                      onChange={(e) =>
                        this.setState({ initial: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: 10 }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <p style={{ margin: 0 }}>Frequency</p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                      }}
                    >
                      <input
                        style={{ marginRight: 5 }}
                        type='radio'
                        value='Monthly'
                        checked={this.state.frequency === 'Monthly'}
                        onClick={() => this.setState({ frequency: 'Monthly' })}
                      />
                      <p style={{ margin: 0, marginRight: 12 }}>Monthly</p>
                      <input
                        style={{ marginRight: 5 }}
                        type='radio'
                        value='Annually'
                        checked={this.state.frequency === 'Annually'}
                        onClick={() => this.setState({ frequency: 'Annually' })}
                      />
                      <p style={{ margin: 0, marginRight: 12 }}>Annually</p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      flexDirection: 'column',
                    }}
                  >
                    <p style={{ margin: 0 }}>Recurring Deposit Amount (USD)</p>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      $
                      <input
                        type='text'
                        placeholder='xxxxx'
                        value={this.state.reccuring}
                        onChange={(e) =>
                          this.setState({ reccuring: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 25,
          }}
        >
          <div
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 7,
              paddingBottom: 7,
              borderRadius: 8,
              border: 'solid',
              borderColor: '#3b8e8c',
              borderWidth: '.25px',
              fontSize: 'smaller',
              minWidth: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              this.setState({ step: 0.1 })
            }}
          >
            <p
              style={{
                color: '#3b8e8c',
                margin: 0,
                fontWeight: 'bold',
                fontSize: 18,
              }}
            >
              Start Investing
            </p>
          </div>
        </div>
      </div>
    )
  }

  closeSure = () => {
    this.setState({ sureCheck: [] })
  }

  selectStocks = (selectedArr) => {
    console.log('selecting:', selectedArr)
    this.setState({ step: 2, selected: selectedArr })
  }

  changeTg = (event, location) => {
    this.setState({ [location]: event.target.value })
  }

  backFromCreateDWacc = () => {
    this.setState({ step: 2 })
  }

  whatStep = () => {
    switch (this.state.step) {
      case -1:
        return this.accountType()
      case 0:
        return this.whichAccount()
      case 0.1:
        return this.questions()
      case 0.2:
        return (
          <div>
            <TradeGroup
              groupAmount={this.state.groupAmount}
              groupName={this.state.groupName}
              changeTg={this.changeTg}
              selectStocks={this.selectStocks}
            />
          </div>
        )
      case 2:
        return this.portfolioOptions()
      case 3.1:
        return this.useLinked()
      case 3.2:
        return this.linkModal()
      case 3.3:
        return (
          <div>
            <CreateDwAcc
              goBack={this.backFromCreateDWacc}
              product={this.state.product}
              accountPlusProduct={this.accountPlusProduct}
              user={this.props.user}
              nextStep={() => this.setState({ step: (this.state.step += 1) })}
            />
          </div>
        )
      case 3.2:
        return this.linkModal()
      default:
        console.log('error step set to: ', this.state.step)
        return (
          <div>
            <p>ERROR</p>
          </div>
        )
    }
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        {this.whatStep()}
        {Object.keys(this.state.sureCheck).length === 4 ? (
          <AreYouSure
            product={this.state.product}
            info={this.state.sureCheck}
            confirm={this.accountPlusProduct}
            selected={this.state.selected}
            closeModal={this.closeSure}
          />
        ) : null}
        {this.subModal()}
      </div>
    )
  }
}
