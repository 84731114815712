import React, { useEffect, useState } from 'react'
import { ConfirmDialog } from 'primereact/confirmdialog'
import Axios from 'axios'
import Endpoint from '../../Endpoint'
import { usePlaidLink } from 'react-plaid-link'
import { Dialog } from 'primereact/dialog'
export default function RelinkPlaid(props) {
  const [plusDropdown, setPlusDropdown] = useState(false)
  const [messagePopUp, setMessagePopUp] = useState(false)
  const [errorsSucceses, setErrorsSucceses] = useState('')
  const [responseStatus, setResponseStatus] = useState(null)
  const [plaidToken, setPlaidToken] = useState('')
  const [realReady, setRealReady] = useState(false)

  const { open, ready } = usePlaidLink({
    token: plaidToken,
    onSuccess: (public_token, metadata) => {
      linkPlaidAccount(public_token, metadata)
    },
  })

  useEffect(() => {
    if (ready && realReady) {
      open()
    }
  }, [open, ready])

  const plaidHandleOnSuccess = (public_token, metadata) => {
    let accountId = metadata.account_id
    let itemId = metadata.institution.institution_id
    linkPlaidAccount(public_token, metadata)
  }
  const linkPlaidAccount = (token, meta) => {
    setRealReady(false)
    let id = meta.account_id
    let mask = meta.account.mask
    let name = meta.account.name
    let subType = meta.account.subtype
    let accounts = meta.accounts

    let inst_id = meta.institution.institution_id
    let inst_name = meta.institution.name
    let public_token = token

    let body = {
      // accounts: [
      //   {
      //     id,
      //     mask,
      //     name,
      //     subType,
      //     type: "depository",
      //   },
      // ],
      accounts: accounts,
      inst_id,
      relinking: true,
      inst_name,
      public_token,
      relink_item_id: props.selectedRelinkAcc?.itemId,
      type: 'depository',
    }
    fetch(`${Endpoint}/mobile-plaid/plaid/link`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + props.user?.idToken?.jwtToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((respJSON) => {
        if (respJSON.Error === 'Successful Linked') {
          props.getAllAccountInfo()
          setErrorsSucceses('Your bank account has been successfully linked')
          setResponseStatus(true)
          setMessagePopUp(true)
        } else {
          setErrorsSucceses(respJSON.Error)
          setResponseStatus(false)
          setMessagePopUp(true)
        }
      })
      .catch((err) => console.log('catching: ', err))
  }

  const toggleMessagePopUp = () => {
    setMessagePopUp(!messagePopUp)
  }
  const initiatePlaidLink = async () => {
    setRealReady(true)
    await Axios.get(
      `${Endpoint}/plaid-new/plaid/linktoken/create?link=bank&app=react`,
      {
        headers: {
          Authorization: 'Bearer ' + props.user?.idToken?.jwtToken,
        },
      }
    )
      .then((res) => {
        if (res?.data?.link_token) {
          setPlaidToken(res.data.link_token)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  if (props.t) {
    return (
      <div>
        {' '}
        <ConfirmDialog
          visible={props.showRelinkDialog}
          onHide={() => props.toggleRelinkDialog()}
          // message='This can occur when you change your account credentials for security purposes when your account has been inactive for an extended period of time.'
          message={`${props.t('your')} ${
            props.selectedRelinkAcc?.inst
          } ${props.t('accounthasbeenunlinked')}. ${props.t(
            'relinktocontinue'
          )}`}
          header={props.t('thisaccountneedstoberelinked')}
          style={{ width: props.isSmallScreen ? '80vw' : '50vw' }}
          accept={initiatePlaidLink}
          acceptLabel={'Relink'}
          rejectLabel={'Cancel'}
          // reject={reject}
        />
        <Dialog
          visible={messagePopUp}
          onHide={toggleMessagePopUp}
          header={errorsSucceses}
          style={{ width: props.isSmallScreen ? '80vh' : '30vw' }}
        ></Dialog>
      </div>
    )
  } else return null
}
