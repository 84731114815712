import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Amplify, { Auth } from 'aws-amplify'
import awsExports from '../../aws-config'
import Footer from '../PublicFooter'
import axios from 'axios'
import { Dropdown } from 'primereact/dropdown'

import LandingPageBlob from '../../assets/newLandingPage/landingPageBlob.svg'
import AnalyticsTable from '../components/AnalyticsTable'

import ProductsPanel from '../components/ProductsPanel'
import { IoIosArrowRoundForward } from 'react-icons/io'
import ProductsTabs from '../components/ProductsTabs'
import MainNavbar from '../components/MainNavBar'

import GooglePlay from '../../assets/newUI/googlePlay.png'
import Appstore from '../../assets/newUI/appStore.svg'
import i18n from 'i18next'
import MyLoader from '../MyLoader'
import { connect } from 'react-redux'
import { handleAppRedirect } from '../../utility-functions/handleAppRedirect'
import { setUsername } from '../../store/slice/NavbarSlice'
import SearchEndpoint from '../SearchEndpoint'

Amplify.configure(awsExports)

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    overflow: 'auto',
    borderRadius: '5px',
    border: 'none',
    boxShadow: '0px 4px 23px rgba(0, 0, 0, 0.08)',
  },
}

const graphOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        display: false,
      },
    ],
    yAxes: [
      {
        display: false,
      },
    ],
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  onHover: (evt) => {
    console.log(evt)
  },
}

const numOfStocks = 6

class LandingPage2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: false,
      dropdown: false,
      isSmallScreen: false,
      graphData: [],
      initAmount: 500,
      contributionAmount: 1000,
      liveStockData: {},
      mostActiveData: [],
      toggleStockInfo: new Array(numOfStocks).fill(false),
      selectedStock: 0,
      openPanel: false,
      currentProduct: 0,
      language: i18n.language === 'zh' ? 'zh' : 'eng',
    }
    this.topOfPage = React.createRef()
    this.graphRef = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions)
    this.updateWindowDimensions()
    this.getAnalysis()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  getAnalysis = async () => {
    console.log('stock info')
    // await axios
    //   .get("https://api.allocaterite.com/search/ticker-bulk-live?search=AAPL")
    //   .then((res) => {
    //     this.setState({ liveStockData: res.data.content[0].AAPL });
    //   });
    let res2 = await axios.get(`${SearchEndpoint}/ticker-active`)
    let allStocksURL = `${SearchEndpoint}/ticker-bulk-live`
    let mostActive = res2.data.lists.slice(0, numOfStocks)
    // let symbols = mostActive.map((stock) => stock.symbol)
    const symbols = ['AAPL', 'AMZN', 'GOOG', 'NVDA', 'TSLA', 'WMT']
    console.log('most active', symbols)
    let bulkRes = await axios.get(
      `${SearchEndpoint}/ticker-bulk-live?search=${symbols}`
    )
    this.setState({
      mostActiveData: bulkRes.data.content_list,
    })
    // let filteredData = bulkRes.data.content_list
    // console.log(filteredData)
    // Promise.all(
    //   mostActive.map(async (stock) => {
    //     let res = await axios.get(
    //       `https://api.allocaterite.com/search/ticker-bulk-live?search=${stock.symbol}`
    //     );
    //     return res.data.content[0][stock.symbol];
    //   })
    // ).then((res) => {
    //   console.log('most active res', res)
    //   this.setState({
    //     mostActiveData: res,
    //   });
    // });
  }

  bearOrBull = (stat) => {
    switch (stat) {
      case 'rsi':
        if (parseFloat(this.state.liveStockData.rsi_9_value) < 20.0) {
          return 'Bearish'
        } else if (parseFloat(this.state.liveStockData.rsi_9_value) > 80.0) {
          return 'Bullish'
        } else {
          return 'Neutral'
        }
      case 'ema':
        if (this.state.liveStockData.ema_3 < this.state.liveStockData.ema_10) {
          return 'Bearish'
        } else if (
          this.state.liveStockData.ema_3 > this.state.liveStockData.ema_10
        ) {
          return 'Bullish'
        } else {
          return 'Neutral'
        }
      case 'cmf':
        if (this.state.liveStockData.cmf_21 < -0.1) return 'Bearish'
        else if (this.state.liveStockData.cmf_21 > 0.1) return 'Bullish'
        else return 'Neutral'
      default:
        return 'Neutral'
    }
  }

  forecastChange = (price, forecast) => {
    return (
      ((parseFloat(forecast) - parseFloat(price)) / parseFloat(price)) *
      100
    ).toFixed(2)
  }

  handleScrollIntoView = () => {
    this.analyticsScrollRef &&
      this.analyticsScrollRef.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
    console.log(this.analyticsScrollRef)
  }

  renderSmall() {
    return (
      <div>
        <MainNavbar {...this.props} colorChange={true} />
        {/* NEW LANDING PAGE */}
        <div className='hero__container__mobile'>
          <div className='top__container'>
            <div className='bold-text primary-color-text get-started'>
              {this.props.t('getstarted')}
              <IoIosArrowRoundForward className='arrow' />
            </div>
            <p className='title__text'>{this.props.t('landingpagetitle')}</p>
            <div className='subText__container'>
              <p>{this.props.t('landingpageinfo')}</p>
              <input
                onChange={(e) => {
                  e.preventDefault()
                  // this.mainNavbarRef.current.setState({
                  //   username: e.target.value,
                  // })
                  this.props.setUsername(e.target.value)
                }}
                value={this.props.username}
                type='text'
                placeholder={this.props.t('email')}
              />
              <NavLink to={'/signup'} exact={true} style={{ width: '100%' }}>
                <div
                  className='long-button'
                  // onClick={() => this.props.setSignUpModalOpen(true)}
                >
                  {this.props.t('signup')}
                </div>
              </NavLink>
            </div>
          </div>
        </div>

        <div className='top-stocks-cryptos'>
          <p className='title-text bold-text'>
            {this.props.t('investallforecast')}
          </p>
          <div className='wrapper-container'>
            <AnalyticsTable data={this.state.mostActiveData} t={this.props.t} />
          </div>
        </div>

        <div className='get-the-app'>
          <p style={{ fontSize: 18 }}>{this.props.t('findouttheshortterm')}</p>
          <div className='default__button' onClick={() => handleAppRedirect()}>
            {this.props.t('gettheapp')}
          </div>
        </div>

        {/* <div className="features__container__mobile">
            <div className="text__container">
              <p className="primary-color-text bold-text large-text">
                Features
              </p>
              <p className="title-text">Upgrade to fully automated tools.</p>
            </div>
            <div
              onClick={() =>
                this.setState({
                  openPanel: !this.state.openPanel,
                  currentProduct: 0,
                })
              }
              className="featureBox"
            >
              <div className="image__container">
                <img src={Robo} alt="Robo" />
              </div>
              <div className="info__container">
                <p className="subtitle__text">INVEST ROBO</p>
                <p className="small__text">
                  Our AI will automate your investments by choosing the right
                  strategy for you that fits your risk profile. GIPS audited
                  track record of outperforming other top robo-advisors.
                </p>
              </div>
            </div>
            <div
              onClick={() =>
                this.setState({
                  openPanel: !this.state.openPanel,
                  currentProduct: 1,
                })
              }
              className="featureBox"
            >
              <div className="image__container">
                <img src={PriceTrend} alt="Price_Trend" />
              </div>
              <div className="info__container">
                <p className="subtitle__text">AI TRADER</p>
                <p className="small__text">
                  Leverage AI-powered automated trading, just like the hedge
                  funds use. You pick your stocks, up to four in each trade
                  group.
                </p>
              </div>
            </div>
            <div
              onClick={() =>
                this.setState({
                  openPanel: !this.state.openPanel,
                  currentProduct: 2,
                })
              }
              className="featureBox"
            >
              <div className="image__container">
                <img src={RiskIcon} alt="Risk" />
              </div>
              <div className="info__container">
                <p className="subtitle__text">GOALS</p>
                <p className="small__text">
                  Tools to help you set and reach you financial goals. Save for
                  your next mortgage, vacation or emergency fund with ease.
                </p>
              </div>
            </div>
            <div
              onClick={() =>
                this.setState({
                  openPanel: !this.state.openPanel,
                  currentProduct: 3,
                })
              }
              className="featureBox"
            >
              <div className="image__container">
                <img src={Planner} alt="Planner" />
              </div>
              <div className="info__container">
                <p className="subtitle__text">BUDGETING</p>
                <p className="small__text">
                  Aggregate all of your financial accounts into one place. The
                  more accounts you link, the more our AI can help you assess
                  your risk, spending habits, and more.
                </p>
              </div>
            </div>
          </div> */}

        <div className='features-background-container'>
          <div className='products-mobile-container'>
            <p className='product-title-mobile bold-text'>
              {this.props.t('Invest Robo')}
            </p>
            <p className='product-description-mobile'>
              {this.props.t('roboinfo')}
            </p>
            <NavLink to='/products-info' className='learn-more-mobile'>
              <p>{this.props.t('learnmore')}</p> <IoIosArrowRoundForward />
            </NavLink>
          </div>
          <div className='products-mobile-container'>
            <p className='product-title-mobile bold-text'>
              {this.props.t('AI Trader')}
            </p>
            <p className='product-description-mobile'>
              {this.props.t('aitraderinfo')}
            </p>
            <NavLink to='/products-info' className='learn-more-mobile'>
              <p>{this.props.t('learnmore')}</p> <IoIosArrowRoundForward />
            </NavLink>
          </div>
          <div className='products-mobile-container'>
            <p className='product-title-mobile bold-text'>
              {this.props.t('goals')}
            </p>
            <p className='product-description-mobile'>
              {this.props.t('goalsinfo')}
            </p>
            <NavLink to='/products-info' className='learn-more-mobile'>
              <p>{this.props.t('learnmore')}</p> <IoIosArrowRoundForward />
            </NavLink>
          </div>
          <div className='products-mobile-container'>
            <p className='product-title-mobile bold-text'>
              {this.props.t('Budgeting')}
            </p>
            <p className='product-description-mobile'>
              {this.props.t('budgetinginfo')}
            </p>
            <NavLink to='/products-info' className='learn-more-mobile'>
              <p>{this.props.t('learnmore')}</p> <IoIosArrowRoundForward />
            </NavLink>
          </div>
          <NavLink to='/products-info'>
            <div
              className='default__button purple'
              style={{ marginBottom: '50px' }}
            >
              <p className='bold-text'>{this.props.t('productdetails')}</p>
            </div>
          </NavLink>
        </div>

        {this.state.openPanel && (
          <ProductsPanel
            openPanel={this.state.openPanel}
            togglePanel={() =>
              this.setState({ openPanel: !this.state.openPanel })
            }
            currentProduct={this.state.currentProduct}
            setCurrentProduct={(x) => this.setState({ currentProduct: x })}
          />
        )}

        {/* <div className="largePhoneDisplay__container">
            <div className="text__container">
              <p className="subtitle__text">Budgeting</p>
              <p className="title__text">Smart Wealth Management</p>
            </div>
            <div className="image__container">
              <img src={HeroPhone} alt="" />
            </div>
          </div> */}
        <Footer t={this.props.t} isSmallScreen={this.state.isSmallScreen} />
      </div>
    )
  }

  renderChangeLanguage = () => {
    return (
      <div className='language-toggle-container'>
        <p className='language-header'>{this.props.t('language')}</p>
        <Dropdown
          value={this.state.language}
          options={[
            { label: 'En', value: 'eng' },
            { label: '中文', value: 'zh' },
          ]}
          onChange={(e) => this.changeLanguage(e.value)}
        />
      </div>
    )
  }

  changeLanguage = (value) => {
    if (i18n.language === 'zh') {
      i18n.changeLanguage('en')
    } else {
      i18n.changeLanguage('zh')
    }
    // this.setState({
    //   language: value,
    // })
  }

  renderBig() {
    return (
      <div className='landingPageMain__container'>
        {/* New Navbar */}
        <MainNavbar {...this.props} colorChange={true} />
        {/* New Hero Landing Page */}
        <div className='hero__container__background'>
          <div className='hero__container__2'>
            <div className='left__container'>
              <p className='hero-title'>{this.props.t('landingpagetitle')}</p>
              <div className='subText__container'>
                <p className='hero-sub-text'>
                  {this.props.t('landingpageinfo')}
                </p>
              </div>
              <div className='signup__container'>
                <NavLink
                  to={'/signup'}
                  exact={true}
                  style={{ textDecoration: 'none' }}
                >
                  <div className='default__button'>
                    <p className='bold-text'>{this.props.t('signup')}</p>
                  </div>
                </NavLink>
              </div>
            </div>
            <div className='right__container'>
              <img src={LandingPageBlob} alt='Blob' />
            </div>
          </div>
        </div>

        {/* Analytics */}

        <div className='top-stocks-cryptos'>
          <p className='title-text bold-text'>
            {this.props.t('investallforecast')}
          </p>
          <div className='wrapper-container'>
            <AnalyticsTable
              data={this.state.mostActiveData}
              handleScroll={this.handleScrollIntoView}
              t={this.props.t}
            />
          </div>
          {/* TRANSLATE LINE BELOW */}
          <p className='table-disclaimer-text'>
            *Investall's forecasts are only predictions and do not represent
            actual returns.
          </p>
        </div>

        <div className='get-the-app'>
          <p style={{ fontSize: 24 }}>{this.props.t('findouttheshortterm')}</p>
          <div
          // className='default__button'
          // onClick={() => this.handleAppRedirect()}
          >
            {/* {this.props.t('gettheapp')} */}
          </div>
          {/* <div
            style={{
              display: 'flex',
              margin: '15px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <a
              href='https://apps.apple.com/us/app/forescite/id1567589436'
              target='_blank'
            >
              <img
                target
                style={{
                  cursor: 'pointer',
                }}
                src={Appstore}
              />
            </a>
            <a
              href='https://play.google.com/store/apps/details?id=com.forescite'
              target='_blank'
            >
              <img
                style={{
                  cursor: 'pointer',
                  height: '60px',
                }}
                src={GooglePlay}
              />
            </a>
          </div> */}
        </div>

        {/* New Features Section */}
        {/* <div className="features__container__background">
          <div className="features__container">
            <div className="text__container">
              <p className="bold-text primary-color-text large-text">
                Features
              </p>
              <p className="title-text">Upgrade to fully automated tools.</p>
            </div>
            <div className="boxes__container">
              <div
                onClick={() =>
                  this.setState({
                    openPanel: !this.state.openPanel,
                    currentProduct: 0,
                  })
                }
                className="featureBox"
              >
                <div className="image__container">
                  <img src={Robo} alt="Robo" />
                </div>
                <div className="info__container">
                  <p className="bold-text">INVEST ROBO</p>
                  <p className="small__text">
                    Our AI will automate your investments by choosing the right
                    strategy for you that fits your risk profile. GIPS audited
                    track record of outperforming other top robo-advisors.
                  </p>
                </div>
              </div>
              <div
                onClick={() =>
                  this.setState({
                    openPanel: !this.state.openPanel,
                    currentProduct: 1,
                  })
                }
                className="featureBox"
              >
                <div className="image__container">
                  <img src={PriceTrend} alt="Price_Trend" />
                </div>
                <div className="info__container">
                  <p className="bold-text">AI TRADER</p>
                  <p className="small__text">
                    Leverage AI-powered automated trading, just like the hedge
                    funds use. You pick your stocks, up to four in each trade
                    group.
                  </p>
                </div>
              </div>
              <div
                onClick={() =>
                  this.setState({
                    openPanel: !this.state.openPanel,
                    currentProduct: 2,
                  })
                }
                className="featureBox"
              >
                <div className="image__container">
                  <img src={RiskIcon} alt="Risk" />
                </div>
                <div className="info__container">
                  <p className="bold-text">GOALS</p>
                  <p className="small__text">
                    Tools to help you set and reach you financial goals. Save
                    for your next mortgage, vacation or emergency fund with
                    ease.
                  </p>
                </div>
              </div>
              <div
                onClick={() =>
                  this.setState({
                    openPanel: !this.state.openPanel,
                    currentProduct: 3,
                  })
                }
                className="featureBox"
              >
                <div className="image__container">
                  <img src={Planner} alt="Planner" />
                </div>
                <div className="info__container">
                  <p className="bold-text">BUDGETING</p>
                  <p className="small__text">
                    Aggregate all of your financial accounts into one place. The
                    more accounts you link, the more our AI can help you assess
                    your risk, spending habits, and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className='features-background-container'>
          <div className='features-main-container'>
            <p className='small-title bold-text'>{this.props.t('features')}</p>
            <p className='large-title'>
              {this.props.t('automatedinvestingmadeeasy')}
            </p>
            <div className='products-wrapper-container'>
              <ProductsTabs t={this.props.t} />
            </div>
          </div>
          {/* <div className='maintenance-fee-container'>
            <p className='maintenance-fee'>$3.99 maintenance fee</p>
          </div> */}
        </div>

        {this.state.openPanel && (
          <ProductsPanel
            openPanel={this.state.openPanel}
            togglePanel={() =>
              this.setState({ openPanel: !this.state.openPanel })
            }
            currentProduct={this.state.currentProduct}
            setCurrentProduct={(x) => this.setState({ currentProduct: x })}
            t={this.props.t}
          />
        )}
        {/* <div className='maintenance-fee-container'>
          <p className='maintenance-fee'>$3.99 maintenance fee</p>
        </div> */}

        <Footer isSmallScreen={this.state.isSmallScreen} t={this.props.t} />
      </div>
    )
  }

  render() {
    if (this.props.t) {
      return this.state.isSmallScreen ? this.renderSmall() : this.renderBig()
    } else return null
  }
}

const mapStateToProps = (state) => {
  return {
    signUpModalOpen: state.navbar.signUpModalOpen,
    username: state.navbar.username,
  }
}

const mapDispatchToProps = { setUsername }

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage2)
