import React from 'react'
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import Youtube from 'react-youtube'

const videos={
    // "getting-started":{
    //     source:'5HAeOY_NGYcg',
    //     title:'Getting Started',
        
    // },
    "price-trend-predictor-1":{
        source: 'W6CeiROGzK0',
        title:'Price Trend Predictor #1',
       
    },
    "price-trend-predictor-2":{
        source: '8KNaR9o-Qik',
        title:'Price Trend Predictor #2',
       
    },
    "alerts":{
        source:'OHBOBLMERHo',
        title: 'Alerts'
    },
    "wealth-management": {
        source: 'im7bIZDPNyk',
        title: 'Wealth Management',
        
     },
    "risk-monkey":{
        source:'uzm1s_ac_pg',
        title:'Risk Monkey',
        
    },
    
    
}
const brandColor = "#00A99D"

export default class VideoBay extends React.Component{

    state={
        showVideo: "price-trend-predictor-1"
        
    }

    componentDidMount(){
        this.updateWindowDimensions()
    }

    updateWindowDimensions = () => {
        if (window.innerWidth < 1000) {
          this.setState({ isSmallScreen: true, screenWidth: window.innerWidth });
        } else {
          this.setState({ isSmallScreen: false, screenWidth: window.innerWidth });
        }
      };
    
    render(){
        let mapped = Object.keys(videos).map(ele=><div style={{width:this.state.isSmallScreen?'100%':190 ,padding:this.state.isSmallScreen? 2:7, display:'flex', alignItems:'center', justifyContent:'center', marginBottom: 5, cursor:'pointer', backgroundColor: this.state.showVideo !== ele ? 'rgb(240,240,240)':brandColor , borderRadius:5}} onClick={()=>this.setState({showVideo:ele})}><p style={{margin:0}}>{videos[ele].title}</p></div>)
        return(
            <div>
                <Header user={this.props.user} signOut={this.props.signOut}/>
                <div
                    style={{
                        paddingTop: 100,
                        height: '100%'
                    }}
                >
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginBottom: 20}}>
                        <p style={{fontSize:'2em'}}>AllocateRite Videos</p>
                    </div>
                    <div style={{width: '100%', display:'flex',justifyContent:'space-around',alignItems:'center', flexDirection:this.state.isSmallScreen? 'column':'row'}}>
                        <div style={{width:!this.state.showVideo || this.state.isSmallScreen?'100%':'40%',display:'flex',justifyContent:'center',alignItems:'center',}}>
                            <div style={{display:'flex', flexDirection:'column', width:'50%', justifyContent:'center', alignItems:'center'}}>
                                {mapped}
                            </div>
                        </div>
                        {this.state.showVideo?
                        <div style={{width:'60%', display:'flex',justifyContent:'center',alignItems:'center', height:'100%', minHeight: 240}}>
                        <Youtube
                            videoId={videos[this.state.showVideo].source}// defaults -> null
                            // id={string}                       // defaults -> null
                            // className={string}                // defaults -> null
                            // containerClassName={string}       // defaults -> ''
                            opts={{ height: 315, width: 560 }}        // defaults -> {}
                            // onReady={func}                    // defaults -> noop
                            // onPlay={func}                     // defaults -> noop
                            // onPause={func}                    // defaults -> noop
                            onEnd={()=>{
                                if(this.state.showVideo === 'price-trend-predictor-1'){this.setState({showVideo:'price-trend-predictor-2'})}
                                else if(this.state.showVideo === 'price-trend-predictor-2'){this.setState({showVideo:'alerts'})}
                                else if(this.state.showVideo === 'wealth-management'){this.setState({showVideo:'risk-monkey'})}
                                else if(this.state.showVideo === 'alerts'){this.setState({showVideo:'wealth-management'})}
                                else if(this.state.showVideo === 'risk-monkey'){this.setState({showVideo:false})}
                                else{this.setState({showVideo:false})}
                            }}                                   // defaults -> noop
                            // onError={func}                    // defaults -> noop
                            // onStateChange={func}              // defaults -> noop
                            // onPlaybackRateChange={func}       // defaults -> noop
                            // onPlaybackQualityChange={func}    // defaults -> noop
                        />
                        </div>
                        :
                        null}
                    </div>
                    <div>
                        <Footer/>
                    </div>
                </div>
                
            </div>
        )
    }
}