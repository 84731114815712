import React, { Component } from 'react'
import { Button } from 'primereact/button'
import InvestmentForm from './InvestmentForm'
import Summary from './Summary'
import Intro from './Intro'
import Loader from '../../Loader'
import ProductActivationBackIcon from '../../../../../assets/product-activation-back-icon.svg'
export default class RoboActivation extends Component {
  state = {
    //Keeps record of previous components
    pageHistory: [],
    startingAmount: 0,
    contributionAmount: 0,
    roundUp: false,
    selectedBankAccount: null,
  }

  addHistory = (page) => {
    let pageHistory = this.state.pageHistory
    pageHistory.push(page)
    this.setState({
      pageHistory: pageHistory,
    })
  }

  goPrevious = () => {
    let pageHistory = this.state.pageHistory
    pageHistory.pop()
    this.setState({
      pageHistory: pageHistory,
    })
  }

  updateInvestmentDetails = (
    startingAmount,
    contributionAmount,
    roundUp,
    selectedBankAccount
  ) => {
    this.setState(
      {
        startingAmount: startingAmount,
        contributionAmount: contributionAmount,
        roundUp: roundUp,
        selectedBankAccount: selectedBankAccount,
      },
      () => {
        this.addHistory('Summary')
      }
    )
  }

  showCurrentPage = () => {
    switch (this.state.pageHistory[this.state.pageHistory.length - 1]) {
      case 'Investment-Form':
        return (
          <InvestmentForm
            {...this.props}
            updateInvestmentDetails={this.updateInvestmentDetails}
          />
        )
      case 'Summary':
        return (
          <Summary
            {...this.props}
            {...this.state}
            toggleActivation={this.props.toggleActivation}
          />
        )
      default:
        // return <Intro {...this.props} addHistory={this.addHistory} />
        return (
          <InvestmentForm
            {...this.props}
            updateInvestmentDetails={this.updateInvestmentDetails}
          />
        )
    }
  }

  render() {
    if (this.props.t) {
      return (
        <div>
          {/* <Button
          label="CANCEL ACTIVATION"
          onClick={() => {
            this.props.toggleActivation();
          }}
        />
        {this.state.pageHistory.length > 0 ? (
          <Button
            label="PREVIOUS"
            onClick={() => {
              this.goPrevious();
            }}
          />
        ) : null} */}
          <img
            src={ProductActivationBackIcon}
            height={25}
            width={25}
            onClick={() => {
              if (this.state.pageHistory.length > 0) {
                this.goPrevious()
              } else {
                this.props.toggleActivation()
              }
              if (this.props.selectedName === 'ROBO') {
                this.props.showButtons()
              }
              // this.props.toggleShowRoboStrategyInfo()
            }}
            style={{ cursor: 'pointer', marginTop: '25px' }}
            alt=''
          />
          {this.props.productInstanceData && this.props.strategyInfo ? (
            this.showCurrentPage()
          ) : (
            <Loader
              isSmallScreen={this.props.isSmallScreen}
              logoLoader={false}
            />
          )}
        </div>
      )
    } else return null
  }
}
