import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import BaseURL from '../Endpoint'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import Loader from '../components/accountInfo/Loader'
import { TabMenu } from 'primereact/tabmenu'
import { useHistory } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import '../../scss/pages/cp3/StockOptions.scss'
import { SelectButton } from 'primereact/selectbutton'
import { Skeleton } from 'primereact/skeleton'
import { InputNumber } from 'primereact/inputnumber'
import { Toast } from 'primereact/toast'

const MoreOptionInfoModal = ({
  setVisible,
  visible,
  selectedOptionChain,
  user,
  filterOptions,
  ibStatus,
  stockSource,
  acctInfo,
  restartInterval,
  stopInterval,
  ...props
}) => {
  const [moreData, setMoreData] = useState(null)
  const [dataLoading, setDataLoading] = useState(true)
  const [tradeStep, setTradeStep] = useState(0)
  const [price, setPrice] = useState(
    filterOptions.primary === 'Buy'
      ? selectedOptionChain.ask_price
      : selectedOptionChain.bid_price
  )
  const [numberOfContracts, setNumberOfContracts] = useState(null)
  const [currentStockPrice, setCurrentStockPrice] = useState(null)
  const [maxSupply, setMaxSupply] = useState(0)
  const [processingTrade, setProcessingTrade] = useState(false)
  const [receipt, setReceipt] = useState(null)
  const [modalHeaderName, setModalHeaderName] = useState('')

  const toast = useRef(null)

  useEffect(() => {
    stopInterval()
    setCurrentStockPrice(Math.round(stockSource?.price * 100) / 100)
    getNumberOfContracts()
    fetchOptionMoreData()
  }, [])

  // dynamically set the modal title base on the current trade step
  useEffect(() => {
    if (tradeStep === 0) {
      setModalHeaderName('Option Statistics')
    } else if (tradeStep === 1) {
      if (filterOptions.primary === 'Buy') {
        setModalHeaderName('Buy Option')
      } else {
        setModalHeaderName('Sell Option')
      }
    } else if (tradeStep === 2) {
      setModalHeaderName('Order Review')
    } else {
      setModalHeaderName('Order Submitted')
    }
  }, [tradeStep])

  // get more data about that single option chain
  const fetchOptionMoreData = async () => {
    try {
      let res = await axios.get(
        `${BaseURL}/ibkr/options-data?id=${selectedOptionChain?.conid}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user?.idToken?.jwtToken,
          },
        }
      )
      setMoreData(res.data)
      setDataLoading(false)
    } catch (error) {
      console.log(error)
      setDataLoading(false)
    }
  }

  const executeTradeOptions = async () => {
    try {
      setProcessingTrade(true)

      let body = {
        acctId: acctInfo.accountId,
        orderType: 'LMT', // always limit for option
        side: filterOptions?.primary?.toUpperCase(),
        ticker: stockSource?.actual_ticker,
        conid: selectedOptionChain?.conid,
        secType: 'OPT', // option  type
        quantity: Number(numberOfContracts)?.toString(),
        price: Number(price),
      }

      console.log(body)

      let res = await axios.post(
        `${BaseURL}/ibkr/execute-trade`,
        JSON.stringify(body),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user?.idToken?.jwtToken,
          },
        }
      )
      setReceipt(res.data)
      setProcessingTrade(false)
      setTradeStep((prev) => prev + 1)
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Order Failed',
        detail: 'Please resubmit again later',
      })

      console.log(error)

      setProcessingTrade(false)
    }
  }

  // trade information component
  const InfoScreen = () => {
    return (
      <>
        <div className='info-title'>{selectedOptionChain.description}</div>

        <div className='info-content'>
          <p>
            Bid Price <span>${moreData?.bid_price || '--'}</span>
          </p>
          <p>
            Ask Price <span>${moreData?.ask_price || '--'}</span>
          </p>
          <p>
            Last Price <span>${moreData?.price || '--'}</span>
          </p>
          <p>
            Implied Volatility{' '}
            <span>{moreData?.implied_volatility || '--'}</span>
          </p>
          <p>
            Volume <span>{moreData?.volume || '--'}</span>
          </p>
        </div>

        <div className='info-title'>The Greeks</div>

        <div className='info-content'>
          <p>
            Delta <span>{moreData?.delta || '--'}</span>
          </p>
          <p>
            Gamma <span>{moreData?.gamma || '--'}</span>
          </p>
          <p>
            Theta <span>{moreData?.theta || '--'}</span>
          </p>
          <p>
            Vega <span>{moreData?.vega || '--'}</span>
          </p>
        </div>
      </>
    )
  }

  // trading title component
  const TradeScreen = () => {
    return (
      <>
        {!processingTrade && (
          <i
            className='pi pi-chevron-left back-arrow'
            style={{ fontSize: '2rem' }}
            onClick={() => setTradeStep((prev) => prev - 1)}
          >
            Back
          </i>
        )}
        <div className='trade-subheader'>
          <span>{filterOptions.primary}</span>
          <span>{selectedOptionChain.description}</span>
          <span>
            {filterOptions.primary === 'Buy'
              ? `$${selectedOptionChain.ask_price}`
              : `$${selectedOptionChain.bid_price}`}
          </span>
        </div>

        {/* dynamically rendered the content */}
        {tradeStep === 1 ? <EnterForm /> : <TradeConfirmation />}
      </>
    )
  }

  // get the number of contract for the specific option
  const getNumberOfContracts = () => {
    let holdings = acctInfo?.holdings

    let filterHoldings = holdings?.filter((hold) => {
      return hold.conid === selectedOptionChain.conid
    })

    if (filterHoldings?.length > 0) {
      setMaxSupply(filterHoldings[0].quantity)
    }
  }

  // buy/sell order input fields
  const EnterForm = () => {
    return (
      <>
        <div className='buying-info'>
          <p>
            Stock Price: <span>{`$${currentStockPrice}`}</span>
          </p>
          <p>
            Buying Power <span>{`$${acctInfo?.balances[0]?.buyingPower}`}</span>
          </p>
          {filterOptions.primary === 'Sell' && (
            <p>
              Max contracts to sell: <span>{maxSupply}</span>
            </p>
          )}
        </div>

        <div className='input-info-container'>
          <div className='single-input'>
            <span> Limit Price</span>

            <InputNumber
              inputId='currency-us'
              value={price}
              onValueChange={(e) => setPrice(e.value)}
              mode='currency'
              currency='USD'
              locale='en-US'
            />
          </div>

          <div className='single-input'>
            <span>No. of Contracts</span>

            {filterOptions.primary === 'Buy' && (
              <InputNumber
                value={numberOfContracts}
                onValueChange={(e) => setNumberOfContracts(e.value)}
                min={0}
              />
            )}

            {filterOptions.primary === 'Sell' && (
              <InputNumber
                value={numberOfContracts}
                onValueChange={(e) => setNumberOfContracts(e.value)}
                min={0}
                max={maxSupply}
              />
            )}
          </div>
        </div>

        <div className='total-result-container'>
          <span>
            {filterOptions.primary === 'Buy' ? 'Total Cost' : 'Est. Credit'}
          </span>
          <span>{`$${(price * numberOfContracts * 100).toFixed(2)}`}</span>
        </div>
      </>
    )
  }

  // confirmation screen page
  const TradeConfirmation = () => {
    return (
      <>
        <div className='trade-confirmation-container'>
          <p>
            Market Price <span>{`$${currentStockPrice}`}</span>
          </p>
          <p>
            Number of Contracts <span>{numberOfContracts}</span>
          </p>
          <p>
            Order Type <span>LIMIT</span>
          </p>
          <p>
            Limit Price <span>{`$${price}`}</span>
          </p>
          <p>
            Total Amount(approx.){' '}
            <span>{`$${(price * numberOfContracts * 100).toFixed(2)}`}</span>
          </p>
        </div>
      </>
    )
  }

  // order submitted receipt screen
  const TradeOrderSubmitScreen = () => {
    return (
      <div className='submitted-container'>
        <div className='submitted-stock-title'>
          <p>{receipt?.contract_description_1}</p>
          <p>{stockSource?.company}</p>
        </div>
        <div className='submitted-info-detail'>
          <p>
            Order Description <span>{receipt?.contract_description_2}</span>
          </p>
          <p>
            Order Status <span>Submitted</span>
          </p>
          <p>
            Order Type <span>{receipt?.order_type}</span>
          </p>
          <p>
            Order Quantity <span>{numberOfContracts}</span>
          </p>
          <p>
            Price <span>{`$${price}`}</span>
          </p>
          <p>
            Total Amount{' '}
            <span>{`$${(price * numberOfContracts * 100).toFixed(2)}`}</span>
          </p>
        </div>
      </div>
    )
  }

  // check if the "EnterForm" modal that all input fields are filled or not
  const isAllFilled = () => {
    if (price && numberOfContracts) {
      return true
    } else {
      return false
    }
  }

  // checking user buying power is able to buy the stock
  const checkBuyingPower = () => {
    let optionPrice = (price * numberOfContracts * 100).toFixed(2)

    if (optionPrice > acctInfo?.balances[0]?.buyingPower) {
      toast.current.show({
        severity: 'error',
        summary: 'Insufficient Buying Power',
        detail: 'You have insufficient funds to complete this transaction.',
      })
      return false
    }
    return true
  }

  // footer component for the modal
  const footerContent = (
    <div className='footer-button'>
      {tradeStep === 0 && (
        <Button
          label={filterOptions.primary === 'Buy' ? 'Buy' : 'Sell'}
          onClick={() => setTradeStep((prev) => prev + 1)}
          autoFocus
          className='step-button'
        />
      )}
      {tradeStep === 1 && (
        <Button
          label={'Preview Order'}
          disabled={!isAllFilled()}
          onClick={() => {
            if (filterOptions.primary === 'Buy') {
              if (checkBuyingPower()) {
                setTradeStep((prev) => prev + 1)
              }
            } else {
              setTradeStep((prev) => prev + 1)
            }
          }}
          autoFocus
          className='step-button'
        />
      )}
      {tradeStep === 2 && (
        <Button
          label={processingTrade ? 'Processing' : 'Place Order'}
          icon={processingTrade ? 'pi pi-spin pi-spinner' : ''}
          onClick={async () => {
            await executeTradeOptions()
          }}
          autoFocus
          className='step-button submit'
        />
      )}
    </div>
  )

  // skeleton structure when the data is loading and not yet finished
  const SkeletonLayout = () => {
    return (
      <>
        <div>
          <Skeleton className='mb-2'></Skeleton>
          <Skeleton className='mb-2'></Skeleton>
          <Skeleton className='mb-2'></Skeleton>
          <Skeleton className='mb-2'></Skeleton>
          <Skeleton className='mb-2'></Skeleton>
        </div>

        <div>
          <Skeleton className='mb-2'></Skeleton>
        </div>

        <div>
          <Skeleton className='mb-2'></Skeleton>
          <Skeleton className='mb-2'></Skeleton>
          <Skeleton className='mb-2'></Skeleton>
          <Skeleton className='mb-2'></Skeleton>
        </div>
      </>
    )
  }

  return (
    <Dialog
      header={modalHeaderName}
      visible={visible}
      style={{ width: '40vw' }}
      onHide={() => {
        setVisible(false)
        restartInterval()
      }}
      footer={ibStatus && footerContent}
      breakpoints={{ '1000px': '75vw' }}
    >
      {/* rendered the content of modal based on the current step of user */}
      <div className='trading-container'>
        {dataLoading ? (
          <SkeletonLayout />
        ) : tradeStep === 0 ? (
          <InfoScreen />
        ) : tradeStep <= 2 ? (
          <TradeScreen />
        ) : (
          <TradeOrderSubmitScreen />
        )}
      </div>
      <Toast ref={toast} position='top-center' />
    </Dialog>
  )
}

const StockOptions = ({
  stockData,
  user,
  stockSymbol,
  ibStatus,
  stockSource,
  allAccounts,
  restartInterval,
  stopInterval,
  ...props
}) => {
  const [optionDates, setOptionDates] = useState([])

  const [optionsChain, setOptionsChain] = useState()

  const [dateLoading, setDateLoading] = useState(true)
  const [optionsChainLoading, setOptionsChainLoading] = useState(true)
  const [activeDateIndex, setActiveDateIndex] = useState(null)
  const [visible, setVisible] = useState(false)
  const [selectedOptionChain, setSelectedOptionChain] = useState(null)

  const [filterOptions, setFilterOptions] = useState({
    primary: 'Buy',
    secondary: 'Call', // call type
  })

  const [acctInfo, setAcctInfo] = useState(null)

  const primaryOptions = ['Buy', 'Sell']
  const secondaryOptions = ['Call', 'Put']
  const history = useHistory()

  const [currentStockPrice, setCurrentStockPrice] = useState(null)

  useEffect(() => {
    fetchCorrectAcctInfo()
    fetchOptionDates()
  }, [])

  // whenever the filter option changed or index change then call the option api again
  useEffect(() => {
    if (activeDateIndex !== null) {
      fetchSingleDateInfo()
    }
  }, [activeDateIndex, filterOptions])

  // get option dates for the ticker
  const fetchOptionDates = async () => {
    try {
      let res = await axios.get(
        `${BaseURL}/search/options-dates?ticker=${stockSymbol}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user?.idToken?.jwtToken,
          },
        }
      )
      // make it to object so it is easy to show in a dynamic tabs
      const dateLabelObjects = res.data.dates.map((str) => ({
        label: str,
        icon: 'pi pi-calendar',
      }))

      setOptionDates(dateLabelObjects)
      setActiveDateIndex(0)
      setDateLoading(false)
    } catch (error) {
      console.log(error)
      setDateLoading(false)
    }
  }

  // fetch the single date options chain information
  const fetchSingleDateInfo = async () => {
    try {
      let res = await axios.get(
        `${BaseURL}/search/options-chain?ticker=${stockSymbol}&date=${optionDates[activeDateIndex].label}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + user?.idToken?.jwtToken,
          },
        }
      )
      let data = res.data.output

      let type = filterOptions.secondary === 'Call' ? 'C' : 'P'

      // add the current share stock
      setCurrentStockPrice(Math.round(stockSource?.price * 100) / 100)

      data.push({
        strike: stockSource?.price
          ? Math.round(stockSource?.price * 100) / 100
          : '--',
        type: 'P',
      })
      data.push({
        strike: stockSource?.price
          ? Math.round(stockSource?.price * 100) / 100
          : '--',
        type: 'C',
      })

      // sort the alway based on the type of options
      const filteredData = data.filter((opt) => opt.type === type)
      filteredData.sort((a, b) => b.strike - a.strike)

      setOptionsChain(filteredData)
      setOptionsChainLoading(false)
    } catch (error) {
      console.log(error)
      setOptionsChainLoading(false)
    }
  }

  // fetch the correct account from the user for trading
  const fetchCorrectAcctInfo = () => {
    let acct = allAccounts.filter((acct) => {
      return (
        acct.inst === 'InteractiveBrokers' && acct.product_assigned === 'trade'
      )
    })
    setAcctInfo(acct[0])
  }

  // filter out the table based on the options selected
  const handleFilterOptions = (newData) => {
    setFilterOptions((prevData) => ({ ...prevData, ...newData }))
  }

  return (
    <div className='options-container'>
      {/* fetch the date loading */}
      {dateLoading ? (
        <Loader logoLoader={true} />
      ) : optionDates?.length > 0 ? (
        <>
          <TabMenu
            model={optionDates}
            activeIndex={activeDateIndex}
            onTabChange={(e) => setActiveDateIndex(e.index)}
          />

          <div className='selection-container'>
            {ibStatus && (
              <SelectButton
                value={filterOptions.primary}
                onChange={(e) => handleFilterOptions({ primary: e.value })}
                options={primaryOptions}
              />
            )}
            <SelectButton
              value={filterOptions.secondary}
              onChange={(e) => handleFilterOptions({ secondary: e.value })}
              options={secondaryOptions}
            />
          </div>

          {optionsChainLoading ? (
            <Loader logoLoader={true} />
          ) : (
            <>
              {/* dates options */}
              <DataTable
                value={optionsChain}
                // paginator={
                //   optionsChain?.length > 0 && optionsChain?.length !== 10
                // }
                scrollable
                // rows={10}
                onRowClick={(singleInfo) => {
                  console.log(singleInfo)

                  if (singleInfo.data.strike !== currentStockPrice) {
                    setSelectedOptionChain(singleInfo.data)
                    setVisible(true)
                  }
                }}
              >
                <Column
                  field='strike'
                  body={(rowData) => (
                    <div
                      className={`${
                        rowData.strike === currentStockPrice
                          ? 'current-price-row-header'
                          : ''
                      }`}
                    >
                      {rowData.strike === 'N/A' || !rowData.strike
                        ? '--'
                        : rowData.strike === currentStockPrice
                        ? `Current Price: $${rowData.strike}`
                        : `$${rowData.strike}`}
                    </div>
                  )}
                  header='Strike'
                  sortable
                ></Column>
                <Column
                  field='bid_price'
                  body={(rowData) => (
                    <div
                      className={`${
                        rowData.strike === currentStockPrice
                          ? 'current-price-row'
                          : ''
                      }`}
                    >
                      {rowData.bid_price === 'N/A' || !rowData.bid_price
                        ? '--'
                        : `$${rowData.bid_price}`}
                    </div>
                  )}
                  header='Bid'
                  sortable
                ></Column>
                <Column
                  field='ask_price'
                  body={(rowData) => (
                    <div
                      className={`${
                        rowData.strike === currentStockPrice
                          ? 'current-price-row'
                          : ''
                      }`}
                    >
                      {rowData.ask_price === 'N/A' || !rowData.ask_price
                        ? '--'
                        : `$${rowData.ask_price}`}
                    </div>
                  )}
                  header='Ask'
                  sortable
                ></Column>
                <Column
                  field='volume'
                  body={(rowData) => (
                    <div
                      className={`${
                        rowData.strike === currentStockPrice
                          ? 'current-price-row'
                          : ''
                      }`}
                    >
                      {rowData.volume === 'N/A' || !rowData.volume
                        ? '--'
                        : rowData.volume}
                    </div>
                  )}
                  header='Volume'
                  sortable
                ></Column>
                <Column
                  field='price'
                  body={(rowData) => (
                    <div
                      className={`last-price-container ${
                        rowData.strike === currentStockPrice
                          ? 'current-price-row'
                          : ''
                      }`}
                    >
                      {rowData.price ? (
                        <>
                          <span>{`$${
                            rowData.price
                              ? rowData.price.replace(/[A-Za-z]/g, '')
                              : '--'
                          }`}</span>
                          <span
                            className={
                              rowData?.priceChangeValue?.[0] === '-'
                                ? 'decrease'
                                : 'increase'
                            }
                          >
                            {`${rowData?.priceChangeValue || '--'}%`}
                          </span>
                        </>
                      ) : (
                        '--'
                      )}
                    </div>
                  )}
                  header='Last'
                  sortable
                ></Column>

                <Column
                  field={
                    filterOptions.primary === 'Buy' ? 'ask_price' : 'bid_price'
                  }
                  body={(rowData) => (
                    <div
                      className={`${
                        rowData.strike === currentStockPrice
                          ? 'current-price-row'
                          : ''
                      }`}
                    >
                      <Button
                        label={
                          filterOptions.primary === 'Buy'
                            ? `$${rowData?.ask_price || '--'}`
                            : `$${rowData?.bid_price || '--'}`
                        }
                        icon='pi pi-plus
'
                        iconPos='right'
                        onClick={() => {
                          setSelectedOptionChain(rowData)
                          setVisible(true)
                        }}
                      />
                    </div>
                  )}
                  header={
                    filterOptions.primary === 'Buy' ? 'Ask Price' : 'Bid Price'
                  }
                  sortable
                  style={{ width: 'max-content' }}
                ></Column>
              </DataTable>
            </>
          )}
        </>
      ) : (
        'No Data Available'
      )}

      {visible && (
        <MoreOptionInfoModal
          setVisible={setVisible}
          visible={visible}
          selectedOptionChain={selectedOptionChain}
          user={user}
          ibStatus={ibStatus}
          filterOptions={filterOptions}
          stockSource={stockSource}
          acctInfo={acctInfo}
          restartInterval={restartInterval}
          stopInterval={stopInterval}
        />
      )}
    </div>
  )
}

export default StockOptions
