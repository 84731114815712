import React from 'react'
import LinkToPlaid from '../LinkToPlaid'
import ENDPOINT from '../Endpoint'

const brandColor = '#00A99D'

export default class myStrats extends React.Component {
  state = {
    customPorts: this.props.customPorts,
    selectedStrat: '',
    portHoldings: [],
    showLinkModal: false,
    selectedInst: '',
    sameInst: {},
  }

  componentDidMount() {
    // this.getPlaidAccounts()
    this.getPlaidHoldings()
  }

  findOtherPortfoliosSameInstitution = (instName) => {
    console.log('looking for others')
    let itemId = this.getItemId(instName)
    fetch(
      `${ENDPOINT}/plaid/accounts?unconnectedAccounts=true&itemId=${itemId}`,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((res) => res.json())
      .then((respJSON) => {
        console.log('respJSON: ', respJSON)
        this.setState({
          sameInst: {
            ...this.state.sameInst,
            [this.state.selectedInst]: respJSON,
          },
        })
      })
      .catch((err) => console.log('catching: ', err))
  }

  getItemId = (instName) => {
    let id = ''
    this.props.linkedPorts.forEach((ele) => {
      if (ele.instName === instName) {
        id = ele.itemId
      }
    })
    return id
  }

  addOtherPortSameInstituion = () => {
    let getbody = []
    this.state.sameInst[this.state.selectedInst].forEach((ele) => {
      if (ele.checked) {
        getbody.push({
          id: ele.itemId,
          itemId: this.getItemId(),
          mask: ele.mask,
          name: ele.name,
        })
      }
    })
    fetch(`${ENDPOINT}/plaid/accounts/add`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(getbody),
    })
      .then((res) => res.json())
      .then((responseJson) => console.log(responseJson))
      .catch((err) => console.log(`catching errors`, err))
  }

  getPlaidHoldings = () => {
    // all holdings of all linked accounts combined
    fetch(`${ENDPOINT}/plaid/holdings`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => this.setState({ holdings: responseJson }))
      .catch((err) => console.log(`catching errors`, err))
  }

  checkOne = (id) => {
    let instName = this.state.selectedInst
    let updated = this.state.sameInst[instName].map((ele) => {
      if (ele.id === id) {
        return { ...ele, checked: !ele.checked }
      } else {
        return ele
      }
    })
    let sameInst = this.state.sameInst
    sameInst[instName] = updated
    this.setState({ sameInst })
  }

  addCommas(flt) {
    return flt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  showOthers = (name) => {
    let arr = []
    if (name === this.state.selectedInst) {
      if (this.state.sameInst[this.state.selectedInst]) {
        this.state.sameInst[this.state.selectedInst].forEach((ele) => {
          arr.push(
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderTop: 'solid',
                borderWidth: 0.25,
                borderColor: '#ddd',
                backgroundColor: ele.checked ? '#eeeeee' : '',
              }}
            >
              <div
                style={{ display: 'flex', alignItems: 'center', width: '5%' }}
              >
                <input
                  type='checkbox'
                  value={ele.checked}
                  onChange={() => this.checkOne(ele.id)}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '60%',
                  justifyContent: 'flex-start',
                }}
              >
                <p style={{ margin: 0 }}>
                  {ele.name}-{ele.mask}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '35%',
                  justifyContent: 'flex-end',
                  marginRight: 1,
                }}
              >
                {/* <p style={{margin:0}}>${ele.balance.toFixed(2)}</p> */}
                <p style={{ margin: 0 }}>${this.addCommas(ele.balance)}</p>
              </div>
            </div>
          )
        })
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>{arr}</div>
        )
      }
    }
  }

  linkChecked = () => {
    let linkXmore = 0
    if (this.state.sameInst[this.state.selectedInst]) {
      this.state.sameInst[this.state.selectedInst].forEach((ele) => {
        if (ele.checked) {
          linkXmore += 1
        }
      })
    }
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgb(226,238,227)',
            borderRadius: 8,
            padding: 10,
            cursor: 'pointer',
          }}
          onClick={() => {
            if (linkXmore > 0) {
              // console.log('this.state.massAddTable', this.state.massAddTable)
              this.addOtherPortSameInstituion()
              this.setState({ showLinkModal: false })
            }
          }}
        >
          <p style={{ margin: 0, color: 'rgb(51,173,53)', fontWeight: 'bold' }}>
            {linkXmore > 0
              ? `Link ${linkXmore} portfolio${linkXmore === 1 ? '' : 's'}`
              : 'Select Accounts Above'}
          </p>
        </div>
      </div>
    )
  }

  linkModal = () => {
    if (this.state.showLinkModal) {
      let arr = []
      this.props.linkedPorts.forEach((ele) => {
        arr.push(
          <div
            style={{
              width: '100%',
              padding: 4,
              borderTop: 'solid',
              borderWidth: 0.5,
              borderColor: '#ddd',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (!this.state.sameInst[ele.instName]) {
                  this.findOtherPortfoliosSameInstitution(ele.instName)
                }
                if (this.state.selectedInst !== ele.instName) {
                  this.setState({ selectedInst: ele.instName })
                } else {
                  this.setState({ selectedInst: '' })
                }
              }}
            >
              {ele.instName}
            </div>
            <div>{this.showOthers(ele.instName)}</div>
          </div>
        )
      })
      return (
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            position: 'fixed',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'auto',
            zIndex: 99999,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: 'auto',
              backgroundColor: 'rgb(255,255,255)',
              borderRadius: 10,
              padding: 40,
              minWidth: 500,
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  backgroundColor: 'rgb(253,234,234)',
                  borderRadius: 10,
                  width: 40,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => this.setState({ showLinkModal: false })}
              >
                <p
                  style={{
                    margin: 0,
                    fontWeight: 'bold',
                    color: 'rgb(187,58,60)',
                  }}
                >
                  X
                </p>
              </div>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <h5>Link</h5>
            </div>
            <div
              style={{
                display: 'flex',
                margin: 0,
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ margin: 'auto' }}>Another account from:</p>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                border: 'solid',
                borderWidth: 0.25,
                borderColor: '#ddd',
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              {arr}
            </div>
            <div>{this.linkChecked()}</div>
            <div
              style={{
                display: 'flex',
                margin: 0,
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ margin: 'auto' }}>or</p>
            </div>
            <div
              style={{
                display: 'flex',
                margin: 0,
                flexDirection: 'column',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <LinkToPlaid
                user={this.props.user}
                numAccounts={this.props.linkedPorts.length}
              />
            </div>
          </div>
        </div>
      )
    }
  }

  showPortData = (title, data) => {
    let arr = []
    if (this.state.selectedStrat === title) {
      data.forEach((ele) => {
        arr.push(
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              border: 'solid',
              borderWidth: 0.25,
              borderColor: '#ddd',
            }}
          >
            <div
              style={{
                width: '50%',
                borderRight: 'solid',
                borderWidth: 0.25,
                borderColor: '#ddd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {Array.isArray(ele) ? (
                <a
                  style={{
                    textDecoration: 'none',
                    color: brandColor,
                    cursor: 'pointer',
                  }}
                  href={
                    ele.symbol === '$$' ? null : `/Dashboard/Trade/${ele[0]}`
                  }
                  target='_blank'
                >
                  {ele[0]}
                </a>
              ) : (
                <a
                  style={{
                    textDecoration: 'none',
                    color: brandColor,
                    cursor: 'pointer',
                  }}
                  href={
                    ele.symbol === '$$'
                      ? null
                      : `/Dashboard/Trade/${ele.symbol}`
                  }
                  target='_blank'
                >
                  {ele.symbol}
                </a>
              )}
            </div>
            <div
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {Array.isArray(ele) ? (
                <p style={{ margin: 0 }}>
                  {ele.symbol === '$$' ? parseFloat(ele[1]).toFixed(2) : ele[1]}
                </p>
              ) : (
                <p style={{ margin: 0 }}>
                  {ele.symbol === '$$'
                    ? parseFloat(ele.quantity).toFixed(2)
                    : ele.quantity}
                </p>
              )}
            </div>
          </div>
        )
      })
      return (
        <div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              border: 'solid',
              borderWidth: 0.25,
              borderColor: '#ddd',
            }}
          >
            <div
              style={{
                width: '50%',
                borderRight: 'solid',
                borderWidth: 0.25,
                borderColor: '#ddd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ margin: 0 }}>Symbol</p>
            </div>
            <div
              style={{
                width: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{ margin: 0 }}>Number of Shares</p>
            </div>
          </div>
          <div style={{ width: '100%', maxHeight: 200, overflowY: 'scroll' }}>
            <div>{arr}</div>
          </div>
        </div>
      )
    }
  }

  showOneCustom = (title, data) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: 'solid',
            borderWidth: 0.25,
            borderColor: '#ddd',
            height: '100%',
          }}
        >
          <div
            style={{ width: '80%', marginLeft: '10%', cursor: 'pointer' }}
            onClick={() =>
              this.setState({
                selectedStrat: this.state.selectedStrat === title ? '' : title,
              })
            }
          >
            <p style={{ margin: 0 }}>{title}</p>
          </div>
          <div
            style={{
              width: '20%',
              height: '100%',
              borderLeft: 'solid',
              borderWidth: 0.25,
              borderColor: '#ddd',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              backgroundColor: 'rgb(253,180,181)',
            }}
            onClick={() => this.deleteOneStrategy(title)}
          >
            <p style={{ margin: 0 }}>Delete</p>
          </div>
        </div>
        <div>{this.showPortData(title, data)}</div>
      </div>
    )
  }

  deleteOneStrategy = (id) => {
    fetch(`${ENDPOINT}/risk-monkey/portfolios/delete/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => console.log('responseJson:', responseJson))
      .then(() => {
        let strats = { ...this.state.customPorts }
        delete strats[id]
        this.setState({ customPorts: strats })
      })
  }

  getHoldings = (id) => {
    let arr = []
    this.state.holdings.forEach((ele) => {
      if (ele.accountId === id) {
        arr.push([ele.symbol, ele.quantity])
      }
    })
    this.setState({ selectedStrat: id, portHoldings: arr })
  }

  ids = () => {
    let arr = []
    this.props.linkedPorts.forEach((ele) => {
      arr.push([ele.instName, ele.itemId])
    })
    return arr
  }

  linked = () => {
    let arr = []
    if (this.props.linkedPorts) {
      arr.push(
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            border: 'solid',
            borderWidth: 0.25,
            borderColor: '#ddd',
          }}
          onClick={() => console.log(`clicked header`)}
        >
          <div
            style={{
              width: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRight: 'solid',
              borderWidth: 0.25,
              borderColor: '#ddd',
            }}
          >
            Institution
          </div>
          <div
            style={{
              width: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Account Number
          </div>
        </div>
      )
      this.props.linkedPorts.forEach((ele) => {
        ele.accounts.forEach((innerEle) => {
          console.log('innerEle', innerEle)
          arr.push(
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  border: 'solid',
                  borderWidth: 0.25,
                  borderColor: '#ddd',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (
                    this.state.selectedStrat !== innerEle.accountId &&
                    this.state.holdings
                  ) {
                    this.getHoldings(innerEle.accountId)
                  } else {
                    this.setState({ selectedStrat: '' })
                  }
                }}
              >
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRight: 'solid',
                    borderWidth: 0.25,
                    borderColor: '#ddd',
                    textAlign: 'center',
                  }}
                >
                  {ele.instName}
                </div>
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  {innerEle.name} - {innerEle.mask}
                </div>
              </div>
              <div>
                {this.showPortData(innerEle.accountId, this.state.portHoldings)}
              </div>
            </div>
          )
        })
      })
      return (
        <div>
          <div
            style={{
              minheight: 0,
              marginTop: 25,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              marginBottom: 10,
            }}
          >
            <div>
              <h4 style={{ color: brandColor }}>Your Linked Portfolios</h4>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 8,
              }}
            >
              <p style={{ margin: 0, marginRight: 4 }}>
                To track all your portfolios in one place{' '}
              </p>
              {this.props.linkedPorts.length > 0 ? (
                <p
                  onClick={() => this.setState({ showLinkModal: true })}
                  style={{
                    border: 'none',
                    margin: 0,
                    padding: 0,
                    color: brandColor,
                    fontWeight: 'bold',
                  }}
                >
                  link an account{' '}
                </p>
              ) : (
                <LinkToPlaid
                  user={this.props.user}
                  numAccounts={this.props.linkedPorts.length}
                />
              )}
            </div>
            <div style={{ width: '50%' }}>
              <div>{arr}</div>
            </div>
          </div>
        </div>
      )
    }
  }

  customized = () => {
    let arr = []
    Object.keys(this.state.customPorts).forEach((ele) =>
      arr.push(this.showOneCustom(ele, this.state.customPorts[ele]))
    )
    return (
      <div
        style={{
          minheight: 0,
          marginTop: 25,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          marginBottom: 100,
        }}
      >
        <div>
          <h4 style={{ color: brandColor }}>
            Your Customized Portfolio Strategies
          </h4>
        </div>
        <div>
          <p>
            Use our{' '}
            <strong
              style={{
                fontWeight: 'bold',
                color: brandColor,
                cursor: 'pointer',
              }}
              onClick={() => this.props.toPc()}
            >
              {' '}
              portfolio constructor
            </strong>{' '}
            to create a new portfolio, or optimize an existing portfolio
          </p>
        </div>
        {Object.keys(this.state.customPorts).length === 0 ? null : (
          <div
            style={{
              width: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              border: 'solid',
              borderWidth: 0.25,
              borderColor: '#ddd',
            }}
          >
            <p style={{ margin: 0, padding: 10 }}>
              Portfolio Name -- Select for more infomation
            </p>
          </div>
        )}
        <div style={{ width: '50%' }}>
          {this.state.customPorts ? <div style={{}}>{arr}</div> : null}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div>{this.linked()}</div>
        <div>{this.customized()}</div>
        {this.linkModal()}
      </div>
    )
  }
}
