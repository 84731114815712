import React, { Component } from 'react'
import '../../scss/components/AccountActivity.scss'
import OrderHistory from './accountInfo/OrderHistory'
import TransferHistory from './accountInfo/TransferHistory'
import { TabView, TabPanel } from 'primereact/tabview'
export default class AccountActivity extends Component {
  state = {
    activeTab: 0,
  }

  setActiveIndex = (index) => {
    this.setState({
      activeTab: index,
    })
  }
  render() {
    return (
      <div className='account-activity-container'>
        {' '}
        <TabView
          activeIndex={this.state.activeTab}
          onTabChange={(e) => this.setActiveIndex(e.index)}
        >
          <TabPanel header={this.props.t('orderhistory')}>
            <div className='order-history-container'>
              <OrderHistory {...this.props} />
            </div>
          </TabPanel>
          <TabPanel header={this.props.t('transferhistory')}>
            <div className='transfer-history-container'>
              <TransferHistory {...this.props} />
            </div>
          </TabPanel>
        </TabView>
      </div>
    )
  }
}
