import { configureStore } from '@reduxjs/toolkit'

import userReducer from './slice/UserSlice'
import navbarReducer from './slice/NavbarSlice'
import generalReducer from './slice/GeneralSlice'
import testlReducer from './slice/TestSlice'

const store = configureStore({
  reducer: {
    user: userReducer,
    navbar: navbarReducer,
    general: generalReducer,
    test: testlReducer,
  },
})

export default store
