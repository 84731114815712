import React, { Component } from "react";
import CommentaryYearList from "../CommentaryYearList";

class ChineseCalendarCommentary extends Component {
  state = {
    commentaiesYearList: [
      {
        commentaies: [
          {
            id: 17,
            url: "Docs/Monthly Bulletins/Commentary (Jul 2019)_2019-08-07.pdf",
            year: "2019",
            month: "七月"
          },
          {
            id: 18,
            url: "Docs/Monthly Bulletins/Commentary (Jun 2019)_2019-07-02.pdf",
            year: "2019",
            month: "六月"
          },
          {
            id: 16,
            url: "Docs/Monthly Bulletins/Commentary (May 2019)_2019-06-05.pdf",
            year: "2019",
            month: "五月"
          },
          {
            id: 20,
            url: "Docs/Monthly Bulletins/Commentary (Apr 2019)_2019-05-01.pdf",
            year: "2019",
            month: "四月"
          },
          {
            id: 23,
            title: "Commentary (Mar 19)",
            url: "Docs/Monthly Bulletins/Commentary (Mar 2019)_2019-04-03.pdf",
            year: "2019",
            month: "三月"
          },
          {
            id: 22,
            url: "Docs/Monthly Bulletins/Commentary (Feb 2019)_2019-03-06.pdf",
            year: "2019",
            month: "二月"
          },
          {
            id: 23,
            url: "Docs/Monthly Bulletins/Commentary (Jan 2019)_2019-02-06.pdf",
            year: "2019",
            month: "一月"
          }
        ]
      },
      {
        commentaies: [
          {
            id: 28,
            url: "Docs/Monthly Bulletins/Commentary (Dec 2018)_2019-01-02.pdf",
            year: "2018",
            month: "十二月"
          },
          {
            id: 27,
            url: "Docs/Monthly Bulletins/Commentary (Nov 2018)_2018-12-04.pdf",
            year: "2018",
            month: "十一月"
          },
          {
            id: 24,
            url: "Docs/Monthly Bulletins/Commentary (Oct 2018)_2018-11-07.pdf",
            year: "2018-11-07",
            month: "十月"
          },
          {
            id: 25,
            url: "Docs/Monthly Bulletins/Commentary (Sep 2018)_2018-09-05.pdf",
            year: "2018",
            month: "九月"
          },
          {
            id: 21,
            url: "Docs/Monthly Bulletins/Commentary (Aug 2018)_2018-08-01.pdf",
            year: "2018",
            month: "八月"
          },
          {
            id: 30,
            url: "Docs/Monthly Bulletins/Commentary (Jul 2018)_2018-07-20.pdf",
            year: "2018",
            month: "七月"
          },
          {
            id: 11,
            url: "Docs/Monthly Bulletins/Commentary (Jun 2018)_2018-07-05.pdf",
            year: "2018",
            month: "六月"
          },
          {
            id: 14,
            url: "Docs/Monthly Bulletins/Commentary (May 2018)_2018-05-02.pdf",
            year: "2018",
            month: "五月"
          },
          {
            id: 26,
            url: "Docs/Monthly Bulletins/Commentary (Apr 2018)_2018-04-04.pdf",
            year: "2018",
            month: "四月"
          },
          {
            id: 15,
            url: "Docs/Monthly Bulletins/Commentary (Mar 2018)_2018-03-07.pdf",
            year: "2018",
            month: "三月"
          },
          {
            id: 32,
            url: "Docs/Monthly Bulletins/Commentary (Feb 2018)_2018-02-07.pdf",
            year: "2018",
            month: "二月"
          },
          {
            id: 31,
            url: "Docs/Monthly Bulletins/Commentary (Jan 2018)_2018-01-03.pdf",
            year: "2018",
            month: "一月"
          }
        ]
      },
      {
        commentaies: [
          {
            id: 29,
            url: "Docs/Monthly Bulletins/Commentary (Dec 2017)_2017-12-06.pdf",
            year: "2017",
            month: "十二月"
          },
          {
            id: 35,
            url: "Docs/Monthly Bulletins/Commentary (Nov 2017)_2017-11-01.pdf",
            year: "2017",
            month: "十一月"
          },
          {
            id: 37,
            url: "Docs/Monthly Bulletins/Commentary (Oct 2017)_2017-10-42.pdf",
            year: "2017",
            month: "十月"
          },
          {
            id: 36,
            url: "Docs/Monthly Bulletins/Commentary (Aug 2017)_2017-08-02.pdf",
            year: "2017",
            month: "九月"
          },
          {
            id: 13,
            url: "Docs/Monthly Bulletins/Commentary (Sep 2017)_2017-09-06.pdf",
            year: "2017",
            month: "八月"
          },
          {
            id: 41,            url: "Docs/Monthly Bulletins/Commentary (Jul 2017)_2017-07-10.pdf",
            year: "2017",
            month: "七月"
          },
          {
            id: 40,
            url: "Docs/Monthly Bulletins/Commentary (Jun 2017)_2017-06-12.pdf",
            date: "2017",
            month: "六月"
          },
          {
            id: 33,
            url: "Docs/Monthly Bulletins/Commentary (May 2017)_2017-05-03.pdf",
            date: "2017",
            month: "五月"
          },
          {
            id: 12,
            url: "Docs/Monthly Bulletins/Commentary (Apr 2017)_2017-04-05.pdf",
            year: "2017",
            month: "四月"
          },
          {
            id: 42,
            url: "Docs/Monthly Bulletins/Commentary (Mar 2017)_2017-03-01.pdf",
            year: "2017",
            month: "三月"
          },
          {
            id: 38,
            url: "Docs/Monthly Bulletins/Commentary (Feb 2017)_2017-02-01.pdf",
            year: "2017",
            month: "二月"
          },
          {
            id: 39,
            url: "Docs/Monthly Bulletins/Commentary (Jan 2017)_2017-01-04.pdf",
            date: "2017",
            month: "一月"
          }
        ]
      }
    ]
  };

  render() {
    return (
      <div>
        {this.state.commentaiesYearList.map(yearList => (
          <CommentaryYearList
            key={yearList.commentaies[0].id}
            value={yearList.commentaies}
          />
        ))}
      </div>
    );
  }
}

export default ChineseCalendarCommentary;
