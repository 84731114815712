import React from 'react'
import GroupedDataContent from './GroupedDataContent'

export default function ObjectContent(props) {
  const handleObjectStatus = () => {
    switch (props.content_data.data.status) {
      case 'DONE':
        switch (props.content_data?.data?.display_type) {
          case 'GROUPED_DATA':
            return <GroupedDataContent data={props.content_data} />
          default:
            return null
        }
      default:
        return null
    }
  }

  return <div>{handleObjectStatus()}</div>
}
