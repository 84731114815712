const AWSConfig = {
    prod: {
        Auth: {
            userPoolId: "us-east-1_o7zSAII1v",
            region: "us-east-1",
            userPoolWebClientId: "2d1an4cgt8feims3uqljke0fe7"
        }
    },
    dev: {
        Auth: {
            userPoolId: "us-east-1_xeOsmvPU1",
            region: "us-east-1",
            userPoolWebClientId: "3g4jeiaqa5pvp168op31nj1kbj"
        }
    }
}

export default AWSConfig