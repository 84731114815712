import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

export default class UADisplay extends Component {
  state = {
    hover: false,
  }

  render() {
    return this.props.type === 'UAStocks' ? (
      <NavLink
        exact={true}
        to={`/Dashboard/Trade/${this.props.data.symbol}`}
        style={{
          display: 'flex',
          width: '100%',
          backgroundColor: this.state.hover
            ? 'rgb(220,220,220)'
            : 'transparent',
          textDecoration: 'none',
          height: 60,
          outline: 'none',
          borderBottom: 'solid .25px rgb(221, 221, 221)',
        }}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          {this.props.data.imageURL === 'not-found' ? (
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
              }}
            >
              <div
                style={{
                  objectFit: 'contain',
                  width: 50,
                  height: 50,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgb(17,17,17)',
                  borderRadius: 10,
                }}
              >
                <p
                  style={{
                    margin: 0,
                    fontSize: 24,
                    fontWeight: 'bold',
                    color: 'rgb(255,255,255)',
                  }}
                >
                  {this.props.data.symbol[0]}
                </p>
              </div>
              <p
                style={{
                  color: '#272748',
                  margin: 0,
                  marginRight: '5px',
                  textAlign: 'center',
                  fontSize: 19,
                  fontFamily: 'Akkurat-Bold',
                  marginLeft: '25px',
                }}
              >
                {this.props.data.symbol}
              </p>
            </div>
          ) : (
            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
              }}
            >
              <img
                style={{
                  height: 40,
                  width: 40,
                  objectFit: 'contain',
                  marginLeft: '0.5rem',
                }}
                src={this.props.data.imageURL}
              />
              <p
                style={{
                  color: '#272748',
                  margin: 0,
                  marginRight: '5px',
                  textAlign: 'center',
                  fontSize: 19,
                  fontFamily: 'Akkurat-Bold',
                  marginLeft: '25px',
                }}
              >
                {this.props.data.symbol}
              </p>
            </div>
          )}
          {/* <p style={{color: '#272748', margin:0, flex: 1, textAlign: 'left', fontSize: 19, fontFamily: 'Akkurat-Bold'}}>{this.props.data.symbol}</p> */}
          <p
            style={{
              color: '#272748',
              margin: 0,
              flex: 1,
              textAlign: 'center',
              fontSize: 14,
              fontFamily: 'Akkurat-Bold',
            }}
          >
            {this.props.data.company ? this.props.data.company : 'Name'}
          </p>
          <p
            style={{
              color: this.props.data.priceChange < 0 ? '#3D5062' : '#3F46F6',
              margin: 0,
              flex: 1,
              textAlign: 'center',
              fontSize: 16,
            }}
          >
            {this.props.data.priceChange?.toFixed(2)}%
          </p>
          <p
            style={{
              color: '#272748',
              margin: 0,
              flex: 1,
              textAlign: 'center',
              fontSize: 16,
            }}
          >
            {this.props.data.volChange?.toFixed(2)}%
          </p>
        </div>
      </NavLink>
    ) : (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          backgroundColor: this.state.hover
            ? 'rgb(220,220,220)'
            : 'transparent',
          textDecoration: 'none',
          height: 50,
          outline: 'none',
          borderBottom: 'solid .25px rgb(221, 221, 221)',
        }}
        // onMouseEnter={()=>this.setState({hover:true})}
        // onMouseLeave={()=>this.setState({hover:false})}>
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            fontSize: 19,
            fontFamily: 'Akkurat-Bold',
          }}
        >
          <p
            style={{
              color: '#272748',
              margin: 0,
              flex: 2,
              textAlign: 'left',
              fontSize: 16,
            }}
          >
            {this.props.data.symbol}
          </p>
          <p
            style={{
              color: '#272748',
              margin: 0,
              flex: 1,
              textAlign: 'center',
              fontSize: 16,
            }}
          >
            {this.props.data.volume}
          </p>
          <p
            style={{
              color: '#272748',
              margin: 0,
              flex: 1,
              textAlign: 'center',
              fontSize: 16,
            }}
          >
            {this.props.data.volChange.toFixed(2)}%
          </p>
        </div>
      </div>
    )
  }
}
