import { Dialog } from 'primereact/dialog'
import { Slider } from 'primereact/slider'
import React, { useEffect, useState } from 'react'
import '../../../scss/components/PersonalizationForm.scss'

import {
  IoChevronBackOutline,
  IoArrowForwardOutline,
  IoCheckmark,
} from 'react-icons/io5'

// Icons

import SustainableIcon from '../../../assets/impact/Sustainable_Product_Lifecycle.png'
import MindfulIcon from '../../../assets/impact/Mindful_Business_Models.png'
import CleanIcon from '../../../assets/impact/Clean_Air.png'
import PureIcon from '../../../assets/impact/Pure_Water.png'
import OceanIcon from '../../../assets/impact/Ocean_Life.png'
import LandIcon from '../../../assets/impact/Land_Health.png'
import EthicalIcon from '../../../assets/impact/Ethical_Leadership.png'
import CustomerIcon from '../../../assets/impact/Customer_Safety.png'
import CompanyIcon from '../../../assets/impact/Company_Transparency.png'
import LGBTQIcon from '../../../assets/impact/LGBTQ_Inclusion.png'
import GenderIcon from '../../../assets/impact/Gender_Equality.png'
import RacialIcon from '../../../assets/impact/Racial_Equality.png'
import FairIcon from '../../../assets/impact/Fair_Labor_Thriving_Communities.png'
import GreenhouseIcon from '../../../assets/impact/Greenhouse_Emissions.png'
import PoliticalIcon from '../../../assets/impact/Political_Spending_Lobbying.png'
import WeaponsIcon from '../../../assets/impact/Weapons_and_Gun_Manufacturers.png'
import BusinessIcon from '../../../assets/impact/Business_Ethics_Controversies.png'
import EnergyIcon from '../../../assets/impact/Energy_Intensive.png'
import TobaccoIcon from '../../../assets/impact/Tobacco_Alcohol_and_Gambling.png'
import HazardousIcon from '../../../assets/impact/Hazardous_Waste.png'
import AnimalIcon from '../../../assets/impact/Animal_Testing.png'
import FossilIcon from '../../../assets/impact/Fossil_Fuels.png'
import HighIcon from '../../../assets/impact/High_Water_Usage.png'
import Question5 from '../../../assets/impact/q5.png'
import Question6 from '../../../assets/impact/q6.png'

import Axios from 'axios'
import ENDPOINT from '../../Endpoint'
import SEARCHENDPOINT from '../../SearchEndpoint'

import { Button } from 'primereact/button'
import { Chips } from 'primereact/chips'

import { formatPercentage } from '../../../utility-functions/formatPercentage'
import formatCurrency from '../../../utility-functions/formatCurrency'
import { ListBox } from 'primereact/listbox'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'

const formQuestions = [
  {
    id: 1,
    img: '',
    question: 'When you invest money, what is your primary purpose? ',
    answers: [
      {
        text: 'Preserve the value of my investments.',
        subtext: 'I want to minimize the risk of my investments losing value.',
        value: 'PRESERVE',
      },
      {
        text: 'Emphasize current income.',
        subtext: 'My investments should be relatively safe. ',
        value: 'SAFE',
      },
      {
        text: 'Generate current income.',
        subtext:
          'However, I would also like to build the value of my investments gradually over time. I am willing to expose my investments to a moderate level of risk.',
        value: 'INCOME',
      },
      {
        text: 'Preserve the value of my investments.',
        subtext: 'I want to minimize the risk of my investments losing value.',
        value: 'GROWTH',
      },
      {
        text: 'Preserve the value of my investments.',
        subtext: 'I want to minimize the risk of my investments losing value.',
        value: 'SUBSTANTIAL_GROWTH',
      },
    ],
  },
  {
    id: 2,
    img: '',
    question:
      'The degree to which the value of an investment increases and decreases is called volatility (one measure of risk). More volatile investments generally offer greater long-term growth potential than less volatile investments, but they may produce greater losses. How much volatility are you comfortable with?',
    answers: [
      {
        text: 'As little as possible',
        subtext:
          'I want to focus on current income and stability of value even if it means that my total returns are relatively small.',
        value: 'NONE',
      },
      {
        text: 'Emphasize current income.',
        subtext: 'My investments should be relatively safe. ',
        value: 'SOME',
      },
      {
        text: 'Generate current income.',
        subtext:
          'However, I would also like to build the value of my investments gradually over time. I am willing to expose my investments to a moderate level of risk.',
        value: 'MODERATE',
      },
      {
        text: 'Preserve the value of my investments.',
        subtext: 'I want to minimize the risk of my investments losing value.',
        value: 'HIGH',
      },
    ],
  },
  {
    id: 3,
    img: '',
    question:
      'Extremely conservative investments sometimes earn less than the inflation rate. This may result in the loss of purchasing power. With respect to your investment objectives, which of the following is most true?',
    answers: [
      {
        text: 'My investments should be safe, even if it means my returns do not keep pace with inflation. ',
        subtext: '',
        value: 'SAFE',
      },
      {
        text: 'I am willing to risk an occasional loss of investment value so that my investments may grow at about the same rate as inflation over time.',
        subtext: '',
        value: 'INFLATION',
      },
      {
        text: 'It is important that my investments grow somewhat faster than inflation. I am willing to accept some risk to achieve this goal.',
        subtext: '',
        value: 'ABOVE_INFLATION',
      },
      {
        text: 'My investments should grow much faster than inflation. I am willing to accept considerable risk to achieve this goal.',
        subtext: '',
        value: 'FASTEST',
      },
    ],
  },
  {
    id: 4,
    img: '',
    question:
      'You understand the value of investments will fluctuate over time, depending on the amount of risk taken. What is the approximate loss in any one-year period that you would be willing to accept before deciding to change your investments? ',
    answers: [
      {
        text: 'Less than (5%)',
        subtext: '',
        value: '5',
      },
      {
        text: '(5%) to (10%)',
        subtext: '',
        value: '10',
      },
      {
        text: '(10%) to (15%)',
        subtext: '',
        value: '15',
      },
      {
        text: '(15%) to (20%)',
        subtext: '',
        value: '25',
      },
      {
        text: '(25%) or more',
        subtext: '',
        value: '40',
      },
    ],
  },
  {
    id: 5,
    img: Question5,
    question:
      'Consider two different investments: Investment A, which provides an average annual return of 5% with a minimal risk of loss of value, and Investment B, which provides an average annual return of 10% and a potential loss of 25% or more in any year. How would you divide your investment dollars?',
    answers: [
      {
        text: 'A',
        subtext: '',
        value: 'A',
      },
      {
        text: 'B',
        subtext: '',
        value: 'B',
      },
    ],
  },
  {
    id: 6,
    img: Question6,
    question:
      'If you could choose only one of the five hypothetical portfolios characterized below, which would you select?',
    answers: [
      {
        text: 'A',
        subtext: '',
        value: 'A',
      },
      {
        text: 'B',
        subtext: '',
        value: 'B',
      },
      {
        text: 'C',
        subtext: '',
        value: 'C',
      },
      {
        text: 'D',
        subtext: '',
        value: 'D',
      },
      {
        text: 'E',
        subtext: '',
        value: 'E',
      },
    ],
  },
  {
    id: 7,
    img: '',
    question:
      'When is the earliest you anticipate needing all or a substantial portion of your investment assets?',
    answers: [
      {
        text: 'Short Term',
        subtext: '0 to 2 years ',
        value: 'SHORT',
      },
      {
        text: 'Medium Term',
        subtext: 'More than 2 but less than 5 years',
        value: 'MEDIUM',
      },
      {
        text: 'Long Term',
        subtext: '',
        value: 'LONG',
      },
    ],
  },
  {
    id: 8,
    img: '',
    question: 'Are your total investment assets less than $1 million?',
    answers: [
      {
        text: 'Yes',
        subtext: '',
        value: true,
      },
      {
        text: 'No',
        subtext: '',
        value: false,
      },
    ],
  },
  {
    id: 9,
    img: '',
    question:
      'If your response to Question 8 is No, is the amount you are investing less than 10% of your total investment assets?',
    answers: [
      {
        text: 'Yes',
        subtext: '',
        value: true,
      },
      {
        text: 'No',
        subtext: '',
        value: false,
      },
    ],
  },
]

const preferences = [
  {
    text: 'Sustainable Product Lifecycle',
    icon: SustainableIcon,
  },
  {
    text: 'Mindful Business Models',
    icon: MindfulIcon,
  },
  {
    text: 'Clean Air',
    icon: CleanIcon,
  },
  {
    text: 'Pure Water',
    icon: PureIcon,
  },
  {
    text: 'Ocean Life',
    icon: OceanIcon,
  },
  {
    text: 'Land Health',
    icon: LandIcon,
  },
  {
    text: 'Ethical Leadership',
    icon: EthicalIcon,
  },
  {
    text: 'Customer Safety',
    icon: CustomerIcon,
  },
  {
    text: 'Company Transparency',
    icon: CompanyIcon,
  },
  {
    text: 'LGBTQ Inclusion',
    icon: LGBTQIcon,
  },
  {
    text: 'Gender Equality',
    icon: GenderIcon,
  },
  {
    text: 'Racial Equality',
    icon: RacialIcon,
  },
  {
    text: 'Fair Labor & Thriving Communities',
    icon: FairIcon,
  },
]

const avoid = [
  {
    text: 'Greenhouse Emissions',
    icon: GreenhouseIcon,
  },
  {
    text: 'Political Spending & Lobbying',
    icon: PoliticalIcon,
  },
  {
    text: 'Weapons and Gun Manufacturers',
    icon: WeaponsIcon,
  },
  {
    text: 'Business Ethics Controversies',
    icon: BusinessIcon,
  },
  {
    text: 'Energy Intensive',
    icon: EnergyIcon,
  },
  {
    text: 'Tobacco, Alcohol and Gambling',
    icon: TobaccoIcon,
  },
  {
    text: 'Hazardous Waste',
    icon: HazardousIcon,
  },
  {
    text: 'Animal Testing',
    icon: AnimalIcon,
  },
  {
    text: 'Fossil Fuels',
    icon: FossilIcon,
  },
  {
    text: 'High Water Usage',
    icon: HighIcon,
  },
]

const sortTimePeriods = (periods) => {
  // Convert periods to months
  const periodsInMonths = periods.map((period) => {
    if (period.includes('y')) {
      return parseInt(period) * 12 // Convert years to months
    } else if (period.includes('m')) {
      return parseInt(period) // Keep months as is
    } else {
      return 0 // Handle unexpected cases
    }
  })
  // Sort the periods in ascending order
  const sortedPeriodsInMonths = periodsInMonths.sort((a, b) => a - b)
  // Convert back to original format
  const sortedPeriods = sortedPeriodsInMonths.map((months) => {
    if (months >= 12) {
      return `${Math.floor(months / 12)}y ago`
    } else {
      return `${months}m ago`
    }
  })
  return sortedPeriods
}

const QAStep = ({
  question,
  nextStep,
  setFormStep,
  responses,
  setResponses,
}) => {
  const saveResponse = (questionNum, answerValue) => {
    let restOfResponses = { ...responses }
    restOfResponses[questionNum] = answerValue
    setResponses(restOfResponses)
    if (questionNum === 8 && answerValue === true) {
      setFormStep(9)
    } else if (questionNum === 8 && answerValue === false) {
      nextStep()
    } else if (questionNum !== formQuestions.length) nextStep()
    else setFormStep(9)
  }

  return (
    <div className='question-container'>
      <div className='question'>{question.question}</div>
      {question?.img && (
        <img src={question?.img} alt='' style={{ marginBottom: 20 }} />
      )}
      <div className='answers-container'>
        {question.answers.map((ans) => {
          return (
            <div
              className='answer-container'
              onClick={() => saveResponse(question.id, ans.value)}
            >
              <div className='answer'>
                <p className='main-text'>{ans.text}</p>
                <p className='sub-text'>{ans.subtext}</p>
              </div>
              <div style={{ width: 14 }}>
                <IoArrowForwardOutline size={14} />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const SelectionItem = (props) => {
  const isSelected =
    props.type === 'purpose'
      ? props.purposes.indexOf(props.item.text) === -1
        ? false
        : true
      : props.areasToAvoid.indexOf(props.item.text) === -1
      ? false
      : true

  const handleClick = (val, type) => {
    if (isSelected) {
      if (type === 'purpose') {
        let temp = [...props.purposes]
        let index = temp.indexOf(val)
        temp.splice(index, 1)
        props.setPurposes(temp)
      } else {
        let temp = [...props.areasToAvoid]
        let index = temp.indexOf(val)
        temp.splice(index, 1)
        props.setAreasToAvoid(temp)
      }
    } else {
      if (type === 'purpose') {
        let temp = [...props.purposes]
        temp.push(val)
        props.setPurposes(temp)
      } else {
        let temp = [...props.areasToAvoid]
        temp.push(val)
        props.setAreasToAvoid(temp)
      }
    }
  }

  return (
    <div
      className={`list-item ${isSelected ? 'selected' : ''}`}
      onClick={() => handleClick(props.item.text, props.type)}
    >
      <div className='list-item-content'>
        <img className='item-icon' src={props.item.icon} alt='' />
        <p className='item-title'>{props.item.text}</p>
      </div>
      <div className='checked-container'>
        {isSelected && <IoCheckmark size={28} />}
      </div>
    </div>
  )
}

const RestrictedPage = ({
  setSectorsAvoid,
  sectorsAvoid,
  setSecuritiesAvoid,
  securitiesAvoid,
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [searchResults, setSearchResults] = useState(null)
  const [sectorList, setSectorList] = useState(null)

  console.log(securitiesAvoid)
  console.log(sectorsAvoid)

  useEffect(() => {
    fetchSectors()
  }, [])

  useEffect(() => {
    const searchTimeoutId = setTimeout(() => {
      fetchSearchResults()
    }, 300)

    return () => {
      clearTimeout(searchTimeoutId)
    }
  }, [searchQuery])

  const fetchSearchResults = async () => {
    try {
      setIsSearching(true)

      let url = `${SEARCHENDPOINT}/ticker?search=${searchQuery?.toUpperCase()}`

      let res = await Axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      // fitler the data that has weired result where the company name is ""
      const filteredData = res.data.content.filter((obj) => obj.company !== '')

      if (filteredData.length > 0) {
        let organizedResults = Array.from({
          length: filteredData.length,
        })

        organizedResults = filteredData.map((stock) => ({
          label: `${stock.symbol} - ${stock.company}`,
          value: stock.symbol,
        }))

        setSearchResults(organizedResults)
      } else {
        if (searchQuery) {
          setSearchResults(['No results found'])
        } else {
          setSearchResults(null)
        }
        // setSearchResults([])
      }

      setIsSearching(false)
    } catch (e) {
      console.log(e)
      setIsSearching(false)

      return
    }
  }

  const fetchSectors = async () => {
    try {
      let res = await Axios.get(`${ENDPOINT}/mobile//industry/sector`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      console.log(res?.data)

      const arrayOfObjects = res?.data?.sectors?.map((item) => ({
        label: item,
        value: item,
      }))

      setSectorList(arrayOfObjects)
    } catch (error) {
      console.log(error)
    }
  }

  const removeItem = (itemToRemove) => {
    setSecuritiesAvoid((prevSecuritiesAvoid) =>
      prevSecuritiesAvoid?.filter((item) => item !== itemToRemove)
    )
  }

  const removeSectorItem = (itemToRemove) => {
    setSectorsAvoid((prev) => prev?.filter((item) => item !== itemToRemove))
  }

  const handleChange = (e) => {
    const newItem = e?.target?.value

    // Check if the newItem is not already in the array
    if (
      newItem &&
      newItem !== 'No results found' &&
      !securitiesAvoid.includes(newItem)
    ) {
      setSecuritiesAvoid([...securitiesAvoid, newItem])
    }

    setSearchQuery('')
    setSearchResults(null)
  }

  const handleSectorChange = (e) => {
    const newItem = e?.target?.value

    // Check if the newItem is not already in the array
    if (newItem && !sectorsAvoid.includes(newItem)) {
      setSectorsAvoid([...sectorsAvoid, newItem])
    }
  }

  return (
    <>
      <div>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              justifyContent: 'space-between',
              marginBottom: '0.5rem',
            }}
          >
            <label style={{ marginBottom: '0rem' }}>Securities</label>
            {securitiesAvoid?.length > 0 && (
              <Button
                label='Clear all'
                className='p-button-danger p-button-text'
                style={{
                  padding: '0rem',
                  paddingBottom: '0rem',
                  fontSize: 14,
                }}
                onClick={() => setSecuritiesAvoid([])}
              />
            )}
          </div>

          {searchQuery ? (
            !searchResults ? (
              ''
            ) : (
              <div
                style={{
                  position: 'absolute',
                  bottom: '0px',
                  width: '100%',
                  zIndex: 1,
                  marginBottom: '0.5rem',
                }}
              >
                <ListBox
                  // value={searchResults}
                  // onChange={(e) => setSelectedItem(e.value)}
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  options={searchResults}
                  virtualScrollerOptions={{ itemSize: 18 }}
                  // className='w-full md:w-14rem'
                  listStyle={{ height: '150px' }}
                />
              </div>
            )
          ) : (
            ''
          )}
        </div>

        <span className='p-input-icon-right' style={{ width: '100%' }}>
          <i
            className={`pi ${isSearching ? 'pi-spin pi-spinner' : 'pi-search'}`}
          />

          <InputText
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder='Search a security'
            // disabled={messageDataObj.disabled}
          />
        </span>

        <div
          style={{
            display: 'flex',
            gap: '0.5rem',
            padding: '1rem 0rem',
            flexWrap: 'wrap',
          }}
        >
          {securitiesAvoid?.map((item, index) => {
            return (
              <Button
                key={index}
                label={item}
                className='p-button p-button-sm'
                icon='pi pi-times'
                iconPos='right'
                style={{ background: '#0000ff' }}
                onClick={() => removeItem(item)}
              />
            )
          })}
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'space-between',
            marginBottom: '0.5rem',
          }}
        >
          <label style={{ marginBottom: '0rem' }}>Restricted Sectors</label>
          {sectorsAvoid?.length > 0 && (
            <Button
              label='Clear all'
              className='p-button-danger p-button-text'
              style={{
                padding: '0rem',
                paddingBottom: '0rem',
                fontSize: 14,
              }}
              onClick={() => setSectorsAvoid([])}
            />
          )}
        </div>

        <Dropdown
          options={sectorList}
          onChange={(e) => handleSectorChange(e)}
          optionLabel='label'
          placeholder='Choose sectors'
        />
        <div
          style={{
            display: 'flex',
            gap: '0.5rem',
            padding: '1rem 0rem',
            flexWrap: 'wrap',
          }}
        >
          {sectorsAvoid?.map((item, index) => {
            return (
              <Button
                key={index}
                label={item}
                className='p-button p-button-sm'
                icon='pi pi-times'
                iconPos='right'
                onClick={() => removeSectorItem(item)}
                style={{ background: '#0000ff' }}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

export default function PersonalizationForm(props) {
  const [formStep, setFormStep] = useState(
    props?.personalizationComplete ? 11 : 0
  )
  const [responses, setResponses] = useState({
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
    6: null,
    7: null,
    8: null,
    9: null,
  })
  const [purposes, setPurposes] = useState([])
  const [areasToAvoid, setAreasToAvoid] = useState([])
  const [submittingQuestionnaire, setSubmittingQuestionnaire] = useState(false)
  const [selectedStrategy, setSelectedStrategy] = useState(null)
  // strategy preview information
  const [customStrategyRes, setCustomStategyRes] = useState(null)
  const [strategyPreviewInfo, setStrategyPreviewInfo] = useState({
    name: '',
    description: '',
    riskData: null,
    allocations: null,
  })
  const [showMoreAllocations, setShowMoreAllocations] = useState(false)
  const [savingStrategyLoading, setSavingStrategyLoading] = useState(false)
  const [investmentAmount, setInvestmentAmount] = useState(1000)
  const [selectedYear, setSelectedYear] = useState(
    props?.productInstanceData?.strategyInfo?.strategy_returns
      ? Object.keys(
          props?.productInstanceData?.strategyInfo?.strategy_returns
        )[0]
      : ''
  )
  const [sectorsAvoid, setSectorsAvoid] = useState([])
  const [securitiesAvoid, setSecuritiesAvoid] = useState([])

  const submitQuestionnaire = async () => {
    setSubmittingQuestionnaire(true)
    console.log('[API]: submitQuestionnaire start')
    try {
      let body = {
        primary_purpose: responses[1],
        volatility: responses[2],
        pace_objective: responses[3],
        acceptable_loss: responses[4],
        risk_reward_binary: responses[5],
        risk_reward_level: responses[6],
        investment_duration: responses[7],
        invest_under_million: responses[8],
        invest_under_10_perc: responses[9],
        purposes: purposes,
        areas_to_avoid: areasToAvoid,
        is_custom_strategy: true,
        securities_to_avoid: securitiesAvoid,
        sectors_to_avoid: sectorsAvoid,
      }
      console.log('body', body)
      let res = await Axios.post(
        ENDPOINT + '/mobile/personalized/questionnaire',
        body,
        {
          headers: {
            Authorization: 'Bearer ' + props.user.idToken.jwtToken,
          },
        }
      )
      console.log('res', res)
      if (res?.data?.status === true) {
        setFormStep(formStep + 1)
        setSelectedStrategy('custom')
        setSubmittingQuestionnaire(false)
      } else {
        setSubmittingQuestionnaire(false)
      }
    } catch (error) {
      console.log('[API]: submitQuestionnaire error', error)
      setSubmittingQuestionnaire(false)
      return
    }
  }

  const assignFixedStrategy = async () => {
    setSubmittingQuestionnaire(true)
    console.log('[API]: submitQuestionnaire start')
    try {
      let body = {
        primary_purpose: responses[1],
        volatility: responses[2],
        pace_objective: responses[3],
        acceptable_loss: responses[4],
        risk_reward_binary: responses[5],
        risk_reward_level: responses[6],
        investment_duration: responses[7],
        invest_under_million: responses[8],
        invest_under_10_perc: responses[9],
        purposes: [],
        areas_to_avoid: [],
        is_custom_strategy: false,
        securities_to_avoid: securitiesAvoid,
        sectors_to_avoid: sectorsAvoid,
      }
      console.log('body', body)
      let res = await Axios.post(
        ENDPOINT + '/mobile/personalized/questionnaire',
        body,
        {
          headers: {
            Authorization: 'Bearer ' + props.user.idToken.jwtToken,
          },
        }
      )
      console.log('res', res)
      if (res?.data?.status === true) {
        setSelectedStrategy('expert')
        setFormStep(12)
        setSubmittingQuestionnaire(false)
      } else {
        setSubmittingQuestionnaire(false)
      }
      setSelectedStrategy('expert')
      setFormStep(12)
    } catch (error) {
      console.log('[API]: submitQuestionnaire error', error)
      setSubmittingQuestionnaire(false)
      return
    }
  }

  const fetchCustomStrategy = async () => {
    try {
      console.log('[API]: Start fetchCustomStrategy')
      let res = await Axios.get(
        ENDPOINT + '/risk-optimization/personalized/generate-strategy',
        {
          headers: {
            Authorization: 'Bearer ' + props.user.idToken.jwtToken,
          },
        }
      )
      console.log('[API]: fetchCustomStrategy res: ', res.data.payload)
      return res.data.payload
    } catch (error) {
      console.log('[API]: Error fetchCustomStrategy', error)
      return {
        error: error,
      }
    }
  }

  const saveStrategyInformation = async (body) => {
    try {
      console.log('[API]: Start saveStrategyInformation')
      let res = await Axios.post(
        ENDPOINT + '/mobile/personalized/save-strategy',
        body,
        {
          headers: {
            Authorization: 'Bearer ' + props.user.idToken.jwtToken,
          },
        }
      )
      console.log('res', res)
      return res.data
    } catch (error) {
      console.log('[API]: Error saveStrategyInformation', error)
      return
    }
  }

  const formTitle = () => {
    switch (formStep) {
      case 12:
        return <></>
      // case 11:
      //   return (
      //     <>
      //       <p className='title' style={{ marginBottom: 20 }}>
      //         Now it’s time for you to pick your strategy.
      //       </p>
      //       <p>
      //         Review from the recommended strategies below, add money and we’ll
      //         invest for you.
      //       </p>
      //     </>
      //   )
      case 11:
        return (
          <div style={{ marginBottom: 20 }}>
            <p className='title' style={{ fontSize: 29, textAlign: 'center' }}>
              Select the areas you like to avoid
            </p>
            <p style={{ fontSize: 14, color: '#9191A2', textAlign: 'center' }}>
              Flag practices that are undesirable in your portfolio.
            </p>
          </div>
        )
      case 10:
        return (
          <div
            style={{
              marginBottom: 20,
            }}
          >
            <p className='title' style={{ fontSize: 29, textAlign: 'center' }}>
              Select the causes that are important to you
            </p>
            <p style={{ fontSize: 14, color: '#9191A2', textAlign: 'center' }}>
              Select as many as you like
            </p>
          </div>
        )
      case 9:
        return (
          <div style={{ marginBottom: 20 }}>
            <p className='title' style={{ fontSize: 29, textAlign: 'center' }}>
              Restricted Securities
            </p>
            <p style={{ fontSize: 14, color: '#9191A2', textAlign: 'center' }}>
              This step is optional, your choices will be used for
              individualized investment strategy.
            </p>
          </div>
        )
      default:
        return (
          <p
            className='title'
            style={{ marginBottom: 20, fontSize: 29, textAlign: 'center' }}
          >
            Risk Tolerance Test
          </p>
        )
    }
  }

  const loadStrategyData = async (type) => {
    // IF we need to load premade CIO strategies
    if (type === 'expert') {
      console.log(props.productInstanceData)
      if (props.productInstanceData?.strategyInfo?.id) {
        setStrategyPreviewInfo({
          name: props.productInstanceData?.strategyInfo?.name,
          description:
            props.strategyInfo[props.productInstanceData?.strategyInfo?.id]
              ?.description,
          allocations: props.productInstanceData?.allocations,
        })
      }
    } else {
      // Load Custom Strategy Information
      let customStrategy = await fetchCustomStrategy()
      if (customStrategy.error) {
      } else {
        setCustomStategyRes(customStrategy?.customized_strategies)
        let allocationList = Object.keys(
          customStrategy?.customized_strategies?.['CS_0']?.allocation
        ).map((symbol) => {
          return {
            symbol: symbol,
            allocation:
              customStrategy?.customized_strategies?.['CS_0']?.allocation[
                symbol
              ],
          }
        })
        setStrategyPreviewInfo({
          name: props.userInfo?.firstName
            ? 'Personalized for ' + props.userInfo?.firstName
            : 'You',
          description: null,
          allocations: allocationList,
        })
      }
    }
  }

  const saveStrategy = async () => {
    let body = {}
    setSavingStrategyLoading(true)
    if (selectedStrategy === 'custom') {
      body = {
        is_custom_strategy: true,
        customized_strategies: { ...customStrategyRes },
      }
    } else {
      body = {
        is_custom_strategy: false,
      }
    }
    let savedStrategyRes = await saveStrategyInformation(body)

    console.log('savedStrategyRes', savedStrategyRes)
    await props.getRoboInstance()
    setSavingStrategyLoading(false)
    props.hidePersonalizationForm()
  }

  const finishExpertStrategy = async () => {
    await props.getRoboInstance()
    props.hidePersonalizationForm()
  }

  useEffect(() => {
    if (props?.personalizationComplete) setSelectedStrategy('custom')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedStrategy) {
      loadStrategyData(selectedStrategy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStrategy])

  return (
    <Dialog
      closable
      visible={props.showPersonalizationForm}
      style={{ width: props.isSmallScreen ? '90vw' : '40vw' }}
      onHide={() => props.hidePersonalizationForm()}
      className='personalization-dialog'
      header={
        <div className='personalization-header'>
          {formStep > 0 && formStep !== 12 ? (
            <button
              className='form-back-btn'
              onClick={() => {
                // handle on condition
                // IF question 8 is true then skip the 9th question
                if (formStep <= 8) {
                  let temp = { ...responses }
                  temp[formStep + 1] = null
                  setResponses(temp)
                }
                if (formStep === 12) setSelectedStrategy(null)
                if (formStep === 10 && responses[8] === true) setFormStep(7)
                else setFormStep(formStep - 1)
              }}
            >
              <IoChevronBackOutline />
            </button>
          ) : (
            <div style={{ height: 40, width: 40 }} />
          )}
          <div className='progress-bar-container'>
            <div className='progress-bar'>
              <div
                className={`step-indicator ${formStep === 12 ? 'hidden' : ''}`}
                style={{ width: ((formStep + 1) / 12) * 100 + '%' }}
              ></div>
            </div>
          </div>
        </div>
      }
      draggable={false}
    >
      <div className='qa-container'>
        {/* Skip button to choose fixed strategy */}
        {formStep === 10 && (
          <div className='skip-button-container'>
            <p
              className='skip-button'
              onClick={() =>
                formStep === 9
                  ? setFormStep(formStep + 1)
                  : assignFixedStrategy()
              }
            >
              Skip
            </p>
          </div>
        )}
        {/* Title for the current form */}
        {formTitle()}
        {/* Questionnaire */}
        {formStep >= 0 && formStep < 9 && (
          <QAStep
            question={formQuestions[formStep]}
            key={formQuestions[formStep].id}
            nextStep={() => setFormStep(formStep + 1)}
            setFormStep={setFormStep}
            responses={responses}
            setResponses={setResponses}
          />
        )}

        {formStep === 9 && (
          <div>
            <RestrictedPage
              sectorsAvoid={sectorsAvoid}
              setSectorsAvoid={setSectorsAvoid}
              securitiesAvoid={securitiesAvoid}
              setSecuritiesAvoid={setSecuritiesAvoid}
            />
            <br />

            <Button
              label='Continue'
              className='p-button-rounded personalization-full-width-btn'
              // disabled={
              //   sectorsAvoid?.length === 0 && securitiesAvoid?.length === 0
              // }
              onClick={() => setFormStep(formStep + 1)}
            />
          </div>
        )}
        {/* Investment Amount */}
        {/* {formStep === 9 && (
          <div className='question-container'>
            <div className='question'>What amount are you ready to invest?</div>
            <div className='answers-container'>
              <div className='answer-container'>
                <input type='text' />
                <button className='form-back-btn'>
                  <IoChevronForwardOutline
                    onClick={() => setFormStep(formStep + 1)}
                  />
                </button>
              </div>
            </div>
          </div>
        )} */}
        {/* Select Purposes */}
        {formStep === 10 && (
          <div>
            <div className='selector-list'>
              {preferences.map((item, i) => {
                return (
                  <SelectionItem
                    key={i}
                    item={item}
                    type='purpose'
                    purposes={purposes}
                    setPurposes={setPurposes}
                  />
                )
              })}
            </div>
            <Button
              label='Continue'
              className='p-button-rounded personalization-full-width-btn'
              disabled={purposes.length === 0}
              onClick={() => setFormStep(formStep + 1)}
            />
          </div>
        )}
        {/* Select Areas to Avoid */}
        {formStep === 11 && (
          <div>
            <div className='selector-list'>
              {avoid.map((item, i) => {
                return (
                  <SelectionItem
                    key={i}
                    item={item}
                    type='avoid'
                    areasToAvoid={areasToAvoid}
                    setAreasToAvoid={setAreasToAvoid}
                  />
                )
              })}
            </div>
            <Button
              label='Submit'
              className='p-button-rounded personalization-full-width-btn'
              loading={submittingQuestionnaire}
              disabled={areasToAvoid.length === 0}
              onClick={() => submitQuestionnaire()}
            />
          </div>
        )}
        {/* Select which strategy you want to select */}
        {/* {formStep === 11 && (
          <div className='strategy-select-container'>
            <div className='center-content'>
              <p className='strategy-card-label'>Recommended for you</p>
              <div
                className='strategy-card expert'
                onClick={() => setSelectedStrategy('expert')}
              >
                <p className='card-title'>Expert Strategy</p>
                <div className='strategy-card-description-container'>
                  <p>
                    We’ve selected the best expert strategy based on your
                    preferences. Click here to learn more.
                  </p>
                  <IoArrowForwardOutline size={56} />
                </div>
              </div>
            </div>
            <div className='center-content'>
              <p className='strategy-card-label'>
                Custom strategy based on your preferences
              </p>
              <div
                className='strategy-card custom'
                onClick={() => setSelectedStrategy('custom')}
              >
                <p className='card-title'>Custom AI Strategy </p>
                <div className='strategy-card-description-container'>
                  <p>
                    Creates a custom strategy based on your preferences and let
                    us do the rest.
                  </p>
                  <IoArrowForwardOutline size={56} />
                </div>
              </div>
            </div>
          </div>
        )} */}
        {/* Strategy Information  */}
        {formStep === 12 && (
          <div className='strategy-info-container'>
            <div className={`strategy-info-header ${selectedStrategy}`}>
              <p className='sub-title'>
                {selectedStrategy === 'expert'
                  ? 'Expert Strategy'
                  : 'Personalized Portfolio'}
              </p>
              <p className='main-title'>{strategyPreviewInfo.name}</p>
              <p>
                {selectedStrategy === 'expert' &&
                  'This strategy matches to your risk tolerance and Impact investing preferences.'}
              </p>
            </div>
            {strategyPreviewInfo.description && (
              <div className='strategy-info-description'>
                <p className='description-title'>Portfolio Description</p>
                <p>{strategyPreviewInfo.description}</p>
              </div>
            )}
            <div className='strategy-info-description'>
              <p className='description-title'>Automatic Rebalancing</p>
              <p>
                This strategy is drift rebalanced{' '}
                {selectedStrategy === 'expert' ? 'monthly' : 'quarterly'}
              </p>
            </div>
            {selectedStrategy === 'expert' && (
              <div className='strategy-returns-container'>
                <p className='description-title'>
                  {strategyPreviewInfo.name} Returns
                </p>
                <div className='return-row'>
                  <p>Month to Date</p>
                  <p>
                    {formatPercentage(
                      props?.productInstanceData?.strategyInfo.mtd
                    )}
                  </p>
                </div>
                <div className='return-row'>
                  <p>Year to Date</p>
                  <p>
                    {formatPercentage(
                      props?.productInstanceData?.strategyInfo.ytd
                    )}
                  </p>
                </div>
              </div>
            )}
            {selectedStrategy === 'expert' && (
              <>
                {props?.productInstanceData?.strategyInfo?.strategy_returns && (
                  <div className='investment-calc-container'>
                    <div className='row'>
                      <p className='title'>Investment Calculator</p>
                      <p className='icon'>🔮</p>
                    </div>
                    <p className='general-text'>
                      If you have invested <span>{investmentAmount}</span>
                    </p>
                    <Slider
                      value={investmentAmount}
                      onChange={(e) => setInvestmentAmount(e.value)}
                      step={500}
                      min={1000}
                      max={5000}
                    />
                    <div className='year-selector'>
                      {sortTimePeriods(
                        Object.keys(
                          props?.productInstanceData?.strategyInfo
                            ?.strategy_returns
                        )
                      ).map((time, i) => {
                        return (
                          <div
                            className={`year ${
                              selectedYear === time && 'selected'
                            }`}
                            onClick={() => setSelectedYear(time)}
                            key={i}
                          >
                            {time}
                          </div>
                        )
                      })}
                    </div>
                    <p className='general-text'>It now could be</p>
                    <p className='return-amount'>
                      {formatCurrency(
                        props?.productInstanceData?.strategyInfo
                          ?.strategy_returns[selectedYear] * investmentAmount
                      )}
                    </p>
                    <p className='disclaimer'>
                      *Past performance is not a guarantee of future returns
                    </p>
                  </div>
                )}
              </>
            )}
            <div className='strategy-info-allocations'>
              <p className='description-title'>What you will invest in</p>
              {showMoreAllocations && strategyPreviewInfo?.allocations
                ? strategyPreviewInfo?.allocations?.map((alloc, i) => {
                    return (
                      <div className='allocation-row' key={i}>
                        <p>{alloc.symbol}</p>
                        <p>{formatPercentage(alloc.allocation)}</p>
                      </div>
                    )
                  })
                : strategyPreviewInfo?.allocations
                    ?.slice(0, 3)
                    ?.map((alloc, i) => {
                      return (
                        <div className='allocation-row' key={i}>
                          <p>{alloc.symbol}</p>
                          <p>{formatPercentage(alloc.allocation)}</p>
                        </div>
                      )
                    })}
              <p
                className='show-more-less-btn'
                onClick={() => setShowMoreAllocations(!showMoreAllocations)}
              >
                {showMoreAllocations ? 'Show Less' : 'Show More'}
              </p>
            </div>
            <Button
              className='p-button-rounded personalization-full-width-btn'
              loading={savingStrategyLoading}
              onClick={() =>
                selectedStrategy === 'custom'
                  ? saveStrategy()
                  : finishExpertStrategy()
              }
            >
              {selectedStrategy === 'custom'
                ? 'Select Personal Portfolio'
                : 'Continue'}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  )
}
