export const selectedTab = {
  backgroundColor: '#3F46F6',
  flex: 1,
  height: '40px',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '100px',
}
export const unSelectedTab = {
  height: '40px',
  cursor: 'pointer',
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  backgroundColor: 'rgba(146, 149, 161, 0.1)',
  textAlign: 'center',
  minWidth: '100px',
}
export const selectedText = {
  margin: 0,
  margin: 'auto',
  fontSize: '16px',
  fontFamily: 'Akkurat-Bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.3,
  letterSpacing: 'normal',
  textAlign: 'center',
  color: 'rgb(255, 255, 255)',
}
export const unSelectedText = {
  margin: 0,
  margin: 'auto',
  fontSize: 16,
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.3,
  letterSpacing: 'normal',
  textAlign: 'center',
  color: 'rgb(146, 149, 161)',
}
