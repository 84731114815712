import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import DetailOfPlatform from './DetailOfPlatform';


class ElementOfPlatfrom extends Component {
    render() {
        return (
            <Container id={this.props.value.class} fluid>
                {this.props.isSmallScreen ?
                    (<Row key={this.props.value.title} className="platform-branch-element">
                        <Col sm={4}>
                            <img src={this.props.value.iconUrl} width="30%" alt="element-icon" />
                            <p className="platform-branch-element-title">{this.props.value.title}</p>
                            <p className="platform-branch-element-subtitle">{this.props.value.subtitle}</p>
                            <p className="platform-branch-element-text">{this.props.value.text}</p>
                        </Col>
                        <Col sm={8} className="branch-element-details">
                            {this.props.value.details.map(detail =>
                                <DetailOfPlatform key={detail.id} value={detail} />
                            )}
                        </Col>
                    </Row>) :
                    this.props.value.isLeft ? (<Row key={this.props.value.title} className="platform-branch-element">
                        <Col sm={4}>
                            <img src={this.props.value.iconUrl} width="30%" alt="element-icon" />
                            <p className="platform-branch-element-title">{this.props.value.title}</p>
                            <p className="platform-branch-element-subtitle">{this.props.value.subtitle}</p>
                            <p className="platform-branch-element-text">{this.props.value.text}</p>
                        </Col>
                        <Col sm={8} className="branch-element-details">
                            {this.props.value.details.map(detail =>
                                <DetailOfPlatform key={detail.id} value={detail} />
                            )}
                        </Col>
                    </Row>) :
                        (<Row key={this.props.value.title} className="platform-branch-element">
                            <Col sm={8} className="branch-element-details">
                                {this.props.value.details.map(detail =>
                                    <DetailOfPlatform key={detail.id} value={detail} />
                                )}
                            </Col>
                            <Col sm={4}>
                                <img src={this.props.value.iconUrl} width="30%" alt="element-icon" />
                                <p className="platform-branch-element-title">{this.props.value.title}</p>
                                <p className="platform-branch-element-subtitle">{this.props.value.subtitle}</p>
                                <p className="platform-branch-element-text">{this.props.value.text}</p>
                            </Col>
                        </Row>)
                }
            </Container>
        )
    }
}

export default ElementOfPlatfrom
