import React from 'react'
import {StickyTable, Row, Cell} from 'react-sticky-table';


export default class MobileTickerList extends React.Component{

    createCells = ()=>{
        return this.props.data.map(ele=>{
            console.log(ele)
            return(
                <Row>
                    <Cell style={{width:'16%'}}>{ele.symbol}</Cell>
                    <Cell style={{width:'16%'}}>${ele.priceUSD.toFixed(2)}</Cell>
                    <Cell style={{width:'16%'}}><input style={{ width: 50, border: 'none', backgroundColor: 'transparent', fontWeight: 'bold', boxShadow:'none', textAlign:'right', outline:'none' }} onChange={(e) => this.props.setShares(e, ele)} type="text" value={ele.shares}/></Cell>
                    <Cell style={{width:'16%'}}>{ele.allocation}%</Cell>
                    {/* <Cell style={{width:'16%'}}>${ele.priceUSD}</Cell> */}
                </Row>
            )
        })
    }

    render(){
        return(
            <div style={{width:'100%', display:'flex',justifyContent:'center',alignItems:'center', }}>
                <div style={{width: '100%', height: this.props.isSmallScreen ? '' :'450px', display:'flex',justifyContent:'center',alignItems:'center'}}>
                <StickyTable style={{width:'100%', margin:0,padding:0}}>
                    <Row>
                        <Cell style={{width:'16%',height:52}}>Symbol</Cell>
                        <Cell style={{width:'16%',height:52}}>Price</Cell>
                        {/* <Cell style={{width:'16%',height:52}}>Trend</Cell> */}
                        <Cell style={{width:'16%',height:52, fontWeight:'bold'}}>Shares</Cell>
                        <Cell style={{width:'16%',height:52}}>Allocation</Cell>
                        {/* <Cell style={{width:'16%',height:52}}>Value</Cell> */}
                    </Row>
                    {this.createCells()}
                </StickyTable>
                </div>
            </div>
        )
    }
}