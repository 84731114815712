import React, { Component } from 'react'

export default class InactivePendingTransfers extends Component {
  state = {
    showAllPendingTransfers: false,
  }

  displayPendingTransfers() {
    const pendingTransfersList =
      this.props.accountTransactionsData.accountOrderHistory.pendingTransfers
    if (pendingTransfersList.length > 0) {
      return (
        <div
          style={{
            width: this.props.isSmallScreen ? '100%' : '100%',
            boxShadow:
              '-3px -2px 4px rgba(255, 255, 255, 0.92), 3px 1px 5px rgba(86, 86, 86, 0.2), inset 0px 0px 2px rgba(255, 255, 255, 0.21)',
            borderRadius: '19px',
            marginTop: '25px',
          }}
        >
          <div style={{ padding: '20px' }}>
            <p
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '15px',
              }}
            >
              {this.props.t('pendingtransfers')}
            </p>
            <div
              style={{ width: '100%', display: 'flex', marginBottom: '15px' }}
            ></div>
            {/* {pendingTransfersList.length > 5 ? (
              <div>
                {this.state.showAllPendingTransfers ? (
                  <div>
                    {pendingTransfersList.map((transfer) => {
                      return this.displayPendingTransfersRow(transfer)
                    })}
                  </div>
                ) : (
                  <div>
                    {pendingTransfersList.map((transfer, index) => {
                      if (index < 5)
                        return this.displayPendingTransfersRow(transfer)
                    })}
                  </div>
                )}
              </div>
            ) : (
              pendingTransfersList.map((transfer) => {
                return this.displayPendingTransfersRow(transfer)
              })
            )} */}
            <div style={{ maxHeight: '30vh', overflow: 'auto' }}>
              {pendingTransfersList.map((transfer) => {
                return this.displayPendingTransfersRow(transfer)
              })}
            </div>
          </div>
          {/* {pendingTransfersList.length > 5 ? (
            <div>
              {this.state.showAllPendingTransfers ? (
                <button
                  style={{
                    width: '100%',
                    borderRadius: '0 0 25px 25px',
                    background: 'black',
                    color: 'grey',
                    padding: '8px',
                  }}
                  onClick={() => {
                    this.setState({ showAllPendingTransfers: false })
                  }}
                >
                  {this.props.t('showless')}
                </button>
              ) : (
                <button
                  style={{
                    width: '100%',
                    borderRadius: '0 0 25px 25px',
                    background: 'black',
                    color: 'grey',
                    padding: '8px',
                  }}
                  onClick={() => {
                    this.setState({ showAllPendingTransfers: true })
                  }}
                >
                  {this.props.t('showmore')}
                </button>
              )}
            </div>
          ) : null} */}
        </div>
      )
    } else return null
  }

  displayPendingTransfersRow(transfer) {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ alignContent: 'center' }}>
          <p
            style={{
              color: transfer.type === 'DEPOSITS' ? 'green' : 'red',
              fontSize: '15px',
              marginBottom: '0',
            }}
          >
            {transfer.type}
          </p>
          <p
            style={{
              marginBottom: '0',
              marginRight: '0',
              fontSize: '15px',
              width: '150px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {transfer.name}
          </p>
          <p
            style={{
              marginBottom: '0',
              color: 'rgba(128,128,128,.5)',
              fontSize: '10px',
            }}
          >
            {transfer.transactionDate}
          </p>
        </div>
        <div style={{ alignContent: 'center' }}>
          <p style={{ fontSize: '20px', marginBottom: '0' }}>
            {this.props.formatCurrency(transfer.transactionAmount)}
          </p>
        </div>
      </div>
    )
  }
  render() {
    if (this.props.t) {
      return <div>{this.displayPendingTransfers()}</div>
    } else return null
  }
}
