//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//CONTEXT
import { BudgetingContext } from './Budgeting'

//STYLES
import '../../scss/components/budgeting/ActivityDetails.scss'

//UTILS
import formatCurrency from '../../utility-functions/formatCurrency'

export default function ActivityEmergencyFunds() {
  const { t, budgetingData, setPage } = useContext(BudgetingContext)
  const details = budgetingData?.payload?.budgeting
  const [reservesData, setReservesData] = useState(null)

  useEffect(() => {
    getReserves()
  }, [])

  const getReserves = () => {
    const expectedReserve = details?.incStat?.expense_2m * 3
    const currentReserve = Math.min(
      expectedReserve,
      details?.finStat?.asset?.sumDepository
    )
    let message = ''
    let width = '0'
    if (currentReserve >= expectedReserve) {
      message = t('you_have_recommended_cash_reserve_for_emergency')
      width = '100%'
    } else if (currentReserve < expectedReserve) {
      message = t('you_are_below_recommended_cash_reserved_for_emergencies')
      width = `${(currentReserve / expectedReserve) * 100}%`
    }

    setReservesData({ expectedReserve, currentReserve, message, width })
  }

  return (
    <div className='activity-emergency-funds'>
      <p className='activity-details-section-header'>{t('emergency_funds')}</p>
      <div className='budgeting-row'>
        <p className='budgeting-label big-text'>{t('available_funds')}:</p>
        <p className='budgeting-value big-text'>
          {t(formatCurrency(reservesData?.currentReserve))}
        </p>
      </div>
      <div className='bar-full-container'>
        <div
          className='bar-container'
          style={{
            width: reservesData?.width,
            backgroundColor: '#3627e4',
          }}
        >
          <p className='bar-label'>
            {parseFloat(reservesData?.width)?.toFixed(2)}%
          </p>
        </div>
      </div>
      <p className='emergency-fund-message'>
        {t('based_on_an_emergency_fund_of')}{' '}
        {formatCurrency(reservesData?.expectedReserve)} {t('six_mo_expenses')}{' '}
        {reservesData?.message}
      </p>
    </div>
  )
}
