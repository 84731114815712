import React, { Component } from "react";
import FeaturePart from "../FeaturePart";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import PieChartOfFeature from "../PieChartOfFeature";
import FeatureHiddenText from "../FeatureHiddenText";

const pre = "<";
const next = ">";

class Features extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.state = {
      isSmallScreen: false,
      isEnglish: this.props.language,
      features: [
        {
          id: 1,
          title: "AUTONOMOUS AI WEALTH MANAGEMENT",
          content: [
            {
              id: 1,
              iconUrl: `/images/AI-1.png`,
              title: `Automated, periodic rebalancing`,
              detail: `You choose a strategy and investment amount, and an AI optimizes trade orders to best lower risk and reap reward.`
            },
            {
              id: 2,
              iconUrl: `/images/AI-2.png`,
              title: `Tax optimization`,
              detail: `AllocateRite advises investment in tax-efficient ETFs and automatically accounts for your unique tax situation.`
            },
            {
              id: 3,
              iconUrl: `/images/AI-3.png`,
              title: `24/7 risk monitoring & reporting`,
              detail: `AI provides proactive risk monitoring and disciplined execution of investment decisions, helping guide portfolios (and individuals) through the constant swirl of the markets.`
            }
          ]
        },
        {
          id: 2,
          title: "TRUSTED",
          content: [
            {
              id: 1,
              iconUrl: `/images/TRUST-1.png`,
              title: `Provides strategies used by professional investment firms`,
              detail: `AllocateRite is providing both turn-key solutions via its four core <a href="http://www.allocaterite.com/Strategies">strategies<\a> and tailored alpha overlays for institutional users with access to BlackRock Aladdin strategies.`
            },
            {
              id: 2,
              iconUrl: `/images/TRUST-2.png`,
              title: `Published track record`,
              detail: ` Event tested and established under varying <a href="http://www.allocaterite.com/Strategies">conditions<\a>.`
            },
            // {
            //   //THIS IS THE ISSUE
            //   id: 3,
            //   iconUrl: `/images/TRUST-3.png`,
            //   title: `GIPS compliant & verified`,
            //   detail: `You are ensured the AllocateRite track record is reliable and not cherry picked. Click here to see a GIPS appoved presentation`
            // }
          ]
        },
        {
          id: 3,
          title: "YOU’RE IN CONTROL",
          content: [
            {
              id: 1,
              iconUrl: `/images/CONTROL-1.png`,
              title: `Use your current broker`,
              detail: `Choose your preferred broker across <a href="http://www.allocaterite.com/ProvideList">2500 custodians</a>.`
            },
            {
              id: 2,
              iconUrl: `/images/CONTROL-2.png`,
              title: `Select any ETF issuer`,
              detail: `Provided the ETFs you choose track the defaults well, they can be reliably slotted into any strategy.`
            },
            {
              id: 3,
              iconUrl: `/images/CONTROL-3.png`,
              title: `Maintain full discretion over investments`,
              detail: `You maintain custody of your capital and are the ultimate arbiter on all investment decisions.`
            }
          ]
        }
      ],
      chartData: [
        {
          datasets: [
            {
              id: 1,
              data: [70.97, 25, 4.03],
              backgroundColor: [
                "rgba(126, 192, 101, 0.8)",
                "rgba(241, 98, 104, 0.8)",
                "rgba(93, 199, 208, 0.8)"
              ],
              borderColor: "#FFFFFF",
              borderWidth: "6",
              hoverBackgroundColor: [
                "rgba(126, 192, 101, 2)",
                "rgba(241, 98, 104, 2)",
                "rgba(93, 199, 208, 2)"
              ]
            }
          ],
          labels: ["Equity", "Fixed-Income", "Cash"]
        },
        {
          datasets: [
            {
              id: 2,
              data: [76.63, 21.32, 2.05],
              backgroundColor: [
                "rgba(126, 192, 101, 0.8)",
                "rgba(241, 98, 104, 0.8)",
                "rgba(93, 199, 208, 0.8)"
              ],
              borderColor: "#FFFFFF",
              borderWidth: "6",
              hoverBackgroundColor: [
                "rgba(126, 192, 101, 2)",
                "rgba(241, 98, 104, 2)",
                "rgba(93, 199, 208, 2)"
              ]
            }
          ],
          labels: ["Equity", "Fixed-Income", "Cash"]
        },
        {
          datasets: [
            {
              id: 3,
              data: [86.33, 13.67, 0],
              backgroundColor: [
                "rgba(126, 192, 101, 0.8)",
                "rgba(241, 98, 104, 0.8)",
                "rgba(93, 199, 208, 0.8)"
              ],
              borderColor: "#FFFFFF",
              borderWidth: "6",
              hoverBackgroundColor: [
                "rgba(126, 192, 101, 2)",
                "rgba(241, 98, 104, 2)",
                "rgba(93, 199, 208, 2)"
              ]
            }
          ],
          labels: ["Equity", "Fixed-Income", "Cash"]
        },
        {
          datasets: [
            {
              id: 4,
              data: [92.5, 7.5, 0],
              backgroundColor: [
                "rgba(126, 192, 101, 0.8)",
                "rgba(241, 98, 104, 0.8)",
                "rgba(93, 199, 208, 0.8)"
              ],
              borderColor: "#FFFFFF",
              borderWidth: "6",
              hoverBackgroundColor: [
                "rgba(126, 192, 101, 2)",
                "rgba(241, 98, 104, 2)",
                "rgba(93, 199, 208, 2)"
              ]
            }
          ],
          labels: ["Equity", "Fixed-Income", "Cash"]
        }
      ],
      prevIcon: <span className="glyphicon glyphicon-glass">{pre}</span>,
      nextIcon: <span className="glyphicon glyphicon-glass">{next}</span>,
      showResults: false
    };
  }

  onClick() {
    if (this.state.showResults) {
      this.setState({ showResults: false });
    } else {
      this.setState({ showResults: true });
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    if (window.innerWidth < 600) {
      this.setState({ isSmallScreen: true });
    }
  }

  render() {
    const { nextIcon, prevIcon } = this.state;
    return (
      <div style={{marginTop:50}}>
        <Container>
          <Row className="features-subtitle-row">
            <p className="features-subtitle-text">
              AUTONOMOUS AI WEALTH MANAGEMENT
            </p>
          </Row>

          <Row>
            <Col sm={6} className="features-content">
              <FeaturePart
                key={this.state.features[0].id}
                value={this.state.features[0]}
              />
            </Col>
            <Col sm={2} />
            <Col sm={4} className="features-content-icons">
              <img src="/images/cloudAI.svg" width="70%" alt="features-img" />
            </Col>
          </Row>

          <Row className="features-subtitle-row">
            <p className="features-subtitle-text">TRUSTED</p>
          </Row>
          {this.state.isSmallScreen ? (
            <Row>
              <Col sm={6} className="features-content">
                <FeaturePart
                  key={this.state.features[1].id}
                  value={this.state.features[1]}
                />
              </Col>
              <Col sm={2} />
              <Col sm={4} className="features-content-icons">
                <img src="/images/compass.svg" width="67%" alt="features-img" />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={5} className="features-content-icons">
                <img
                  className="second-img-of-features"
                  src="/images/compass.svg"
                  width="67%"
                  alt="features-img"
                />
              </Col>
              <Col sm={6} className="features-content">
                <FeaturePart
                  key={this.state.features[1].id}
                  value={this.state.features[1]}
                />
              </Col>
              <Col sm={1} />
            </Row>
          )}

          <Row className="features-subtitle-row">
            <p className="features-subtitle-text">YOU’RE IN CONTROL</p>
          </Row>

          <Row>
            <Col sm={6} className="features-content">
              <FeaturePart
                key={this.state.features[2].id}
                value={this.state.features[2]}
              />
            </Col>
            <Col sm={2} />
            <Col sm={4} className="features-content-icons">
              <img src="/images/control.svg" width="65%" alt="features-img" />
            </Col>
          </Row>
        {/*
          <Row className="features-subtitle-row">
            <p className="features-subtitle-text">DIVERSIFIED</p>
          </Row>
           <Row className="features-diversified">
            <Col sm={5} className="features-diversified-chart">
              <Carousel
                className="diversified-charts-switch"
                nextIcon={nextIcon}
                prevIcon={prevIcon}
              >
                <Carousel.Item>
                  <PieChartOfFeature
                    key={this.state.chartData[0].id}
                    value={this.state.chartData[0]}
                    titleText="International Global Composite 2019 - Aug"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <PieChartOfFeature
                    key={this.state.chartData[1].id}
                    value={this.state.chartData[1]}
                    titleText="Dynamic Global Blend 2019 - Aug"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <PieChartOfFeature
                    key={this.state.chartData[2].id}
                    value={this.state.chartData[2]}
                    titleText="U.S. Domestic Composite AI Adjust 2019 - Aug"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <PieChartOfFeature
                    key={this.state.chartData[3].id}
                    value={this.state.chartData[3]}
                    titleText="U.S. Domestic Composite 2019 - Aug"
                  />
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col sm={1} />
            <Col sm={5} className="diversified-features-content">
              <p className="diversified-title">ETFs and Cash Management</p>
              <p className="diversified-text-show">
                To optimize risk-adjusted returns and reduce taxes, AllocateRite
                dynamically rebalances your portfolio’s ETFs among stock
                sectors, bonds, and cash.{" "}
              </p>
              <input
                className="diversified-text-button"
                type="submit"
                value={this.state.showResults ? "Show Less" : "Learn More..."}
                onClick={this.onClick}
              />
              {this.state.showResults ? (
                <FeatureHiddenText isChinese={false} />
              ) : null}
            </Col>
            <Col sm={1} />
          </Row> */}
        </Container>
      </div>
    );
  }
}

export default Features;
