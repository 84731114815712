import React from 'react'
import { Card, Row, } from "react-bootstrap";
import { FaSearch, FaQuestionCircle, } from "react-icons/fa";
import Steps from './Steps'
import SingleCompanyCard from "../singleCompanyCard"
import ErrorModal from "../ErrorModal"
import MobileTickerList from "./mobileTickerList"
import {CSVReader} from 'react-papaparse'
import ENDPOINT from '../Endpoint'
import SearchEndpoint from '../SearchEndpoint';

const brandColor = "#00A99D"


export default class PortConstructor extends React.Component {

    state = {
        searchMomentumText: this.props.isSmallScreen? '':'enter a symbol',
        momDataSource: [],
        sudoPortfolioStocks: this.props.prevSet,
        hover: false,
        dropDown: true,
        total: 0,
        showWatchlist: this.props.isSmallScreen? true:false,
        showMassAdd: false,
        massAddTable: [["",""],["",""]],
        errors: '',
        showErrorModal: false,
        cash: 0,
        cashModal: false,
        fileImportModal: false,
        loading: false,
        returnsConstraint: false,
        objDesc: false,
        uploadSuccessful: false,
        massAddByShares: true,
        showCustoms: false,
        holdings: [],
        rtVal: 5,
        rtDesc:false,
        massAddBy: 'quantity'
    }

    componentDidMount(){
        // this.getPlaidAccounts()
        this.getPlaidHoldings()
    }

    getPlaidItems = ()=>{ // an Item is all the accounts at one place (multiple accounts at robinhood)
        fetch(`${ENDPOINT}/plaid/items`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => console.log('Items response', responseJson))
          .catch(err=>console.log(`catching errors`, err))
    }
    
    getPlaidAccounts = ()=>{ // an Account is one account at one place (ie. one account at robinhood)
        fetch(`${ENDPOINT}/plaid/accounts`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => console.log('Accounts response', responseJson))
          .catch(err=>console.log(`catching errors`, err))
    }
    
    getPlaidHoldings = ()=>{ // all holdings of all linked accounts combined
        fetch(`${ENDPOINT}/plaid/holdings`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => this.setState({holdings: responseJson}))
          .catch(err=>console.log(`catching errors`, err))
    }

    arrayToObject = (arr) => {
        let result = {};
        for (let i = 0; i < arr.length; i++) {
            result[arr[i].symbol] = {allocation:arr[i].allocation, shares:arr[i].shares};
        }
        return result
    }

    removeCommas(numberAsString){
        return (parseFloat(numberAsString.split(",").join("")))
    }

    addCommas(flt){
        return flt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    checkCusip(str){
        const re = new RegExp("(^|[^a-zA-Z0-9])[0-9]{3}[a-zA-Z0-9]{6}([^a-zA-Z0-9]|$)");
        return re.test(str) 
    }

    massAdd = ()=>{
        let cash = this.state.cash
        let totalValue = this.state.total
        let total = this.state.massAddByShares ? parseFloat(this.state.cash):parseFloat(this.state.cash)+1000000
        let lst = new Array()
        let sps = new Array()
        let tableObj = {}
        this.state.massAddTable.forEach((ele)=> {
            if(ele[0] === '$$'){
                // cash+= ele[1]
                // total+= ele[1]
                cash = parseFloat(cash) + parseFloat(ele[1])
                total = parseFloat(total) + parseFloat(ele[1])
            }else{
                let sym = (ele[0].split('-')[0]).toUpperCase()
                console.log('sym', sym)
                tableObj[sym]=parseFloat(ele[1])
            }
        })
        // fetch(`${ENDPOINT}/risk-monkey/momentum/bulk?realtime=true`,{
            
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify(Object.keys(tableObj))
        // })
        fetch(`${SearchEndpoint}/ticker-bulk?search=${Object.keys(tableObj)}`)
        .catch(err=> console.log('catching: ', err))
        .then(resp=>resp.json())
        .then(responseJson=>{
            responseJson.forEach((ele, i)=>{
                let obj
                if(this.state.massAddBy === 'quantity'){
                    if(this.checkCusip(ele.symbol)){
                        total += Math.abs(tableObj[ele.symbol])
                        obj = {
                            ...ele,
                            shares: (tableObj[(ele.symbol).toUpperCase()] / 100).toFixed(2),
                            value: (tableObj[(ele.symbol).toUpperCase()] / 100 * ele.price).toFixed(2),
                            allocation: 0,
                            type:'FixedIncome'

                        }
                        
                    }else{
                        total += ele.price*tableObj[ele.symbol]
                        obj = {
                                ...ele, 
                                shares:tableObj[ele.symbol], 
                                value:((ele.price*tableObj[ele.symbol]).toFixed(2)), 
                                allocation:0
                        }
                    }
                }
                else if (this.state.massAddBy === 'allocation'){
                    if (totalValue > 0){
                        obj = {
                            ...ele,
                            shares:((totalValue*tableObj[ele.symbol]/100)/ele.price).toFixed(2),
                            value:(totalValue*tableObj[ele.symbol]/100).toFixed(2),
                            allocation:Math.abs(tableObj[ele.symbol]) ,
                        }
                    }
                    else{
                        obj = {
                            ...ele,
                            shares:0,
                            value:0,
                            allocation:Math.abs(tableObj[ele.symbol]) ,
                        }
                    }
                }
                else if(this.state.massAddBy === 'dollarValue'){
                    total += Math.abs(tableObj[ele.symbol])
                    obj = {
                        ...ele, 
                        shares:((tableObj[ele.symbol]/ele.price).toFixed(2)), 
                        value:tableObj[ele.symbol],
                        allocation:0
                    }
                }
                else{
                    console.log('no matching Add by')
                }
                // console.log(ele.symbol ,tableObj[ele.symbol])
                // let obj = {
                //             ...ele, 
                //             shares:this.state.massAddByShares?tableObj[ele.symbol]:0, 
                //             value:this.state.massAddByShares?((ele.price*tableObj[ele.symbol]).toFixed(2)):0, 
                //             allocation: this.state.massAddByShares? 0:tableObj[ele.symbol]
                //         }
                lst.push(obj)
                // lst.forEach(ele=>{
                //     sps.push({...ele, allocation: (ele.value / total * 100).toFixed(2)})
                // })
                } 
            )
            
            if(this.state.massAddBy === 'quantity' || this.state.massAddBy === 'dollarValue'){
                lst.forEach(ele=>{
                    sps.push({...ele, allocation: (Math.abs(ele.value) / total * 100).toFixed(2)})
                })
            }else if(totalValue !== 0){
                lst.forEach(ele=>{
                    sps.push({...ele, allocation: (ele.allocation).toFixed(2), })
                })
            }else{
                lst.forEach(ele=>{
                    sps.push({...ele, shares:((ele.allocation * total / 100)/ele.price).toFixed(2), allocation: (ele.allocation).toFixed(2), value: (ele.allocation * total / 100).toFixed(2)})
                })
            }
        }
        )
        .then(this.setState({sudoPortfolioStocks: sps, showMassAdd:false, total, loading:false, cash, massAddTable: [["",""],["",""]], }))
        }

    // massAddOld = ()=>{
    //     let cash = this.state.massAddByShares ? 0: 1000000
    //     let lst = new Array()
    //     let sps = new Array()
    //     let total = parseFloat(this.state.cash)
    //     this.state.massAddTable.forEach((ele, idx)=>{
    //         if (ele[0].length > 0 && ele[1] > 0 && ele[0] !== '$$'){
    //         let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${ele[0]}`
    //         fetch(url, {
    //                 "Access-Control-Allow-Headers": {
    //                     "Content-Type": "application/json",
    //                     "Cache-Control": "no-cache",
    //                     "Access-Control-Request-Headers": "*",
    //                     "Access-Control-Allow-Origin": "*",
    //                     "Access-Control-Allow-Methods": "*",
    //                 },
    //             })
                
    //             .then((resp) => resp.json())
    //             // .then(this.setState({loading:true}))
    //             .then((responseJson) => {
    //                 console.log('responseJson', responseJson)
    //                 if(responseJson.content[0]){
    //                     total += responseJson.content[0].price*ele[1]
    //                     let obj = {...responseJson.content[0], shares:this.state.massAddByShares?ele[1]:0, value:(responseJson.content[0].price*ele[1]).toFixed(2), allocation: this.state.massAddByShares?0:ele[1]}
    //                     lst.push(obj)
    //                     if (lst.length === this.state.massAddTable.length - 1){
    //                         lst.forEach(ele=>{
    //                             sps.push({...ele, allocation: (ele.value / total * 100).toFixed(2)})
    //                         })
    //                     }
    //                 }
    //             })
    //         }
    //         else if(ele[0] === '$$'){
    //             cash = ele[1]
    //         }
    //     })
    //     this.setState({sudoPortfolioStocks: sps, showMassAdd:false, total, loading:false, cash})
    // }

    setTableCell = (e, index, column) => {
        let value = e.target.value
        let updated = [...this.state.massAddTable]
        let final
        if(column === 0 || (column === 1 && (value > 0 || value === ""))){   
            updated[index][column] = value
        }
        if (updated.length - 1 === index && column === 1){
            final = [...updated,["",""]]
        }else{
            final = [...updated]
        }
        this.setState({massAddTable: final})
    }  

    singleStockConstraint=()=>{
        let total = 0
        this.state.sudoPortfolioStocks.forEach(ele=>{
            if(ele.type === 'Equity'){
                total+=1
            }
        })
        return (100/total*1.5)
    }

    cashModal = ()=>{
        if(this.state.cashModal){
        return (
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', width: '100%'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', }}>
                        <div style={{width:'20%'}}/>
                        <h2>Import</h2>
                        <div style={{width:'20%',}}>
                            <div style={{backgroundColor:'rgb(253,234,234)', borderRadius: 10, width: 40, display: 'flex', justifyContent:'center', alignItems: 'center', cursor: 'pointer'}} onClick={()=>this.setState({cashModal:false})}>
                                <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(187,58,60)'}}>
                                    X
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                        <input type="number" value={this.state.cash} onChange={(e)=>{
                            let cash = parseFloat(e.target.value)
                            this.setState({cash})
                        }
                        }/>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'center', marginTop: 10}}>
                        <div style={{display:'flex', alignItems:'center', justifyContent: 'center', backgroundColor:'rgb(226,238,227)', borderRadius: 8, padding: 10, width: '20%', cursor:'pointer'}} onClick={()=>{
                            let total = this.state.total + this.state.cash
                            let sudoPortfolioStocks = this.state.sudoPortfolioStocks.map(ele=>{
                                return(
                                    {...ele, allocation: (ele.value/total*100).toFixed(2)}
                                )
                            })
                            this.setState({total, cashModal:false, sudoPortfolioStocks})
                        }}>
                            <p style={{margin: 0, color: 'rgb(51,173,53)', fontWeight: 'bold' }}>
                                Add Cash
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          )}
    }
    showErrors = ()=>{
        if (this.state.errors){
            return (
                <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', width: '75%', margin:'auto'}}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(253,180,181)', borderRadius: 10, padding: 10, marginBottom: 5, width: '100%'}}>
                        <p style={{margin: 0}}>{this.state.errors}</p>
                    </div>
                </div>
            )
        }else{
            if(this.state.uploadSuccessful){
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '75%',margin:'auto' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(234,255,219)', borderRadius: 10, padding: 10, marginBottom: 5, width: '100%' }}>
                            <p style={{ margin: 0 }}>Successfully Imported</p>
                        </div>
                    </div>
                )
            }
        }
    }

    removeDollarSign = (string)=>{
        if (typeof string !== 'undefined' && string.includes('$')){
            return string.replace('$', '')
        }else{
            return string
        }

    }

    removePercentSign = (string)=>{
        // console.log('string',string)
        if (typeof string !== 'undefined' && string.includes('%')){
            return string.replace('%', '')
        }else{
            return string
        }

    }

    fileImportModal = ()=>{
        if(this.state.fileImportModal){
        return (
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', width: '80%', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, height: '85%', padding:20}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', }}>
                        <div style={{width:'20%',}}/>
                            <h2>Import From a File</h2>
                            
                        <div style={{width:'20%',}}>
                            <div style={{backgroundColor:'rgb(253,234,234)', borderRadius: 10, width: 40, display: 'flex', justifyContent:'center', alignItems: 'center', cursor: 'pointer'}} onClick={()=>this.setState({fileImportModal:false})}>
                                <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(187,58,60)'}}>
                                    X
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', margin:'auto', marginBottom: 0, flexDirection:'column'}}>
                        <p style={{margin:0, margin:'auto'}}>Your CSV file should have two columns called 'Symbol' and 'Number of Shares' or 'Allocation'</p>
                        <p style={{margin:0, margin:'auto'}}>If you have excess cash in your portfolio that you would like optimized please use '$$' as the symbol</p>
                    </div>
                    <div style={{display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width: '80%', margin:'auto'}}>
                    <CSVReader
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        style={{}}
                        config={{}}
                        addRemoveButton
                        onRemoveFile={()=>this.handleOnRemoveFile}
                        onFileLoad={(e)=>{
                            // console.log('e',e)
                            let arr = []
                            let errorRows = []
                            let symColumn = -1
                            let quantityColumn = -1
                            let pcntColumn = -1
                            let dolValColumn = -1
                            // if(e[0].data.length !== 2){
                            //     this.setState({errors:'too many columns'})
                            // }
                            // else if(e[0].data[0].toLowerCase() !== 'symbol' || e[0].data[1].toLowerCase() !== 'number of shares'){
                            //     this.setState({errors:'incorrect column names'})
                            // }
                            /* LOOP THROUGH COLUMN NAMES TO DECIDE WHICH COLUMN IS WHICH */
                            e[0].data.forEach((ele, i)=>{ 
                                if(ele.toLowerCase() === 'symbol'|| ele.toLowerCase() === 'ticker' || ele.toLowerCase() === 'stock symbol' || ele.toLowerCase() === 'symb' || ele.toLowerCase() === 'symbols' || ele.toLowerCase() === 'stock' || ele.toLowerCase() === 'asset' ||ele.toLowerCase() === 'cusip' ||ele.toLowerCase() === 'company' || ele.toLowerCase() === 'security id'){
                                    if(symColumn === -1){
                                        symColumn = i
                                    }
                                }
                                if (ele.toLowerCase() === 'quantity' || ele.toLowerCase() === 'quantity of shares' || ele.toLowerCase() === 'number of shares' || ele.toLowerCase() === 'num of shares'|| ele.toLowerCase() === 'num shares'|| ele.toLowerCase() === 'shares'){
                                    if(quantityColumn === -1){
                                        quantityColumn = i
                                    }
                                }
                                if (ele.toLowerCase() === 'dollar value' || ele.toLowerCase() === 'value' || ele.toLowerCase() === '$ value' || ele.toLowerCase() === 'market value' || ele.toLowerCase() === 'mkt value' || ele.toLowerCase() === 'mkt. value' || ele.toLowerCase() === 'val'){
                                    if(dolValColumn === -1 && quantityColumn === -1){
                                        dolValColumn = i
                                    }
                                }
                                if (ele.toLowerCase() === 'allocation' || ele.toLowerCase() === '%' || ele.toLowerCase() === 'allocation %' || ele.toLowerCase() === '100%' || ele.toLowerCase() === 'percent' || ele.toLowerCase() === 'percent allocation' || ele.toLowerCase() === '% of portfolio' || ele.toLowerCase() === '% portfolio' || ele.toLowerCase() === 'weight' || ele.toLowerCase() === 'weight %' || ele.toLowerCase() === 'weight%' ){
                                    if(pcntColumn === -1 && dolValColumn === -1 && quantityColumn === -1){
                                        pcntColumn = i
                                    }
                                }
                                if (quantityColumn >=0){
                                    this.setState({massAddByShares: true})
                                    this.setState({massAddBy: 'quantity'})
                                }
                                else if (pcntColumn >=0){
                                    this.setState({massAddBy: 'allocation'})
                                }
                                else if (dolValColumn >=0){
                                    this.setState({massAddBy: 'dollarValue'})
                                }
                                else{
                                    this.setState({massAddByShares: false})
                                }
                            })

                            /* if the necessary columns are there loop through data and get necessary info  */
                            // console.log('symColumn: ',symColumn)
                            // console.log('quantityColumn: ',quantityColumn)
                            // console.log('pcntColumn: ',pcntColumn)
                            // console.log('dolValColumn: ',dolValColumn)

                            if(symColumn !== -1 && (quantityColumn !== -1 || pcntColumn !== -1 || dolValColumn !== -1)){
                                    e.forEach((ele,i)=>{
                                        if(ele.data[symColumn].length !== 0){
                                            if(ele.data[symColumn].length === 0){
                                                console.log(`error in row ${i} because missing data`)
                                                if(!errorRows.includes(i)){
                                                    errorRows.push(` ${i}`)
                                                } 
                                            }
                                            if (i>0 && quantityColumn !== -1 && isNaN((ele.data[quantityColumn]))){
                                                console.log(`error in row ${i} because missing data in quantity column`)
                                                if(!errorRows.includes(i)){
                                                    errorRows.push(` ${i}`)
                                                }
                                            }
                                            else if (i>0 && dolValColumn !== -1 && isNaN(this.removeDollarSign(ele.data[dolValColumn]))){
                                                console.log(`error in row ${i} because missing data in dolval column`)
                                                if(!errorRows.includes(i)){
                                                    errorRows.push(` ${i}`)
                                                }
                                            }
                                            else if (i>0 && pcntColumn !== -1 && isNaN(this.removePercentSign(ele.data[pcntColumn]))){
                                                console.log(`error in row ${i} because missing data in allocation column`)
                                                if(!errorRows.includes(i)){
                                                    errorRows.push(` ${i}`)
                                                }
                                            }
                                            else{
                                                // console.log(`success in row ${i}`)
                                                if(quantityColumn >= 0){
                                                    arr.push([ele.data[symColumn],ele.data[quantityColumn]])
                                                }else if(dolValColumn >= 0) {
                                                    arr.push([ele.data[symColumn],ele.data[dolValColumn]])
                                                }else{
                                                    arr.push([ele.data[symColumn],ele.data[pcntColumn]])
                                                    
                                                }
                                            }
                                        }
                                    })
                                    if (errorRows.length === 1){
                                        this.setState({errors: `problems in row: ${errorRows}`})
                                    }else if (errorRows.length > 1){
                                        this.setState({errors: `problems in rows: ${errorRows}`})
                                    }
                                    else{
                                        this.setState({massAddTable: arr.slice(1,arr.length), uploadSuccessful: true})
                                    }
                            }else if(symColumn !== -1){
                                console.log('only symbols')
                                console.log('e', e)
                                e.forEach((ele,i)=>{
                                    if(ele.data[symColumn].length !== 0 && i > 0){
                                        console.log('ele', ele)
                                        arr.push([ele.data[symColumn],0])
                                    }
                                })
                                console.log('arr',arr)
                                this.setState({massAddBy:'allocation', total:1000000, massAddTable: arr, noValues: true, errors:"We could not find any of the following optional categories: Quantity, Allocation, or Value, we Recommend adding one of these columns. Otherwise, Click 'Import' to import anyway"})
                            }
                            else{
                                if(symColumn === -1 ){
                                    this.setState({errors:'We could not find column: "Symbol"'})
                                }
                                else{
                                    this.setState({errors:"We could not find any of the following optional categories: Quantity, Allocation, or Value. Click 'Import' to import anyway"})
                                }
                            }
                        }}
                    >
                        <span>Drop CSV File Here or Click Browse Files</span>
                        {/* <span style={{width:'100%'}}>Click to Browse Files or Drag and Drop a .csv file </span> */}
                    </CSVReader>
                    </div>
                    <div style={{display:'flex',margin:'auto',width:'30%', marginTop: '1%'}}>
                        {this.showErrors()}
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'center', marginTop: 10}}>
                        <div style={{display:'flex', alignItems:'center', justifyContent: 'center', backgroundColor:'rgb(226,238,227)', borderRadius: 8, padding: 10, width: '20%', cursor:'pointer'}} onClick={()=>{
                            if(!this.state.errors || this.state.errors==="We could not find any of the following optional categories: Quantity, Allocation, or Value. Click 'Import' to import anyway"){
                                // console.log('this.state.massAddTable', this.state.massAddTable)
                                this.massAdd()
                                this.setState({fileImportModal:false})
                            }
                            else{
                                this.setState({errors:'Please Uploaded Corrected CSV file'})
                            }
                            }}>
                            <p style={{margin: 0, color: 'rgb(51,173,53)', fontWeight: 'bold' }}>
                                Convert to Portfolio
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          )}
    }

    reOpt = (arr)=>{
        let converted = []
        arr.forEach(ele=>converted.push([ele.symbol, ele.quantity]))
        this.setState({massAddTable: converted})
    }

    getHoldings = (id)=>{
        let arr = []
        this.state.holdings.forEach(ele=>{
            if(ele.accountId === id){
                arr.push([ele.symbol, ele.quantity])
            }
        })
        this.setState({massAddTable: arr})
    }

    customsModal = ()=>{
        let arr = []
        let arr2 = []
        if(this.state.showCustoms){
        let header = 
            <div style={{display:"flex", flexDirection:'row', width:'100%', border:'solid', borderWidth:.25, borderColor:'#ddd'}} onClick={()=>console.log(`clicked header`)}>
                <div style={{width:'50%', display:'flex',alignItems:'center',justifyContent:'center',borderRight:'solid', borderWidth:.25, borderColor:'#ddd'}}>
                    Portfolio Name
                </div>
                <div style={{width:'50%', display:'flex',alignItems:'center',justifyContent:'center'}}>
                    Date Created
                </div>
            </div>
        arr.push(header)
        Object.keys(this.props.customPorts).forEach((ele, i)=>{
            arr.push(
                <div style={{display:"flex", flexDirection:'row', width:'100%', border:'solid', borderWidth:.25, borderColor:'#ddd', cursor:'pointer'}} onClick={()=>this.reOpt(this.props.customPorts[ele])}>
                    <div style={{width:'50%', display:'flex',alignItems:'center',justifyContent:'center',borderRight:'solid', borderWidth:.25, borderColor:'#ddd'}}>
                        {ele}
                    </div>
                    <div style={{width:'50%', display:'flex',alignItems:'center',justifyContent:'center'}}>
                        {this.props.customPorts[ele][0].dateCreated}
                    </div>
                </div>
            )
        }) 
        if(this.props.linkedPorts){
            arr2.push( 
                <div style={{display:"flex", flexDirection:'row', width:'100%', border:'solid', borderWidth:.25, borderColor:'#ddd'}} onClick={()=>console.log(`clicked header`)}>
                    <div style={{width:'50%', display:'flex',alignItems:'center',justifyContent:'center',borderRight:'solid', borderWidth:.25, borderColor:'#ddd'}}>
                        Insitution
                    </div>
                    <div style={{width:'50%', display:'flex',alignItems:'center',justifyContent:'center'}}>
                        Account Number
                    </div>
                </div>
            )
            this.props.linkedPorts.forEach(ele=>{
                ele.accounts.forEach(innerEle=>{
                    arr2.push(
                        <div style={{display:"flex", flexDirection:'row', width:'100%', border:'solid', borderWidth:.25, borderColor:'#ddd'}} onClick={()=>this.getHoldings(innerEle.accountId)}>
                            <div style={{width:'50%', display:'flex',alignItems:'center',justifyContent:'center',borderRight:'solid', borderWidth:.25, borderColor:'#ddd', textAlign:'center'}}>
                                {ele.instName}
                            </div>
                            <div style={{width:'50%', display:'flex',alignItems:'center',justifyContent:'center', textAlign:'center'}}>
                                {innerEle.name}
                            </div>
                        </div>
                    )
                })
                
            })
        }
        return (
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, padding:20}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', }}>
                        {/* <div style={{width:'20%', }}/> */}
                        <div style={{width:'80%', display:'flex', flexDirection:'column', textAlign:'center'}}>
                            <div style={{width:'100%', display:'flex', flexDirection:'column', textAlign:'center'}}>
                                <h5>Select a Portfolio to</h5>
                                <h5>Re-Optimize</h5>
                            </div>
                        </div>
                        <div style={{width:'20%',}}>
                            <div style={{backgroundColor:'rgb(253,234,234)', borderRadius: 10, width: 40, display: 'flex', justifyContent:'center', alignItems: 'center', cursor: 'pointer'}} onClick={()=>this.setState({showCustoms:false})}>
                                <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(187,58,60)'}}>
                                    X
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', margin:0, flexDirection:'column', width:'100%', justifyContent:'center', alignItems:'center'}}>
                        <p style={{margin:'auto'}}>Created Strategies</p>
                    </div>
                    <div style={{display:'flex', margin:'auto', flexDirection:'column', width:'100%', marginBottom: 20}}>
                        {arr}
                    </div>
                    <div style={{display:'flex', margin:0, flexDirection:'column', width:'100%', justifyContent:'center', alignItems:'center'}}>
                        <p style={{margin:'auto'}}>Linked Portfolios</p>
                    </div>
                    <div style={{display:'flex', margin:'auto', flexDirection:'column', width:'100%', marginBottom: 20}}>
                        {arr2}
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'center', marginTop: 10}}>
                        <div style={{display:'flex', alignItems:'center', justifyContent: 'center', backgroundColor:'rgb(226,238,227)', borderRadius: 8, padding: 10, cursor:'pointer'}} onClick={()=>{
                            if(!this.state.errors && JSON.stringify(this.state.massAddTable) !== JSON.stringify([["",""],["",""]])){
                                // console.log('this.state.massAddTable', this.state.massAddTable)
                                this.massAdd()
                                this.setState({showCustoms:false})
                            }
                            }}>
                            <p style={{margin: 0, color: 'rgb(51,173,53)', fontWeight: 'bold' }}>
                                {JSON.stringify(this.state.massAddTable) !== JSON.stringify([["",""],["",""]])?'Re-Optimize Portfolio':'Select a Portfolio Above'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          )}
    }

    setTotal = (total)=>{
        this.setState({total})
    }

    massAddModal = ()=>{
        let total = 0
        if(this.state.showMassAdd){
            let {massAddTable} = this.state
            let table 
            if (this.state.massAddBy === 'allocation'){
                table = [
                    <div style={{border:'solid', display:'flex',justifyContent:'center',alignItems:'center',minWidth: 200, width:'100%' }}>
                        <div style={{borderRight:'solid', width: '100%',}}>
                            <p style={{margin:0, textAlign:'center', fontWeight: 'bold'}}>Total Portfolio Value (dollars)</p>
                        </div>
                        <div style={{width: '100%',}}>
                            <input style={{width:'100%', border:'none', textAlign:'center'}} type="number" value={this.state.total} onChange={(e)=> this.setTotal(parseFloat(e.target.value))}/>
                        </div>
                    </div>
                    ,
                    <div style={{border:'solid',width: '100%', display:'flex', minWidth: 200}}>
                    <div style={{width:'100%', padding: 5, display:'flex', justifyContent:'center',alignItems:'center', borderRight:'solid'}}>
                        <p style={{margin:0, fontWeight: 'bold'}}>Stock Symbol</p>
                    </div>
                    <div style={{width:'100%', padding: 5, display:'flex', justifyContent:'center',alignItems:'center', }}>
                        {this.state.massAddBy === 'quantity' ? <p style={{margin:0, fontWeight: 'bold'}}># of Shares</p>:null}
                        {this.state.massAddBy === 'allocation' ? <p style={{margin:0, fontWeight: 'bold'}}>Percentage</p>:null}
                        {this.state.massAddBy === 'dollarValue' ? <p style={{margin:0, fontWeight: 'bold'}}>Dollar Value</p>:null}
                    </div>
                </div>
                ]
            }else{
                table = [
                    <div style={{border:'solid',width: '100%', display:'flex', minWidth: 200}}>
                        <div style={{width:'100%', padding: 5, display:'flex', justifyContent:'center',alignItems:'center', borderRight:'solid'}}>
                            <p style={{margin:0, fontWeight: 'bold'}}>Stock Symbol</p>
                        </div>
                        <div style={{width:'100%', padding: 5, display:'flex', justifyContent:'center',alignItems:'center', }}>
                            {this.state.massAddBy === 'quantity' ? <p style={{margin:0, fontWeight: 'bold'}}># of Shares</p>:null}
                            {this.state.massAddBy === 'allocation' ? <p style={{margin:0, fontWeight: 'bold'}}>Percentage</p>:null}
                            {this.state.massAddBy === 'dollarValue' ? <p style={{margin:0, fontWeight: 'bold'}}>Dollar Value</p>:null}
                        </div>
                    </div>
                ]
            }
            massAddTable.forEach((ele, idx)=>{
                table.push(
                    <div style={{border:'solid', display:'flex',justifyContent:'center',alignItems:'center',minWidth: 200, width:'100%' }}>
                        <div style={{borderRight:'solid', width: '100%',}}>
                            <input style={{width:'100%', textAlign:'center'}}type="text" value={ele[0]} onChange={(e)=>this.setTableCell(e, idx, 0)}/>
                        </div>
                        <div style={{width: '100%',}}>
                            <input style={{width:'100%', textAlign:'center'}} type="number" value={ele[1]} onChange={(e)=>this.setTableCell(e, idx, 1)}/>
                        </div>
                    </div>
                )
            })
            return (
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    minWidth: 200
                    }}>
                    <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, padding:50}}>
                        <div style={{width: '100%', display: 'flex', justifyContent:'space-between', }}>
                            <div style={{width:'20%'}}/>
                            <h2>Add Multiple Securities</h2>
                            <div style={{width:'20%',}}>
                                <div style={{backgroundColor:'rgb(253,234,234)', borderRadius: 10, width: 40, display: 'flex', justifyContent:'center', alignItems: 'center', cursor: 'pointer'}} onClick={()=>this.setState({showMassAdd:false})}>
                                    <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(187,58,60)'}}>
                                        X
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{marginBottom:20,}}>
                           {this.addMethod()}
                        </div>
                        <div style={{display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                            {table}
                        </div>
                        {/* <div style={{display:'flex', justifyContent:'space-around', alignItems:'center'}}> */}
                            <div style={{display:'flex', alignItems:'center', justifyContent: 'center', marginTop: 10}}>
                                <div style={{display:'flex', alignItems:'center', justifyContent: 'center', backgroundColor:'rgb(226,238,227)', borderRadius: 8, padding: 10, width: '60%', cursor:'pointer'}} onClick={()=>this.massAdd(total)}>
                                    <p style={{margin: 0, color: 'rgb(51,173,53)', fontWeight: 'bold' }}>
                                        Add All
                                    </p>
                                </div>
                            </div>
                            <div style={{display:'flex', alignItems:'center', justifyContent: 'center', marginTop: 10}}>
                                <div style={{display:'flex', alignItems:'center', justifyContent: 'center', backgroundColor:'rgb(253,234,234)', borderRadius: 8, padding: 10, width: '60%', cursor:'pointer'}} onClick={()=>this.setState({massAddTable:[["",""],["",""]]})}>
                                    <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(187,58,60)'}}>
                                        Clear All
                                    </p>
                                </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
              )
        }
    }

    convertArrayToObject = (array, key) => {
        const initialValue = {};
        return array.reduce((obj, item) => {
          return {
            ...obj,
            [item[key]]: item,
          };
        }, initialValue);
      };


    findCashValue = (total)=>{
        if(this.state.cash > 0){
            return parseFloat(this.state.cash)/parseFloat(total)*100
        }else{
            return 0
        }
    }
    optimizeWithConstraints = ()=>{
        let total = this.state.cash
        let ssc = this.singleStockConstraint()/100
        let fetchable = true
        let toObj = this.arrayToObject(this.state.sudoPortfolioStocks)
        let ob = {}
        this.state.sudoPortfolioStocks.forEach(ele=>{
            if(ele.shares === 0 && this.state.total === 0){
                fetchable = false
            }
            if(ele.type == 'Equity'){
                ob[ele.symbol]={"value":[parseFloat(ele.value), parseFloat(ele.shares)], "type":ele.type, "constraint": [null,ssc], "unit_price":ele.priceUSD}
                total += parseFloat(ele.value)
            }else{
                ob[ele.symbol]={"value":[parseFloat(ele.value), parseFloat(ele.shares)], "type":'FixedIncome', "constraint": [null,null],"unit_price":ele.priceUSD}
                if(ele.symbol !== '$$'){
                    total += parseFloat(ele.value)
                }
            }
        })
        let userInputs = { '$$':{allocation:this.findCashValue(total), shares:this.state.cash}, 'BIL':{allocation:'0', shares:'0'} , ...toObj, }
        let prevSet = [...this.state.sudoPortfolioStocks]
        // if (fetchable){
            let body = {
                "id": "testMessage-YVC4jC-p3Ciyx",
                "portfolio": ob,
                "cash": this.state.noValues ? this.state.total :parseFloat(this.state.cash),
                "constraint": {
                    "Return": this.state.returnsConstraint, 
                    "dispersion": null, 
                    "Equity": [null,null],
                    // "FixedIncome": [null,null],
                    // "Cash": [null,null]
                    // "Equity": [0.2,0.8],
                    "FixedIncome": [null,0.24],
                    "Cash": [0.005,0.05]
                },
                 "secret": "nMoNbIARwbuW5Pj9ihodcqCOwAF4ST3yth3fHRAm28tWVa3DiBnhmqzDD5oS",
                }
            console.log('body:',body)
            fetch(`${ENDPOINT}/risk-optimization/get-dashboard-optimization`, {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body)
            })
            // .then(resp=>console.log(resp.body))
            .then((res) => res.json())
            .then(responseJson=>{
                this.props.optimizeButton(responseJson, userInputs, prevSet)
            })
            .catch(err =>console.log('fetch error:', err))
        // }
        // else{
        //     this.setState({errors: 'zeroShares', showErrorModal:true})
        // }
    }

    optimizeNoConstraints = ()=>{
        let fetchable = true
        let toObj = this.arrayToObject(this.state.sudoPortfolioStocks)
        let ob = {}
        this.state.sudoPortfolioStocks.forEach(ele=>{
            if(ele.shares === 0){
                fetchable = false
            }
            ob[ele.symbol]=[parseFloat(ele.value), parseFloat(ele.shares)]
        })
        if (fetchable){
            let body = {
                "id": "testMessage-YVC4jC-p3Ciyx",
                "portfolio": ob,
                "cash": 0,
                "secret": "nMoNbIARwbuW5Pj9ihodcqCOwAF4ST3yth3fHRAm28tWVa3DiBnhmqzDD5oS"
            }
            fetch(`${ENDPOINT}/risk-optimization/get-dashboard-optimization`, {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body)
            })
            // .then(resp=>console.log(resp.body))
            .then((res) => res.json())
            .then(responseJson=>this.props.optimizeButton(responseJson, toObj))
            .catch(err =>console.log('fetch error:', err))
        }
        else{
            this.setState({errors: 'zeroShares', showErrorModal:true})
        }
    }

    setSearch = (symbol)=>{
            this.setState({ isMomDataLoading: true });
            // console.log(searchMomentumText);
            let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${symbol}`;

            fetch(url, {
                "Access-Control-Allow-Headers": {
                    "Content-Type": "application/json",
                    "Cache-Control": "no-cache",
                    "Access-Control-Request-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*",
                },
            })
                .then((res) => res.json())
                .then((responseJson) => {
                    var data = responseJson.content;
                    // console.log("SEARCHING RESULT: " , data);

                    for (var i = 0; i < data.length; i++) {
                        var item = data[i];
                        var color = "";
                        if (item.rar) {
                            switch (item.rar) {
                                case "H":
                                    color = "#dc143c";
                                    break;
                                case "M":
                                    color = "#fcac0c";
                                    break;
                                default:
                                    color = "#00A99D";
                            }
                        } else {
                            color = "rgb(0,0,0)";
                        }

                        item.symbolColor = color;
                        data[i] = item;
                    }
                    this.setState({
                        isMomDataLoading: false,
                        momDataSource: data,
                        searchMomentumText:symbol
                    });
                })
                .catch((err) => {
                    this.setState({ isMomDataLoading: false });
                    console.log(err);
                });
    }

    setAllocation = (e, item)=>{
        let allocation = e.target.value
        let value = (this.state.total * parseFloat(e.target.value) / 100 ).toFixed(2)
        let shares = (value / item.price).toFixed(2)
        let updated = {...item, allocation, value, shares}

        let sudoPortfolioStocks = this.state.sudoPortfolioStocks.map(ele=>{
            if(ele.symbol === item.symbol){
                return updated
            }
            else{
                return ele
            }
        })
        this.setState({sudoPortfolioStocks})
    }

    setShares = (e, item)=>{
        let sudoPortfolioStocks
        let updated 
        let shares
        let total = this.state.total
        if (isNaN(e.target.value) || e.target.value < 0 ) {
        }else{
        if (e.target.value === "") {
            shares = e.target.value
            updated = {...item, shares}
            sudoPortfolioStocks = this.state.sudoPortfolioStocks.map(ele=>{
                if(ele.symbol === item.symbol){
                    return updated
                }
                else{
                    return {...ele}
                }
            })
        }
        else{
            shares = e.target.value
            let value = (parseFloat(e.target.value) * item.price).toFixed(2)
            total = parseFloat(this.state.cash)
            this.state.sudoPortfolioStocks.forEach(ele=>{
                if(ele.symbol === item.symbol){
                    total += parseFloat(value)
                }
                else{
                    total += parseFloat(ele.value)
                }
            })
            let allocation = (value / total * 100).toFixed(2)
             updated = {...item, allocation, value, shares}
             sudoPortfolioStocks = this.state.sudoPortfolioStocks.map(ele=>{
                if(ele.symbol === item.symbol){
                    return updated
                }
                else{
                    return {...ele, allocation: (ele.value/total*100).toFixed(2)}
                }
            })
        }
        this.setState({sudoPortfolioStocks, total})
        }
    }

    setValue = (e, item)=>{

        if (isNaN(e.target.value) || e.target.value < 0 ) {
            console.log('...');
        }
        else{
            let updated
            let value 
            let total
            if (e.target.value === ""){
                value = ""
                updated = {...item, value,}
            }else{
                value = parseFloat(e.target.value)
                total = parseFloat(this.state.cash)
                this.state.sudoPortfolioStocks.forEach(ele=>{
                    if(ele.symbol === item.symbol){
                        total += value
                    }
                    else{
                        total += ele.value
                    }
                })
                let allocation =  value / total * 100
                let shares = (value / item.price).toFixed(2)
                updated = {...item, allocation, value, shares}
            }
            let sudoPortfolioStocks = this.state.sudoPortfolioStocks.map(ele =>{
                if(ele.symbol === item.symbol){
                    return updated
                }
                else{
                    return(
                        {
                            ...ele,
                            allocation: e.target.value === "" ? ele.allocation : ele.value / total * 100
                        }
                    ) 
                }
            })
            this.setState({sudoPortfolioStocks, total})
        }
    }
    
    setTotal = (total) =>{
        let sudoPortfolioStocks = this.state.sudoPortfolioStocks.map(ele=>{
            return(
                {
                    ...ele,
                    value: (ele.allocation * total / 100).toFixed(2),
                    shares: ((ele.allocation * total / 100)/ ele.price).toFixed(2),
                }
            )
        })
        this.setState({total, sudoPortfolioStocks})
    }

    displayCard = (item)=>{
        if(item){
        return (
            <div style={{ height: "100%", }}>
              <div
                style={{
                  paddingLeft: 25,
                  paddingRight: 25,
                  paddingTop: 10,
                  width: 220,
                  borderRadius: 8,
                  margin: 10,
                  backgroundColor: "rgb(255,255,255)",
                  textDecoration: "none",
                  minHeight: 300,
                  border: 'solid',
                  borderWidth:'.5px'
                }}
              >
                <Row
                  style={{
                    backgroundColor: this.titleColor(item.direction),
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 8,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 8,
                    textDecoration: "none",
                  }}
                >
                  <h4
                    style={{
                      color: "#FFF",
                      fontWeight: "bold",
                      marginTop: 4,
                      width: "",
                      textAlign: "left",
                      textDecoration: "none",
                    }}
                  >
                    {item.symbol}
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 44,
                      textDecoration: "none",
                    }}
                  >
                    <h6
                      style={{
                        borderRadius: 5,
                        color: this.titleColor(item.direction),
                        marginTop: 5,
                        backgroundColor: "#FFF",
                        padding: 5,
                        textDecoration: "none",
                      }}
                    >
                      {item.price
                        ? `${item.price ? item.price.toFixed(2) : item.price.toFixed(2)}`
                        : "00.00"}
                    </h6>
                  </div>
                </Row>
                <h5
                  style={{
                    fontWeight: "bold",
                    textAlign: "left",
                    textDecoration: "none",
                    color: "rgb(17,17,17)",
                    minHeight: 48
                  }}
                >
                  {item.company}
                </h5>
                
                <div style={{display:'flex',justifyContent:'space-between', alignItems:'center', height: 36, backgroundColor: 'rgb(250,250,253)', paddingLeft: 10, paddingRight:10, borderRadius: 5,marginTop: 10}}>
                  <div>
                    <p style={{margin:0, float:'left'}}>allocation</p>
                  </div>
                  <div style={{display:'flex', justifyContent:'right', alignItems:'center'}}>
                    {/* <input style={{ width: 50, border: 'none', backgroundColor: 'transparent', fontWeight: 'bold', boxShadow:'none',textAlign:'right', outline:'none' }} onChange={(e) => this.setAllocation(e, item)} type="text" value={item.allocation}/> */}
                    {/* <p style={{width: 50, fontWeight:'bold',}}></p> */}
                    <p style={{fontWeight:'bold', margin:0}}>{item.allocation}%</p> 
                  </div>
                </div>
                <div style={{display:'flex',justifyContent:'space-between', alignItems:'center', height: 36, backgroundColor: 'rgb(250,250,253)', paddingLeft: 10, paddingRight:10, borderRadius: 5,marginTop: 10}}>
                  <div>
                    <p style={{margin:0, float:'left'}}>value</p>
                  </div>
                  <div style={{display:'flex', justifyContent:'right', alignItems:'center'}}>
                    <p style={{fontWeight:'bold', margin:0}}>${item.value}</p> 
                    {/* <input style={{ width: 75, border: 'none', backgroundColor: 'transparent', fontWeight: 'bold', boxShadow:'none', textAlign:'right', outline:'none' }} onChange={(e) => this.setValue(e, item)} type="text" value={item.value}/> */}
                  </div>
                </div>

                <div style={{display:'flex',justifyContent:'space-between', alignItems:'center', height: 36, backgroundColor: 'rgb(250,250,253)', paddingLeft: 10, paddingRight:10, borderRadius: 5,marginTop: 10}}>
                  <div style={{width:'30%'}}>
                    <p style={{margin:0, float:'left'}}>shares</p>
                  </div>
                  <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center', width:'70%', }}>
                    <input style={{ width: '100%', border: 'none', backgroundColor: 'transparent', fontWeight: 'bold', boxShadow:'none', textAlign:'right', outline:'none' }} onChange={(e) => this.setShares(e, item)} type="text" value={item.shares}/>
                  </div>
                </div>

                {/* <div style={{display:'flex',justifyContent:'space-between', alignItems:'center', height: 45, paddingLeft: 10, paddingRight:10, borderRadius: 5,marginTop: 10}}>
                  <div style={{display:'flex', justifyContent:'left', alignItems:'center', flexDirection: 'column'}}>
                    <input style={{ width: 50, border: 'none', backgroundColor: 'transparent', fontWeight: 'bold', boxShadow:'none', margin:0, textAlign:'center' }} onChange={(e) => this.setState({ shares: e.target.value})} type="text" value={item.shares}/>
                    <p style={{margin:0, marginTop: -5}}>shares</p> 
                  </div>
                  <div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', border:'solid', borderWidth:'0.25px', borderRadius: 6}}>
                      <div style={{cursor:'pointer',paddingLeft: 10,paddingRight: 10, paddingTop: 5, paddingBottom:5, borderRight:'solid', borderWidth:'0.25px'}} onClick={()=>this.setState({shares: this.state.shares + 1})}>
                        <p style={{margin: 0, fontWeight:'bold'}}>
                          +
                        </p>
                      </div>
                      <div style={{ cursor:'pointer',paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5 }} onClick={this.state.shares > 0? () => this.setState({ shares: this.state.shares - 1 }):null}>
                        <p style={{margin: 0, fontWeight:'bold'}}>
                          -
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div style={{display:'flex', alignItems:'center', justifyContent: 'center', marginTop: 10}}>
                  <div
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(253,234,234)', borderRadius: 10, cursor: 'pointer', marginLeft: 2, width: '80%' }}
                    onClick={() => this.sudoed(item) ? this.removeFromSudo(item) : this.addSudo(item)}
                  >
                    <p style={{ margin: 0, color:'rgb(187,58,60)', fontWeight:'bold' }}>remove</p>
                  </div>
                </div>
              </div>
            </div>
          )}
    }

    watchlisted = (item) => {
        const { myWatchlist } = this.props
        let watchlisted = false
        Object.keys(myWatchlist).forEach(ele => {
            if (myWatchlist[ele].symbol === item.symbol) {
                watchlisted = true
            }
        })
        return watchlisted
    }

    sudoed = (item) => {
        const { sudoPortfolioStocks } = this.state
        let swtch = false
        sudoPortfolioStocks.forEach(ele=>{
            if(ele.symbol === item.symbol){
                swtch = true
            }
        })
        return swtch
    }

    addToSudo = (item) => {
        let updatedItem = {...item, allocation: 0, value: 0, shares:0}
        let sudoPortfolioStocks= [...this.state.sudoPortfolioStocks, updatedItem]
        this.setState({
            sudoPortfolioStocks
        })
    }

    removeFromSudo = (item) => {
        let sudoPortfolioStocks = this.state.sudoPortfolioStocks.filter(ele => ele.symbol !== item.symbol)
        let total = parseFloat(this.state.cash)
        sudoPortfolioStocks.forEach(ele=>{
            total += parseFloat(ele.value)
        })
        let x = sudoPortfolioStocks.map(ele=>{
            let ob = {...ele, allocation: (ele.value/total*100).toFixed(2)}
            return ob
        })
        this.setState({ sudoPortfolioStocks: x , total })
    }

    titleColor = (direction)=>{
        if (direction == 0) {
          // return "#a8a6a5"
          return "rgb(84, 83, 82)"
        }
        else if (direction > 0) {
          return 'rgb(43,174,43)'
        } 
        else {
          return "#e31111"
        }
      }

    individuleCompanyCard = (data, location) => {
        return (
            <div
                style={{
                    textDecoration: "none",
                    display: 'flex',
                    height: 300,
                    width: '80%',
                    marginLeft: 20,
                    marginRight: 20
                }}
            >
                {location !== 'wl'?
                <div style={{ height: "100%", }}>
                    <div
                        style={{
                            paddingLeft: 25,
                            paddingRight: 25,
                            paddingTop: 10,
                            width: 220,
                            borderRadius: 8,
                            margin: 10,
                            backgroundColor: "rgb(255,255,255)",
                            textDecoration: "none",
                            height: 300,
                            border: 'solid',
                            borderWidth: '.5px',
                            overflowY: this.props.isSmallScreen? 'hidden':'scroll', 
                            overflowX: this.props.isSmallScreen? 'scroll':'hidden'
                        }}
                    >
                        <Row
                            style={{
                                backgroundColor: '#ddd',
                                paddingLeft: 10,
                                paddingRight: 10,
                                borderRadius: 8,
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                marginBottom: 8,
                                textDecoration: "none",
                            }}
                            onClick={this.state.searchMomentumText === 'enter a symbol' ?()=>this.setState({searchMomentumText:''}):null}
                        >
                            <input style={{ width: '100%',height: 44, border: 'none', backgroundColor: 'transparent', fontWeight: 'bold', boxShadow: 'none', textAlign: 'center', outline: 'none',}}placeholder='enter a symbol' type="text" value={this.state.searchMomentumText} onChange={(event) => {
                                let searchMomentumText = event.target.value;
                                this.setState({ searchMomentumText, selectedPreset: null }, () => {
                                    const { isSearching } = this.state;
                                    if (isSearching) {
                                        return;
                                    } else {
                                        this.setState({ isSearching: true });
                                        setTimeout(() => {
                                            this.setState(
                                                { isSearching: false },
                                                this.onSubmitSearchMomentum
                                            );
                                        }, 2000);
                                    }
                                });
                            }} />
                        </Row>
                        <div style={{ display: 'flex', flexDirection: 'column',  }}>
                            {this.state.momDataSource.map(ele =>
                                <div onClick={()=>this.setState({sudoPortfolioStocks: [...this.state.sudoPortfolioStocks, {...ele, allocation: 0, value: 0, shares:0 }]})} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'rgb(250,250,253)', paddingLeft: 10, paddingRight: 10, borderRadius: 5, marginTop: 10, cursor: 'pointer' }}>
                                    <div style={{display:'flex', flexDirection: 'column'}}>
                                        <p style={{ margin: 0, float: 'left', fontWeight:'bold' }}>{ele.symbol}</p>
                                        <p style={{ margin: 0, float: 'left' }}>{ele.company}</p>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>:null}
                {location !== 'wl' && this.state.sudoPortfolioStocks.length === 0 ? <Steps loading={this.state.loading} toWm={this.props.toWm} 
                    // isSmallScreen={this.props.isSmallScreen}
                    /> 
                : null}
                {data.map((item, index) => {
                    if (location === 'wl'){
                        return (
                            <SingleCompanyCard dashboard={true} sudoed={this.sudoed(item)} removeSudo={this.removeFromSudo} addSudo={this.addToSudo} remove={this.props.removeWl} add={this.props.addWl} watchlisted={this.watchlisted(item)} user={this.props.user} remove={this.remove} editMode={this.state.editMode} item={item} index={index} />
                        );
                    }else{
                        return(
                            this.displayCard(item)
                            // <SingleCompanyCard construct={true} sudoed={this.sudoed(item)} removeSudo={this.removeFromSudo} addSudo={this.addToSudo} remove={this.props.removeWl} add={this.props.addWl} watchlisted={this.watchlisted(item)} user={this.props.user} remove={this.remove} editMode={this.state.editMode} item={item} index={index} />
                        )
                    }
                })}
            </div>
        );
    };

    showTopTickers = () => {
        let data
        const { searchMomentumText } = this.state
        const { myWatchlist } = this.props
        if (this.state.searchMomentumText.length > 0) {
            data = this.state.momDataSource
        } else {
            data = myWatchlist
        }
        return (
            <Card
                style={{
                    width: "100%",
                    marginTop: 20,
                    height: 300,
                    overflowY: 'hidden', 
                    overflowX: 'auto', 
                    flexWrap: 'nowrap',

                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                    {this.state.shownList === 'My Watchlist' ? <div style={{ width: '20%' }} /> : null}
                    <p style={{ color: 'rgb(120,120,120)', margin: 0, marginTop: 5, fontWeight: 'bold' }}>
                        {searchMomentumText.length > 0 ? `searching for ${searchMomentumText}` : 'my watchlist'}
                    </p>

                </div>

                <div>
                    {this.individuleCompanyCard(data, 'wl')}
                </div>
                
            </Card>
        );
    }



    showBottomTickers = () => {
        const { searchMomentumText } = this.state
        let data = this.state.sudoPortfolioStocks
        return (
            <Card
                style={{
                    width: "100%",
                    marginTop: 20,
                    height: 500,
                    overflowY: 'hidden', 
                    overflowX: 'auto', 
                    flexWrap: 'nowrap',
                    // borderWidth: 0
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                    <div style={{ width: '20%', marginTop: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', minWidth: 280, flexDirection:'column'  }} >
                            <div style={{display:'flex',height:'100%', justifyContent:'center',alignItems:'center', marginLeft: 25, marginRight: 25,with:'20%' }} onClick={() => this.setState({dropDown:!this.state.dropDown})}>
                                <div style={{borderRadius: 8, border:'solid', borderWidth: '.25px', fontSize: 'smaller', minWidth: 280, display:'flex', alignItems:'center',justifyContent:'center' }}>
                                    <p style={{ color: 'rgb(17,17,17)', margin: 0, fontWeight: 'bold', fontSize: 18 }}>
                                     Import
                                    </p> 
                                </div>
                            </div>
                        {this.state.dropDown? null:
                            <div style={{
                                display: 'inline-block',
                                position: 'absolute',
                                backgroundColor: '#f1f1f1',
                                backgroundColor:'rgb(255,255,255)',
                                // minWidth: '160px',
                                width:'25%',
                                boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                                zIndex: 1,
                                marginTop: 190,
                                borderRadius:8
                                }}>
                                <div style={{display:'flex', flexDirection:'column', zIndex:999, border: 'none', borderWidth:0.25, borderRadius:8, }}>
                                    <div style={{display:'flex', justifyContent:'center',alignItems:'center', borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%'}} onClick={() => this.setState({showWatchlist: !this.state.showWatchlist, searchMomentumText: '', dropDown:!this.state.dropDown})}>
                                        <p style={{margin:0}}>
                                            From Watchlist
                                        </p>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'center',alignItems:'center',borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%' }} onClick={() => this.setState({showMassAdd: true, searchMomentumText: '', dropDown:!this.state.dropDown})}>
                                        <p style={{margin:0}}>
                                            Multiple Stocks at a time
                                        </p>
                                    </div>
                                    {Object.keys(this.props.customPorts).length > 0 ||  this.props.linkedPorts.length > 0 ?
                                    <div style={{display:'flex', justifyContent:'center',alignItems:'center',borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%' }} onClick={() => this.setState({showCustoms: true, searchMomentumText: '', dropDown:!this.state.dropDown})}>
                                        <p style={{margin:0}}>
                                            Re-Optimize a Custom Strategy
                                        </p>
                                    </div>:null
                                    }
                                    <div style={{display:'flex', justifyContent:'center',alignItems:'center',borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%' }} onClick={() => this.setState({fileImportModal: true, searchMomentumText: '', dropDown:!this.state.dropDown})}>
                                        <p style={{margin:0}}>
                                            From a File
                                        </p>
                                    </div>
                                    {/* <div style={{display:'flex', justifyContent:'center',alignItems:'center',borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%' }} onClick={() => this.setState({dropDown:!this.state.dropDown})} >
                                        <p style={{margin:0}}>
                                            From a Linked Portfolio
                                        </p>
                                    </div> */}
                                    <div style={{display:'flex', justifyContent:'center',alignItems:'center',borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%', borderRadius:8 }} onClick={() => this.setState({dropDown:!this.state.dropDown, cashModal:true})} >
                                        <p style={{margin:0}}>
                                            Add Cash
                                        </p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {/* <div style={{ width: '20%', marginTop: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', minWidth: 280 }} onClick={() => this.setState({showWatchlist: !this.state.showWatchlist, searchMomentumText: ''})}>
                        <div style={{width: 25}}/>
                            <p style={{margin:0, fontWeight: 'bold', padding: 10, borderRadius: 8, border:'solid', borderWidth: '.25px', fontSize: 'smaller',  }}>{this.state.showWatchlist? 'HIDE WATCHLIST' :'IMPORT FROM WATCHLIST'}</p>
                        <div style={{width: 25}}/>
                    </div> */}
                    {/* <div style={{width:'30%'}}>
                        {this.objective()}
                    </div> */}
                    <div style={{width: '40%',display:'flex',justifyContent:'center', alignItems:'center'}}>
                        <p style={{ color: 'rgb(17,17,17)', margin: 0, marginTop: 5, fontWeight: 'bold', fontSize: 18, marginRight: 10 }}>
                            Portfolio Constructor
                        </p> 
                    </div>
                   
                     <div style={{width:'20%',height:'70%',  backgroundColor: this.state.sudoPortfolioStocks.length < 2? 'rgb(82,82,82)': brandColor, borderRadius: 8, paddingTop:5,paddingBottom:5,paddingLeft:10,paddingRight:10, marginTop:5, cursor:'pointer', display:'flex',justifyContent:'center', alignItems:'center',marginTop:10}} onClick={this.state.sudoPortfolioStocks.length < 2 ? null :()=>this.optimizeWithConstraints()}>
                        <p style={{ color: 'rgb(255,255,255)', margin: 0,  fontWeight: 'bold', fontSize: 18 }}>
                            Optimize Portfolio
                        </p> 
                    </div>
                    {/* <div style={{ width: '20%', marginTop: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 10, paddingLeft: 5, paddingRight: 5, borderRadius: 8, border:'solid', borderWidth: '.25px', fontSize: 'smaller' }}>
                        <p style={{margin:0, fontWeight: 'bold',  }}>Portfolio Value ${this.state.total.toFixed(2)}</p>
                        <input style={{ width: 50, border: 'none', fontWeight: 'bold'  }} onChange={(e) => this.setTotal(e.target.value)} type="text" value={this.state.total}/> 
                    </div> */}
                    {/* <div style={{ width: '20%', marginTop: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                        <p style={{ fontWeight: 'bold', backgroundColor: brandColor, padding: 10, borderRadius: 8 }} onClick={() => console.log('Optimizing')}>Optimize</p> 
                    </div> */}
                </div>
                {/* <div style={{display:'flex', justifyContent: 'space-around', paddingTop: 10,paddingLeft: 10, paddingRight: 10, paddingBottom: 0, marginBottom:0 }}>
                <p style={{ margin:0, fontWeight: 'bold',color: '#a8a6a5'}}> Trending Even</p>
                <p style={{ margin:0, fontWeight: 'bold',color: '#25d09c'}}> Trending Up</p>
                <p style={{ margin:0, fontWeight: 'bold',color: '#e31111'}}> Trending Down</p>
            </div>*/}
                <div style={{width:'100%'}}>
                    {this.objective()}
                    {this.objectiveDescription()}
                </div>
                {/* <div style={{width:'100%'}}>
                    {this.riskTolerance()}
                    {this.toleranceDescription()}
                </div> */}
                <div>
                    {this.individuleCompanyCard(data, 'sudo')}
                </div>
                
            </Card>
        );
    }

    objective = ()=>{
        return(
            <div style={{width:'100%', display:'flex',justifyContent:'center', alignItems:'center', marginTop:10, borderTop:'solid',borderBottom:'solid', borderWidth: '1px',borderColor:'#ddd' }}>
                    <div style={{width:'90%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'row'}}>
                        <div style={{ display:'flex',justifyContent:'center', alignItems:'center', marginRight:'2%'}}>
                            <p style={{margin:0, fontSize: '1em'}}>Objective:</p>
                            <FaQuestionCircle style={{marginRight:4, marginLeft:4, color:brandColor, cursor:'pointer'}} onClick={()=>this.setState({objDesc:!this.state.objDesc})}/>
                        </div>
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center', marginRight:'2%'}}>
                            <input style={{cursor:'pointer'}}type="radio" checked={!this.state.returnsConstraint} onChange={()=>this.setState({returnsConstraint:false})} />
                            <label style={{margin:0, fontSize: '1em', marginLeft: 4}} for="risk">Maximize Sharpe</label>
                        </div>
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center', }}>
                            <input style={{cursor:'pointer'}}type="radio" checked={this.state.returnsConstraint} onChange={()=>this.setState({returnsConstraint:true})}/>
                            <label style={{margin:0, fontSize: '1em', marginLeft: 4}} for="returns">Maximize Return</label>
                        </div>
                    </div>
                </div>
        )
    }

    addMethod = ()=>{
        return(
            <div style={{width:'100%', display:'flex',justifyContent:'center', alignItems:'center', marginTop:10, borderTop:'solid',borderBottom:'solid', borderWidth: '1px',borderColor:'#ddd' }}>
                    <div style={{width:'90%', display:'flex', justifyContent:'space-evenly', alignItems:'center', flexDirection:'row', flexDirection:'column'}}>
                        <div style={{ display:'flex',justifyContent:'center', alignItems:'center', marginRight:'2%'}}>
                            <p style={{margin:0, fontSize: '1em'}}>Add by:</p>
                        </div>
                        <div style={{width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center', flexDirection:'row'}}>
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center', marginRight:'2%'}}>
                                <input style={{cursor:'pointer'}}type="radio" checked={this.state.massAddBy === 'quantity'} onClick={()=>this.setState({massAddBy: 'quantity'})} />
                                <label style={{margin:0, fontSize: '1em', marginLeft: 4}} for="risk">Quantity</label>
                            </div>
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center', }}>
                                <input style={{cursor:'pointer'}}type="radio" checked={this.state.massAddBy === 'allocation'} onClick={()=>this.setState({massAddBy: 'allocation'})}/>
                                <label style={{margin:0, fontSize: '1em', marginLeft: 4}} for="returns">Percentage</label>
                            </div>
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center', }}>
                                <input style={{cursor:'pointer'}}type="radio" checked={this.state.massAddBy === 'dollarValue'} onClick={()=>this.setState({massAddBy: 'dollarValue'})}/>
                                <label style={{margin:0, fontSize: '1em', marginLeft: 4}} for="returns">Dollar Value</label>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }

    riskTolerance = ()=>{
        return(
            <div style={{width:'100%', display:'flex',justifyContent:'center', alignItems:'center',borderBottom:'solid', borderWidth: '1px',borderColor:'#ddd' }}>
                    <div style={{width:'90%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'row'}}>
                        <div style={{ display:'flex',justifyContent:'center', alignItems:'center', marginRight:'2%'}}>
                            <p style={{margin:0, fontSize: '1em'}}>Risk Tolerance:</p>
                            <FaQuestionCircle style={{marginRight:4, marginLeft:4, color:brandColor, cursor:'pointer'}} onClick={()=>this.setState({rtDesc:!this.state.rtDesc})}/>
                        </div>
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center', marginRight:'2%'}}>
                            <p style={{margin:0, marginRight: 5}}>Low Risk</p>
                            <input style={{cursor:'pointer', backgroundColor:brandColor}}type="range" value={this.state.rtVal} onChange={(e)=>this.setState({rtVal:e.target.value})} />
                            <p style={{margin:0, marginLeft: 5}}>High Risk</p>
                        </div>
                    </div>
                </div>
        )
    }

    toleranceDescription = ()=>{
        if(this.state.rtDesc){
            return(
                <div style={{display:'flex', width: '100%', justifyContent:'center', alignItems:'center',marginTop:5, borderBottom:'solid', borderColor: '#ddd', borderWidth:.25}}>
                    <div style={{display:'flex', width: '90%', flexDirection:'column'}}>
                        <p style={{margin:0}}>Slide to the amount of risk  you willing to take on. All the way to left would be no risk, all the way to the right would be extremely risky.</p>
                    </div>
                </div>
            )
        }
    }

    onSubmitSearchMomentum = () => {
        const { searchMomentumText } = this.state;
        this.setState({ isMomDataLoading: true });
        // console.log(searchMomentumText);
        let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchMomentumText}`;

        fetch(url, {
            "Access-Control-Allow-Headers": {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                "Access-Control-Request-Headers": "*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
            },
        })
            .then((res) => res.json())
            .then((responseJson) => {
                var data = responseJson.content;
                // console.log("SEARCHING RESULT: " , data);

                for (var i = 0; i < data.length; i++) {
                    var item = data[i];
                    var color = "";
                    if (item.rar) {
                        switch (item.rar) {
                            case "H":
                                color = "#dc143c";
                                break;
                            case "M":
                                color = "#fcac0c";
                                break;
                            default:
                                color = "#00A99D";
                        }
                    } else {
                        color = "rgb(0,0,0)";
                    }

                    item.symbolColor = color;
                    data[i] = item;
                }
                this.setState({
                    isMomDataLoading: false,
                    momDataSource: data,
                });
            })
            .catch((err) => {
                this.setState({ isMomDataLoading: false });
                console.log(err);
            });
    };

    row = (ele, i)=>{
        return(
            <div style={{display:'flex', width:'100%', justifyContent:'space-between', backgroundColor: i % 2 === 0 ?'': 'rgb(126,126,126)', }}>
                <div style={{display:'flex', borderLeft:'solid', }}>
                    <p style={{margin:0, display:'flex',alignItems:'center',justifyContent:'center', marginTop:4,marginBottom:4, width: '15%'}}>{ele.symbol}</p>
                </div>
                <div style={{display:'flex', borderLeft:'solid'}}>
                    <p style={{margin:0, display:'flex',alignItems:'center',justifyContent:'center', marginTop:4,marginBottom:4,width: '20%'}}>{ele.priceUSD === 'Price' ? ele.priceUSD :ele.priceUSD.toFixed(2)}</p>
                </div>
                <div style={{display:'flex', borderLeft:'solid'}}>
                    <p style={{margin:0, display:'flex',alignItems:'center',justifyContent:'center', marginTop:4,marginBottom:4,width: '15%'}}>{ele.direction}</p>
                </div>
                <div style={{display:'flex', borderLeft:'solid'}}>
                    <p style={{margin:0, display:'flex',alignItems:'center',justifyContent:'center', marginTop:4,marginBottom:4,width: '15%'}}>{ele.allocation}%</p>
                </div>
                <div style={{display:'flex', borderLeft:'solid'}}>
                    <p style={{margin:0, display:'flex',alignItems:'center',justifyContent:'center', marginTop:4,marginBottom:4,width: '20%'}}>${ele.value}</p>
                </div>
                <div style={{display:'flex', borderLeft:'solid',borderRight:'solid'}}>
                    <p style={{margin:0, display:'flex',alignItems:'center',justifyContent:'center', marginTop:4,marginBottom:4,width: '15%'}}>{ele.shares}</p>
                </div>
            </div>
        )
    }

    showBottomTickersSmall = ()=>{
        let data = this.state.sudoPortfolioStocks
        return (
            <Card
                style={{
                    width: '100%',
                    marginTop: 20,
                    height: 500,
                    border:'transparent'
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                    <div style={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', flexDirection:'column'  }} >
                        <div style={{backgroundColor: brandColor, borderRadius: 8, paddingTop:5,paddingBottom:5,paddingLeft:10,paddingRight:10, marginTop:5, cursor:'pointer'}} onClick={() => this.setState({dropDown:!this.state.dropDown})}>
                            <p style={{ color: 'rgb(255,255,255)', margin: 0, fontWeight: 'bold', fontSize: 14 }}>
                                Import
                            </p> 
                        </div>
                        {this.state.dropDown? null:
                            <div style={{
                                display: 'inline-block',
                                position: 'absolute',
                                backgroundColor: '#f1f1f1',
                                backgroundColor:'rgb(255,255,255)',
                                minWidth: '160px',
                                boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                                zIndex: 1,
                                marginTop: 130
                            }}>
                            <div style={{display:'flex', flexDirection:'column', zIndex:999, border: 'none', borderWidth:0.25, borderRadius:8}}>
                                <div style={{display:'flex', justifyContent:'center',alignItems:'center',borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%' }} onClick={() => this.setState({showWatchlist: !this.state.showWatchlist, searchMomentumText: '', dropDown:!this.state.dropDown})}>
                                    <p style={{margin:0}}>
                                        From Watchlist
                                    </p>
                                </div>
                                <div style={{display:'flex', justifyContent:'center',alignItems:'center',borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%' }} onClick={() => this.setState({showMassAdd: true, searchMomentumText: '', dropDown:!this.state.dropDown})}>
                                    <p style={{margin:0}}>
                                        Multiple Stocks
                                    </p>
                                </div>
                                {/* <div style={{display:'flex', justifyContent:'center',alignItems:'center',borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%' }} onClick={() => this.setState({dropDown:!this.state.dropDown})} >
                                    <p style={{margin:0}}>
                                        From a Linked Portfolio
                                    </p>
                                </div> */}
                                <div style={{display:'flex', justifyContent:'center',alignItems:'center',borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%' }} onClick={() => this.setState({dropDown:!this.state.dropDown, cashModal:true})} >
                                    <p style={{margin:0}}>
                                        Add Cash
                                    </p>
                                </div>
                            </div>
                            </div>
                        }
                    </div>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <p style={{ color: 'rgb(17,17,17)', margin: 0, marginTop: 5, fontWeight: 'bold', fontSize: 14, marginLeft: 5 }}>
                            Portfolio
                        </p> 
                        <p style={{ color: 'rgb(17,17,17)', margin: 0, marginTop: 5, fontWeight: 'bold', fontSize: 14, marginLeft: 5 }}>
                            Constructor
                        </p> 
                    </div>
                   
                     <div style={{backgroundColor: this.state.sudoPortfolioStocks.length < 2? 'rgb(82,82,82)': brandColor, borderRadius: 8, paddingTop:5,paddingBottom:5,paddingLeft:10,paddingRight:10, marginTop:5, cursor:'pointer'}} onClick={this.state.sudoPortfolioStocks.length < 2 ? null :()=>this.optimizeWithConstraints()}>
                        <p style={{ color: 'rgb(255,255,255)', margin: 0, fontWeight: 'bold', fontSize: 14 }}>
                            Optimize
                        </p> 
                    </div>
                </div>
                {/* <div>
                <Row
                            style={{
                                backgroundColor: 'rgb(34,114,195)',
                                paddingLeft: 10,
                                paddingRight: 10,
                                borderRadius: 8,
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                marginBottom: 8,
                                textDecoration: "none",
                            }}
                            onClick={this.state.searchMomentumText === 'enter a symbol' ?()=>this.setState({searchMomentumText:''}):null}
                        >
                            <input style={{ width: '100%',height: 44, border: 'none', backgroundColor: 'transparent', fontWeight: 'bold', boxShadow: 'none', textAlign: 'center', outline: 'none',}} type="text" value={this.state.searchMomentumText} onChange={(event) => {
                                let searchMomentumText = event.target.value;
                                this.setState({ searchMomentumText, selectedPreset: null }, () => {
                                    const { isSearching } = this.state;
                                    if (isSearching) {
                                        return;
                                    } else {
                                        this.setState({ isSearching: true });
                                        setTimeout(() => {
                                            this.setState(
                                                { isSearching: false },
                                                this.onSubmitSearchMomentum
                                            );
                                        }, 2000);
                                    }
                                });
                            }} />
                        </Row>
                </div> */}
                <div style={{width:'100%'}}>
                    {this.objective()}
                    {this.objectiveDescription()}
                </div>
                <div style={{display:'flex', flexDirection: 'column', marginTop: 10, width: '100%',}}>
                    {this.state.sudoPortfolioStocks.length < 1?
                    <div style={{height:400}}>
                        <Steps loading={this.state.loading} toWm={this.props.toWm} isSmallScreen={true}/>
                    </div>
                    :
                    <MobileTickerList data={data} isSmallScreen={true} setShares={this.setShares}/>}
                    {/* {this.row({symbol: 'Symbol', priceUSD: 'Price', direction: 'Trend', allocation: '', value: 'Value', shares: "Shares",borderTop:'solid' }, 1)}
                    {data.map((ele, i)=>this.row(ele, i))} */}
                </div>
            </Card>
        )
    }

    objectiveDescription = ()=>{
        if(this.state.objDesc){
            return(
                <div style={{display:'flex', width: '100%', justifyContent:'center', alignItems:'center',marginTop:5, borderBottom:'solid', borderColor: '#ddd', borderWidth:.25}}>
                    <div style={{display:'flex', width: '90%', flexDirection:'column'}}>
                        <p style={{margin:0}}><strong>Maximizing Sharpe - </strong>This maximizes your risk adjusted return for the portfolio</p>
                        <p style={{margin:0}}><strong>Maximizing Returns - </strong>This maximizes your risk adjusted return as much as possible while forecasting a higher return than the portfolio you have submitted.</p>
                    </div>
                </div>
            )
        }
    }

    showWlbar = () =>{
        if (this.state.showWatchlist){
            return(
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <div
                        style={{
                            backgroundColor: 'rgb(255,255,255)', width: '75%', marginBottom: 20, borderRadius: 20, paddingLeft: 10, display: 'flex', justifyContent: 'space-between', boxShadow: this.state.hover ? '0 0 10px rgb(223,225,229)' : 'none', borderColor: 'rgb(237,238,240)', borderStyle: 'solid', borderWidth: 'thin', minWidth: 250,
                        }}
                        onMouseEnter={() => this.setState({ hover: true })}
                        onMouseLeave={() => this.setState({ hover: false })}
                    >
                        <input
                            type="text"
                            style={{ width: '90%', border: 'none', outline: 'none', paddingTop: 5, paddingBottom: 5 }}
                            value={this.state.searchMomentumText}
                            onChange={(event) => {
                                let searchMomentumText = event.target.value;
                                this.setState({ searchMomentumText, selectedPreset: null }, () => {
                                    const { isSearching } = this.state;
                                    if (isSearching) {
                                        return;
                                    } else {
                                        this.setState({ isSearching: true });
                                        setTimeout(() => {
                                            this.setState(
                                                { isSearching: false },
                                                this.onSubmitSearchMomentum
                                            );
                                        }, 2000);
                                    }
                                });
                            }}
                            onFocus={() => {
                                this.setState({ showTrendPredictor: true });
                            }}
                            placeholder="Enter a symbol or company name"
                            ref={this.input}
                        />

                        <div style={{ minWidth: 20, width: '5%', backgroundColor: '#00A99D', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0px 15px 15px 0px' }}>
                            <FaSearch size={10} style={{ color: 'rgb(255,255,255)' }} />
                        </div>
                    </div>
                </div>
            )
        }
    }    

    render() {
        // console.log(this.state)
        return (
            <div style={{ width: '100%' }} onMouseEnter={()=>this.setState({hover:true})} onMouseLeave={()=>this.setState({hover:false})}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', filter: this.state.showMassAdd?'blur(10px)':null }}>
                    {this.showWlbar()}
                    {this.state.showWatchlist?
                    <div style={{
                        width: '100%',
                    }}>
                        {this.showTopTickers()}
                    </div> : null}
                    <div style={{
                        width: '100%',
                        height: this.state.sudoPortfolioStocks.length <1? 675:''
                    }}>
                        {this.props.isSmallScreen? this.showBottomTickersSmall() : this.showBottomTickers()}
                    </div>

                </div>
                {this.massAddModal()}
                {this.fileImportModal()}
                {this.customsModal()}
                {this.cashModal()}
                {this.state.showErrorModal?<ErrorModal errorType={this.state.errors} closeError={()=>this.setState({showErrorModal:false})}/>:null}
            </div>
        )
    }
}