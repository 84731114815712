import React from 'react'
import {  FaSearch } from "react-icons/fa";
import NoImage from "../../assets/newUI/logo_notfound.png"
import upArrow from "../../assets/single_up.svg";
import downArrow from "../../assets/single_down.svg";
import strongUpArrow from "../../assets/double_up.svg";
import strongDownArrow from "../../assets/double_down.svg";
import flatArrow from "../../assets/flat.svg";
import ENDPOINT from '../Endpoint'
import SearchEndpoint from '../SearchEndpoint';


export default class TradeGroup extends React.Component{
    constructor(props) {
        super(props);
        this.input = React.createRef();
        this.state={
            searchMomentumText:'',
            momDataSource: [],
            isSearching: false,
            selected: [],
            hover:'',
            noSelection:false,
            errors:'',
            canTrade: false,
            marketClosedModal: false,
        }
    }
    componentDidMount = () => {
        this.getMarketStatus();
    }

    getMarketStatus = () =>{
        // console.log('inside can trade')
        fetch(`${SearchEndpoint}/istradinghour`,{
            method:"GET"
        })
        .then((res) => res.json())
        .then((responseJson) => {
            this.setState({canTrade:responseJson})
    })}

    utcToLocal(timeAndDate){
        if(timeAndDate === 'Market is closed today'){
            return ''
        }
        // console.log('mkt open',timeAndDate )
        let date = new Date(timeAndDate).toString().split(" ")
        if (date && date.length > 3){
            let hour = date[4].substring(0,2) 
            let min = date[4].substring(3,5) 
            let day = date[1]+ ' ' + date[2].replace(/^0(?:0:0?)?/, '')+ ', ' + date[3]
            let amPm = 'AM'
            if (hour > 12){
                hour = hour - 12
                amPm = 'PM'
            }
            else if(hour === '00'){
                hour = '12'
            }
            let time = `${hour}:${min} ${amPm}`
            return `The market will open at ${time.replace(/^0(?:0:0?)?/, '')} on ${day}`
        }
    }

    onSubmitSearchMomentum = () => {
        const { searchMomentumText } = this.state;
        this.setState({ isMomDataLoading: true });
        // let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchMomentumText}`;
        let url = `${SearchEndpoint}/ticker?search=${searchMomentumText.toUpperCase()}`;

        fetch(url, {
            "Access-Control-Allow-Headers": {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                "Access-Control-Request-Headers": "*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
            },
        })
            .then((res) => res.json())
            .then((responseJson) => {
                var data = responseJson.content;
                this.setState({
                    isMomDataLoading: false,
                    momDataSource: data,
                });
            })
            .catch((err) => {
                this.setState({ isMomDataLoading: false });
                console.log(err);
            });
    };

    deselect = (bad)=>{
        let mapped = this.state.selected.filter(ele=> ele !== bad)
        this.setState({selected:[...mapped]})
    }

    selectedStocks = ()=>{
        let mapped = []
        this.state.selected.forEach(ele=>{
            mapped.push(
                <div onClick={()=>this.deselect(ele)} /*style={{display:'flex', justifyContent:'space-evenly', backgroundColor:'rgb(70,182,179)', alignItems:'center', marginRight:25, borderRadius: 12,cursor:'pointer', marginBottom:5}}*/>
                    {this.createBox(ele)}
                </div>
            )
        })
        return mapped
    }


    showErrors = () => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '75%' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(253,180,181)', borderRadius: 10, padding: 10, marginBottom: 5, width: '100%' }}>
                    <p style={{ margin: 0 }}>{this.state.errors}</p>
                </div>
            </div>
        )
    }

    getArrow = (direction) => {
        switch (direction) {
          case -1:
            return downArrow;
          case 1:
            return upArrow;
          case 2:
            return strongUpArrow;
          case -2:
            return strongDownArrow;
          default:
            return flatArrow;
        }
      };

    createBox = (ele)=>{
        return (
            <div onClick={()=>{
            }}
            onMouseEnter={()=>this.setState({hover:ele})}
            onMouseLeave={()=>this.setState({hover:''})}
            style={{width:100,height:175, marginBottom:10, marginRight:15}}
            // style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', border:'solid', borderColor:'#dddddd', borderWidth:1,backgroundColor: this.state.hover === ele?'rgb(220,220,220)'  :'rgb(243,243,243)', paddingLeft: 20, paddingRight: 20, paddingLeft:10, paddingRight:10, borderRadius: 5, marginTop: 10, cursor: 'pointer' }}
        >
            <div style={{display:'flex', cursor:'pointer', justifyContent:'center',alignItems:'center',backgroundColor:'rgb(255,255,255)', borderRadius:7,overflow:'hidden', width:100, height:100,justifySelf:'flex-end',border:'solid',borderWidth:'0.25px' }}>
                {ele.imageURL === "not-found"?
                    <div style={{objectFit:'contain', width:50,height:50,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'rgb(17,17,17)',borderRadius:10 }}
                    >
                        <p style={{margin:0, fontSize:24,fontWeight:'bold', color:'rgb(255,255,255)'}}>{ele.symbol[0]}</p>
                    </div>
                :
                    <img 
                        // src={this.checkLogo(ele.symbol)?`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`:NoImage}
                        src={ele.imageURL}
                        style={{objectFit:'contain', width:80,height:80,padding:10, }}
                    />
                }
            </div>
            <div style={{display:'flex', flexDirection: 'column', width:'100%', padding:10 }}>
                <p style={{ margin: 0, textAlign: 'center', fontWeight:'bold' }}>{ele.symbol}</p>
                {/* <p style={{ margin: 0, textAlign: 'center',overflow: 'hidden',  textOverflow: 'ellipsis' }}>{ele.company}</p> */}
            </div>
        </div>
            )
        }

    render(){
        return(
            <div style={{display:'flex', flexDirection:'column', backgroundColor:'rgb(255,255,255)', padding:20, borderRadius:12, marginBottom:25}}>
                        <div style={{width:'90%', margin:'auto', display:'flex', justifyContent:'flex-start', alignItems:'center'}}>
                            <p style={{fontSize:24}}>Trade Group Setup</p>
                        </div>
                        <div style={{width:'90%', margin:'auto', display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:5}}>
                           <p style={{fontSize:20, width:'80%'}}>
                                Name your Trade Group
                            </p>
                            <div style={{width:'25%', display:'flex',justifyContent:'center',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5,}}>

                                <input 
                                    style={{border:'none',textAlign:'center', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent',width: '100%'}}
                                    type="text" 
                                    value={this.props.groupName}
                                    onChange={(e)=>this.props.changeTg(e,'groupName')
                                }/>
                            </div>
                        </div>
                        {/* <div style={{width:'90%', margin:'auto', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                           <p style={{margin:0, fontSize:20, width:'80%'}}>
                                What percentage of your account value would you like to dedicate to your trade group?
                            </p>
                            <div style={{width:'20%'}}>
                                <p style={{margin:0, textAlign:'center',color:'rgb(52,131,129)',}}>{(this.props.groupAmount*100).toFixed(0)}%</p>
                                <input 
                                    type="range" 
                                    value={this.props.groupAmount} 
                                    onChange={(e)=>this.props.changeTg(e,'groupAmount')}
                                    min="0.02"
                                    max="0.98" 
                                    step={0.01}
                                    style={{width:'100%'}}
                                />
                            </div>

                        </div> */}
                        
                        <div style={{width:'90%', margin:'auto', marginBottom: 25}}>
                            <div>
                                <p style={{fontSize:22,margin:0}}>
                                    Selected Stocks:
                                </p>
                            </div>
                            <div style={{display:'flex',alignItems:'center', flexWrap:'wrap-reverse', marginTop:25}}>
                                {this.selectedStocks()}
                            </div>
                            <div>
                                <p style={{fontSize:18}}>
                                    Search and select stocks below to add to your trade group. {this.state.selected.length>0?'To remove a stock from your trade group click on it above. (maximum 4 and minimum 2) ':null}
                                </p>
                            </div>
                            {this.state.errors.length > 0?
                                <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    {this.showErrors()}
                                </div>
                            :
                                null
                            }
                        </div>
                        <div style={{display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column',marginTop:20}}>
                        <div
                            style={{
                                backgroundColor: 'rgb(255,255,255)', width: '75%', marginBottom: 20, borderRadius: 20, paddingLeft: 10, display: 'flex', justifyContent: 'flex-start', boxShadow: this.state.hover ? '0 0 10px rgb(223,225,229)' : 'none', borderColor: 'rgb(237,238,240)', borderStyle: 'solid', borderWidth: 'thin', minWidth: 250, 
                            }}
                            onMouseEnter={() => this.setState({ hover: 'input' })}
                            onMouseLeave={() => this.setState({ hover: false })}
                        >
                            <div style={{ minWidth: 20, width: '5%', /*backgroundColor: '#00A99D',*/ display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                                <FaSearch size={'50%'} style={{color: '#dddddd'}} />                        
                            </div>
                            <input 
                                ref={this.input}
                                style={{ width: '90%', border: 'none', outline: 'none', paddingTop: 5, paddingBottom: 5}}
                                placeholder="Enter stock symbol or company name"
                                type="text" 
                                value={this.state.searchMomentumText} 
                                onChange={(event) => {
                                let searchMomentumText = event.target.value.toUpperCase();
                                this.setState({ searchMomentumText, selectedPreset: null }, () => {
                                    const { isSearching } = this.state;
                                    if (isSearching) {
                                        return;
                                    } else {
                                        this.setState({ isSearching: true });
                                        setTimeout(() => {
                                            this.setState(
                                                { isSearching: false },
                                                this.onSubmitSearchMomentum
                                            );
                                        }, 2000);
                                    }
                                });
                            }} />
                            </div>
                        </div>
                        <div style={{maxHeight: 300, overflowY:'scroll', paddingLeft:20, paddingRight:20, marginTop:20, }}>
                        {this.state.searchMomentumText.length > 0? 
                            this.state.isSearching? 
                            <div style={{width:'100%', marginTop:75}}>
                                <p style={{textAlign:'center', fontSize:22, fontWeight:'bold'}}> Loading...</p>
                            </div>:
                            <div style={{display:'flex', flexWrap:'wrap'}}>
                                {
                                    this.state.momDataSource.length > 0 ?
                                        this.state.momDataSource.map(ele =>
                                        
                                            ele.isDwSupported ? 
                                                <div onClick={()=>{
                                                        if(this.state.selected.length === 4){
                                                            this.setState({errors:'You can only have up to four stocks in your trade group'})
                                                        }
                                                        else if(this.state.selected.includes(ele)){
                                                            this.setState({errors:`You already selected ${ele.symbol}`})
                                                        }
                                                        else{
                                                            this.setState({searchMomentumText:'', selected:[...this.state.selected, ele,], momDataSource:[],errors:''})
                                                        }
                                                    }}
                                                    onMouseEnter={()=>this.setState({hover:ele})}
                                                    onMouseLeave={()=>this.setState({hover:''})}
                                                    // style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', border:'solid', borderColor:'#dddddd', borderWidth:1,backgroundColor: this.state.hover === ele?'rgb(220,220,220)'  :'rgb(243,243,243)', paddingLeft: 20, paddingRight: 20, paddingLeft:10, paddingRight:10, borderRadius: 5, marginTop: 10, cursor: 'pointer' }}
                                                >
                                                    {console.log('ele', ele)}
                                                    {this.createBox(ele)}
                                                </div> 
                                            :
                                            null
                                        ) :
                                        <div style={{width:'100%', marginTop:75}}>
                                            <p style={{textAlign:'center'}}>The stock you are looking for does not exist or is not supported. Please try another.</p>
                                        </div>
                                }
                            </div>
                        :
                            <div style={{width:'100%', marginTop:75}}>
                                <p style={{textAlign:'center'}}> Search above to see results</p>
                            </div>
                        }
                        {this.state.noSelection? this.displayErrors():null}
                        <div style={{display:'flex',height:'100%', justifyContent:'center',alignItems:'center',marginTop:25}}>
                            <div 
                                style={{paddingLeft: 20, paddingRight: 20, paddingTop:7, paddingBottom:7, borderRadius: 8, border:'solid',borderColor:'#3b8e8c',borderWidth:'.25px', fontSize: 'smaller', minWidth: 0, display:'flex', alignItems:'center',justifyContent:'center', cursor:'pointer' }}
                                onClick={() => {
                                    this.getMarketStatus()
                                    if(this.state.selected.length > 1){
                                        if(this.state.canTrade.status) {
                                            this.props.selectStocks(this.state.selected)
                                        } else {
                                            this.setState({marketClosedModal: true})
                                        }
                                    }
                                    else{
                                        this.setState({errors:'You need at least two stock into your trading group to proceed'})
                                    }
                                }} 
                            >
                                <p style={{ color: '#3b8e8c', margin: 0, fontWeight: 'bold', fontSize: 18 }}>
                                    Submit Trade Group
                                </p> 
                            </div>
                        </div>
                        {
                            this.state.marketClosedModal ?
                            <div style={{
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                position: 'fixed',
                                height: '100%',
                                width: '100%',
                                top: 0,
                                left: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                overflow: 'auto',
                                zIndex: 99999,
                                }}>
                                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', height: '40%', paddingBottom:25}}>
                                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Market Closed</p>
                                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=> this.setState({marketClosedModal: false})}> X </p>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>                        
                                        <div style={{width:'90%', margin:'auto',marginBottom:25, marginTop:25}}>
                                            <h4 style={{color:'rgb(17,17,17)'}}>The market is currently closed, please try again during trading hours.</h4>
                                        </div>
                                        <div style={{width:'90%', margin:'auto',marginBottom:25, marginTop:25}}>
                                            <h4 style={{color:'rgb(17,17,17)'}}>{this.utcToLocal(this.state.canTrade.market_open)}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            null
                        }
                    </div>
            </div>
        )
    }
}