import React from 'react'
import LinkToPlaid from '../LinkToPlaid'
import RoiChart from '../roiChart'
import NoImage from '../../assets/newUI/usersicon.png'
import Locked from '../../assets/newUI/locked.svg'
import { FaUserCircle, FaCog, FaLongArrowAltLeft } from 'react-icons/fa'
import AccountModal from './AccountModal'
import ENDPOINT from '../Endpoint'
import SubModal from '../SubModal'
import { Link, NavLink } from 'react-router-dom'
import AreYouSure from '../areYouSureModal'
import Loader from '../components/accountInfo/Loader'
import { TailSpin, ThreeDots } from 'react-loader-spinner'
import { Dialog } from 'primereact/dialog'
import { ConfirmDialog } from 'primereact/confirmdialog'
import Amplify, { Auth, container } from 'aws-amplify'
import awsExports from '../../aws-config'
import LinkPlaidBtn from '../components/LinkPlaidBtn'
import Axios from 'axios'
import Endpoint from '../Endpoint'
import RemoveAccountIcon from '../../assets/remove-account-icon.svg'
import FinraQuestionnaire from '../FinraQuestionnaire'
import DwApexQuestionnaire from '../DwApexQuestionnaire'
import UpdateDwApexQuestionnaire from '../UpdateDwApexQuestionnaire'
import Iframe from 'react-iframe'
import DwKYC from '../DwKYC'
import {
  selectedTab,
  unSelectedTab,
  selectedText,
  unSelectedText,
} from '../../utility-functions/tabStyles'
import { validatePassword } from '../../utility-functions/validatePassword'
import ApexKYC from '../ApexKYC'
import { connect } from 'react-redux'
import { A } from 'aws-amplify-react/lib-esm/AmplifyTheme'
import ConfirmationDialog from '../components/ConfirmationDialog'
import PasswordMessage from '../PasswordMessage'
import PasswordInput from '../PasswordInput'
import '../../scss/pages/Account.scss'
import Promotions from '../promotions/Promotions'
import { Checkbox } from 'primereact/checkbox'
import { Button } from 'primereact/button'

Amplify.configure(awsExports)

const brandColor = '#00A99D'
const GREEN = '#3b8e8c'

class Account extends React.Component {
  state = {
    activeTab: 'inv',
    acc: false,
    hover: false,
    dwFix: [],
    dwNeedsFix: [],
    settingsDropdown: false,
    youSure: false,
    showLinkOrUnlinkDialog: false,
    showRemoveAccountDialog: false,
    selectedAccountToRemove: null,
    //kyc
    kyc: false,
    docType: 'PASSPORT',
    docFront: false,
    docBack: false,
    kycNecessary: false,
    // sub
    showSub: false,
    showDowngrade: false,
    // pw
    showChangePw: false,
    oldPass: '',
    newPass: '',
    newPassConf: '',
    pwStatus: false,
    // cc
    showUpdateCC: false,
    // image
    uploadImageStatus: false,
    hoverIndex: -1,
    accountSelected: 0,
    userDetails: '',
    userDetails2: '',
    accountTab: this.props.defaultAccountTab
      ? this.props.defaultAccountTab
      : 'personal',
    loading: true,
    bankingTab: true,

    showErrorDialog: false,
    showFinraQuestionaire: false,
    showUpdateDwApexQuestionaire: false,
    showExtendedHours: false,
    extendedHoursAgreement: false,
    setExtendedLoading: false,

    showDwKYC: false,
    showApexKYC: false,
    gfv: '-',

    showPasswordMessage: false,
  }

  componentDidMount() {
    window.zE('webWidget', 'show')
    this.getKycStatus()
    // this.props.getCustoms()
    this.fetchPresetCategories()
    // We need to call this as props are not passed to this screen when the user refreshes the screen, leading to no accounts being shown
    this.getAllAccountInfo()
    this.getUserData()
    this.getGFV()
    // this.getStatements(this.state.allAccounts[this.state.accountSelected].accountId)
  }

  // TRACK PROPS AND STATE CHANGES
  // componentDidUpdate(prevProps, prevState) {
  //     Object.entries(this.props).forEach(([key, val]) =>
  //       prevProps[key] !== val && console.log(`Prop '${key}' changed from ${prevProps[key]} to ${val}`)
  //     );
  //     if (this.state) {
  //       Object.entries(this.state).forEach(([key, val]) =>
  //         prevState[key] !== val && console.log(`State '${key}' changed from ${prevState[key]} to ${val}`)
  //       );
  //     }
  //   }
  componentWillUnmount() {
    window.zE('webWidget', 'hide')
  }

  getGFV = () => {
    this.props.accounts?.forEach((account) => {
      if (account.product_assigned && account.product_assigned === 'equity') {
        this.setState({ gfv: account.gfv_count })
      }
    })
  }

  turnOnExternalHours = async () => {
    try {
      this.setState({ setExtendedLoading: true })
      let res = await Axios.get(`${ENDPOINT}/mobile/dw/patch/user?extend=1`, {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      })
      console.log(res)
      await this.props.getAllAccountInfo()
      this.setState({ setExtendedLoading: false, showExtendedHours: false })
    } catch (error) {
      console.log(error)
    }
  }

  turnOffExternalHours = async () => {
    try {
      this.setState({ setExtendedLoading: true })
      let res = await Axios.get(`${ENDPOINT}/mobile/dw/patch/user?extend=0`, {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      })
      console.log(res)
      await this.props.getAllAccountInfo()
      this.setState({ setExtendedLoading: false, showExtendedHours: false })
    } catch (error) {
      console.log(error)
    }
  }

  getUserData = async () => {
    await fetch(`${ENDPOINT}/users?details=false`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => this.setState({ userDetails: responseJson }))
      .then(() => {
        if (this.state.userDetails.accountSub) {
          if (this.state.userDetails.accountSub.source === 'apple') {
            this.setState({
              pwStatus:
                'You have subscribed using the iOS App. In order to downgrade your account you must downgrade from the iOS App',
            })
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })

    await fetch(`${ENDPOINT}/mobile/get-email`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => this.setState({ userDetails2: responseJson }))
      .catch((err) => {
        console.log(err)
      })
    this.setState({ loading: false })
  }
  getAllAccountInfo = () => {
    // SUPER IMPORTANT gets the holdings and account information
    fetch(`${ENDPOINT}/mobile/user/holdings`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      //   .then(responseJson => this.setState({holdings: responseJson}))
      .then((responseJson) => {
        let allAccounts = []
        console.log('neten all resp: ', responseJson)
        responseJson.items.forEach((inst) =>
          inst.accounts.forEach((acc) => {
            if (inst.instName === 'DriveWealth') {
              allAccounts.push({ ...acc, inst: inst.instName })
              // if (acc?.product_assigned && acc?.product_assigned === 'equity')
              //   this.setState({ gfv: acc?.gfv_count })
            }
          })
        )
        //   console.log('neten all parsed: ',allAccounts)
        this.setState({ allAccounts, itemsResp: responseJson.items })
      })
      .then(() => {
        this.getStatements(
          this.state.allAccounts[this.state.accountSelected].accountId
        )
      })
      .catch((err) => console.log(`catching errors`, err))
  }

  getStatements = (id) => {
    let url = `${ENDPOINT}/mobile/dw/documents/${id}`
    this.setState({ loadingStatements: true })
    fetch(url, {
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err)
      })
      .then((responseJson) => {
        this.setState({
          confirmations: responseJson.payload.confirmations,
          statements: responseJson.payload.statements,
          tax_forms: responseJson.payload.tax_forms,
        })
      })
      .then(() => this.setState({ loadingStatements: false }))
  }

  fetchPresetCategories = () => {
    fetch(`${ENDPOINT}/drivewealth/preset`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .catch((err) => {
        console.log(err)
      })
      .then((responseJson) => this.setState({ presets: responseJson }))
  }

  toggleShowLinkOrUnlinkDialog = () => {
    this.setState({
      showLinkOrUnlinkDialog: !this.state.showLinkOrUnlinkDialog,
    })
  }

  createRow = (title, value) => {
    return (
      <div
        style={{
          width: '95%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: 'auto',
          borderRadius: 7,
          backgroundColor: 'rgba(247, 247, 247, 0.73)',
          padding: 10,
          marginTop: '3%',
          marginBottom: '3%',
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <div style={{ width: '50%', marginLeft: 10 }}>
          <p style={{ color: '#242a38', margin: 0 }}>{title}</p>
        </div>
        <div
          style={{
            borderRadius: 15,
            backgroundColor: GREEN,
            padding: '2%',
            width: '50%',
            marginRight: 10,
          }}
        >
          <p
            style={{
              color: 'rgb(255,255,255)',
              margin: 0,
              fontSize: '13px',
              textAlign: 'center',
            }}
          >
            {value}
          </p>
        </div>
      </div>
    )
  }

  //REMOVE ACCOUNT
  toggleRemoveAccountDialog = () => {
    this.setState({
      showRemoveAccountDialog: !this.state.showRemoveAccountDialog,
    })
  }

  removeAccount = (account) => {
    this.setState({ selectedAccountToRemove: account }, () => {
      this.toggleRemoveAccountDialog()
    })
  }

  removeAccountAPI = async () => {
    // let body = {
    //   accounts: [
    //     {
    //       id: this.state.selectedAccountToRemove?.accountId,
    //       itemId: this.state.selectedAccountToRemove?.itemId,
    //     },
    //   ],
    // }
    // return await Axios.post(`${Endpoint}/plaid/accounts/remove`, body, {
    // headers: {
    //   Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
    //   'Content-Type': 'application/json',
    // },
    // })
    //   .then(async (res) => {
    //     await this.props.getAllAccountInfo()
    //     return true
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //     return false
    //   })

    return await Axios.get(
      `${Endpoint}/mobile-plaid/plaid/unlink?item_id=${this.state.selectedAccountToRemove?.itemId}`,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      }
    )
      .then(async (res) => {
        if (res?.data?.status) {
          await this.props.getAllAccountInfo()
          return true
        } else {
          return false
        }
      })
      .catch((error) => {
        console.log(error)
        return false
      })

    // return fetch(
    //   `${Endpoint}/mobile-plaid/plaid/unlink?item_id=${this.state.selectedAccountToRemove?.itemId}`,
    //   {
    //     method: 'GET',
    //   }
    // )
    //   .catch((err) => {
    //     console.log(err)
    //     return false
    //   })
    //   .then((res) => res?.json())
    //   .then(async (responseJson) => {
    //     console.log(responseJson)
    //     if (responseJson?.status) {
    //       await this.props.getAllAccountInfo()
    //       return true
    //     } else {
    //       return false
    //     }
    //   })
  }

  tabs() {
    let selectedBk = {
      backgroundImage:
        this.state.activeTab === 'inv'
          ? 'linear-gradient(94deg, rgb(30, 51, 66) 0%, rgb(73, 185, 168))'
          : 'linear-gradient(94deg,rgb(73, 185, 168) 0%, rgb(30,51,66)',
      width: '50%',
      height: '100%',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius:
        this.state.activeTab === 'inv' ? '5px 0px 0px 5px' : '0px 5px 5px 0px',
      height: '100%',
    }
    let unselectedBk = {
      width: '50%',
      height: '100%',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius:
        this.state.activeTab === 'inv' ? '0px 5px 5px 0px' : '5px 0px 0px 5px',
      height: '100%',
      backgroundColor: 'rgba(146, 149, 161, 0.1)',
    }
    let selectedTxt = {
      margin: 0,
      margin: 'auto',
      marginLeft: this.state.isSmallScreen ? 10 : 'auto',
      fontSize: this.state.isSmallScreen ? 14 : 16,
      fontSize: '16px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.3,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: 'rgb(255, 255, 255)',
    }
    let unselectedTxt = {
      margin: 0,
      margin: 'auto',
      marginLeft: this.state.isSmallScreen ? 10 : 'auto',
      fontSize: this.state.isSmallScreen ? 14 : 16,
      fontSize: 16,
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.3,
      letterSpacing: 'normal',
      textAlign: 'left',
      color: 'rgb(146, 149, 161)',
    }
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div
          style={this.state.activeTab === 'inv' ? selectedBk : unselectedBk}
          onClick={() => this.setState({ activeTab: 'inv' })}
        >
          <p
            style={this.state.activeTab === 'inv' ? selectedTxt : unselectedTxt}
          >
            Investment Accounts
          </p>
        </div>
        <div
          style={this.state.activeTab === 'bank' ? selectedBk : unselectedBk}
          onClick={() => this.setState({ activeTab: 'bank' })}
        >
          <p
            style={
              this.state.activeTab === 'bank' ? selectedTxt : unselectedTxt
            }
          >
            Bank Accounts
          </p>
        </div>
      </div>
    )
  }

  filterAccs = () => {
    let data = this.props.accounts
    let filtered

    if (this.state.activeTab === 'bank') {
      filtered = data.filter((acc) => acc.type === 'depository')
    } else {
      filtered = data.filter((acc) => acc.type !== 'depository')
    }
    return filtered.map((acc) => this.displayOneAcc(acc))
  }

  displayOneAcc = (acc) => {
    return (
      <div
        style={{
          display: 'flex',
          padding: 10,
          marginBottom: 5,
          backgroundColor: acc.name === this.state.hover ? '#dddddd' : '',
        }}
        onMouseLeave={() => this.setState({ hover: false })}
        onMouseEnter={() => this.setState({ hover: acc.name })}
        key={acc.mask}
      >
        <div style={{ flex: 3 }}>
          <p style={{ margin: 0 }}>{acc.inst}</p>
        </div>
        <div style={{ flex: 3 }}>
          <p style={{ margin: 0 }}>{acc.mask}</p>
        </div>
        <div style={{ flex: 3 }}>
          <p style={{ margin: 0 }}>
            {acc.product
              ? acc.product
              : this.state.activeTab === 'inv'
              ? 'Brokerage Account'
              : 'Bank Account'}
          </p>
        </div>
        <div style={{ flex: 3 }}>
          <p style={{ margin: 0 }}>{acc.mask_full}</p>
        </div>
        {/* <div style={{flex:1, display:'flex',justifyContent:'flex-end',alignItems:'center',cursor:'pointer'}} onClick={()=>this.setState({acc:acc})}>
                    <FaCog/>
                </div> */}
      </div>
    )
  }
  accHeadings = () => {
    return (
      <div
        style={{ display: 'flex', padding: 10, marginBottom: 5, marginTop: 10 }}
      >
        <div style={{ flex: 3 }}>
          <p style={{ margin: 0, fontWeight: 'bold' }}>Institution</p>
        </div>
        <div style={{ flex: 3 }}>
          <p style={{ margin: 0, fontWeight: 'bold' }}>Account #</p>
        </div>
        <div style={{ flex: 3 }}>
          <p style={{ margin: 0, fontWeight: 'bold' }}>Product</p>
        </div>
        <div style={{ flex: 3 }}>
          <p style={{ margin: 0, fontWeight: 'bold' }}>Name</p>
        </div>
        {/* <div style={{flex:1, display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                    <p style={{margin:0, fontWeight:'bold'}}>Settings</p>
                </div> */}
      </div>
    )
  }

  deleteOneCustom = (id) => {
    this.setState({ selectedStrat: '' })
    fetch(`${ENDPOINT}/risk-monkey/portfolios/delete/${id}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => console.log('responseJson:', responseJson))
      .then(() => {
        let strats = { ...this.props.customPorts }
        delete strats[id]
        this.props.updateCustoms(strats)
      })
  }

  showOneCustom = (title, data) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: 'solid',
            borderWidth: 0.25,
            borderColor: '#ddd',
            height: '100%',
            padding: 12,
            backgroundColor: this.state.hover === title ? '#f7f7f7' : null,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '60%',
            }}
            onMouseEnter={() => this.setState({ hover: title })}
            onMouseLeave={() => this.setState({ hover: '' })}
            onClick={() =>
              this.setState({
                selectedStrat: this.state.selectedStrat === title ? '' : title,
              })
            }
          >
            <p style={{ margin: 0, fontWeight: 'bold' }}>{title}</p>
          </div>
          <div
            style={{
              height: '100%',
              borderLeft: 'solid',
              borderWidth: 0.25,
              borderColor: '#ddd',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              /*backgroundColor:'rgb(253,180,181)',LIGHTER */ backgroundColor:
                '#ee5e68',
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 15,
              paddingRight: 15,
            }}
            // onClick={()=>this.deleteOneCustom(title)}
            onClick={() => this.setState({ youSure: title })}
          >
            <p style={{ margin: 0, color: 'rgb(255,255,255)' }}>Delete</p>
          </div>
        </div>
        <div>{this.showPortData(title, data, false)}</div>
      </div>
    )
  }

  showPortData = (title, data, location) => {
    let arr = []
    if (this.state.selectedStrat === title) {
      data.forEach((ele) => {
        // USE THIS TO MIRROR HOLDINGS
        arr.push(
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              borderBottom: 'solid',
              borderWidth: 0.25,
              borderColor: '#ddd',
            }}
          >
            <div
              style={{
                width: Array.isArray(ele) ? '25%' : '50%',
                borderRight: 'none',
                borderWidth: 0.25,
                borderColor: '#ddd',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: 12,
              }}
            >
              {Array.isArray(ele) ? (
                <a
                  style={{
                    textDecoration: 'none',
                    color: brandColor,
                    cursor: 'pointer',
                  }}
                  href={
                    ele.symbol === '$$' ? null : `/Dashboard/Trade/${ele[0]}`
                  }
                  target='_blank'
                >
                  {ele[0]}
                </a>
              ) : (
                <a
                  style={{
                    textDecoration: 'none',
                    color: brandColor,
                    cursor: 'pointer',
                  }}
                  href={
                    ele.symbol === '$$'
                      ? null
                      : `/Dashboard/Trade/${ele.symbol}`
                  }
                  target='_blank'
                >
                  {ele.symbol}
                </a>
              )}
            </div>
            <div
              style={{
                width: Array.isArray(ele) ? '25%' : '50%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                padding: 12,
              }}
            >
              {Array.isArray(ele) ? (
                <p style={{ margin: 0 }}>
                  {ele.symbol === '$$'
                    ? `$${this.addCommas(parseFloat(ele[1]).toFixed(2))}`
                    : this.addCommas(ele[1].toFixed(2))}
                </p>
              ) : (
                <p style={{ margin: 0 }}>
                  {ele.symbol === '$$'
                    ? this.addCommas(parseFloat(ele.quantity).toFixed(2))
                    : this.addCommas(ele.quantity.toFixed(2))}
                </p>
              )}
            </div>
            {Array.isArray(ele) ? (
              <div
                style={{
                  width: '25%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  padding: 12,
                }}
              >
                <p style={{ margin: 0 }}>
                  {this.addCommas(parseFloat(ele[2]).toFixed(2))}%
                </p>
              </div>
            ) : null}
            {Array.isArray(ele) ? (
              <div
                style={{
                  width: '25%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  padding: 12,
                }}
              >
                <p style={{ margin: 0 }}>${parseFloat(ele[3]).toFixed(2)}</p>
              </div>
            ) : null}
          </div>
        )
      })
      return (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              width: '100%',
            }}
          >
            <div
              style={{
                width: location === 'linked' ? '25%' : '50%',
                backgroundColor: 'rgb(245,246,247)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                padding: 12,
                borderRight: 'solid',
                borderWidth: 1,
                borderColor: 'rgb(255,255,255)',
              }}
            >
              <p style={{ fontWeight: 'bold', margin: 0 }}>Symbol</p>
            </div>
            <div
              style={{
                width: location === 'linked' ? '25%' : '50%',
                backgroundColor: 'rgb(245,246,247)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                padding: 12,
              }}
            >
              <p style={{ fontWeight: 'bold', margin: 0 }}>Number of Shares</p>
            </div>
            {location === 'linked' ? (
              <div
                style={{
                  width: '25%',
                  backgroundColor: 'rgb(245,246,247)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  padding: 12,
                  borderLeft: 'solid',
                  borderWidth: 1,
                  borderColor: 'rgb(255,255,255)',
                }}
              >
                <p style={{ fontWeight: 'bold', margin: 0 }}>Percentage</p>
              </div>
            ) : null}
            {location === 'linked' ? (
              <div
                style={{
                  width: '25%',
                  backgroundColor: 'rgb(245,246,247)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  padding: 12,
                  borderLeft: 'solid',
                  borderWidth: 1,
                  borderColor: 'rgb(255,255,255)',
                }}
              >
                <p style={{ fontWeight: 'bold', margin: 0 }}>Position Value</p>
              </div>
            ) : null}
          </div>
          <div style={{ width: '100%', maxHeight: 600, overflowY: 'scroll' }}>
            <div>{arr}</div>
          </div>
        </div>
      )
    }
  }

  addCommas(flt) {
    return flt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  fixes = () => {
    let fixed = []
    this.dwNeedsFix.forEach((fix) => {
      return (
        <div>
          <p>SSN</p>
          <input
            type='text'
            value={{ dwFix: '' }}
            onChange={(e) => this.setState({ dwFix: e.target.value })}
          />
        </div>
      )
    })
  }

  setOldPassword = (value) => {
    this.setState({ pwStatus: false, oldPass: value })
  }

  setNewPassword = (value) => {
    this.setState({ pwStatus: false, newPass: value })
  }

  setNewConfPassword = (value) => {
    this.setState({ pwStatus: false, newPassConf: value })
  }

  customized = () => {
    let general = {
      backgroundImage:
        'linear-gradient(94deg, rgb(30, 51, 66) 0%, rgb(73, 185, 168))',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 5,
      height: '100%',
    }
    let arr = []
    Object.keys(this.props.customPorts).forEach((ele) =>
      arr.push(this.showOneCustom(ele, this.props.customPorts[ele]))
    )
    return (
      <div
        style={{
          minheight: 0,
          marginTop: 25,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          marginBottom: 25,
        }}
      >
        <div
          style={{
            width: '95%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <h4 style={{ color: 'rgb(17,17,17)' }}>
            Your Customized Portfolio Strategies
          </h4>
        </div>
        <div
          style={{
            width: '95%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <p style={{ color: '#afb2bb' }}>
            Use our{' '}
            <strong
              style={{
                fontWeight: 'bold',
                color: brandColor,
                cursor: 'pointer',
              }}
              onClick={() => this.props.toPc()}
            >
              {' '}
              portfolio constructor
            </strong>{' '}
            to create a new portfolio strategy, or optimize an existing
            portfolio
          </p>
        </div>
        {Object.keys(this.props.customPorts).length === 0 ? null : (
          <div style={general}>
            <p style={{ margin: 0, padding: 10, color: 'rgb(255,255,255)' }}>
              Portfolio Name - Select for more infomation
            </p>
          </div>
        )}
        <div style={{ width: '95%' }}>
          {this.props.customPorts ? <div style={{}}>{arr}</div> : null}
        </div>
      </div>
    )
  }

  settings = () => {
    if (this.state.settingsDropdown) {
      return (
        <div onMouseLeave={() => this.setState({ settingsDropdown: false })}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <FaCog size={'25px'} />
          </div>
          <div
            style={{
              position: 'absolute',
              right: '2%',
              backgroundColor: '#f9f9f9',
              minWidth: '10%',
              overflow: 'auto',
              boxShadow: '0px 8px 16px 0px rgb(0 0 0 / 20%)',
              zIndex: 100,
              marginRight: 25,
            }}
          >
            <div
              id='ManageSubscription_Button'
              style={{
                color: 'black',
                padding: '12px 16px',
                textDecoration: 'none',
                display: 'block',
                cursor: 'pointer',
                backgroundColor:
                  this.state.hover === 'sub' ? 'rgb(200,200,200)' : '',
              }}
              onClick={
                this.props.subStatus
                  ? () => this.setState({ showDowngrade: true })
                  : () => this.setState({ showSub: true })
              }
              onMouseEnter={() => this.setState({ hover: 'sub' })}
            >
              <p style={{ margin: 0, fontWeight: '', color: 'rgb(17,17,17)' }}>
                {this.props.subStatus
                  ? 'Downgrade Subscription'
                  : 'Boost to Pro Subscription'}
              </p>
            </div>
            {/* {this.props.subStatus?
                        <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'cc'?'rgb(200,200,200)':'',}}  onClick={()=>this.setState({showUpdateCC:true})} onMouseEnter={()=>this.setState({hover:'cc'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Mange Payments</p>
                        </div>:null} */}
            {/* <div style={{ color: 'black', padding: '12px 16px', textDecoration:'none', display: 'block', cursor:'pointer',backgroundColor:this.state.hover === 'username'?'rgb(200,200,200)':'',}}  onClick={this.props.subStatus?()=>this.setState({showDowngrade:true}) :()=>this.setState({showSub:true})} onMouseEnter={()=>this.setState({hover:'username'})}>
                            <p style={{margin:0,fontWeight:'', color:'rgb(17,17,17)'}}>Update Email Address</p>
                        </div> */}
            <div
              id='UpdatePassword_Button'
              style={{
                color: 'black',
                padding: '12px 16px',
                textDecoration: 'none',
                display: 'block',
                cursor: 'pointer',
                backgroundColor:
                  this.state.hover === 'pw' ? 'rgb(200,200,200)' : '',
              }}
              onClick={() => this.setState({ showChangePw: true })}
              onMouseEnter={() => this.setState({ hover: 'pw' })}
            >
              <p style={{ margin: 0, fontWeight: '', color: 'rgb(17,17,17)' }}>
                Update Password
              </p>
            </div>

            <NavLink
              id='UpdateQuestionnaire_Button'
              to='/Questionnaire'
              exact={true}
            >
              <div
                style={{
                  color: 'black',
                  padding: '12px 16px',
                  textDecoration: 'none',
                  display: 'block',
                  cursor: 'pointer',
                  backgroundColor:
                    this.state.hover === 'orders' ? 'rgb(200,200,200)' : '',
                }}
                onMouseEnter={() => this.setState({ hover: 'orders' })}
              >
                <p
                  style={{ margin: 0, fontWeight: '', color: 'rgb(17,17,17)' }}
                >
                  Update Investment Questionnaire
                </p>
              </div>
            </NavLink>
            <NavLink
              id='UpdateDWApplication_Button'
              to='/UpdateDw'
              exact={true}
            >
              <div
                style={{
                  color: 'black',
                  padding: '12px 16px',
                  textDecoration: 'none',
                  display: 'block',
                  cursor: 'pointer',
                  backgroundColor:
                    this.state.hover === 'statements' ? 'rgb(200,200,200)' : '',
                }}
                onMouseEnter={() => this.setState({ hover: 'statements' })}
              >
                <p
                  style={{ margin: 0, fontWeight: '', color: 'rgb(17,17,17)' }}
                >
                  Update DriveWealth Application
                </p>
              </div>
            </NavLink>
            {this.state.kycNecessary ? (
              <div>
                <div
                  style={{
                    color: 'black',
                    padding: '12px 16px',
                    textDecoration: 'none',
                    display: 'block',
                    cursor: 'pointer',
                    backgroundColor:
                      this.state.hover === 'kyc' ? 'rgb(200,200,200)' : '',
                  }}
                  onClick={() => this.setState({ kyc: true })}
                  onMouseEnter={() => this.setState({ hover: 'kyc' })}
                >
                  <p
                    style={{
                      margin: 0,
                      fontWeight: '',
                      color: 'rgb(17,17,17)',
                    }}
                  >
                    Upload Proof of ID
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div
            id='ShowSettings_Hover'
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
            onMouseEnter={() => this.setState({ settingsDropdown: true })}
          >
            <FaCog size={'25px'} color={'rgb(34,37,41)'} />
          </div>
        </div>
      )
    }
  }

  sureCheck = () => {
    let title = this.state.youSure
    return (
      <div
        style={{
          backgroundColor: 'rgba(0,0,0,0.5)',
          position: 'fixed',
          height: '100%',
          width: '100%',
          top: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'auto',
          zIndex: 99999,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            backgroundColor: 'rgb(255,255,255)',
            borderRadius: 10,
            width: this.props.isSmallScreen ? '90%' : '40%',
            paddingBottom: 25,
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: 'rgb(37,42,55)',
              paddingLeft: 20,
              paddingRight: 20,
              alignItems: 'center',
              paddingTop: 10,
              paddingBottom: 10,
              borderRadius: '10px 10px 0px 0px',
            }}
          >
            <p
              style={{
                color: 'rgb(255,255,255)',
                fontWeight: 'bold',
                fontSize: 20,
                margin: 0,
              }}
            >
              Delete {title}
            </p>
            <p
              style={{
                margin: 0,
                fontWeight: 'bold',
                color: 'rgb(255,255,255)',
                cursor: 'pointer',
              }}
              onClick={() => this.setState({ youSure: false })}
            >
              {' '}
              X{' '}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: 30,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <p>Are you sure you want to delete the custom strategy: {title}?</p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div
                style={{
                  backgroundColor: 'rgb(221,103,108',
                  borderRadius: 10,
                  padding: 20,
                  marginTop: 10,
                  marginBottom: 20,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  this.deleteOneCustom(title)
                  this.setState({ youSure: false })
                }}
              >
                <p style={{ margin: 0, color: 'rgb(255,255,255)' }}>
                  Delete this strategy
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  changePwModal = () => {
    if (this.state.showChangePw) {
      return (
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            position: 'fixed',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'auto',
            zIndex: 99999,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: 'auto',
              backgroundColor: 'rgb(255,255,255)',
              borderRadius: 10,
              width: this.props.isSmallScreen ? '90%' : '40%',
              paddingBottom: 25,
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'rgb(37,42,55)',
                paddingLeft: 20,
                paddingRight: 20,
                alignItems: 'center',
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <p
                style={{
                  color: 'rgb(255,255,255)',
                  fontWeight: 'bold',
                  fontSize: 20,
                  margin: 0,
                }}
              >
                {this.props.t('changepassword')}
              </p>
              <p
                style={{
                  margin: 0,
                  fontWeight: 'bold',
                  color: 'rgb(255,255,255)',
                  cursor: 'pointer',
                }}
                onClick={() => this.setState({ showChangePw: false })}
              >
                {' '}
                X{' '}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                marginTop: 30,
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <div style={{ width: '80%' }}>
                {/* <div
                  style={{
                    backgroundColor: '#f0f0f0',
                    display: 'flex',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      width: 75,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={Locked} />
                  </div>
                  <div
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 7,
                      paddingRight: 7,
                      width: '95%',
                    }}
                  >
                    <p>{this.props.t('oldpassword')}</p>
                    <input
                      style={{
                        minWidth: 200,
                        border: 'none',
                        width: '100%',
                        backgroundColor: 'transparent',
                        outline: 'none',
                      }}
                      placeholder={this.props.t('password')}
                      value={this.state.oldPass}
                      onChange={(e) =>
                        this.setState({
                          pwStatus: false,
                          oldPass: e.target.value,
                        })
                      }
                      type='password'
                    />
                  </div>
                </div> */}
                <PasswordInput
                  password={this.state.oldPass}
                  setPassword={this.setOldPassword}
                  placeHolder={this.props.t('oldpassword')}
                  className='change-password-input space-bottom'
                />

                {/* <div
                  style={{
                    backgroundColor: '#f0f0f0',
                    display: 'flex',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      width: 75,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={Locked} />
                  </div>
                  <div
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 7,
                      paddingRight: 7,
                      width: '95%',
                    }}
                  >
                    <p>{this.props.t('newpassword')}</p>
                    <input
                      style={{
                        minWidth: 200,
                        border: 'none',
                        width: '100%',
                        backgroundColor: 'transparent',
                        outline: 'none',
                      }}
                      placeholder={this.props.t('password')}
                      value={this.state.newPass}
                      onChange={(e) =>
                        this.setState({
                          pwStatus: false,
                          newPass: e.target.value,
                        })
                      }
                      type='password'
                    />
                  </div>
                </div> */}
                <PasswordInput
                  password={this.state.newPass}
                  setPassword={this.setNewPassword}
                  placeHolder={this.props.t('newpassword')}
                  className='change-password-input space-bottom'
                />
                <PasswordInput
                  password={this.state.newPassConf}
                  setPassword={this.setNewConfPassword}
                  placeHolder={this.props.t('confirmnewpassword')}
                  className='change-password-input space-bottom'
                />
                {/* <div
                  style={{
                    backgroundColor: '#f0f0f0',
                    display: 'flex',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      width: 75,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={Locked} />
                  </div>
                  <div
                    style={{
                      paddingTop: 5,
                      paddingBottom: 5,
                      paddingLeft: 7,
                      paddingRight: 7,
                      width: '95%',
                    }}
                  >
                    <p>{this.props.t('confirmnewpassword')}</p>
                    <input
                      style={{
                        minWidth: 200,
                        border: 'none',
                        width: '100%',
                        backgroundColor: 'transparent',
                        outline: 'none',
                      }}
                      placeholder={this.props.t('password')}
                      value={this.state.newPassConf}
                      onChange={(e) =>
                        this.setState({
                          pwStatus: false,
                          newPassConf: e.target.value,
                        })
                      }
                      type='password'
                    />
                  </div>
                </div> */}
              </div>
              {this.state.showPasswordMessage && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '100%',
                    marginLeft: '20%',
                  }}
                >
                  <PasswordMessage
                    t={this.props.t}
                    password={this.state.newPass}
                  />
                </div>
              )}
              {this.state.pwStatus ? (
                <div
                  style={{
                    display: 'flex',
                    margin: 'auto',
                    width: '60%',
                    backgroundColor: this.successErrorLoadingBackground(),
                    padding: 12,
                    marginBottom: 10,
                    borderRadius: 8,
                  }}
                >
                  <p style={{ margin: 'auto' }}>{this.state.pwStatus}</p>
                </div>
              ) : null}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    borderRadius: 10,
                    padding: 20,
                    marginTop: 10,
                    marginBottom: 20,
                    cursor: 'pointer',
                    background: '#3F46F6',
                  }}
                  onClick={() => {
                    // this.setState({showChangePw:false})
                    this.changePW()
                  }}
                >
                  <p style={{ margin: 0, color: 'white' }}>
                    {this.props.t('changepassword')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  updateCreditCard = () => {
    fetch()
  }

  successErrorLoadingBackground = () => {
    if (
      this.state.pwStatus === 'Password Changed' ||
      this.state.subChange === 'Subcription Upgraded' ||
      this.state.uploadImageStatus === 'Document Uploaded'
    ) {
      return 'rgb(234,255,219)'
    } else {
      return 'rgb(253,180,181)'
    }
  }

  changePW = () => {
    if (this.state.oldPass === '') {
      this.setState({ pwStatus: this.props.t('pleaseenteryouroldpassword') })
    } else if (this.state.newPass === '') {
      this.setState({ pwStatus: this.props.t('pleaseenteryournewpassword') })
    } else if (this.state.newPassConf === '') {
      this.setState({ pwStatus: this.props.t('pleaseconfirmyournewpassword') })
    } else if (!validatePassword(this.state.newPass)) {
      this.setState({
        //pwStatus: this.props.t('pleaseentervalidpassword'),
        showPasswordMessage: true,
      })
    } else if (this.state.newPass !== this.state.newPassConf) {
      this.setState({
        pwStatus: this.props.t('newpasswordandconfirmdoesnotmatch'),
      })
    } else if (this.state.newPass === this.state.oldPass) {
      this.setState({
        pwStatus: this.props.t('oldpasswordcannotbethesameasnewpassword'),
      })
    } else {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(
            user,
            this.state.oldPass,
            this.state.newPass
          )
        })
        .then((data) => {
          if (data === 'SUCCESS') {
            this.setState({
              pwStatus: this.props.t('passwordupdatedsuccessfully'),
            })
          } else {
            this.setState({ pwStatus: data })
          }
        })
        .catch((err) => {
          console.log('changepw error', err)
          if (
            err.message.includes('previousPassword') ||
            err.message.includes('Incorrect username or password.')
          ) {
            this.setState({
              pwStatus: this.props.t('youroldpasswordisincorrect'),
            })
          } else {
            this.setState({ pwStatus: err.message })
          }
          // else if(){
          //     this.setState({pwStatus:'You have used that password previously, please try something new'})
          // }
          // this.setState({pwStatus:err}))
        })
    }
  }

  getKycStatus = () => {
    let hasDw = false
    for (let i = 0; i < this.props.accounts.length; i++) {
      if (this.props.accounts[i].inst === 'DriveWealth') {
        hasDw = true
        i += this.props.accounts.length
      }
    }
    if (hasDw) {
      fetch(`${ENDPOINT}/mobile/dw/kyc`, {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      })
        .catch((err) => {
          console.log(err)
        })
        .then((res) => res.json())
        .then((responseJson) => {
          // console.log('kyc status resp', responseJson)
          if (
            responseJson.payload.status &&
            responseJson.payload.status.name === 'APPROVED'
          ) {
            this.setState({ kycNecessary: false })
          } else {
            this.setState({ kycNecessary: true })
          }
        })
    }
  }
  createDropdownDocType = () => {
    let list = {}
    if (this.state.presets) {
      list = this.state.presets.document_types_v2
    }
    let mapped = []
    list.forEach((option) => {
      let title = Object.keys(option)[0]
      mapped.push(
        <option
          value={option.name}
          selected={this.state.docType == option.name}
        >
          {option.description}
        </option>
      )
    })
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 7,
          paddingBottom: 7,
          background: 'rgb(225,238,238)',
          borderRadius: 5 /*width:'40%'*/,
        }}
      >
        <select
          placeholder='Select Below'
          onChange={(e) => this.setState({ docType: e.target.value })}
          value={this.state.docType}
          style={{
            border: 'none',
            textAlign: 'right',
            margin: 0,
            color: 'rgb(52,131,129)',
            outline: 'none',
            background: 'transparent',
          }}
        >
          {mapped}
        </select>
      </div>
    )
  }

  getKycStatus = () => {
    let hasDw = false
    for (let i = 0; i < this.props.accounts?.length; i++) {
      if (this.props.accounts[i].inst === 'DriveWealth') {
        hasDw = true
        i += this.props.accounts.length
      }
    }
    if (hasDw) {
      fetch(`${ENDPOINT}/mobile/dw/kyc`, {
        headers: {
          Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
          'Content-Type': 'application/json',
        },
      })
        .catch((err) => {
          console.log(err)
        })
        .then((res) => res?.json())
        .then((responseJson) => {
          // console.log('kyc status resp', responseJson)
          if (
            responseJson.payload.status &&
            responseJson.payload.status.name === 'APPROVED'
          ) {
            this.setState({ kycNecessary: false })
          } else {
            this.setState({ kycNecessary: true })
          }
        })
    }
  }

  uploadImage = (front) => {
    // let body = {
    //     "userID":dwUserID,
    //     "type": this.state.docType,
    //     "documentImage":image,
    //     "side":front?'FRONT':'BACK'
    // }
    let dwUserId = false
    for (let i = 0; i < this.props.accounts.length; i++) {
      if (this.props.accounts[i].inst === 'DriveWealth') {
        dwUserId = this.props.accounts[i].itemId
        i += this.props.accounts.length
      }
    }
    console.log('[INFO] Do KYC document upload')
    let prodid = dwUserId
    // let testId = "193e233f-22ec-401e-a938-35927974f84f";
    let url = ENDPOINT + '/user/kyc-doc'
    // let testurl = "http://arjavaapi-env-test.us-east-1.elasticbeanstalk.com/drivewealth/user/kyc-doc";
    let produrl = `${ENDPOINT}/drivewealth/user/kyc-doc`
    // let proddriecturl = "http://arjavaapi-env.eba-pppapp25.us-east-1.elasticbeanstalk.com/drivewealth/user/kyc-doc";
    // let devurl = "https://dev-api.allocaterite.com/drivewealth/user/kyc-doc";
    let doc = front ? this.state.docFront : this.state.docBack
    let formData = new FormData()
    formData.append('userID', dwUserId)
    formData.append('documentType', this.state.docType)
    formData.append('documentImage', doc)
    formData.append('side', front ? 'FRONT' : 'BACK')
    fetch(produrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
      body: formData,
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        console.log('file upload resp: ', data)
        if (
          this.state.docType === 'PASSPORT' ||
          (this.state.docType === 'VISA' && !front)
        ) {
          this.setState({ uploadImageStatus: 'Document Uploaded' })
        } else if (
          this.state.docType !== 'PASSPORT' &&
          this.state.docType !== 'VISA'
        ) {
          this.setState({ uploadImageStatus: 'Document Uploaded' })
        }
      })
      .catch((err) => {
        this.setState({
          uploadImageStatus: 'Something went wrong please try again later',
        })
        console.log('Upload Failed', err)
      })
  }

  kycModal = () => {
    return (
      <div
        style={{
          backgroundColor: 'rgba(0,0,0,0.5)',
          position: 'fixed',
          height: '100%',
          width: '100%',
          top: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'auto',
          zIndex: 99999,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            backgroundColor: 'rgb(255,255,255)',
            borderRadius: 10,
            width: this.props.isSmallScreen ? '90%' : '40%',
            paddingBottom: 25,
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: 'rgb(37,42,55)',
              paddingLeft: 20,
              paddingRight: 20,
              alignItems: 'center',
              paddingTop: 10,
              paddingBottom: 10,
              borderRadius: '10px 10px 0px 0px',
            }}
          >
            <p
              style={{
                color: 'rgb(255,255,255)',
                fontWeight: 'bold',
                fontSize: 20,
                margin: 0,
              }}
            >
              Document Upload
            </p>
            <p
              style={{
                margin: 0,
                fontWeight: 'bold',
                color: 'rgb(255,255,255)',
                cursor: 'pointer',
              }}
              onClick={() => this.setState({ kyc: false })}
            >
              {' '}
              X{' '}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexDirection: 'column',
              margin: 'auto',
              marginTop: 30,
              width: '95%',
            }}
          >
            <p style={{ fontSize: 20, color: '' }}>
              To get your DriveWealth account approved please select a document
              type document below.
            </p>
            {this.createDropdownDocType()}
            <div style={{ width: '95%', margin: 'auto' }}>
              <p style={{ fontSize: 20, color: '', marginTop: 10 }}>
                Then upload the necessary image
                {this.state.docType === 'PASSPORT' ||
                this.state.docType === 'VISA'
                  ? ''
                  : 's'}
                .
              </p>
              <p style={{ fontSize: 20, color: '' }}>
                All files must be either .pdf .jpg or .png
              </p>
              {this.state.docType === 'PASSPORT' ||
              this.state.docType === 'VISA' ? (
                <div>
                  <p
                    style={{
                      margin: 0,
                      marginLeft: 10,
                      marginTop: 10,
                      fontSize: 20,
                    }}
                  >
                    Front of Document
                  </p>
                  <input
                    type='file'
                    id='img'
                    name='img'
                    accept='image/*'
                    onChange={(event) => {
                      this.setState(
                        {
                          docFront: event.target.files[0],
                        },
                        () => {
                          console.log(this.state.docFront)
                        }
                      )
                    }}
                  />
                </div>
              ) : (
                <div style={{}}>
                  <p
                    style={{
                      margin: 0,
                      marginLeft: 10,
                      marginTop: 10,
                      fontSize: 20,
                    }}
                  >
                    Front of Document
                  </p>
                  <input
                    type='file'
                    id='img'
                    name='img'
                    accept='image/*'
                    onChange={(event) => {
                      this.setState(
                        {
                          docFront: event.target.files[0],
                        },
                        () => {
                          console.log(this.state.docFront)
                        }
                      )
                    }}
                  />
                  <p
                    style={{
                      margin: 0,
                      marginLeft: 10,
                      marginTop: 10,
                      fontSize: 20,
                    }}
                  >
                    Back of Document
                  </p>
                  <input
                    type='file'
                    id='img'
                    name='img'
                    accept='image/*'
                    onChange={(event) => {
                      this.setState(
                        {
                          docBack: event.target.files[0],
                        },
                        () => {
                          console.log(this.state.docFront)
                        }
                      )
                    }}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div
                style={{
                  backgroundColor: GREEN,
                  borderRadius: 10,
                  padding: 20,
                  marginTop: 10,
                  marginBottom: 20,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  if (
                    this.state.docType === 'PASSPORT' ||
                    this.state.docType === 'VISA'
                  ) {
                    this.uploadImage(true)
                  } else {
                    this.uploadImage(true)
                    this.uploadImage(false)
                  }
                }}
              >
                <p style={{ margin: 0, color: 'rgb(255,255,255)' }}>
                  Upload Files
                </p>
              </div>
            </div>
            {this.state.uploadImageStatus ? (
              <div
                style={{
                  display: 'flex',
                  margin: 'auto',
                  width: '60%',
                  backgroundColor: this.successErrorLoadingBackground(),
                  padding: 12,
                  marginBottom: 10,
                  borderRadius: 8,
                }}
              >
                <p style={{ margin: 'auto' }}>{this.state.uploadImageStatus}</p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  subModal = () => {
    if (this.state.showSub) {
      return (
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            position: 'fixed',
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'auto',
            zIndex: 99999,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: 'auto',
              backgroundColor: 'rgb(255,255,255)',
              borderRadius: 10,
              width: this.props.isSmallScreen ? '90%' : '40%',
              height: '95%',
              paddingBottom: 25,
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'rgb(37,42,55)',
                paddingLeft: 20,
                paddingRight: 20,
                alignItems: 'center',
                paddingTop: 10,
                paddingBottom: 10,
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <p
                style={{
                  color: 'rgb(255,255,255)',
                  fontWeight: 'bold',
                  fontSize: 20,
                  margin: 0,
                }}
              >
                {' '}
                Subscribe
              </p>
              <p
                style={{
                  margin: 0,
                  fontWeight: 'bold',
                  color: 'rgb(255,255,255)',
                  cursor: 'pointer',
                }}
                onClick={() => this.setState({ showSub: false })}
              >
                {' '}
                X{' '}
              </p>
            </div>
            <div style={{ width: '100%' }}>
              <SubModal
                updatePaymentStatus={this.upgrade}
                modal={true}
                user={this.props.user}
                subStatusMessage={this.props.subStatusMessage}
                subStatusExpirationDate={this.props.subStatusExpirationDate}
                t={this.props.t}
                getAllAccountInfo={this.props.getAllAccountInfo}
              />
            </div>
          </div>
        </div>
      )
    }
  }

  upgrade = () => {
    // this.props.updatePaymentStatus()
    this.setState({ showSub: false, subChange: 'Subcription Upgraded' })
  }

  downgrade = () => {
    return (
      <div
        style={{
          backgroundColor: 'rgba(0,0,0,0.5)',
          position: 'fixed',
          height: '100%',
          width: '100%',
          top: 0,
          left: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'auto',
          zIndex: 99999,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            backgroundColor: 'rgb(255,255,255)',
            borderRadius: 10,
            width: this.props.isSmallScreen ? '90%' : '40%',
            paddingBottom: 25,
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: 'rgb(37,42,55)',
              paddingLeft: 20,
              paddingRight: 20,
              alignItems: 'center',
              paddingTop: 10,
              paddingBottom: 10,
              borderRadius: '10px 10px 0px 0px',
            }}
          >
            <p
              style={{
                color: 'rgb(255,255,255)',
                fontWeight: 'bold',
                fontSize: 20,
                margin: 0,
              }}
            >
              Subscription Downgrade
            </p>
            <p
              style={{
                margin: 0,
                fontWeight: 'bold',
                color: 'rgb(255,255,255)',
                cursor: 'pointer',
              }}
              onClick={() => this.setState({ showDowngrade: false })}
            >
              {' '}
              X{' '}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginTop: 30,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <p style={{ fontSize: 20 }}>
              Are you sure you want to downgrade your account to Basic?{' '}
              <br></br> Any future subscription payments will stop.
            </p>
            {this.state.pwStatus ? (
              <div
                style={{
                  display: 'flex',
                  margin: 'auto',
                  width: '60%',
                  backgroundColor: this.successErrorLoadingBackground(),
                  padding: 12,
                  marginBottom: 10,
                  borderRadius: 8,
                }}
              >
                <p style={{ margin: 'auto' }}>{this.state.pwStatus}</p>
              </div>
            ) : null}
            {this.state.userDetails.accountSub.source === 'apple' ? null : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    backgroundColor: 'rgb(221,103,108',
                    borderRadius: 10,
                    padding: 20,
                    marginTop: 10,
                    marginBottom: 20,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    this.downgradeSub()
                    // this.setState({showDowngrade:false})
                  }}
                >
                  <p style={{ margin: 0, color: 'rgb(255,255,255)' }}>
                    Downgrade
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  downgradeSub = () => {
    return fetch(`${ENDPOINT}/stripe/cards`, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        console.log('downgrade resp', responseJson)
        if (responseJson.status) {
          // this.props.getSubStatus()
          // this.props.downgradePaymentStatus()
          // this.setState({
          //   showDowngrade: false,
          //   subChange: 'Subscription Downgraded',
          // })
          this.setState({ showErrorDialog: true })
          return true
        } else {
          this.setState({ pwStatus: 'Something went wrong' })
          return false
        }
      })
      .catch((err) => {
        console.log(`catching errors`, err)
        return false
      })
  }

  selectDocuments = (index) => {
    this.setState({ accountSelected: index })
    this.getStatements(this.state.allAccounts[index].accountId)
  }

  getLink = (fileId) => {
    let url = `${ENDPOINT}/mobile/dw/documents/link/${
      this.state.allAccounts[this.state.accountSelected].accountId
    }/${fileId}`
    fetch(url, {
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        console.log('tax docs link: ', responseJson)
        // this.setState({presetTickers: responseJson.lists})
        window.open(responseJson.payload.url, '_blank')
      })
  }

  showAll = () => {
    let mappedConf = []
    console.log('cfs:', this.state.confirmations)
    this.state.confirmations.forEach((ele) => {
      mappedConf.push(
        <div>
          <a href='#' onClick={() => this.getLink(ele.fileKey)}>
            {ele.displayName}
          </a>
        </div>
      )
    })
    console.log('sts:', this.state.statements)
    let mappedStatments = []
    this.state.statements.forEach((ele) => {
      mappedStatments.push(
        <div>
          <a href='#' onClick={() => this.getLink(ele.fileKey)}>
            {ele.displayName}
          </a>
        </div>
      )
    })
    console.log('tfs:', this.state.tax_forms)
    let mappedTf = []
    this.state.tax_forms.forEach((ele) => {
      mappedTf.push(
        <div>
          <a href='#' onClick={() => this.getLink(ele.fileKey)}>
            {ele.displayName}
          </a>
        </div>
      )
    })
    return (
      <div>
        <div>
          <p style={{ fontSize: 'larger', fontWeight: 'bold' }}>Tax Forms</p>
        </div>
        <div>
          {mappedTf.length === 0 ? (
            <p>You have no Tax Forms Available</p>
          ) : (
            mappedTf
          )}
        </div>
        <div>
          <p style={{ fontSize: 'larger', fontWeight: 'bold' }}>Statements</p>
        </div>
        <div>
          {mappedStatments.length === 0 ? (
            <p>You have no Statements Available</p>
          ) : (
            mappedStatments
          )}
        </div>
        <div>
          <p style={{ fontSize: 'larger', fontWeight: 'bold' }}>
            Confirmations
          </p>
        </div>
        <div>
          {mappedConf.length === 0 ? (
            <p>You have no Confirmations Available</p>
          ) : (
            mappedConf
          )}
        </div>
      </div>
    )
  }

  renderDifferentTabs = () => {
    switch (this.state.accountTab) {
      case 'link':
        return (
          <>
            <div className='link-accounts-container'>
              <div className='top-container'>
                <div className='toggle-container'>
                  <div
                    onClick={() => this.setState({ bankingTab: true })}
                    className={this.state.bankingTab ? 'selected' : null}
                    style={{ cursor: 'pointer' }}
                  >
                    {this.props.t('banking')}
                  </div>
                  <div
                    onClick={() => this.setState({ bankingTab: false })}
                    className={this.state.bankingTab ? null : 'selected'}
                    style={{ cursor: 'pointer' }}
                  >
                    {this.props.t('investment')}
                  </div>{' '}
                </div>
              </div>
              {this.state.bankingTab ? (
                <div className='linked-accounts'>
                  {this.props.accounts
                    .filter((account) => {
                      if (
                        account.type === 'depository' ||
                        account.type === 'credit'
                      ) {
                        return account
                      }
                    })
                    .map((account) => {
                      return (
                        <div className='account-block' key={account.maskt}>
                          <p>
                            {account.inst} {account.mask}
                          </p>
                          {account.reconnect_plaid === 1 ? (
                            <div>
                              <LinkPlaidBtn
                                user={this.props.user}
                                getAllAccountInfo={this.props.getAllAccountInfo}
                                isBank={true}
                                showButton={true}
                                reLink={true}
                                t={this.props.t}
                              />
                            </div>
                          ) : (
                            <img
                              src={RemoveAccountIcon}
                              width={25}
                              height={25}
                              className='remove-account-icon'
                              onClick={() => {
                                this.removeAccount(account)
                              }}
                            />
                          )}
                        </div>
                      )
                    })}
                  <div className='account-block'>
                    <p>{this.props.t('linkabankaccount')}</p>
                    <LinkPlaidBtn
                      user={this.props.user}
                      getAllAccountInfo={this.props.getAllAccountInfo}
                      isBank={true}
                      showButton={false}
                      reLink={false}
                      t={this.props.t}
                    />
                  </div>
                </div>
              ) : (
                <div className='linked-accounts'>
                  {this.props.accounts
                    .filter((account) => {
                      if (account.type === 'investment') {
                        if (
                          account.inst !== 'DriveWealth' &&
                          account.inst !== 'Apex'
                        )
                          return account
                      }
                    })
                    .map((account) => {
                      return (
                        <div className='account-block' key={account.mask}>
                          <p>
                            {account.inst} {account.mask}
                          </p>
                          {account.reconnect_plaid === 1 ? (
                            <div>
                              <LinkPlaidBtn
                                user={this.props.user}
                                getAllAccountInfo={this.props.getAllAccountInfo}
                                isBank={true}
                                showButton={true}
                                reLink={true}
                                t={this.props.t}
                              />
                            </div>
                          ) : (
                            <img
                              src={RemoveAccountIcon}
                              width={25}
                              height={25}
                              className='remove-account-icon'
                              onClick={() => {
                                this.removeAccount(account)
                              }}
                            />
                          )}
                        </div>
                      )
                    })}
                  <div className='account-block'>
                    <p>{this.props.t('linkainvestmentaccount')}</p>
                    <LinkPlaidBtn
                      user={this.props.user}
                      getAllAccountInfo={this.props.getAllAccountInfo}
                      isBank={false}
                      showButton={false}
                      isRelink={false}
                      t={this.props.t}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )
      case 'manage':
        return (
          <>
            <div className='link-list-container'>
              {this.props.userData?.country === 'USA' && (
                <div
                  className='link-container'
                  onClick={() => this.setState({ showSub: true })}
                  style={{ cursor: 'pointer' }}
                >
                  <p>{this.props.t('subscriptionmanage')}</p>
                </div>
              )}
              {/* <div
                className='link-container'
                onClick={() => this.setState({ showSub: true })}
                style={{ cursor: 'pointer' }}
              >
                <p>{this.props.t('subscriptionmanage')}</p>
              </div> */}
              <div
                // id='UpdateQuestionnaire_Button'
                // to='/Questionnaire'
                // exact={true}
                className='link-container'
                onClick={() => {
                  this.setState({ showExtendedHours: true })
                }}
                style={{ cursor: 'pointer' }}
              >
                <p>{this.props.t('extended_hours_trading_for_stock')}</p>
              </div>
              <div
                // id='UpdateQuestionnaire_Button'
                // to='/Questionnaire'
                // exact={true}
                className='link-container'
                onClick={() => {
                  this.setState({ showFinraQuestionaire: true })
                }}
                style={{ cursor: 'pointer' }}
              >
                <p>{this.props.t('investmentquestionnaireupdate')}</p>
              </div>
              {/* <div
                className='link-container'
                onClick={() => {
                  this.setState({ showUpdateDwApexQuestionaire: true })
                }}
                style={{ cursor: 'pointer' }}
              >
                <p>DriveWealth Questionnaire - UPDATE</p>
              </div> */}
              <div
                className='link-container'
                onClick={() => this.setState({ showChangePw: true })}
                style={{ cursor: 'pointer' }}
              >
                <p>{this.props.t('changepassword')}</p>
              </div>
              <div
                className='link-container'
                onClick={() => this.setState({ showDwKYC: true })}
                style={{ cursor: 'pointer' }}
              >
                <p>
                  {this.props.t('userverification')}/ {this.props.t('stocks')}
                </p>
              </div>
              <div
                className='link-container'
                onClick={() => this.setState({ showApexKYC: true })}
                style={{ cursor: 'pointer' }}
              >
                <p>
                  {this.props.t('userverification')}/ {this.props.t('crypto')}
                </p>
              </div>
              <NavLink exact={true} to='/Support' className='link-container'>
                {this.props.t('faqs')}
              </NavLink>
            </div>
          </>
        )
      case 'faq':
        return (
          <>
            {/* <iframe src={'https://allocaterite.zendesk.com/hc/en-us'} /> */}
            {/* <Iframe
              url='https://allocaterite.zendesk.com/hc/en-us'
              width='450px'
              height='450px'
              id='myId'
              className='myClassname'
              display='initial'
              position='relative'
            /> */}
            <Link to={'/zendesk'} target='_blank'>
              <div className='faq-link'>
                {this.props.t('clickhereforfrequentlyaskedquestions')}
              </div>
            </Link>
          </>
        )
      case 'support':
        return <></>
      case 'notifications':
        return <></>
      case 'legal':
        return (
          <>
            <div className='link-list-container'>
              <a
                className='link-container'
                href='/Legal/ForeSCITE-Privacy-Policy.pdf'
                target='_blank'
              >
                <p>{this.props.t('privacypolicy')}</p>
              </a>
              <a
                className='link-container'
                href='/Legal/Terms-of-use.pdf'
                target='_blank'
              >
                <p>{this.props.t('termsofuse')}</p>
              </a>
              <a
                href='/Legal/EULA.pdf'
                className='link-container'
                target='_blank'
              >
                {this.props.t('eula')}
              </a>
              <a
                className='link-container'
                href='/Legal/General-Disclaimer.pdf'
                target='_blank'
              >
                <p>{this.props.t('generaldisclaimer')}</p>
              </a>
              <a
                className='link-container'
                href='/Legal/ForeSCITE-Investment-Advisory-Agreement.pdf'
                target='_blank'
                rel='noopener noreferrer'
              >
                <p>{this.props.t('advisoryagreement')}</p>
              </a>
              <NavLink className='link-container' to='/Disclosures'>
                <p>{this.props.t('disclosures')}</p>
              </NavLink>
            </div>
          </>
        )
      case 'promotions':
        return <Promotions {...this.props} />
      default:
        return (
          <>
            <div className='personal-info-row'>
              <p className='personal-info-label'>{this.props.t('name')}:</p>
              <p className='personal-info-value'>
                {this.state.userDetails2.firstName}{' '}
                {this.state.userDetails2.lastName}
              </p>
            </div>
            <div className='personal-info-row'>
              {' '}
              <p className='personal-info-label'>{this.props.t('email')}:</p>
              <p className='personal-info-value'>
                {this.state.userDetails.email}
              </p>
            </div>
            <div className='personal-info-row'>
              <p className='personal-info-label'>{this.props.t('phone')}: </p>
              <p className='personal-info-value'>
                {this.state.userDetails2.phone
                  ? this.state.userDetails2.phone
                  : 'N/A'}
              </p>
            </div>
            <div className='personal-info-row'>
              <p className='personal-info-label'>
                {this.props.t('goodfaithviolation')}:{' '}
              </p>
              <p className='personal-info-value'>{this.state.gfv}</p>
            </div>
          </>
        )
    }
  }

  render() {
    // let user = this.state.userInfo
    const customSelectedTab = { ...selectedTab, minWidth: 170 }
    const customUnselectedTab = { ...unSelectedTab, minWidth: 170 }
    if (this.props.t) {
      return (
        <div className='account-main-container'>
          <div
            style={{
              width: '100',
              margin: 'auto',
              marginBottom: 10,
              marginTop: 10,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: this.props.isSmallScreen ? 'column' : 'row',
            }}
          >
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            ></div>
            <h3
              style={{
                color: 'rgb(17,17,17)',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              {this.props.t('accountinformation')}
            </h3>

            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  color: 'rgb(17,17,17)',
                  cursor: 'pointer',
                  textAlign: 'right',
                  textDecoration: 'none',
                }}
                onClick={() => this.props.signOut()}
              >
                <p
                  style={{
                    textAlign: 'right',
                    color: 'rgb(17,17,17)',
                    margin: 0,
                    fontFamily: 'Akkurat-Bold',
                  }}
                >
                  {this.props.t('signout')}
                </p>
              </div>
            </div>
          </div>
          <div className='account-info-container'>
            <div className='account-wrapper'>
              <div className='account-side-bar'>
                <div
                  // className={
                  //   this.state.accountTab === 'personal'
                  //     ? 'bar selected'
                  //     : 'bar'
                  // }
                  style={
                    this.state.accountTab === 'personal'
                      ? customSelectedTab
                      : customUnselectedTab
                  }
                  onClick={() => this.setState({ accountTab: 'personal' })}
                >
                  <p
                    style={
                      this.state.accountTab === 'personal'
                        ? selectedText
                        : unSelectedText
                    }
                  >
                    {this.props.t('personalinformation')}
                  </p>
                </div>
                {this.props.userType === 'normal' && (
                  <div
                    // className={
                    //   this.state.accountTab === 'link' ? 'bar selected' : 'bar'
                    // }
                    style={
                      this.state.accountTab === 'link'
                        ? customSelectedTab
                        : customUnselectedTab
                    }
                    onClick={() => this.setState({ accountTab: 'link' })}
                  >
                    <p
                      style={
                        this.state.accountTab === 'link'
                          ? selectedText
                          : unSelectedText
                      }
                    >
                      {this.props.t('linkedaccounts')}
                    </p>
                  </div>
                )}
                <div
                  // className={
                  //   this.state.accountTab === 'manage' ? 'bar selected' : 'bar'
                  // }
                  style={
                    this.state.accountTab === 'manage'
                      ? customSelectedTab
                      : customUnselectedTab
                  }
                  onClick={() => this.setState({ accountTab: 'manage' })}
                >
                  <p
                    style={
                      this.state.accountTab === 'manage'
                        ? selectedText
                        : unSelectedText
                    }
                  >
                    {this.props.t('manage')}
                  </p>
                </div>
                <div
                  style={
                    this.state.accountTab === 'legal'
                      ? customSelectedTab
                      : customUnselectedTab
                  }
                  onClick={() => this.setState({ accountTab: 'legal' })}
                >
                  <p
                    style={
                      this.state.accountTab === 'legal'
                        ? selectedText
                        : unSelectedText
                    }
                  >
                    {this.props.t('legal')}
                  </p>
                </div>
                <div
                  style={
                    this.state.accountTab === 'promotions'
                      ? customSelectedTab
                      : customUnselectedTab
                  }
                  onClick={() => this.setState({ accountTab: 'promotions' })}
                >
                  <p
                    style={
                      this.state.accountTab === 'promotions'
                        ? selectedText
                        : unSelectedText
                    }
                  >
                    {this.props.t('promotions')}
                  </p>
                </div>
              </div>
              <div className='account-main-section'>
                {this.state.loading ? (
                  <div className='loading-spinner'>
                    <Loader logoLoader={false} />
                  </div>
                ) : (
                  this.renderDifferentTabs()
                )}
              </div>
            </div>
          </div>
          {this.state.acc ? (
            <AccountModal
              activeTab={this.state.activeTab}
              acc={this.state.acc}
              closeModal={() => this.setState({ acc: false })}
            />
          ) : null}
          {this.state.youSure ? this.sureCheck() : null}
          {this.state.kyc ? this.kycModal() : null}
          {this.changePwModal()}
          {this.state.showDowngrade ? this.downgrade() : null}
          {/* <ConfirmDialog
            visible={this.state.showRemoveAccountDialog}
            onHide={() => {
              this.setState({ selectedAccountToRemove: null })
              this.toggleRemoveAccountDialog()
            }}
            message={this.props.t(
              'Are you sure you want to remove this account'
            )}
            header={this.props.t('confirmation')}
            style={{ width: this.props.isSmallScreen ? '80vw' : '30vw' }}
            accept={this.removeAccountAPI}
            // reject={reject}
          /> */}
          <ConfirmationDialog
            isSmallScreen={this.props.isSmallScreen}
            toggleShowConfirmationDialog={this.toggleRemoveAccountDialog}
            showConfirmationDialog={this.state.showRemoveAccountDialog}
            action={this.removeAccountAPI}
            name='Remove Account'
            successMessage='Account removed successfully'
            customHeader='Remove Account'
            customMessage='This will remove all other sub accounts associated with this account. Do you want to remove this account?'
            t={this.props.t}
          />
          <Dialog
            visible={this.state.showSub}
            header={
              <p style={{ fontFamily: 'Akkurat-Bold' }}>
                {this.props.t('yoursubscription')}
              </p>
            }
            onHide={() => this.setState({ showSub: false })}
            style={{ width: this.props.isSmallScreen ? '90vw' : '50vw' }}
          >
            <SubModal
              updatePaymentStatus={this.upgrade}
              downgradeSub={this.downgradeSub}
              subStatus={this.props.subStatus}
              getSubStatus={this.props.getSubStatus}
              modal={true}
              user={this.props.user}
              userEmail={this.props.userEmail}
              subStatusMessage={this.props.subStatusMessage}
              subStatusExpirationDate={this.props.subStatusExpirationDate}
              appleStatus={this.props.appleStatus}
              freeTrialUsed={
                this.state.userDetails?.accountSub &&
                this.state.userDetails?.accountSub?.trialUsed
              }
              getAllAccountInfo={this.props.getAllAccountInfo}
              t={this.props.t}
            />
          </Dialog>
          <ConfirmDialog
            visible={this.state.showErrorDialog}
            onHide={() => window.location.reload(false)}
            message={this.props.t(
              'youhavebeensuccessfullyunsubscribedtoinvetallpro'
            )}
            header={this.props.t('successfullyunsubscribed')}
            style={{ width: this.props.isSmallScreen ? '80vw' : '30vw' }}
            rejectClassName='rejectButton'
            acceptLabel='OK'
          />
          <Dialog
            header={
              <div className='unremoveable-header-container'>
                <p className='unremoveable-header'>
                  {this.props.t('investmentquestionnaire')}
                </p>
              </div>
            }
            visible={this.state.showFinraQuestionaire}
            style={{ width: this.props.isSmallScreen ? '90vw' : '50vw' }}
            onHide={() => {
              this.setState({ showFinraQuestionaire: false })
            }}
          >
            <FinraQuestionnaire
              user={this.props.user}
              action={this.props.getAllAccountInfoV2}
              t={this.props.t}
            />
          </Dialog>
          <Dialog
            visible={this.state.showDwKYC}
            header={
              <p style={{ marginLeft: '40px' }}>
                {this.props.t('kycverification')}
              </p>
            }
            onHide={() => this.setState({ showDwKYC: false })}
            style={{ width: this.props.isSmallScreen ? '90vw' : '30vw' }}
          >
            <DwKYC
              user={this.props.user}
              getAllAccountInfoV2={this.props.getAllAccountInfoV2}
              dwKYCstatus={this.props.dwKYCstatus}
              dwKYCid={this.props.dwKYCid}
              t={this.props.t}
            />
          </Dialog>
          <Dialog
            header={
              <div className='unremoveable-header-container'>
                <p className='unremoveable-header'>
                  {this.props.t('extended_hours_trading_for_stock')}
                </p>
                <p
                  onClick={() => {
                    this.setState({ showExtendedHours: false })
                  }}
                  className='unremoveable-signout'
                >
                  X
                </p>
              </div>
            }
            visible={this.state.showExtendedHours}
            style={{ width: '50vw' }}
            closeOnEscape={false}
            closable={false}
          >
            <div className='extended-hours-agreement-container'>
              {this.props.dwExtendedHours === 0 ? (
                <p>{this.props.t('enable_extended_trading_description')}</p>
              ) : (
                <p>{this.props.t('disable_extended_trading_description')}</p>
              )}
              {this.props.dwExtendedHours === 0 && (
                <div className='agreement-row'>
                  <Checkbox
                    onChange={(e) => {
                      this.setState({
                        extendedHoursAgreement:
                          !this.state.extendedHoursAgreement,
                      })
                    }}
                    checked={this.state.extendedHoursAgreement}
                  ></Checkbox>
                  <p className='agreement-label'>
                    {this.props.t('i_agree_to_the')}{' '}
                    <a
                      href='https://legal.drivewealth.com/extended-hours-trading-disclosures'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {this.props.t(
                        'drivewealth_extended_hours_trading_disclosures'
                      )}
                    </a>
                  </p>
                </div>
              )}
              <div className='button-container'>
                {this.props.dwExtendedHours === 0 ? (
                  <Button
                    label={this.props.t('enable')}
                    className='dw-apex-submit-btn'
                    disabled={!this.state.extendedHoursAgreement}
                    loading={this.state.setExtendedLoading}
                    onClick={() => this.turnOnExternalHours()}
                  />
                ) : (
                  <Button
                    label={this.props.t('disable_for_now')}
                    className='dw-apex-submit-btn'
                    loading={this.state.setExtendedLoading}
                    onClick={() => this.turnOffExternalHours()}
                  />
                )}
              </div>
            </div>
          </Dialog>
          <Dialog
            visible={this.state.showApexKYC}
            header={
              <p style={{ marginLeft: '40px' }}>
                {this.props.t('kycverification')}
              </p>
            }
            onHide={() => this.setState({ showApexKYC: false })}
            style={{ width: this.props.isSmallScreen ? '90vw' : '30vw' }}
          >
            <ApexKYC
              user={this.props.user}
              getAllAccountInfoV2={this.props.getAllAccountInfoV2}
              apexKYCunapprovedIds={this.props.apexKYCunapprovedIds}
              t={this.props.t}
            />
          </Dialog>
        </div>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  }
}

export default connect(mapStateToProps, null)(Account)
