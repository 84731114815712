import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import NewtDownloadButton from '../../buttons/NewtDownloadButton'
import { MdOutlineFileDownload } from 'react-icons/md'
import ContainerButton from '../../buttons/ContainerButton'

export default function TableContent(props) {
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10

  const result = props?.content_data?.data

  const prepareHeaders = (data) => {
    if (!data?.length) {
      return { headers: [], linkColumns: {} }
    }

    const firstRow = data[0]
    let headers = []
    let linkColumns = {}
    let hasPriceHeaders = false

    Object.keys(firstRow).forEach((key) => {
      if (key.endsWith('_link')) {
        const actualKey = key.slice(0, -5)
        linkColumns[actualKey] = key
      } else {
        headers.push(key)
      }
    })

    const lowerCaseHeaders = headers.map((header) => header.toLowerCase())

    if (
      lowerCaseHeaders.includes('current price') &&
      lowerCaseHeaders.includes('price change') &&
      lowerCaseHeaders.includes('price percent change')
    ) {
      hasPriceHeaders = true
      headers = headers.filter(
        (header) =>
          header.toLowerCase() !== 'price change' &&
          header.toLowerCase() !== 'price percent change'
      )
    }

    return { headers, linkColumns, hasPriceHeaders }
  }

  const getTriangle = (priceChange) => {
    return priceChange > 0 ? '\u25B2' : priceChange < 0 ? '\u25BC' : ''
  }

  const getClassNameColor = (value) => {
    if (value === '-') {
      return 'price-neutral'
    } else if (value?.[0] === '-') {
      return 'price-down'
    } else {
      return 'price-up'
    }
  }

  const { headers, linkColumns, hasPriceHeaders } = prepareHeaders(result)

  return (
    <div className='newt-table-container'>
      <div className='table-container'>
        <table className='table'>
          <thead>
            <tr className='table-head'>
              {headers?.map((col, j) => (
                <th
                  key={j}
                  className={`table-header-cell ${
                    col === 'Symbol' ? 'symbol-column' : ''
                  }`}
                >
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='table-body'>
            {result
              ?.slice(
                (currentPage - 1) * itemsPerPage,
                currentPage * itemsPerPage
              )
              ?.map((row, i) => (
                <tr key={i} className='table-row'>
                  {headers.map((col, k) => (
                    <td key={k} className='table-cell'>
                      {hasPriceHeaders && col === 'Current Price' ? (
                        <div className='price-cell'>
                          <span
                            className={`price ${
                              row['Current Price'] === '-'
                                ? 'price-neutral'
                                : ''
                            }`}
                          >
                            {row['Current Price'] ?? '--'}{' '}
                          </span>
                          <span
                            className={`${getClassNameColor(
                              row['Price Change']
                            )} flex`}
                          >
                            {getTriangle(row['Price Percent Change'])}{' '}
                            {row['Price Change'] ?? '--'}
                          </span>
                          <span
                            className={`${getClassNameColor(
                              row['Price Percent Change']
                            )}`}
                          >
                            ({row['Price Percent Change'] ?? '--'}%)
                          </span>
                        </div>
                      ) : linkColumns[col] ? (
                        <Link to={row[linkColumns[col]]} className='link'>
                          {row[col]}
                        </Link>
                      ) : (
                        row[col]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {result?.length > 0 && (
        <div className='action-buttons-container'>
          {(props.content_data?.news_prompt ||
            props.content_data?.earnings_summary_prompt ||
            props.content_data?.market_sentiment_prompt) && (
            <div className='prompt-buttons'>
              {props.content_data?.market_sentiment_prompt && (
                <ContainerButton
                  onClick={async () =>
                    await props?.sendPrompt(
                      props.content_data?.market_sentiment_prompt
                    )
                  }
                >
                  Market Sentiment
                </ContainerButton>
              )}

              {props.content_data?.earnings_summary_prompt && (
                <ContainerButton
                  onClick={async () =>
                    await props?.sendPrompt(
                      props.content_data?.earnings_summary_prompt
                    )
                  }
                >
                  Earnings Call Summary
                </ContainerButton>
              )}

              {props.content_data?.news_prompt && (
                <ContainerButton
                  onClick={async () =>
                    await props?.sendPrompt(props.content_data?.news_prompt)
                  }
                >
                  News
                </ContainerButton>
              )}
            </div>
          )}

          {(props.content_data?.download_csv_url ||
            props.content_data?.download_excel_url) && (
            <div
              className={`button-wrapper ${
                !props.content_data?.news_prompt ? 'full-width' : ''
              }`}
            >
              {props.content_data?.download_csv_url && (
                <NewtDownloadButton
                  downloadUrl={props.content_data?.download_csv_url}
                  icon={<MdOutlineFileDownload className='icon-small' />}
                >
                  CSV
                </NewtDownloadButton>
              )}

              {props.content_data?.download_excel_url && (
                <NewtDownloadButton
                  downloadUrl={props.content_data?.download_excel_url}
                  icon={<MdOutlineFileDownload className='icon-small' />}
                >
                  Excel
                </NewtDownloadButton>
              )}

              {/* <ActionButton
                onClick={async () => await fetchPromptEmailData(data)}
                icon={<IoIosShareAlt className='icon-small' />}
              >
                Share
              </ActionButton> */}
            </div>
          )}
        </div>
      )}

      {result?.length > itemsPerPage && (
        <div className='pagination-container'>
          <Pagination
            totalItems={result?.length}
            itemsPerPage={itemsPerPage}
            onChangePage={setCurrentPage}
          />
        </div>
      )}
    </div>
  )
}

const Pagination = ({ totalItems, itemsPerPage, onChangePage }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage)
  const maxPageNumbersToShow = 5
  const [currentPagination, setCurrentPagination] = useState(1)

  const setPage = (page) => {
    setCurrentPagination(page)
    onChangePage(page)
  }

  const getPaginationGroup = () => {
    const halfWay = Math.floor(maxPageNumbersToShow / 2)
    let start, end
    if (currentPagination <= halfWay) {
      start = 1
      end = maxPageNumbersToShow
    } else if (currentPagination + halfWay >= totalPages) {
      start = totalPages - maxPageNumbersToShow + 1
      end = totalPages
    } else {
      start = currentPagination - halfWay
      end = currentPagination + halfWay
    }
    return new Array(Math.min(end, totalPages) - start + 1)
      .fill(0)
      .map((_, idx) => start + idx)
  }

  return (
    <div className='pagination'>
      <button
        onClick={() => setPage(1)}
        disabled={currentPagination === 1}
        className='pagination-button'
      >
        {'<<'}
      </button>
      <button
        onClick={() => setPage(currentPagination - 1)}
        disabled={currentPagination === 1}
        className='pagination-button'
      >
        {'<'}
      </button>
      {getPaginationGroup().map((pageNumber) => (
        <button
          key={pageNumber}
          onClick={() => setPage(pageNumber)}
          disabled={currentPagination === pageNumber}
          className={`${
            currentPagination === pageNumber ? 'pagination-button-active' : ''
          }`}
        >
          {pageNumber}
        </button>
      ))}
      <button
        onClick={() => setPage(currentPagination + 1)}
        disabled={currentPagination === totalPages}
        className='pagination-button'
      >
        {'>'}
      </button>
      <button
        onClick={() => setPage(totalPages)}
        disabled={currentPagination === totalPages}
        className='pagination-button'
      >
        {'>>'}
      </button>
    </div>
  )
}
