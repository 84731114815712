import React, { useEffect, useRef, useState } from 'react'

import '../../scss/components/budgeting/NewBudgetPage.scss'

import InsightIcon from '../../assets/newBudgetPage/insight_icon.svg'
import IncomeIcon from '../../assets/newBudgetPage/income-icon.svg'
import InvestIcon from '../../assets/newBudgetPage/invest-icon.svg'
import CharityIcon from '../../assets/newBudgetPage/charitable-icon.svg'

// dynamic spending icon
import EntertainmentIcon from '../../assets/newBudgetPage/Icon=Entertainment.svg'
import GeneralIcon from '../../assets/newBudgetPage/Icon=General_Services.svg'
import GovernmentIcon from '../../assets/newBudgetPage/Icon=Government_Non_Profit.svg'
import CarRepairIcon from '../../assets/newBudgetPage/Icon=car_repair.svg'
import DebtIcon from '../../assets/newBudgetPage/Icon=debt.svg'
import HealthIcon from '../../assets/newBudgetPage/Icon=health.svg'
import HomeRepairIcon from '../../assets/newBudgetPage/Icon=home_repair.svg'
import RentIcon from '../../assets/newBudgetPage/Icon=rent.svg'
import RestaurantIcon from '../../assets/newBudgetPage/Icon=restaurants.svg'
import SubscriptionIcon from '../../assets/newBudgetPage/Icon=subscription.svg'
import MerchandiseIcon from '../../assets/newBudgetPage/Icon_General_Merchandise.svg'
import CareIcon from '../../assets/newBudgetPage/Icon_Personal_Care.svg'
import TravelIcon from '../../assets/newBudgetPage/Icon_Travel.svg'
import ChildCareIcon from '../../assets/newBudgetPage/Icon_childcare.svg'
import InsuranceIcon from '../../assets/newBudgetPage/Icon_insurance.svg'
import MiscelaneousIcon from '../../assets/newBudgetPage/Icon_miscelaneous.svg'
import PetIcon from '../../assets/newBudgetPage/Icon_pet.svg'
import TransportationIcon from '../../assets/newBudgetPage/Icon_transportation.svg'
import PlusIcon from '../../assets/plus.svg'
import { BiSolidCategory } from 'react-icons/bi'
import {
  IoWarningOutline,
  IoChevronForwardOutline,
  IoSettings,
} from 'react-icons/io5'

import axios from 'axios'
import Endpoint from '../Endpoint'
import Loader from '../components/accountInfo/Loader'
import Balance from './Balance'
import CreditDetails from './CreditDetails'
import CreditScoreForm from './CreditScoreForm'
import AddExternalAccount from './AddExternalAccount'
import ExternalAccountsList from './ExternalAccountsList'

import { BudgetingContext } from './Budgeting'

// prime react component
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { Chart } from 'primereact/chart'
import { Paginator } from 'primereact/paginator'
import { Checkbox } from 'primereact/checkbox'
import { InputText } from 'primereact/inputtext'
import { ProgressBar } from 'primereact/progressbar'
import { Dropdown } from 'primereact/dropdown'
import FinancialYoga from './FinancialYoga'
import { current } from '@reduxjs/toolkit'
import LinkPlaidCustom from '../components/LinkPlaidCustom'

const NewBudgetPage = ({
  budgetData,
  oldBudgetData,
  currComponent,
  setCurrComponent,
  fetchBudgetingInformation,
  incomeHighlightInfo,
  plaidAcctsInfo,
  ...props
}) => {
  const toastMsg = useRef()

  const [showWealthDialog, setShowWealthDialog] = useState(false)
  const [showCharityDialog, setShowCharityDialog] = useState(false)

  const [selectedDynamicOption, setSelectedDynamicOption] = useState(null)

  const [showRelinkAlert, setShowRelinkAlert] = useState(false)

  const [showHighlightDialog, setShowHighlightDialog] = useState(
    incomeHighlightInfo?.['Previous Highlights']?.[0]?.is_read === 0 ? true : false
  )

  useEffect(() => {
    console.log('plaidAcctsInfo', plaidAcctsInfo)
    if (plaidAcctsInfo && plaidAcctsInfo?.length > 0) {
      for (let i in plaidAcctsInfo) {
        // If there is one account with reconnect_link
        if (plaidAcctsInfo[i]?.reconnect_link === 1) {
          setShowRelinkAlert(true)
          break
        }
      }
    }
  }, [plaidAcctsInfo])

  const onSuccess = async () => {
    await fetchBudgetingInformation()
  }

  // return specific category object based on passing name for data
  const getSpecificCategory = (name) => {
    return budgetData?.category_details?.find((item) => item.category === name)
  }

  // covert money to us format with 2 decimal places and $ sign
  const changedMoneyToUSFormat = (amount) => {
    const formattedNumber = amount?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    })

    return `${formattedNumber ? formattedNumber : '$0.00'}`
  }

  // rendering page dynamically based on the name
  const displayBudgetPage = () => {
    switch (currComponent) {
      case 'main':
        return <BudgetHome />
      case 'insight':
        return <BudgetInsight />
      case 'income_reserve':
        return <BudgetIncomeReserve />
      case 'dynamic':
        return <BudgetDynmaicSpending />
      case 'transfer':
        return <BudgetTransfer />
      case 'expense':
        return <BudgetExpense />
      case 'income':
        return <BudgetIncome />
      case 'financial_yoga':
        return (
          <FinancialYoga
            setCurrComponent={setCurrComponent}
            userInfo={props.userInfo}
            user={props.user}
            budgetData={budgetData}
            fetchBudgetingInformation={fetchBudgetingInformation}
            t={props?.t}
          />
        )
      case 'relink_accounts':
        return <RelinkAccounts />
      default:
        return <BudgetHome />
    }
  }

  // get dynamic flow text for the dynamic page
  const getDynamicFlowText = () => {
    let cashFlow_descript = ''

    let cashFlow = budgetData?.cash_flow || 0
    let transCounts =
      getSpecificCategory('Transfers')?.['total_transactions_mtd']

    if (cashFlow > 0 && transCounts === 0) {
      cashFlow_descript = 'Your cash flow is positive, looking great!'
    } else if (cashFlow > 0 && transCounts > 0) {
      cashFlow_descript = `Your cash flow is positive, looking great! You have ${transCounts} transfers that may require action.`
    } else if (cashFlow < 0 && transCounts === 0) {
      cashFlow_descript =
        'Your cash flow is negative, your current income is below expenses!'
    } else if (cashFlow < 0 && transCounts > 0) {
      cashFlow_descript = `Your cash flow is negative, your current income is below expenses! You have ${transCounts} transfers that may need to be categorized.`
    }

    return cashFlow_descript
  }

  // return specific icon image for the dynamic spending part
  const getSpendingImg = (name) => {
    switch (name) {
      case 'Icon=car repair.pdf':
        return CarRepairIcon
      case 'Icon=childcare.pdf':
        return ChildCareIcon
      case 'Icon=debt.pdf':
        return DebtIcon
      case 'Icon=Entertainment.pdf':
        return EntertainmentIcon
      case 'Icon=General Merchandise.pdf':
        return MerchandiseIcon
      case 'Icon=General Services.pdf':
        return GeneralIcon
      case 'Icon=Government & Non-Profit.pdf':
        return GovernmentIcon
      case 'Icon=health.pdf':
        return HealthIcon
      case 'Icon=home repair.pdf':
        return HomeRepairIcon
      case 'Icon=insurance.pdf':
        return InsuranceIcon
      case 'Icon=miscelaneous.pdf':
        return MiscelaneousIcon
      case 'Icon=Personal Care.pdf':
        return CareIcon
      case 'Icon=pet.pdf':
        return PetIcon
      case 'Icon=rent.pdf':
        return RentIcon
      case 'Icon=restaurants.pdf':
        return RestaurantIcon
      case 'Icon=subscription.pdf':
        return SubscriptionIcon
      case 'Icon=transportation.pdf':
        return TransportationIcon
      case 'Icon=Travel.pdf':
        return TravelIcon
    }
  }

  // return the red or green color or gray color based on the number value is less or greater than 0 or equal to 0
  const getMoneyTrendStyle = (number) => {
    if (number === 0) {
      return { color: '#aeaeae' }
    } else if (number < 0) {
      return { color: 'red' }
    } else {
      return { color: '#0fa268' }
    }
  }

  // get the dynamic color for green, red and gray based on the percentage ratio for progress bar
  const getProgressBarClassName = (ratio) => {
    if (Number(ratio) === 0) {
      return 'netural'
    } else if (Number(ratio) < 0) {
      return 'negative-bar'
    } else {
      return 'positive-bar'
    }
  }

  // no data component
  const NoDataText = ({ text }) => {
    return (
      <p
        style={{
          minHeight: '20vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '1.2rem',
        }}
      >
        {text ? text : 'No data are available now'}
      </p>
    )
  }

  /**********
   *
   * componets for the budgeting ui
   *
   *
   * ************** */

  // home page
  const BudgetHome = () => {
    // dynamic spending row part
    const DynmaicSpendingRow = ({ name, imgName, current, total }) => {
      return (
        <div
          className='single-dynamic-spending-container'
          onClick={() => {
            setSelectedDynamicOption(name)
            setCurrComponent('dynamic')
          }}
        >
          <div className='spending-container'>
            <span className='spending-img'>
              <img src={getSpendingImg(imgName)} alt='' />

              {name}
            </span>

            <span className='spending-text'>
              <span style={getMoneyTrendStyle(current)}>
                {changedMoneyToUSFormat(current)}
              </span>
            </span>
          </div>

          <div>
            {/* value in this prime react, only do the positive, negative will give wrong, so beside 0, do absoluet value */}
            <ProgressBar
              value={total ? (Math.abs(current / total) * 100)?.toFixed(0) : 0}
              className={getProgressBarClassName(
                total ? ((current / total) * 100)?.toFixed(0) : 0
              )}
            ></ProgressBar>
          </div>
        </div>
      )
    }

    const HighlightDialog = () => {
      return (
        <Dialog
          visible={showHighlightDialog}
          onHide={() => setShowHighlightDialog(false)}
          // style={{
          //   width: props.isSmallScreen ? '90vw' : '40vw',
          //   height: props.isSmallScreen ? '90vh' : '70vh',
          // }}
          className='budget-highlight-dialog'
        >
          <div className='budget-highlight'>
            <div className='highlight-header'>
              <p>
                {props.t('s_recap', {
                  date:
                    incomeHighlightInfo?.['Previous Highlights']?.[0]
                      ?.date_record ?? 'N/A',
                })}
              </p>
              <p style={{ fontSize: '1.5rem' }}>{props.t('you_saved')}</p>
              <p>
                {changedMoneyToUSFormat(
                  incomeHighlightInfo?.['Previous Highlights']?.[0]?.total_save
                )}
              </p>
            </div>

            <div className='highlight-content'>
              {incomeHighlightInfo?.['Previous Highlights']?.[0]?.content?.map(
                (item, index) => {
                  return (
                    <p key={index}>
                      {item?.category}

                      <span style={getMoneyTrendStyle(item?.amount)}>
                        {changedMoneyToUSFormat(item?.amount)}
                      </span>
                    </p>
                  )
                }
              )}
            </div>
          </div>
        </Dialog>
      )
    }

    return (
      <div className='budgeting-home'>
        <IoSettings
          size={28}
          className='relink-btn'
          onClick={() => setCurrComponent('relink_accounts')}
        />
        {showRelinkAlert && (
          <div className='relink-notification-container'>
            <IoWarningOutline size={42} />
            <p>
              One or more of your account(s) have been unlinked. Re-link your
              account.
            </p>
            <IoChevronForwardOutline
              size={42}
              style={{ cursor: 'pointer' }}
              onClick={() => setCurrComponent('relink_accounts')}
            />
          </div>
        )}
        <p className='section-title'>
          🧘&nbsp;&nbsp;&nbsp;{props.t('financial_yoga')}
        </p>
        <section className='financial-yoga-container'>
          <div>
            <p>
              {props.t('financial_yoga_summary')}
              <span onClick={() => setCurrComponent('financial_yoga')}>
                <IoChevronForwardOutline size={42} />
              </span>
            </p>
          </div>

          <div onClick={() => setCurrComponent('insight')}>
            <span>
              <img src={InsightIcon} alt='' />
              {props.t('view_insights')}
            </span>
          </div>
        </section>

        <span className='time-period'>
          {budgetData?.time_period?.start ?? 'N/A'} -{' '}
          {budgetData?.time_period?.end ?? 'N/A'}
        </span>

        <p className='section-title'>{props.t('dynamic_spending')}</p>
        <span>{props.t('dynamic_spending_explanation')}</span>
        <section className='dynamic-spend-section'>
          <p className='syntax-text'>
            <span>{props.t('available_to_spend')}</span>
          </p>
          {budgetData?.dynamic_spend ? (
            budgetData?.dynamic_spend?.map((item, index) => {
              return (
                <DynmaicSpendingRow
                  name={item?.category}
                  key={index}
                  imgName={item?.icon}
                  current={item?.remaining}
                  total={item?.['spend goal']}
                />
              )
            })
          ) : (
            <NoDataText />
          )}
        </section>
        <p className='section-title'>{props.t('income_reserves')}</p>
        <section
          className='income-reserves-section'
          onClick={() => setCurrComponent('income_reserve')}
        >
          <span>
            <img src={IncomeIcon} alt='' />
            {props.t('available_reserves')}
          </span>
          <p className='money-text'>
            {changedMoneyToUSFormat(budgetData?.income_reserves?.available)}
          </p>
          <div>
            <span
              style={getMoneyTrendStyle(budgetData?.income_reserves?.ratio)}
            >
              {budgetData?.income_reserves?.ratio
                ? (budgetData?.income_reserves?.ratio * 100)?.toFixed(2)
                : '0'}
              %
            </span>
            <ProgressBar
              value={
                budgetData?.income_reserves?.ratio * 100
                  ? (
                      Math.abs(budgetData?.income_reserves?.ratio) * 100
                    )?.toFixed(0)
                  : 0
              }
              className={getProgressBarClassName(
                budgetData?.income_reserves?.ratio
                  ? budgetData?.income_reserves?.ratio
                  : 0
              )}
            ></ProgressBar>
          </div>
          <span>
            {/* Based on an emergency fund of{' '}
            {changedMoneyToUSFormat(budgetData?.income_reserves?.total)} (
            {budgetData?.income_reserves?.months} mo. expenses). You are below
            the recommended cash reserved for emergencies. Use our budgeting to
            help you save. */}
            {props.t('income_reserves_explanation', {
              fund: changedMoneyToUSFormat(budgetData?.income_reserves?.total),
              months: budgetData?.income_reserves?.months,
            })}
          </span>
        </section>

        <p className='section-title'>{props.t('wealth_fund')}</p>
        <section
          className='wealth-fund-section'
          onClick={() => setShowWealthDialog(true)}
        >
          <span>
            <img src={InvestIcon} alt='' />
            {props.t('available_to_invest')}
          </span>
          <p>${budgetData?.available_to_invest?.toFixed(2) ?? '0.00'}</p>
        </section>
        <p className='section-title'>{props.t('charitable_giving')}</p>
        <section
          className='charitable-section'
          onClick={() => setShowCharityDialog(true)}
        >
          <span>
            <img src={CharityIcon} alt='' />
            {props.t('available_to_give')}
          </span>
          <p>${budgetData?.charitable_giving?.toFixed(2) ?? '0.00'}</p>
        </section>

        {/* empty section for space in footer */}
        <section style={{ visibility: 'hidden', padding: '0.1rem' }}></section>
        <HighlightDialog />
      </div>
    )
  }

  // insight section page
  const BudgetInsight = () => {
    const [addAccountDialog, setAddAccountDialog] = useState(false)
    const [addCreditScore, setAddCreditScore] = useState(false)

    // dialog for add new bank account
    const AddExternalAcctDialog = () => {
      return (
        <Dialog
          visible={addAccountDialog}
          header={props.t('add_account')}
          onHide={() => setAddAccountDialog(false)}
          style={{
            width: props.isSmallScreen ? '90vw' : '40vw',
            height: props.isSmallScreen ? '90vh' : '70vh',
          }}
          className='grey-dialog'
        >
          <AddExternalAccount />
        </Dialog>
      )
    }

    // dialog to add new credit score information
    const AddCreditScoreDialog = () => {
      return (
        <Dialog
          visible={addCreditScore}
          header={props.t('add_credit_score')}
          onHide={() => setAddCreditScore(false)}
          style={{
            width: props.isSmallScreen ? '90vw' : '50vw',
            height: props.isSmallScreen ? '90vh' : '70vh',
          }}
        >
          <CreditScoreForm />
        </Dialog>
      )
    }

    // subscription part
    const SubscriptionInfo = () => {
      const [selectedFilter, setSelectedFilter] = useState('ALL')

      // Function to handle filter selection
      const handleFilterSelect = (filter) => {
        setSelectedFilter(filter)
      }

      const subscriptionDetail =
        oldBudgetData?.payload?.budgeting?.subscriptions?.subscriptions_details

      let filterKeys = []

      // make sure it has key and not {} object
      if (subscriptionDetail) {
        if (Object?.keys(subscriptionDetail)?.length !== 0) {
          filterKeys = ['ALL', ...Object?.keys(subscriptionDetail)] // Create an array of filter keys
        }
      }

      return (
        <>
          <div className='subscription-header'>
            <span>
              {changedMoneyToUSFormat(
                oldBudgetData?.payload?.budgeting?.subscriptions?.monthly_toal
              )}
            </span>
            <span>
              {oldBudgetData?.payload?.budgeting?.subscriptions?.insight}
            </span>
          </div>

          {filterKeys?.length !== 0 ? (
            <div className='subscription-info'>
              {/* Dynamic filter buttons */}

              <div className='filter-buttons'>
                {filterKeys.map((filter) => (
                  <Button
                    key={filter}
                    onClick={() => handleFilterSelect(filter)}
                    label={filter}
                    className='p-button-text'
                    style={
                      selectedFilter === filter
                        ? { background: 'rgba(63, 81, 181, 0.12)' }
                        : {}
                    }
                  />
                ))}
              </div>

              {/* Display filtered data */}
              <div className='subscription-info-container'>
                {selectedFilter === 'ALL'
                  ? Object.values(subscriptionDetail)
                      .flat()
                      .map((item, index) => (
                        <div key={index} className='single-subscription-info'>
                          <div className='first-part'>
                            <img src={item?.image_url} alt='' />
                            <p>
                              {item?.name} <span>{item?.source}</span>
                            </p>
                          </div>

                          <p>
                            {item?.price} <span>{item?.frequency}</span>
                          </p>
                        </div>
                      ))
                  : subscriptionDetail[selectedFilter].map((item, index) => (
                      <div key={index} className='single-subscription-info'>
                        <div className='first-part'>
                          <img src={item?.image_url} alt='' />
                          <p>
                            {item?.name} <span>{item?.source}</span>
                          </p>
                        </div>

                        <p>
                          {item?.price} <span>{item?.frequency}</span>
                        </p>
                      </div>
                    ))}
              </div>
            </div>
          ) : (
            <NoDataText />
          )}
        </>
      )
    }

    return (
      <div className='budgeting-insight'>
        <span
          onClick={() => setCurrComponent('main')}
          className='budget-back-button'
        >
          {'<'} {props.t('back')}
        </span>
        <div className='header-section'>
          <p>My Finance</p>
          <p>NET WORTH</p>
          <p>
            {changedMoneyToUSFormat(
              oldBudgetData?.payload?.budgeting?.netWorth
            )}
          </p>
        </div>

        <p>Summary</p>
        <section className='summary-section'>
          {/* summary part */}

          <p className='syntax-text'>Month to date</p>
          <div
            className='progress-bar-container'
            onClick={() => setCurrComponent('income')}
          >
            <p>
              Income{' '}
              <span>
                <span style={getMoneyTrendStyle(budgetData?.income)}>
                  {changedMoneyToUSFormat(budgetData?.income)}
                </span>{' '}
              </span>
            </p>
            {/* data from $xxx / $xxxxx */}

            <ProgressBar
              value={
                getSpecificCategory('Income')?.['6m_avg']
                  ? Math.abs(
                      budgetData?.income /
                        getSpecificCategory('Income')?.['6m_avg']
                    ) * 100?.toFixed(0)
                  : 0
              }
              className={getProgressBarClassName(
                getSpecificCategory('Income')?.['6m_avg']
                  ? (budgetData?.income /
                      getSpecificCategory('Income')?.['6m_avg']) *
                      100?.toFixed(0)
                  : 0
              )}
            ></ProgressBar>
          </div>

          <div
            className='progress-bar-container'
            onClick={() => setCurrComponent('expense')}
          >
            <p>
              Expense{' '}
              <span>
                <span style={getMoneyTrendStyle(budgetData?.expenses)}>
                  {changedMoneyToUSFormat(budgetData?.expenses)}
                </span>{' '}
              </span>
            </p>
            {/* data from $xxx / $xxxxx */}
            <ProgressBar
              value={
                getSpecificCategory('Expense')?.['6m_avg']
                  ? Math.abs(
                      budgetData?.expenses /
                        getSpecificCategory('Expense')?.['6m_avg']
                    ) * 100?.toFixed(0)
                  : 0
              }
              className={getProgressBarClassName(
                getSpecificCategory('Expense')?.['6m_avg']
                  ? (budgetData?.expenses /
                      getSpecificCategory('Expense')?.['6m_avg']) *
                      100?.toFixed(0)
                  : 0
              )}
            ></ProgressBar>
          </div>
        </section>
        <div className='summary-sub-section'>
          {/* cash flow and transfers part */}

          <p>{getDynamicFlowText()}</p>

          <div className='summary-sub-content'>
            <div className='cash-flow-container'>
              <i
                className={`pi ${
                  budgetData?.cash_flow === 0
                    ? 'pi-minus'
                    : budgetData?.cash_flow < 0
                    ? 'pi-angle-down'
                    : 'pi-angle-up'
                }`}
                style={{ fontSize: '2rem' }}
              ></i>

              <div>
                <p>Cash Flow</p>
                <span style={getMoneyTrendStyle(budgetData?.cash_flow)}>
                  {changedMoneyToUSFormat(budgetData?.cash_flow)}
                </span>
              </div>
            </div>

            <div className='transfer-flow-container'>
              <i
                className={`pi ${
                  budgetData?.transfers === 0
                    ? 'pi-minus'
                    : budgetData?.transfers < 0
                    ? 'pi-angle-down'
                    : 'pi-angle-up'
                }`}
                style={{ fontSize: '2rem' }}
              ></i>

              <div onClick={() => setCurrComponent('transfer')}>
                <p>Transfers</p>
                <span style={getMoneyTrendStyle(budgetData?.transfers)}>
                  {changedMoneyToUSFormat(budgetData?.transfers)}
                </span>
              </div>

              {getSpecificCategory('Transfers')?.['total_transactions_mtd'] !==
                0 && (
                <p className='notification'>
                  {getSpecificCategory('Transfers')?.['total_transactions_mtd']}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className='financial-section'>
          <Balance />
        </div>
        <p>Subscriptions</p>
        <section className='subscription-section'>
          <SubscriptionInfo />
        </section>
        <p>Credit Score</p>
        <section className='credit-score-section'>
          {oldBudgetData?.payload?.credit_score ? (
            <CreditDetails />
          ) : (
            <>
              <NoDataText />
              <Button
                label='Add credit score'
                onClick={() => {
                  setAddCreditScore(true)
                }}
                style={{
                  display: 'flex',
                  margin: 'auto',
                  marginBottom: '1rem',
                }}
              />
            </>
          )}
        </section>
        <p>External Accounts</p>
        <section className='external-section'>
          <Button
            label={props.t('add_account')}
            icon='pi pi-plus-circle'
            onClick={() => {
              setAddAccountDialog(true)
            }}
            iconPos='right'
          />
          <div className='budgeting-block'>
            <ExternalAccountsList />
          </div>
        </section>
        <AddExternalAcctDialog />
        <AddCreditScoreDialog />
      </div>
    )
  }

  // income reserve page
  const BudgetIncomeReserve = () => {
    let data = budgetData?.income_reserves
    const [highlightData, setHighlightData] = useState(null)
    const [showHighlightDialog, setShowHighlightDialog] = useState(false)

    const HighlightDialog = () => {
      return (
        <Dialog
          visible={showHighlightDialog}
          onHide={() => setShowHighlightDialog(false)}
          // style={{
          //   width: props.isSmallScreen ? '90vw' : '40vw',
          //   height: props.isSmallScreen ? '90vh' : '70vh',
          // }}
          className='budget-highlight-dialog'
        >
          <div className='budget-highlight'>
            <div className='highlight-header'>
              <p>{highlightData?.date_record ?? 'N/A'} recap</p>
              <p style={{ fontSize: '1.5rem' }}>You saved</p>
              <p>{changedMoneyToUSFormat(highlightData?.total_save)}</p>
            </div>

            <div className='highlight-content'>
              {highlightData?.content?.map((item, index) => {
                return (
                  <p key={index}>
                    {item?.category}

                    <span style={getMoneyTrendStyle(item?.amount)}>
                      {changedMoneyToUSFormat(item?.amount)}
                    </span>
                  </p>
                )
              })}
            </div>
          </div>
        </Dialog>
      )
    }

    return (
      <div className='budget-income-reserve'>
        <div className='budget-income-reserve-container'>
          <span
            onClick={() => setCurrComponent('main')}
            className='budget-back-button'
          >
            {'<'} {props.t('back')}
          </span>
          <div className='income-reserve-text'>
            <div>
              <img src={IncomeIcon} alt='' />
            </div>

            <p>{props.t('income_reserves')}</p>
            <span>{props.t('income_reserves_subtext')}</span>
          </div>
          <DetailContainerInfo
            inforObj={data}
            available={budgetData?.income_reserves?.available}
            title={props.t('available_reserves')}
            goalTitle={props.t('allocation_goal')}
            t={props.t}
          />

          <p className='subtitle'>{props.t('monthly_highlights')}</p>
          <div
            onClick={() => {
              setHighlightData(incomeHighlightInfo?.['Current Highlight'])
              setShowHighlightDialog(true)
            }}
            className='highlight-container'
          >
            <p>
              {props.t('cash_flow_for_s', {
                date:
                  incomeHighlightInfo?.['Current Highlight']?.date_record ??
                  'N/A',
              })}
            </p>
            <span
              style={getMoneyTrendStyle(
                incomeHighlightInfo?.['Current Highlight']?.cash_flow
              )}
            >
              {changedMoneyToUSFormat(
                incomeHighlightInfo?.['Current Highlight']?.cash_flow
              )}
            </span>
          </div>

          <p className='subtitle'>{props.t('previous_highlights')}</p>
          <div className='previous-highlight-container'>
            {incomeHighlightInfo?.['Previous Highlights'] ? (
              incomeHighlightInfo?.['Previous Highlights']?.map(
                (item, index) => {
                  return (
                    <p
                      key={index}
                      onClick={() => {
                        setHighlightData(item)
                        setShowHighlightDialog(true)
                      }}
                    >
                      {item?.date_record}

                      <span style={getMoneyTrendStyle(item?.cash_flow)}>
                        {changedMoneyToUSFormat(item?.cash_flow)}
                      </span>
                    </p>
                  )
                }
              )
            ) : (
              <NoDataText />
            )}
          </div>
        </div>

        <HighlightDialog />
      </div>
    )
  }

  // dynmaic spending detail page
  const BudgetDynmaicSpending = () => {
    let data = getSpecificCategory(selectedDynamicOption)
    let overviewData = budgetData?.dynamic_spend?.find(
      (item) => item.category === selectedDynamicOption
    )

    let time = budgetData?.time_period

    // dynamic summary section for this page
    const DynamicOverview = () => {
      return (
        <>
          <div className='available-spend'>
            <p>
              {props.t('available_to_spend')}
              <span style={getMoneyTrendStyle(overviewData?.remaining)}>
                {changedMoneyToUSFormat(overviewData?.remaining)}
              </span>
            </p>
            <p>
              {time?.start} to {time?.end}
            </p>
          </div>

          {overviewData?.remaining < 0 && (
            <div className='warning-msg'>
              <i className='pi pi-exclamation-triangle'></i>

              <p>
                Overspent: {budgetData?.days_to_refill} days left to next refill
              </p>
            </div>
          )}

          <div className='other-financial-info'>
            <p>
              {props.t('spend_goal')}
              <span>
                {changedMoneyToUSFormat(overviewData?.['spend goal'])}
              </span>
            </p>
            <p>
              {props.t('mtd_spend')}
              <span>{changedMoneyToUSFormat(data?.mtd)}</span>
            </p>

            <p>
              {props.t('last_month')}
              <span>{changedMoneyToUSFormat(data?.last_month)}</span>
            </p>

            <p>
              {props.t('take_from')}
              <span style={{ color: 'blue' }}>{overviewData?.steal_from}</span>
            </p>

            <p>
              {props.t('taken_by_other_categories')}
              <span style={{ color: 'red' }}>
                {changedMoneyToUSFormat(overviewData?.steal)}
              </span>
            </p>
          </div>
        </>
      )
    }

    return (
      <div className='budget-dynamic-spend'>
        <span
          onClick={() => setCurrComponent('main')}
          className='budget-back-button'
        >
          {'<'} {props.t('back')}
        </span>

        <p className='dynamic-header-title'>{data?.category}</p>

        <div className='dynamic-summary-container'>
          <DynamicOverview />
        </div>

        <p className='dynamic-title'>{props.t('insights')}</p>
        <div className='line-graph-container'>
          <div className='legend-container'>
            <div className='month-legend'>
              <p>
                <span className='legend-color-symbol'></span>
                {props.t('this_month')}
              </p>
              <p>{changedMoneyToUSFormat(data?.mtd)}</p>
            </div>

            <div className='avg-legend'>
              <p>
                <span className='legend-color-symbol'></span>
                {props.t('average')}
              </p>
              <p>{changedMoneyToUSFormat(data?.['6m_avg'])}</p>
            </div>
          </div>
          <LineGraph dataInfo={data} />
        </div>
        <p className='dynamic-title'>{props.t('transactions')}</p>

        <TransactionInfo dataInfo={data} t={props.t} />

        <div></div>
      </div>
    )
  }

  // wealth detail page but in dialog popup window
  const WealthDialog = () => {
    let data = getSpecificCategory('Investment')

    return (
      <Dialog
        visible={showWealthDialog}
        onHide={() => setShowWealthDialog(false)}
        className='budget-wealth-dialog'
      >
        <div className='budget-wealth'>
          <div className='wealth-text'>
            <div>
              <img src={InvestIcon} alt='' />
            </div>

            <p>{props.t('wealth_fund')}</p>
            <span>{props.t('wealth_fund_subtext')}</span>
          </div>
          <DetailContainerInfo
            inforObj={data}
            available={budgetData?.available_to_invest}
            title={props.t('available_to_invest')}
            goalTitle={props.t('contribution_goal')}
            t={props.t}
          />
        </div>
      </Dialog>
    )
  }

  // charity detail page but in dialog popup window
  const CharityDialog = () => {
    let data = getSpecificCategory('Charitable Giving')
    return (
      <Dialog
        visible={showCharityDialog}
        onHide={() => setShowCharityDialog(false)}
        className='budget-charity-dialog'
      >
        <div className='budget-charity'>
          <div className='charity-text'>
            <div>
              <img src={CharityIcon} alt='' />
            </div>

            <p>{props.t('charitable_giving')}</p>
            <span>{props.t('charitable_giving_subtext')}</span>
          </div>
          <DetailContainerInfo
            inforObj={data}
            available={budgetData?.charitable_giving}
            title={props.t('available_to_spend')}
            goalTitle={props.t('contribution_goal')}
            t={props.t}
          />
        </div>
      </Dialog>
    )
  }

  // transfer detail page
  const BudgetTransfer = () => {
    let data = getSpecificCategory('Transfers')

    return (
      <div className='budget-transfer'>
        <span
          onClick={() => setCurrComponent('insight')}
          className='budget-back-button'
        >
          {'<'} {props.t('back')}
        </span>

        <div className='transfer-text'>
          <div>
            <img src={IncomeIcon} alt='' />
          </div>

          <p>Transfers</p>
        </div>

        <DetailContainerInfo
          inforObj={data}
          available={data?.mtd}
          title='Month to date'
          goalTitle=''
          t={props.t}
        />
        <p className='dynamic-title'>Insight</p>
        <div className='line-graph-container'>
          <div className='legend-container'>
            <div className='month-legend'>
              <p>
                <span className='legend-color-symbol'></span>
                This Month
              </p>
              <p>{changedMoneyToUSFormat(data?.mtd)}</p>
            </div>

            <div className='avg-legend'>
              <p>
                <span className='legend-color-symbol'></span>
                Average
              </p>
              <p>{changedMoneyToUSFormat(data?.['6m_avg'])}</p>
            </div>
          </div>
          <LineGraph dataInfo={data} />
        </div>
        <p className='dynamic-title'>Transactions</p>
        <TransactionInfo dataInfo={data} />
        <div></div>
      </div>
    )
  }

  // expense/investment detail page
  const BudgetExpense = () => {
    let data = getSpecificCategory('Expense')

    return (
      <div className='budget-expense'>
        <span
          onClick={() => setCurrComponent('insight')}
          className='budget-back-button'
        >
          {'<'} {props.t('back')}
        </span>

        <div className='transfer-text'>
          <div>
            <img src={IncomeIcon} alt='' />
          </div>

          <p>Expense</p>
        </div>

        <DetailContainerInfo
          inforObj={data}
          available={data?.mtd}
          title='Month to date'
          goalTitle=''
          t={props.t}
        />
        <p className='dynamic-title'>Insight</p>
        <div className='line-graph-container'>
          <div className='legend-container'>
            <div className='month-legend'>
              <p>
                <span className='legend-color-symbol'></span>
                This Month
              </p>
              <p>{changedMoneyToUSFormat(data?.mtd)}</p>
            </div>

            <div className='avg-legend'>
              <p>
                <span className='legend-color-symbol'></span>
                Average
              </p>
              <p>{changedMoneyToUSFormat(data?.['6m_avg'])}</p>
            </div>
          </div>
          <LineGraph dataInfo={data} />
        </div>
        <p className='dynamic-title'>Transactions</p>
        <TransactionInfo dataInfo={data} />
        <div></div>
      </div>
    )
  }

  // income detail page
  const BudgetIncome = () => {
    let data = getSpecificCategory('Income')

    return (
      <div className='budget-income'>
        <span
          onClick={() => setCurrComponent('insight')}
          className='budget-back-button'
        >
          {'<'} {props.t('back')}
        </span>

        <div className='transfer-text'>
          <div>
            <img src={IncomeIcon} alt='' />
          </div>

          <p>Income</p>
        </div>

        <DetailContainerInfo
          inforObj={data}
          available={data?.mtd}
          title='Month to date'
          goalTitle=''
          t={props.t}
        />
        <p className='dynamic-title'>Insight</p>
        <div className='line-graph-container'>
          <div className='legend-container'>
            <div className='month-legend'>
              <p>
                <span className='legend-color-symbol'></span>
                This Month
              </p>
              <p>{changedMoneyToUSFormat(data?.mtd)}</p>
            </div>

            <div className='avg-legend'>
              <p>
                <span className='legend-color-symbol'></span>
                Average
              </p>
              <p>{changedMoneyToUSFormat(data?.['6m_avg'])}</p>
            </div>
          </div>
          <LineGraph dataInfo={data} />
        </div>
        <p className='dynamic-title'>Transactions</p>
        <TransactionInfo dataInfo={data} />
        <div></div>
      </div>
    )
  }

  const RelinkAccounts = () => {
    return (
      <div className='relink-accounts-container'>
        <p onClick={() => setCurrComponent('main')}>
          {'<'} {props.t('back')}
        </p>
        <div className='relink-accounts-content'>
          <p className='title'>{props.t('connected_accounts')}</p>
          {plaidAcctsInfo?.map((account, i) => {
            return (
              <div className='account-container'>
                <div className='account-row' key={i}>
                  <p className='account-name'>
                    {account?.inst_name}...{account?.mask}
                  </p>
                  <p className='account-value'>
                    {changedMoneyToUSFormat(account?.current)}
                  </p>
                </div>
                {account?.reconnect_link === 1 && (
                  <LinkPlaidCustom
                    user={props.user}
                    isBank={true}
                    reLink={true}
                    t={props.t}
                    onSuccess={onSuccess}
                    itemID={account.item_id}
                  >
                    <div className='relink-button'>Re-Link</div>
                  </LinkPlaidCustom>
                )}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  /********************
   *
   * reusable component for detail container
   *
   *
   *
   *  */

  // detail container reuse with different values
  const DetailContainerInfo = ({
    inforObj,
    available,
    goalTitle,
    title,
    t,
  }) => {
    const [selectedGoal, setSelectedGoal] = useState(
      inforObj?.pct?.toString() || inforObj?.months?.toString()
    )

    // hardcoded option value
    const goalOptions = [
      { label: '0%', value: '0' },
      { label: '1%', value: '1' },
      { label: '2%', value: '2' },
      { label: '3%', value: '3' },
      { label: '4%', value: '4' },
      { label: '5%', value: '5' },
      { label: '6%', value: '6' },
      { label: '7%', value: '7' },
      { label: '8%', value: '8' },
      { label: '9%', value: '9' },
      { label: '10%', value: '10' },
      { label: '11%', value: '11' },
      { label: '12%', value: '12' },
      { label: '13%', value: '13' },
      { label: '14%', value: '14' },
      { label: '15%', value: '15' },
      { label: '16%', value: '16' },
      { label: '17%', value: '17' },
      { label: '18%', value: '18' },
      { label: '19%', value: '19' },
      { label: '20%', value: '20' },
      { label: '21%', value: '21' },
      { label: '22%', value: '22' },
      { label: '23%', value: '23' },
      { label: '24%', value: '24' },
      { label: '25%', value: '25' },
      { label: '265', value: '26' },
      { label: '27%', value: '27' },
      { label: '28%', value: '28' },
      { label: '29%', value: '29' },
      { label: '30%', value: '30' },
    ]

    const allocaOptions = [
      { label: '0 month(s)', value: '0' },
      { label: '1 month(s)', value: '1' },
      { label: '2 month(s)', value: '2' },
      { label: '3 month(s)', value: '3' },
      { label: '4 month(s)', value: '4' },
      { label: '5 month(s)', value: '5' },
      { label: '6 month(s)', value: '6' },
      { label: '7 month(s)', value: '7' },
      { label: '8 month(s)', value: '8' },
      { label: '9 month(s)', value: '9' },
      { label: '10 month(s)', value: '10' },
      { label: '11 month(s)', value: '11' },
      { label: '12 month(s)', value: '12' },
      { label: '13 month(s)', value: '13' },
      { label: '14 month(s)', value: '14' },
      { label: '15 month(s)', value: '15' },
      { label: '16 month(s)', value: '16' },
      { label: '17 month(s)', value: '17' },
      { label: '18 month(s)', value: '18' },
      { label: '19 month(s)', value: '19' },
      { label: '20 month(s)', value: '20' },
      { label: '21 month(s)', value: '21' },
      { label: '22 month(s)', value: '22' },
      { label: '23 month(s)', value: '23' },
      { label: '24 month(s)', value: '24' },
      { label: '25 month(s)', value: '25' },
      { label: '26 month(s)', value: '26' },
      { label: '27 month(s)', value: '27' },
      { label: '28 month(s)', value: '28' },
      { label: '29 month(s)', value: '29' },
      { label: '30 month(s)', value: '30' },
    ]

    // updated the goal value with different body formatting based on the category
    const submitGoalValue = async () => {
      let body = {}

      if (inforObj?.category === 'Investment') {
        body = {
          wealth_fund: Number(selectedGoal),
        }
      } else if (inforObj.category === 'Charitable Giving') {
        body = {
          charitable_giving: Number(selectedGoal),
        }
      } else {
        // income reserve
        body = {
          income_reserves: Number(selectedGoal),
        }
      }

      try {
        let res = await axios.post(
          `${Endpoint}/budget-new/change_user_settings`,
          body,
          {
            headers: {
              Authorization: 'Bearer ' + props?.jwtToken,
            },
          }
        )

        if (res?.data?.payload === 'success') {
          if (inforObj?.category === 'Investment') {
            setShowWealthDialog(false)
          } else if (inforObj.category === 'Charitable Giving') {
            setShowCharityDialog(false)
          }

          // update the information once it is successfully updated
          fetchBudgetingInformation()

          toastMsg.current.show({
            severity: 'success',
            summary: 'Completed',
            detail: 'Goal updated successfully',
            life: 3000,
          })
        } else {
          // if the payload not success, then something is wrong popup the message notification
          toastMsg.current.show({
            severity: 'error',
            summary: 'Updated failed',
            detail: 'Please try again later',
            life: 3000,
          })
        }
      } catch (error) {
        console.log(error)
        toastMsg.current.show({
          severity: 'error',
          summary: 'Updated failed',
          detail: 'Please try again later',
          life: 3000,
        })
      }
    }

    return (
      <div className='subdetail-summary-container'>
        <div className='first-row'>
          <p>{title}</p>
          <span>{changedMoneyToUSFormat(available)}</span>
          {inforObj?.ratio && (
            <>
              <p
                style={getMoneyTrendStyle(inforObj?.ratio)}
                className='percentage-text'
              >
                {inforObj?.ratio ? (inforObj?.ratio * 100)?.toFixed(2) : '0'}%
              </p>

              <ProgressBar
                value={
                  inforObj?.ratio * 100
                    ? (Math.abs(inforObj?.ratio) * 100)?.toFixed(0)
                    : 0
                }
                className={getProgressBarClassName(
                  inforObj?.ratio ? inforObj?.ratio : 0
                )}
              ></ProgressBar>
            </>
          )}
        </div>

        {(inforObj?.pct !== undefined || inforObj?.months !== undefined) && (
          <div className='second-row'>
            <p>{goalTitle}</p>

            <div>
              <Dropdown
                value={selectedGoal}
                onChange={(e) => setSelectedGoal(e.value)}
                options={
                  inforObj?.pct !== undefined ? goalOptions : allocaOptions
                }
                optionLabel='label'
                placeholder='Select goal'
              />
              <Button label={t('submit')} onClick={() => submitGoalValue()} />
            </div>
          </div>
        )}

        <div className='third-row'>
          <p>
            {t('last_month')}
            <span>{changedMoneyToUSFormat(inforObj?.last_month)} </span>
          </p>
          <p>
            {t('six_month_avg')}
            <span>{changedMoneyToUSFormat(inforObj?.['6m_avg'])}</span>
          </p>
        </div>
      </div>
    )
  }

  // line graph compoennt for the data
  const LineGraph = ({ dataInfo }) => {
    const [chartData, setChartData] = useState({})
    const [chartOptions, setChartOptions] = useState({})

    useEffect(() => {
      const documentStyle = getComputedStyle(document.documentElement)
      const textColor = documentStyle.getPropertyValue('--text-color')
      const textColorSecondary = documentStyle.getPropertyValue(
        '--text-color-secondary'
      )
      const surfaceBorder = documentStyle.getPropertyValue('--surface-border')

      // custom format
      const titleTooltip = (tool) => {
        return ''
      }

      const data = {
        labels: Array.from(
          { length: dataInfo?.chart?.['6m_avg']?.length },
          (_, index) => index.toString()
        ),
        datasets: [
          {
            label: `This Month`,
            data: dataInfo?.chart?.['mtd'],
            fill: false,
            borderColor: documentStyle.getPropertyValue('--blue-500'),
            backgroundColor: documentStyle.getPropertyValue('--blue-500'),
            tension: 0.4,
          },
          {
            label: `Average`,
            data: dataInfo?.chart?.['6m_avg'],
            fill: false,
            borderColor: documentStyle.getPropertyValue('--pink-500'),
            backgroundColor: documentStyle.getPropertyValue('--pink-500'),
            tension: 0.4,
          },
        ],
      }
      const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            display: false,
            labels: {
              color: textColor,
              boxWidth: 50,
            },
          },
          tooltip: {
            // displayColors: false,
            callbacks: {
              title: titleTooltip,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
      }

      setChartData(data)
      setChartOptions(options)
    }, [])

    return (
      <div className='line-graph-container'>
        <Chart type='line' data={chartData} options={chartOptions} />
      </div>
    )
  }

  // transaction components
  const TransactionInfo = ({ dataInfo, t }) => {
    // data for transaction
    const [transactionInfo, setTransactionInfo] = useState(null)

    // for the pagination variables
    const [currentData, setCurrentData] = useState(null)
    const [first, setFirst] = useState(0)
    const [rows, setRows] = useState(5)
    // end here

    //move transaction from one category to another new category
    const [changeCategories, setChangeCategories] = useState(null)
    const [selectedChangeCategory, setSelectedChangeCategory] = useState(null)
    const [selectedChangeTransactions, setSelectedChangeTransactions] =
      useState([])
    // end here

    // user filters variables
    const [userFilters, setUserFilters] = useState(null)
    const [selectedUserFilter, setSelectedUserFilter] = useState(null)

    // for searching input variables
    const [searchTransaction, setSearchTransaction] = useState('')
    const [isSearching, setIsSearching] = useState(false)

    // loading
    const [transactionLoading, setTransactionLoading] = useState(true)

    useEffect(() => {
      // search debouncing function
      if (searchTransaction?.trim()) {
        // update the filter array before seaching
        setSelectedUserFilter((prevFilter) => ({
          ...prevFilter,
          ['Search']: searchTransaction, // Update the specific dropdown value
        }))

        // debouncing function
        const delayFn = setTimeout(async () => {
          setIsSearching(true)

          fetchTransactions()
          setIsSearching(false)
        }, 300)
        return () => clearTimeout(delayFn)
      } else {
        // if it is empty, then update the filter again
        setSelectedUserFilter((prevFilter) => ({
          ...prevFilter,
          ['Search']: '', // Update the specific dropdown value
        }))

        fetchTransactions()
      }
    }, [searchTransaction])

    // get the user filter and catgory option list when page rendered
    useEffect(() => {
      getUserFilterList()
      getChangeCategoriesList()
    }, [])

    // whenever the pagination is changed, get that page data
    useEffect(() => {
      const end = first + rows
      setCurrentData(transactionInfo?.slice(first, end))
    }, [first])

    // when the data is updated, then make the loading to falase
    useEffect(() => {
      if (currentData) {
        setTransactionLoading(false)
      }
    }, [currentData])

    // change transaction category and call api to update the transaction
    useEffect(() => {
      if (selectedChangeCategory) {
        moveCategories()
      }
    }, [selectedChangeCategory])

    // filter change category and call api to get filtered the transaction
    useEffect(() => {
      if (selectedUserFilter) {
        fetchTransactions()
      }
    }, [selectedUserFilter])

    // get the transaction data
    const fetchTransactions = async () => {
      try {
        let res = await axios.post(
          `${Endpoint}/budget-new/get_filtered_result`,
          selectedUserFilter,
          {
            headers: {
              Authorization: 'Bearer ' + props?.jwtToken,
            },
          }
        )

        setTransactionInfo(res?.data?.payload)

        // also get the first pagination of updated transaction
        const end = first + rows
        setCurrentData(res?.data?.payload?.slice(first, end))
      } catch (error) {
        setTransactionLoading(false)
        console.log(error)
      }
    }

    // get category list data
    const getChangeCategoriesList = async () => {
      try {
        let res = await axios.get(
          `${Endpoint}/budget-new/get_investall_categories`,
          {
            headers: {
              Authorization: 'Bearer ' + props?.jwtToken,
            },
          }
        )

        // Transform the API data to the desired format for dropdown
        const transformedData = res?.data?.payload?.map((item) => ({
          label: item.category, // Map "category" to "label"
          items: item?.sub_cat?.map((subCategory) => ({
            label: subCategory,
            value: subCategory,
          })),
        }))
        setChangeCategories(transformedData)
      } catch (error) {
        console.log(error)
      }
    }

    // get user filter list data
    const getUserFilterList = async () => {
      try {
        let res = await axios.get(`${Endpoint}/budget-new/get_user_filters`, {
          headers: {
            Authorization: 'Bearer ' + props?.jwtToken,
          },
        })

        let filterObj = {}

        // get dynamic filter object value
        for (let ele of res?.data?.payload) {
          if (ele?.title === 'Categories') {
            if (dataInfo?.category === 'Expense') {
              filterObj[ele?.title] = 'Investment'
            } else {
              filterObj[ele?.title] = dataInfo?.category
            }
          } else {
            filterObj[ele?.title] = 'All'
          }
        }
        filterObj['Search'] = ''
        setSelectedUserFilter(filterObj)

        setUserFilters(res?.data?.payload)
      } catch (error) {
        console.log(error)
      }
    }

    // move transaction from one category to another
    const moveCategories = async () => {
      // get the specific object data
      const foundObject = changeCategories?.find((category) => {
        return category?.items?.some(
          (item) => item.label === selectedChangeCategory
        )
      })

      let primaryCategory = foundObject

      let body = {
        investall_catogry_primary: primaryCategory?.label ?? '',
        investall_catogry_detailed: selectedChangeCategory ?? '',
        transaction_ids: selectedChangeTransactions,
      }

      try {
        let res = await axios.post(
          `${Endpoint}/budget-new/change_category`,
          body,
          {
            headers: {
              Authorization: 'Bearer ' + props?.jwtToken,
            },
          }
        )
        // update transaction and reset the dropdown category
        fetchTransactions()
        setSelectedChangeTransactions([])

        if (res?.data?.payload === 'success') {
          toastMsg.current.show({
            severity: 'success',
            summary: 'Updated categories',
            detail: 'Updated Categories successfully',
            life: 3000,
          })
        } else {
          toastMsg.current.show({
            severity: 'error',
            summary: 'Updated failed',
            detail: 'Please try again later',
            life: 3000,
          })
        }
      } catch (error) {
        console.log(error)
      }
    }

    // update pagination row and current page inde
    const onPageChange = (event) => {
      setFirst(event.first)
      setRows(event.rows)
    }

    // change the unix timestamp date to month date, year
    const changeUnixToDateFormat = (unixTimestamp) => {
      // Create a Date object from the Unix timestamp
      const date = new Date(unixTimestamp)
      // Define an array of month names
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]

      // Extract the month, day, and year from the Date object
      const month = monthNames[date.getMonth()]
      const day = date.getDate()
      const year = date.getFullYear()

      // Create the formatted date string
      const formattedDate = `${month} ${day}, ${year}`

      return formattedDate
    }

    // group header template for the group dropdown prime react
    const groupedItemTemplate = (option) => {
      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <BiSolidCategory style={{ width: '20px', height: '20px' }} />
          <div>{option.label}</div>
        </div>
      )
    }

    //single item template for the group dropdown prime react
    const itemTemplate = (option) => {
      return (
        <div style={{ fontSize: '0.875rem', paddingLeft: '1.5rem' }}>
          <div>{option.label}</div>
        </div>
      )
    }

    // get the selected transaction id that is check for the update category (movecategory function)
    const handleSelectedTrans = (e) => {
      let _selectedTransactions = [...selectedChangeTransactions]

      if (e.checked) {
        _selectedTransactions.push(e.value)
      } else {
        _selectedTransactions = _selectedTransactions?.filter(
          (category) => category !== e.value
        )
      }

      setSelectedChangeTransactions(_selectedTransactions)
    }

    // update filters varaible
    const handleUserFilterChange = (e, dropdownName) => {
      setSelectedUserFilter((prevFilter) => ({
        ...prevFilter,
        [dropdownName]: e.value, // Update the specific dropdown value
      }))
    }

    return transactionLoading ? (
      <Loader logoLoader={true} />
    ) : (
      <>
        <div className='transaction-container'>
          <div className='filter-transaction-buttons'>
            <div className='user-filter'>
              <div className='search-bar'>
                <span className={`p-input-icon-left`}>
                  <i className='pi pi-search' />
                  <InputText
                    placeholder={t('search_in_transaction')}
                    onChange={(e) => setSearchTransaction(e.target.value)}
                  />
                </span>
              </div>

              <div className='filters'>
                {/* <span
                className={`p-input-icon-left
                    ${isSearching && 'p-input-icon-right'}
                    }`}
              >
                <i className='pi pi-search' />
                {isSearching && <i className='pi pi-spin pi-spinner' />}
                <InputText
                  placeholder='Search in transaction'
                  onChange={(e) => setSearchTransaction(e.target.value)}
                />
              </span> */}

                {selectedUserFilter && (
                  <>
                    {userFilters?.map((item, index) => {
                      const newArray = item?.elements?.map((item) => ({
                        label: item?.element,
                        value: item?.operation?.value_data,
                      }))

                      return (
                        <>
                          <span className='p-float-label' key={index}>
                            <Dropdown
                              value={selectedUserFilter?.[item?.title]}
                              onChange={(e) =>
                                handleUserFilterChange(e, item?.title)
                              }
                              options={newArray}
                              optionLabel='label'
                              className='filter-dropdown'
                            />

                            <label>{props.t(item?.title?.toLowerCase())}</label>
                          </span>
                        </>
                      )
                    })}
                  </>
                )}
              </div>
            </div>

            {selectedChangeTransactions?.length > 0 && (
              <div className='change-categories-container'>
                <span className='p-float-label'>
                  <Dropdown
                    value={selectedChangeCategory}
                    onChange={(e) => setSelectedChangeCategory(e.value)}
                    options={changeCategories}
                    optionLabel='label'
                    optionGroupLabel='label'
                    optionGroupChildren='items'
                    optionGroupTemplate={groupedItemTemplate}
                    itemTemplate={itemTemplate}
                  />
                  <label>Change Category</label>
                </span>
              </div>
            )}
          </div>

          {currentData?.length > 0 ? (
            currentData?.map((item, index) => {
              return (
                <div className='single-transaction-container' key={index}>
                  <Checkbox
                    inputId={index}
                    name='transaction'
                    value={item?.transaction_id}
                    onChange={handleSelectedTrans}
                    checked={selectedChangeTransactions?.some(
                      (transaction) => transaction === item?.transaction_id
                    )}
                  />

                  <div className='transaction-part'>
                    <div className='top-part'>
                      <div className='first-row'>
                        <p>
                          {item?.inst_name} {item?.mask}
                        </p>
                        <span>{changeUnixToDateFormat(item?.date)}</span>
                      </div>

                      <div className='second-row'>
                        <p>
                          {item?.investall_category_primary} -{' '}
                          {item?.investall_category_detailed}
                        </p>
                        <span>{changedMoneyToUSFormat(item?.amount)}</span>
                      </div>
                    </div>
                    <p className='transaction-name'>{item?.name}</p>
                  </div>
                </div>
              )
            })
          ) : (
            <NoDataText text={'No results found'} />
          )}

          <Paginator
            first={first}
            rows={rows}
            totalRecords={transactionInfo?.length}
            // rowsPerPageOptions={[10, 20, 30]}
            onPageChange={onPageChange}
          />
        </div>
      </>
    )
  }

  return (
    <>
      {displayBudgetPage()}
      <Toast ref={toastMsg} position='top-right' />
      <WealthDialog />
      <CharityDialog />
    </>
  )
}

export default NewBudgetPage
