import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

export default function ProductsTabs(props) {
  const [currentTab, setTab] = useState(0)
  if (props.t) {
    const products = [
      {
        name: 'Invest Robo',
        description: props.t('roboinfo'),
      },
      {
        name: 'AI Trader',
        description: props.t('aitraderinfo'),
      },
      {
        name: 'Goals',
        description: props.t('goalsinfo'),
      },
      {
        name: 'Budgeting',
        description: props.t('budgetinginfo'),
      },
    ]
    return (
      <div className='products-tab-main-container'>
        <div className='tab-navigation-container'>
          {products.map((product, i) => {
            return (
              <div className='product' onClick={() => setTab(i)} key={i}>
                <p
                  className={
                    i === currentTab ? 'selected-tab bold-text' : 'bold-text'
                  }
                >
                  {props.t(product.name)}
                </p>
                {currentTab === i ? (
                  <div className='underline' />
                ) : (
                  <div className='underline hidden' />
                )}
              </div>
            )
          })}
        </div>
        <div className='product-description-container'>
          <p className='description'>{products[currentTab].description}</p>
          <NavLink to='/products-info' className='default__button purple'>
            <p className='bold-text'>{props.t('learnmore')}</p>
          </NavLink>
        </div>
      </div>
    )
  } else return null
}
