import Axios from 'axios'
import React, { Component } from 'react'
import ENDPOINT from '../Endpoint'
import RiskNum from './RiskNum'
import RiskForecast from './RiskForecast'
import DdChart from './DdChart'
import { Dialog } from 'primereact/dialog'
import '../../scss/pages/cp3/Forecast.scss'
import UpArrow from '../../assets/upArrow.svg'
import DownArrow from '../../assets/downArrow.svg'
import Disclaimer from '../Disclaimer'
import ForecastChart from './ForecastChart'
import formatCurrency from '../../utility-functions/formatCurrency'
import ChartIcon from '../../assets/chart-icon.svg'
import ChartIconActive from '../../assets/chart-icon-active.svg'
import TableIcon from '../../assets/table-icon.svg'
import TableIconActive from '../../assets/table-icon-active.svg'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import {
  selectedTab,
  unSelectedTab,
  selectedText,
  unSelectedText,
} from '../../utility-functions/tabStyles'
import bigNumberFormatter from '../../utility-functions/bigNumberFormatter'
import moment from 'moment'

export default class Forecast extends Component {
  state = {
    showDdChart: false,
    priceForecastTable: true,
    financialForecastTab: 'forecast_revenue',
    showPredictionSuccessDialog: false,
    financialForecastData: null,
  }

  componentDidMount() {
    //this.getStockInformation()
    if (this.props.stockData) this.formatFinancialForecastData()
  }
  forecastChange = (price, forecast) => {
    let value = (
      ((parseFloat(forecast) - parseFloat(price)) / parseFloat(price)) *
      100
    ).toFixed(2)
    if (value > 0) {
      return <p className='percent-value positive'>+{value}%</p>
    } else {
      return <p className='percent-value negative'>{value}%</p>
    }
  }

  formatFinancialForecastData = () => {
    const latest = this.props.stockData?.fundamentals_latest
    const firstData = {
      forecast_ebitda: latest?.[0]?.value,
      forecast_ebitda_value: latest?.[0]?.current_value,
      quarterly_change_ebitda: latest?.[0]?.quarterly_change,
      yearly_change_ebitda: latest?.[0]?.yearly_change,
      forecast_eps: latest?.[1]?.value,
      forecast_eps_value: latest?.[1]?.current_value,
      quarterly_change_eps: latest?.[1]?.quarterly_change,
      yearly_change_eps: latest?.[1]?.yearly_change,
      forecast_revenue: latest?.[2]?.value,
      forecast_revenue_value: latest?.[2]?.current_value,
      quarterly_change_revenue: latest?.[2]?.quarterly_change,
      yearly_change_revenue: latest?.[2]?.yearly_change,
      quarter: latest?.[0]?.quarter,
    }

    this.setState({
      financialForecastData: [
        firstData,
        ...this.props.stockData?.forecast_data,
      ],
    })
  }

  forecastChangeValue = (price, forecast) => {
    if (forecast === 'nan') return -1
    else if (
      (
        ((parseFloat(forecast) - parseFloat(price)) / parseFloat(price)) *
        100
      ).toFixed(2) > 0
    )
      return 1
    else return 0
  }

  toggleShowDdChart = () => {
    this.setState({ showDdChart: !this.state.showDdChart })
  }

  getRiskNum = () => {
    return (
      Math.pow(Math.abs(this.props.sourceData?.maxDrawdownVal) / 100, 0.25) *
      Math.pow(Math.abs(this.props.sourceData?.cvar), 0.75)
    ).toFixed(2)
  }
  numText = (riskNum, ticker) => {
    if (riskNum < 0.16) {
      return this.props.t('lowrisk')
    } else if (riskNum >= 0.16 && riskNum < 0.26) {
      return this.props.t('moderaterisk')
    } else {
      return this.props.t('highrisk')
    }
  }

  riskForecastCard = () => {
    return (
      <div
        style={{
          padding: '25px 15px 5px 15px',
          marginTop: '5%',
          borderRadius: 15,
          display: 'flex',
          //border: '1px solid #3F46F6',
          boxShadow:
            'rgb(255 255 255 / 92%) -3px -2px 4px, rgb(86 86 86 / 20%) 3px 1px 5px, rgb(255 255 255 / 21%) 0px 0px 2px inset',
          flexDirection: 'column',
          width: this.props.isSmallScreen ? '100%' : '50%',
          marginBottom: 20,
          cursor: 'pointer',
          justifyContent: 'space-between',
          height: '200px',
          marginRight: 15,
        }}
        onClick={() => {
          this.toggleShowDdChart()
        }}
      >
        <p
          style={{
            margin: 0,
            fontSize: 15,
            fontFamily: 'Akkurat-Bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 0.75,
            letterSpacing: '0.56px',
            textAlign: 'center',
            color: '#272748',
          }}
        >
          {this.props.t('riskforecast')}
        </p>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '22px',
              marginRight: '5px',
              marginBottom: '0',
              color: '#3F46F6',
            }}
          >
            {(this.getRiskNum() * 100).toFixed(0)}%
          </p>
          <p
            style={{
              fontSize: '22px',
              marginBottom: '0',
              color: '#3F46F6',
            }}
          >
            {this.numText(this.getRiskNum())}
          </p>
        </div>
        <p
          style={{
            fontSize: '15px',
            display: 'flex',
            justifyContent: 'right',
            marginBottom: '0',
            color: '#3F46F6',
            fontFamily: 'Akkurat-Bold',
          }}
        >
          {this.props.t('historicaldrawdown')}{' '}
          <span style={{ color: 'grey', marginLeft: '5px' }}>{'>'}</span>
        </p>
      </div>
    )
  }

  showForecastChart = () => {
    const mape = this.props.forecastChartData?.mape_1m
      ? `${(this.props.forecastChartData?.mape_1m * 100)?.toFixed(2)}%`
      : '-'
    return (
      <div className='forecast-chart-container'>
        <p className='forecast-chart-header'>
          {this.props.t('forecastmargin')}
        </p>
        <p className='forecast-chart-bold'>{mape}</p>
        <div className='forecast-chart-wrapper'>
          <ForecastChart
            forecastChartData={this.props.forecastChartData}
            t={this.props.t}
          />
        </div>
      </div>
    )
  }

  priceForecastTable = () => {
    let oneToTwoTrend = this.forecastChangeValue(
      this.props.sourceData?.priceUSD,
      this.props.sourceData?.returnForecastValue
    )
    let threeMonthTrend = this.forecastChangeValue(
      this.props.sourceData?.priceUSD,
      this.props.sourceData?.forecast_3m
    )
    let sixMonthTrend = this.forecastChangeValue(
      this.props.sourceData?.priceUSD,
      this.props.sourceData?.forecast_6m
    )
    let oneYearTrend = this.forecastChangeValue(
      this.props.sourceData?.priceUSD,
      this.props.sourceData?.forecast_1y
    )
    let twoYearsTrend = this.forecastChangeValue(
      this.props.sourceData?.priceUSD,
      this.props.sourceData?.forecast_2y
    )
    return (
      <div className='forecast-table'>
        {/* <p className='forecast-table-title capitalize'>
                {this.props.t('price_forecast')}
              </p> */}
        <div className='forecast-header'>
          <p className='header'>{this.props.t('forecast')}</p>
          <p className='header1'>{this.props.t('price')}</p>
          <p className='header2'>{this.props.t('percentchange')}</p>
        </div>
        <div className='forecast-row'>
          <div
            className='forecast-row-label'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <p>1-2 {this.props.t('month')}</p>
            {this.props.stockData?.prediction_success?.success && (
              <div
                className='blue-dot'
                onClick={(e) => {
                  this.setState({ showPredictionSuccessDialog: true })
                }}
              >
                <p className='blue-dot-i'>i</p>
              </div>
            )}
          </div>
          <p className='forecast-row-value'>
            {this.props.sourceData?.returnForecastValue === 'nan'
              ? '-'
              : this.props.formatCurrency(
                  this.props.sourceData?.returnForecastValue
                )}
            {oneToTwoTrend !== -1 ? (
              oneToTwoTrend == 0 ? (
                <img
                  className='arrow'
                  src={DownArrow}
                  width={15}
                  height={15}
                ></img>
              ) : (
                <img
                  className='arrow'
                  src={UpArrow}
                  width={15}
                  height={15}
                ></img>
              )
            ) : null}
          </p>
          {this.props.sourceData?.returnForecastValue === 'nan' ? (
            <p className='percent-value'>{'-'}</p>
          ) : (
            this.forecastChange(
              this.props.sourceData?.priceUSD,
              this.props.sourceData?.returnForecastValue
            )
          )}
        </div>
        <div className='forecast-row'>
          <p className='forecast-row-label'>3 {this.props.t('month')}</p>
          <p className='forecast-row-value'>
            {this.props.sourceData?.forecast_3m === 'nan'
              ? '-'
              : this.props.formatCurrency(this.props.sourceData?.forecast_3m)}
            {threeMonthTrend !== -1 ? (
              threeMonthTrend === 0 ? (
                <img
                  className='arrow'
                  src={DownArrow}
                  width={15}
                  height={15}
                ></img>
              ) : (
                <img
                  className='arrow'
                  src={UpArrow}
                  width={15}
                  height={15}
                ></img>
              )
            ) : null}
          </p>
          {this.props.sourceData?.forecast_3m === 'nan' ? (
            <p className='percent-value'>{'-'}</p>
          ) : (
            this.forecastChange(
              this.props.sourceData?.priceUSD,
              this.props.sourceData?.forecast_3m
            )
          )}
        </div>
        <div className='forecast-row'>
          <p className='forecast-row-label'>6 {this.props.t('month')}</p>
          <p className='forecast-row-value'>
            {this.props.sourceData?.forecast_6m === 'nan'
              ? '-'
              : this.props.formatCurrency(this.props.sourceData?.forecast_6m)}
            {sixMonthTrend !== -1 ? (
              sixMonthTrend === 0 ? (
                <img
                  className='arrow'
                  src={DownArrow}
                  width={15}
                  height={15}
                ></img>
              ) : (
                <img
                  className='arrow'
                  src={UpArrow}
                  width={15}
                  height={15}
                ></img>
              )
            ) : null}
          </p>
          {this.props.sourceData?.forecast_6m === 'nan' ? (
            <p className='percent-value'>{'-'}</p>
          ) : (
            this.forecastChange(
              this.props.sourceData?.priceUSD,
              this.props.sourceData?.forecast_6m
            )
          )}
        </div>
        <div className='forecast-row'>
          <p className='forecast-row-label'>1 {this.props.t('year')}</p>
          <p className='forecast-row-value'>
            {this.props.sourceData?.forecast_1y === 'nan'
              ? '-'
              : this.props.formatCurrency(this.props.sourceData?.forecast_1y)}
            {oneYearTrend !== -1 ? (
              oneYearTrend === 0 ? (
                <img
                  className='arrow'
                  src={DownArrow}
                  width={15}
                  height={15}
                ></img>
              ) : (
                <img
                  className='arrow'
                  src={UpArrow}
                  width={15}
                  height={15}
                ></img>
              )
            ) : null}
          </p>
          {this.props.sourceData?.forecast_1y === 'nan' ? (
            <p className='percent-value'>{'-'}</p>
          ) : (
            this.forecastChange(
              this.props.sourceData?.priceUSD,
              this.props.sourceData?.forecast_1y
            )
          )}
        </div>
        <div className='forecast-row'>
          <p className='forecast-row-label'>2 {this.props.t('year')}</p>
          <p className='forecast-row-value'>
            {this.props.sourceData?.forecast_2y === 'nan'
              ? '-'
              : this.props.formatCurrency(this.props.sourceData?.forecast_2y)}
            {twoYearsTrend !== -1 ? (
              twoYearsTrend === 0 ? (
                <img
                  className='arrow'
                  src={DownArrow}
                  width={15}
                  height={15}
                ></img>
              ) : (
                <img
                  className='arrow'
                  src={UpArrow}
                  width={15}
                  height={15}
                ></img>
              )
            ) : null}
          </p>
          {this.props.sourceData?.forecast_2y === 'nan' ? (
            <p className='percent-value'>{'-'}</p>
          ) : (
            this.forecastChange(
              this.props.sourceData?.priceUSD,
              this.props.sourceData?.forecast_2y
            )
          )}
        </div>
      </div>
    )
  }

  priceForecastGraph = () => {
    return (
      <div>
        <ResponsiveContainer width='100%' height={350}>
          <LineChart
            width={'100%'}
            height={300}
            data={this.priceForecastGraphData()}
            // margin={{
            //   top: 5,
            //   right: 30,
            //   left: 50,
            //   bottom: 5,
            // }}
          >
            <CartesianGrid strokeDasharray='0' />
            <XAxis dataKey='name' axisLine={false} dy={15} />
            <YAxis
              domain={[undefined, undefined]}
              tickFormatter={(value) => formatCurrency(value)}
              axisLine={false}
            />
            <Tooltip formatter={(value) => formatCurrency(value)} />
            <Line
              type='monotone'
              dataKey={'value'}
              stroke='#3627E4'
              activeDot={{ r: 6 }}
              dot={{
                stroke: '#3627E4',
                strokeWidth: 1,
                r: 4,
                strokeDasharray: '',
              }}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    )
  }

  priceForecastGraphData = () => {
    const data = []
    data.push({
      name: '1-2m',
      value: parseFloat(this.props.sourceData?.returnForecastValue),
    })
    data.push({
      name: '3m',
      value: parseFloat(this.props.sourceData?.forecast_3m),
    })
    data.push({
      name: '6m',
      value: parseFloat(this.props.sourceData?.forecast_6m),
    })
    data.push({
      name: '1y',
      value: parseFloat(this.props.sourceData?.forecast_1y),
    })
    data.push({
      name: '2y',
      value: parseFloat(this.props.sourceData?.forecast_2y),
    })
    return data
  }

  financialForecastTabs = () => {
    return (
      <div className='financial-forecast-tabs'>
        <div
          style={
            this.state.financialForecastTab === 'forecast_revenue'
              ? selectedTab
              : unSelectedTab
          }
          onClick={() =>
            this.setState({ financialForecastTab: 'forecast_revenue' })
          }
        >
          <p
            style={
              this.state.financialForecastTab === 'forecast_revenue'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('revenue')}
          </p>
        </div>
        <div
          style={
            this.state.financialForecastTab === 'forecast_eps'
              ? selectedTab
              : unSelectedTab
          }
          onClick={() =>
            this.setState({ financialForecastTab: 'forecast_eps' })
          }
        >
          <p
            style={
              this.state.financialForecastTab === 'forecast_eps'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('eps')}
          </p>
        </div>
        <div
          style={
            this.state.financialForecastTab === 'forecast_ebitda'
              ? selectedTab
              : unSelectedTab
          }
          onClick={() =>
            this.setState({ financialForecastTab: 'forecast_ebitda' })
          }
        >
          <p
            style={
              this.state.financialForecastTab === 'forecast_ebitda'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('ebitda')}
          </p>
        </div>
      </div>
    )
  }

  fiancialForecastChart = () => {
    const CustomTooltip = ({ payload, label, active }) => {
      if (active && payload && label) {
        return (
          <div className='custom-tooltip'>
            <p className='custom-tooltip-date'>{label}</p>
            <div className='custom-tooltip-row'>
              <p className='value-label'>{this.props.t('value')}: </p>
              <p className='value-predicted'>
                ${bigNumberFormatter(payload[0].value, 1)}
              </p>
            </div>
          </div>
        )
      }
      return null
    }
    return (
      <div>
        <ResponsiveContainer width='100%' height={350}>
          <LineChart
            width={'100%'}
            height={300}
            data={this.financialForecastGraphData()}
            // padding={{
            //   top: 5,
            //   right: 30,
            //   left: 50,
            //   bottom: 5,
            // }}
          >
            <defs>
              <linearGradient id='gradient' x1='0' y1='0' x2='100%' y2='0'>
                <stop
                  offset={`${Math.ceil(
                    (1 / this.state.financialForecastData?.length) * 100
                  )}%`}
                  stopColor='grey'
                />
                <stop offset={'0%'} stopColor='#3627e4' />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray='0' />
            <XAxis dataKey='name' axisLine={false} dy={15} />
            <YAxis
              domain={[undefined, undefined]}
              tickFormatter={(value) => `$${bigNumberFormatter(value, 1)}`}
              axisLine={false}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type='monotone'
              stroke='url(#gradient)'
              dataKey={'value'}
              // stroke='#3627E4'
              activeDot={{ r: 6 }}
              dot={{
                stroke: '#3627E4',
                strokeWidth: 1,
                r: 4,
                strokeDasharray: '',
              }}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    )
  }

  // financialForecastGraphData = () => {
  //   const data = this.props.sourceData?.forecast_data?.map((item) => {
  //     return {
  //       name: item.quarter,
  //       value: item[`${this.state.financialForecastTab}_value`],
  //     }
  //   })
  //   data.unshift({
  //     name: this.props.sourceData?.fundamentals_latest?.[0]?.quarter,
  //     value: this.getFundamentalLatestGraphValue(),
  //   })
  //   return data
  // }

  // financialForecastTable = () => {
  //   const data = this.props.sourceData?.forecast_data?.map((item) => {
  //     return {
  //       label: item.quarter,
  //       value: item[this.state.financialForecastTab],
  //     }
  //   })
  //   data.unshift({
  //     label: this.props.sourceData?.fundamentals_latest?.[0]?.quarter,
  //     value: this.getFundamentalLatestValue(),
  //   })
  //   return (
  //     <div className='financial-table-data'>
  //       {data?.map((item, index) => {
  //         return (
  //           <div key={index} className='item'>
  //             <p className='header'>{item.label}</p>
  //             <p className='forecast-row-value'>{item.value}</p>
  //           </div>
  //         )
  //       })}
  //     </div>
  //   )
  // }

  financialForecastGraphData = () => {
    if (!this.state.financialForecastData) return
    const data = this.state.financialForecastData?.map((item) => {
      return {
        name: item.quarter,
        value: item[`${this.state.financialForecastTab}_value`],
      }
    })
    return data
  }

  financialForecastTable = () => {
    const tabLabels = this.state.financialForecastTab?.split('_')
    return (
      <div className='financial-table-chart'>
        <div className='table-cols-label'>
          <p className='header capitalize'>{this.props.t(tabLabels?.[1])}</p>
          <p className='header'>{this.props.t('quarterly')}</p>
          <p className='header'>{this.props.t('yearly')}</p>
        </div>
        <div style={{ width: '100%' }}>
          <div className='col-header'>
            {this.state.financialForecastData?.map((item) => {
              return (
                <div className='table-col'>
                  <p className='header'>{item.quarter}</p>
                </div>
              )
            })}
          </div>
          <div className='table-cols'>
            {this.state.financialForecastData?.map((item, index) => {
              return (
                <div className='table-col'>
                  <p
                    className={index === 0 ? 'table-value-grey' : 'table-value'}
                  >
                    {item[this.state.financialForecastTab]}
                  </p>
                  <p
                    className={index === 0 ? 'table-value-grey' : 'table-value'}
                  >
                    {
                      item[
                        `quarterly_change_${tabLabels?.[tabLabels?.length - 1]}`
                      ]
                    }
                  </p>
                  <p
                    className={index === 0 ? 'table-value-grey' : 'table-value'}
                  >
                    {
                      item[
                        `yearly_change_${tabLabels?.[tabLabels?.length - 1]}`
                      ]
                    }
                  </p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  getFundamentalLatestValue = () => {
    switch (this.state.financialForecastTab) {
      case 'forecast_revenue':
        return this.props.sourceData?.fundamentals_latest?.[2]?.value
      case 'forecast_eps':
        return this.props.sourceData?.fundamentals_latest?.[1]?.value
      case 'forecast_ebitda':
        return this.props.sourceData?.fundamentals_latest?.[0]?.value
      default:
        return '-'
    }
  }

  getFundamentalLatestGraphValue = () => {
    switch (this.state.financialForecastTab) {
      case 'forecast_revenue':
        return this.props.sourceData?.fundamentals_latest?.[2]?.current_value
      case 'forecast_eps':
        return this.props.sourceData?.fundamentals_latest?.[1]?.current_value
      case 'forecast_ebitda':
        return this.props.sourceData?.fundamentals_latest?.[0]?.current_value
      default:
        return '-'
    }
  }

  render() {
    if (this.props.t) {
      return (
        <>
          <div className='forecast-container'>
            <div className='toggle-icons-container'>
              {this.state.priceForecastTable ? (
                <img
                  src={TableIconActive}
                  onClick={() => {
                    this.setState({
                      priceForecastTable: !this.state.priceForecastTable,
                    })
                  }}
                  className='toggle-icon'
                />
              ) : (
                <img
                  src={ChartIconActive}
                  onClick={() => {
                    this.setState({
                      priceForecastTable: !this.state.priceForecastTable,
                    })
                  }}
                  className='toggle-icon'
                />
              )}
              {/* <img
                src={
                  this.state.priceForecastTable ? TableIconActive : TableIcon
                }
                onClick={() => {
                  this.setState({
                    priceForecastTable: !this.state.priceForecastTable,
                  })
                }}
                className='toggle-icon'
              />
              <img
                src={
                  !this.state.priceForecastTable ? ChartIconActive : ChartIcon
                }
                className='toggle-icon'
                onClick={() => {
                  this.setState({
                    priceForecastTable: !this.state.priceForecastTable,
                  })
                }}
              /> */}
            </div>
            {this.state.priceForecastTable
              ? this.priceForecastTable()
              : this.priceForecastGraph()}

            {!this.props.sourceData?.isCrypto && (
              <div className='financial-forecast-table space-top-big'>
                <p className='forecast-table-title capitalize'>
                  {this.props.t('financial_forecast')}
                </p>
                {this.financialForecastTabs()}
                {this.fiancialForecastChart()}
                {this.financialForecastTable()}
                {/* <div className='forecast-header'>
                  <p className='header'>{this.props.t('forecast')}</p>
                  <p className='header-center'>{this.props.t('revenue')}</p>
                  <p className='header-center uppercase'>
                    {this.props.t('ebitda')}
                  </p>
                  <p className='header-right uppercase'>
                    {this.props.t('eps')}
                  </p>
                </div>
                <div className='forecast-row grey-background'>
                  <p className='forecast-row-label no-margin'>
                    {this.props.sourceData?.fundamentals_latest?.[0]?.quarter
                      ? this.props.sourceData?.fundamentals_latest?.[0]?.quarter
                      : '-'}
                  </p>
                  <p className='forecast-row-value no-margin'>
                    {this.props.sourceData?.fundamentals_latest?.[2]?.value
                      ? `${this.props.sourceData?.fundamentals_latest?.[2]?.value}`
                      : '-'}
                  </p>
                  <p className='forecast-row-value no-margin'>
                    {this.props.sourceData?.fundamentals_latest?.[0]?.value
                      ? `${this.props.sourceData?.fundamentals_latest?.[0]?.value}`
                      : '-'}
                  </p>
                  <p className='forecast-row-value-right no-margin'>
                    {this.props.sourceData?.fundamentals_latest?.[1]?.value
                      ? `${this.props.sourceData?.fundamentals_latest?.[1]?.value}`
                      : '-'}
                  </p>
                </div>
                {this.props.sourceData?.forecast_data?.map((item, index) => {
                  return (
                    <div className='forecast-row' key={index}>
                      <p className='forecast-row-label'>{item.quarter}</p>
                      <p className='forecast-row-value'>
                        {item.forecast_revenue}
                      </p>
                      <p className='forecast-row-value'>
                        {item.forecast_ebitda}
                      </p>
                      <p className='forecast-row-value-right'>
                        {item.forecast_eps}
                      </p>
                    </div>
                  )
                })} */}
              </div>
            )}
          </div>
          <div className='risk-forecast-cards'>
            {this.props.stockData &&
              this.props.stockData?.maxDrawdownData &&
              this.riskForecastCard()}
            {this.props.forecastChartData && this.showForecastChart()}
          </div>
          <Disclaimer />
          <Dialog
            visible={this.state.showDdChart}
            header={this.props.t('historicalstresstest')}
            onHide={() => this.toggleShowDdChart()}
            style={{ width: this.props.isSmallScreen ? '90vw' : '50vw' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0 15px 0 15px',
              }}
            >
              <div>
                <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                  {this.props.sourceData?.symbol}
                </p>
                <p style={{ marginTop: 0 }}>{this.props.sourceData?.company}</p>
              </div>
              <div>
                <p
                  style={{
                    marginBottom: 0,
                    fontWeight: 'bold',
                    color: 'red',
                    textAlign: 'right',
                  }}
                >
                  {this.props.sourceData?.maxDrawdownVal.toFixed(2)}%
                </p>
                <p
                  style={{
                    marginTop: 0,
                    color: 'grey',
                    textAlign: 'right',
                  }}
                >
                  {this.props.t('maxdrawdown')}
                </p>
              </div>
            </div>
            <DdChart
              drawdownChart={this.props.stockData?.maxDrawdownData}
              t={this.props.t}
            />
          </Dialog>
          <Dialog
            visible={this.state.showPredictionSuccessDialog}
            header={this.props.t('forecast_achieved')}
            onHide={() => this.setState({ showPredictionSuccessDialog: false })}
            style={{ width: this.props.isSmallScreen ? '90vw' : '30vw' }}
          >
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>{this.props.t('forecasted_price')}: </p>
                <p>
                  {formatCurrency(
                    this.props.stockData?.prediction_success?.prediction
                  )}
                </p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>{this.props.t('forecasted_date')}: </p>
                <p>
                  {moment
                    .unix(
                      parseInt(
                        this.props.stockData?.prediction_success?.date_record /
                          1000
                      )
                    )
                    .format('MM/DD/YYYY')}
                </p>
              </div>
            </div>
          </Dialog>
        </>
      )
    } else return null
  }
}
