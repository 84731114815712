import React from 'react'
import { Card, Row, } from "react-bootstrap";
import { FaSearch, FaQuestionCircle,FaRegQuestionCircle, FaWindowClose } from "react-icons/fa";
import Steps from './Steps'
import SingleCompanyCard from "../singleCompanyCard"
import ErrorModal from "../ErrorModal"
import MobileTickerList from "./mobileTickerList"
import {CSVReader} from 'react-papaparse'
import InputRange from 'react-input-range';
import Explore from "../../assets/newUI/explore.png"
import ForeSCITELogo from "../../assets/newUI/ForeSCITE_Final_Logo_Dark.png"

import importPic from "../../assets/newUI/importPic.png"
import upArrow from "../../assets/single_up.svg";
import downArrow from "../../assets/single_down.svg";
import strongUpArrow from "../../assets/double_up.svg";
import strongDownArrow from "../../assets/double_down.svg";
import flatArrow from "../../assets/flat.svg";

import Eye from "../../assets/newUI/eye1.svg";
import ReOptImg from "../../assets/newUI/reOpt.svg";
import Complete from "../../assets/newUI/complete.svg";
import File from "../../assets/newUI/file.svg";
import Ticket from "../../assets/newUI/ticket.svg";
import Spinner from 'react-bootstrap/Spinner'

import ENDPOINT from '../Endpoint'
import SearchEndpoint from '../SearchEndpoint';

const brandColor = "#00A99D"


export default class PortConstructor extends React.Component {
    

    state = {
        showing: true,
        searchMomentumText: '',
        momDataSource: [],
        sudoPortfolioStocks: this.props.prevSet,
        hover: false,
        dropDown: true,
        total: 0,
        showWatchlist: this.props.isSmallScreen? true:false,
        showMassAdd: false, 
        massAddTable: [["",""],["",""]],
        errors: '',
        showErrorModal: false,
        cash: this.props.optCash,
        cashModal: false,
        fileImportModal: false,
        loading: false,
        returnsConstraint: false,
        objDesc: false,
        uploadSuccessful: false,
        massAddByShares: true,
        showCustoms: false,
        holdings: [],
        rtVal: 5,
        rtDesc:false,
        massAddBy: 'quantity',
        rangeVal: 3,
        selected: [],
        step:0,
        showSteps:this.props.prevSet.length > 0 ? false : true,
        addRowModal:false,
        excluded:[],
        customPorts:this.props.customPorts
    }

    componentDidMount(){
        // this.getPlaidAccounts()
        this.fetchCustomStrategies()
        this.getPlaidHoldings()
    }

    getPlaidItems = ()=>{ // an Item is all the accounts at one place (multiple accounts at robinhood)
        fetch(`${ENDPOINT}/plaid/items`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => console.log('Items response', responseJson))
          .catch(err=>console.log(`catching errors`, err))
    }
    
    getPlaidAccounts = ()=>{ // an Account is one account at one place (ie. one account at robinhood)
        fetch(`${ENDPOINT}/plaid/accounts`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => console.log('Accounts response', responseJson))
          .catch(err=>console.log(`catching errors`, err))
    }
    
    getPlaidHoldings = ()=>{ // all holdings of all linked accounts combined
        fetch(`${ENDPOINT}/plaid/holdings`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => this.setState({holdings: responseJson}))
          .catch(err=>console.log(`catching errors`, err))
    }

    arrayToObject = (arr) => { // necessary for nothing anymore
        let result = {};
        for (let i = 0; i < arr.length; i++) {
            result[arr[i].symbol] = {allocation:arr[i].allocation, shares:arr[i].shares};
        }
        return result
    }

    // removeCommas(numberAsString){ // no longer necessary
    //     return (parseFloat(numberAsString.split(",").join("")))
    // }

    addCommas(flt){ //add commas to numbers 
        return flt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    checkCusip(str){ // rarely if ever used still here for backwards compatibility
        const re = new RegExp("(^|[^a-zA-Z0-9])[0-9]{3}[a-zA-Z0-9]{6}([^a-zA-Z0-9]|$)");
        return re.test(str) 
    }

    checkNoValues = ()=>{ // catches weird edge case where someone optimizes with no values
        let val = 0
        this.state.sudoPortfolioStocks.forEach(ele=>val += ele.value)
        console.log('val b4 cash',val)
        val += this.state.cash
        console.log('val after cash',val)
    }

    massAdd = ()=>{ //!!!! IMPORTANT !!!! Crucial for portfolio construction
        this.setState({loading:true})
        let cash = this.state.cash
        let totalValue = this.state.total
        let total = this.state.massAddByShares ? parseFloat(this.state.cash):parseFloat(this.state.cash)+1000000
        let lst = new Array()
        let sps = new Array()
        let tableObj = {}
        this.state.massAddTable.forEach((ele)=> {
            if(ele[0] === '$$'){
                // cash+= ele[1]
                // total+= ele[1]
                cash = parseFloat(cash) + parseFloat(ele[1])
                total = parseFloat(total) + parseFloat(ele[1])
            }
            else{
                if(ele[0] !== ""){
                    let sym = (ele[0].split('-')[0]).toUpperCase()
                    // console.log('sym', sym)
                    tableObj[sym]=parseFloat(ele[1])
                }
            }
        })
        //NETEN BULK LIVE
        // fetch(`${ENDPOINT}/search/ticker-bulk-live?search=${Object.keys(tableObj)}`)

        //NETEN BULK 
        fetch(`${SearchEndpoint}/ticker-bulk?search=${Object.keys(tableObj)}`)
        .catch(err=> console.log('catching: ', err))
        .then(resp=>resp.json())
        .then(responseJson=>{
            responseJson.content_list.forEach((ele, i)=>{
                let obj
                if(this.state.massAddBy === 'quantity'){
                    if(this.checkCusip(ele.symbol)){
                        total += Math.abs(tableObj[ele.symbol])
                        obj = {
                            ...ele,
                            shares: (tableObj[(ele.symbol).toUpperCase()] / 100).toFixed(2),
                            value: (tableObj[(ele.symbol).toUpperCase()] / 100 * ele.priceUSD).toFixed(2),
                            allocation: 0,
                            type:'FixedIncome'

                        }
                        
                    }else{
                        total += ele.priceUSD*tableObj[ele.symbol]
                        obj = {
                                ...ele, 
                                shares:tableObj[ele.symbol] < 0 ? 0:tableObj[ele.symbol], 
                                value:((ele.priceUSD*tableObj[ele.symbol]).toFixed(2)), 
                                allocation:0
                        }
                    }
                }
                else if (this.state.massAddBy === 'allocation'){
                    // console.log('Mass Adding by Allocation')
                    if (totalValue > 0){
                        // console.log('inside if statement total value > 0 // total value: ', totalValue)
                        obj = {
                            ...ele,
                            shares:((totalValue*tableObj[ele.symbol]/100)/ele.priceUSD).toFixed(2),
                            value:(totalValue*tableObj[ele.symbol]/100).toFixed(2),
                            allocation:(Math.abs(tableObj[ele.symbol])),
                        }
                    }
                    else{
                        obj = {
                            ...ele,
                            shares:0,
                            value:0,
                            allocation:Math.abs(tableObj[ele.symbol]),
                        }
                    }
                }
                else if(this.state.massAddBy === 'dollarValue'){
                    total += Math.abs(tableObj[ele.symbol])
                    obj = {
                        ...ele, 
                        shares:((tableObj[ele.symbol]/ele.priceUSD).toFixed(2)), 
                        value:tableObj[ele.symbol],
                        allocation:0
                    }
                }
                else{
                    console.log('no matching Add by')
                }
                lst.push(obj)
                } 
            )
            
            if(this.state.massAddBy === 'quantity' || this.state.massAddBy === 'dollarValue'){
                lst.forEach(ele=>{
                    sps.push({...ele, allocation: (Math.abs(ele.value) / total * 100).toFixed(2)})
                })
            }else if(totalValue !== 0){
                lst.forEach(ele=>{
                    sps.push({...ele, allocation: (ele.allocation).toFixed(2), })
                })
            }else{
                lst.forEach(ele=>{
                    sps.push({...ele, shares:((ele.allocation * total / 100)/ele.price).toFixed(2), allocation: (ele.allocation).toFixed(2), value: (ele.allocation * total / 100).toFixed(2)})
                })
            }
        }
        )
        .then(this.setState({loading:false, sudoPortfolioStocks: sps, showMassAdd:false, total, loading:false, cash, massAddTable: [["",""],["",""]], }))
        }


    setTableCell = (e, index, column) => { // adding multiple stocks by ticker
        let value = e.target.value.toUpperCase()
        let updated = [...this.state.massAddTable]
        let final
        if(column === 0 || (column === 1 && (value > 0 || value === ""))){   
            updated[index][column] = value
        }
        if (updated.length - 1 === index && column === 1){
            final = [...updated,["",""]]
        }else{
            final = [...updated]
        }
        this.setState({massAddTable: final})
    }  

    singleStockConstraint=()=>{  // dynamically set upper bound of each equity
        let total = 0
        this.state.sudoPortfolioStocks.forEach(ele=>{
            if(ele.type === 'Equity' ){
            // if(ele.type === 'Equity' && ele.needOptimization){
                total+=1
            }
        })
        return (100/total*1.5)
    }

    cashModal = ()=>{ // not used since steps modal introduced
        if(this.state.cashModal){
        return (
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Add Unallocated Cash (in USD)</p>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.setState({cashModal:false})}> X </p>
                    </div>
                            
                    <div style={{display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column',marginTop:20}}>
                        <input style={{textAlign:'right'}} type="number" value={this.state.cash.toFixed(2)} onChange={(e)=>{
                            let cash = parseFloat(e.target.value)
                            this.setState({cash})
                        }
                        }/>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'center', marginTop: 20, marginBottom:20}}>
                        <div style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, padding: 10, width: '20%', cursor:'pointer'}} 
                        onClick={()=>{
                            let total = this.state.total + this.state.cash
                            let sudoPortfolioStocks = this.state.sudoPortfolioStocks.map(ele=>{
                                return(
                                    {...ele, allocation: (ele.value/total*100).toFixed(2)}
                                )
                            })
                            this.setState({total, cashModal:false, sudoPortfolioStocks})
                        }}>
                            <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                                Add Cash
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          )}
    }
    showErrors = ()=>{ // 
        if (this.state.errors){
            return (
                <div style={{display: 'flex', justifyContent:'center', alignItems: 'center', width: '75%', margin:'auto'}}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(253,180,181)', borderRadius: 10, padding: 10, marginBottom: 5, width: '100%'}}>
                        <p style={{margin: 0}}>{this.state.errors}</p>
                    </div>
                </div>
            )
        }else{
            if(this.state.uploadSuccessful){
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '75%',margin:'auto' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(234,255,219)', borderRadius: 10, padding: 10, marginBottom: 5, width: '100%' }}>
                            <p style={{ margin: 0 }}>Successfully Imported</p>
                        </div>
                    </div>
                )
            }
        }
    }

    removeDollarSign = (string)=>{
        if (typeof string !== 'undefined' && string.includes('$')){
            return string.replace('$', '')
        }else{
            return string
        }

    }

    removePercentSign = (string)=>{
        // console.log('string',string)
        if (typeof string !== 'undefined' && string.includes('%')){
            return string.replace('%', '')
        }else{
            return string
        }

    }

    fileImportModal = ()=>{ // allows for import, and interpreting CSVs
        if(this.state.fileImportModal){
        return (
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:'40%'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Import A File</p>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.setState({fileImportModal:false})}> X </p>
                    </div>
                    <div style={{display:'flex', justifyContent:"center", alignItems:'center',padding:10, marginTop:20}}>
                        <img
                            src={importPic}
                            style={{width:'20%'}}
                        />
                    </div>
                    <div style={{display:'flex', margin:'auto', marginBottom: 0, flexDirection:'column', width:'90%', marginTop:20, marginBottom:20}}>
                        <p style={{margin:0, margin:'auto', fontSize: 14, color:'#afb2bb', textAlign:'center'}}>Your CSV file should have one column called 'Symbol' and another column called either 'Number of Shares' or 'Allocation'.</p>
                        <p style={{margin:0, margin:'auto', fontSize: 14, color:'#afb2bb', textAlign:'center'}}>If you have excess cash in your portfolio that you would like to add please use '$$' as the symbol.</p>
                    </div>
                    <div style={{display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width: '80%', margin:'auto'}}>
                    <CSVReader
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        style={{}}
                        config={{}}
                        addRemoveButton
                        onRemoveFile={()=>this.handleOnRemoveFile}
                        onFileLoad={(e)=>{
                            // console.log('e',e)
                            let arr = []
                            let errorRows = []
                            let symColumn = -1
                            let quantityColumn = -1
                            let pcntColumn = -1
                            let dolValColumn = -1
                            // if(e[0].data.length !== 2){
                            //     this.setState({errors:'too many columns'})
                            // }
                            // else if(e[0].data[0].toLowerCase() !== 'symbol' || e[0].data[1].toLowerCase() !== 'number of shares'){
                            //     this.setState({errors:'incorrect column names'})
                            // }
                            /* LOOP THROUGH COLUMN NAMES TO DECIDE WHICH COLUMN IS WHICH */
                            e[0].data.forEach((ele, i)=>{ 
                                if(ele.toLowerCase() === 'symbol'|| ele.toLowerCase() === 'ticker' || ele.toLowerCase() === 'stock symbol' || ele.toLowerCase() === 'symb' || ele.toLowerCase() === 'symbols' || ele.toLowerCase() === 'stock' || ele.toLowerCase() === 'asset' ||ele.toLowerCase() === 'cusip' ||ele.toLowerCase() === 'company' || ele.toLowerCase() === 'security id'){
                                    if(symColumn === -1){
                                        symColumn = i
                                    }
                                }
                                if (ele.toLowerCase() === 'quantity' || ele.toLowerCase() === 'quantity of shares' || ele.toLowerCase() === 'number of shares' || ele.toLowerCase() === 'num of shares'|| ele.toLowerCase() === 'num shares'|| ele.toLowerCase() === 'shares'){
                                    if(quantityColumn === -1){
                                        quantityColumn = i
                                    }
                                }
                                if (ele.toLowerCase() === 'dollar value' || ele.toLowerCase() === 'value' || ele.toLowerCase() === '$ value' || ele.toLowerCase() === 'market value' || ele.toLowerCase() === 'mkt value' || ele.toLowerCase() === 'mkt. value' || ele.toLowerCase() === 'val'){
                                    if(dolValColumn === -1 && quantityColumn === -1){
                                        dolValColumn = i
                                    }
                                }
                                if (ele.toLowerCase() === 'allocation' || ele.toLowerCase() === '%' || ele.toLowerCase() === 'allocation %' || ele.toLowerCase() === '100%' || ele.toLowerCase() === 'percent' || ele.toLowerCase() === 'percent allocation' || ele.toLowerCase() === '% of portfolio' || ele.toLowerCase() === '% portfolio' || ele.toLowerCase() === 'weight' || ele.toLowerCase() === 'weight %' || ele.toLowerCase() === 'weight%' ){
                                    if(pcntColumn === -1 && dolValColumn === -1 && quantityColumn === -1){
                                        pcntColumn = i
                                    }
                                }
                                if (quantityColumn >=0){
                                    this.setState({massAddByShares: true})
                                    this.setState({massAddBy: 'quantity'})
                                }
                                else if (pcntColumn >=0){
                                    this.setState({massAddBy: 'allocation'})
                                }
                                else if (dolValColumn >=0){
                                    this.setState({massAddBy: 'dollarValue'})
                                }
                                else{
                                    this.setState({massAddByShares: false})
                                }
                            })

                            /* if the necessary columns are there loop through data and get necessary info  */
                            // console.log('symColumn: ',symColumn)
                            // console.log('quantityColumn: ',quantityColumn)
                            // console.log('pcntColumn: ',pcntColumn)
                            // console.log('dolValColumn: ',dolValColumn)

                            if(symColumn !== -1 && (quantityColumn !== -1 || pcntColumn !== -1 || dolValColumn !== -1)){
                                    e.forEach((ele,i)=>{
                                        if(ele.data[symColumn].length !== 0){
                                            if(ele.data[symColumn].length === 0){
                                                console.log(`error in row ${i} because missing data`)
                                                if(!errorRows.includes(i)){
                                                    errorRows.push(` ${i}`)
                                                } 
                                            }
                                            if (i>0 && quantityColumn !== -1 && isNaN((ele.data[quantityColumn]))){
                                                console.log(`error in row ${i} because missing data in quantity column`)
                                                if(!errorRows.includes(i)){
                                                    errorRows.push(` ${i}`)
                                                }
                                            }
                                            else if (i>0 && dolValColumn !== -1 && isNaN(this.removeDollarSign(ele.data[dolValColumn]))){
                                                console.log(`error in row ${i} because missing data in dolval column`)
                                                if(!errorRows.includes(i)){
                                                    errorRows.push(` ${i}`)
                                                }
                                            }
                                            else if (i>0 && pcntColumn !== -1 && isNaN(this.removePercentSign(ele.data[pcntColumn]))){
                                                console.log(`error in row ${i} because missing data in allocation column`)
                                                if(!errorRows.includes(i)){
                                                    errorRows.push(` ${i}`)
                                                }
                                            }
                                            else{
                                                // console.log(`success in row ${i}`)
                                                if(quantityColumn >= 0){
                                                    arr.push([ele.data[symColumn],ele.data[quantityColumn]])
                                                }else if(dolValColumn >= 0) {
                                                    arr.push([ele.data[symColumn],ele.data[dolValColumn]])
                                                }else{
                                                    arr.push([ele.data[symColumn],ele.data[pcntColumn]])
                                                    
                                                }
                                            }
                                        }
                                    })
                                    if (errorRows.length === 1){
                                        this.setState({errors: `problems in row: ${errorRows}`})
                                    }else if (errorRows.length > 1){
                                        this.setState({errors: `problems in rows: ${errorRows}`})
                                    }
                                    else{
                                        this.setState({massAddTable: arr.slice(1,arr.length), uploadSuccessful: true})
                                    }
                            }else if(symColumn !== -1){
                                console.log('only symbols')
                                console.log('e', e)
                                e.forEach((ele,i)=>{
                                    if(ele.data[symColumn].length !== 0 && i > 0){
                                        console.log('ele', ele)
                                        arr.push([ele.data[symColumn],0])
                                    }
                                })
                                console.log('arr',arr)
                                this.setState({massAddBy:'allocation', total:1000000, massAddTable: arr, noValues: true, errors:"We could not find any of the following optional categories: Quantity, Allocation, or Value, we Recommend adding one of these columns. Otherwise, Click 'Import' to import anyway"})
                            }
                            else{
                                if(symColumn === -1 ){
                                    this.setState({errors:'We could not find column: "Symbol"'})
                                }
                                else{
                                    this.setState({errors:"We could not find any of the following optional categories: Quantity, Allocation, or Value. Click 'Import' to import anyway"})
                                }
                            }
                        }}
                    >
                        <span>Drop CSV File Here or Click Browse Files</span>
                        {/* <span style={{width:'100%'}}>Click to Browse Files or Drag and Drop a .csv file </span> */}
                    </CSVReader>
                    </div>
                    <div style={{display:'flex',margin:'auto',width:'50%', marginTop: '1%'}}>
                        {this.showErrors()}
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'center', marginTop: 10}}>
                        <div style={{display:'flex', alignItems:'center', justifyContent: 'center',border:'solid', borderColor:'#3b8e8c', borderRadius: 8, padding: 10, width: '40%', cursor:'pointer', marginBottom:20}} onClick={()=>{
                            if(!this.state.errors || this.state.errors==="We could not find any of the following optional categories: Quantity, Allocation, or Value. Click 'Import' to import anyway"){
                                // console.log('this.state.massAddTable', this.state.massAddTable)
                                this.massAdd()
                                this.setState({fileImportModal:false})
                            }
                            else{
                                this.setState({errors:'Please Uploaded Corrected CSV file'})
                            }
                            }}>
                            <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                                Convert to Portfolio
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          )}
    }

    reOpt = (arr)=>{
        console.log('reopt arr', arr)
        let converted = []
        let cash = 0
        arr.forEach(ele=>{
            if(ele.symbol === 'CASH' || ele.symbol === '$$'|| ele.cashEq){
                cash+=ele.quantity
            }else{
                converted.push([ele.symbol, ele.quantity])
            }
        })
        console.log('converted')
        this.setState({massAddTable: converted,cash})
    }

    getHoldings = (id)=>{
        let cash = 0
        let arr = []
        this.state.holdings.forEach(ele=>{
            if(ele.accountId === id){
                if( ele.symbol === '$$'|| ele.cashEq){
                    cash+=ele.quantity
                }else{
                arr.push([ele.symbol, ele.quantity])
                }
            }
        })
        this.setState({massAddTable: arr, cash})
    }

    createBox = (ele)=>{
        // console.log('inside create box', ele)
        if(ele.isDwSupported){

            return (
                <div                                         
                onMouseEnter={()=>this.setState({hover:ele})} 
                onClick={()=>this.setState({addRowModal:false ,sudoPortfolioStocks: [...this.state.sudoPortfolioStocks, {...ele, allocation: 0, value: 0, shares:0 }]})} 
                onMouseEnter={()=>this.setState({hover:ele})}
                style={{width:100,height:175, marginBottom:10, marginRight:15}}
                // style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', border:'solid', borderColor:'#dddddd', borderWidth:1,backgroundColor: this.state.hover === ele?'rgb(220,220,220)'  :'rgb(243,243,243)', paddingLeft: 20, paddingRight: 20, paddingLeft:10, paddingRight:10, borderRadius: 5, marginTop: 10, cursor: 'pointer' }}
            >
                <div style={{display:'flex', cursor:'pointer', justifyContent:'center',alignItems:'center',backgroundColor:this.state.hover === ele? 'rgb(225,225,225)':'rgb(255,255,255)', borderRadius:7,overflow:'hidden', width:100, height:100,justifySelf:'flex-end',border:'solid',borderWidth:'0.25px' }}>
                {ele.imageURL === "not-found"?
                    <div style={{objectFit:'contain', width:50,height:50,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'rgb(17,17,17)',borderRadius:10 }}
                    >
                        <p style={{margin:0, fontSize:24,fontWeight:'bold', color:'rgb(255,255,255)'}}>{ele.symbol[0]}</p>
                    </div>
                    :
                    <img 
                        // src={this.checkLogo(ele.symbol)?`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`:NoImage}
                        src={ele.imageURL}
                        style={{objectFit:'contain', width:80,height:80,padding:10, }}
                    />}
                </div>
                <div style={{display:'flex', flexDirection: 'column', width:'100%', padding:10 }}>
                    <p style={{ margin: 0, textAlign: 'center', fontWeight:'bold' }}>{ele.symbol}</p>
                    {/* <p style={{ margin: 0, textAlign: 'center',overflow: 'hidden',  textOverflow: 'ellipsis' }}>{ele.company}</p> */}
                </div>
            </div>
                )
        }
    }

    customsModal = ()=>{
        let arr = []
        let arr2 = []
        if(this.state.showCustoms){
        let header = 
            <div style={{display:"flex", flexDirection:'row', width:'100%', border:'solid', borderWidth:.25, borderColor:'#ddd'}} onClick={()=>console.log(`clicked header`)}>
                <div style={{width:'10%'}}>
                    <p>&nbsp;</p>
                </div>
                <div style={{width:'45%', display:'flex',alignItems:'center',justifyContent:'flex-start',borderRight:'solid', borderWidth:.25, borderColor:'#ddd'}}>
                    <p style={{textAlign:'left'}}>
                        Portfolio Name
                    </p>
                </div>
                <div style={{width:'45%', display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                    <p style={{textAlign:'right'}}>
                        Date Created
                    </p>
                </div>
            </div>
        // arr.push(header)
        if(this.state.customPorts){
            console.log('customs', this.state.customPorts)
            Object.keys(this.state.customPorts).forEach((ele, i)=>{
                let holdings = this.state.customPorts[ele]
                arr.push(
                    <div style={{display:"flex", flexDirection:'row', width:'90%', borderBottom:'solid', borderWidth:'.25px', borderColor:'#ddd', cursor:'pointer', margin:'auto'}} >
                        <div style={{width:'10%', display:'flex', alignItems:'center' }}>
                            <input type="checkbox" onClick={()=>this.reOpt(holdings)}/>
                        </div>
                        <div style={{width:'45%', display:'flex',alignItems:'center',justifyContent:'flex-start',borderRight:'none', borderWidth:.25, borderColor:'#ddd', padding:7}}>
                           <p style={{margin:0,textAlign:'left'}}>
                               {ele}
                            </p> 
                        </div>
                        <div style={{width:'45%', display:'flex',alignItems:'center',justifyContent:'flex-end',padding:7}}>
                           <p style={{margin:0,textAlign:'right'}}>
                             {this.state.customPorts[ele][0].dateCreated}
                           </p>
                        </div>
                    </div>
                )
            }) 
        }
        if(this.props.linkedPorts){
            // arr2.push( 
            //     <div style={{display:"flex", flexDirection:'row', width:'100%', border:'solid', borderWidth:.25, borderColor:'#ddd'}} onClick={()=>console.log(`clicked header`)}>
            //         <div style={{width:'50%', display:'flex',alignItems:'center',justifyContent:'center',borderRight:'solid', borderWidth:.25, borderColor:'#ddd'}}>
            //             Insitution
            //         </div>
            //         <div style={{width:'50%', display:'flex',alignItems:'center',justifyContent:'center'}}>
            //             Account Number
            //         </div>
            //     </div>
            // )
            this.props.linkedPorts.forEach(ele=>{
                ele.accounts.forEach(innerEle=>{
                    let holdings = innerEle.holdings
                    arr2.push(
                        <div style={{display:"flex", flexDirection:'row', width:'90%', borderBottom:'solid', borderWidth:'.25px', borderColor:'#ddd', cursor:'pointer', margin:'auto'}} >
                         <div style={{width:'10%', display:'flex', alignItems:'center' }}>
                             <input type="checkbox" onClick={()=>this.reOpt(holdings)} /*onClick={()=>this.getHoldings(innerEle.accountId)}*/ style={{verticalAlign:'middle'}}/>
                         </div>
                         <div style={{width:'45%', display:'flex',alignItems:'center',justifyContent:'flex-start',borderRight:'none', borderWidth:.25, borderColor:'#ddd', padding:7}}>
                            <p style={{margin:0,textAlign:'left'}}>
                                {ele.instName}
                             </p> 
                         </div>
                         <div style={{width:'45%', display:'flex',alignItems:'center',justifyContent:'flex-end',padding:7}}>
                            <p style={{margin:0,textAlign:'right'}}>
                                {innerEle.name}
                            </p>
                         </div>
                        </div>
                    )
                })
                
            })
        }
        return (
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width: this.props.isSmallScreen?'90%' :'40%'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>My Portolios</p>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.setState({showCustoms:false})}> X </p>
                    </div>
                    <div style={{display:'flex', width:'90%',margin:'auto', marginTop:20}}>
                        <p style={{textAlign:'left',margin:0, fontSize:20}}>Created Strategies</p>
                    </div>
                    <div style={{display:'flex', width:'90%',margin:'auto', justifyContent:'space-between',marginTop:10, borderBottom:'solid', borderColor:'#dddddd',}}>
                        <p style={{textAlign:'left',margin:0}}>Portfolio Name</p>
                        <p style={{textAlign:'left',margin:0}}>Date Created</p>
                    </div>
                    <div style={{display:'flex', margin:'auto', flexDirection:'column', width:'100%', marginBottom: 20, maxHeight:200, overflowY:'scroll'}}>
                        {arr}
                    </div>
                    <div style={{display:'flex', width:'90%',margin:'auto'}}>
                        <p style={{textAlign:'left',margin:0,fontSize:20}}>Linked Portfolios</p>
                    </div>
                    <div style={{display:'flex', width:'90%',margin:'auto', justifyContent:'space-between',marginTop:10,borderBottom:'solid', borderColor:'#dddddd',}}>
                        <p style={{textAlign:'left',margin:0}}>Institution</p>
                        <p style={{textAlign:'left',margin:0}}>Account Number</p>
                    </div>
                    <div style={{display:'flex', margin:'auto', flexDirection:'column', width:'100%', marginBottom: 20,maxHeight:200, overflowY:'scroll'}}>
                        {arr2}
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'center', marginTop: 10, marginBottom:20}}>
                        <div style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, padding: 10, cursor:'pointer'}} onClick={()=>{
                            if(!this.state.errors && JSON.stringify(this.state.massAddTable) !== JSON.stringify([["",""],["",""]])){
                                // console.log('this.state.massAddTable', this.state.massAddTable)
                                this.massAdd()
                                this.setState({showCustoms:false})
                            }
                            }}>
                            <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                                {JSON.stringify(this.state.massAddTable) !== JSON.stringify([["",""],["",""]])?'Re-Construct Portfolio':'Select a Portfolio Above'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          )}
    }

    setTotal = (total)=>{
        this.setState({total})
    }

    massAddModal = ()=>{
        let total = 0
        if(this.state.showMassAdd){
            let {massAddTable} = this.state
            let table 
            if (this.state.massAddBy === 'allocation'){
                table = [
                    <div style={{border:'none', display:'flex',justifyContent:'center',alignItems:'center',minWidth: 200, width:'100%' }}>
                        <div style={{borderRight:'none', width: '100%',}}>
                            <p style={{margin:0, textAlign:'center', fontWeight: 'bold'}}>Total Portfolio Value (USD)</p>
                        </div>
                        <div style={{width: '100%',}}>
                            <input style={{width:'100%', border:'none', textAlign:'center'}} type="number" value={this.state.total} onChange={(e)=> this.setTotal(parseFloat(e.target.value))}/>
                        </div>
                    </div>
                    ,
                    <div style={{border:'none',width: '100%', display:'flex', minWidth: 200}}>
                    <div style={{width:'100%', padding: 5, display:'flex', justifyContent:'center',alignItems:'center', borderRight:'none'}}>
                        <p style={{margin:0, fontWeight: 'bold'}}>Stock Symbol</p>
                    </div>
                    <div style={{width:'100%', padding: 5, display:'flex', justifyContent:'center',alignItems:'center', }}>
                        {this.state.massAddBy === 'quantity' ? <p style={{margin:0, fontWeight: 'bold'}}># of Shares</p>:null}
                        {this.state.massAddBy === 'allocation' ? <p style={{margin:0, fontWeight: 'bold'}}>Percentage</p>:null}
                        {this.state.massAddBy === 'dollarValue' ? <p style={{margin:0, fontWeight: 'bold'}}>Dollar Value</p>:null}
                    </div>
                </div>
                ]
            }else{
                table = [
                    <div style={{border:'none',width: '100%', display:'flex', minWidth: 200}}>
                        <div style={{width:'100%', padding: 5, display:'flex', justifyContent:'center',alignItems:'center', borderRight:'none'}}>
                            <p style={{margin:0, fontWeight: 'bold'}}>Stock Symbol</p>
                        </div>
                        <div style={{width:'100%', padding: 5, display:'flex', justifyContent:'center',alignItems:'center', }}>
                            {this.state.massAddBy === 'quantity' ? <p style={{margin:0, fontWeight: 'bold'}}># of Shares</p>:null}
                            {this.state.massAddBy === 'allocation' ? <p style={{margin:0, fontWeight: 'bold'}}>Percentage</p>:null}
                            {this.state.massAddBy === 'dollarValue' ? <p style={{margin:0, fontWeight: 'bold'}}>Dollar Value</p>:null}
                        </div>

                    </div>
                ]
            }
            massAddTable.forEach((ele, idx)=>{
                table.push(
                    <div style={{border:'none', display:'flex',justifyContent:'center',alignItems:'center',minWidth: 200, width:'100%', marginBottom:5 }}>
                        <div style={{borderRight:'none', width: '50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <input style={{width:'50%', textAlign:'center'}}type="text" placeholder="Stock Symbol" value={ele[0]} onChange={(e)=>this.setTableCell(e, idx, 0)}/>
                        </div>
                        <div style={{width: '50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <input style={{width:'50%', textAlign:'center'}} type="number" value={ele[1]} onChange={(e)=>this.setTableCell(e, idx, 1)}/>
                        </div>
                    </div>
                )
            })
            return (
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                    <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%'}}>
                        <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                            <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Add Multiple Securities</p>
                            <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.setState({showMassAdd:false})}> X </p>
                        </div>
                        <div style={{marginBottom:20,width:'100%',borderBottom: 'solid', borderWidth:'.5px', borderColor: '#dddddd'}}>
                           {this.addMethod()}
                        </div>
                        <div style={{display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', }}>
                            {table}
                        </div>
                        <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center', width: '90%',marginBottom:20}}>
                            <div style={{display:'flex', alignItems:'center', justifyContent: 'center', marginTop: 10, flexDirection:'row', marginRight:10, }}>
                                <div style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20, width: '100%', cursor:'pointer'}} onClick={()=>this.massAdd()}>
                                    <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                                        Add All
                                    </p>
                                </div>
                            </div>
                            <div style={{display:'flex', alignItems:'center', justifyContent: 'center', marginTop: 10}}>
                                <div style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#f85e25', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20, width: '100%', cursor:'pointer'}} onClick={()=>this.setState({massAddTable:[["",""],["",""]]})}>
                                    <p style={{margin: 0, fontWeight: 'bold', color: '#f85e25'}}>
                                        Clear All
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              )
        }
    }

    // convertArrayToObject = (array, key) => { // no longer necessary
    //     const initialValue = {};
    //     return array.reduce((obj, item) => {
    //       return {
    //         ...obj,
    //         [item[key]]: item,
    //       };
    //     }, initialValue);
    //   };


    findCashValue = (total)=>{
       if(this.state.cash > 0){
            return parseFloat(this.state.cash)/parseFloat(total)*100
        }else{
            return 0
        }
    }
    optimizeWithConstraints = ()=>{
        this.checkNoValues()
        let total = this.state.cash
        if (this.state.cash==0){
            total = 10000;
        }
        var max = 0;
        var alloc_cash_max = 0.05;
        var stocks_name = "";
        // console.log("max",this.state.sudoPortfolioStocks)
        this.state.sudoPortfolioStocks.forEach(ele=>{
            if (ele.price>max){
                max = ele.price
                stocks_name = ele.symbol
            }
        })
        // console.log(max)
        // console.log(total)
        // console.log(1.05*max/total)
        if(2*max/total >0.05){
            alloc_cash_max = 2*max/total
        }
        // console.log("Cash",alloc_cash_max)
        let ssc = this.singleStockConstraint()/100
        let toObj = this.arrayToObject(this.state.sudoPortfolioStocks)
        let ob = {BIL:{"value":[0,0],"type":"Cash","constraint":[null,null],"unit_price":91.52}}
        // if (ob["BIL"]["unit_price"]>max){

        // }
        this.state.sudoPortfolioStocks.forEach(ele=>{
            // NEED OPTIMIZATION FIX
            // console.log('ele before toss', ele)
            // if(!ele.needOptimization){
            //     console.log('Throw out', ele.symbol)
            //     total += parseFloat(ele.value)
            //     let cash = this.state.cash + parseFloat(ele.value)
            //     this.setState({cash,excluded:[...this.state.excluded,ele.symbol]})
            // }else{
            //     console.log('Keep', ele.symbol)
                if(ele.symbol === '$$'|| ele.cashEq){
                    console.log('in CASH sym is: ',ele.symbol)
                    total += parseFloat(ele.value)
                }
                else if(ele.type == 'Equity'){
                    ob[ele.symbol]={
                        "value":[parseFloat(ele.value),parseFloat(ele.shares)],
                         "type":ele.type,
                         "constraint": [null,ssc], 
                         "unit_price":ele.priceUSD
                    }
                    total += parseFloat(ele.value)
                }else{
                    ob[ele.symbol]={
                        "value":[parseFloat(ele.value), parseFloat(ele.shares)], 
                        "type":'FixedIncome', 
                        "constraint": [null,null],
                        "unit_price":ele.priceUSD
                    }
                    
                }
            // }
        })
        let userInputs = { '$$':{allocation:this.state.noValues?100 :this.findCashValue(total), shares:this.state.noValues?this.state.total:this.state.cash}, 'BIL':{allocation:'0', shares:'0'} , ...toObj, }
        let prevSet = [...this.state.sudoPortfolioStocks]
        // if (fetchable){
            // console.log("portfolio",ob)
            let body = {
                "id": "testMessage-YVC4jC-p3Ciyx",
                "portfolio": ob,
                "cash": this.state.noValues ? 10000 :parseFloat(this.state.cash),
                "constraint": {
                    "Return": null, 
                    "dispersion": null, 
                    "Equity": [null,null],
                    // "FixedIncome": [null,null],
                    // "Cash": [null,null]
                    // "Equity": [0.2,0.8],
                    "FixedIncome": [null,0.24],
                    "Cash": [0.005,0.05],
                    "cvar": null,
                    "dividend": null
                },
                 "secret": "nMoNbIARwbuW5Pj9ihodcqCOwAF4ST3yth3fHRAm28tWVa3DiBnhmqzDD5oS",
                }
            console.log('optimizerBody:',body)
            let url = `${ENDPOINT}/risk-optimization/get-dashboard-optimization-no-round`
            // if (total > 1000){
            //     url = '${ENDPOINT}/risk-optimization/get-dashboard-optimization'
            // }
            console.log('about to fetch')
            fetch(url, {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body)
            })
            // .then(resp=>console.log(resp.body))
            .then((res) => res.json())
            .then(responseJson=>{
                // console.log("userINput",userInputs,prevSet)
                this.props.optimizeButton(responseJson, userInputs, prevSet, this.state.excluded, this.state.noValues ? 10000 :parseFloat(this.state.cash))
                // console.log('responseJson:',responseJson)
            })
            .then(()=>this.setState({showing:false}))
            // .catch(err =>console.log('fetch error:', err))
        // }
        // else{
        //     this.setState({errors: 'zeroShares', showErrorModal:true})
        // }
    }

    optimizeNoConstraints = ()=>{
        let fetchable = true
        let toObj = this.arrayToObject(this.state.sudoPortfolioStocks)
        let ob = {}
        this.state.sudoPortfolioStocks.forEach(ele=>{
            if(ele.shares === 0){
                fetchable = false
            }
            ob[ele.symbol]=[parseFloat(ele.value), parseFloat(ele.shares)]
        })
        if (fetchable){
            let body = {
                "id": "testMessage-YVC4jC-p3Ciyx",
                "portfolio": ob,
                "cash": 0,
                "secret": "nMoNbIARwbuW5Pj9ihodcqCOwAF4ST3yth3fHRAm28tWVa3DiBnhmqzDD5oS"
            }
            fetch(`${ENDPOINT}/risk-optimization/get-dashboard-optimization`, {
                method: "POST",
                headers: { 
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body)
            })
            // .then(resp=>console.log(resp.body))
            .then((res) => res.json())
            .then(responseJson=>this.props.optimizeButton(responseJson, toObj))
            .catch(err =>console.log('fetch error:', err))
        }
        else{
            this.setState({errors: 'zeroShares', showErrorModal:true})
        }
    }

    

    setSearch = (symbol)=>{
            this.setState({ isMomDataLoading: true });
            // console.log(searchMomentumText);
            // let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${symbol}`;
            let url = `${SearchEndpoint}/ticker?search=${symbol}`;

            fetch(url, {
                "Access-Control-Allow-Headers": {
                    "Content-Type": "application/json",
                    "Cache-Control": "no-cache",
                    "Access-Control-Request-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*",
                },
            })
                .then((res) => res.json())
                .then((responseJson) => {
                    var data = responseJson.content;
                    // console.log("SEARCHING RESULT: " , data);

                    for (var i = 0; i < data.length; i++) {
                        var item = data[i];
                        var color = "";
                        if (item.rar) {
                            switch (item.rar) {
                                case "H":
                                    color = "#dc143c";
                                    break;
                                case "M":
                                    color = "#fcac0c";
                                    break;
                                default:
                                    color = "#00A99D";
                            }
                        } else {
                            color = "rgb(0,0,0)";
                        }

                        item.symbolColor = color;
                        data[i] = item;
                    }
                    this.setState({
                        isMomDataLoading: false,
                        momDataSource: data,
                        searchMomentumText:symbol
                    });
                })
                .catch((err) => {
                    this.setState({ isMomDataLoading: false });
                    console.log(err);
                });
    }

    setAllocation = (e, item)=>{
        let allocation = e.target.value
        let value = (this.state.total * parseFloat(e.target.value) / 100 ).toFixed(2)
        let shares = (value / item.price).toFixed(2)
        let updated = {...item, allocation, value, shares}

        let sudoPortfolioStocks = this.state.sudoPortfolioStocks.map(ele=>{
            if(ele.symbol === item.symbol){
                return updated
            }
            else{
                return ele
            }
        })
        this.setState({sudoPortfolioStocks})
    }

    setShares = (e, item)=>{
        console.log('setting shares')
        let sudoPortfolioStocks
        let updated 
        let shares
        let total = this.state.total
        if (isNaN(e.target.value) || e.target.value < 0 ) {
            console.log('negative or NaN')
        }
        else{
            console.log('NOT negative or NaN')
            if (e.target.value === "") {
                shares = e.target.value
                updated = {...item, shares}
                sudoPortfolioStocks = this.state.sudoPortfolioStocks.map(ele=>{
                    if(ele.symbol === item.symbol){
                        return updated
                    }
                    else{
                        return {...ele}
                    }
                })
            }
            else{
                shares = e.target.value
                // console.log('shares', shares)
                // console.log('item', item)
                // console.log('item.priceUSD', item.priceUSD)
                let value = (parseFloat(shares) * item.priceUSD).toFixed(2)
                // console.log('value', value)
                total = parseFloat(this.state.cash)
                // console.log('total', total)
                this.state.sudoPortfolioStocks.forEach(ele=>{
                    if(ele.symbol === item.symbol){
                        total += parseFloat(value)
                    }
                    else{
                        total += parseFloat(ele.value)
                    }
                })
                let allocation = isNaN(value/total*100)?((0).toFixed(2)):(value/total*100).toFixed(2)
                updated = {...item, allocation, value, shares}
                // console.log('updated', updated)
                sudoPortfolioStocks = this.state.sudoPortfolioStocks.map(ele=>{
                    if(ele.symbol === item.symbol){
                        return updated
                    } 
                    else{
                        return {...ele, allocation: isNaN(ele.value/total*100)?((0).toFixed(2)):(ele.value/total*100).toFixed(2) }
                    }
                })
            }
        this.setState({sudoPortfolioStocks, total})
        }
    }

    setValue = (e, item)=>{

        if (isNaN(e.target.value) || e.target.value < 0 ) {
            console.log('...');
        }
        else{
            let updated
            let value 
            let total
            if (e.target.value === ""){
                value = ""
                updated = {...item, value,}
            }else{
                value = parseFloat(e.target.value)
                total = parseFloat(this.state.cash)
                this.state.sudoPortfolioStocks.forEach(ele=>{
                    if(ele.symbol === item.symbol){
                        total += value
                    }
                    else{
                        total += ele.value
                    }
                })
                let allocation =  value / total * 100
                let shares = (value / item.price).toFixed(2)
                updated = {...item, allocation, value, shares}
            }
            let sudoPortfolioStocks = this.state.sudoPortfolioStocks.map(ele =>{
                if(ele.symbol === item.symbol){
                    return updated
                }
                else{
                    return(
                        {
                            ...ele,
                            allocation: e.target.value === "" ? ele.allocation : ele.value / total * 100
                        }
                    ) 
                }
            })
            this.setState({sudoPortfolioStocks, total})
        }
    }
    
    setTotal = (total) =>{
        let sudoPortfolioStocks = this.state.sudoPortfolioStocks.map(ele=>{
            return(
                {
                    ...ele,
                    value: (ele.allocation * total / 100).toFixed(2),
                    shares: ((ele.allocation * total / 100)/ ele.price).toFixed(2),
                }
            )
        })
        this.setState({total, sudoPortfolioStocks})
    }

    displayCard = (item)=>{
        if(item){
        return (
            <div style={{ }}>
              <div
                style={{
                  paddingLeft: 25,
                  paddingRight: 25,
                  paddingTop: 10,
                  paddingBottom: 10,
                  width: 250,
                  borderRadius: 8,
                  margin: 10,
                  backgroundColor: "#F9F9F9",
                  textDecoration: "none",
                //   minHeight: 350,
                  borderRadius:10,
                //   border: 'solid',
                //   borderWidth:'.5px'
                //   height:500,
                }}
              >
                <Row
                  style={{
                    // backgroundColor: this.titleColor(item.direction),
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 8,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 8,
                    textDecoration: "none",
                    
                  }}
                >
                  <h4
                    style={{
                    //   color: "#FFF",
                      fontWeight: "bold",
                      marginTop: 4,
                      width: "",
                      textAlign: "left",
                      textDecoration: "none",
                    }}
                  >
                    {item.symbol}
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 44,
                      textDecoration: "none",
                    }}
                  >
                    <h6
                      style={{
                        borderRadius: 5,
                        color: 'rgb(255,255,255)',
                        // color: this.titleColor(item.direction),
                        marginTop: 5,
                        backgroundColor: "#FFF",
                        paddingRight: 10,
                        paddingLeft: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                        textDecoration: "none",
                        backgroundColor:'#3b8e8c',
                      }}
                    >
                      {item.price
                        ? `${item.price.toFixed(2) }`
                        : "00.00"}
                    </h6>
                  </div>
                </Row>
                <hr/>
                <div style={{display:'flex', justifyContent:'space-between', height:56}}>
                    <h5
                    style={{
                        fontWeight: "bold",
                        textAlign: "left",
                        textDecoration: "none",
                        color: "rgb(17,17,17)",
                        minHeight: 48,
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis',
                    }}
                    >
                    {item.company}
                    </h5>
                    <img
                        src={this.getArrow(item.direction)}
                        style={{height:30}}
                    />
                </div>
                
                <div style={{display:'flex',justifyContent:'space-between', alignItems:'center', height: 36, paddingLeft: 10, paddingRight:10, borderRadius: 5,}}>
                  <div>
                    <p style={{margin:0, float:'left', fontSize:12, fontWeight:"bold"}}>ALLOCATION</p>
                  </div>
                  <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center',backgroundColor: 'rgb(250,250,253)',width:'45%'}}>
                    {/* <input style={{ width: 50, border: 'none', backgroundColor: 'transparent', fontWeight: 'bold', boxShadow:'none',textAlign:'right', outline:'none' }} onChange={(e) => this.setAllocation(e, item)} type="text" value={item.allocation}/> */}
                    {/* <p style={{width: 50, fontWeight:'bold',}}></p> */}
                    <p style={{fontWeight:'bold', margin:0}}>{item.allocation}%</p> 
                  </div>
                </div>
                <div style={{display:'flex',justifyContent:'space-between', alignItems:'center', height: 36, paddingLeft: 10, paddingRight:10, borderRadius: 5,marginTop: 10}}>
                  <div>
                    <p style={{margin:0, float:'left', fontSize:12, fontWeight:"bold"}}>VALUE</p>
                  </div>
                  <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center',backgroundColor: 'rgb(250,250,253)',width:'45%'}}>
                    <p style={{fontWeight:'bold', margin:0}}>${item.value}</p> 
                    {/* <input style={{ width: 75, border: 'none', backgroundColor: 'transparent', fontWeight: 'bold', boxShadow:'none', textAlign:'right', outline:'none' }} onChange={(e) => this.setValue(e, item)} type="text" value={item.value}/> */}
                  </div>
                </div>

                <div style={{display:'flex',justifyContent:'space-between', alignItems:'center', height: 36, paddingLeft: 10, paddingRight:10, borderRadius: 5,marginTop: 10}}>
                  <div style={{width:'30%'}}>
                    <p style={{margin:0, float:'left', fontSize:12, fontWeight:"bold"}}>SHARES</p>
                  </div>
                  <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center', width:'45%',backgroundColor: 'rgb(250,250,253)' }}>
                    <input style={{ width: '100%', border: 'none', backgroundColor: 'transparent', fontWeight: 'bold', boxShadow:'none', textAlign:'right', outline:'none' }} onChange={(e) => this.setShares(e, item)} type="text" value={item.shares}/>
                  </div>
                </div>

                {/* <div style={{display:'flex',justifyContent:'space-between', alignItems:'center', height: 45, paddingLeft: 10, paddingRight:10, borderRadius: 5,marginTop: 10}}>
                  <div style={{display:'flex', justifyContent:'left', alignItems:'center', flexDirection: 'column'}}>
                    <input style={{ width: 50, border: 'none', backgroundColor: 'transparent', fontWeight: 'bold', boxShadow:'none', margin:0, textAlign:'center' }} onChange={(e) => this.setState({ shares: e.target.value})} type="text" value={item.shares}/>
                    <p style={{margin:0, marginTop: -5}}>shares</p> 
                  </div>
                  <div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent:'center', border:'solid', borderWidth:'0.25px', borderRadius: 6}}>
                      <div style={{cursor:'pointer',paddingLeft: 10,paddingRight: 10, paddingTop: 5, paddingBottom:5, borderRight:'solid', borderWidth:'0.25px'}} onClick={()=>this.setState({shares: this.state.shares + 1})}>
                        <p style={{margin: 0, fontWeight:'bold'}}>
                          +
                        </p>
                      </div>
                      <div style={{ cursor:'pointer',paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5 }} onClick={this.state.shares > 0? () => this.setState({ shares: this.state.shares - 1 }):null}>
                        <p style={{margin: 0, fontWeight:'bold'}}>
                          -
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div style={{display:'flex', alignItems:'center', justifyContent: 'center', marginTop: 10}}>
                  <div
                    // style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgb(253,234,234)', borderRadius: 10, cursor: 'pointer', marginLeft: 2, width: '80%' }}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#ee5e68', borderRadius: 2, cursor: 'pointer', marginLeft: 2, width: '90%', padding:5 }}
                    onClick={() => this.sudoed(item) ? this.removeFromSudo(item) : this.addSudo(item)}
                  >
                    {/* <p style={{ margin: 0, color:'rgb(187,58,60)', fontWeight:'bold' }}>remove</p> */}
                    <p style={{ margin: 0, color:'rgb(255,255,255)', fontWeight:'bold', }}>Remove</p>
                  </div>
                </div>
              </div>
            </div>
          )}
    }

    watchlisted = (item) => {
        const { myWatchlist } = this.props
        let watchlisted = false
        Object.keys(myWatchlist).forEach(ele => {
            if (myWatchlist[ele].symbol === item.symbol) {
                watchlisted = true
            }
        })
        return watchlisted
    }

    sudoed = (item) => {
        const { sudoPortfolioStocks } = this.state
        let swtch = false
        sudoPortfolioStocks.forEach(ele=>{
            if(ele.symbol === item.symbol){
                swtch = true
            }
        })
        return swtch
    }

    addToSudo = (item) => {
        let updatedItem = {...item, allocation: 0, value: 0, shares:0}
        let sudoPortfolioStocks= [...this.state.sudoPortfolioStocks, updatedItem]
        this.setState({
            sudoPortfolioStocks
        })
    }

    removeFromSudo = (item) => {
        let showSteps = false
        let step = this.state.step
        let sudoPortfolioStocks = this.state.sudoPortfolioStocks.filter(ele => ele.symbol !== item.symbol)
        let total = parseFloat(this.state.cash)
        sudoPortfolioStocks.forEach(ele=>{
            total += parseFloat(ele.value)
        })
        let x = sudoPortfolioStocks.map(ele=>{
            let ob = {...ele, allocation: (ele.value/total*100).toFixed(2)}
            return ob
        })
        if (x.length === 0){
            showSteps = true
            step = 0
        }
        this.setState({ sudoPortfolioStocks: x , total, showSteps, step})
    }

    getArrow = (direction) => {
        switch (direction) {
          case -1:
            return downArrow;
          case 1:
            return upArrow;
          case 2:
            return strongUpArrow;
          case -2:
            return strongDownArrow;
          default:
            return flatArrow;
        }
      };

    titleColor = (direction)=>{
        if (direction == 0) {
          // return "#a8a6a5"
          return "rgb(84, 83, 82)"
        }
        else if (direction > 0) {
          return 'rgb(43,174,43)'
        } 
        else {
          return "#e31111"
        }
      }

    individuleCompanyCard = (data, location) => {
        return (
            <div
                style={{
                    textDecoration: "none",
                    display: 'flex',
                    width: '100%',
                    
                    flexWrap:'wrap'
                }}
            >
                {location !== 'wl'?
                <div style={{ height: "100%",}}>
                    <div
                        style={{
                            paddingLeft: 25,
                            paddingRight: 25,
                            paddingTop: 10,
                            width: 250,
                            borderRadius: 8,
                            margin: 10,
                            // backgroundColor: "rgb(255,255,255)",
                            textDecoration: "none",
                            height: 325,
                            zIndex: -1,
                            // border: 'solid',
                            // borderWidth: '.5px',
                            // overflowY: this.props.isSmallScreen? 'hidden':'scroll', 
                            // overflowX: this.props.isSmallScreen? 'scroll':'hidden'
                        }}
                    >
                         
                            <div style={{width:'100%', display:'flex',justifyContent:'center', alignItems:'center', marginBottom:20,marginTop:20}}>
                                <img src={Explore} style={{width:'30%', height:'auto'}}/>
                            </div>
                        <Row
                            style={{
                                paddingLeft: 10,
                                paddingRight: 10,
                                borderRadius: 8,
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                marginBottom: 8,
                                textDecoration: "none",
                            }}
                            onClick={this.state.searchMomentumText === 'enter a symbol' ?()=>this.setState({searchMomentumText:''}):null}
                        >
                            
                            <input style={{ width: '100%', border: 'none', backgroundColor: 'transparent', fontWeight: 'bold', boxShadow: 'none', textAlign: 'center', outline: 'none',}}placeholder={"Enter symbol here"}  type="text" value={this.state.searchMomentumText} onChange={(event) => {
                                let searchMomentumText = event.target.value;
                                this.setState({ searchMomentumText, selectedPreset: null }, () => {
                                    const { isSearching } = this.state;
                                    if (isSearching) {
                                        return;
                                    } else {
                                        this.setState({ isSearching: true });
                                        setTimeout(() => {
                                            this.setState(
                                                { isSearching: false },
                                                this.onSubmitSearchMomentum
                                            );
                                        }, 2000);
                                    }
                                });
                            }} />
                        </Row>
                        <div style={{ display: 'flex', flexDirection: 'column',overflowY:'scroll', height:'60%', }}> 
                            {this.state.searchMomentumText.length > 0? 
                            this.state.momDataSource.map(ele =>
                                <div onClick={()=>this.setState({sudoPortfolioStocks: [...this.state.sudoPortfolioStocks, {...ele, allocation: 0, value: 0, shares:0 }]})} style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: 'rgb(255,255,255)', paddingLeft: 10, paddingRight: 10, borderRadius: 5, marginTop: 10, cursor: 'pointer' }}>
                                    <div style={{display:'flex', flexDirection: 'column',}}>
                                        <p style={{ margin: 0, float: 'left', fontWeight:'bold' }}>{ele.symbol}</p>
                                        <p style={{ margin: 0, float: 'left' }}>{ele.company}</p>
                                    </div>
                                </div>
                            ):null
                            }

                        </div>
                    </div>
                </div>:null}
                {location !== 'wl' && this.state.sudoPortfolioStocks.length === 0 ? 
                    <div style={{ marginLeft:4}}>
                        <Steps loading={this.state.loading} toWm={this.props.toWm} 
                        // isSmallScreen={this.props.isSmallScreen}
                        /> 
                    </div>
                : null}
                {data.map((item, index) => {
                    if (location === 'wl'){
                        return (
                            <SingleCompanyCard dashboard={true} sudoed={this.sudoed(item)} removeSudo={this.removeFromSudo} addSudo={this.addToSudo} remove={this.props.removeWl} add={this.props.addWl} watchlisted={this.watchlisted(item)} user={this.props.user} remove={this.remove} editMode={this.state.editMode} item={item} index={index} />
                        );
                    }else{
                        return(
                            this.displayCard(item)
                            // <SingleCompanyCard construct={true} sudoed={this.sudoed(item)} removeSudo={this.removeFromSudo} addSudo={this.addToSudo} remove={this.props.removeWl} add={this.props.addWl} watchlisted={this.watchlisted(item)} user={this.props.user} remove={this.remove} editMode={this.state.editMode} item={item} index={index} />
                        )
                    }
                })}
            </div>
        );
    };

    showTopTickers = () => {
        let data
        let mapped = []
        const { searchMomentumText } = this.state
        const { myWatchlist } = this.props
        if (this.state.searchMomentumText.length > 0) {
            data = this.state.momDataSource
        } else {
            data = myWatchlist
        }
        if(this.props.isSmallScreen){
            data.forEach(ele=>{
                mapped.push(
                    <div 
                    onClick={()=>this.setState({sudoPortfolioStocks: [...this.state.sudoPortfolioStocks, {...ele, allocation: 0, value: 0, shares:0 }]})}
                    style={{display:'flex', padding: 8, borderBottom:'solid', borderColor: '#dddddd'}}>
                        <div style={{width:'20%'}}>
                            <p style={{margin:0}}>{ele.symbol}</p>
                        </div>
                        <div style={{width:'60%',  }}>
                            <p style={{whiteSpace: 'nowrap', overflow: 'hidden',  textOverflow: 'ellipsis',margin:0}}>{ele.company}</p>
                        </div>
                        <div style={{width:'20%'}}>
                            <p style={{margin:0}}>{ele.priceUSD ? ele.priceUSD.toFixed(2):'$00.00'}</p>
                        </div>
                    </div>
                )
            })
            return(
                <div style={{width:'100%', display:'flex', flexDirection:'column'}}>
                    <p style={{ color: 'rgb(120,120,120)', margin: 0, marginTop: 5, fontWeight: 'bold' }}>
                        {searchMomentumText.length > 0 ? `searching for ${searchMomentumText}` : 'my watchlist'}
                    </p>
                    <div style={{display:'flex',flexDirection:'column', width:'100%', height: 200, overflowY:'scroll'}}>
                        {mapped}
                    </div>
                </div>
            )
        }else{
            return (
                null
                // <Card
                //     style={{
                //         width: "100%",
                //         marginTop: 20,
                //         height: 300,
                //         overflowY: 'scroll', 
                //         overflowX: 'auto', 
                //         flexWrap: 'nowrap',
                //         backgroundColor:"transparent !important"
                //     }}
                // >
                //     <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                //         {this.state.shownList === 'My Watchlist' ? <div style={{ width: '20%' }} /> : null}
                //         <p style={{ color: 'rgb(120,120,120)', margin: 0, marginTop: 5, fontWeight: 'bold' }}>
                //             {searchMomentumText.length > 0 ? `searching for ${searchMomentumText}` : 'my watchlist'}
                //         </p>
    
                //     </div>
    
                //     <div style={{}}>
                //         {this.individuleCompanyCard(data, 'wl')}
                //     </div>
                    
                // </Card>
            );
        }
    }


    showBottomTickers = () => {
        const { searchMomentumText } = this.state
        let data = this.state.sudoPortfolioStocks
        const { showing } = this.state;
        return (
            <Card
                style={{
                    width: "100%",
                    marginTop: 20,
                    // height: 500,
                    overflowY: 'hidden', 
                    overflowX: 'auto', 
                    flexWrap: 'nowrap',
                    paddingTop:10,
                    border:'none',
                    backgroundColor:"transparent"
                    // borderWidth: 0
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%', }}>
                    {/* <div style={{display:'flex',height:'100%', justifyContent:'center',alignItems:'center',}} onClick={() => this.setState({showSteps:true})}>
                        <div style={{paddingLeft: 20, paddingRight: 20, paddingTop:7, paddingBottom:7, borderRadius: 8, border:'solid', borderColor:'#3b8e8c',backgroundColor:'#3b8e8c', fontSize: 'smaller', minWidth: 0, display:'flex', alignItems:'center',justifyContent:'center', marginRight:15, cursor:'pointer' }}>
                            <p style={{ color: 'rgb(255,255,255)', margin: 0, fontWeight: 'bold', fontSize: 18 }}>
                                Get Started
                            </p> 
                        </div>
                    </div> */}
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', flexDirection:'column'  }} >
                            {/* <div style={{display:'flex',height:'100%', justifyContent:'center',alignItems:'center',}} onClick={() => this.setState({dropDown:!this.state.dropDown})}>
                                <div style={{paddingLeft: 20, paddingRight: 20, paddingTop:7, paddingBottom:7, borderRadius: 8, border:'solid', fontSize: 'smaller', minWidth: 0, display:'flex', alignItems:'center',justifyContent:'center' }}>
                                    <p style={{ color: 'rgb(17,17,17)', margin: 0, fontWeight: 'bold', fontSize: 18 }}>
                                     Import List
                                    </p> 
                                </div>
                            </div> */}
                        {/* {this.state.dropDown? null:
                            <div style={{
                                display: 'inline-block',
                                position: 'absolute',
                                backgroundColor: '#f1f1f1',
                                backgroundColor:'rgb(255,255,255)',
                                // minWidth: '160px',
                                width:'25%',
                                boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                                zIndex: 1,
                                marginTop: 190,
                                borderRadius:8
                                }}>
                                <div style={{display:'flex', flexDirection:'column', zIndex:999, border: 'none', borderWidth:0.25, borderRadius:8, }}>
                                    <div style={{display:'flex', justifyContent:'center',alignItems:'center', borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%'}} onClick={() => this.setState({showWatchlist: !this.state.showWatchlist, searchMomentumText: '', dropDown:!this.state.dropDown})}>
                                        <p style={{margin:0}}>
                                            From Watchlist
                                        </p>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'center',alignItems:'center',borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%' }} onClick={() => this.setState({showMassAdd: true, searchMomentumText: '', dropDown:!this.state.dropDown})}>
                                        <p style={{margin:0}}>
                                            Multiple Stocks at a time
                                        </p>
                                    </div>
                                    {Object.keys(this.props.customPorts).length > 0 ||  this.props.linkedPorts.length > 0 ?
                                    <div style={{display:'flex', justifyContent:'center',alignItems:'center',borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%' }} onClick={() => this.setState({showCustoms: true, searchMomentumText: '', dropDown:!this.state.dropDown})}>
                                        <p style={{margin:0}}>
                                            Re-Optimize a Portfolio
                                        </p>
                                    </div>:null
                                    }
                                    <div style={{display:'flex', justifyContent:'center',alignItems:'center',borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%' }} onClick={() => this.setState({fileImportModal: true, searchMomentumText: '', dropDown:!this.state.dropDown})}>
                                        <p style={{margin:0}}>
                                            Import From a File
                                        </p>
                                    </div>
                                    <div style={{display:'flex', justifyContent:'center',alignItems:'center',borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%', borderRadius:8 }} onClick={() => this.setState({dropDown:!this.state.dropDown, cashModal:true})} >
                                        <p style={{margin:0}}>
                                            Add Unallocated Cash
                                        </p>
                                    </div>
                                </div>
                            </div>
                        } */}
                    </div>
                    {this.state.sudoPortfolioStocks.length < 2? null:
                     <div style={{ border:'solid', borderColor:'#3b8e8c', borderRadius: 8,paddingLeft: 20, paddingRight: 20, paddingTop:7, paddingBottom:7, cursor:'pointer', display:'flex',justifyContent:'center', alignItems:'center', marginLeft:15}} onClick={this.state.sudoPortfolioStocks.length < 2 ? null :()=> {this.setState({ showing:false });this.optimizeWithConstraints()}}>
                         { showing ? 
                            <p style={{ color: '#3b8e8c', margin: 0,  fontWeight: 'bold', fontSize: 18 }}>
                                Optimize
                            </p> 
                        : 
                            <Spinner animation="border" id="spinner" variant="dark" role="status" >
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        }
                    </div>
                    
                    }
                    {/* <div style={{ width: '20%', marginTop: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 10, paddingLeft: 5, paddingRight: 5, borderRadius: 8, border:'solid', borderWidth: '.25px', fontSize: 'smaller' }}>
                        <p style={{margin:0, fontWeight: 'bold',  }}>Portfolio Value ${this.state.total.toFixed(2)}</p>
                        <input style={{ width: 50, border: 'none', fontWeight: 'bold'  }} onChange={(e) => this.setTotal(e.target.value)} type="text" value={this.state.total}/> 
                    </div> */}
                    {/* <div style={{ width: '20%', marginTop: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                        <p style={{ fontWeight: 'bold', backgroundColor: brandColor, padding: 10, borderRadius: 8 }} onClick={() => console.log('Optimizing')}>Optimize</p> 
                    </div> */}
                </div>
                {/* <div style={{display:'flex', justifyContent: 'space-around', paddingTop: 10,paddingLeft: 10, paddingRight: 10, paddingBottom: 0, marginBottom:0 }}>
                <p style={{ margin:0, fontWeight: 'bold',color: '#a8a6a5'}}> Trending Even</p>
                <p style={{ margin:0, fontWeight: 'bold',color: '#25d09c'}}> Trending Up</p>
                <p style={{ margin:0, fontWeight: 'bold',color: '#e31111'}}> Trending Down</p>
            </div>*/}
                <div style={{width:'100%', backgroundColor:'#f1f1f1', borderRadius:8, marginTop:10,marginBottom:10}}>
                    {this.objective()}
                    {this.objectiveDescription()}
                </div>
                {/* <div style={{width:'100%'}}>
                    {this.riskTolerance()}
                    {this.toleranceDescription()}
                </div> */}
                <div style={{}}>
                    {/* {this.individuleCompanyCard(data, 'sudo')} */}
                    {this.showBottomTickersBig(data)}
                </div>
                
            </Card>
        );
    }

    rangeText = ()=>{
        if(this.state.rangeVal === 1 || this.state.rangeVal === "1"){
            return 'Cautious'
        }
        else if (this.state.rangeVal === 2 || this.state.rangeVal === "2"){
            return 'Conservative'
        }
        else if (this.state.rangeVal === 3 || this.state.rangeVal === "3"){
            return 'Moderate'
        }
        else if (this.state.rangeVal === 4 || this.state.rangeVal === "4"){
            return 'Moderate - Agressive'
        }
        else if (this.state.rangeVal === 5 || this.state.rangeVal === "5"){
            return 'Agressive'
        }
        else if (this.state.rangeVal === 6 || this.state.rangeVal === "6"){
            return 'Extremely Agressive'
        }
        else{
            return ''
        }
    }

    objective = ()=>{
        return(
            <div style={{width:'100%', display:'flex',justifyContent:'center', alignItems:'center', paddingTop:15,paddingBottom:15,paddingLeft:25,paddingRight:25,  marginTop:10 }}>
                    <div style={{display: 'flex', justifyContent:'space-between', width: '100%', flexWrap:'wrap'}}>
                        <div style={{width:this.props.isSmallScreen?'90%':'40%', display:'flex', justifyContent:'flex-start', alignItems:'center', flexDirection:'column'}}>
                            <div style={{ width: '100%',display:'flex',justifyContent:'flex-start', flexDirection:'column', }}>
                                {/* <p style={{ margin:0, fontSize: '1.5em', }}>Objective</p> */}
                                <p style={{ margin:0, fontSize: '1.25em', fontWeight:'bold' }}>Optimization Objective: </p>
                                <p style={{ margin:0, fontSize: '1.25em', }}>Find Highest Risk Adjusted Return </p>
                                {/* <FaRegQuestionCircle style={{marginRight:4, marginLeft:4, color:brandColor, cursor:'pointer'}} onClick={()=>this.setState({objDesc:!this.state.objDesc})}/> */}
                            </div>
                            <div style={{display:'flex', width:'100%', flexDirection:this.props.isSmallScreen?'column':'row'}}>
                                <div style={{display:'flex', justifyContent:'center',alignItems:'center', marginRight:'2%'}}>
                                    {/* <input style={{cursor:'pointer'}}type="radio" checked={!this.state.returnsConstraint} onChange={()=>this.setState({returnsConstraint:false})} /> */}
                                    {/* <label style={{margin:0, fontSize: '1em', marginLeft: 4}}>Maximize Sharpe</label> */}
                                </div>
                                {/* <div style={{display:'flex', justifyContent:'center',alignItems:'center', }}>
                                    <input style={{cursor:'pointer'}}type="radio" checked={this.state.returnsConstraint} onChange={()=>this.setState({returnsConstraint:true})}/>
                                    <label style={{margin:0, fontSize: '1em', marginLeft: 4}}>Increase Return</label>
                                </div> */}
                            </div>
                        </div>

                        {/* <div style={{width:this.props.isSmallScreen?'90%':'40%', display:'flex', justifyContent:'flex-start', alignItems:'flex-end', flexDirection:'column'}}>
                            <div style={{ width: '100%',display:'flex',justifyContent:'flex-start', alignItems:'center', }}>
                                <p style={{ margin:0, fontSize: '1.5em', }}>Risk Tolerance</p>
                                <FaRegQuestionCircle style={{marginRight:4, marginLeft:4, color:brandColor, cursor:'pointer'}} onClick={()=>this.setState({objDesc:!this.state.objDesc})}/>
                            </div>
                            <div style={{display:'flex', width:'100%'}}>
                                <div style={{display:'flex', justifyContent:'center',alignItems:'center', marginRight:'2%'}}>
                                    <input style={{}}type="range" 
                                    
                                        min="1"
                                        max="6" 
                                        step={1}
                                        value={this.state.rangeVal}
                                        onChange={e => this.setState({ rangeVal:e.target.value })}
                                    />
                                </div>
                                <div style={{display:'flex', justifyContent:'center',alignItems:'center', }}>
                                    <label style={{margin:0, fontSize: '1em', marginLeft: 4}} for="returns">{this.rangeText()}</label>
                                </div>
                            </div>
                        </div> */}
                        {this.state.sudoPortfolioStocks.length > 0 ?
                            
                        <div style={{width:this.props.isSmallScreen? '80%':'20%', display:'flex', justifyContent:this.props.isSmallScreen? 'center':'flex-end'}}>
                            <div onClick={() => {
                                this.props.resetPrevSet()
                                this.setState({sudoPortfolioStocks:[], showing:false, showSteps:true})}
                             } style={{paddingLeft: 20, paddingRight: 20, paddingTop:7, paddingBottom:7, borderRadius: 8, border:'solid', borderColor:'#3b8e8c',backgroundColor:'#3b8e8c', fontSize: 'smaller', minWidth: 0, display:'flex', alignItems:'center',justifyContent:'center', marginRight:15, cursor:'pointer' }}>
                                <p style={{ color: 'rgb(255,255,255)', margin: 0, fontWeight: 'bold', fontSize: 18, textAlign:'center' }}>
                                    Clear Stocks
                                </p> 
                            </div>
                            <div onClick={() => this.setState({addRowModal:true})} style={{paddingLeft: 20, paddingRight: 20, paddingTop:7, paddingBottom:7, borderRadius: 8, border:'solid', borderColor:'#3b8e8c',backgroundColor:'#3b8e8c', fontSize: 'smaller', minWidth: 0, display:'flex', alignItems:'center',justifyContent:'center', /*marginRight:15,*/ cursor:'pointer' }}>
                                <p style={{ color: 'rgb(255,255,255)', margin: 0, fontWeight: 'bold', fontSize: 18, textAlign:'center' }}>
                                    Add Stock
                                </p> 
                            </div>
                        </div>:null
                        }
                    </div>
                </div>
        )
    }

    addRow = ()=>{
        if(this.state.addRowModal){
            return (
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                    <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', }}>
                        <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                            <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Stock Search</p>
                            <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.setState({addRowModal:false})}> X </p>
                        </div>
                        <div style={{width:'90%', margin:'auto', marginTop: 25, marginBottom: 50}}>
                            <p style={{fontSize:22}}>
                                Search for any stock by company name or symbol
                            </p>
                            <p style={{fontSize:22}}>
                                Click on any result to add it to your portfolio
                            </p>
                        </div>
                        <div
                            style={{
                                backgroundColor: 'rgb(255,255,255)', width: '75%', margin:'auto', borderRadius: 20, paddingLeft: 10, display: 'flex', justifyContent: 'flex-start', boxShadow: this.state.hover ? '0 0 10px rgb(223,225,229)' : 'none', borderColor: 'rgb(237,238,240)', borderStyle: 'solid', borderWidth: 'thin', minWidth: 250, 
                            }}
                            onMouseEnter={() => this.setState({ hover: 'input' })}
                            onMouseLeave={() => this.setState({ hover: false })}
                        >
                            <div style={{ minWidth: 20, width: '5%', /*backgroundColor: '#00A99D',*/ display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                                <FaSearch size={'50%'} style={{color: '#dddddd'}} />                        
                            </div>
                            <input 
                                ref={this.input}
                                style={{ width: '90%', border: 'none', outline: 'none', paddingTop: 5, paddingBottom: 5}}
                                placeholder="Enter stock symbol or company name"
                                type="text" 
                                value={this.state.searchMomentumText} 
                                onChange={(event) => {
                                let searchMomentumText = event.target.value.toUpperCase();
                                this.setState({ searchMomentumText, selectedPreset: null }, () => {
                                    const { isSearching } = this.state;
                                    if (isSearching) {
                                        return;
                                    } else {
                                        this.setState({ isSearching: true });
                                        setTimeout(() => {
                                            this.setState(
                                                { isSearching: false },
                                                this.onSubmitSearchMomentum
                                            );
                                        }, 2000);
                                    }
                                });
                            }} />
                            </div>
                        <div style={{maxHeight: 300, overflowY:'scroll', paddingLeft:20, paddingRight:20, marginTop:20}}>
                        {this.state.searchMomentumText.length > 0? 
                            this.state.isSearching? 
                            <div style={{width:'100%', marginTop:75}}>
                                <p style={{textAlign:'center', fontSize:22, fontWeight:'bold'}}> Loading...</p>
                            </div>:
                            <div style={{minHeight:200, overflowY:'scroll',display:'flex',flexWrap:'wrap'}}>{
                                this.state.momDataSource.map(ele => this.createBox(ele)
                                    // <div 
                                    //     onMouseEnter={()=>this.setState({hover:ele})} 
                                    //     onClick={()=>this.setState({addRowModal:false ,sudoPortfolioStocks: [...this.state.sudoPortfolioStocks, {...ele, allocation: 0, value: 0, shares:0 }]})} 
                                    //     style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', border:'solid', borderColor:'#dddddd', borderWidth:1,backgroundColor: this.state.hover === ele?'rgb(220,220,220)':'rgb(243,243,243)', paddingLeft: 20, paddingRight: 20, paddingLeft:10, paddingRight:10, borderRadius: 5, marginTop: 10, cursor: 'pointer' }}>
                                    //     <div style={{display:'flex', flexDirection: 'column', width:'100%', padding:10 }}>
                                    //         <p style={{ margin: 0, float: 'left', fontWeight:'bold' }}>{ele.symbol}</p>
                                    //         <p style={{ margin: 0, float: 'left' }}>{ele.company}</p>
                                    //     </div>
                                    // </div>
                                )
                                }

                            </div>
                        :
                            <div style={{width:'100%', marginTop:75}}>
                                <p style={{textAlign:'center'}}>Search above to see results</p>
                            </div>
                        }
                        </div>
                    </div>
                </div>
              )}
    }

    addMethod = ()=>{
        return(
            <div style={{width:'100%', display:'flex',justifyContent:'center', alignItems:'center', marginTop:10, borderTop:'none',borderBottom:'none', borderWidth: '1px',borderColor:'#ddd' }}>
                    <div style={{width:'90%', display:'flex', justifyContent:'space-evenly', alignItems:'center', flexDirection:'row', paddingTop:7, paddingBottom:7 }}>
                        <div style={{ display:'flex',justifyContent:'center', alignItems:'center',  marginRight:5 }}>
                            <p style={{margin:0, fontSize: 14,width:'max-content'}}>Add by:</p>
                        </div>
                        <div style={{width:'100%', display:'flex', justifyContent:'space-around', alignItems:'center', flexDirection:'row',backgroundColor:'rgb(240,241,242)'}}>
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center', backgroundColor:'rgb(240,241,242)', paddingTop:3, paddingBottom:3, }}>
                                <input id='PorfolioSymbolMethod_Quantity' style={{cursor:'pointer'}}type="radio" checked={this.state.massAddBy === 'quantity'} onClick={()=>this.setState({massAddBy: 'quantity'})} />
                                <label style={{margin:0, fontSize: 14, marginLeft: 4}} for="risk">Quantity</label>
                            </div>
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center', backgroundColor:'rgb(240,241,242)' }}>
                                <input id='PorfolioSymbolMethod_Percentage' style={{cursor:'pointer'}}type="radio" checked={this.state.massAddBy === 'allocation'} onClick={()=>this.setState({massAddBy: 'allocation'})}/>
                                <label style={{margin:0, fontSize: 14, marginLeft: 4}} for="returns">Percentage</label>
                            </div>
                            <div style={{display:'flex', justifyContent:'center',alignItems:'center', backgroundColor:'rgb(240,241,242)' }}>
                                <input id='PorfolioSymbolMethod_Dollar' style={{cursor:'pointer'}}type="radio" checked={this.state.massAddBy === 'dollarValue'} onClick={()=>this.setState({massAddBy: 'dollarValue'})}/>
                                <label style={{margin:0, fontSize: 14, marginLeft: 4}} for="returns">Dollar Value</label>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }

    riskTolerance = ()=>{
        return(
            <div style={{width:'100%', display:'flex',justifyContent:'center', alignItems:'center',borderBottom:'solid', borderWidth: '1px',borderColor:'#ddd' }}>
                    <div style={{width:'90%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'row'}}>
                        <div style={{ display:'flex',justifyContent:'center', alignItems:'center', marginRight:'2%'}}>
                            <p style={{margin:0, fontSize: '1em'}}>Risk Tolerance:</p>
                            <FaQuestionCircle style={{marginRight:4, marginLeft:4, color:brandColor, cursor:'pointer'}} onClick={()=>this.setState({rtDesc:!this.state.rtDesc})}/>
                        </div>
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center', marginRight:'2%'}}>
                            <p style={{margin:0, marginRight: 5}}>Low Risk</p>
                            <input style={{cursor:'pointer', backgroundColor:brandColor}}type="range" value={this.state.rtVal} onChange={(e)=>this.setState({rtVal:e.target.value})} />
                            <p style={{margin:0, marginLeft: 5}}>High Risk</p>
                        </div>
                    </div>
                </div>
        )
    }

    toleranceDescription = ()=>{
        if(this.state.rtDesc){
            return(
                <div style={{display:'flex', width: '100%', justifyContent:'center', alignItems:'center',marginTop:5, borderBottom:'solid', borderColor: '#ddd', borderWidth:.25}}>
                    <div style={{display:'flex', width: '90%', flexDirection:'column'}}>
                        <p style={{margin:0}}>Slide to the amount of risk  you willing to take on. All the way to left would be minimal risk, all the way to the right would be extremely risky.</p>
                    </div>
                </div>
            )
        }
    }

    onSubmitSearchMomentum = () => {
        const { searchMomentumText } = this.state;
        this.setState({ isMomDataLoading: true });
        // console.log(searchMomentumText);
        let url = `${SearchEndpoint}/ticker?search=${searchMomentumText}`;

        fetch(url, {
            "Access-Control-Allow-Headers": {
                "Content-Type": "application/json",
                "Cache-Control": "no-cache",
                "Access-Control-Request-Headers": "*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
            },
        })
            .then((res) => res.json())
            .then((responseJson) => {
                var data = responseJson.content;
                // console.log("SEARCHING RESULT: " , data);

                for (var i = 0; i < data.length; i++) {
                    var item = data[i];
                    var color = "";
                    if (item.rar) {
                        switch (item.rar) {
                            case "H":
                                color = "#dc143c";
                                break;
                            case "M":
                                color = "#fcac0c";
                                break;
                            default:
                                color = "#00A99D";
                        }
                    } else {
                        color = "rgb(0,0,0)";
                    }

                    item.symbolColor = color;
                    data[i] = item;
                }
                this.setState({
                    isMomDataLoading: false,
                    momDataSource: data,
                });
            })
            .catch((err) => {
                this.setState({ isMomDataLoading: false });
                console.log(err);
            });
    };

    row = (ele, i)=>{
        return(
            <div style={{display:'flex', width:'100%', justifyContent:'space-between', backgroundColor: i % 2 === 0 ?'': 'rgb(126,126,126)', }}>
                <div style={{display:'flex', borderLeft:'solid', }}>
                    <p style={{margin:0, display:'flex',alignItems:'center',justifyContent:'center', marginTop:4,marginBottom:4, width: '15%'}}>{ele.symbol}</p>
                </div>
                <div style={{display:'flex', borderLeft:'solid'}}>
                    <p style={{margin:0, display:'flex',alignItems:'center',justifyContent:'center', marginTop:4,marginBottom:4,width: '20%'}}>{ele.priceUSD === 'Price' ? ele.priceUSD :ele.priceUSD.toFixed(2)}</p>
                </div>
                <div style={{display:'flex', borderLeft:'solid'}}>
                    <p style={{margin:0, display:'flex',alignItems:'center',justifyContent:'center', marginTop:4,marginBottom:4,width: '15%'}}>{ele.direction}</p>
                </div>
                <div style={{display:'flex', borderLeft:'solid'}}>
                    <p style={{margin:0, display:'flex',alignItems:'center',justifyContent:'center', marginTop:4,marginBottom:4,width: '15%'}}>{ele.allocation}%</p>
                </div>
                <div style={{display:'flex', borderLeft:'solid'}}>
                    <p style={{margin:0, display:'flex',alignItems:'center',justifyContent:'center', marginTop:4,marginBottom:4,width: '20%'}}>${ele.value}</p>
                </div>
                <div style={{display:'flex', borderLeft:'solid',borderRight:'solid'}}>
                    <p style={{margin:0, display:'flex',alignItems:'center',justifyContent:'center', marginTop:4,marginBottom:4,width: '15%'}}>{ele.shares}</p>
                </div>
            </div>
        )
    }

    showBottomTickersSmall = ()=>{
        return (
            <div
                style={{
                    width: '100%',
                    marginTop: 20,
                    height: 500,
                    border:'transparent',
                    
                }}
            >
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                    {/* <div style={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', flexDirection:'column'  }} >
                        <div style={{backgroundColor: brandColor, borderRadius: 8, paddingTop:5,paddingBottom:5,paddingLeft:10,paddingRight:10, marginTop:5, cursor:'pointer'}} onClick={() => this.setState({dropDown:!this.state.dropDown})}>
                            <p style={{ color: 'rgb(255,255,255)', margin: 0, fontWeight: 'bold', fontSize: 14 }}>
                                Import
                            </p> 
                        </div>
                        {this.state.dropDown? null:
                            <div style={{
                                display: 'inline-block',
                                position: 'absolute',
                                backgroundColor: '#f1f1f1',
                                backgroundColor:'rgb(255,255,255)',
                                minWidth: '160px',
                                boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                                zIndex: 1,
                                marginTop: 130
                            }}>
                            <div style={{display:'flex', flexDirection:'column', zIndex:999, border: 'none', borderWidth:0.25, borderRadius:8}}>
                               
                                <div style={{display:'flex', justifyContent:'center',alignItems:'center',borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%' }} onClick={() => this.setState({showMassAdd: true, searchMomentumText: '', dropDown:!this.state.dropDown})}>
                                    <p style={{margin:0}}>
                                        Multiple Stocks
                                    </p>
                                </div>
                                <div style={{display:'flex', justifyContent:'center',alignItems:'center',borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%' }} onClick={() => this.setState({dropDown:!this.state.dropDown, showCustoms:true})} >
                                    <p style={{margin:0}}>
                                      Re-Construct
                                    </p>
                                </div>
                                <div style={{display:'flex', justifyContent:'center',alignItems:'center',borderBottom: 'solid', borderColor:'#ddd', paddingTop:'1%', paddingBottom:'1%' }} onClick={() => this.setState({dropDown:!this.state.dropDown, cashModal:true})} >
                                    <p style={{margin:0}}>
                                        Add Cash
                                    </p>
                                </div>
                            </div>
                            </div>
                        }
                    </div> */}
                    {this.props.isSmallScreen?
                        null
                    :
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <p style={{ color: 'rgb(17,17,17)', margin: 0, marginTop: 5, fontWeight: 'bold', fontSize: 14, marginLeft: 5 }}>
                                Portfolio
                            </p> 
                            <p style={{ color: 'rgb(17,17,17)', margin: 0, marginTop: 5, fontWeight: 'bold', fontSize: 14, marginLeft: 5 }}>
                                Constructor
                            </p> 
                        </div>
                    }
                   
                     <div style={{backgroundColor: this.state.sudoPortfolioStocks.length < 2? 'rgb(82,82,82)': brandColor, borderRadius: 8, paddingTop:5,paddingBottom:5,paddingLeft:10,paddingRight:10, marginTop:5, cursor:'pointer'}} onClick={this.state.sudoPortfolioStocks.length < 2 ? null :()=>this.optimizeWithConstraints()}>
                        <p style={{ color: 'rgb(255,255,255)', margin: 0, fontWeight: 'bold', fontSize: 14 }}>
                            Construct
                        </p> 
                    </div>
                </div>
                {/* <div>
                <Row
                            style={{
                                backgroundColor: 'rgb(34,114,195)',
                                paddingLeft: 10,
                                paddingRight: 10,
                                borderRadius: 8,
                                display: "flex",
                                justifyContent: "left",
                                alignItems: "center",
                                marginBottom: 8,
                                textDecoration: "none",
                            }}
                            onClick={this.state.searchMomentumText === 'enter a symbol' ?()=>this.setState({searchMomentumText:''}):null}
                        >
                            <input style={{ width: '100%',height: 44, border: 'none', backgroundColor: 'transparent', fontWeight: 'bold', boxShadow: 'none', textAlign: 'center', outline: 'none',}} type="text" value={this.state.searchMomentumText} onChange={(event) => {
                                let searchMomentumText = event.target.value;
                                this.setState({ searchMomentumText, selectedPreset: null }, () => {
                                    const { isSearching } = this.state;
                                    if (isSearching) {
                                        return;
                                    } else {
                                        this.setState({ isSearching: true });
                                        setTimeout(() => {
                                            this.setState(
                                                { isSearching: false },
                                                this.onSubmitSearchMomentum
                                            );
                                        }, 2000);
                                    }
                                });
                            }} />
                        </Row>
                </div> */}
                <div style={{width:'100%'}}>
                    {this.objective()}
                    {this.objectiveDescription()}
                </div>
                <div style={{display:'flex', flexDirection: 'column', marginTop: 10, width: '100%',}}>
                    {this.state.sudoPortfolioStocks.length < 1?
                        <div style={{height:400, marginTop:50}}>
                            <Steps loading={this.state.loading} toWm={this.props.toWm} isSmallScreen={true}/>
                        </div>
                        :
                        <div style={{display:'flex', margin:'auto'}}>
                            <MobileTickerList data={this.state.sudoPortfolioStocks} isSmallScreen={true} setShares={this.setShares}/>
                        </div>
                    }
                    {/* {this.row({symbol: 'Symbol', priceUSD: 'Price', direction: 'Trend', allocation: '', value: 'Value', shares: "Shares",borderTop:'solid' }, 1)}
                    {data.map((ele, i)=>this.row(ele, i))} */}
                </div>
            </div>
        )
    }

    showBottomTickersBig = (data)=>{
        let arr = []
        arr.push(
            <div style={{display:'flex', justifyContent:'space-between', paddingTop:8, paddingBottom:8, paddingLeft: 18, paddingRight:12}}>
                <div style={{width: '18%',display:'flex', alignItems:'center'}}>
                    <p style={{fontSize:18, fontWeight:'bold'}}>Symbol</p>
                </div>
                <div style={{width: '20%',display:'flex', alignItems:'center'}}>
                    <p style={{fontSize:18, fontWeight:'bold',marginLeft:12}}>Name</p>
                </div>
                <div style={{width: '12%',display:'flex', alignItems:'center',justifyContent:'center'}}>
                    <p style={{fontSize:18, fontWeight:'bold', textAlign:'center',marginLeft:12}}>Trend</p>
                </div>
                <div style={{width: '12%',display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                    <p style={{fontSize:18, fontWeight:'bold',textAlign:'right',marginLeft:12}}>Price</p>
                </div>
                <div style={{width: '15%',display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                    <p style={{fontSize:18, fontWeight:'bold',textAlign:'right',marginLeft:12}}>Allocation</p>
                </div>
                <div style={{width: '12%',display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                    <p style={{fontSize:18, fontWeight:'bold', textAlign:'right',marginLeft:12}}>Value</p>
                </div>
                <div style={{width: '12%',display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                    <p style={{fontSize:18, fontWeight:'bold', textAlign:'right',marginLeft:12}}># Shares</p>
                </div>
                <div style={{width:'2%'}}/>
            </div>
        )
        data.forEach(ele=>{
            // console.log(`creating row for ${ele.symbol}`, ele)
            if(ele.priceUSD){
                arr.push(
                    <div style={{display:'flex', justifyContent:'space-between', paddingTop:8, paddingBottom:8, paddingLeft: 12, paddingRight:12, borderBottom:'solid', borderColor:'#dddddd'}}>
                    <div style={{width:'8%', marginLeft:-5}}>
                        {ele.symbol === 'CASH'?null: this.checkLogo(ele.symbol)}
                    </div>
                        <div style={{width: '12%',display:'flex', alignItems:'center'}}>
                            <p style={{fontSize:15,margin:0,}}>{ele.symbol}</p>
                        </div>
                        <div style={{width: '20%',display:'flex', alignItems:'center'}}>
                            <p style={{fontSize:20,margin:0,overflow: 'hidden', textOverflow: 'ellipsis',whiteSpace: 'nowrap',}}>{ele.company}</p>
                        </div>
                        <div style={{width: '12%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <img
                                style={{height:'fit-content',}}
                                src={this.getArrow(ele.direction)}
                            
                            />
                        </div>
                        <div style={{width: '12%',display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                            <p style={{fontSize:20,margin:0,textAlign:'right'}}>${this.addCommas(ele.priceUSD.toFixed(2))}</p>
                        </div>
                        <div style={{width: '12%',display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                            <p style={{fontSize:20,margin:0,textAlign:'right'}}>{isNaN(ele.allocation) || !isFinite(ele.allocation)?0 :ele.allocation}%</p>
                            {/* <div style={{width:'75%', backgroundColor:'#dddddd', display:'flex',alignItems:'center', paddingLeft:5, paddingRight:5}}>
                                <input style={{ width: '100%', border: 'none', backgroundColor: 'transparent', boxShadow:'none', textAlign:'right', outline:'none',fontSize:16,margin:0, }} onChange={(e) => this.setAllocation(e, ele)} type="text" value={ele.allocation}/>%
                            </div> */}
                        </div>
                        <div style={{width: '12%',display:'flex', alignItems:'center', justifyContent:'flex-end',}}>
                            <p style={{fontSize:20,margin:0,textAlign:'right'}}>${this.addCommas(ele.value)}</p>
                            {/* <div style={{width:'75%', backgroundColor:'#dddddd', display:'flex',alignItems:'center', paddingLeft:5, paddingRight:5,}}>
                                $<input style={{ width: '100%', border: 'none', backgroundColor: 'transparent', boxShadow:'none', textAlign:'right', outline:'none',fontSize:16,margin:0, }} onChange={(e) => this.setValue(e, ele)} type="text" value={ele.value}/>
                            </div> */}
                        </div>
                        <div style={{width: '12%',display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                            {/* <p style={{fontSize:20,margin:0, textAlign:'right'}}>{ele.shares.toFixed(1)}</p> */}
                            <div style={{width:'75%', backgroundColor:'#dddddd', display:'flex',alignItems:'center', paddingLeft:5, paddingRight:5}}>
                                <input style={{ width: '100%', border: 'none', backgroundColor: 'transparent', boxShadow:'none', textAlign:'right', outline:'none',fontSize:16,margin:0, }} onChange={(e) => this.setShares(e, ele)} type="text" value={ele.shares}/>
                            </div>
                        </div>
                        <div style={{display:'flex',justifyContent:'flex-end', alignItems:'center', cursor:'pointer',width:'2%'}} onClick={()=>this.removeFromSudo(ele)}>
                            <FaWindowClose />
                        </div>
                    </div>
                )
            }

        })
        if(arr.length > 1){
            return arr
        }else{
            return(
                <div style={{display:'flex',height:'100%', justifyContent:'center',alignItems:'center', marginTop:50, flexDirection:'column' }} >
                    {/* {this.state.loading? null:
                        <div style={{width:'80%', margin:'auto',marginTop:20, marginBottom:25}}>
                            <p style={{fontSize:24, }}>
                                Use our AI to help you make the most of your portfolio stocks you want to invest in. 
                            </p>
                            <p style={{fontSize:24, }}>
                               Pick your stocks and we'll do the rest.
                            </p>
                            <p style={{fontSize:24, }}>
                                Feel free to go back and forth, tinkering with your portofolio until you are happy with it.
                            </p>
                        </div>
                    } */}
                    {this.state.loading?
                    <div onClick={ this.state.loading? null:() => this.setState({showSteps:true, step:0})} style={{paddingLeft: 20, paddingRight: 20, paddingTop:7, paddingBottom:7, borderRadius: 8, border:'solid', minWidth: 0, display:'flex', alignItems:'center',justifyContent:'center', marginRight:15, }}>
                        <p style={{ color: 'rgb(17,17,17)', margin: 0, fontWeight: 'bold', fontSize: 18 }}>
                            Loading...
                        </p> 
                    </div>:
                    <div onClick={ this.state.loading? null:() => this.setState({showSteps:true, step:0})} style={{paddingLeft: 20, paddingRight: 20, paddingTop:7, paddingBottom:7, borderRadius: 8, border:'solid', borderColor:'#3b8e8c',backgroundColor:'#3b8e8c', fontSize: 'smaller', minWidth: 0, display:'flex', alignItems:'center',justifyContent:'center', marginRight:15, cursor:'pointer' }}>
                        <p style={{ color: 'rgb(255,255,255)', margin: 0, fontWeight: 'bold', fontSize: 18 }}>
                            Construct Portfolio
                        </p> 
                    </div>}
                    
                </div>
            )
        }
    }

    wlselection = (ele)=>{
        let selected = []
        if(this.inSelection(ele)){
            selected = this.state.selected.filter(e=>e.symbol !== ele.symbol)
        }
        else{
            selected = [...this.state.selected, ele]
        }
        this.setState({selected})
    }

    inSelection = (ele)=>{
        let arr = []
        this.state.selected.forEach(e=>{
            arr.push(e.symbol)
        })
        if(arr.includes(ele.symbol)){
            return true
        }
        else{
            return false
        }
    }
    // neten
    wlModal = ()=>{
        const { myWatchlist } = this.props
        let mapped = []
        myWatchlist.forEach(ele=>{
            mapped.push(
                
                <div style={{display:'flex', justifyContent:'space-around', width:'90%', margin:'auto', borderBottom:'solid', borderColor:'#dddddd', borderWidth:'0.5px', padding: 8}}>
                    
                    <div style={{width:'10%'}}>
                        <input value={this.inSelection(ele)} onChange={()=>this.wlselection(ele)} type="checkbox"/>
                    </div>
                    <div style={{width:'15%'}}>
                        <p style={{margin:0}}>{ele.symbol}</p>
                    </div>
                    <div style={{width:'20%'}}>
                        <p style={{margin:0}}>{ele.symbol}</p>
                    </div>
                    <div style={{width:'20%',}}>
                        <p style={{margin:0,whiteSpace: 'nowrap', overflow: 'hidden',  textOverflow: 'ellipsis'}}>{ele.company}</p>
                    </div>
                    <div style={{width:'10%'}}>
                        <p style={{margin:0, textAlign:'right'}}>${ele.priceUSD ? ele.priceUSD.toFixed(2):'$00.00'}</p>
                    </div>
                </div>
            )
        })
        if(this.state.showWatchlist && !this.props.isSmallScreen){
            return(
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                    <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:'40%'}}>
                        <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                            <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Add From Watchlist</p>
                            <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.setState({showWatchlist:false})}> X </p>
                        </div>
                                
                        <div style={{display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column',marginTop:20, maxHeight: 300, overflowY:'scroll'}}>
                          
                            {mapped}
                        </div>
                        <div style={{display:'flex', alignItems:'center', justifyContent: 'center', marginTop: 20, marginBottom:20}}>
                            <div style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, padding: 10, width: '30%', cursor:'pointer'}} 
                                onClick={()=>{
                                    let arr = []
                                    this.state.selected.forEach(ele=>{
                                        arr.push({...ele, allocation: 0, value: 0, shares:0 })
                                    })
                                    // let sudoPortfolioStocks = this.state.sudoPortfolioStocks.map(ele=>{
                                    //     return(
                                    //         {...ele, allocation: (ele.value/total*100).toFixed(2)}
                                    //     )
                                    // })
                                    this.setState({
                                        showWatchlist:false, 
                                        selected:[],
                                        sudoPortfolioStocks:[...arr,...this.state.sudoPortfolioStocks]
                                    })
                                }}>
                                <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                                    Add Selected ({this.state.selected.length})
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    objectiveDescription = ()=>{
        if(this.state.objDesc){
            return(
                <div style={{display:'flex', width: '100%', justifyContent:'center', alignItems:'center',paddingTop:0,paddingBottom:15,paddingLeft:25,paddingRight:25 }}>
                    <div style={{display:'flex', width: '100%', flexDirection:'column'}}>
                        <p style={{margin:0}}><strong>Risk Tolerance - </strong>How much risk you are willing to have in your portfolio. More risk can allow for more gains but also more losses.</p>
                        <p style={{margin:0}}><strong>Maximizing Sharpe - </strong>This maximizes your risk adjusted return for the portfolio</p>
                        <p style={{margin:0}}><strong>Increase Return - </strong>This maximizes your risk adjusted return as much as possible while forecasting a higher return than the portfolio you have submitted.</p>
                    </div>
                </div>
            )
        }
    }

    showWlbar = () =>{
        if (this.state.showWatchlist){
            return(
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <div
                        style={{
                            backgroundColor: 'rgb(255,255,255)', width: '75%', marginBottom: 20, borderRadius: 20, paddingLeft: 10, display: 'flex', justifyContent: 'space-between', boxShadow: this.state.hover ? '0 0 10px rgb(223,225,229)' : 'none', borderColor: 'rgb(237,238,240)', borderStyle: 'solid', borderWidth: 'thin', minWidth: 250,
                        }}
                        onMouseEnter={() => this.setState({ hover: true })}
                        onMouseLeave={() => this.setState({ hover: false })}
                    >
                        <input
                            type="text"
                            style={{ width: '90%', border: 'none', outline: 'none', paddingTop: 5, paddingBottom: 5 }}
                            value={this.state.searchMomentumText}
                            onChange={(event) => {
                                let searchMomentumText = event.target.value;
                                this.setState({ searchMomentumText, selectedPreset: null }, () => {
                                    const { isSearching } = this.state;
                                    if (isSearching) {
                                        return;
                                    } else {
                                        this.setState({ isSearching: true });
                                        setTimeout(() => {
                                            this.setState(
                                                { isSearching: false },
                                                this.onSubmitSearchMomentum
                                            );
                                        }, 2000);
                                    }
                                });
                            }}
                            onFocus={() => {
                                this.setState({ showTrendPredictor: true });
                            }}
                            placeholder="Enter a symbol or company name"
                            ref={this.input}
                        />

                        <div style={{ minWidth: 20, width: '5%', backgroundColor: '#00A99D', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0px 15px 15px 0px' }}>
                            <FaSearch size={10} style={{ color: 'rgb(255,255,255)' }} />
                        </div>
                    </div>
                </div>
            )
        }
    }    


    whatStep = ()=>{
        if(this.state.showSteps){
            switch(this.state.step) {
                case 0:
                  return this.stepZero()
                  break;
                // case 1:
                //   return this.stepOne()
                //   console.log('step 1')
                //   break;
                case 1.1:
                  return this.stepWl()
                case 1.2:
                  return this.stepSymbol()
                case 1.3:
                  return this.stepFile()
                case 1.4:
                  return this.stepReopt()
                // case 2:
                //   return this.stepTwo()
                case 2:
                    if(this.state.massAddBy === 'allocation'){
                        return this.stepFour()
                    }else{
                        return this.stepThree()
                    }
                case 3:
                    return this.stepFour()
                default:
                  // code block
              }
        }
    }

    fetchCustomStrategies(){
        console.log('fetching custom strats')
        fetch(`${ENDPOINT}/risk-monkey/portfolios/ALL_PORTFOLIOS`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .catch(err => console.log('custom strategy error message: ', err))
          .then(responseJson => {
                    // console.log('custom strategy success resp: ',responseJson)
                    this.setState({customPorts:{...responseJson}}
                )
            }
            )
    }

    canOptimize = ()=>{
        if(this.state.sudoPortfolioStocks.length > 2){
            return false
        }else{
            return true
        }
    }

    stepReopt = ()=>{
        // console.log('customs length',Object.keys(this.state.customPorts).length)
        let arr = []
        let arr2 = []
        let header = 
            <div style={{display:"flex", flexDirection:'row', width:'100%', border:'solid', borderWidth:.25, borderColor:'#ddd'}} onClick={()=>console.log(`clicked header`)}>
                <div style={{width:'10%'}}>
                    <p>&nbsp;</p>
                </div>
                <div style={{width:'45%', display:'flex',alignItems:'center',justifyContent:'flex-start',borderRight:'solid', borderWidth:.25, borderColor:'#ddd'}}>
                    <p style={{textAlign:'left'}}>
                        Portfolio Name
                    </p>
                </div>
                <div style={{width:'45%', display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                    <p style={{textAlign:'right'}}>
                        Date Created
                    </p>
                </div>
            </div>
        // arr.push(header)
        if(this.state.customPorts){
            Object.keys(this.state.customPorts).forEach((ele, i)=>{
                arr.push(
                    <div style={{display:"flex", flexDirection:'row', width:'90%', borderBottom:'solid', borderWidth:'.25px', borderColor:'#ddd', cursor:'pointer', margin:'auto'}} >
                        <div style={{width:'10%', display:'flex', alignItems:'center' }}>
                            <input id={'CreatedPortfolio_' + i} name='port' type="radio" onClick={()=>this.reOpt(this.state.customPorts[ele])}/>
                        </div>
                        <div style={{width:'45%', display:'flex',alignItems:'center',justifyContent:'flex-start',borderRight:'none', borderWidth:.25, borderColor:'#ddd', padding:7}}>
                           <p style={{margin:0,textAlign:'left'}}>
                               {ele}
                            </p> 
                        </div>
                        <div style={{width:'45%', display:'flex',alignItems:'center',justifyContent:'flex-end',padding:7}}>
                           <p style={{margin:0,textAlign:'right'}}>
                             {this.state.customPorts[ele][0].dateCreated}
                           </p>
                        </div>
                    </div>
                )
            }) 
        }
        if(this.props.linkedPorts){
            // arr2.push( 
            //     <div style={{display:"flex", flexDirection:'row', width:'100%', border:'solid', borderWidth:.25, borderColor:'#ddd'}} onClick={()=>console.log(`clicked header`)}>
            //         <div style={{width:'50%', display:'flex',alignItems:'center',justifyContent:'center',borderRight:'solid', borderWidth:.25, borderColor:'#ddd'}}>
            //             Insitution
            //         </div>
            //         <div style={{width:'50%', display:'flex',alignItems:'center',justifyContent:'center'}}>
            //             Account Number
            //         </div>
            //     </div>
            // )
            this.props.linkedPorts.forEach(ele=>{
                ele.accounts.forEach((innerEle, index)=>{
                    if(!(innerEle.type.toLowerCase() === 'checking' || innerEle.type.toLowerCase() === 'depository'|| innerEle.ria))
                    arr2.push(
                        <div style={{display:"flex", flexDirection:'row', width:'90%', borderBottom:'solid', borderWidth:'.25px', borderColor:'#ddd', cursor:'pointer', margin:'auto'}} >
                            <div style={{width:'10%', display:'flex', alignItems:'center' }}>
                                <input id={'LinkedAccountChoice_' + index} name='port' type="radio" onClick={()=>this.getHoldings(innerEle.accountId)} style={{verticalAlign:'middle'}}/>
                            </div>
                            <div style={{width:'45%', display:'flex',alignItems:'center',justifyContent:'flex-start',borderRight:'none', borderWidth:.25, borderColor:'#ddd', padding:7}}>
                                <p style={{margin:0,textAlign:'left'}}>
                                    {ele.instName}
                                </p> 
                            </div>
                            <div style={{width:'45%', display:'flex',alignItems:'center',justifyContent:'flex-end',padding:7}}>
                                <p style={{margin:0,textAlign:'right'}}>
                                    {innerEle.name}
                                </p>
                            </div>
                        </div>
                    )
                })
                
            })
        }
        return(
            <div style={{display:'flex', flexDirection: 'column', margin:'auto', borderRadius: 10, width:'90%', }}>
               <div style={{display:'flex', width:'100%',flexDirection:'column', justifyContent:'flex-start', alignItems:'center', marginTop:20}}>
                    <p style={{fontSize:22, fontWeight:'bold',margin:0}}>
                        My Portfolios
                    </p>
                    <p style={{fontSize:14, fontWeight:''}}>
                        Scroll to see your linked and saved portfolios
                    </p>
                </div>
                <div style={{display: 'flex', alignItems:'center', flexDirection:'column',marginTop:20, maxHeight: window.innerHeight*.4, overflowY:'scroll', }}>
                    
                    <div style={{width:'100%'}}>
                        <div style={{display:'flex', width:'90%',margin:'auto'}}>
                            <p style={{textAlign:'left',margin:0,fontSize:20}}>Linked Accounts</p>
                        </div>
                        <div style={{display:'flex', width:'90%',margin:'auto', justifyContent:'space-between',marginTop:10,borderBottom:'solid', borderColor:'#dddddd',}}>
                            <p style={{textAlign:'left',margin:0}}>Institution</p>
                            <p style={{textAlign:'left',margin:0}}>Account Number</p>
                        </div>
                        <div style={{display:'flex', margin:'auto', flexDirection:'column', width:'100%', marginBottom: 20, }}>
                            {arr2.length > 0?  arr2 :<p style={{textAlign:'center',fontWeight:'bold'}}>You do not have any Linked Accounts</p>}
                        </div>
                    </div>
                    
                    
                    <div style={{width:'100%'}}>
                        <div style={{display:'flex', width:'90%',margin:'auto', marginTop:20, }}>
                            <p style={{textAlign:'left',margin:0, fontSize:20}}>Created Portfolios</p>
                        </div>
                        <div style={{display:'flex', width:'90%',margin:'auto', justifyContent:'space-between',marginTop:10, borderBottom:'solid', borderColor:'#dddddd',}}>
                            <p style={{textAlign:'left',margin:0}}>Strategy Name</p>
                            <p style={{textAlign:'left',margin:0}}>Date Created</p>
                        </div>
                        <div style={{display:'flex', margin:'auto', flexDirection:'column', width:'100%', marginBottom: 20,}}>
                            {arr.length > 0?arr:<p style={{textAlign:'center',fontWeight:'bold'}}>You do not have any Saved Portfolios</p>}
                        </div>
                    </div>
                   
                </div>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width: '100%',marginBottom:20}}>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'space-evenly', marginTop: 10, flexDirection:'row', marginRight:10, width:'100%'}}>
                        <div
                            id='YourPortfolioBack_Button' 
                            style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20,  cursor:'pointer'}} 
                            onClick={()=>{
                                this.setState({step:0})
                            }}
                        >
                            <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                                Back
                            </p>
                        </div>
                        <div
                            id='YourPortfolioNext_Button' 
                            style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20,  cursor:'pointer'}} 
                            onClick={()=>{
                                // this.setState({showing:false})
                                // new Promise(()=>this.massAdd())
                                // .then(this.setState({step:2}))
                                this.massAdd()
                                this.setState({step:2})
                            }}
                        >
                            <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                                Next
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    stepFile = ()=>{
        return(
            <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:'90%'}}>
                {/* <div style={{display:'flex', width:'100%',flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop:50}}>
                    <p style={{fontSize:22, fontWeight:'bold'}}>
                        Import File
                    </p>
                    <p style={{}}>
                        Enter asset symbols and the left and values on the right. As you fill up rows more rows will appear.
                    </p>
                </div> */}
                    <div style={{display:'flex', justifyContent:"center", alignItems:'center',padding:10, marginTop:20}}>
                        <img
                            src={importPic}
                            style={{width:'20%'}}
                        />
                    </div>
                    <div style={{display:'flex', margin:'auto', marginBottom: 0, flexDirection:'column', width:'90%', marginTop:20, marginBottom:20}}>
                        <p style={{margin:0, margin:'auto', fontSize: 14, color:'#afb2bb', textAlign:'center'}}>Your CSV file should have one column called 'Symbol' and another column called either 'Number of Shares' or 'Allocation'.</p>
                        <p style={{margin:0, margin:'auto', fontSize: 14, color:'#afb2bb', textAlign:'center'}}>If you have excess cash in your portfolio that you would like add please use '$$' as the symbol.</p>
                    </div>
                    <div style={{display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width: '80%', margin:'auto'}}>
                    <CSVReader
                        id='UploadPortfolioCSV_Button'
                        onDrop={this.handleOnDrop}
                        onError={this.handleOnError}
                        style={{}}
                        config={{}}
                        addRemoveButton
                        onRemoveFile={()=>this.handleOnRemoveFile}
                        onFileLoad={(e)=>{
                            // console.log('e',e)
                            let arr = []
                            let errorRows = []
                            let symColumn = -1
                            let quantityColumn = -1
                            let pcntColumn = -1
                            let dolValColumn = -1
                            // if(e[0].data.length !== 2){
                            //     this.setState({errors:'too many columns'})
                            // }
                            // else if(e[0].data[0].toLowerCase() !== 'symbol' || e[0].data[1].toLowerCase() !== 'number of shares'){
                            //     this.setState({errors:'incorrect column names'})
                            // }
                            /* LOOP THROUGH COLUMN NAMES TO DECIDE WHICH COLUMN IS WHICH */
                            e[0].data.forEach((ele, i)=>{ 
                                if(ele.toLowerCase() === 'symbol'|| ele.toLowerCase() === 'ticker' || ele.toLowerCase() === 'stock symbol' || ele.toLowerCase() === 'symb' || ele.toLowerCase() === 'symbols' || ele.toLowerCase() === 'stock' || ele.toLowerCase() === 'asset' ||ele.toLowerCase() === 'cusip' ||ele.toLowerCase() === 'company' || ele.toLowerCase() === 'security id'){
                                    if(symColumn === -1){
                                        symColumn = i
                                    }
                                }
                                if (ele.toLowerCase() === 'quantity' || ele.toLowerCase() === 'quantity of shares' || ele.toLowerCase() === 'number of shares' || ele.toLowerCase() === 'num of shares'|| ele.toLowerCase() === 'num shares'|| ele.toLowerCase() === 'shares'){
                                    if(quantityColumn === -1){
                                        quantityColumn = i
                                    }
                                }
                                if (ele.toLowerCase() === 'dollar value' || ele.toLowerCase() === 'value' || ele.toLowerCase() === '$ value' || ele.toLowerCase() === 'market value' || ele.toLowerCase() === 'mkt value' || ele.toLowerCase() === 'mkt. value' || ele.toLowerCase() === 'val'){
                                    if(dolValColumn === -1 && quantityColumn === -1){
                                        dolValColumn = i
                                    }
                                }
                                if (ele.toLowerCase() === 'allocation' || ele.toLowerCase() === '%' || ele.toLowerCase() === 'allocation %' || ele.toLowerCase() === '100%' || ele.toLowerCase() === 'percent' || ele.toLowerCase() === 'percent allocation' || ele.toLowerCase() === '% of portfolio' || ele.toLowerCase() === '% portfolio' || ele.toLowerCase() === 'weight' || ele.toLowerCase() === 'weight %' || ele.toLowerCase() === 'weight%' ){
                                    if(pcntColumn === -1 && dolValColumn === -1 && quantityColumn === -1){
                                        pcntColumn = i
                                    }
                                }
                                if (quantityColumn >=0){
                                    this.setState({massAddByShares: true})
                                    this.setState({massAddBy: 'quantity'})
                                }
                                else if (pcntColumn >=0){
                                    this.setState({massAddBy: 'allocation'})
                                }
                                else if (dolValColumn >=0){
                                    this.setState({massAddBy: 'dollarValue'})
                                }
                                else{
                                    this.setState({massAddByShares: false})
                                }
                            })

                            /* if the necessary columns are there loop through data and get necessary info  */
                            // console.log('symColumn: ',symColumn)
                            // console.log('quantityColumn: ',quantityColumn)
                            // console.log('pcntColumn: ',pcntColumn)
                            // console.log('dolValColumn: ',dolValColumn)

                            if(symColumn !== -1 && (quantityColumn !== -1 || pcntColumn !== -1 || dolValColumn !== -1)){
                                    e.forEach((ele,i)=>{
                                        if(ele.data[symColumn].length !== 0){
                                            if(ele.data[symColumn].length === 0){
                                                console.log(`error in row ${i} because missing data`)
                                                if(!errorRows.includes(i)){
                                                    errorRows.push(` ${i}`)
                                                } 
                                            }
                                            if (i>0 && quantityColumn !== -1 && isNaN((ele.data[quantityColumn]))){
                                                console.log(`error in row ${i} because missing data in quantity column`)
                                                if(!errorRows.includes(i)){
                                                    errorRows.push(` ${i}`)
                                                }
                                            }
                                            else if (i>0 && dolValColumn !== -1 && isNaN(this.removeDollarSign(ele.data[dolValColumn]))){
                                                console.log(`error in row ${i} because missing data in dolval column`)
                                                if(!errorRows.includes(i)){
                                                    errorRows.push(` ${i}`)
                                                }
                                            }
                                            else if (i>0 && pcntColumn !== -1 && isNaN(this.removePercentSign(ele.data[pcntColumn]))){
                                                console.log(`error in row ${i} because missing data in allocation column`)
                                                if(!errorRows.includes(i)){
                                                    errorRows.push(` ${i}`)
                                                }
                                            }
                                            else{
                                                // console.log(`success in row ${i}`)
                                                if(quantityColumn >= 0){
                                                    arr.push([ele.data[symColumn],ele.data[quantityColumn]])
                                                }else if(dolValColumn >= 0) {
                                                    arr.push([ele.data[symColumn],ele.data[dolValColumn]])
                                                }else{
                                                    arr.push([ele.data[symColumn],ele.data[pcntColumn]])
                                                    
                                                }
                                            }
                                        }
                                    })
                                    if (errorRows.length === 1){
                                        this.setState({errors: `problems in row: ${errorRows}`})
                                    }else if (errorRows.length > 1){
                                        this.setState({errors: `problems in rows: ${errorRows}`})
                                    }
                                    else{
                                        this.setState({massAddTable: arr.slice(1,arr.length), uploadSuccessful: true})
                                    }
                            }else if(symColumn !== -1){
                                console.log('only symbols')
                                console.log('e', e)
                                e.forEach((ele,i)=>{
                                    if(ele.data[symColumn].length !== 0 && i > 0){
                                        console.log('ele', ele)
                                        arr.push([ele.data[symColumn],0])
                                    }
                                })
                                console.log('arr',arr)
                                this.setState({massAddBy:'allocation', total:1000000, massAddTable: arr, noValues: true, errors:"We could not find any of the following optional categories: Quantity, Allocation, or Value, we Recommend adding one of these columns. Otherwise, Click 'Import' to import anyway"})
                            }
                            else{
                                if(symColumn === -1 ){
                                    this.setState({errors:'We could not find column: "Symbol"'})
                                }
                                else{
                                    this.setState({errors:"We could not find any of the following optional categories: Quantity, Allocation, or Value. Click 'Import' to import anyway"})
                                }
                            }
                        }}
                    >
                        <span>Drop CSV File Here or Click Browse Files</span>
                        {/* <span style={{width:'100%'}}>Click to Browse Files or Drag and Drop a .csv file </span> */}
                    </CSVReader>
                    </div>
                    <div style={{display:'flex',margin:'auto',width:'50%', marginTop: '1%'}}>
                        {this.showErrors()}
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'space-evenly', marginTop: 10, width:'100%'}}>
                        <div id='FileBack_Button' style={{display:'flex', alignItems:'center', justifyContent: 'center',border:'solid', borderColor:'#3b8e8c', borderRadius: 8, padding: 10, cursor:'pointer', marginBottom:20}} 
                            onClick={()=>{ this.setState({step:0})}}>
                            <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                                Back
                            </p>
                        </div>
                        <div id='FileConstructPortfolio_Button' style={{display:'flex', alignItems:'center', justifyContent: 'center',border:'solid', borderColor:'#3b8e8c', borderRadius: 8, padding: 10, cursor:'pointer', marginBottom:20}} onClick={()=>{
                            if(!this.state.errors || this.state.errors==="We could not find any of the following optional categories: Quantity, Allocation, or Value. Click 'Import' to import anyway"){
                                // console.log('this.state.massAddTable', this.state.massAddTable)
                                this.massAdd()
                                this.setState({step:2})
                            }
                            else{
                                this.setState({errors:'Please Uploaded Corrected CSV file'})
                            }
                            }}>
                            <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                                Convert to Portfolio
                            </p>
                        </div>
                    </div>
                </div>
        )
    }

    stepSymbol = ()=>{
        let total = 0
            let {massAddTable} = this.state
            let table 
            if (this.state.massAddBy === 'allocation'){
                table = [
                    <div style={{border:'none', display:'flex',justifyContent:'center',alignItems:'center',minWidth: 200, width:'100%' }}>
                        <div style={{borderRight:'none', width: '100%',}}>
                            <p style={{margin:0, textAlign:'center', fontWeight: 'bold'}}>Total Portfolio Value (USD)</p>
                        </div>
                        <div style={{width: '100%',}}>
                            <input style={{width:'100%', border:'none', textAlign:'center'}} type="number" value={this.state.total} onChange={(e)=> this.setTotal(parseFloat(e.target.value))}/>
                        </div>
                    </div>
                    ,
                    <div style={{border:'none',width: '100%', display:'flex', minWidth: 200}}>
                    <div style={{width:'100%', padding: 5, display:'flex', justifyContent:'center',alignItems:'center', borderRight:'none'}}>
                        <p style={{margin:0, fontWeight: 'bold'}}>Stock Symbol</p>
                    </div>
                    <div style={{width:'100%', padding: 5, display:'flex', justifyContent:'center',alignItems:'center', }}>
                        {this.state.massAddBy === 'quantity' ? <p style={{margin:0, fontWeight: 'bold'}}># of Shares</p>:null}
                        {this.state.massAddBy === 'allocation' ? <p style={{margin:0, fontWeight: 'bold'}}>Percentage</p>:null}
                        {this.state.massAddBy === 'dollarValue' ? <p style={{margin:0, fontWeight: 'bold'}}>Dollar Value</p>:null}
                    </div>
                </div>
                ]
            }else{
                table = [
                    <div style={{border:'none',width: '100%', display:'flex', minWidth: 200}}>
                        <div style={{width:'100%', padding: 5, display:'flex', justifyContent:'center',alignItems:'center', borderRight:'none'}}>
                            <p style={{margin:0, fontWeight: 'bold'}}>Stock Symbol</p>
                        </div>
                        <div style={{width:'100%', padding: 5, display:'flex', justifyContent:'center',alignItems:'center', }}>
                            {this.state.massAddBy === 'quantity' ? <p style={{margin:0, fontWeight: 'bold'}}># of Shares</p>:null}
                            {this.state.massAddBy === 'allocation' ? <p style={{margin:0, fontWeight: 'bold'}}>Percentage</p>:null}
                            {this.state.massAddBy === 'dollarValue' ? <p style={{margin:0, fontWeight: 'bold'}}>Dollar Value</p>:null}
                        </div>

                    </div>
                ]
            }
            massAddTable.forEach((ele, idx)=>{
                table.push(
                    <div style={{border:'none', display:'flex',justifyContent:'center',alignItems:'center',minWidth: 200, width:'100%', marginBottom:5 }}>
                        <div style={{borderRight:'none', width: '50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <input id={'StockSymbol_Input_' + idx} style={{width:'50%', textAlign:'center'}}type="text" placeholder="Stock Symbol" value={ele[0]} onChange={(e)=>this.setTableCell(e, idx, 0)}/>
                        </div>
                        <div style={{width: '50%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <input id={'NumberStocks_Input_' + idx} style={{width:'50%', textAlign:'center'}} type="number" value={ele[1]} onChange={(e)=>this.setTableCell(e, idx, 1)}/>
                        </div>
                    </div>
                )
            })

        return(
            <div style={{display:'flex', flexDirection: 'column', margin:'auto', borderRadius: 10, width:'90%' }}>
               <div style={{display:'flex', width:'100%',flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop:50}}>
                    <p style={{fontSize:22, fontWeight:'bold'}}>
                        Add by Symbol
                    </p>
                    <p style={{}}>
                        Enter asset symbols and the left and values on the right. As you fill up the rows more rows will appear.
                    </p>
                </div>
                <div style={{marginBottom:20,width:'100%',borderBottom: 'solid', borderWidth:'.5px', borderColor: '#dddddd'}}>
                    {this.addMethod()}
                </div>
                <div style={{display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column',overflowY:'scroll', maxHeight:150 }}>
                    {table}
                </div>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width: '90%', margin:'auto',marginBottom:20, }}>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'space-evenly', marginTop: 10, flexDirection:'row', marginRight:10, width:'100%' }}>
                        <div
                            id='SymbolBack_Button' 
                            style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20, cursor:'pointer'}} 
                            onClick={()=>{
                                this.setState({step:0})
                            }}
                        >
                            <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                                Back
                            </p>
                        </div>
                        <div style={{display:'flex', alignItems:'center', justifyContent: 'center', }}>
                            <div id='SymbolClear_Button' style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#f85e25', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20, cursor:'pointer'}} onClick={()=>this.setState({massAddTable:[["",""],["",""]]})}>
                                <p style={{margin: 0, fontWeight: 'bold', color: '#f85e25'}}>
                                    Clear
                                </p>
                            </div>
                        </div>
                        <div
                            id='SymbolNext_Button' 
                            style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20, cursor:'pointer'}} 
                            onClick={()=>{
                                this.massAdd()
                                this.setState({step:2})
                            }}
                        >
                            <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                                Next
                            </p>
                        </div>
                       
                    </div>
                </div>
            </div>
        )
    }

    stepWl = ()=>{
        const { myWatchlist } = this.props
        let mapped = []
        myWatchlist.forEach((ele, index)=>{
            mapped.push(
                <div style={{display:'flex', justifyContent:'space-around', width:'90%', margin:'auto', borderBottom:'solid', borderColor:'#dddddd', borderWidth:'0.5px', padding: 8}}>
                    <div style={{width:'10%'}}>
                        <input id={'PortfolioWatchlistOption_' + index} value={this.inSelection(ele)} onChange={()=>this.wlselection(ele)} type="checkbox"/>
                    </div>
                    <div style={{width:'20%'}}>
                        <p style={{margin:0}}>{ele.symbol}</p>
                    </div>
                    <div style={{width:'60%',}}>
                        <p style={{margin:0,whiteSpace: 'nowrap', overflow: 'hidden',  textOverflow: 'ellipsis'}}>{ele.company}</p>
                    </div>
                    <div style={{width:'10%'}}>
                        <p style={{margin:0, textAlign:'right'}}>${ele.priceUSD ? ele.priceUSD.toFixed(2):'$00.00'}</p>
                    </div>
                </div>
            )
        })
        return(
            <div>
                <div style={{display:'flex', width:'100%',flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop:25}}>
                    <p style={{fontSize:22, fontWeight:'bold'}}>
                        IMPORT PORTFOLIO
                    </p>
                    <p style={{}}>
                        Select which assets to add to your portfolio
                    </p>
                </div>
                <div style={{display: 'flex', alignItems:'center', flexDirection:'column',marginTop:20, maxHeight: window.innerHeight*.5, overflowY:'scroll', }}>
                    {mapped}
                </div>
                <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent: 'space-evenly', marginTop:25, marginBottom:25}}>
                    <div id='PortfolioWatchlistBack_Button' style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, padding: 10, width: '30%', cursor:'pointer'}} 
                        onClick={()=>{
                            
                            this.setState({
                                
                                step:0,
                                
                            })
                        }}>
                        <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                            Back
                        </p>
                    </div>
                    <div id='PortfolioWatchlistNext_Button' style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, padding: 10, width: '30%', cursor:'pointer'}} 
                        onClick={()=>{
                            let arr = []
                            this.state.selected.forEach(ele=>{
                                arr.push({...ele, allocation: 0, value: 0, shares:0 })
                            })
                            // let sudoPortfolioStocks = this.state.sudoPortfolioStocks.map(ele=>{
                            //     return(
                            //         {...ele, allocation: (ele.value/total*100).toFixed(2)}
                            //     )
                            // })
                            this.setState({
                                showWatchlist:false, 
                                selected:[],
                                sudoPortfolioStocks:[...arr,...this.state.sudoPortfolioStocks],
                                step:2,
                                noValues:true,
                                total:1000000,
                            })
                        }}>
                        <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                            Next
                        </p>
                    </div>
                    
                </div>
            </div>
        )
    }

    stepModal = ()=>{
        if(this.state.showSteps){
            return(
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                        <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen? '90%':'60%',height:this.state.step === 1.3 ||this.state.step === 1.1 ? '90%':'80%',}}>

                            <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                                <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>{`Step ${Math.floor(this.state.step)+1}`}</p>
                                <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.setState({showSteps:false})}> X </p>
                            </div>
                            <div style={{width:'100%',}}>
                                {this.whatStep()}
                            </div>
                            {/* <div style={{width:'80%', margin:'auto'}}>
                                <div style={{width:'100%', backgroundColor:'#dddddd', height:30, borderRadius:10, display:'flex', alignItems:'center',justifyContent:'flex-start'}}>
                                    <div style={{width:`${this.state.showSteps/3}%`, backgroundColor:'rgb(0,255,0)',borderRadius:10, height:'95%'}}>

                                    </div>
                                </div>
                            </div> */}
                        </div>
                </div>
            )
        }

    }

    stepZero = ()=>{
        return(
           <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{display:'flex', width:'90%',margin:'auto',flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop:50}}>
                    {this.props.isSmallScreen?null:
                    <img
                        src={ForeSCITELogo}
                        style={{height:200, marginTop:'-75px'}}
                    />}
                    <p style={{fontSize:22, fontWeight:'bold'}}>
                        CREATE PORTFOLIO
                    </p>
                    <p style={{}}>
                        Choose one of the following methods to import
                    </p>
                </div>
                <div style={{display:'flex', flexDirection:'row', width:'80%', margin:'auto', justifyContent:'space-evenly', flexWrap:'wrap', marginTop:25}}>
                    <div id='PorfolioContructorWatchlist_Button' style={{cursor:'pointer',}} onClick={()=>this.setState({step:1.1})}>
                        <div style={{backgroundColor:'rgb(222,223,224)', borderRadius:8, height: 100, width:100, display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img 
                                src={Eye}
                            />
                        </div>
                        <div style={{width:'100%', justifyContent:'center',alignItems:'center'}}>
                            <p style={{color:'rgb(178,179,180)', textAlign:'center'}}>
                                Watchlist
                            </p>
                        </div>
                    </div>
                    <div id='PorfolioContructorSymbols_Button' style={{cursor:'pointer',}} onClick={()=>this.setState({step:1.2})}>
                        <div style={{backgroundColor:'rgb(222,223,224)', borderRadius:8, height: 100, width:100, display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img 
                                src={Ticket}
                            />
                        </div>
                        <div style={{width:'100%', justifyContent:'center',alignItems:'center'}}>
                            <p style={{color:'rgb(178,179,180)', textAlign:'center'}}>
                                Symbols
                            </p>
                        </div>
                    </div>
                    <div id='PorfolioContructorFile_Button' style={{cursor:'pointer',}} onClick={()=>this.setState({step:1.3})}>
                        <div style={{backgroundColor:'rgb(222,223,224)', borderRadius:8, height: 100, width:100, display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img 
                                src={File}
                            />
                        </div>
                        <div style={{width:'100%', justifyContent:'center',alignItems:'center'}}>
                            <p style={{color:'rgb(178,179,180)', textAlign:'center'}}>
                                File
                            </p>
                        </div>
                    </div>
                    <div id='PorfolioContructorMyPortfolio_Button' style={{cursor:'pointer',}} onClick={()=>this.setState({step:1.4})}>
                        <div style={{backgroundColor:'rgb(222,223,224)', borderRadius:8, height: 100, width:100, display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <img 
                                src={ReOptImg}
                            />
                        </div>
                        <div style={{width:'100%', justifyContent:'center',alignItems:'center'}}>
                            <p style={{color:'rgb(178,179,180)', textAlign:'center'}}>
                                My Portfolios
                            </p>
                        </div>
                    </div>
                    
                </div>
                {/* <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:25}}>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, padding: 10, width: '20%', cursor:'pointer'}} 
                        onClick={()=>{
                            let total = this.state.total + this.state.cash
                            let sudoPortfolioStocks = this.state.sudoPortfolioStocks.map(ele=>{
                                return(
                                    {...ele, allocation: (ele.value/total*100).toFixed(2)}
                                )
                            })
                            this.setState({step:this.state.step+1})
                        }}
                    >
                        <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                            Next
                        </p>
                    </div>
                </div> */}
            </div>
        )
    }

    stepThree = ()=>{
        return(
           <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{display:'flex', width:'80%',flexDirection:'column', justifyContent:'center', alignItems:'center', margin:'auto',marginTop:75}}>
                    <p style={{fontSize:22, fontWeight:'bold'}}>
                        Add Cash
                    </p>
                    <p style={{textAlign:'center'}}>
                       If there is any cash in your account that is not allocated to any asset enter it below. All amounts should be in U.S. Dollars.
                    </p>
                </div>
                <div style={{display:'flex', width:'80%',flexDirection:'column', justifyContent:'center', alignItems:'center', margin:'auto',}}>
                    <input id='CashAllocation_Input' style={{textAlign:'right'}} type="number" value={this.state.cash} onChange={(e)=>{
                            let cash = parseFloat(e.target.value)
                            this.setState({cash})
                        }
                        }/>
                </div>
                <div style={{display:'flex', justifyContent:'space-evenly', alignItems:'center', width:'100%', marginTop:25}}>
                    {/* <div 
                        style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, padding: 10, width: '20%', cursor:'pointer'}}
                        onClick={()=>this.setState({step:2})}
                    >
                        <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                            Back
                        </p>
                    </div> */}
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, padding: 10, width: '20%', cursor:'pointer'}} 
                        id='AddCashNext_Button'
                        onClick={()=>{
                            let total
                            if(this.state.total === 1000000 && this.state.cash > 0){
                                // console.log('1-check')
                                total = this.state.cash
                            }
                            else if(this.state.total === 1000000 && this.state.cash === 0){
                                // console.log('2-check')
                                total  = this.state.total
                                // console.log('total', total)
                            }else{
                                // console.log('3-check')
                                total = this.state.cash + this.state.total
                            }
                            let sudoPortfolioStocks = []
                            total = parseFloat(this.state.cash)
                            this.state.sudoPortfolioStocks.forEach(ele=>{
                                total += parseFloat(ele.value)
                            })
                            this.state.sudoPortfolioStocks.forEach(ele=>{
                                let allocation = (ele.value/total*100).toFixed(2)
                                sudoPortfolioStocks.push(
                                    {...ele, allocation }
                                )
                            })
                            this.setState({
                                total,
                                step:this.state.step+1, 
                                sudoPortfolioStocks,
                                noValues: total === 1000000 || (total === 0 && this.state.cash === 0) ? true : false,
                                showing:true,
                            })
                        }}
                    >
                        <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                            Next
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    stepFour = ()=>{
        const { showing } = this.state;
        return(
           <div style={{display:'flex', flexDirection:'column'}}>
               <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%'}}>
                    <img
                        src={Complete}
                        style={{width:'20%', marginTop:25}}
                    />
               </div>
                <div style={{display:'flex', width:'80%',flexDirection:'column', justifyContent:'center', alignItems:'center', margin:'auto',marginTop:25}}>
                    <p style={{fontSize:22, fontWeight:'bold'}}>
                        Complete!
                    </p>
                    <p style={{textAlign:'center'}}>
                        Your portfolio has been constructed.
                    </p>
                </div>
                <div style={{display:'flex', width: '100%', justifyContent:'space-evenly', alignItems:'center'}}>
                    
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:25}}>
                        <div id='RestartSteps_Button' style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, padding: 10, cursor:'pointer'}} 
                            onClick={()=>this.setState({step:0, sudoPortfolioStocks:[], cash:0})}
                        >
                            <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                                Restart
                            </p>
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:25}}>
                        <div id='ViewPortfolio_Button' style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'rgb(17,17,17)', borderRadius: 8, padding: 10, cursor:'pointer'}} 
                            onClick={()=>{
                                let total = this.state.total + this.state.cash
                                // let sudoPortfolioStocks = this.state.sudoPortfolioStocks.map(ele=>{
                                //     return(
                                //         {...ele, allocation: (ele.value/total*100).toFixed(2)}
                                //     )
                                // })
                                this.setState({showSteps:false, step:0})
                            }}
                        >
                            <p style={{margin: 0, color: 'rgb(17,17,17)', fontWeight: 'bold' }}>
                                View
                            </p>
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:25}}>
                        <div id='OptimizePortfolio_Button' style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, padding: 10, cursor:'pointer'}} 
                            onClick={
                                // netspin
                                
                                () => {this.setState({ showing:false });
                                console.log("showing",showing);
                                // this.hideComponent("showHideDemo3"),
                                this.optimizeWithConstraints();}
                            }
                        >
                                { showing 
                                ? 
                                <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                                    Optimize
                                </p>
                                : <Spinner animation="border" id="spinner" variant="dark" role="status" >
                                <span className="sr-only">Loading...</span>
                                </Spinner>
                                }
                            </div>

                    </div>
                  
                    
                </div>
            </div>
        )
        
    }

    stepTwo = ()=>{
        return(
            <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{display:'flex', width:'100%',flexDirection:'column', justifyContent:'center', alignItems:'center', marginTop:50}}>
                    <p style={{fontSize:22, fontWeight:'bold'}}>
                        SET CONSTRAINTS
                    </p>
                    <p style={{}}>
                        Choose your Portfolio Objective and Risk Level
                        
                    </p>
                </div>
                <div style={{display:'flex', width:'95%', justifyContent:'center', alignItems:'center', margin:'auto', flexDirection:'column'}}>
                    <p style={{ margin:0, fontSize: 20, }}>Objective: </p>
                    <div style={{display:'flex', width:'100%', flexDirection:this.props.isSmallScreen?'column':'row', justifyContent:'center'}}>
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center', marginRight:'2%'}}>
                            <input style={{cursor:'pointer'}}type="radio" checked={!this.state.returnsConstraint} onChange={()=>this.setState({returnsConstraint:false})} />
                            <label style={{margin:0, fontSize: '1em', marginLeft: 4}}>Maximize Sharpe</label>
                        </div>
                        {/* <div style={{display:'flex', justifyContent:'center',alignItems:'center', }}>
                            <input style={{cursor:'pointer'}}type="radio" checked={this.state.returnsConstraint} onChange={()=>this.setState({returnsConstraint:true})}/>
                            <label style={{margin:0, fontSize: '1em', marginLeft: 4}}>Increase Returns</label>
                        </div> */}
                    </div>
                </div>
                <div>
                <div style={{width:'80%', display:'flex', justifyContent:'flex-start', alignItems:'center', flexDirection:'column', margin:'auto', marginTop:25}}>
                            <div style={{ width: '100%',display:'flex',justifyContent:'center', alignItems:'center', }}>
                                <p style={{ margin:0, fontSize: 20, }}>Risk Tolerance: </p>
                            </div>
                            <div style={{display:'flex', width:'100%',justifyContent:'center',alignItems:'center', flexDirection:'column'}}>
                                <div style={{display:'flex', justifyContent:'center',alignItems:'center', marginRight:'2%'}}>
                                    <input style={{}}type="range" 
                                    
                                        min="1"
                                        max="6" 
                                        step={1}
                                        value={this.state.rangeVal}
                                        onChange={e => this.setState({ rangeVal:e.target.value })}
                                    />
                                </div>
                                <div style={{display:'flex', justifyContent:'center',alignItems:'center', }}>
                                    <label style={{margin:0, fontSize: '1em', marginLeft: 4}} for="returns">{this.rangeText()}</label>
                                </div>
                            </div>
                        </div>
                </div>
                <div style={{width:'100%', display:'flex', justifyContent:'space-evenly', alignItems:'center', marginTop:40}}>
                    <div 
                        style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, padding: 10, width: '20%', cursor:'pointer'}}
                        onClick={()=>this.setState({step:0})}
                    >
                        <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                            Back
                        </p>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, padding: 10, width: '20%', cursor:'pointer'}} 
                        onClick={()=>{
                            let total = this.state.total + this.state.cash
                            let sudoPortfolioStocks = this.state.sudoPortfolioStocks.map(ele=>{
                                return(
                                    {...ele, allocation: (ele.value/total*100).toFixed(2)}
                                )
                            })
                            this.setState({step:this.state.step+1})
                        }}
                    >
                        <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold' }}>
                            Next
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    checkLogo = (symbol)=>{
        let url = `https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`
        var http = new XMLHttpRequest();
        http.open('HEAD', url, false);
        http.send();
        if (http.status != 404){
            return (
              <div style={{display:'flex', cursor:'pointer', justifyContent:'center',alignItems:'center',backgroundColor:'rgb(255,255,255)', borderRadius:7,overflow:'hidden', width:70, height:70,justifySelf:'flex-end',}}>
                    <img 
                        // src={`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`}
                        src={`https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`}
                        onError="this.onerror=null; this.remove();" 
                        style={{objectFit:'contain', width:70,height:70, }}
                    />
                </div>
            )
        }else{
            return(
                
                <div style={{objectFit:'contain', width:50,height:50,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'rgb(17,17,17)',borderRadius:10 }}>
                    <p style={{margin:0, fontSize:24,fontWeight:'bold', color:'rgb(255,255,255)'}}>{symbol[0]}</p>
                </div>
            
            )
        }
    }

    render() {
        return (
            <div style={{ width: '100%', paddingLeft:'4%',paddingRight:'4%',paddingTop:50,paddingBottom:50, borderRadius: '50px', backgroundColor:'#FFFFFF',}} /*onMouseMove={()=>this.setState({hover:!this.state.hover})}*/ /* onClick={this.state.dropDown? null:()=>this.setState({dropDown:false})} */>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', filter: this.state.showMassAdd || this.state.showSteps?'blur(10px)':null }}>
                    {this.props.isSmallScreen ? this.showWlbar() :null}
                    {/* {this.state.showWatchlist?
                    <div style={{
                        width: '100%',
                    }}>
                        {this.showTopTickers()}
                    </div> : null} */}
                    <div style={{
                        width: '100%',
                        height: this.state.sudoPortfolioStocks.length <1? 675:''
                    }}>
                        {this.props.isSmallScreen? this.showBottomTickersSmall() : this.showBottomTickers()}
                    </div>
                </div>
                {/* {this.massAddModal()}
                {this.fileImportModal()}
                {this.customsModal()}
                {this.cashModal()}
                {this.wlModal()} */}
                {this.stepModal()}
                {this.addRow()}
                {this.state.showErrorModal?<ErrorModal errorType={this.state.errors} closeError={()=>this.setState({showErrorModal:false})}/>:null}
            </div>
        )
    }
}