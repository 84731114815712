import React from 'react'
import MainNavBar from '../components/MainNavBar'
import '../../scss/pages/Support.scss'
import PublicFooter from '../PublicFooter'
import i18n from 'i18next'

export default function Support(props) {
  return (
    <>
      <MainNavBar {...props} colorChange={true} />
      <div className='support-content'>
        <p className='support-header'>{props.t('frequentlyaskedquestions')}</p>
        <div className='support-container'>
          {i18n.language === 'zh' ? (
            <>
              <a
                href='https://investallfaqs.s3.amazonaws.com/Sign+in+and+Sign+up+-+ch.pdf'
                target='_blank'
                className='support-link'
              >
                {props.t('signin')}/{props.t('signup')}
              </a>
              <a
                href='https://investallfaqs.s3.amazonaws.com/Fund+Transfers+-+ch.pdf'
                target='_blank'
                className='support-link'
              >
                {props.t('transferfunds')}
              </a>
              <a
                href='https://investallfaqs.s3.amazonaws.com/Account+Documents+-+ch.pdf'
                target='_blank'
                className='support-link'
              >
                {props.t('accounts')}
              </a>
              <a
                href='https://investallfaqs.s3.amazonaws.com/Good+Faith+Violation+-+ch.pdf'
                target='_blank'
                className='support-link'
              >
                {props.t('goodfaithviolation')}
              </a>
              <a
                href='https://investallfaqs.s3.amazonaws.com/Trading+and+Investing+-+ch.pdf'
                target='_blank'
                className='support-link'
              >
                {props.t('tradingandinvesting')}
              </a>
              <a
                href='https://investallfaqs.s3.amazonaws.com/Investall+pro+Subscription+-+ch.pdf'
                target='_blank'
                className='support-link'
              >
                {props.t('investallprosubscription')}
              </a>
            </>
          ) : (
            <>
              <a
                href='https://investallfaqs.s3.amazonaws.com/Sign+in+and+sign+up+-+en.pdf'
                target='_blank'
                className='support-link'
              >
                {props.t('signin')}/{props.t('signup')}
              </a>
              <a
                href='https://investallfaqs.s3.amazonaws.com/Fund+Transfers+-+en.pdf'
                target='_blank'
                className='support-link'
              >
                {props.t('transferfunds')}
              </a>
              <a
                href='https://investallfaqs.s3.amazonaws.com/Account+Documents+-+en.pdf'
                target='_blank'
                className='support-link'
              >
                {props.t('accounts')}
              </a>
              <a
                href='https://investallfaqs.s3.amazonaws.com/Good+Faith+Violation+-+en.pdf'
                target='_blank'
                className='support-link'
              >
                {props.t('goodfaithviolation')}
              </a>
              <a
                href='https://investallfaqs.s3.amazonaws.com/Trading+and+Investing+-+en.pdf'
                target='_blank'
                className='support-link'
              >
                {props.t('tradingandinvesting')}
              </a>
              <a
                href='https://investallfaqs.s3.amazonaws.com/Investall+pro+Subscription+-+en.pdf'
                target='_blank'
                className='support-link'
              >
                {props.t('investallprosubscription')}
              </a>
            </>
          )}
        </div>
      </div>
      <PublicFooter isSmallScreen={props.isSmallScreen} t={props.t} />
    </>
  )
}
