import React from 'react'

export default class ErrorModal extends React.Component{

    displayErrorMessage = ()=>{
        switch (this.props.errorType) {
            case "zeroShares":
                return 'You cannot optimize until all selected stocks have a selected number of shares'
            case "requireLogin":
                return 'You must login to access this feature'
            case "requireSubscription":
                return 'You must subscribe to access this feature'
            default:
                return 'ERROR'
        }
    }

    render(){
        return(
            <div style={{
                backgroundColor: 'transparent',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', width: '100%', backgroundColor: 'rgb(255,255,255)', borderRadius: 12, border:'solid', width: '60%'}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', }}>
                        <div style={{width:'20%'}}/>
                        <h2>Error</h2>
                        <div style={{width:'20%',}}>
                            <div style={{backgroundColor:'rgb(253,234,234)', borderRadius: 10, width: 40, display: 'flex', justifyContent:'center', alignItems: 'center', cursor: 'pointer'}} onClick={()=>this.props.closeError()}>
                                <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(187,58,60)'}}>
                                    X
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                        <p>
                            {this.displayErrorMessage()}
                        </p>
                    </div>
                    
                </div>
            </div>
          )
        
    }
}