import React, { Component } from "react";
import FeaturePart from "../FeaturePart";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import PieChartOfFeature from "../PieChartOfFeature";
import FeatureHiddenText from "../FeatureHiddenText";

const pre = "<";
const next = ">";

class ChineseFeatures extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.state = {
      isSmallScreen: false,
      isEnglish: this.props.language,
      features: [
        {
          id: 1,
          content: [
            {
              id: 1,
              iconUrl: `/images/AI-1.png`,
              title: `自动定期平衡资产管理`,
              detail: `您选择策略和投资金额，AI优化交易订单以最大程度地降低风险并获得回报。`
            },
            {
              id: 2,
              iconUrl: `/images/AI-2.png`,
              title: `税率优化`,
              detail: `AllocateRite建议对节税型ETF进行投资，并自动解决您的独特税务情况。`
            },
            {
              id: 3,
              iconUrl: `/images/AI-3.png`,
              title: `24小时风险监控和报告`,
              detail: `AI提供主动的风险监控和严谨的投资决策执行，通过市场的风险运转帮助指导投资组合（个人）。`
            }
          ]
        },
        {
          id: 2,
          content: [
            {
              id: 1,
              iconUrl: `/images/TRUST-1.png`,
              title: `提供专业投资公司使用的策略`,
              detail: `AllocateRite通过其四种核心策略提供解决方案，并为用户提供量身定制的alpha覆盖和BlackRock Aladdin<a href="http://www.allocaterite.com/cn/Strategies">策略<\a>。`
            },
            {
              id: 2,
              iconUrl: `/images/TRUST-2.png`,
              title: `发布跟踪记录`,
              detail: `在不同<a href="http://www.allocaterite.com/cn/Strategies">条件<\a>下测试和建立事件。`
            },
            {
              id: 3,
              iconUrl: `/images/TRUST-3.png`,
              title: `符合GIPS标准并经过验证`,
              detail: `我们确保AllocateRite跟踪记录可靠且详尽。`
            }
          ]
        },
        {
          id: 3,
          content: [
            {
              id: 1,
              iconUrl: `/images/CONTROL-1.png`,
              title: `挑选您的经纪人`,
              detail: `您可以从<a href="http://www.allocaterite.com/ProvideList">2500名托管商</a>中选择您的首选经纪人。`
            },
            {
              id: 2,
              iconUrl: `/images/CONTROL-2.png`,
              title: `选择ETF发行人`,
              detail: `基于您的选择，ETF可以很好地追踪您的每笔交易，并且它们可以可靠地加入到您任何决策中。`
            },
            {
              id: 3,
              iconUrl: `/images/CONTROL-3.png`,
              title: `拥有投资完全自由裁量权`,
              detail: `您拥有对您资本的保管权，并且是所有投资决策的最终决定者。`
            }
          ]
        }
      ],
      chartData: [
        {
          datasets: [
            {
              id: 1,
              data: [70.97, 25, 4.03],
              backgroundColor: [
                "rgba(126, 192, 101, 0.8)",
                "rgba(241, 98, 104, 0.8)",
                "rgba(93, 199, 208, 0.8)"
              ],
              borderColor: "#FFFFFF",
              borderWidth: "6",
              hoverBackgroundColor: [
                "rgba(126, 192, 101, 2)",
                "rgba(241, 98, 104, 2)",
                "rgba(93, 199, 208, 2)"
              ]
            }
          ],
          labels: ["Equity", "Fixed-Income", "Cash"]
        },
        {
          datasets: [
            {
              id: 2,
              data: [76.63, 21.32, 2.05],
              backgroundColor: [
                "rgba(126, 192, 101, 0.8)",
                "rgba(241, 98, 104, 0.8)",
                "rgba(93, 199, 208, 0.8)"
              ],
              borderColor: "#FFFFFF",
              borderWidth: "6",
              hoverBackgroundColor: [
                "rgba(126, 192, 101, 2)",
                "rgba(241, 98, 104, 2)",
                "rgba(93, 199, 208, 2)"
              ]
            }
          ],
          labels: ["Equity", "Fixed-Income", "Cash"]
        },
        {
          datasets: [
            {
              id: 3,
              data: [86.33, 13.67, 0],
              backgroundColor: [
                "rgba(126, 192, 101, 0.8)",
                "rgba(241, 98, 104, 0.8)",
                "rgba(93, 199, 208, 0.8)"
              ],
              borderColor: "#FFFFFF",
              borderWidth: "6",
              hoverBackgroundColor: [
                "rgba(126, 192, 101, 2)",
                "rgba(241, 98, 104, 2)",
                "rgba(93, 199, 208, 2)"
              ]
            }
          ],
          labels: ["Equity", "Fixed-Income", "Cash"]
        },
        {
          datasets: [
            {
              id: 4,
              data: [92.5, 7.5, 0],
              backgroundColor: [
                "rgba(126, 192, 101, 0.8)",
                "rgba(241, 98, 104, 0.8)",
                "rgba(93, 199, 208, 0.8)"
              ],
              borderColor: "#FFFFFF",
              borderWidth: "6",
              hoverBackgroundColor: [
                "rgba(126, 192, 101, 2)",
                "rgba(241, 98, 104, 2)",
                "rgba(93, 199, 208, 2)"
              ]
            }
          ],
          labels: ["Equity", "Fixed-Income", "Cash"]
        }
      ],
      prevIcon: <span className="glyphicon glyphicon-glass">{pre}</span>,
      nextIcon: <span className="glyphicon glyphicon-glass">{next}</span>,
      showResults: false
    };
  }

  onClick() {
    if (this.state.showResults) {
      this.setState({ showResults: false });
    } else {
      this.setState({ showResults: true });
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    if (window.innerWidth < 600) {
      this.setState({ isSmallScreen: true });
    }
  }

  render() {
    const { nextIcon, prevIcon } = this.state;
    return (
      <div>
        <Container>
          <Row className="features-subtitle-row">
            <p className="features-subtitle-text">人工智能资产管理</p>
          </Row>

          <Row>
            <Col sm={6} className="features-content">
              <FeaturePart
                key={this.state.features[0].id}
                value={this.state.features[0]}
              />
            </Col>
            <Col sm={2} />
            <Col sm={4} className="features-content-icons">
              <img src="/images/cloudAI.svg" width="70%" alt="features-img" />
            </Col>
          </Row>

          <Row className="features-subtitle-row">
            <p className="features-subtitle-text">值得信任</p>
          </Row>
          {this.state.isSmallScreen ? (
            <Row>
              <Col sm={6} className="features-content">
                <FeaturePart
                  key={this.state.features[1].id}
                  value={this.state.features[1]}
                />
              </Col>
              <Col sm={2} />
              <Col sm={4} className="features-content-icons">
                <img src="/images/compass.svg" width="67%" alt="features-img" />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm={5} className="features-content-icons">
                <img
                  className="second-img-of-features"
                  src="/images/compass.svg"
                  width="67%"
                  alt="features-img"
                />
              </Col>
              <Col sm={6} className="features-content">
                <FeaturePart
                  key={this.state.features[1].id}
                  value={this.state.features[1]}
                />
              </Col>
              <Col sm={1} />
            </Row>
          )}

          <Row className="features-subtitle-row">
            <p className="features-subtitle-text">在您的掌控之中</p>
          </Row>

          <Row>
            <Col sm={6} className="features-content">
              <FeaturePart
                key={this.state.features[2].id}
                value={this.state.features[2]}
              />
            </Col>
            <Col sm={2} />
            <Col sm={4} className="features-content-icons">
              <img src="/images/control.svg" width="65%" alt="features-img" />
            </Col>
          </Row>

          <Row className="features-subtitle-row">
            <p className="features-subtitle-text">多样化</p>
          </Row>
          <Row className="features-diversified">
            <Col sm={5} className="features-diversified-chart">
              <Carousel
                className="diversified-charts-switch"
                nextIcon={nextIcon}
                prevIcon={prevIcon}
              >
                <Carousel.Item>
                  <PieChartOfFeature
                    key={this.state.chartData[0].id}
                    value={this.state.chartData[0]}
                    titleText="International Global Composite 2019 - Aug"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <PieChartOfFeature
                    key={this.state.chartData[1].id}
                    value={this.state.chartData[1]}
                    titleText="Dynamic Global Blend 2019 - Aug"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <PieChartOfFeature
                    key={this.state.chartData[2].id}
                    value={this.state.chartData[2]}
                    titleText="U.S. Domestic Composite AI Adjust 2019 - Aug"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <PieChartOfFeature
                    key={this.state.chartData[3].id}
                    value={this.state.chartData[3]}
                    titleText="U.S. Domestic Composite 2019 - Aug"
                  />
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col sm={1} />
            <Col sm={5} className="diversified-features-content">
              <p className="diversified-title">板块基金ETF和现金管理</p>
              <p className="diversified-text-show">
                AllocateRite投资股票板块基金（ETF）,包括代表标普500十一个主要板块的ETF，同时也投资代表债券和美国以外的国际金融市场的各类ETF，并在投资组合里保有部分现金。这个平台通过选取这些ETF以及保有一定比例的现金仓位来实现资产最优化配置，从而更好地管理风险并降低税负。
              </p>
              <input
                className="diversified-text-button"
                type="submit"
                value={this.state.showResults ? "向上收起" : "显示更多..."}
                onClick={this.onClick}
              />
              {this.state.showResults ? (
                <FeatureHiddenText isChinese={true} />
              ) : null}
            </Col>
            <Col sm={1} />
          </Row>
        </Container>
      </div>
    );
  }
}

export default ChineseFeatures;
