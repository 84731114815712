import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import '../../scss/components/ConfirmationDialog.scss'

export default class ConfirmationDialog extends Component {
  //PROPS
  //action: function to call when they want to proceed
  //toggleShowConfirmationDialog: function to toggle this dialog
  //successMessage: success message to show when action completes successfully
  //showConfirmationDialog: boolean to show dialog or not
  //name: string for button label
  //btnClassName: String for classname of the button
  //customHeader: String to change header if needs to be changed from default
  //customMessage : String to show message
  state = {
    isLoading: false,
    errorMessage: '',
    successMessage: '',
  }

  toggleErrorMessage = (msg) => {
    this.setState({ errorMessage: msg })
    setTimeout(() => {
      this.setState({ errorMessage: '' })
    }, 10000)
  }

  submitButton = async () => {
    this.setState({ isLoading: !this.state.isLoading })
    let status = await this.props.action()
    if (!status) {
      this.setState({ isLoading: !this.state.isLoading })
      this.toggleErrorMessage(this.props.t('somethingwentwrong'))
    } else {
      this.setState({
        successMessage: this.props.successMessage,
        isLoading: false,
      })
    }
  }
  hideDialog = () => {
    this.setState({ isLoading: false, errorMessage: '', successMessage: '' })
    this.props.toggleShowConfirmationDialog()
  }
  render() {
    if (this.props.t) {
      return (
        <div>
          <Dialog
            closable={!this.state.isLoading}
            closeOnEscape={!this.state.isLoading}
            visible={this.props.showConfirmationDialog}
            style={{ width: this.props.isSmallScreen ? '90vw' : '30vw' }}
            header={
              this.state.isLoading ? (
                <p className='dialog-header'>
                  {this.props.t('pleasedonotleavethispage')}
                </p>
              ) : (
                <p className='dialog-header'>
                  {this.props.customHeader
                    ? this.props.customHeader
                    : this.props.t('areyousureyouwanttoproceed')}
                </p>
              )
            }
            onHide={() => this.hideDialog()}
          >
            {this.state.successMessage === '' ? (
              <>
                {' '}
                <p className='message'>{this.props.customMessage}</p>
                <p className='error'>{this.state.errorMessage}</p>
                {this.state.isLoading ? (
                  <Button
                    className='confirm-dialog-submit-btn'
                    label={this.props.t('inprogress')}
                    loading
                  />
                ) : (
                  <Button
                    className={`confirm-dialog-submit-btn ${this.props.btnClassName}`}
                    label={this.props.name}
                    onClick={() => {
                      this.submitButton()
                    }}
                  />
                )}
              </>
            ) : (
              <p className='success'>{this.state.successMessage}</p>
            )}
          </Dialog>
        </div>
      )
    } else return null
  }
}
