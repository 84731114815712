import React from 'react'
import StockNews from './groupedData/StockNews'
import NewtDownloadButton from '../../buttons/NewtDownloadButton'
import { MdOutlineFileDownload } from 'react-icons/md'
import { MemoizedPieChart } from './groupedData/PieChart'
import SimpleTable from './groupedData/SimpleTable'
import StructuredText from './groupedData/StructuredText'

export default function GroupedDataContent(props) {
  const groupDataRender = () => {
    if (
      !props.data.data?.display_group ||
      props.data.data?.display_group?.length === 0
    ) {
      return <p>error</p> // Return null if there are no groups to display
    }
    return (
      <div className='grouped-data-content'>
        {props.data.data?.display_group?.map((group, index) => {
          const isEmptyObject = Object?.keys(group)?.length === 0
          return (
            <React.Fragment key={index}>
              {!isEmptyObject && (
                <div key={index}>
                  {group?.level_title && (
                    <p className='group-level-title'>{group?.level_title}</p>
                  )}
                  {buildNewtGroupsLevel(group?.groups, props.data.data)}
                </div>
              )}
            </React.Fragment>
          )
        })}
      </div>
    )
  }

  const buildNewtGroupsLevel = (level_data, newt_res_data) => {
    if (!level_data || level_data?.length === 0) {
      return null // Returns null if there's no data to display
    }

    return (
      <div className='level-container'>
        {level_data?.map((data, index) => {
          return (
            <div key={index} className='level-content'>
              {buildNewtGroupsLevelSection(data, newt_res_data)}
            </div>
          )
        })}
      </div>
    )
  }

  const buildNewtGroupsLevelSection = (section_data, newt_res_data) => {
    if (!section_data || section_data?.length === 0) {
      return null // Returns null if there's no data to display
    }

    return (
      <>
        {section_data?.map((data, index) => (
          <React.Fragment key={index}>
            {buildNewtGroupsLevelSectionGroup(data, newt_res_data)}
          </React.Fragment>
        ))}
      </>
    )
  }

  const buildNewtGroupsLevelSectionGroup = (group_data, newt_res_data) => {
    if (!group_data || Object?.keys(group_data)?.length === 0) {
      return null // Return null if there's no data to display
    }

    return (
      <React.Fragment>
        {buildNewtSpecificGroupUI(group_data, newt_res_data)}
      </React.Fragment>
    )
  }

  const buildNewtSpecificGroupUI = (data, newt_res_data) => {
    const isEmpty = (value) => {
      if (Array.isArray(value)) {
        return value?.length === 0
      } else if (value && typeof value === 'object') {
        return Object?.keys(value)?.length === 0
      }
      return !value
    }

    if (!data) return null // Ensure there is data to process

    if (isEmpty(newt_res_data?.result_data?.[data?.group_reference])) {
      return null
    }

    const renderGroupContent = () => {
      switch (data?.group_type) {
        // case 'nested_table':
        //   return buildNewtGroupsNestedTable(
        //     data?.group_reference,
        //     data?.table_info,
        //     newt_res_data
        //   )
        case 'pie_chart':
          return (
            <MemoizedPieChart
              group_data={data}
              group_reference={data?.group_reference}
              newt_res_data={newt_res_data}
            />
          )
        case 'structured_text':
          return (
            <StructuredText
              keys={data?.group_keys}
              group_reference={data?.group_reference}
              newt_res_data={newt_res_data}
            />
          )
        case 'table':
          return (
            <SimpleTable
              group_reference={data?.group_reference}
              table_info={data?.table_info}
              newt_res_data={newt_res_data}
              table_data={data}
            />
          )
        // case 'bounded_line_chart':
        //   return (
        //     <MemoizedBoundedLineChart
        //       group_data={data}
        //       group_reference={data?.group_reference}
        //       newt_res_data={newt_res_data}
        //     />
        //   )
        // case 'multi_period_line_chart':
        //   return (
        //     <MemoizedMultiPeriodLineChart
        //       group_data={data}
        //       group_reference={data?.group_reference}
        //       newt_res_data={newt_res_data}
        //     />
        //   )
        // case 'bar_chart':
        //   return (
        //     <MemoizedBarChart
        //       group_data={data}
        //       group_reference={data?.group_reference}
        //       newt_res_data={newt_res_data}
        //     />
        //   )
        // case 'prompt_list':
        //   return buildAIThemeLists(data?.group_reference, newt_res_data)
        case 'stock_news':
          return (
            <StockNews
              groupReference={data?.group_reference}
              newtResData={newt_res_data}
            />
          )
        // return buildNewtGroupsNews(data?.group_reference, newt_res_data)
        // case "trade_file":
        //   return <BuildNewtTradePreview data={data} newt_res_data={newt_res_data} />;
        default:
          return null // Handle unknown group type
      }
    }

    return (
      <div className='newt-group-content-container'>
        {data?.group_title && (
          <p className='group-title'>{data?.group_title}</p>
        )}
        {renderGroupContent()}

        {(data?.download_csv_url || data?.download_excel_url) && (
          <div
            className={`download-container ${
              data?.news_prompt ? '' : 'w-full'
            }`}
          >
            {data?.download_csv_url && (
              <NewtDownloadButton
                downloadUrl={data?.download_csv_url}
                icon={<MdOutlineFileDownload className='download-button' />}
              >
                CSV
              </NewtDownloadButton>
            )}
            {data?.download_excel_url && (
              <NewtDownloadButton
                downloadUrl={data?.download_excel_url}
                icon={<MdOutlineFileDownload className='download-button' />}
              >
                Excel
              </NewtDownloadButton>
            )}
          </div>
        )}
      </div>
    )
  }

  return <div>{groupDataRender()}</div>
}
