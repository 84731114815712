import React from 'react'
import ReactHighcharts from "react-highcharts";

export default class ReturnsChart extends React.Component{
    state={}
    render(){
        if (!this.props.returnsChart){
            return(
                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <p style={{margin:0, fontSize:20}}>Loading...</p>
                </div>
            )
        }
        let returns = this.props.returnsChart
        const returnsData = returns.map(ele => [ele[0], Math.abs(ele[1].toFixed(2))])
        let pcnts = returnsData.map(ele => ele[1])
        let maxVal = Math.max(...pcnts)
        let minVal = Math.min(...pcnts)
        let maxRange = maxVal + ((maxVal - minVal) * 0.02)
        let minRange = minVal - ((maxVal - minVal) * 0.02)
        let lastreturns = returns[returns.length - 1]
        const config = {
          credits: {
            enabled: false,
          },
          chart: {
            zoomType: "x",
          },
          title: {
            text: "",
          },
          xAxis: {
            type: "datetime",
          },
          tooltip:{
            valueSuffix: '%'
          },
          yAxis: {
            title: {
              text: "Return",
            },
            maxZoom: 150,
            max: maxRange,
            min: minRange,
            labels: {
              format: "{value}%"
            }
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            area: {
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1,
                },
                stops: [
                  [0, '#8682cb'],
                  [1, 'rgba(120, 116, 182, 0)'],
                ],
              },
              marker: {
                radius: 0,
              },
              lineColor: "#8682cb",
              lineWidth: 2,
              states: {
                hover: {
                  lineWidth: 3,
                },
              },
              threshold: null,
            },
          },
          chart:{
            // width: '100%',
            height: 200
          },
          series: [
            {
              animation:false,
              type: "area",
              name: "Returns",
              data: returnsData,
            },
          ],
        }; 
        return(
            <div>
                <ReactHighcharts config={config} />
            </div>
        )
    }
}