import React from 'react'
import TableContent from './displayTypes/TableContent'
import TextContent from './displayTypes/TextContent'
import ObjectContent from './displayTypes/ObjectContent'
import { MemoizedDynamicChart } from './displayTypes/DynamicChart'
import { MemoizedLineChart } from './displayTypes/groupedData/LineChart'
import NewtIcon from '../../../../assets/newt/newt_black.png'
import FailedContent from './FailedContent'

export default function NewtResponse(props) {
  const renderMessageStatus = (message) => {
    switch (message.status) {
      case 'DONE':
        return message?.data?.map((m, i) => {
          return renderMessageContent(m, i)
        })
      case 'FAILED':
        return <FailedContent />
      default:
    }
  }

  const renderMessageContent = (m, i) => {
    switch (m.data_type) {
      case 'text':
        return <TextContent key={i} content_data={m} />
      case 'table':
        return (
          <TableContent
            key={i}
            content_data={m}
            sendPrompt={props.sendPrompt}
          />
        )
      case 'object':
        return <ObjectContent key={i} content_data={m} />
      case 'dynamic_chart':
        return <MemoizedDynamicChart key={i} content_data={m} />
      case 'chart':
        if (Array.isArray(m?.data)) {
          return m?.data?.map((item, index) => (
            <MemoizedLineChart key={index} data={item} />
          ))
        } else {
          return <MemoizedLineChart key={i} data={m?.data} />
        }
      default:
        return null
    }
  }

  return (
    <div className='newt-response-container'>
      <div className='newt-icon-content-container'>
        <div className='avatar-container'>
          <img src={NewtIcon} alt='' className='avatar-image' />
        </div>
        <div className='reponse-content'>
          <div className='response-header-container'>
            <p className='response-title'>
              {props.message.gpt_response?.[0]?.query}
            </p>
          </div>
          <div className='response-content-container'>
            {renderMessageStatus(props.message)}
          </div>
        </div>
      </div>
      <button
        className='newt-rewrite-btn'
        onClick={() => props.setPromptInput(props.message.prompt)}
      >
        Rewrite
      </button>
    </div>
  )
}
