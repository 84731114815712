import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import FooterPageTitle from '../layout/FooterPageTitle'
import Footer from '../Footer'
import Header from '../PublicHeader'

export default class PubLowPrice extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <Header
          isSmallScreen={this.props.isSmallScreen}
          user={this.props.user}
          signOut={this.props.signOut}
        />
        <div>
          <FooterPageTitle title='Low-Priced Securities Disclosure' />
          <Container style={{ width: '95%', margin: 'auto' }}>
            <Row />
            <Row className='terms-paragraph-row'>
              <h3>Low-Priced Securities (Penny and Sub Penny Stocks)</h3>
              <p className='terms-text'>
                The term “penny stock” generally refers to low-priced (below
                $5), speculative securities of very small companies. While penny
                stocks generally are quoted over the counter, such as on the OTC
                Bulletin Board or in the Pink Sheets, they may also trade on
                securities exchanges, including foreign securities exchanges. In
                addition, penny stocks include the securities of certain private
                companies with no active trading market.
              </p>
            </Row>
            <Row className='terms-paragraph-row'>
              <h3>Risks</h3>
              <p className='terms-text'>
                Investing in low-priced securities is speculative and involves
                considerable risk. Low-priced securities often exhibit high
                price volatility and erratic market movements. Often, when
                investors buy or sell these securities, they significantly
                affect the quoted price. In some cases, the liquidation of a
                position in a low priced security may not be possible within a
                reasonable period of time and is subject to additional fees. It
                may be difficult to properly value an investment in a low-priced
                security. Reliable information regarding issuers of low-priced
                securities, their prospects, or the risks associated with
                investing in such securities may not be available. Certain
                issuers of low-priced securities have no obligation to provide
                information to investors. Some issuers register securities with
                the Securities and Exchange Commission (SEC) and may provide
                regular reports to investors. Others however may not be required
                to maintain such registration or provide such reports.
                Securities may continue to be traded if issuers are delinquent
                in their reporting obligation to the SEC or other federal or
                state regulatory agencies. Penny stocks have not been approved
                or disapproved by the Securities and Exchange Commission (SEC).
                The SEC has not passed upon the fairness, the merits, the
                accuracy or adequacy of the information contained in any
                prospectus or any other information provided by an issuer or a
                broker or a dealer of penny stocks. Trading low-priced
                securities is subject to significant risks, increasing
                regulatory requirements and oversight, and additional fees.
              </p>
            </Row>
            <Row className='terms-paragraph-row'>
              <h3>Settlement Fees for Non-DTC Eligible Securities</h3>
              <p className='terms-text'>
                Investing in low-priced securities is speculative and involves
                considerable risk. Low-priced securities often exhibit high
                price volatility and erratic market movements. Often, when
                investors buy or sell these securities, they significantly
                affect the quoted price. In some cases, the liquidation of a
                position in a low priced security may not be possible within a
                reasonable period of time and is subject to additional fees. It
                may be difficult to properly value an investment in a low-priced
                security. Reliable information regarding issuers of low-priced
                securities, their prospects, or the risks associated with
                investing in such securities may not be available. Certain
                issuers of low-priced securities have no obligation to provide
                information to investors. Some issuers register securities with
                the Securities and Exchange Commission (SEC) and may provide
                regular reports to investors. Others however may not be required
                to maintain such registration or provide such reports.
                Securities may continue to be traded if issuers are delinquent
                in their reporting obligation to the SEC or other federal or
                state regulatory agencies. Penny stocks have not been approved
                or disapproved by the Securities and Exchange Commission (SEC).
                The SEC has not passed upon the fairness, the merits, the
                accuracy or adequacy of the information contained in any
                prospectus or any other information provided by an issuer or a
                broker or a dealer of penny stocks. Trading low-priced
                securities is subject to significant risks, increasing
                regulatory requirements and oversight, and additional fees.
              </p>
            </Row>
            <Row className='terms-paragraph-row'>
              <h3>Risks</h3>
              <p className='terms-text'>
                For various reasons, certain low-priced securities are not DTC
                eligible or have had their eligibility revoked. As a result, the
                settlement of these physical positions can carry significant
                pass-through charges for our clearing firm, ForeSCITE, Inc.
                (“ForeSCITE”), including execution fees, DTC fees, deposit fees,
                New York window fees, and transfer agent fees. These fees, which
                can vary and may be substantial, increase the cost that
                ForeSCITE passes through for clearing and execution. Customers
                who trade non-DTC eligible securities are responsible for these
                charges, which may exceed 10 times the value of the trade.
                Orders that require executions with multiple contra-parties will
                result in settlement fees for each separate transaction. Neither
                T3 Securities, Inc nor ForeSCITE mark up any of these fees
                before they are passed through to customers. These pass-through
                charges may not be immediately charged to a customer account
                following a trade in non-DTC eligible securities, as ForeSCITE
                may not receive notice of such fees until several weeks
                following the trade. T3 Securities, Inc. reserves the right to
                withhold funds in a customer account pending potential
                assessment of fees associated with trading in low priced
                securities. It is your responsibility to investigate the
                eligibility status of a low-priced security before trading it.
                It is strongly recommended that you contact the specific company
                whose equity you intend to trade to confirm eligibility.
              </p>
            </Row>

            <Row className='terms-paragraph-row'>
              <Col className='terms-bottom-row'>
                <p className='terms-bottom-date'>Last updated: May 5, 2021</p>
              </Col>
            </Row>
          </Container>
          <Footer t={this.props.t} isSmallScreen={this.props.isSmallScreen} />
        </div>
      </div>
    )
  }
}
