import React from 'react'
import '../../../scss/pages/disclosures/PrivacyPolicy.scss'

export default function PrivacyPolicy() {
  return (
    <div className='policy-container'>
      <p className='c23'>
        <span className='c31'>Investall Privacy Policy </span>
      </p>
      <p className='c3'>
        <span className='c1'>
          This privacy policy (&ldquo;Policy&rdquo;) discloses the privacy
          practices for Investall.app. This privacy policy applies solely to
          information collected by this web site. It will notify you of the
          following:
        </span>
      </p>
      <p className='c0'>
        <span className='c1'>
          What personally identifiable information is collected from you through
          the web site, how it is used and with whom it may be shared.
        </span>
      </p>
      <p className='c26'>
        <span className='c1'>
          What choices are available to you regarding the use of your data. The
          security procedures in place to protect the misuse of your
          information. How you can correct any inaccuracies in the information.
        </span>
      </p>
      <p className='c11'>
        <span className='c1'>
          Personal data received or transferred to our website is subject to the
          regulatory enforcement powers of the U.S. Federal Trade Commission. In
          certain situations, we may be required to disclose personal data in
          response to lawful requests by public authorities, including to meet
          national security or law enforcement requirements.
        </span>
      </p>
      <p className='c21'>
        <span className='c2'>INFORMATION COLLECTION, USE, AND SHARING </span>
      </p>
      <p className='c8'>
        <span className='c1'>
          The user recognizes that non-public information concerning the user,
          disclosed by the user to Investall (&ldquo;the Company&rdquo;), or
          other agents of either party (the &ldquo;information&rdquo;) (such as
          the user&rsquo;s name, company name, address, assets under management,
          type of business, etc.) (i) may be disclosed to the general partner,
          attorneys, accountants and auditors in furtherance of the
          Company&rsquo;s business and to other service providers such as
          brokers who may have a need for the information in connection with
          providing services to the company, (ii) to third party service
          providers or financial institutions who may be providing marketing
          services to the funds provided that such persons must agree to protect
          the confidentiality of the information and use the information only
          for the purposes of providing services to the Company and (iii) as
          otherwise required or permitted by law. The Company restricts access
          to the information to their employees who need to know the information
          to provide services to the Company, and maintain physical, electronic,
          and procedural safeguards that comply with U.S. federal standards to
          guard the information.
        </span>
      </p>
      <p className='c18'>
        <span className='c2'>YOUR ACCESS TO AND CONTROL OVER INFORMATION </span>
      </p>
      <p className='c16'>
        <span className='c1'>
          You may opt out of any future contacts from us at any time. You can do
          the following at any time by contacting us via the email address or
          phone number provided on our website:
        </span>
      </p>
      <p className='c4'>
        <span className='c1'>See what data we have about you, if any. </span>
      </p>
      <p className='c25'>
        <span className='c1'>Change/correct any data we have about you. </span>
      </p>
      <p className='c12'>
        <span className='c1'>Have us delete any data we have about you. </span>
      </p>
      <p className='c12'>
        <span className='c1'>
          Express any concern you have about our use of your data.
        </span>
      </p>
      <p className='c10'>
        <span className='c2'>REGISTRATION </span>
      </p>
      <p className='c17'>
        <span className='c1'>
          To use this website, a user must first complete the registration form.
          During registration a user is required to give certain information
          (such as name and email address). This information is used to contact
          you about the products/services on our site in which you have
          expressed interest.
        </span>
      </p>
      <p className='c10'>
        <span className='c2'>DURATION </span>
      </p>
      <p className='c14'>
        <span className='c1'>
          We will retain your personal information for as long as is necessary
          and as permitted by applicable laws.
        </span>
      </p>
      <p className='c18'>
        <span className='c2'>YOUR RIGHTS </span>
      </p>
      <p className='c9'>
        <span className='c1'>
          We always will enable you to exercise your rights as provided by data
          protection laws. Depending on the laws of the country where you live,
          you may have other rights in respect of your personal information. If
          you wish to exercise any legal right in respect of your personal
          information, please contact us.
        </span>
      </p>
      <p className='c24'>
        <span className='c1'>
          In some cases, we may be entitled to decline a request you have made
          regarding your personal information, in accordance with application
          laws. We will explain if that is the case. Otherwise, we will meet
          your request as promptly as we reasonably can. If you have requested
          that we stop sending you marketing messages, please note that you may
          still receive them for a short period while the request is processed.
        </span>
      </p>
      <p className='c7'>
        <span className='c2'>COOKIES </span>
      </p>
      <p className='c27'>
        <span className='c1'>
          This site uses cookies and other tracking technologies to assist with
          navigation, use, authority, accessing content, and website use
          practices.
        </span>
      </p>
      <p className='c6'>
        <span className='c2'>WHAT ARE COOKIES? </span>
      </p>
      <p className='c20'>
        <span className='c1'>
          Cookies are small tracking files that are stored by the on your
          computer or mobile phone. They allow websites to store user habits and
          preferences. Some cookies are required for users to be able to log in
          to our sites. We use &ldquo;cookies&rdquo; on this site. A cookie is a
          piece of data stored on a site visitor&rsquo;s hard drive to help us
          improve your access to our site and identify repeat visitors to our
          site. For instance, when we use a cookie to identify you, you would
          not have to log in a password more than once, thereby saving time
          while on our site. Cookies can also enable us to track and target the
          interests of our users to enhance the experience on our site. Usage of
          a cookie is in no way linked to any personally identifiable
          information on our site. Some of our business partners may use cookies
          on our site (for example, advertisers). However, we have no access to
          or control over these cookies.
        </span>
      </p>
      <p className='c19'>
        <span className='c2'>LINKS </span>
      </p>
      <p className='c15'>
        <span className='c1'>
          This web site may contain links to other sites. Please be aware that
          we are not responsible for the content or privacy practices of such
          other sites. We encourage our users to be aware when they leave our
          site and to read the privacy statements of any other site that
          collects personally identifiable information.
        </span>
      </p>
      <p className='c30'>
        <span className='c2'>SECURITY </span>
      </p>
      <p className='c5'>
        <span className='c1'>
          We take precautions to protect your information. When you submit
          sensitive information via the website, your information is protected
          both online and offline. Wherever we collect sensitive information,
          that information is encrypted and transmitted to us in a secure way.
          You can verify this by looking for a closed lock icon at the bottom of
          your web browser or looking for &ldquo;https&rdquo; at the beginning
          of the address of the web page.
        </span>
      </p>
      <p className='c29'>
        <span className='c1'>
          While we use encryption to protect sensitive information transmitted
          online, we also protect your information offline. Only employees who
          need the information to perform a specific job (for example, billing
          or customer service) are granted access to personally identifiable
          information. The computers/servers in which we store personally
          identifiable information is kept in a secure environment.
        </span>
      </p>
      <p className='c7'>
        <span className='c2'>UPDATES </span>
      </p>
      <p className='c13'>
        <span className='c1'>
          Our Privacy Policy may change from time to time and all updates will
          be posted on this page.
        </span>
      </p>
      <p className='c28'>
        <span className='c1'>
          If you feel that we are not abiding by this privacy policy, you should
          contact our customer support department immediately via telephone at
          (212) 995-9191 or via email at help@investall.ai.
        </span>
      </p>
    </div>
  )
}
