import React, { useEffect, useState } from 'react'

export default function IBDisclosures() {
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const disclosures = queryParameters.get('disclosures')
    console.log(disclosures.split(','))
    setDisclosures(disclosures.split(','))
  }, [])

  const [disclosures, setDisclosures] = useState([])

  return (
    <div className='disclosoue-main-container'>
      <div className='center-container'>
        <h1>Interactive Brokers</h1>
      </div>
      <ul>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3071.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Day Trading Risk Disclosure Statement
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3203.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Interactive Brokers LLC Client Agreement
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form6108.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Agreement Limiting Interactive Brokers' Liability for Decisions and
            Actions of your Independent Financial Advisor.
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form2109.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Form2109
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3074.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Interactive Brokers Order Routing and Payment for Order Flow
            Disclosure
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3230.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Disclosure Regarding Trading of Certificates of Deposit
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form4304.pdf'
            target='_blank'
            rel='noreferrer'
          >
            REGULATION BEST INTEREST DISCLOSURE
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form6112.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Agreements & Disclosures - Advisor Client Agreement
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form2192.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Interactive Brokers Group Privacy Policy
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3076.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Nasdaq ISE Disclosure for Option Orders Over 500 Contracts
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form4399.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Interactive Brokers Group Cookie Policy
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form9130.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Stock Stop Order Disclosure
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3007.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Interactive Brokers LLC Customer Agreement
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form4009.pdf'
            target='_blank'
            rel='noreferrer'
          >
            RISK DISCLOSURE STATEMENT
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form4404.pdf'
            target='_blank'
            rel='noreferrer'
          >
            FINRA Investor Protection Information Resources
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form9490.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Penny Stock Trading Risk Disclosure
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3081.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Notice Regarding USA Patriot Act Section 311
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form4016.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Risks of After-Hours Trading
          </a>
        </li>
        {/* <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form5002.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Request for Taxpayer Identification Number and Certification
          </a>
        </li> */}
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3089.pdf'
            target='_blank'
            rel='noreferrer'
          >
            GLOBAL FINANCIAL INFORMATION SERVICES SUBSCRIBER AGREEMENT
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form4024.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Customer Relationship Summary
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form5013.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Certification Regarding Trading Control and Ownership of the Account
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3070.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Interactive Brokers LLC Business Continuity Plan Disclosure
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3094.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Notice Regarding NFAs BASIC System
          </a>
        </li>
        <li>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form6105.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Discretionary Trading Authorization/Power Of Attorney For Financial
            Advisor Request To Send Trade Confirmations And Account Statements
            To Advisor
          </a>
        </li>
      </ul>
      {disclosures.includes('OPT') && (
        <>
          <h2>Options</h2>
          <ul>
            <li>
              <a
                href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form4003.pdf'
                target='_blank'
                rel='noreferrer'
              >
                OCC Risk Disclosure Statement and Acknowledgements
              </a>
            </li>
          </ul>
        </>
      )}
      {disclosures.includes('BOND') && (
        <>
          <h2>Bonds</h2>
          <ul>
            <li>
              <a
                href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3044.pdf'
                target='_blank'
                rel='noreferrer'
              >
                Risk Disclosure Statement for Trading Bonds
              </a>
            </li>
            <li>
              <a
                href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3077.pdf'
                target='_blank'
                rel='noreferrer'
              >
                Interactive Brokers Municipal Securities Disclosure
              </a>
            </li>
          </ul>
        </>
      )}
    </div>
  )
}
