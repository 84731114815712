import React, { useEffect, useRef, useState } from 'react'
import '../../../scss/pages/productsPageV2.scss'
import MainNavBar from '../../components/MainNavBar'
import { Carousel } from 'primereact/carousel'

import InvestallBlueSquareLogo from '../../../assets/landingPage/v3/investall-blue-square.svg'
import PFMain from '../../../assets/landingPage/v3/personal-finance-main.png'
import PFIcon from '../../../assets/landingPage/v3/pf-icon-dark.png'

import DynamicScale from '../../../assets/landingPage/v3/dynamic-scale.svg'
import DynamicSpending1 from '../../../assets/landingPage/v3/dynamic-spending-1.png'
import DynamicSpending2 from '../../../assets/landingPage/v3/dynamic-spending-2.png'

import InsigtIcon from '../../../assets/landingPage/v3/insight-bars.svg'
import Insight1 from '../../../assets/landingPage/v3/insight-1.png'
import Insight2 from '../../../assets/landingPage/v3/insight-2.png'

import UnwantedSub from '../../../assets/landingPage/v3/unwanted-screenshot.png'

import SelfTrading1 from '../../../assets/landingPage/v3/self-trading-1.png'
import SelfTrading2 from '../../../assets/landingPage/v3/self-trading-2.png'

import SDTIcon from '../../../assets/landingPage/v3/sdt-icon.svg'
import RoboIcon from '../../../assets/landingPage/v3/ra-icon.svg'
import AITIcon from '../../../assets/landingPage/v3/ait-icon.svg'
import GoalsIcon from '../../../assets/landingPage/v3/g-icon-light.svg'

import NewtIcon from '../../../assets/landingPage/v3/newt.svg'
import NewtScreenshot from '../../../assets/landingPage/v3/newt-screenshot.png'

import AITScreenshot from '../../../assets/landingPage/v3/ait-screenshot.png'

import RoboStrat from '../../../assets/landingPage/v3/robo-strat.png'

import Goals1 from '../../../assets/landingPage/v3/goals-1.png'
import Goals2 from '../../../assets/landingPage/v3/goals-2.png'
import PublicFooter from '../../PublicFooter'
import { NavLink } from 'react-router-dom'
import Axios from 'axios'
import formatCurrency from '../../../utility-functions/formatCurrency'
import { Line } from 'react-chartjs-2'
import Chart from 'chart.js/auto'

export default function ProductPage2(props) {
  const screenshotTemplate = (product) => {
    return (
      <div className='image-container'>
        <img src={product} alt='' />
      </div>
    )
  }

  const graphOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    tension: 0.4,
    tooltips: { enabled: false },
    hover: { mode: null },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        max: 4000,
        ticks: {
          fontColor: 'black', // this here
        },
        gridLines: {
          display: false,
        },
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  }

  const [initContribution, setInitContribution] = useState(500)
  const [displayInitContribution, setDisplayInitContribution] = useState(500)
  const [contributionAmount, setContributionAmount] = useState(0)
  const [displayContributionAmount, setDisplayContributionAmount] = useState(0)
  const [totalReturn, setTotalReturn] = useState((1361.6).toFixed(2))
  const [investallData, setInvestallData] = useState([
    500.0, 556.9819654883372, 619.0353259051365, 686.611435399031,
    760.2018186378821, 840.341745984991, 927.6141268659927, 1022.6537496454036,
    1126.1518988521823, 1238.861383338364, 1361.602011943816,
  ])
  const [indexData, setIndexData] = useState([
    500.0, 556.9819654883372, 619.0353259051365, 686.611435399031,
    760.2018186378821, 840.341745984991, 927.6141268659927, 1022.6537496454036,
    1126.1518988521823, 1238.861383338364, 1361.602011943816,
  ])

  const graphRef = useRef(null)

  const decrease = (data) => {
    const res = []
    if (data) {
      Object.values(data).forEach((item, index) => {
        res.push(Math.pow(2.25, index))
      })
    }
    return res
  }
  const increase = (data) => {
    const res = []
    if (data) {
      Object.values(data).forEach((item, index) => {
        res.push(Math.pow(2.4, index))
      })
    }
    return res
  }

  useEffect(() => {
    async function getGraphData() {
      let body = {
        goalData: {
          compoundInterest: 'Compound Monthly',
          contributionFrequency: 'Monthly',
          contributionAmount: contributionAmount,
          startingAmount: initContribution,
          targetedAmount: 10000,
          yearsToAccumulate: 5,
          roi: 0.089,
          totalTaxRate: 0.15,
          inflationRate: 0.1,
        },
        option: { action: 'calculate' },
      }
      let { data } = await Axios.post(
        'https://api.allocaterite.com/mobile/investment-forecasting',
        body
      )
      if (graphRef.current) {
        console.log(
          'increase(data.balanceData.balance)',
          increase(data.balanceData.balance)
        )
        setInvestallData(increase(data.balanceData.balance))
        setIndexData(decrease(data.balanceData.balance))
        setTotalReturn(data.balanceData.balance['10'].toFixed(2))
        graphRef.current.update()
      }
    }
    getGraphData()
  }, [initContribution, contributionAmount])

  return (
    <>
      <MainNavBar {...props} white />
      <div className='navbar-spacer' />
      <div className='full-screen-container'>
        <section className='background white'>
          <div className='center-container'>
            <div className='my-finances-section'>
              <img
                className='investall-logo'
                src={InvestallBlueSquareLogo}
                alt='investall-logo'
              />
              <p className='bold-title'>One app to handle all your finances.</p>
              <img
                className='banner-image'
                src={PFMain}
                alt='my-finance-main'
              />
              <div className='icon-title-container'>
                <img src={PFIcon} alt='my-finance-logo' />
                <p className='section-title'>My Finance</p>
              </div>
              <p className='content'>
                My Finances makes it easy to keep track of your income and
                expenses -- even if you have external accounts. You can also use
                Dynamic Spending to identify your average epxense by category
                and the opportunity cost each time you go over budget. Plus, you
                can start investing with completely automated Robo Advisor and
                AI Trader, or use forecasted prices on your favorite stocks to
                Self Trade. And Investall doesn’t take custody of your money
                giving you full control of your funds.
              </p>
            </div>
          </div>
        </section>
        <section className='background turquoise' id='financial-yoga'>
          <div className='center-container'>
            <div className='dynamic-spending-section'>
              <div className='desktop-row'>
                <div className='side text-content'>
                  <p className='section-title'>💸 Dynamic Spending</p>
                  <p className='content'>
                    With Dynamic Spending, you can quickly identify your average
                    spending by category to prioritize spending on the things
                    you need the most.
                  </p>
                  <img src={DynamicScale} alt='scale' className='scale-image' />
                  <p className='content'>
                    Framing spending decisions as trade-offs between two things
                    that you want, instead of focusing on “sticking to a
                    budget.” leads to positive emotions and may lead to better
                    decision making.
                  </p>
                </div>
                <div className='side center'>
                  <div className='carousel-container'>
                    <Carousel
                      value={[DynamicSpending1, DynamicSpending2]}
                      numVisible={1}
                      numScroll={1}
                      itemTemplate={screenshotTemplate}
                      circular
                      page={0}
                      autoplayInterval={4000}
                      autoPlay
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='background white' id='insights'>
          <div className='center-container'>
            <div className='insights-section'>
              <div className='desktop-row reverse'>
                <div className='side center'>
                  <div className='carousel-container'>
                    <Carousel
                      value={[Insight1, Insight2]}
                      numVisible={1}
                      numScroll={1}
                      itemTemplate={screenshotTemplate}
                      circular
                      page={0}
                      autoplayInterval={4000}
                      autoPlay
                    />
                  </div>
                </div>
                <div className='side text-content'>
                  <div className='icon-title-container'>
                    <img src={InsigtIcon} alt='insights' />
                    <p className='section-title'>Insights</p>
                  </div>
                  <p className='content'>
                    Checking your net balance has never been easier, get a full
                    picture of your assets and liabilities with an intuitive
                    dashboard. Connect existing banks and brokerages to get a
                    holistic view your your financial health.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='background gray' id='subscriptions'>
          <div className='center-container'>
            <div className='unwanted-subscriptions-section'>
              <div className='desktop-row'>
                <div className='side text-content'>
                  <p className='section-title'>
                    Manage unwanted subscriptions.
                  </p>
                  <p className='content'>
                    See how much you spend monthly on subscriptions, and easily
                    cancel unwanted expenses.
                  </p>
                </div>
                <div className='side single-screenshot'>
                  <img src={UnwantedSub} alt='subscription' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='background dark-blue'>
          <div className='center-container'>
            <div className='auto-trade-section'>
              <div className='desktop-row reverse'>
                <div className='side'>
                  <div className='icon-grid'>
                    <div className='grid-element'>
                      <img src={SDTIcon} alt='grid-1' />
                      <p>SELF DIRECTED TRADING</p>
                    </div>
                    <div className='grid-element'>
                      <img src={RoboIcon} alt='grid-2' />
                      <p>ROBO ADVISOR</p>
                    </div>
                    <div className='grid-element'>
                      <img src={AITIcon} alt='grid-3' />
                      <p>AI TRADER</p>
                    </div>
                    <div className='grid-element'>
                      <img src={GoalsIcon} alt='grid-4' />
                      <p>GOALS</p>
                    </div>
                  </div>
                </div>
                <div className='side text-content end'>
                  <p className='section-title'>
                    Automate trading and investing using AI.
                  </p>
                  <p className='content'>
                    With Investall AI driven products, you can start investing
                    into a custom tailored portfolio or personalize you
                    automated trader. Additionally, use price and financial
                    forecasts to make better decision with self trading.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='background white' id='self-directed-trading'>
          <div className='center-container'>
            <div className='self-trading-section'>
              <div className='desktop-row reverse'>
                <div className='side center'>
                  <div className='carousel-container'>
                    <Carousel
                      value={[SelfTrading1, SelfTrading2]}
                      numVisible={1}
                      numScroll={1}
                      itemTemplate={screenshotTemplate}
                      circular
                      page={0}
                      autoplayInterval={4000}
                      autoPlay
                    />
                  </div>
                </div>
                <div className='side text-content'>
                  <div className='icon-title-container'>
                    <img src={SDTIcon} alt='self-trading' />
                    <p className='section-title'>Self Trading</p>
                  </div>
                  <p className='content'>
                    Trade with confidence on thousands of stocks, digital assets
                    and bonds. Use price financial forecasts to help you make
                    informed decisions.
                  </p>
                  <div className='feature-lists'>
                    <ul className='feature-list'>
                      <li className='list-header'>Analysis</li>
                      <li>
                        <p className='content'>Technical Levels</p>
                      </li>
                      <li>
                        <p className='content'>Bullish and Bearish</p>
                      </li>
                      <li>
                        <p className='content'>Indicator</p>
                      </li>
                      <li>
                        <p className='content'>RSI Indicator</p>
                      </li>
                      <li>
                        <p className='content'>Standard Levels</p>
                      </li>
                      <li>
                        <p className='content'>Fibonacci Levels</p>
                      </li>
                      <li>
                        <p className='content'>Max drawdown</p>
                      </li>
                      <li>
                        <p className='content'>Chaikin Money Flow</p>
                      </li>
                    </ul>
                    <ul className='feature-list'>
                      <li className='list-header'>Forecast</li>
                      <li>
                        <p className='content'>1mo Price Forecast</p>
                      </li>
                      <li>
                        <p className='content'>3mo Price Forecast</p>
                      </li>
                      <li>
                        <p className='content'>6mo Price Forecast</p>
                      </li>
                      <li>
                        <p className='content'>1Y Price Forecast</p>
                      </li>
                      <li>
                        <p className='content'>2Y Price Forecast</p>
                      </li>
                      <li>
                        <p className='content'>Revenue Forecasts</p>
                      </li>
                      <li>
                        <p className='content'>EPS Forecasts</p>
                      </li>
                      <li>
                        <p className='content'>Chaikin Money Flow</p>
                      </li>
                    </ul>
                    <ul className='feature-list'>
                      <li className='list-header'>Stats</li>
                      <li>
                        <p className='content'>Corporate Actions</p>
                      </li>
                      <li>
                        <p className='content'>Key Stats</p>
                      </li>
                      <li>
                        <p className='content'>Ratio / Profitability</p>
                      </li>
                      <li>
                        <p className='content'>Events</p>
                      </li>
                      <li>
                        <p className='content'>News</p>
                      </li>
                      <li>
                        <p className='content'>Best Hedge</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='background white'>
          <div className='center-container'>
            <div className='personal-ai-section'>
              <div className='desktop-row'>
                <div className='side text-content'>
                  <div className='icon-title-container'>
                    <img src={NewtIcon} alt='personal-ai' />
                    <p className='section-title'>Personal AI Assistant</p>
                  </div>
                  <p className='content'>
                    With Newt, your integrated AI assistant, you can help you
                    find any information across all accounts, automate several
                    tasks such as reporting, creating proposals, scheduling,
                    trading and more. Increase productivity, streamline
                    workflows, filter stocks, forecast prices, prepare reports
                    and more.
                  </p>
                </div>
                <div className='side single-screenshot'>
                  <div className='screenshot-w-caption'>
                    <p>Screen Stocks & Trade</p>
                    <img src={NewtScreenshot} alt='personal-ai' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='background dark-blue' id='ai-trader'>
          <div className='center-container'>
            <div className='ai-trader-section'>
              <div className='desktop-row'>
                <div className='side text-content'>
                  <div className='icon-title-container'>
                    <img src={AITIcon} alt='ai-trader' />
                    <p className='section-title'>AI Trader</p>
                  </div>
                  <p className='content'>
                    Personalize your AI Trader with four stocks of your choice,
                    and leave it to Investall's AI to make automated buys and
                    sells for you.
                  </p>
                </div>
                <div className='side single-screenshot'>
                  <img src={AITScreenshot} alt='ai-trader' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='background white' id='robo'>
          <div className='center-container'>
            <div className='robo-section'>
              <div className='desktop-row short' style={{ gap: 40 }}>
                <div className='side text-content'>
                  <div className='icon-title-container'>
                    <img src={RoboIcon} alt='robo' />
                    <p className='section-title'>Robo Advisor</p>
                  </div>
                  <p className='content'>
                    Get started with an custom tailored portfolio based on your
                    risk tolerance, with diversified exposure across a wide
                    range of sectors.
                  </p>
                </div>
                <div className='side single-screenshot'>
                  <img src={RoboStrat} alt='robo-strat' />
                </div>
              </div>
              <div className='desktop-row reverse' style={{ gap: 80 }}>
                <div className='slider-side'>
                  <p className='label-text'>Start with an initial investment</p>
                  <div className='slider-container'>
                    <p className='content amount'>
                      {formatCurrency(displayInitContribution)}
                    </p>
                    <input
                      type='range'
                      min='500'
                      max='50000'
                      step={100}
                      value={displayInitContribution}
                      onChange={(e) =>
                        setDisplayInitContribution(e.target.value)
                      }
                      onMouseUp={(e) => setInitContribution(e.target.value)}
                      onTouchEnd={(e) => setInitContribution(e.target.value)}
                      className='space-bottom'
                    />
                  </div>
                  <p className='label-text'>Plan for monthly contributions</p>
                  <p className='content amount'>
                    {formatCurrency(displayContributionAmount)}
                  </p>
                  <div className='slider-container'>
                    <input
                      type='range'
                      min='0'
                      max='500'
                      step={20}
                      value={displayContributionAmount}
                      onChange={(e) =>
                        setDisplayContributionAmount(e.target.value)
                      }
                      onMouseUp={(e) => setContributionAmount(e.target.value)}
                      onTouchEnd={(e) => setContributionAmount(e.target.value)}
                    />
                  </div>
                  <p className='label-text'>Projected Return</p>
                  <p className='content amount'>
                    {formatCurrency(totalReturn)} (
                    {parseInt(
                      parseFloat(totalReturn) /
                        (parseInt(contributionAmount) * 60 +
                          parseInt(initContribution))
                    )}
                    X)
                  </p>
                </div>
                <div className='graph-side'>
                  <div className='custom-legend'>
                    <div className='legend-row'>
                      <div className='color purple' />
                      <p>Investall Robo</p>
                    </div>
                    <div className='legend-row'>
                      <div className='color gray' />
                      <p>S&P 500</p>
                    </div>
                  </div>
                  <Line
                    ref={graphRef}
                    data={{
                      labels: ['', '', 3, '', 5, '', '', '', '', 10],
                      datasets: [
                        {
                          data: investallData,
                          // borderColor: '#3627e4',
                          borderColor: '#716FF6',
                          label: 'Investall Robo',
                          backgroundColor: 'transparent',
                        },
                        {
                          data: indexData,
                          borderColor: '#888888',
                          label: 'S&P 500',
                          backgroundColor: 'transparent',
                        },
                      ],
                    }}
                    options={graphOptions}
                  />
                </div>
              </div>
              <p className='content chart-disclaimer'>
                Chart is not indicative of future performance, it is for
                simulation purposes only. Past performance is not indicative of
                future performance. See full disclosure below*
              </p>
              <div className='robo-cards'>
                <div className='card'>
                  <p className='card-title'>Institutional Level</p>
                  <p>
                    Investall strategies have an audited track record and are
                    used by institutional traders based on their performance.
                    Strategies rebalance periodically to meet requirements.
                  </p>
                </div>
                <div className='card'>
                  <p className='card-title'>Custom Tailored</p>
                  <p>
                    Robo advisor will automatically apply a strategy that best
                    fits your risk profile that you enter when opening an
                    account.
                  </p>
                </div>
                <div className='card'>
                  <p className='card-title'>Highly Liquid</p>
                  <p>
                    You keep full custody of your funds in your brokerage
                    account, and can start and stop the robo at will.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='background white' id='goals'>
          <div className='center-container'>
            <div className='goals-section'>
              <div className='desktop-row'>
                <div className='side text-content'>
                  <div className='icon-title-container'>
                    <img src={GoalsIcon} alt='goals' />
                    <p className='section-title'>GOALS</p>
                  </div>
                  <p className='content'>
                    Set goals for your financial future. Wether it's for a down
                    payment for a house or your next vacation, Goals helps you
                    get there.
                  </p>
                  <p className='content'>
                    Fund your goals automatically by rounding up your spare
                    change using Round Up and setting periodic deposits with
                    Recurring Deposits.
                  </p>
                  <p className='content'>
                    Grow your funds with an account that pays you interest on
                    cash. Investall allows you to set up recurring buys in
                    stocks, and build a portfolio with dollar cost averaging.
                  </p>
                </div>
                <div className='side center'>
                  <div className='carousel-container'>
                    <Carousel
                      value={[Goals1, Goals2]}
                      numVisible={1}
                      numScroll={1}
                      itemTemplate={screenshotTemplate}
                      circular
                      page={0}
                      autoplayInterval={4000}
                      autoPlay
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='background gray'>
          <div className='center-container'>
            <div className='disclaimer-section'>
              <p className='content'>
                Chart is not indicative of future performance, it is for
                simulation purposes only. Past performance is not indicative of
                future performance. See full disclosure below* AllocateRite is
                registered with the Securities and Exchange Commission
                (&ldquo;SEC&rdquo;). AllocateRite claims compliance with the
                Global Investment Performance Standards (GIPS&reg;).
                AllocateRite first claimed compliance with the Global Investment
                Performance Standards (GIPS&reg;) standards on 3/24/2017, for
                the periods 01/06/2016 through current.
              </p>
              <p className='content'>
                AllocateRite reviews a total firm AUM report broken out by
                account on a monthly basis to ensure that only actual assets
                managed by the firm are included. All accounts deemed to be
                advisory only, hypothetical, or model in nature are excluded
                from total firm AUM. AllocateRite currently reviews only firm
                AUM on a monthly basis. Composite AUM, is checked to eliminate
                any double counting of assets.
              </p>
              <p className='content'>
                A GIPS report and/or the firm&rsquo;s list of composite
                descriptions can be obtained upon request at
                info@allocaterite.com. GIPS&reg; is a registered trademark of
                the CFA institute. The CFA institute does not endorse&#160;or
                promote this organization nor does it warrant the accuracy or
                quality of the content contained herein.
              </p>
              <p className='content'>
                Actual composite performance includes dividends and dividends
                reinvested.
              </p>
              <p className='content'>
                Actual Performance figures are reported in US $.
              </p>
              <p className='content'>
                The indices/funds used by AllocateRite LLC have not been
                selected to represent an appropriate benchmark to compare an
                investor&rsquo;s performance, but rather are disclosed to allow
                for comparison of the investor&rsquo;s performance to that of
                certain well known and widely recognized indices/funds. Indices
                are typically not available for direct investment, are unmanaged
                and do not incur fees or expenses.&#160;
              </p>
              <p className='content'>
                Past performance is not indicative of future performance.
              </p>
              <p className='content'>
                Any information you receive from AllocateRite is believed to be
                accurate. Nevertheless, neither AllocateRite nor its agents are
                liable for any deficiencies in the accuracy, completeness,
                availability or timeliness of such information. The information
                provided on this document does not necessarily reflect the most
                up to date or current information available on the product or
                service. The information contained herein is provided without
                any warranty of any kind. All the information above cannot be
                transmitted without AllocateRite&rsquo;s consent. All the above
                materials are for information purposes only.
              </p>
              <p className='content'>
                Any performance shown in USD for the relevant time periods is
                based upon composite results of the AllocateRite U S Domestic
                portfolios. Portfolio performance is the result of the
                application of the AllocateRite LLC&rsquo;s investment process.
                The composite incorporates the relevant accounts managed by
                AllocateRite LLC.
              </p>
              <p className='content'>
                Portfolio performance is shown net of the advisory fees of
                0.50%, the highest fee charged by AllocateRite LLC and trading
                costs charged by their Custodians. Performance does not reflect
                the deduction of other fees or expenses, including but not
                limited to brokerage fees, custodial fees and fees and expenses
                charged by other investment companies. Performance results shown
                include the reinvestment of dividends and interest on cash
                balances where applicable. The data used to calculate the
                portfolio performance was obtained from sources deemed reliable
                and then organized and presented by AllocateRite, LLC.
              </p>
              <p className='content'>
                The S&amp;P 500 Index measures the performance of the 500
                leading economies in leading industries of the U.S. economy,
                capturing 75% of all U.S. equities. The SPX/Barclays Index is a
                blend composed of 60% Standard &amp; Poor&rsquo;s 500 Index
                (S&amp;P) and 40% Bloomberg Barclays U.S. Aggregate Bond Index
                (BC Agg) and assumes monthly rebalancing. The S&amp;P is
                regarded as the standard for measuring large-cap U.S. stock
                market performance. The BC Agg is an unmanaged index that is
                widely regarded as a standard for measuring U.S. investment
                grade bond market performance.&#160;
              </p>
              <p className='content'>
                Actual performance of client portfolios may differ materially
                due to the timing related to additional client deposits or
                withdrawals and the actual deployment and investment of a client
                portfolio, the reinvestment of dividends, the length of time
                various positions are held, the client&rsquo;s objectives and
                restrictions, and fees and expenses incurred by any specific
                individual portfolio.
              </p>
              <p className='content'>-</p>
              <p className='content'>
                Investall is a SEC-Registered Investment Advisor and facilitates
                account opening with Interactive Brokers and Apex Clearing Corp.
                Interactive Brokers and APEX Clearing Corp are a member of SIPC,
                which protects securities for customers of its members up to
                $500,000 (including $250,000 for claims for cash). Explanatory
                brochure available upon request or at www.sipc.org.
              </p>
            </div>
          </div>
        </section>
      </div>
      <PublicFooter isSmallScreen={false} t={props.t} />
    </>
  )
}
