import React, { Component } from "react";
import { Container, Carousel, Row } from "react-bootstrap";
import StrategiesChart from "../StrategiesChart";

class StrategiesCharts extends Component {
  state = {
    isChinese: this.props.isChinese,
    Components: [
      {
        id: 1,
        title: `Actuals`,
        cnTitle: `真实数据`,
        charts: [
          {
            id: `A1`,
            frameSrc: `https://app.allocaterite.com/performanceGraphPub?startDate=2016-01-01`,
            title: `PERFORMANCE - US DOMESTIC COMPOSITE`,
            cnTitle: ``,
            subtitle: `* Please see <a href="http://www.allocaterite.com/USDomestic">Disclaimers and Disclosures</a> for additional information regarding back-tested data and historical performance.`,
            cnSubtitle: ``
          },
          {
            id: `A2`,
            frameSrc: `https://app.allocaterite.com/performanceGraphIntPub?startDate=2017-05-01`,
            title: `PERFORMANCE — DIVERSIFIED INTERNATIONAL COMPOSITE`,
            cnTitle: ``,
            subtitle: `* Please see <a href="http://www.allocaterite.com/DiversifiedInternational">Disclaimers and Disclosures</a> for additional information regarding back-tested data and historical performance.`,
            cnSubtitle: ``
          },
          {
            id: `A3`,
            frameSrc: `https://app.allocaterite.com/performanceGraphBlendPub?startDate=2017-05-01`,
            title: `PERFORMANCE — DYNAMIC BLEND COMPOSITE`,
            cnTitle: ``,
            subtitle: `* Please see <a href="http://www.allocaterite.com/DynamicGlobalBlend">Disclaimers and Disclosures</a> for additional information regarding back-tested data and historical performance.`,
            cnSubtitle: ``
          }
        ]
      },
      {
        id: 2,
        title: `Hypothetical Back-tested`,
        cnTitle: `历史回溯结果`,
        charts: [
          {
            id: `H1`,
            frameSrc: `https://app.allocaterite.com/graphs/pastPerformanceStrategyGraphUSWB`,
            title: `Back-tested results for a long period of time included a significant down period, AllocateRite significantly outperformed the S&P 500 and a traditional 60/40 portfolio.`,
            subtitle: `* Please see <a href="http://www.allocaterite.com/USDomestic">Disclaimers and Disclosures</a> for additional information regarding back-tested data and historical performance.`
          },
          {
            id: `H2`,
            frameSrc: `https://app.allocaterite.com/graphs/pastPerformanceStrategyGraphINTWB`,
            title: `Back-tested results for a long period of time included a significant down period, AllocateRite significantly outperformed the S&P 500 and a traditional 60/40 portfolio.`,
            subtitle: `* Please see <a href="http://www.allocaterite.com/DiversifiedInternational">Disclaimers and Disclosures</a> for additional information regarding back-tested data and historical performance.`
          },
          {
            id: `H3`,
            frameSrc: `https://app.allocaterite.com/graphs/pastPerformanceStrategyGraphBlendWB`,
            title: `Back-tested results for a long period of time included a significant down period, AllocateRite significantly outperformed the S&P 500 and a traditional 60/40 portfolio.`,
            subtitle: `* Please see <a href="http://www.allocaterite.com/DynamicGlobalBlend">Disclaimers and Disclosures</a> for additional information regarding back-tested data and historical performance.`
          }
        ]
      }
    ]
  };

  render() {
    const nextIcon = (
      <span>
        <img src="../images/next.png" width="15px" alt="next" />
      </span>
    );
    const prevIcon = (
      <span>
        <img src="../images/prev.png" width="15px" alt="prev" />
      </span>
    );

    return (
      <Container fluid className="charts-fluid-container">
        {this.state.Components.map(component => (
          <Container className="charts-container" key={component.id}>
            <Row>
              {this.state.isChinese ? (
                <p className="charts-title">{component.cnTitle}</p>
              ) : (
                <p className="charts-title">{component.title}</p>
              )}
            </Row>
            <Row className="charts-chart-row">
              <Carousel
                className="charts-charts-carousel"
                // nextIcon={nextIcon}
                // prevIcon={prevIcon}
                controls={false}
              >
                {component.charts.map(chart => (
                  <Carousel.Item key={chart.id}>
                    <StrategiesChart key={chart.id} value={chart} />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Row>
          </Container>
        ))}
      </Container>
    );
  }
}

export default StrategiesCharts;
