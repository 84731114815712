import React, { Component } from 'react'
import Footer from '../PublicFooter'
import Header from '../PublicHeader3'
import emailjs from 'emailjs-com'
import TextBubble from '../../assets/Contact/text-balloon@2x.png'
import MainNavbar from '../components/MainNavBar'
import { sendEmail } from '../../services/sendEmail'

const backgroundImage = require('../../assets/background.jpeg')

export class Contact extends Component {
  state = {
    email: '',
    name: '',
    text: '',
    subject: '',
    placeholder: 'We appreciate any and all feedback!',
    isSmallScreen: false,
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener('resize', this.updateWindowDimensions)
    this.updateWindowDimensions()
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  createEmail = () => {
    return `${this.state.name} who can be reached at ${this.state.email} wants to talk about ${this.state.subject}:  ${this.state.text}`
  }

  checkName = () => {
    if (this.state.name.length > 0) {
      return true
    } else {
      return false
    }
  }
  checkEmail = () => {
    if (this.state.email.length > 0) {
      return true
    } else {
      return false
    }
  }
  checkBody = () => {
    if (this.state.text.length > 0) {
      return true
    } else {
      return false
    }
  }

  reachOut = () => {
    return `${this.state.name} who can be reached at ${this.state.email} says:`
  }

  // sendEmail = () => {
  //   let templateParams = {
  //     from_name: this.state.name,
  //     to_name: '<YOUR_EMAIL_ID>',
  //     subject: 'subject',
  //     contact: this.reachOut(),
  //     message: this.createEmail(),
  //   }
  //   console.log('email should say: ', this.createEmail())
  //   // emailjs.sendForm('service_m8qm1fe', 'template_jw39141', this.createEmail(), 'user_fsr7vl0Mp1zwzsbgJAwzD')
  //   emailjs
  //     .send(
  //       'service_m8qm1fe',
  //       'template_jw39141',
  //       templateParams,
  //       'user_fsr7vl0Mp1zwzsbgJAwzD'
  //     )
  //     .then(
  //       (result) => {
  //         console.log('success result', result.text)
  // this.setState({
  //   email: '',
  //   name: '',
  //   text: '',
  //   placeholder: 'Your message has been sent!',
  // })
  //       },
  //       (error) => {
  //         console.log('error result', error.text)
  // this.setState({
  //   email: '',
  //   name: '',
  //   text: '',
  //   placeholder: 'Something went wrong',
  // })
  //       }
  //     )
  // }
  sendEmail = async () => {
    const subject = `Contact Email: ${this.state.subject}`
    const content = `Name: ${this.state.name}, Email: ${this.state.email}, Message: ${this.state.text}`
    const recipients = ['tigran.tovmasyan@investall.ai', 'help@investall.ai']

    const res = await sendEmail(subject, content, recipients)
    if (res) {
      this.setState({
        email: '',
        name: '',
        subject: '',
        text: '',
        placeholder: 'Your message has been sent!',
      })
    } else {
      this.setState({
        email: '',
        name: '',
        subject: '',
        text: '',
        placeholder: 'Something went wrong',
      })
    }
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        {/* <Header
          user={this.props.user}
          signOut={this.props.signOut}
          isSmallScreen={this.state.isSmallScreen}
        /> */}
        <MainNavbar {...this.props} colorChange={true} />
        <div style={{ marginTop: 75 }}>
          <div
            style={{
              backgroundColor: '#3627e4',
              paddingTop: 50,
              paddingLeft: '7%',
            }}
          >
            <div style={{ width: '100%', display: 'flex' }}>
              <div
                style={{
                  width: this.state.isSmallScreen ? '90' : '50%',
                  paddingBottom: '4%',
                  margin: 'auto',
                }}
              >
                {/* <p
                  style={{ fontSize: 30, fontWeight: 'bold', color: '#3f4174' }}
                >
                  We'd love to hear from you
                </p> */}
                <p style={{ fontSize: 26, color: 'white' }}>
                  Please complete the form or reach out to us using the contact
                  information below and one of our team members will be in
                  touch.
                </p>
              </div>
              {this.state.isSmallScreen ? null : (
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    marginTop: 50,
                  }}
                >
                  {/* <img src={TextBubble} style={{ width: '50%' }} /> */}
                </div>
              )}
            </div>
          </div>
          <div style={{ backgroundColor: 'rgb(255,255,255)' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: this.state.isSmallScreen ? 'column' : 'row',
              }}
            >
              {this.state.isSmallScreen ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    marginTop: -30,
                  }}
                >
                  {/* <img src={TextBubble} style={{ width: '50%' }} /> */}
                </div>
              ) : null}
              <div
                style={{
                  width: this.state.isSmallScreen ? '90%' : '70%',
                  paddingBottom: '10%',
                  paddingLeft: 50,
                  paddingRight: 50,
                  paddingTop: 50,
                  margin: 'auto',
                }}
              >
                <input
                  type='text'
                  onChange={(e) => this.setState({ name: e.target.value })}
                  value={this.state.name}
                  style={{
                    width: '100%',
                    marginBottom: 10,
                    padding: '1%',
                    borderRadius: 6,
                  }}
                  placeholder='Name'
                />
                <input
                  type='text'
                  onChange={(e) => this.setState({ email: e.target.value })}
                  value={this.state.email}
                  style={{
                    width: '100%',
                    marginBottom: 10,
                    padding: '1%',
                    borderRadius: 6,
                  }}
                  placeholder='Email'
                />
                <input
                  type='text'
                  onChange={(e) => this.setState({ subject: e.target.value })}
                  value={this.state.subject}
                  style={{
                    width: '100%',
                    marginBottom: 10,
                    padding: '1%',
                    borderRadius: 6,
                  }}
                  placeholder='Subject'
                />
                <textarea
                  value={this.state.text}
                  onChange={(e) => this.setState({ text: e.target.value })}
                  placeholder={this.state.placeholder}
                  style={{
                    height: 200,
                    width: '100%',
                    padding: '1%',
                    borderRadius: 6,
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 20,
                  }}
                >
                  <button
                    onClick={
                      this.checkBody && this.checkEmail() && this.checkName()
                        ? () => this.sendEmail()
                        : null
                    }
                    style={{
                      outline: 'none',
                      boxShadow: 'none',
                      fontWeight: 'bold',
                      backgroundColor: '#3F46F6',
                      borderRadius: 20,
                      padding: '5px 50px',
                      border: 'none',
                      color: 'white',
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>

              <div
                style={{
                  width: this.state.isSmallScreen ? '80%' : '30%',
                  margin: 'auto',
                  marginTop: 50,
                }}
              >
                <p style={{ fontSize: 28, color: '#3f4174' }}>
                  Investall, Inc.
                </p>
                <p style={{ fontSize: 19, margin: 0 }}>100 Wall Street</p>
                <p style={{ fontSize: 19, margin: 0 }}>Suite 901</p>
                <p style={{ fontSize: 19 }}>New York, NY 10005</p>
                {/* <p style={{ fontSize: 19, margin: 0 }}>
                  2500 North Military Trail
                </p>
                <p style={{ fontSize: 19, margin: 0 }}>Suite 455</p>
                <p style={{ fontSize: 19, margin: 0 }}>Boca Raton, Florida</p>
                <p style={{ fontSize: 19 }}>33431</p> */}
                <p style={{ fontSize: 19 }}>(212) 995-9191</p>
                <p style={{ fontSize: 19 }}>help@investall.ai</p>
              </div>
            </div>
          </div>
        </div>
        <Footer t={this.props.t} isSmallScreen={this.state.isSmallScreen} />
      </div>
    )
  }
}

export default Contact
