import React, { Component } from "react";
import StockPriceGraph from "../components/StockPriceGraph";
import BuyOrSellForm from "../components/BuyOrSellForm";

export default class Chart extends Component {
  render() {
    return (
      <div style={{height: '100%'}}>
        <StockPriceGraph
          user={this.props.user}
          isSmallScreen={this.props.isSmallScreen}
          symbol={this.props.symbol}
          isDwSupported={this.props.isDwSupported}
          formatCurrency={this.props.formatCurrency}
          t={this.props.t}
        />
        {/* <div style={{width: '60%'}}>
          <StockPriceGraph user = {this.props.user} isSmallScreen = {this.props.isSmallScreen} symbol = {this.props.symbol} isDwSupported = {this.props.isDwSupported} />
        </div>
        <div style={{width: '35%'}}>
          <BuyOrSellForm {...this.props}/>
        </div> */}
      </div>
    );
  }
}
