import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

export class ChineseFooter extends Component {
  render() {
    return (
      <div id="footer">
        <Container className="footerContainer" bg="light">
          <Row className="foot-blank-row" />
          <Row className="terms">
            <Col sm={2} />
            <Col sm={8}>
              <NavLink to="/cn/PrivacyPolicy" className="footer-navlink">
                隐私政策
              </NavLink>
              <NavLink to="/cn/TermsOfUse" className="footer-navlink">
                使用条款
              </NavLink>
              <NavLink to="/cn/GeneralDisclaimer" className="footer-navlink">
                免责声明
              </NavLink>
            </Col>
            <Col sm={2} />
          </Row>

          <Row className="footer-policy-text">
            <p className="p1">
              AllocateRite LLC
              是SEC注册的投资顾问。过去的表现不能保证未来的表现或者结果。投资不由美国联邦存款保险公司（FDIC）担保，并有可能会有损失。所有历史数据，期望收益或者概率上的预测都无法反映未来的真实表现。所有的投资都涉及风险并且有可能会遭受损失。本网页所有的信息，以及在社交媒体上的所有信息都不构成买卖本公司任何产品或者服务的一项要约或要约招揽行为。
            </p>
          </Row>

          <Row className="footer-policy-text">
            <p className="p1">
              过去的表现不能保证未来的表现或者结果。投资不由美国联邦存款保险公司（FDIC）担保，并有可能会有损失。所有历史数据，期望收益或者概率上的预测都无法反映未来的真实表现。所有的投资都涉及风险并且有可能会遭受损失。本网页所有的信息，以及在社交媒体上的所有信息都不构成买卖本公司任何产品或者服务的一项要约或要约招揽行为。
            </p>
          </Row>

          <Row className="copy">
            <Col sm={6} id="copyleft">
              <span id="copyLeftText">
                版权所有 © 2019 — AllocateRite LLC, NYC
              </span>
            </Col>
            <Col sm={6} id="copyright">
              <span id="copyRightText">保留所有权利 </span>
            </Col>
          </Row>
          <Row className="foot-blank-row" />
        </Container>
      </div>
    );
  }
}

export default ChineseFooter;
