import React, { Component } from 'react'
import Axios from 'axios'
import ENDPOINT from '../../../.././Endpoint'
import { Button } from 'primereact/button'
import '../../../../../scss/pages/account-activation/robo/InvestmentForm.scss'
import { InputNumber } from 'primereact/inputnumber'
import { Checkbox } from 'primereact/checkbox'
import { Dropdown } from 'primereact/dropdown'
import ReactHighcharts from 'react-highcharts'
import { Dialog } from 'primereact/dialog'
import DepositDialog from '../../../DepositDialog'

export default class InvestmentForm extends Component {
  state = {
    contributionAmount: 0,
    startingAmount: Math.max(
      500,
      this.props.selectedAccount?.balances[0]?.virtualAccountValue
    ),
    roundUp: false,
    bankAccounts: null,
    selectedBankAccount: null,
    graphData: null,
    errorMessage: '',
    successMessage: '',
    isCustom:
      this.props.productInstanceData?.strategyInfo?.name ===
      'AR Customized Strategy'
        ? true
        : false,
  }

  componentDidMount() {
    this.getInvestmentForecasting()
    this.getBankAccounts()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedAccount.balance !== this.props.selectedAccount.balance
    ) {
      this.setState({
        startingAmount: Math.max(
          500,
          this.props.selectedAccount?.balances[0]?.virtualAccountValue
        ),
      })
    }
  }

  //APIS
  getInvestmentForecasting = () => {
    let body = {
      goalData: {
        compoundInterest: 'Compound Monthly',
        contributionAmount: this.state.contributionAmount,
        contributionFrequency: 'Monthly',
        inflationRate: 0.03,
        roi: 0.074108,
        startingAmount: this.state.startingAmount,
        totalTaxRate: 0.24,
        yearsToAccumulate: 10,
      },
      option: {
        action: 'calculate',
      },
    }
    Axios.post(`${ENDPOINT}/mobile/investment-forecasting`, body, {
      headers: {
        Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response?.data?.balanceData?.balance) {
          let data = response.data.balanceData.balance
          let graphData = Object.keys(data).map((key) => {
            return data[key]
          })
          this.setState({ graphData: graphData })
        }
      })
      .catch((err) => {
        console.log('investment forecasting error: ', err)
      })
  }

  //FILTER ACCOUNTS TO GET LIST OF BANK ACCOUNTS
  getBankAccounts = () => {
    let bankAccounts = []
    if (this.props.accounts) {
      this.props.accounts.forEach((account) => {
        if (account.type === 'depository')
          bankAccounts.push({ label: account.name, value: account })
      })
    }
    bankAccounts.push({ label: 'None', value: null })
    this.setState({ bankAccounts: bankAccounts })
  }

  handleStartingAmountChange = (value) => {
    this.setState({ startingAmount: value }, () => {
      this.getInvestmentForecasting()
    })
  }

  handlePeriodicAmountChange = (value) => {
    let state = { contributionAmount: value }
    if (value === 0 && !this.state.roundUp) state.selectedBankAccount = null
    this.setState(state, () => {
      this.getInvestmentForecasting()
    })
  }

  handleRoundUpChange = (value) => {
    let state = { roundUp: value }
    if (!value && this.state.contributionAmount === 0)
      state.selectedBankAccount = null
    this.setState(state)
  }

  handleBankAccountChange = (value) => {
    if (this.state.contributionAmount === 0 && !this.state.roundUp) {
      this.toggleErrorMessage(this.props.t('pleaseselectoneofthevaluesabove'))
    } else {
      this.setState({ selectedBankAccount: value })
    }
  }

  handleDepositAmountChange = (value) => {
    this.setState({ depositAmount: value })
  }

  handleDepositBankAccountChange = (value) => {
    this.setState({ depositBankAccount: value })
  }

  toggleShowDepositForm = () => {
    this.setState({ showDepositForm: !this.state.showDepositForm })
  }

  toggleErrorMessage = (msg) => {
    this.setState({ errorMessage: msg })
    setTimeout(() => {
      this.setState({ errorMessage: '' })
    }, 2500)
  }

  showGraph() {
    if (this.state.graphData && this.state.graphData.length > 0) {
      let firstValue = this.state.graphData[0]
      let lastValue = this.state.graphData[this.state.graphData.length - 1]
      return (
        <div className='graph-container'>
          <ReactHighcharts config={this.getConfig()} isPureConfig={true} />
          <div className='graph-data'>
            <p className='value'>
              {this.props.formatCurrency(
                this.state.graphData[this.state.graphData.length - 1]
              )}
              <span className='static-text'>{this.props.t('in10years')}</span>
            </p>
            <p className='percentage'>
              {(((lastValue - firstValue) / firstValue) * 100).toFixed(2)}%{' '}
              <span className='growth'>{this.props.t('growth')}</span>
            </p>
            <p className='gips'>{this.props.t('gipscompliant')}</p>
          </div>
        </div>
      )
    }
  }

  initialInvestmentForm() {
    return (
      <div className='initial-investment-form-container'>
        <p className='info'>{this.props.t('aminimumof500isrequired')}</p>
        <div className='row'>
          <p className='label'>{this.props.t('initialinvestment')}</p>
          <InputNumber
            value={this.state.startingAmount}
            min={Math.max(
              500,
              this.props.selectedAccount.balances[0].virtualAccountValue
            )}
            onValueChange={(e) => this.handleStartingAmountChange(e.value)}
            mode='currency'
            currency='USD'
            minFractionDigits={0}
            locale='en-US'
          />
        </div>
      </div>
    )
  }

  periodicForm() {
    return (
      <div className='periodic-form-container'>
        <p className='header'>{this.props.t('periodiccontribution')}</p>
        <div className='input-container'>
          <div className='monthly-form-container'>
            <p className='label'>{this.props.t('monthlycontribution')}</p>
            <InputNumber
              value={this.state.contributionAmount}
              onValueChange={(e) => this.handlePeriodicAmountChange(e.value)}
              mode='currency'
              currency='USD'
              locale='en-US'
              disabled={this.state.roundUp}
              minFractionDigits={0}
            />
          </div>
          <div className='checkbox-container'>
            <div className='row'>
              <Checkbox
                onChange={(e) => this.handleRoundUpChange(e.checked)}
                checked={this.state.roundUp}
              ></Checkbox>
              <p className='label'>{this.props.t('weeklyroundup')}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  depositForm() {
    if (this.state.bankAccounts) {
      return (
        <div className='bank-accounts-form-container'>
          <Dropdown
            value={this.state.selectedBankAccount}
            options={this.state.bankAccounts}
            onChange={(e) => this.handleBankAccountChange(e.value)}
            placeholder={this.props.t('selectanaccount')}
          />
        </div>
      )
    }
  }

  requirementMessage() {
    let value =
      this.state.startingAmount -
      this.props.selectedAccount?.balances[0]?.virtualAccountValue
    if (value > 0) {
      return (
        <div className='requirement-message-container'>
          <p className='message'>
            {this.props.t('additional')} {this.props.formatCurrency(value)}{' '}
            {this.props.t('isrequiredtomeetyourinitialinvestment')}
          </p>
        </div>
      )
    }
  }

  getConfig() {
    const formatCurrency = this.props.formatCurrency
    const config = {
      credits: false,
      title: {
        text: null,
      },
      chart: {
        zoomType: 'x',
        backgroundColor: 'rgb(247, 247, 247)',
      },
      time: {
        useUTC: false,
      },
      rangeSelector: {
        enabled: false,
      },
      yAxis: {
        labels: {
          format: '${value}',
        },
        gridLineColor: 'transparent',
        opposite: false,
        // max: max,
        min: this.state.startingAmount,
      },

      tooltip: {
        shared: true,
        useHTML: true,
        formatter() {
          return formatCurrency(this.y)
        },
      },

      series: [
        {
          showInLegend: false,
          color: '#3525e4',
          data: this.state.graphData,
          tooltip: {
            valueDecimals: 2,
          },
          type: 'area',
          fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
              [0, '#3525e4'],
              [1, 'rgba(53,37,228,.1)'],
            ],
          },
          marker: {
            enabled: false,
          },
        },
      ],

      xAxis: {
        labels: {
          format: '{value} Years',
        },
        // dateTimeLabelFormats: {
        //   hour: '%I:%M %P'
        // },
        // type: 'datetime',
        tickLength: 0,
      },
    }
    return config
  }

  submitButton() {
    return (
      <div className='button-container'>
        {this.props.selectedAccount &&
        this.props.selectedAccount?.balances[0]?.virtualAccountValue >=
          this.state.startingAmount ? (
          <Button
            className='submit-button'
            label={this.props.t('startrobo')}
            onClick={() => {
              this.goToSummary()
            }}
          />
        ) : (
          <Button
            className='submit-button'
            label={this.props.t('addcash')}
            onClick={() => {
              this.toggleShowDepositForm()
            }}
          />
        )}
      </div>
    )
  }

  goToSummary = () => {
    if (
      (this.state.contributionAmount === 0 && !this.state.roundUp) ||
      this.state.selectedBankAccount
    ) {
      this.props.updateInvestmentDetails(
        this.state.startingAmount,
        this.state.contributionAmount,
        this.state.roundUp,
        this.state.selectedBankAccount
      )
    } else {
      this.toggleErrorMessage(this.props.t('pleaseselectabankaccount'))
    }
  }

  // validateAccount() {
  //   if (
  //     (this.state.contributionAmount === 0 && !this.state.roundUp) ||
  //     this.state.selectedBankAccount
  //   ) {
  //     this.toggleShowDepositForm();
  //   } else {
  //     this.toggleErrorMessage("Please select a bank account");
  //   }
  // }

  showErrorMessage = () => {
    return <p className='error-message'>{this.state.errorMessage}</p>
  }

  render() {
    if (this.props.t) {
      return (
        <div className='investment-form-container'>
          <p className='header'>{this.props.t('investmentcalculator')}</p>
          {!this.state.isCustom && this.showGraph()}
          {this.initialInvestmentForm()}
          {this.periodicForm()}
          {this.depositForm()}
          {this.requirementMessage()}
          {this.showErrorMessage()}
          {this.submitButton()}
          <DepositDialog
            toggleShowDepositForm={this.toggleShowDepositForm}
            selectedAccount={this.props.selectedAccount}
            user={this.props.user}
            getAllAccountInfo={this.props.getAllAccountInfo}
            showDepositForm={this.state.showDepositForm}
            isSmallScreen={this.props.isSmallScreen}
            accounts={this.props.accounts}
            t={this.props.t}
          />
        </div>
      )
    } else return null
  }
}
