import React from 'react'

const brandColor = 'rgb(70,182,179)'

export default class BrokerageRow extends React.Component {
  state = {
    checked: false,
    logo: 'not-found',
  }

  componentDidMount() {
    // if(this.state.checked){
    //     console.log('checked')
    // }else{
    //     this.checkLogo()
    // }
  }

  addCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  checkLogo = () => {
    let symbol = this.props.stock.symbol.toLowerCase()
    let url = `https://drivewealth.imgix.net/symbols/${symbol}.png`
    var http = new XMLHttpRequest()
    http.open('HEAD', url, false)
    http.send()
    if (http.status != 404) {
      this.setState({ logo: url, checked: true })
    } else {
      this.setState({ checked: true })
    }
  }

  createBox = () => {
    if (this.props.stock.imageURL === 'not-found') {
      return (
        <div
          style={{
            objectFit: 'contain',
            width: 50,
            height: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgb(17,17,17)',
            borderRadius: 10,
          }}
        >
          <p
            style={{
              margin: 0,
              fontSize: 24,
              fontWeight: 'bold',
              color: 'rgb(255,255,255)',
            }}
          >
            {this.props.stock.symbol[0]}
          </p>
        </div>
      )
    } else {
      return (
        <img
          // src={`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`}
          src={this.props.stock.imageURL}
          style={{ objectFit: 'contain', width: 70, height: 70, marginLeft: 5 }}
        />
      )
    }
  }

  render() {
    if (!this.props.stock.cashEq) {
      let allocation = isNaN(
        ((this.props.stock.instValue / this.props.total) * 100).toFixed(2)
      )
        ? 0
        : ((this.props.stock.instValue / this.props.total) * 100).toFixed(2)
      if (this.props.total <= 0) {
        allocation = '-'
      }
      return (
        <div style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '8%' }}>
              {/* {this.props.stock.symbol === 'CASH'?null: 
                              this.state.logo === "not-found"?
                              <div style={{objectFit:'contain', width:50,height:50,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'rgb(17,17,17)',borderRadius:10 }}
                              >
                                  <p style={{margin:0, fontSize:24,fontWeight:'bold', color:'rgb(255,255,255)'}}>{this.props.stock.symbol[0]}</p>
                              </div>
                              :
                              <img 
                                  src={this.state.logo}
                                  style={{objectFit:'contain', width:65,height:65, paddingRight:10 }}
                              />
                            } */}
              {this.createBox()}
            </div>
            <a
              style={{ width: '10%' }}
              /*onClick={()=>this.props.tradeStock()} href='#'*/ href={
                this.props.stock.symbol === 'CASH' ||
                this.props.stock.symbol === 'Us dollar'
                  ? null
                  : `/Dashboard/Trade/${this.props.stock.symbol}`
              }
            >
              <p
                style={{
                  width: '100%',
                  margin: 0,
                  textAlign: 'left',
                  color: brandColor,
                  marginLeft: 7,
                }}
              >
                {this.props.stock.symbol}
              </p>
            </a>
            <p style={{ width: '20%', margin: 0, textAlign: 'right' }}>
              ${this.addCommas(this.props.stock.averagePrice.toFixed(2))}
            </p>
            <p style={{ width: '20%', margin: 0, textAlign: 'right' }}>
              {this.props.stock.quantity > 1
                ? this.addCommas(this.props.stock.quantity.toFixed(2))
                : this.props.stock.quantity.toFixed(8)}
            </p>
            <p style={{ width: '20%', margin: 0, textAlign: 'right' }}>
              ${this.addCommas(this.props.stock.instValue.toFixed(2))}
            </p>
            {/* <p style={{width:'20%', margin:0 ,textAlign:'right'}}>{allocation === '-'? '-' : allocation + '%'}</p>   */}
            <p style={{ width: '20%', margin: 0, textAlign: 'right' }}>
              {this.props.stock.unrealizedPL.toFixed(2)}
            </p>
            <div style={{ width: '2%' }}></div>
          </div>
          <hr></hr>
        </div>
      )
    } else {
      return null
    }
  }
}
