//--------WHICH Environment? ---------------------------------
//                                                            |
export default 'https://api.allocaterite.com' // prod |
// export default 'https://dev-api.allocaterite.com' // dev   |
//                                                            |
//-----------------------------------------------------------

//////////////////////////////////////////////////////////////////////
/*
import ENDPOINT from '../Endpoint'

${ENDPOINT}
*/
