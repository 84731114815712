import React from 'react'
import Chart from 'react-apexcharts'

const LineChart = ({ data }) => {
  console.log('render the chat again ')

  const fillMissingTimestamps = (dataInfo) => {
    console.log('filled missing timestamps')

    // Initialize result object and a set to collect all unique timestamps
    const timestamps = new Set()
    const result = {}

    // Step 1: Collect timestamps and prepare structure for results
    for (const key in dataInfo) {
      if (dataInfo.hasOwnProperty(key) && Array.isArray(dataInfo[key])) {
        result[key] = [] // Initialize result for each stock
        dataInfo[key]?.forEach((entry) => {
          timestamps.add(entry[0]) // Collect all timestamps
        })
      }
    }

    // Step 2: Sort timestamps to maintain order
    const sortedTimestamps = Array.from(timestamps)?.sort((a, b) => a - b)

    // Step 3: Populate result with aligned data for each stock
    for (const key in dataInfo) {
      if (dataInfo.hasOwnProperty(key) && Array.isArray(dataInfo[key])) {
        const stockData = dataInfo[key]
        const newData = []
        const entryLength = stockData[0]?.length ?? 0
        const nullArray = Array(entryLength).fill(null) // Pre-construct a null array of appropriate length

        sortedTimestamps?.forEach((timestamp) => {
          const found = stockData?.find((entry) => entry[0] === timestamp)
          if (found) {
            newData.push(found)
          } else {
            newData.push([timestamp, ...nullArray?.slice(1)]) // Slice from 1 to skip overwriting the timestamp
          }
        })

        result[key] = newData
      }
    }

    return result
  }

  // CHECK if all array is same length
  function checkUniformArrayLengths(graphData) {
    // if (!graphData || !graphData.chart_data) {
    //   console.log("No data available.");
    //   return false;
    // }

    let allArrays = []

    allArrays = Object?.values(graphData?.chart_data)

    // console.log("allArrays", allArrays);
    let arrayLength = null

    for (let i = 0; i < allArrays?.length; i++) {
      if (arrayLength === null) {
        arrayLength = allArrays[i]?.length // Set the reference length from the first array
      } else if (allArrays[i]?.length !== arrayLength) {
        return false // If any array does not match the reference length, return false
      }
    }

    return true // If all arrays match the reference length, return true
  }

  const formatNumberAbbre = (value, decimal) => {
    // console.log("value in formatNumberAbbre", value);
    return Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: decimal,
    }).format(value)
  }

  const transformToTooltipArray = (data, config) => {
    // Extract all the arrays of arrays
    const allArrays = Object?.values(data)

    // Map each set of arrays to extract only the second elements of the innermost arrays
    const result = allArrays?.map((arrays) =>
      arrays?.map((innerArray) => {
        // Extract the value using the tooltip_index from config.
        const value = innerArray[config?.tooltip_index]

        // Check if value is a number and if config.tooltip_start_position is set
        if (typeof value === 'number') {
          const formattedNumber = value?.toFixed(2) // Convert number to string with two decimal places
          if (config?.tooltip_start_position) {
            return `${config?.tooltip_format}${formattedNumber}`
          } else {
            return `${formattedNumber}${config?.tooltip_format}`
          }
        }

        const formattedValue =
          value !== null
            ? config?.tooltip_start_position
              ? `${config?.tooltip_format}${value}`
              : `${value}${config?.tooltip_format}`
            : null

        // If value is not a number, just return the value as is or some placeholder
        return formattedValue
      })
    )

    return result
  }

  let graphData = data

  // console.log("graphdata in linechart", graphData);

  // old one
  const alignedData = checkUniformArrayLengths(graphData)
    ? graphData?.chart_data
    : fillMissingTimestamps(graphData?.chart_data)

  let symbolList = graphData?.symbol
    ? typeof graphData?.symbol === 'string'
      ? graphData?.symbol.replace(/[\[\]']/g, '').split(' ')
      : graphData?.symbol
    : Object?.keys(alignedData)

  // console.log("symbolList", symbolList);

  let axisConfig = graphData?.axis_labels

  let xAxisData = alignedData?.[symbolList?.[0]]?.map(
    (subArray) => subArray[axisConfig?.x_index]
  )

  // console.log("xAxisData", xAxisData);

  let isYLeadingFormat = axisConfig?.y_format === '%' ? false : true

  let tooltipData =
    axisConfig?.tooltip_index !== undefined
      ? transformToTooltipArray(alignedData, axisConfig)
      : null

  let customYTickers = axisConfig?.y_ticks ? axisConfig?.y_ticks : null

  // console.log("tooltipData", tooltipData);

  const checkValuesInIndex = (data, index) => {
    for (let i = 0; i < data?.length; i++) {
      if (data[i][index] !== null) {
        return true
      }
    }
    return false
  }

  let chartOption = {
    chart: {
      id: graphData?.chart_type ?? 'line_chart',
      toolbar: {
        show: true,
        offsetY: 20,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: true,
        },
      },
      animations: {
        enabled: false,
      },
    },

    title: {
      text: graphData?.title || graphData?.chart_title,
      align: 'center',
    },

    xaxis: {
      type: axisConfig?.x === 'Date' ? 'datetime' : 'category',
      categories: xAxisData,
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      tickPlacement: 'on',
      tooltip: {
        enabled: false,
      },
      labels: {
        rotate: 0,
      },

      tickAmount: 3,
    },
    yaxis: {
      axisBorder: {
        show: true,
      },

      min: customYTickers ? Math.min(...customYTickers) : undefined,
      max: customYTickers ? Math.max(...customYTickers) : undefined,
      // tickAmount: customYTickers ? customYTickers?.length - 1 : undefined, // Control number of ticks

      labels: {
        formatter: function (val, index) {
          // console.log("index in labels formatter", index);
          // console.log("val in labels formatter", val);

          return isYLeadingFormat
            ? axisConfig?.y_format === '$'
              ? axisConfig?.y_labels
                ? axisConfig?.y_labels?.[val]
                  ? `${axisConfig?.y_format}${axisConfig?.y_labels?.[val]}`
                  : ''
                : `${axisConfig?.y_format}${formatNumberAbbre(val, 2)}`
              : // `${axisConfig?.y_format}${formatNumberAbbre(val, 2)}`
                `${axisConfig?.y_format}${Number(val)?.toFixed(2)}`
            : axisConfig?.y_format === '%'
            ? `${formatNumberAbbre(val, 2)}${axisConfig?.y_format}`
            : `${Number(val)?.toFixed(2)}${axisConfig?.y_format}`

          // return isYLeadingFormat
          //   ? axisConfig?.y_format === "$"
          //     ? axisConfig?.y_labels?.[val]
          //       ? `${axisConfig?.y_format}${axisConfig?.y_labels?.[val]}`
          //       : `${axisConfig?.y_format}${formatNumberAbbre(val, 2)}`
          //     : `${axisConfig?.y_format}${Number(val)?.toFixed(2)}`
          //   : axisConfig?.y_format === "%"
          //   ? `${formatNumberAbbre(val, 2)}${axisConfig?.y_format}`
          //   : `${Number(val)?.toFixed(2)}${axisConfig?.y_format}`;
        },
      },
    },
    tooltip: {
      shared: true,
      hideEmptySeries: false,
      y: {
        formatter: function (val, chartGraphData) {
          if (val !== null) {
            // console.log("val", val);
            return tooltipData
              ? tooltipData[chartGraphData?.seriesIndex][
                  chartGraphData?.dataPointIndex
                ]
              : isYLeadingFormat
              ? axisConfig?.y_format === '$'
                ? `${axisConfig?.y_format}${formatNumberAbbre(val, 2)}`
                : `${axisConfig?.y_format}${Number(val)?.toFixed(2)}`
              : `${Number(val)?.toFixed(2)}${axisConfig?.y_format}`
          }
        },
      },
      x: {
        formatter:
          graphData?.chart_type === 'YIELD_CURVE_CHART'
            ? function (val, chartGraphData) {
                let nameIndex = chartGraphData?.seriesIndex
                let valueIndex = chartGraphData?.dataPointIndex

                if (
                  checkValuesInIndex(chartGraphData?.series, valueIndex)
                  // alignedData?.[symbolList[nameIndex]]?.[valueIndex]?.[
                  //   axisConfig?.y_index
                  // ]
                ) {
                  return alignedData?.[symbolList[nameIndex]]?.[valueIndex]?.[0]
                } else {
                  return null
                }
              }
            : undefined,
      },
    },

    colors: [
      '#0CCA98',
      '#75E6DA',
      '#FF8642',
      '#7EE77C',
      '#FFDF6C',
      '#F04A37',
      '#D6D6D6',
    ],

    stroke: {
      width: 2,
    },

    grid: {
      yaxis: {
        lines: {
          show: customYTickers ? false : true,
        },
      },
    },

    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      itemMargin: {
        horizontal: 10,
        vertical: 15,
      },
    },
  }

  // console.log("alignedData", alignedData);
  // console.log("axisConfig", axisConfig);

  let chartSeries = Object?.entries(alignedData)?.map(([key, value]) => {
    // Log the key and value being processed

    // Process the data for the chart series
    return {
      name: key, // Set the 'name' to the key of the object
      type: 'line', // Assume default type as "line"
      data: value?.map((subArray) => subArray[axisConfig?.y_index]), // Collect the second element of each sub-array
    }
  })

  // console.log("chartSeries", chartSeries);

  return (
    <Chart
      options={chartOption}
      series={chartSeries}
      type='line'
      width='100%'
      height='400'
    />
  )
}

export const MemoizedLineChart = React.memo(LineChart)

// export default BuildChartUI;
