import React, { useState, useRef, useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import axios from 'axios'
import Modal from 'react-modal'
import { useSpringCarousel } from 'react-spring-carousel-js'

import BackButton from '../../assets/newLandingPage/BackButton.svg'
import BlackBlob from '../../assets/newLandingPage/BlackBlob.svg'
import Goals from '../../assets/newLandingPage/chooseYourGoal.svg'

export default function ProductsPanel(props) {
  const screens = [
    {
      title: 'Invest Robo',
    },
    {
      title: 'AI Trader',
    },
    {
      title: 'Goals',
    },
    {
      title: 'Budgeting',
    },
  ]

  const [initContribution, setInitContribution] = useState(500)
  const [displayInitContribution, setDisplayInitContribution] = useState(500)
  const [contributionAmount, setContributionAmount] = useState(1)
  const [displayContributionAmount, setDisplayContributionAmount] = useState(1)
  const [totalReturn, setTotalReturn] = useState((1361.6).toFixed(2))
  const graphRef = useRef()

  const graphOptions = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          display: false,
        },
      ],
      yAxes: [
        {
          display: false,
          ticks: {
            fontColor: '#FFF', // this here
            // min: 500,
            // max: 225000,
            stepSize: 25000,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  }

  useEffect(() => {
    async function getGraphData() {
      let body = {
        goalData: {
          compoundInterest: 'Compound Monthly',
          contributionFrequency: 'Monthly',
          contributionAmount: contributionAmount,
          startingAmount: initContribution,
          targetedAmount: 10000,
          yearsToAccumulate: 5,
          roi: 0.089,
          totalTaxRate: 0.15,
          inflationRate: 0.1,
        },
        option: { action: 'calculate' },
      }
      let { data } = await axios.post(
        'https://api.allocaterite.com/mobile/investment-forecasting',
        body
      )
      if (graphRef.current) {
        graphRef.current.chartInstance.data.datasets[0].data = Object.values(
          data.balanceData.balance
        )
        graphRef.current.chartInstance.update()
        setTotalReturn(data.balanceData.balance['10'].toFixed(2))
      }
    }
    getGraphData()
  }, [initContribution, contributionAmount])

  const handleDotClick = (i) => {
    props.setCurrentProduct(i)
  }

  const { carouselFragment } = useSpringCarousel({
    initialActiveItem: 3,
    items: [
      {
        id: 'item-1',
        renderItem: (
          <div className='center-content'>
            <div className='content-container'>
              <div className='robo-chart-container'>
                <div className='inputs'>
                  <p className='final-amount thin'>${totalReturn}</p>
                  <p>Initial Amount</p>
                  <div className='slider-container'>
                    <p className='thin'>${displayInitContribution}</p>
                    <input
                      type='range'
                      min='500'
                      max='50000'
                      step={100}
                      value={displayInitContribution}
                      onChange={(e) =>
                        setDisplayInitContribution(e.target.value)
                      }
                      onMouseUp={(e) => setInitContribution(e.target.value)}
                      onTouchEnd={(e) => setInitContribution(e.target.value)}
                    />
                  </div>
                  <p>Contribution Amount</p>
                  <p className='thin'>${displayContributionAmount}</p>
                  <div className='slider-container'>
                    <input
                      type='range'
                      min='1'
                      max='500'
                      step={1}
                      value={displayContributionAmount}
                      onChange={(e) =>
                        setDisplayContributionAmount(e.target.value)
                      }
                      onMouseUp={(e) => setContributionAmount(e.target.value)}
                      onTouchEnd={(e) => setContributionAmount(e.target.value)}
                    />
                  </div>
                </div>
                <div className='graph'>
                  <Line
                    data={{
                      labels: ['', '', 3, '', 5, '', '', '', '', 10],
                      datasets: [
                        {
                          data: [
                            500.0, 556.9819654883372, 619.0353259051365,
                            686.611435399031, 760.2018186378821,
                            840.341745984991, 927.6141268659927,
                            1022.6537496454036, 1126.1518988521823,
                            1238.861383338364, 1361.602011943816,
                          ],
                          borderColor: 'white',
                          backgroundColor: '#716ff6',
                        },
                      ],
                    }}
                    options={graphOptions}
                    ref={graphRef}
                  />
                </div>
              </div>
              <div className='robo-text'>
                <div className='text-column'>
                  <p>Used by Institutions</p>
                  <p>
                    Democratizing RIA strategies for consumers. Simply fill out
                    a questionaire and Investall will determine a strategy that
                    is right for you.{' '}
                  </p>
                  <p>Other top robo advisors, now available to the masses. </p>
                </div>
                <div className='text-column'>
                  <p>Tailored to your choices</p>
                  <p>
                    Complete an investment questionaire and our AI picks the
                    best strategy for your account. Montly rebalancing based on
                    the current market.
                  </p>
                </div>
                <div className='text-column'>
                  <p>Highly Liquid</p>
                  <p>
                    Keep full custody of your investment account. Change your
                    mind with ease, get your money when you need it.
                  </p>
                </div>
              </div>
              <div className='bottom-button'>
                <div className='next-button'>
                  <p className='bold-text'>Start Robo</p>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        id: 'item-2',
        renderItem: (
          <div className='center-content'>
            <div className='content-container row1'>
              <div className='column-container'>
                <p className='primary-text'>
                  Automate your trading with up to four stocks or
                  cryptocurrencies.
                </p>
                <div className='group-of-four'>
                  <div className='single-element'>AAPL</div>
                  <div className='single-element'>TSLA</div>
                  <div className='single-element'>AMZN</div>
                  <div className='single-element'>NFLX</div>
                </div>
                <p className='small-text bold-text'>Quant Strategy</p>
                <p className='small-text short'>
                  Leverage AI-powered automated trading, just like the hedge
                  funds use.
                </p>
                <p className='small-text bold-text'>Risk Mangament</p>
                <p className='small-text short'>
                  Trades are automatically placed based on momentym shifts - a
                  combination of over a hundred thousand factors. Our AI
                  optimizes trade orders to best lower risk and reap the reward
                </p>
              </div>
              <div className='column-container'>
                <div className='img-container'>
                  <img src={BlackBlob} alt='' />
                </div>
                <div className='bottom-button'>
                  <div className='next-button'>
                    <p className='bold-text'>Start AI Trader</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        id: 'item-3',
        renderItem: (
          <div className='center-content'>
            <div className='content-container row1'>
              <div className='column-container spacing'>
                <p className='primary-text'>
                  Visualize yourself in five years, now dream bigger.
                </p>
                <p className='bold-text'>Dream Bigger</p>
                <p>
                  It’s important to set goals for your financial future. Wether
                  it’s for your next mortgage or a familty vacation, Goals will
                  automate the process so you can relax and visualize your
                  future.
                </p>
                <p>
                  Select from a strategy based on the time frame, and watch as
                  Goals manifests your desires into reality.
                </p>
                <p>We can help by giving you a level of predictability.</p>
              </div>
              <div className='column-container'>
                <div className='img-container'>
                  <img src={Goals} alt='' />
                </div>
                <div className='bottom-button'>
                  <div className='next-button'>
                    <p className='bold-text'>Start Goals</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        id: 'item-4',
        renderItem: (
          <div className='center-content'>
            <div className='content-container row1'>
              <div className='column-container spacing'>
                <p className='primary-text'>What’s your net worth?</p>
                <p className='bold-text'>Financial Overview</p>
                <p>
                  It’s important to set goals for your financial future. Wether
                  it’s for your next mortgage or a familty vacation, Goals will
                  automate the process so you can relax and visualize your
                  future.
                </p>
                <p>
                  Select from a strategy based on the time frame, and watch as
                  Goals manifests your desires into reality.
                </p>
                <p>We can help by giving you a level of predictability.</p>
              </div>
              <div className='column-container'>
                <div className='img-container'>
                  <img src={Goals} alt='' />
                </div>
                <div className='bottom-button'>
                  <div className='next-button'>
                    <p className='bold-text'>Explore Budgeting</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ],
  })

  const renderContent = () => {
    switch (props.currentProduct) {
      case 1:
        return (
          <div className='center-content'>
            <div className='content-container row1'>
              <div className='column-container'>
                <p className='primary-text'>
                  Automate your trading with up to four stocks or
                  cryptocurrencies.
                </p>
                <div className='group-of-four'>
                  <div className='single-element'>AAPL</div>
                  <div className='single-element'>TSLA</div>
                  <div className='single-element'>AMZN</div>
                  <div className='single-element'>NFLX</div>
                </div>
                <p className='small-text bold-text'>Quant Strategy</p>
                <p className='small-text short'>
                  Leverage AI-powered automated trading, just like the hedge
                  funds use.
                </p>
                <p className='small-text bold-text'>Risk Mangament</p>
                <p className='small-text short'>
                  Trades are automatically placed based on momentum shifts - a
                  combination of over a hundred thousand factors. Our AI
                  optimizes trade orders to best lower risk and reap the reward
                </p>
              </div>
              <div className='column-container'>
                <div className='img-container'>
                  <img src={BlackBlob} alt='' />
                </div>
                <div className='bottom-button'>
                  <div className='next-button'>
                    <p className='bold-text'>Start AI Trader</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      case 2:
        return (
          <div className='center-content'>
            <div className='content-container row1'>
              <div className='column-container spacing'>
                <p className='primary-text'>
                  Visualize yourself in five years, now dream bigger.
                </p>
                <p className='bold-text'>Dream Bigger</p>
                <p>
                  It’s important to set goals for your financial future. Wether
                  it’s for your next mortgage or a familty vacation, Goals will
                  automate the process so you can relax and visualize your
                  future.
                </p>
                <p>
                  Select from a strategy based on the time frame, and watch as
                  Goals manifests your desires into reality.
                </p>
                <p>We can help by giving you a level of predictability.</p>
              </div>
              <div className='column-container'>
                <div className='img-container'>
                  <img src={Goals} alt='' />
                </div>
                <div className='bottom-button'>
                  <div className='next-button'>
                    <p className='bold-text'>Start Goals</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      case 3:
        return (
          <div className='center-content'>
            <div className='content-container row1'>
              <div className='column-container spacing'>
                <p className='primary-text'>What’s your net worth?</p>
                <p className='bold-text'>Financial Overview</p>
                <p>
                  It’s important to set goals for your financial future. Wether
                  it’s for your next mortgage or a familty vacation, Goals will
                  automate the process so you can relax and visualize your
                  future.
                </p>
                <p>
                  Select from a strategy based on the time frame, and watch as
                  Goals manifests your desires into reality.
                </p>
                <p>We can help by giving you a level of predictability.</p>
              </div>
              <div className='column-container'>
                <div className='img-container'>
                  <img src={Goals} alt='' />
                </div>
                <div className='bottom-button'>
                  <div className='next-button'>
                    <p className='bold-text'>Explore Budgeting</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      default:
        return (
          <div className='center-content'>
            <div className='content-container'>
              <div className='robo-chart-container'>
                <div className='inputs'>
                  <p className='final-amount thin'>${totalReturn}</p>
                  <p>Initial Amount</p>
                  <div className='slider-container'>
                    <p className='thin'>${displayInitContribution}</p>
                    <input
                      type='range'
                      min='500'
                      max='50000'
                      step={100}
                      value={displayInitContribution}
                      onChange={(e) =>
                        setDisplayInitContribution(e.target.value)
                      }
                      onMouseUp={(e) => setInitContribution(e.target.value)}
                      onTouchEnd={(e) => setInitContribution(e.target.value)}
                    />
                  </div>
                  <p>Contribution Amount</p>
                  <p className='thin'>${displayContributionAmount}</p>
                  <div className='slider-container'>
                    <input
                      type='range'
                      min='1'
                      max='500'
                      step={1}
                      value={displayContributionAmount}
                      onChange={(e) =>
                        setDisplayContributionAmount(e.target.value)
                      }
                      onMouseUp={(e) => setContributionAmount(e.target.value)}
                      onTouchEnd={(e) => setContributionAmount(e.target.value)}
                    />
                  </div>
                </div>
                <div className='graph'>
                  <Line
                    data={{
                      labels: ['', '', 3, '', 5, '', '', '', '', 10],
                      datasets: [
                        {
                          data: [
                            500.0, 556.9819654883372, 619.0353259051365,
                            686.611435399031, 760.2018186378821,
                            840.341745984991, 927.6141268659927,
                            1022.6537496454036, 1126.1518988521823,
                            1238.861383338364, 1361.602011943816,
                          ],
                          borderColor: 'white',
                          backgroundColor: '#716ff6',
                        },
                      ],
                    }}
                    options={graphOptions}
                    ref={graphRef}
                  />
                </div>
              </div>
              <div className='robo-text'>
                <div className='text-column'>
                  <p>Trusted by institutional traders</p>
                  <p>Audited track record of high risk adjusted returns. </p>
                  <p>Other top robo advisors, now available to the masses. </p>
                </div>
                <div className='text-column'>
                  <p>Tailored to your choices</p>
                  <p>
                    Complete an investment questionaire and our AI picks the
                    best strategy for your account. Montly rebalancing based on
                    the current market.
                  </p>
                </div>
                <div className='text-column'>
                  <p>Highly Liquid</p>
                  <p>
                    Keep full custody of your investment account. Change your
                    mind with ease, get your money when you need it.
                  </p>
                </div>
              </div>
              <div className='bottom-button'>
                <div className='next-button'>
                  <p className='bold-text'>Start Robo</p>
                </div>
              </div>
            </div>
          </div>
        )
    }
  }

  return (
    <Modal
      className={
        props.currentProduct === 3
          ? 'products-panel-container budgeting-background'
          : props.currentProduct === 2
          ? 'products-panel-container goals-background'
          : props.currentProduct === 1
          ? 'products-panel-container ai-trader-background'
          : 'products-panel-container'
      }
      isOpen={props.openPanel}
      onRequestClose={() => props.togglePanel()}
      ariaHideApp={false}
    >
      <div
        className={
          props.currentProduct === 0
            ? 'products-panel-wrapper robo-offset'
            : 'products-panel-wrapper'
        }
      >
        <div className='back-container' onClick={() => props.togglePanel()}>
          <img src={BackButton} alt='Back' />
          <p className='product-title-text'>
            {screens[props.currentProduct].title}
          </p>
          <div className='right-placeholder' />
        </div>
        {renderContent()}
        {/* {carouselFragment} */}
      </div>
      <div className='bottom-row'>
        <div className='dots'>
          {screens.map((screen, i) => {
            return (
              <div
                className={props.currentProduct === i ? 'dot selected' : 'dot'}
                onClick={() => handleDotClick(i)}
              ></div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}
