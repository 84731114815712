import React from 'react'
import { NavLink } from 'react-router-dom'

import upArrow from '../assets/single_up.svg'
import downArrow from '../assets/single_down.svg'
import strongUpArrow from '../assets/double_up.svg'
import strongDownArrow from '../assets/double_down.svg'
import flatArrow from '../assets/flat.svg'

import DoubleNegative from '../assets/double-negative.svg'
import SingleNegative from '../assets/single-negative.svg'
import Neutral from '../assets/neutral.svg'
import SinglePositive from '../assets/single-positive.svg'
import DoublePositive from '../assets/double-positive.svg'
import EditIcon from '../assets/edit_icon.svg'
import EditIconActive from '../assets/edit_active_icon.svg'
import { removeFromWatchlist } from '../services/userServices'
import '../scss/pages/HeaderDropdownPage.scss'

export default class HeaderDropdown extends React.Component {
  state = {
    hover: false,
  }

  getArrow = (direction) => {
    switch (direction) {
      case -1:
        return SingleNegative
      case 1:
        return SinglePositive
      case 2:
        return DoublePositive
      case -2:
        return DoubleNegative
      default:
        return Neutral
    }
  }

  checkLogo = (symbol) => {
    // console.log('symbol', symbol)
    let url = `https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`
    var http = new XMLHttpRequest()
    http.open('HEAD', url, false)
    http.send()
    if (http.status != 404) {
      return (
        <img
          // src={`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`}
          src={`https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`}
          onError='this.onerror=null; this.remove();'
          style={{ objectFit: 'contain', width: 70, height: 70, marginLeft: 5 }}
        />
      )
    } else {
      return (
        <div
          style={{ width: 70, height: 70, backgroundColor: 'transparent' }}
        />
      )
    }
  }

  createBox = () => {
    if (this.props.img === 'not-found') {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              objectFit: 'contain',
              width: 40,
              height: 40,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#272748',
              borderRadius: 10,
              padding: '15px',
              marginLeft: 5,
            }}
          >
            <p
              style={{
                margin: 0,
                fontSize: 24,
                fontWeight: 'bold',
                color: 'rgb(255,255,255)',
              }}
            >
              {this.props.symbol[0]}
            </p>
          </div>
          <p
            style={{
              margin: 0,
              fontSize: 20,
              marginLeft: 15,
              fontWeight: 'bold',
              color: '#272748',
            }}
          >
            {this.props.symbol}
          </p>
        </div>
      )
    } else {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={this.props.img}
            style={{
              objectFit: 'contain',
              width: 40,
              height: 40,
              marginLeft: 5,
            }}
          />
          <p
            style={{
              margin: 0,
              fontSize: 20,
              marginLeft: 15,
              fontWeight: 'bold',
              color: '#272748',
            }}
          >
            {this.props.symbol}
          </p>
        </div>
      )
    }
  }

  addCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  createRow = (ele) => {
    return (
      <NavLink exact to={`/Dashboard/Trade/${ele.symbol}`}>
        <div
          style={{
            display: 'flex',
            borderBottom: 'solid',
            borderWidth: 0.25,
            borderColor: '#ddd',
            width: '100%',
          }}
        >
          <div style={{ flex: 1 }}>
            <p style={{ margin: 0 }}>{ele.symbol}</p>
          </div>
          <div style={{ flex: 1 }}>
            <img
              src={this.getArrow(ele.direction)}
              style={{
                // marginTop: 5,
                width: 20,
                height: 20,
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <img
              src={this.getArrow(ele.directionPrev)}
              style={{
                //    marginTop: 5,
                width: 20,
                height: 20,
              }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <p style={{ margin: 0 }}>{ele.priceUSD}</p>
          </div>
        </div>
      </NavLink>
    )
  }

  remove = async () => {
    const res = await removeFromWatchlist(
      [this.props.selectedWatchlistGroup],
      [this.props.symbol],
      this.props.user.idToken.jwtToken
    )
    if (res) {
      await this.props.refreshWl()
    }
  }

  render() {
    if (this.props.trendKey) {
      return (
        <div
          style={{
            width: '100%',
            backgroundColor: 'rgb(255,255,255)',
            minWidth: 205,
            textDecoration: 'none',
            cursor: 'default',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p style={{ margin: 0, color: '#272748' }}>Price Trend Key</p>
          </div>
          <div
            style={{
              display: 'flex',
              borderBottom: 'solid',
              borderWidth: 0.25,
              borderColor: '#ddd',
              width: '100%',
              alignItems: 'flex-end',
            }}
          >
            <div
              style={{
                width: '20%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: this.props.size === 'small' ? 'column' : 'row',
                }}
              >
                <p style={{ margin: 0, color: '#272748', marginRight: 4 }}>
                  Strong
                </p>
                <p style={{ margin: 0, color: '#272748' }}>Up</p>
              </div>
              <div>
                <img
                  src={strongUpArrow}
                  style={{
                    marginBottom: 5,
                    width: 20,
                    height: 20,
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width: '20%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <p style={{ margin: 0, color: '#272748' }}>Up</p>
              </div>
              <div>
                <img
                  src={upArrow}
                  style={{
                    marginBottom: 5,
                    width: 20,
                    height: 20,
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width: '20%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <p style={{ margin: 0, color: '#272748' }}>Flat</p>
              </div>
              <div>
                <img
                  src={flatArrow}
                  style={{
                    marginBottom: 5,
                    width: 20,
                    height: 20,
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width: '20%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div>
                <p style={{ margin: 0, color: '#272748' }}>Down</p>
              </div>
              <div>
                <img
                  src={downArrow}
                  style={{
                    marginBottom: 5,
                    width: 20,
                    height: 20,
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width: '20%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: this.props.size === 'small' ? 'column' : 'row',
                }}
              >
                <p style={{ margin: 0, color: '#272748', marginRight: 4 }}>
                  Strong
                </p>
                <p style={{ margin: 0, color: '#272748' }}>Down</p>
              </div>
              <div>
                <img
                  src={strongDownArrow}
                  style={{
                    marginBottom: 5,
                    width: 20,
                    height: 20,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )
    }
    if (this.props.editMode && this.props.columnTitle) {
      return (
        <div
          style={{
            width: '100%',
            backgroundColor: 'transparent',
            minWidth: 205,
            textDecoration: 'none',
            cursor: 'default',
            height: 50,
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              padding: 8,
            }}
          >
            <div
              style={{ width: '25%', display: 'flex', alignItems: 'center' }}
            >
              <p
                style={{
                  margin: 0,
                  color: '#272748',
                  verticalAlign: 'center',
                  fontSize: 15,
                  fontFamily: 'Akkurat-Light',
                  marginLeft: 30,
                }}
              >
                {this.props.t('symbol')}
              </p>
            </div>
            <div
              style={{ width: '35%', display: 'flex', alignItems: 'center' }}
            >
              <p
                style={{
                  margin: 0,
                  color: '#272748',
                  verticalAlign: 'center',
                  fontSize: 15,
                  fontFamily: 'Akkurat-Light',
                }}
              >
                {this.props.t('company')}
              </p>
            </div>
            <div
              style={{
                width: '35%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: '#272748',
                  verticalAlign: 'center',
                  fontSize: 15,
                  fontFamily: 'Akkurat-Light',
                  marginRight: 30,
                  textAlign: 'right',
                }}
              >
                {this.props.t('remove')}
              </p>
            </div>
            <div
              style={{
                width: '5%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: '#272748',
                  verticalAlign: 'center',
                  fontSize: 15,
                  fontFamily: 'Akkurat-Light',
                  marginRight: 30,
                  textAlign: 'right',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  this.props.showEdit()
                }}
              >
                <img src={EditIconActive} />
              </p>
            </div>
          </div>
        </div>
      )
    }
    if (this.props.columnTitle) {
      return (
        <div
          style={{
            width: '100%',
            backgroundColor: 'transparent',
            minWidth: 205,
            textDecoration: 'none',
            cursor: 'default',
            // height: 75,
            marginBottom: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              // minWidth: '1300px',
              // overflow: 'auto',
              width: '1200px',
            }}
          >
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'flex-end',
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: '#272748',
                  fontSize: 15,
                  fontFamily: 'Akkurat-Light',
                  marginLeft: 30,
                  textAlign: 'center',
                }}
              >
                {this.props.t('symbol')}
              </p>
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {this.props.size !== 'small' ? (
                <p
                  style={{
                    margin: 0,
                    color: '#272748',
                    fontSize: 15,
                    textAlign: 'right',
                    fontFamily: 'Akkurat-Light',
                  }}
                >
                  {this.props.t('currentprice')}
                </p>
              ) : null}
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: '#272748',
                  fontSize: 15,
                  fontFamily: 'Akkurat-Light',
                  textAlign: 'center',
                }}
              >
                {this.props.t('yearrange')}
              </p>
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: '#272748',
                  fontSize: 15,
                  fontFamily: 'Akkurat-Light',
                  textAlign: 'center',
                }}
              >
                {this.props.t('daysrange')}
              </p>
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: '#272748',
                  fontSize: 15,
                  fontFamily: 'Akkurat-Light',
                  textAlign: 'center',
                }}
              >
                {this.props.t('currenttrend')}
              </p>
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {this.props.size !== 'small' ? (
                <p
                  style={{
                    margin: 0,
                    color: '#272748',
                    fontSize: '15px',
                    fontFamily: 'Akkurat-Light',
                    textAlign: 'center',
                  }}
                >
                  {this.props.t('priceforecast')}
                </p>
              ) : null}
              <p
                style={{
                  margin: 0,
                  color: '#272748',
                  fontSize: '15px',
                  fontFamily: 'Akkurat-Light',
                  textAlign: 'center',
                }}
              >
                {`(1-2 ${this.props.t('months')}.)`}
              </p>
            </div>

            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: '#272748',
                  fontSize: 15,
                  fontFamily: 'Akkurat-Light',
                  textAlign: 'center',
                }}
              >
                {this.props.t('volume')}
              </p>
            </div>

            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: '#272748',
                  fontSize: 15,
                  fontFamily: 'Akkurat-Light',
                  textAlign: 'center',
                }}
              >
                {this.props.t('Beta')}
              </p>
            </div>
            <div
              style={{
                width: '5%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
              onClick={() => {
                this.props.showEdit()
              }}
            >
              {/* <img src={Neutral} width={50} height={50} /> */}
              <p
                style={{
                  margin: 0,
                  color: '#272748',
                  fontSize: '15px',
                  fontFamily: 'Akkurat-Light',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
              >
                <img src={EditIcon} />
              </p>
            </div>
          </div>
        </div>
      )
    }
    if (this.props.subheading) {
      return (
        <div
          style={{
            width: '100%',
            backgroundColor: 'transparent',
            minWidth: 205,
            textDecoration: 'none',
            cursor: 'default',
            borderTop: 'none',
            borderWidth: 0.25,
            borderColor: '#ddd',
          }}
        >
          {/* <div style={{ display: "flex", width: "100%", marginBottom: 10 }}>
            <div
              style={{
                width: "30%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: "#272748",
                  fontSize: 16,
                  marginLeft: 30,
                  textAlign: "center",
                }}
              >
                &nbsp;
              </p>
            </div>
            <div
              style={{
                width: "30%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-end",
              }}
            >
              <p style={{ margin: 0, color: "#272748", fontSize: 16 }}>
                &nbsp;
              </p>
            </div>
            <div
              style={{
                width: "15%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: "#272748",
                  fontSize: 16,
                  textAlign: "right",
                }}
              >
                &nbsp;
              </p>
            </div>
            <div
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: "#272748",
                  fontSize: 16,
                  textAlign: "center",
                }}
              >
                Short Term
              </p>
            </div>
            <div
              style={{
                width: "15%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: "#272748",
                  fontSize: 16,
                  textAlign: "center",
                }}
              >
                Medium Term
              </p>
            </div>
          </div> */}
        </div>
      )
    }
    if (this.props.editMode) {
      return (
        <div
          style={{
            width: '100%',
            cursor: 'pointer',
            backgroundColor: this.state.hover
              ? 'rgb(220,220,220)'
              : 'transparent',
            minWidth: 205,
            textDecoration: 'none',
          }}
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
          // onClick={() => {
          //   // this.props.remove(this.props.symbol)
          //   this.remove()
          // }}
        >
          <div
            style={{
              display: 'flex',
              borderBottom: 'solid',
              borderWidth: 0.25,
              borderColor: '#ddd',
              width: '100%',
              height: '100%',
              padding: 12,
            }}
          >
            <div
              style={{ width: '25%', display: 'flex', alignItems: 'center' }}
            >
              <p
                style={{
                  margin: 0,
                  color: '#272748',
                  verticalAlign: 'center',
                  fontFamily: 'Akkurat-Bold',
                  fontSize: 19,
                  marginLeft: 30,
                }}
              >
                {this.props.symbol}
              </p>
            </div>
            <div
              style={{ width: '35%', display: 'flex', alignItems: 'center' }}
            >
              <p
                style={{
                  margin: 0,
                  color: '#272748',
                  fontFamily: 'Akkurat-Bold',
                  verticalAlign: 'center',
                  fontSize: 19,
                }}
              >
                {this.props.companyName}
              </p>
            </div>
            {/* <div
              style={{
                width: '35%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <p
                style={{
                  margin: 0,
                  color: '#272748',
                  verticalAlign: 'center',
                  fontSize: 18,
                  marginRight: 30,
                  textAlign: 'right',
                }}
                onClick={() => {
                  // this.props.remove(this.props.symbol)
                  this.remove()
                }}
              >
                {/* {`${this.props.t('removestock')} ${this.props.symbol} ${this.props.t('fromwatchlist')}`} */}
            {/* <img
                  src={Neutral}
                  style={{
                    // marginTop: 5,
                    width: 50,
                    height: 50,
                  }}
                /> */}
            {/* </p>
            </div>  */}

            <div
              style={{
                width: '35%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
              className='remove-icon-container'
            >
              <i
                className='pi pi-minus-circle'
                style={{ color: 'red', paddingRight: '2rem' }}
                onClick={() => {
                  // this.props.remove(this.props.symbol)
                  this.remove()
                }}
              ></i>
            </div>
          </div>
        </div>
      )
    }

    return (
      <NavLink
        id={this.props.symbol}
        exact={true}
        to={`/Dashboard/Trade/${this.props.symbol}`}
        style={{
          width: '100%',
          // backgroundColor: this.state.hover
          //   ? 'rgb(220,220,220)'
          //   : 'transparent',
          backgroundColor: 'transparent',
          textDecoration: 'none',
        }}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        <div
          style={{
            display: 'flex',
            borderBottom: 'solid',
            borderWidth: 0.25,
            borderColor: '#ddd',
            width: '100%',
            height: '100%',
            padding: '10px 0',
            // overflow: 'auto',
            width: '1200px',
            backgroundColor: this.state.hover
              ? 'rgb(220,220,220)'
              : 'transparent',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'left',
              // width: '25%',
              flex: 1,
            }}
          >
            {this.createBox()}
          </div>
          {/* <div style={{ width: "15%", display: "flex", alignItems: "center" }}>
            <p
              style={{
                margin: 0,
                color: "#272748",
                verticalAlign: "center",
                fontSize: 19,
                fontFamily: 'Akkurat-Bold',
                marginLeft: 30,
              }}
            >
              {this.props.symbol}
            </p>
          </div> */}
          {/* <div
            style={{
              width: "30%",
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <p
              style={{
                margin: 0,
                color: "#272748",
                verticalAlign: "center",
                fontSize: 19,
                fontFamily: "Akkurat-Bold",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {this.props.companyName}
            </p>
          </div> */}
          <div
            style={{
              flex: 1,
              color: '#272748',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                margin: 0,
                verticalAlign: 'center',
                fontSize: 14,
                textAlign: 'center',
              }}
            >
              $
              {this.props.priceUSD
                ? this.addCommas(parseFloat(this.props.priceUSD).toFixed(2))
                : null}
            </p>
          </div>
          {/* HERE */}
          <div
            style={{
              flex: 1,
              color: '#272748',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                margin: 0,
                verticalAlign: 'center',
                fontSize: 14,
                textAlign: 'center',
              }}
            >
              {this.props.yearHigh && this.props.yearLow
                ? `$${this.addCommas(
                    parseFloat(this.props.yearLow).toFixed(2)
                  )} - $${this.addCommas(
                    parseFloat(this.props.yearHigh).toFixed(2)
                  )}`
                : '-'}
            </p>
          </div>
          <div
            style={{
              flex: 1,
              color: '#272748',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                margin: 0,
                verticalAlign: 'center',
                fontSize: 14,
                textAlign: 'center',
              }}
            >
              {this.props.daysLow && this.props.daysHigh
                ? `$${this.addCommas(
                    parseFloat(this.props.daysLow).toFixed(2)
                  )} - $${this.addCommas(
                    parseFloat(this.props.daysHigh).toFixed(2)
                  )}`
                : '-'}
            </p>
          </div>
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* {this.props.isCrypto? 
                            <p style={{margin:0, color:'rgb(0,0,0)'}}>N/A</p>:
                        <img
                            src={this.getArrow(this.props.direction)}
                            style={{
                            width: 50,
                            height: 50,
                            }}
                        />
                        } */}
            <img
              src={this.getArrow(this.props.direction)}
              style={{
                // marginBottom: 5,
                width: 40,
                height: 40,
              }}
            />
          </div>
          {/* <div
            style={{
              width: "10%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={this.getArrow(this.props.directionPrev)}
              style={{
                width: 50,
                height: 50,
              }}
            />
          </div> */}
          <div
            style={{
              flex: 1,
              color: '#272748',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                margin: 0,
                verticalAlign: 'center',
                fontSize: 14,
                textAlign: 'right',
              }}
            >
              {/* ${(this.props.rf*this.props.priceUSD+this.props.priceUSD).toFixed(0)} */}
              {this.props.needOptimization
                ? `$${
                    this.props.rf
                      ? this.addCommas(this.props.rf.toFixed(2))
                      : null
                  }`
                : 'HIGH RISK'}
            </p>
          </div>

          <div
            style={{
              flex: 1,
              color: '#272748',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                margin: 0,
                verticalAlign: 'center',
                fontSize: 14,
                textAlign: 'right',
              }}
            >
              {this.props.volume}
            </p>
          </div>

          <div
            style={{
              flex: 1,
              color: '#272748',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                margin: 0,
                verticalAlign: 'center',
                fontSize: 14,
                textAlign: 'right',
              }}
            >
              {Math.round(this.props.beta * 100) / 100 !== 0
                ? Math.round(this.props.beta * 100) / 100
                : '0.00'}
            </p>
          </div>

          <div
            style={{
              width: '5%',
              color: '#272748',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* <p
              style={{
                margin: 0,
                verticalAlign: 'center',
                fontSize: 16,
                textAlign: 'right',
                marginRight: 30,
              }}
            >
              Delete
            </p> */}
          </div>
        </div>
      </NavLink>
    )
  }
}
