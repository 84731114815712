import React from "react";
import GreenUpArrow from "../../assets/green_up_arrow_icon.svg";
import RedDownArrow from "../../assets/red_down_arrow_icon.svg";
import Flat_Arrow from "../../assets/flat_arrow.svg";

export default class ChangeSinceYest extends React.Component {
  positive = () => {
    return (
      <div
        style={{
          display: "flex",
          borderRadius: 6,
          //   minHeight: 15,
          minWidth: 150,
          width: this.props.new ? null : "100%",
          paddingLeft: 8,
        }}
      >
        <div style={{ marginTop: 3 }}>
          <img
            style={{ height: 20 }}
            alt={"green arrow up"}
            src={GreenUpArrow}
          />
        </div>
        <div style={{ marginTop: "0%" }}>
          <p
            style={{
              fontSize: 20,
              margin: "auto",

              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 5,
              paddingBottom: 5,
              fontWeight: "bolder",
              // color: "#242A38",
              color: "#1a9561",
              textAlign: "left",
            }}
          >
            +{this.props.dollarChange.toFixed(2)}{" "}
            {` (+${this.props.changePercent.toFixed(2)}%)`}
          </p>
        </div>
      </div>
    );
  };
  negative = () => {
    return (
      <div
        style={{
          display: "flex",
          borderRadius: 6,
          //   minHeight: 15,
          minWidth: 150,
          width: this.props.new ? null : "90%",
          padding: 10,
          alignContent: "center",
        }}
      >
        <div>
          <img
            style={{ height: 20, marginTop: 10 }}
            alt={"red arrow down"}
            src={RedDownArrow}
          />
        </div>
        <div style={{ marginTop: "0%" }}>
          <p
            style={{
              fontSize: 20,
              margin: "auto",

              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 5,
              paddingBottom: 5,
              fontWeight: "bolder",
              // color: "#242A38",
              color: "#B71313",
              textAlign: "left",
            }}
          >
            -{Math.abs(this.props.dollarChange).toFixed(2)}
            {` (${this.props.changePercent.toFixed(2)}%)`}
          </p>
        </div>
      </div>
    );
  };

  zero = () => {
    return (
      <div
        style={{
          display: "flex",
          borderRadius: 6,
          //   minHeight: 15,
          minWidth: 150,
          width: this.props.new ? null : "100%",
          padding: 10,
          alignContent: "center",
        }}
      >
        <div>
          <img
            style={{ height: 20, marginTop: 11 }}
            alt={"black flat arrow down"}
            src={Flat_Arrow}
          />
        </div>
        <div style={{ marginTop: "0%" }}>
          <p
            style={{
              fontSize: 20,
              margin: "auto",

              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 5,
              paddingBottom: 5,
              fontWeight: "bolder",
              // color: "rgb(17,17,17)",
              textAlign: "left",
            }}
          >
            {this.props.changePercent > 0 ? "+" : "-"}
            {Math.abs(this.props.dollarChange).toFixed(2)}
            {" (0.00%)"}
          </p>
        </div>
      </div>
    );
  };

  render() {
    if (this.props.changePercent > 0) {
    } else if (this.props.changePercent === 0) {
    } else {
    }
    return (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          margin: "auto",
          width: "100%",
        }}
      >
        {parseFloat(this.props.changePercent).toFixed(2) == 0.0
          ? this.zero()
          : null}
        {parseFloat(this.props.changePercent).toFixed(2) > 0
          ? this.positive()
          : null}
        {parseFloat(this.props.changePercent).toFixed(2) < 0
          ? this.negative()
          : null}
      </div>
    );
  }
}
