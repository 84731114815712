import React, { Component } from 'react'
import { TabView, TabPanel } from 'primereact/tabview'
import ENDPOINT from '../Endpoint'
import '../../scss/components/Statements.scss'
export default class Statements extends Component {
  state = {
    activeTab: 0,
  }

  setActiveIndex = (index) => {
    this.setState({
      activeTab: index,
    })
  }

  getLink = (fileId) => {
    let url = `${ENDPOINT}/mobile/dw/documents/link/${this.props.selectedAccount.accountId}/${fileId}`
    fetch(url, {
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        // this.setState({presetTickers: responseJson.lists})
        window.open(responseJson.payload.url, '_blank')
      })
  }

  displayTradeConfirmation() {
    if (this.props.statements?.confirmations) {
      let tradeConfirmations = [...this.props.statements?.confirmations]
      tradeConfirmations.reverse()
      return (
        <div className='trade-confirmation-container'>
          {tradeConfirmations.map((ele) => {
            return (
              <div className='row'>
                <a
                  className='statement-link'
                  href='#'
                  onClick={() => this.getLink(ele.fileKey)}
                >
                  {ele.displayName}
                </a>
              </div>
            )
          })}
        </div>
      )
    } else return <p>{this.props.t('notradeconfirmations')}</p>
  }

  displayAccountStatement() {
    if (this.props.statements?.statements) {
      let statements = [...this.props.statements?.statements]
      statements.reverse()
      return (
        <div className='account-statements-container'>
          {statements.map((ele) => {
            return (
              <div className='row'>
                <a
                  className='statement-link'
                  href='#'
                  onClick={() => this.getLink(ele.fileKey)}
                >
                  {ele.displayName}
                </a>
              </div>
            )
          })}
        </div>
      )
    } else return <p>{this.props.t('noaccountstatements')}</p>
  }

  displayTaxDocuments() {
    if (this.props.statements?.tax_forms) {
      let taxDocuments = [...this.props.statements?.tax_forms]
      taxDocuments.reverse()
      return (
        <div className='tax-documents-container'>
          {taxDocuments.map((ele) => {
            return (
              <div className='row'>
                <a
                  className='statement-link'
                  href='#'
                  onClick={() => this.getLink(ele.fileKey)}
                >
                  {ele.displayName}
                </a>
              </div>
            )
          })}
        </div>
      )
    } else return <p>{this.props.t('notaxdocuments')}</p>
  }
  render() {
    if (this.props.t) {
      return (
        <div className='statements-container'>
          <TabView
            activeIndex={this.state.activeTab}
            onTabChange={(e) => this.setActiveIndex(e.index)}
          >
            <TabPanel header={this.props.t('tradeconfirmation')}>
              {this.displayTradeConfirmation()}
            </TabPanel>
            <TabPanel header={this.props.t('accountstatements')}>
              {this.displayAccountStatement()}
            </TabPanel>
            <TabPanel header={this.props.t('taxdocuments')}>
              {this.displayTaxDocuments()}
            </TabPanel>
          </TabView>
        </div>
      )
    } else return null
  }
}
