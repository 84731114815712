import React, { Component } from 'react'
import { TailSpin } from 'react-loader-spinner'

//BETTER POP UP LIBRARY
import { ConfirmDialog } from 'primereact/confirmdialog'
import 'primereact/resources/themes/md-light-deeppurple/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import '../../../../scss/pages/account-page/non-product-account-info/NonProdPendingOrders.scss'
import Loader from '../Loader'

export default class NonProdPendingOrders extends Component {
  state = {
    showAllPendingOrders: false,
    showCancelOrderModal: false,
    selectedCancelOrderId: null,
  }

  toggleCancelOrderModal(id, name) {
    this.setState({
      showCancelOrderModal: !this.state.showCancelOrderModal,
      selectedCancelOrderId: id,
      selectedCancelOrderMessage: name,
    })
  }

  closeAndResetDeleteModal() {
    // if(this.props.cancelOrderStatus && this.props.cancelOrderStatus === 'success') this.props.resetCancelOrderModal();
    this.props.resetCancelOrderModal()
    this.toggleCancelOrderModal()
  }

  showMessage() {
    if (this.props.cancelOrderStatus === 'success') {
      return (
        <div>
          <p style={{ textAlign: 'center' }}>
            {this.state.selectedRecurBuy}
            {this.props.t('removedsuccessfully')}
          </p>
        </div>
      )
    } else if (this.props.cancelOrderStatus === 'error') {
      return (
        <div>
          <p style={{ textAlign: 'center', color: 'red' }}>
            {this.props.t('somethingwentwrong')}
          </p>
        </div>
      )
    } else {
      return (
        <div style={{ width: '10%', margin: 'auto' }}>
          <Loader logoLoader={false} />
        </div>
      )
    }
  }

  cancelOrderModal() {
    return (
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: 'auto',
          backgroundColor: 'rgba(0,0,0, 0.5)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: '25%',
            right: '25%',
            top: '25%',
            bottom: '25%',
            margin: 'auto',
            borderRadius: '20px',
            background: 'white',
            padding: '15px',
          }}
        >
          <div
            style={{
              width: '10%',
              justifyContent: 'right',
              display: 'flex',
              margin: 'auto',
              marginRight: '0',
              textAlign: 'right',
              background: 'transparent',
              border: 'none',
              padding: '1px',
            }}
          >
            <p
              onClick={() => this.closeAndResetDeleteModal(null)}
              style={{ fontSize: '25px', cursor: 'pointer' }}
            >
              X
            </p>
          </div>
          <p style={{ textAlign: 'center', fontSize: '25px' }}>
            {this.props.t('areyousureyouwanttocancelthisorder')}
          </p>
          {this.props.cancelOrderStatus ? (
            this.showMessage()
          ) : (
            <div
              style={{
                margin: 'auto',
                justifyContent: 'space-evenly',
                display: 'flex',
              }}
            >
              <button
                style={{
                  background: '#3F46F6',
                  borderRadius: '25px',
                  padding: '10px 15px',
                  color: 'white',
                  fontSize: '15px',
                }}
                onClick={() =>
                  this.props.cancelOrder(this.state.selectedCancelOrderId)
                }
              >
                YES
              </button>
              <button
                style={{
                  background: '#3F46F6',
                  borderRadius: '25px',
                  padding: '10px 15px',
                  color: 'white',
                  fontSize: '15px',
                }}
                onClick={() => this.toggleCancelOrderModal(null)}
              >
                NO
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }

  displayPendingOrders() {
    const pendingOrdersList =
      this.props.accountTransactionsData.accountOrderHistory.pendingOrders
    console.log(pendingOrdersList)
    if (pendingOrdersList.length > 0) {
      return (
        <div className='non-prod-pending-orders-container'>
          <p className='non-prod-pending-orders-header'>
            {this.props.t('pendingorders')}
          </p>
          <div className='non-prod-pending-orders-list'>
            <div
              style={{ width: '100%', display: 'flex', marginBottom: '15px' }}
            ></div>
            {/* {pendingOrdersList.length > 5 ? (
              <div>
                {this.state.showAllPendingOrders ? (
                  <div>
                    {pendingOrdersList.map((order) => {
                      return this.displayPendingOrdersRow(order)
                    })}
                  </div>
                ) : (
                  <div>
                    {pendingOrdersList.map((order, index) => {
                      if (index < 5) return this.displayPendingOrdersRow(order)
                    })}
                  </div>
                )}
              </div>
            ) : (
              pendingOrdersList.map((order) => {
                return this.displayPendingOrdersRow(order)
              })
            )} */}
            <div style={{ maxHeight: '30vh', overflow: 'auto' }}>
              {pendingOrdersList.map((order) => {
                return this.displayPendingOrdersRow(order)
              })}
            </div>
          </div>
          {/* {pendingOrdersList.length > 5 ? (
            <div>
              {this.state.showAllPendingOrders ? (
                <button
                  style={{
                    width: '100%',
                    borderRadius: '0 0 25px 25px',
                    background: 'black',
                    color: 'grey',
                    padding: '8px',
                  }}
                  onClick={() => {
                    this.setState({ showAllPendingOrders: false })
                  }}
                >
                  Show Less
                </button>
              ) : (
                <button
                  style={{
                    width: '100%',
                    borderRadius: '0 0 25px 25px',
                    background: 'black',
                    color: 'grey',
                    padding: '8px',
                  }}
                  onClick={() => {
                    this.setState({ showAllPendingOrders: true })
                  }}
                >
                  Show More
                </button>
              )}
            </div>
          ) : null} */}
        </div>
      )
    } else return null
  }

  displayPendingOrdersRow(order) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '15px 0',
          paddingRight: '0.5rem',
        }}
      >
        <div
          style={{
            alignContent: 'center',
            width: '50%',
            wordWrap: 'break-word',
          }}
        >
          <p
            style={{
              color: order.type === 'LIMIT' ? '#3F46F6' : '#3D50062',
              fontSize: '15px',
              marginBottom: '0',
            }}
          >
            {order.type}
          </p>
          <p
            style={{
              marginBottom: '0',
              marginRight: '0',
              fontSize: '12px',
              // color: 'gray',
            }}
          >
            {`${order.type} ${order.side} ${order.qty?.toFixed(1)} shares of ${
              order.ticker
            } at $${order.price}`}
          </p>
          <p
            style={{
              marginBottom: '0',
              color: 'rgba(128,128,128,.5)',
              fontSize: '10px',
            }}
          >
            {order.createdOn}
          </p>
        </div>
        <div
          style={{
            width: '15%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <button
            style={{
              border: '1px solid red',
              borderRadius: '25px',
              color: 'white',
              width: '25px',
              background: 'transparent',
            }}
            onClick={() =>
              this.toggleCancelOrderModal(
                order.orderID,
                `${order.type} ${order.side} ${order.qty?.toFixed(
                  1
                )} shares of ${order.ticker} at $${order.price}`
              )
            }
          >
            <span
              style={{ color: 'red', fontWeight: 'bold', fontSize: '15px' }}
            >
              -
            </span>
          </button>
        </div>
        {/* <div style={{ width: '25%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center' }}>
          <button
            style={{
              border: "1px solid red",
              borderRadius: "50%",
              color: "white",
              width: "25px",
              height: '25px',
              background: 'transparent'
            }}
            onClick={()=>this.toggleCancelOrderModal(order.orderID)}
          >
           c <span
              style={{ color: "red", fontWeight: "bold", fontSize: "15px" }}
            >
              -
            </span>
          </button>
        </div> */}
        <div style={{ alignContent: 'center', width: '25%' }}>
          <p
            style={{ fontSize: '20px', marginBottom: '0', textAlign: 'right' }}
          >
            {/* {this.props.formatCurrency(order.avg_price)} */}
            {/* for market value */}
            {this.props.formatCurrency(order.price * order.qty)}
          </p>
          <p
            style={{
              marginBottom: '0',
              color: 'rgba(128,128,128,.5)',
              fontSize: '10px',
              textAlign: 'right',
            }}
          >
            {this.props.t('marketvalue')}
          </p>
        </div>
      </div>
    )
  }

  accept = () => {
    this.props.cancelOrder(this.state.selectedCancelOrderId)
    this.props.getAllAccountInfoV2()
  }

  render() {
    if (this.props.t) {
      return (
        <div style={{ width: this.props.isSmallScreen ? '100%' : '100%' }}>
          {this.displayPendingOrders()}
          <ConfirmDialog
            visible={this.state.showCancelOrderModal}
            onHide={() => this.closeAndResetDeleteModal(false)}
            message={
              <p style={{ textAlign: 'center', fontSize: '1.2rem' }}>
                {this.state.selectedCancelOrderMessage}
              </p>
            }
            header={
              <p style={{ textAlign: 'center' }}>
                {this.props.t('areyousureyouwanttocancelthisorder')}?
              </p>
            }
            accept={this.accept}
            style={{ width: this.props.isSmallScreen ? '90vw' : '50vw' }}
          />
        </div>
      )
    }
  }
}
