import React, { Component } from 'react'
import Footer from '../PublicFooter'
import Header from '../PublicHeader3'
import emailjs from 'emailjs-com'
import RoboIcon from '../../assets/newAssets/robo-dude.png'
import MainNavbar from '../components/MainNavBar'

export default class Careers2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
      blurb: '',
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      roleError: false,
      blurbError: false,
      errors: [12, 3, 4, 5],
      isSmallScreen: false,
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener('resize', this.updateWindowDimensions)
    this.updateWindowDimensions()
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }
  createEmail = () => {
    return `${this.state.firstName} ${this.state.lastName} is applying for ${this.state.role}. Here is what they say about themselves: ${this.state.blurb}. You can contact them at ${this.state.email}`
  }

  checkName = () => {
    let errors = [...this.state.errors]
    let firstNameError = false
    let lastNameError = false
    if (this.state.firstName.length === 0) {
      // errors.push('firstname')
      firstNameError = true
    }
    if (this.state.lastName.length === 0) {
      // errors.push('lastname')
      lastNameError = true
    }
    this.setState({ firstNameError, lastNameError })
    if (this.state.firstName.length > 0 && this.state.lastName.length > 0) {
      return true
    }
    return false
  }
  checkEmail = () => {
    let errors = [...this.state.errors]
    if (
      this.state.email.length > 0 &&
      this.state.email.includes('@') &&
      this.state.email.includes('.')
    ) {
      return true
    }
    // errors.push('email')
    this.setState({ emailError: true })
    return false
  }
  checkRole = () => {
    let errors = [...this.state.errors]
    if (
      this.state.role.length > 0 ||
      this.state.role === 'Desired Role - Select One'
    ) {
      return true
    }
    // errors.push('role')
    this.setState({ roleError: true })
    return false
  }
  checkBody = () => {
    let errors = [...this.state.errors]
    if (this.state.blurb.length > 0) {
      return true
    }
    errors.push('blurb')
    this.setState({ blurbError: true })
    return false
  }

  reachOut = () => {
    return `${this.state.firstName} ${this.state.lastName} who can be reached at ${this.state.email} says:`
  }

  verifyForm = () => {
    this.setState({
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      roleError: false,
      blurbError: false,
    })
    let name = this.checkName()
    let email = this.checkEmail()
    let role = this.checkRole()
    let body = this.checkBody()
    if (name && email && role && body) {
      this.sendEmail()
    }
  }

  resetErrors = () => {
    let errors = []
    this.setState({ errors })
  }

  sendEmail = () => {
    let templateParams = {
      from_name: this.state.name,
      to_name: '<YOUR_EMAIL_ID>',
      subject: 'subject',
      contact: this.reachOut(),
      message: this.createEmail(),
    }
    console.log('email should say: ', this.createEmail())
    // emailjs.sendForm('service_m8qm1fe', 'template_jw39141', this.createEmail(), 'user_fsr7vl0Mp1zwzsbgJAwzD')
    emailjs
      .send(
        'service_m8qm1fe',
        'template_8gofbso',
        templateParams,
        'user_fsr7vl0Mp1zwzsbgJAwzD'
      )
      .then(
        (result) => {
          console.log('success result', result.text)
          this.setState({
            email: '',
            firstName: '',
            lastName: '',
            role: '',
            blurb: 'Your message has been sent!',
          })
        },
        (error) => {
          console.log('error result', error.text)
          this.setState({
            placeholder: 'Something went wrong',
          })
        }
      )
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        {/* <Header isSmallScreen={this.state.isSmallScreen} user={this.props.user} signOut={this.props.signOut}/> */}
        <MainNavbar {...this.props} colorChange={true} />
        <div
          style={{
            marginTop: 75,
            flexDirection: this.state.isSmallScreen ? 'column' : 'row',
            display: 'flex',
            backgroundColor: 'rgb(230,230,230)',
            flex: 1,
            alignItems: this.state.isSmallScreen ? 'center' : 'flex-end',
          }}
        >
          <div
            style={{
              flex: 3,
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '4.5%',
              paddingBottom: '4.5%',
              paddingLeft: this.state.isSmallScreen ? '10%' : '3.5%',
              paddingRight: this.state.isSmallScreen ? '10%' : null,
            }}
          >
            <p
              style={{
                fontSize: '35px',
                fontWeight: 'bolder',
                color: 'rgb(250,250,250)',
                textShadow: '1px 1.7px 2.8px rgba(20, 20, 20, 0.13)',
              }}
            >
              Careers
            </p>
            <p style={{ fontSize: 30, fontWeight: 'bold', color: '#3f4174' }}>
              We are growing
            </p>
            {this.state.isSmallScreen ? (
              <p style={{ fontSize: '24px' }}>
                We are growing and always hiring smart, self-motivated people.
                Feel free to fill out the form below and tell us why we should
                hire you.
              </p>
            ) : (
              <p style={{ fontSize: '24px' }}>
                We are growing and always hiring smart, self-motivated people.
                <br />
                Feel free to fill out the form below and tell us why we should
                hire you.
              </p>
            )}
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <img src={RoboIcon} />
          </div>
        </div>
        <div
          style={{
            flexDirection: this.state.isSmallScreen ? 'column-reverse' : 'row',
            display: 'flex',
            flex: 4,
            alignItems: this.state.isSmallScreen ? 'center' : 'flex-start',
            justifyContent: this.state.isSmallScreen ? 'center' : null,
          }}
        >
          <div
            style={{
              flex: 2,
              height: '80vh',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              paddingTop: '4%',
              paddingBottom: '6%',
              paddingLeft: '5%',
              paddingRight: this.state.isSmallScreen ? '5%' : '2.5%',
            }}
          >
            <div
              style={{
                height: '40px',
                width: '100%',
                marginBottom: this.state.isSmallScreen ? '2%' : null,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <input
                placeholder={'First Name'}
                value={this.state.firstName}
                onChange={(event) =>
                  this.setState({ firstName: event.target.value })
                }
                style={{
                  paddingLeft: '1%',
                  paddingRight: '1%',
                  height: '40px',
                  width: '49%',
                  borderRadius: '6px',
                  border: 'solid 1px',
                  borderColor: this.state.firstNameError
                    ? 'rgb(255,0,0)'
                    : '#636464',
                }}
              />
              <input
                placeholder={'Last Name'}
                value={this.state.lastName}
                onChange={(event) =>
                  this.setState({ lastName: event.target.value })
                }
                style={{
                  paddingLeft: '1%',
                  paddingRight: '1%',
                  height: '40px',
                  width: '49%',
                  borderRadius: '6px',
                  border: 'solid 1px',
                  borderColor: this.state.lastNameError
                    ? 'rgb(255,0,0)'
                    : '#636464',
                }}
              />
            </div>
            <div>
              <input
                placeholder='Email'
                value={this.state.email}
                onChange={(event) =>
                  this.setState({ email: event.target.value })
                }
                style={{
                  marginBottom: this.state.isSmallScreen ? '2%' : null,
                  paddingLeft: '1%',
                  paddingRight: '1%',
                  height: '40px',
                  width: '100%',
                  borderRadius: '6px',
                  border: 'solid 1px',
                  borderColor: this.state.emailError
                    ? 'rgb(255,0,0)'
                    : '#636464',
                }}
              />
            </div>
            <div>
              <select
                value={this.state.role}
                onChange={(event) =>
                  this.setState({ role: event.target.value })
                }
                style={{
                  marginBottom: this.state.isSmallScreen ? '2%' : null,
                  paddingLeft: '1%',
                  paddingRight: '1%',
                  height: '40px',
                  width: '100%',
                  borderRadius: '6px',
                  borderColor: this.state.roleError
                    ? 'rgb(255,0,0)'
                    : '#636464',
                }}
              >
                <option default>Desired Role - Select One</option>
                <option>Software Engineer</option>
                <option>Data Scientist</option>
                <option>Sales Representative</option>
                <option>Project Manager</option>
                <option>QA Engineer</option>
                <option>Other - tell us below!</option>
              </select>
            </div>
            <div>
              <textarea
                placeholder='Tell us what makes you awesome'
                value={this.state.blurb}
                onChange={(event) =>
                  this.setState({ blurb: event.target.value })
                }
                style={{
                  paddingLeft: '1%',
                  paddingRight: '1%',
                  height: '140px',
                  width: '100%',
                  borderRadius: '6px',
                  border: 'solid 1px',
                  borderColor: this.state.blurbError
                    ? 'rgb(255,0,0)'
                    : '#636464',
                }}
              />
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                marginTop: this.state.isSmallScreen ? '5%' : null,
                justifyContent: this.state.isSmallScreen
                  ? 'center'
                  : 'flex-end',
              }}
            >
              <button
                onClick={() => this.verifyForm()}
                style={{
                  minHeight: '40px',
                  width: '25%',
                  backgroundColor: '#3F46F6',
                  borderRadius: '100px',
                  border: 'none',
                  outline: 'none',
                  color: 'white',
                  boxShadow: 'none',
                  fontWeight: 'bold',
                  minWidth: this.state.isSmallScreen ? '150px' : null,
                }}
              >
                Submit
              </button>
            </div>
          </div>
          <div
            style={{
              flex: 1,
              flexDirection: 'column',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              paddingRight: '5%',
              paddingLeft: '2.5%',
              paddingTop: '4%',
            }}
          >
            <p
              style={{
                fontSize: '18px',
                color: '#3F4174',
                fontWeight: 'bold',
              }}
            >
              {' '}
              We are always looking for
              <br />
              people in the following fields:
            </p>
            <ul>
              <li>Software Engineer</li>
              <li>Data Scientist</li>
              <li>Sales Representative</li>
              <li>Project Manager</li>
              <li>QA Engineer</li>
            </ul>
          </div>
        </div>
        <Footer t={this.props.t} isSmallScreen={this.state.isSmallScreen} />
      </div>
    )
  }
}
