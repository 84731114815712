//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//REACT LIBRARIES
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { Calendar } from 'primereact/calendar'
import moment from 'moment'

//CONTEXT
import { BudgetingContext } from './Budgeting'

//ICONS
import BackIcon from '../../assets/product-activation-back-icon.svg'
import CreditCardIcon from '../../assets/credit-card-icon.png'

//APIS
import { addCreditScore, getBudgetingData } from '../../services/userServices'

//STYLES
import '../../scss/components/budgeting/CreditScoreForm.scss'

//UTILS
import formatCurrency from '../../utility-functions/formatCurrency'
import { getTrend } from '../../utility-functions/getTrend'

//COMPONENTS
import Loader from '../components/accountInfo/Loader'

export default function CreditScoreForm() {
  const {
    t,
    budgetingData,
    setPage,
    isSmallScreen,
    user,
    refreshBudgetingInfo,
  } = useContext(BudgetingContext)
  const details = budgetingData?.payload?.budgeting
  const creditDetails = details?.credit_score_data

  const [education, setEducation] = useState(null)
  const [creditLineDate, setCreditLineDate] = useState(null)
  const [startYear, setStartYear] = useState(null)
  const [employmentYear, setEmploymentYear] = useState(null)
  const [option, setOption] = useState(null)

  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [loading, setLoading] = useState(false)

  const getYearOptions = () => {
    let options = []
    for (let i = new Date().getFullYear(); i >= 1970; i--) {
      options.push({ label: i, value: i })
    }
    return options
  }

  const validateForm = () => {
    if (education) {
      if (creditLineDate) {
        if (startYear) {
          if (employmentYear) {
            if (option) {
              submitApi()
            } else {
              setError(t('select_option'))
            }
          } else {
            setError(t('select_employment_year'))
          }
        } else {
          setError(t('select_start_year'))
        }
      } else {
        setError(t('select_credit_line_date'))
      }
    } else {
      setError(t('select_education'))
    }
  }

  const submitApi = async () => {
    setLoading(true)
    const res = await addCreditScore(
      user?.idToken?.jwtToken,
      education,
      moment(creditLineDate).format('MM/YYYY'),
      startYear.toString(),
      employmentYear.toString(),
      option
    )
    if (res) {
      const budgetingData = await getBudgetingData(user?.idToken?.jwtToken)
      refreshBudgetingInfo(budgetingData)
      setSuccess(t('credit_sore_added_successfully'))
    } else {
      setError(t('somethingwentwrong'))
    }
    setLoading(false)
  }

  if (success) {
    return <p className='success'>{success}</p>
  } else {
    if (loading) {
      return <Loader logoLoader={false} />
    } else {
      return (
        <div className='credit-score-form'>
          <div className='credit-score-form-block'>
            <p className='credit-score-form-header'>
              {t('select_the_highest_education_degree_you_have')}
            </p>
            <Dropdown
              value={education}
              onChange={(e) => {
                setEducation(e.target.value)
              }}
              placeholder={t('select_education')}
              options={[
                { label: t('high_school'), value: 'High school' },
                { label: t('bachelor'), value: 'Bachelor' },
                { label: t('master'), value: 'Master' },
                { label: t('phd'), value: 'PhD' },
                { label: t('others'), value: 'Others' },
              ]}
              style={{ width: '100%' }}
            />
          </div>
          <div className='credit-score-form-block'>
            <p className='credit-score-form-header'>
              {t('when_was_your_first_line_of_credit_opened')}
            </p>
            <Calendar
              value={creditLineDate}
              onChange={(e) => setCreditLineDate(e.target.value)}
              dateFormat='mm/yy'
              name='date'
              showIcon
              icon='pi pi-calendar'
              monthNavigator={true}
              yearNavigator={true}
              placeholder='date'
              yearRange={`1900:${new Date().getFullYear()}`}
              style={{ width: '100%' }}
            ></Calendar>
          </div>
          <div className='credit-score-form-block'>
            <p className='credit-score-form-header'>
              {t('when_did_you_start_your_current_job')}
            </p>
            <Dropdown
              value={startYear}
              onChange={(e) => {
                setStartYear(e.target.value)
              }}
              placeholder={t('start_year')}
              options={getYearOptions()}
              style={{ width: '100%' }}
            />
          </div>
          <div className='credit-score-form-block'>
            <p className='credit-score-form-header'>
              {t('what_is_the_first_year_of_your_first_employment')}
            </p>
            <Dropdown
              value={employmentYear}
              onChange={(e) => {
                setEmploymentYear(e.target.value)
              }}
              placeholder={t('employment_year')}
              options={getYearOptions()}
              style={{ width: '100%' }}
            />
          </div>
          <div className='credit-score-form-block'>
            <p className='credit-score-form-header'>
              {t('select_the_option_that_applies_to_you')}
            </p>
            <Dropdown
              value={option}
              onChange={(e) => {
                setOption(e.target.value)
              }}
              placeholder={t('option')}
              options={[
                { label: t('renting_a_home'), value: 'RENT' },
                { label: t('own_a_mortgaged'), value: 'MORTGAGE' },
                { label: t('own_a_home'), value: 'OWN' },
                { label: t('others'), value: 'OTHERS' },
              ]}
              style={{ width: '100%' }}
            />
          </div>
          {error && <p className='error'>{error}</p>}
          <button
            className='credit-score-form-btn'
            onClick={() => {
              validateForm()
            }}
          >
            {t('next')}
          </button>
        </div>
      )
    }
  }
}
