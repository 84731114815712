import React from 'react'
import CreateDwAcc from '../dashboard/CreateDwAcc'
import PublicHeader from '../PublicHeader'
import Footer from '../Footer';

export default class DwCreate extends React.Component{

    toInvested = ()=>{
        window.location.replace("/Dashboard/Invest")
    }

    render(){
        return(
            <div>
                <PublicHeader user={this.props.user}/>
                <div style={{width: '100%',backgroundColor: 'rgb(247, 246, 253)',paddingLeft: '1.5%',paddingRight: '1.5%',paddingTop: '1.5%', marginTop:75}}>
                    <div style={{marginTop:10, marginBottom:10}}>
                        <h3 style={{textAlign:'center'}}>Open a Drivewealth Brokerage Account </h3>
                    </div>
                    <div style={{paddingBottom:50}}>
                        <CreateDwAcc 
                            product={'normal'} 
                            accountPlusProduct={this.toInvested} 
                            user={this.props.user}
                            external={true}
                        />
                    </div>
                </div>
                <Footer isSmallScreen={this.props.isSmallScreen}/>
            </div>
        )
    }
}