import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Amplify, { Auth } from 'aws-amplify'

import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { setTimeOut } from '../../store/slice/UserSlice'

import MainNavBar from '../components/MainNavBar'
import { validateUsername } from '../../utility-functions/validateUsername'
import { validatePassword } from '../../utility-functions/validatePassword'
import PasswordInput from '../PasswordInput'
import '../../scss/pages/SignIn.scss'
import CodeVerification from './CodeVerification'
import { NavLink } from 'react-router-dom'
import ChangePasswordModal from '../components/ChangePasswordModal'
import { sendEmail } from '../../services/sendEmail'
import Endpoint from '../Endpoint'
import { setErrorMessage } from '../../store/slice/GeneralSlice'
import AsaLoginButton from '../AsaLoginButton'
import asa_image from '../../assets/asa-logo.png'

export default function SignIn(props) {
  const { t } = props
  const { general, user } = useSelector((state) => state)
  const { isSmallScreen } = general
  const { timeOut } = user

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMsg, setErrorMsg] = useState(
    general.errorMessage ? general.errorMessage : ''
  )
  const [successMsg, setSuccessMsg] = useState('')
  const [page, setPage] = useState('signin') // signin || codeverify || changePassword
  const [
    showChangeTemporaryPasswordModal,
    setShowChangeTemporaryPasswordModal,
  ] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(setTimeOut(false))
      dispatch(setErrorMessage(''))
    }
  }, [])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: !isSmallScreen ? '500px' : '90%',
      overflow: 'auto',
      borderRadius: '5px',
      border: 'none',
      boxShadow: '0px 4px 23px rgba(0, 0, 0, 0.08)',
    },
  }

  const validateForm = () => {
    if (validateUsername(email)) {
      if (password) {
        login()
      } else {
        setErrorMsg(t('pleaseenterpassword'))
      }
    } else {
      setErrorMsg(t('pleaseentervalidemail'))
    }
  }

  const resetMessages = () => {
    setErrorMsg('')
    setSuccessMsg('')
  }

  const login = async () => {
    setLoading(true)
    let loginVar = Auth.signIn(email.toLowerCase(), password)
      .catch((error) => {
        console.log(error.message)
        switch (error.message) {
          case 'User does not exist.':
            error = t('usernamenotfound')
            break
          case 'Incorrect username or password.':
            error = t('incorrectpassword')
            break
          case 'User is not confirmed.':
            setPage('codeverify')
            break
          case 'Temporary password has expired and must be reset by an administrator.':
            error =
              'Temporary password has expired and must be reset by an administrator.'
            sendEmail(
              `TEMPORARY PASSWORD EXPIRED ALERT FOR ${email}`,
              `User ${email}'s temporary password has been expired`,
              [
                'daniel.kim@forescite.ai',
                'vijayakumar.sivasubramaniyam@forescite.ai ',
                'neten.dharan@forescite.ai',
                'alfred.rajan@allocaterite.com',
              ]
            )
            break
          default:
            // code block
            console.log('Not Caught Error: ' + error.message)
            error = error.message
            break
        }
        setLoading(false)
        setErrorMsg(error)
      })
      .then((res) => {
        // CHECK FOR SESSSION AND CHALLENGE NAME
        console.log('login res', res)
        //if true
        if (res) {
          if (
            !res.signInUserSession &&
            res.challengeName === 'NEW_PASSWORD_REQUIRED'
          ) {
            setLoading(false)
            setShowChangeTemporaryPasswordModal(true)
          } else {
            Auth.currentSession()
              .then((resp) => {
                localStorage.setItem('showSubMessage', true)
                let user = resp
                if (user) {
                  props.setUser(user)
                }
              })
              .catch((err) => {
                console.log(err)
                setLoading(false)
              })
            // .then(() => {
            //   window.location.replace('/Dashboard')
            // })
          }
        }
      })
      .catch((err) => {
        console.log('login err', err)
        setLoading(false)
        return
      })
  }

  const loginWithASA = async () => {
    try {
      window.location.assign(
        'https://api.allocaterite.com/mobile/user/sso-asa/login'
      )
    } catch (error) {
      console.log(error)
    }
  }

  const signinForm = () => {
    return (
      <>
        {/* <div className='space-bottom'>
          <AsaLoginButton t={t} />
        </div>
        <p className='or-text space-bottom space-top'>{t('or')}</p> */}
        <input
          placeholder={t('email')}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value?.toLowerCase())
          }}
          name='username'
          autoComplete='username'
          className='signin-input space-bottom'
        />
        <PasswordInput
          password={password}
          setPassword={setPassword}
          placeHolder={t('password')}
          className={'signin-input'}
        />
        <div className='signin-btn-container'>
          <Button
            className='signin-button'
            label={t('signin')}
            onClick={() => {
              resetMessages()
              validateForm()
            }}
            loading={loading}
          />
        </div>
        {errorMsg && <p className='error'>{errorMsg}</p>}
        {successMsg && <p className='success'>{successMsg}</p>}
        {/* <div className='or-container'>
          <hr />
          <p>or</p>
          <hr />
        </div>
        <div className='signin-btn-container-asa'>
          <div
            className='signin-button'
            onClick={() => {
              loginWithASA()
            }}
          >
            <img src={asa_image} alt='' />
            <p>Continue with ASA</p>
          </div>
        </div> */}
      </>
    )
  }
  const incorrectPassword = () => {
    setPage('signin')
    setErrorMsg(t('incorrectpassword'))
  }

  const changePasswordForm = () => {
    return <div></div>
  }

  const toggleShowChangeTemporaryPasswordModal = (val) => {
    setShowChangeTemporaryPasswordModal(val)
  }

  const goBack = (msg) => {
    setPage('signin')
    setErrorMsg(msg)
  }

  return (
    <>
      <MainNavBar
        isSmallScreen={isSmallScreen}
        t={props.t}
        whiteBorder={true}
      />
      <div className='signin-container'>
        {timeOut && (
          <div className='session-timeout-message-container'>
            <p className='session-timeout-message'>
              {props.t('sessiontimeout')}
            </p>
          </div>
        )}
        <div className='signin-form-container'>
          <p className='signin-header'>{t('signintoinvestall')}</p>
          <div className='signin-form'>
            {page === 'signin' && signinForm()}
            {page === 'codeverify' && (
              <CodeVerification
                email={email}
                password={password}
                t={t}
                defaultPage={'verify'}
                incorrectPassword={incorrectPassword}
                setUser={props.setUser}
                goBack={goBack}
              />
            )}
            {page === 'changepassword' && changePasswordForm()}
          </div>
          <div className='outside-container'>
            <NavLink
              exact={true}
              to='/forgotpassword'
              className='no-decoration'
            >
              <p className='outside-btn'>{t('forgotpassword')}?</p>
            </NavLink>
            <NavLink exact={true} to='/signup' className='no-decoration'>
              <p className='outside-btn'>{t('donthaveanaccount')}</p>
            </NavLink>
            <NavLink
              exact={true}
              to='/Legal/ForeSCITE-Privacy-Policy.pdf'
              target={'_blank'}
              className='no-decoration'
            >
              <p className='outside-btn light'>{t('privacypolicy')}</p>
            </NavLink>
          </div>
        </div>

        <ChangePasswordModal
          customStyles={customStyles}
          showChangePasswordModal={showChangeTemporaryPasswordModal}
          toggleShowChangePasswordModal={toggleShowChangeTemporaryPasswordModal}
          previousPassword={password}
          email={email}
          t={t}
        />
      </div>
    </>
  )
}
