import React, { Component } from 'react';
import TeamMember from '../TeamMember';
import { Container, Row } from 'react-bootstrap';

class TeamMembers extends Component {
    state = {
        members: [
        // {
        //     id: 1,
        //     name: "BALKRISHNA E. SHAGRITHAYA",
        //     position: "CEO",
        //     introduction: "A renowned technologist and highly successful entrepreneur, Balkrishna E. Shagrithaya is the CEO of AllocateRite. Balkrishna brings several decades of leadership in computer systems engineering, and design of enterprise-level front-end banking automation solutions. Most recently, he was CEO of the ESMT Group, a Dallas-based technology company which provides a scalable Enterprise Application Facility for cloud computing using SAS, supporting a very large number of concurrent applications and users. Previously, Mr. Shagrithaya was Co-Founder, EVP and CTO of Argo Data Resources Corporation in Dallas. Built and engineered from scratch in the 1980s, the company was among the first to provide retail software that automates the front-end systems (branches, call centers and Internet applications) of the major banking institutions. He was instrumental in building a blue chip list of banking clients which includes Bank of America and JP Morgan Chase; the company now serves 16 of the top 30 banks. Mr. Shagrithaya holds an M.S. degree in Industrial Engineering from Oklahoma State University, Oklahoma, and a B. Tech degree in Mechanical Engineering from the Indian Institute of Technology in Bombay, India."
        // }, {
        //     id: 2,
        //     name: "JOHN REBUTH",
        //     position: "CHIEF RISK OFFICER",
        //     introduction: "John Rebuth is the Chief Risk Officer of AllocateRite. John brings several decades of financial industry and regulatory compliance experience gained from working at major Wall Street firms. Most recently, he was CFO/COO of Sūmit Capital, a New York City based hedge fund. Prior to joining Sūmit Capital, he was a Managing Director with Deutsche Bank having a COO role overseeing the Global Equity Proprietary Trading group within Global Markets. Before that, Mr. Rebuth held various roles within NatWest Markets and Prudential. Mr. Rebuth is a Certified Public Accountant (CPA)."
        // }, {
        //     id: 3,
        //     name: "ADAM BAUMAN",
        //     position: "CHIEF OPERATING OFFICER",
        //     introduction: "Adam Bauman, an established internet technology and operations executive, is the Chief Operating Officer for AllocateRite. An early Internet adopter, Adam helped launch the latimes.com website before building an online store for Universal Studios and a corporate Intranet for DreamWorks S.K.G. Subsequently, he held various executive positions of increasing responsibility at VWR Scientific Products, Young & Rubicam, AIG and Deloitte Consulting, after which he served as COO and head of compliance for STI Executive Management Corp. Adam holds both an MFA degree from the Peter Stark Motion Picture Producing Program and an MBA from the Marshall Graduate School of Business at the University of Southern California. He is also a graduate of the California State University Long Beach School of Radio and Television."
        // }, {
        //     id: 4,
        //     name: "MICHAEL SPECE",
        //     position: "CHIEF AI / DATA SCIENTIST",
        //     introduction: "Dr. Spece is an expert in non-stationary time series analysis, in which historical financial data is not presumed to be representative of the future. His Carnegie Mellon University dissertation presents a framework for AI and machine learning that permits strong mathematically-proven guarantees for such series. By incorporating these guarantees into financial allocation strategies, clients are protected, regardless of economic conditions. Prior to AllocateRite, he was an AI, data science, and FinTech consultant. Michael graduated first in his class at Caltech and with a 4.0 GPA from Carnegie Mellon University, earning degrees in four interdisciplinary fields: Applied & Computational Mathematics (BS), Business Economics & Management (BS), Machine Learning (PhD), and Statistics & Data Science (PhD)."
        // }, {
        //     id: 5,
        //     name: "IVAN KOMAR",
        //     position: "HEAD OF TECHNOLOGY",
        //     introduction: "Ivan Komar is an accomplished technology entrepreneur who brings substantial experience building and scaling technology companies. He has been successfully launching businesses since the age of 16 and has worked on a wide variety of software systems, from mobile applications to distributed blockchain networks. Most recently, he was a founder and CEO of Sponsy — a blockchain-enabled sponsorships deal brokerage platform, which he built from scratch and successfully exited two years later. Its clients included Adidas and other influential international brands. Ivan’s successful achievements have received coverage from top media outlets, including the Financial Times and WSJ. Ivan holds a B.S. degree in Computer Science and Applied Mathematics from the leading Belarusian university."
        // },
        
            {
                 id: 1,
                 name: "BALKRISHNA E. SHAGRITHAYA",
                 position: "CHAIRMAN OF THE BOARD",
                 introduction: "Technology leader and entrepreneur, founded banking front-end automation. Accenture, founder and CTO of Argo Data Resource Corporation. Active daily engagement in AR.",
                 school: "Indian Institute of Technology, Oklahoma State University MS Industrial Eng."
             }, {
                 id: 2,
                 name: "ABBAS SHAH",
                 position: "FOUNDER AND ALGORITHM SPECIALIST",
                 introduction: "Expert in macro algorithmic-based trading systems. Expert in computer pricing model design, systematic trading systems and risk management platforms.",
                 school: "Columbia University BS Mathematical Economics"
             }, {
                 id: 3,
                 name: "ALAN RUBENFELD",
                 position: "SALES",
                 introduction: "Veteran institutional sales executive. Managing Director at leading global financial institutions, including BNP Paribas, UBS, and Deutsche Bank, responsible for the some of the largest global portfolio trading.",
                 school: "University of Michigan BA English Literature / MBA"
             },
             {
                 id: 4,
                 name: "IVAN KOMAR",
                 position: "HEAD OF TECHNOLOGY",
                 introduction:"Technology entrepreneur who brings substantial experience building and scaling technology companies. Expert in software systems, from mobile applications to cloud SaaS infrastructure and distributed blockchain networks.",
                 school: "Belarusian State University BS Computer Science"
             },
             {
                 id: 5,
                 name: "ADAM BAUMAN",
                 position: "CHIEF OPERATING OFFICER",
                 introduction: "Helped launch latimes.com , built Universal Studios online and developed corporate DreamWorks S.K.G. Intranet platform. He held executive positions at VWR Scientific Products, Young & Rubicam, AIG and Deloitte Consulting.",
                 school: "California State Long Beach BA; USC MFA and MBA"
             },
             {
                 id: 5,
                 name: "ForeSCITE™ COLLECTIVE",
                 position: "AI DATA SCIENCE PHD TEAM",
                 introduction: "AI & Data Science experts in non-stationary time series analysis. Team has developed a framework for AI and machine learning of models and techniques that permits strong mathematically-proven guarantees to provide portfolios with risk-optimized performance, regardless of economic conditions.",
                 school: ""
             },
         

    ]
    }


    render() {
        return (
            <div>
                <div style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                    <h4>Meet Our Team</h4>
                </div>
                <div>
                <Container className="team-member-form">
                    <Row className="page-blank-row"></Row>
                    <Row className="team-member-row">
                        {this.state.members.map(member =>
                            <TeamMember value={member} key={member.id} />
                        )}
                    </Row>
                    <Row className="page-blank-row"></Row>
                </Container>
                </div>
            </div>
        )
    }
}

export default TeamMembers
