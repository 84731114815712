import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import ENDPOINT from '../Endpoint'
import MoneyTransfer from '../../assets/newUI/money-transfer.png'


export default class RedemptionModal extends React.Component{

    state = {
        from:this.props.acc.accountId,
        depositAmount:'0.00',
        currentRedemptions: {},
        summary:false,
        transferStatus:false,
    }

    componentDidMount(){
        this.getRedemptionStatus()
    }

    roboOrGoalsRedemption = ()=>{
        this.setState({transferStatus:'Redemption submitted'})
        let percent =  parseFloat(this.state.depositAmount)/parseFloat(this.props.acc.balances[0].current)*100
        console.log('%', percent)
        fetch(`${ENDPOINT}/rebalance-mobile/redeem-robo?dw_account_id=${this.state.from}&percent=${percent}`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => {
              console.log(responseJson)
                  //{Redeem Account: "Success"}
                if(responseJson['Redeem Account'] === "Success"){
                    this.setState({summary:true})
                }
                else{
                    this.setState({transferStatus:'Redemption failed. Please try again during market hours.'})
                }
          })
    }

    successErrorLoadingBackground = ()=>{
        if(this.state.transferStatus === 'Redemption submitted'){
            return 'rgb(234,255,219)'
        } else if(this.state.transferStatus === 'Placing Order...'){
            return 'rgb(225,225,235)'
        }else{
            return 'rgb(253,180,181)'
        }
     }

    groupRedemption = ()=>{
        console.log('do a group redemption this')
    }

    redeem = ()=>{
        if(this.props.acc.product === 'goal' || this.props.acc.product === 'robo'){
            this.roboOrGoalsRedemption()
        }
        else if (this.props.acc.product === 'group'){
            this.groupRedemption()
        }
    }

    addCommas = (x)=>{
        x = x.toString()
        if(x === ''){
            return ''
        }else{
            let y = x.split('.')
            let z = y[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
            // if(typeof y[1] === 'undefined'){
            //     return z + '.00'
            // }
            // return z + '.' + y[1]
            return z
        }
    }

    removeCommas = (x)=>{
        if(x === ''){
            return ''
        }else{
            return parseFloat(x.replace(/,/g, ''))
        }
    }

    createDropdown = ()=>{
        let fromTo = 'from'
        let list = []
        let mapped = [<option value={'select account'} selected={fromTo === 'from'?'':'to'}>{'Select an Account Below'}</option>]
        if (this.props.allAccounts){
            list = this.props.allAccounts
        }
        list.forEach(acc=>{
            let title = ''
            if(acc.inst === 'DriveWealth'){
                title = acc.inst + ' ' + acc.mask_full + ' $'  /*acc.balances[0].current ?this.addCommas(acc.balances[0].current.toFixed(2)):'0.00'*/
            }
            else{
                title = acc.inst + ' ' + acc.mask + ' $'  /*acc.balances[0].current ?this.addCommas(acc.balances[0].current.toFixed(2)):'0.00'*/
                // title = acc.inst + ' ' + acc.mask + ' $' + /*acc.balances[0].current ?this.addCommas(acc.balances[0].current.toFixed(2)):'0.00'*/
            }
            title +=  acc.balances[0].current ?this.addCommas(acc.balances[0].current.toFixed(2)):'0.00'
            mapped.push(
                <option value={acc.accountId} selected={fromTo === 'from'?'':'to'}>{title}</option>
            ) 
        })
        return(
            <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:'60%'}}>

                <select placeholder={fromTo === 'from'?'':'to'} onChange={(e)=>this.setState({[fromTo]:e.target.value})} value={this.state[fromTo]} style={{border:'none',textAlign:'left', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent', width:'100%'}}>
                    {mapped}
                </select>
            </div>
        )
    }

    getRedemptionStatus = ()=>{
        fetch(`${ENDPOINT}/rebalance-mobile/redeem-status?dw_account_id=${this.props.acc.accountId}`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => {
              console.log('get redem status:', responseJson)
              this.setState({currentRedemptions: {...responseJson}})
            })
    }

    summary = ()=>{
        return (
            <div style={{width:'100%'}}>
                <div style={{display:'flex', flexDirection:'column', backgroundColor:'rgb(255,255,255)', padding:20, borderRadius:12, marginBottom:10   }}>
                    <h3 style={{color:'rgb(17,17,17)', textAlign:'center'}}>Redemption Initiated</h3>
                    <hr></hr>
                    <div style={{width:'95%', margin:'auto'}}>
                        <div>
                        </div>
                        <div style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
                            <img
                                src={MoneyTransfer}
                                style={{width:300,}}

                            />
                        </div>
                        <div>
                            <p style={{textAlign:'center', fontSize:20}}>{`Your redemption of $${this.state.depositAmount} may take 3-5 business days.`}</p>
                        </div>
                    </div>
                 </div>
             </div>
        )
    }
    

    render(){
        if(this.state.showSummary){
            return(
                this.summary()
            )
        }else{
            return(
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', height: '90%', paddingBottom:25}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Redeem Funds</p>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.props.closeModal()}> X </p>
                    </div>
                    <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                        
                            <div style={{paddingTop:25, width:'95%',margin:'auto', paddingLeft:20,paddingRight:20,paddingBottom:20 }}>
                            <div>
                                <p>Redemption Activity:</p>
                                <div style={{display:'flex',justifyContent:'space-between', alignItems:'center', width:'95%',margin:'auto'}}>
                                    <div>
                                        <p>Available</p>
                                        <p>${this.state.currentRedemptions.redeem_value?this.state.currentRedemptions.redeem_value.toFixed(2):0}</p>
                                    </div>
                                    <div>
                                        <p>Pending</p>
                                        <p>${this.state.currentRedemptions.pending_redeem_value?this.state.currentRedemptions.pending_redeem_value.toFixed(2):0}</p>
                                    </div>
                                    <div>
                                        <p>Transfer Date</p>
                                        <p>{this.state.currentRedemptions.expiry_date? this.state.currentRedemptions.expiry_date:'No Transfers'}</p>
                                    </div>
                                </div>
                            </div>
                                <p>In order to withdraw funds from robo or goal account you must first redeem that amount of funds here. </p>
                                <p style={{margin:0,fontSize:20}}>From:</p>
                                <div style={{marginBottom:10}}>
                                    {this.props.allAccounts.length > 0 ? this.createDropdown('from'): <p>You have no accounts linked</p>}
                                </div>
                                <div>
                                    <p style={{margin:0,fontSize:20}}>
                                        Amount: 
                                    </p>
                                    <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:'60%',marginBottom:10}}>
                                        <div style={{width:'100%', display:'flex',justifyContent:'space-between'}}>
                                            <input 
                                                style={{border:'none',textAlign:'left', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent', }}
                                                type="text" 
                                                value={this.addCommas(this.state.depositAmount)} 
                                                onChange={(e)=>{
                                                    let depositAmount = this.removeCommas(e.target.value)
                                                    this.setState({depositAmount})
                                                }}
                                            />
                                            <p style={{margin:0,color:'rgb(52,131,129)',textAlign:'right'}}>(USD)</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <p style={{fontSize:20, margin:0}}>
                                    Memo (optional)
                                </p>
                                <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:'60%',marginBottom:10}}>
                                    <input 
                                            style={{width:'100%', border:'none',textAlign:'left', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent', }}
                                            type="textarea" 
                                            value={this.state.comment} 
                                            onChange={(e)=>{
                                                this.setState({comment:e.target.value})
                                            }}
                                        />
                                </div>
                                <div style={{width:"100%", }}>
                                    { this.state.transferStatus?
                                        <div style={{display:'flex', width:'60%', backgroundColor:this.successErrorLoadingBackground(), padding:12, marginBottom:10, borderRadius:8,}}>
                                            <p style={{margin:'auto'}}>
                                                {this.state.transferStatus}
                                            </p> 
                                        </div>
                                    :null}
                                    <div style={{padding:10, border:'solid',borderWidth:'0.25px', borderRadius:10, width:'50%',cursor:'pointer',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={ this.state.spinner?null:()=>this.redeem()}>
                                        {this.state.spinner? 
                                         <Spinner animation="border" id="spinner" variant="dark" role="status" >
                                             <span className="sr-only">Loading...</span>
                                        </Spinner>:
                                        <p style={{margin:0, textAlign:'center'}}>
                                            Redeem Funds
                                        </p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }}
}