import Axios from 'axios'
import Endpoint from '../components/Endpoint'
//EMAIL USING NETENS API
export const sendEmail = async (subject, content, recipients) => {
  try {
    let body = {
      send_to: '',
      subject: subject,
      body: content,
    }
    for (let i = 0; i < recipients.length; i++) {
      body.send_to = recipients[i]
      await Axios.post(`${Endpoint}/mobile/email-mobile`, body)
    }
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}
