import moment from 'moment'
import React from 'react'
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  Area,
  Legend,
  Rectangle,
} from 'recharts'
import formatCurrency from '../../utility-functions/formatCurrency'
import '../../scss/pages/cp3/ForecastChart.scss'

export default function ForecastChart({ forecastChartData, t }) {
  const CustomTooltip = ({ payload, label, active }) => {
    if (active && payload && label) {
      return (
        <div className='custom-tooltip'>
          <div className='custom-tooltip-row'>
            <p className='value-label purple'>{t('prediction')}: </p>
            <p className='value-predicted purple'>
              {formatCurrency(payload[0].value)}
            </p>
          </div>
          <div className='custom-tooltip-row'>
            <p className='value-label green'>{t('actual')}: </p>
            <p className='value-actual green'>
              {formatCurrency(payload[1].value)}
            </p>
          </div>
          <p className='custom-tooltip-date'>{label}</p>
        </div>
      )
    }
    return null
  }

  const getChartData = () => {
    const data = []
    if (forecastChartData) {
      forecastChartData?.graph?.forEach((item, index) => {
        data.push({
          name: moment.unix(item.date / 1000).format("MMM 'YYYY"),
          prediction: item.prediction_1m,
          actual: item.price,
        })
      })
    }
    return data
  }

  return (
    <ResponsiveContainer width={'100%'} height={'100%'}>
      <LineChart
        data={getChartData()}
        margin={{ left: 0, right: 0 }}
        padding={{ left: 0, right: 0 }}
      >
        <defs>
          <linearGradient id='colorUv' x1='0.4' y1='0' x2='0.6' y2='1'>
            <stop offset='0%' stopColor={'#3F46F6'} />
            <stop offset='100%' stopColor={'rgba(63,70,246,0)'} />
          </linearGradient>
        </defs>
        <XAxis dataKey={'name'} axisLine={false} tick={false} />
        <YAxis
          width={0}
          axisLine={false}
          tick={false}
          domain={[undefined, undefined]}
        />
        <Legend formatter={(value) => t(value)} />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type='monotone'
          dataKey='prediction'
          stroke='#3627e4'
          activeDot={{ r: 5 }}
          dot={false}
        />
        <Line
          type='monotone'
          dataKey='actual'
          stroke='#85ca9d'
          activeDot={{ r: 2 }}
          dot={false}
        />
        {/* <Area
          type='linear'
          dataKey={this.props.yLabel}
          stroke='#3F46F6'
          fillOpacity={1}
          fill='url(#colorUv)'
        /> */}
      </LineChart>
    </ResponsiveContainer>
  )
}
