import React from 'react'

export default function SimpleTable({
  group_reference,
  table_info,
  newt_res_data,
  table_data,
}) {
  if (
    !newt_res_data ||
    !newt_res_data?.display_columns ||
    !newt_res_data?.result_data
  ) {
    return <p>No data available.</p>
  }

  const columns = newt_res_data?.display_columns[group_reference]
  const tableData = newt_res_data?.result_data[group_reference]
  const displayNames = newt_res_data?.display_names[group_reference]

  if (!columns || !tableData) {
    return <p>Table information missing.</p>
  }

  return (
    <div className='newt-table-container'>
      <table className='newt-table'>
        <thead>
          <tr className='newt-table-header'>
            {columns?.map(
              (column, index) =>
                !column?.includes('_link') && (
                  <th key={index} className='newt-table-header-cell'>
                    {displayNames?.[column]}
                  </th>
                )
            )}
          </tr>
        </thead>
        <tbody className='newt-table-body'>
          {tableData?.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns?.map(
                (column, colIndex) =>
                  !column?.includes('_link') && (
                    <td
                      key={colIndex}
                      className={`table-cell ${
                        row?.[column + '_link'] ? 'link-text' : ''
                      }`}
                    >
                      {row?.[column + '_link'] ? (
                        <a
                          href={row?.[column + '_link']}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {row?.[column]}
                        </a>
                      ) : (
                        row?.[column]
                      )}
                    </td>
                  )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
