import Axios from 'axios'
import Endpoint from '../components/Endpoint'
import SearchEndpoint from '../components/SearchEndpoint'

export const getApkData = async () => {
  try {
    const response = await Axios.get(`${Endpoint}/mobile/android/get-latest`)
    return response?.data
  } catch (err) {
    console.log(err)
  }
}

export const sendApkIncrement = async (version) => {
  try {
    const response = await Axios.get(
      `${Endpoint}/mobile/android/downloads?version=${version}`
    )
  } catch (error) {
    console.log(error)
  }
}

export const searchTicker = async (ticker, jwtToken) => {
  try {
    const url = `${Endpoint}/search/ticker?search=${ticker.toUpperCase()}`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
        'Content-Type': 'application/json',
      },
    }
    const res = await Axios.get(url, header)
    if (res?.data?.content) {
      return res?.data.content
    } else {
      return []
    }
  } catch (error) {
    console.log(error)
  }
}

export const fetchPropertySuggestions = async (jwtToken, query, limit) => {
  try {
    const url = `${Endpoint}/search/address-suggestions?address=${query}&limit=${limit}`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
        'Content-Type': 'application/json',
      },
    }
    const res = await Axios.get(url, header)
    if (res?.data?.data?.addresses) {
      return res?.data.data?.addresses
    } else {
      return []
    }
  } catch (error) {
    console.log(error)
    return []
  }
}

export const fetchPropertyData = async (jwtToken, address) => {
  try {
    const url = `https://api.honely.com/searches/short_forecast?address=${address}`
    const header = {
      headers: {
        Authorization: 'Bearer ' + jwtToken,
        'Content-Type': 'application/json',
      },
    }
    const res = await Axios.get(url, header)
    return res?.data
  } catch (error) {
    console.log(error)
  }
}
