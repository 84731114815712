import React, { Component } from 'react'
import { InputSwitch } from 'primereact/inputswitch'
import ConfirmationDialog from '../../ConfirmationDialog'
import Axios from 'axios'
import Endpoint from '../../../Endpoint'

export default class GroupCryptoSpecificInfo extends Component {
  state = {
    showConfirmationDialog: false,
  }
  deactivateAIcryptoTrader = async () => {
    return await Axios.post(
      `${Endpoint}/apex-cp/trade-groups/liquidate`,
      {
        dw_account_id: this.props.selectedAccount.accountId,
        product: 'group',
      },
      {
        headers: {
          Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
        },
      }
    )
      .then((response) => {
        if (
          response?.data['Liquidate Account'] &&
          response?.data['Liquidate Account'] === 'Complete'
        ) {
          this.props.getAllAccountInfo()
          return true
        } else {
          return false
        }
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  }
  toggleShowConfirmationDialog = () => {
    this.setState({
      showConfirmationDialog: !this.state.showConfirmationDialog,
    })
  }
  overview() {
    return (
      <div>
        <p
          style={{
            color: 'black',
            fontFamily: 'Akkurat-Bold',
            fontSize: '15px',
            textAlign: 'center',
          }}
        >
          {this.props.t('overview')}
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ paddingRight: 5 }}>Active</p>
          </div>
          <InputSwitch
            checked={true}
            onChange={(e) => this.toggleShowConfirmationDialog()}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('startdate')}</p>
          <p>{this.props.productInstanceData?.groupData?.createDate}</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <p>{this.props.t('investmentamount')}</p>
          <p>
            {this.props.formatCurrency(
              this.props.productInstanceData?.groupData?.startValue
            )}
          </p>
        </div>
        {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>Return</p>
          {this.props.productInstanceData?.groupData?.groupPNL > 0 ? (
            <p style={{ color: "#3F46F6" }}>
              {this.props.formatCurrency(
                this.props.productInstanceData?.groupData?.groupPNL
              )}
            </p>
          ) : (
            <p style={{ color: "#831818" }}>
              {this.props.formatCurrency(
                this.props.productInstanceData?.groupData?.groupPNL
              )}
            </p>
          )}
        </div> */}
      </div>
    )
  }
  render() {
    if (this.props.t) {
      return (
        <div
          style={{
            width: this.props.isSmallScreen ? '100%' : '100%',
          }}
        >
          {this.overview()}
          <ConfirmationDialog
            name={this.props.t('deactivateaicryptotrader')}
            action={this.deactivateAIcryptoTrader}
            isSmallScreen={this.props.isSmallScreen}
            toggleShowConfirmationDialog={this.toggleShowConfirmationDialog}
            showConfirmationDialog={this.state.showConfirmationDialog}
            successMessage={this.props.t(
              'aicryptotraderhasbeensuccessfullydeactivated'
            )}
            t={this.props.t}
          />
        </div>
      )
    } else return null
  }
}
