import React from 'react'
import NoImage from '../../assets/newUI/logo_notfound.png'

import ENDPOINT from '../Endpoint'

export default class TradeSummary extends React.Component {
  state = {
    live: false,
  }

  componentDidMount() {
    this.getLivePrice(this.props.symbol)
  }

  addCommas = (x) => {
    if (x === '') {
      return ''
    } else {
      let arr = x.split('.')
      return arr[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + arr[1]
    }
  }

  getLivePrice = (sym) => {
    let url = `${ENDPOINT}/mobile/risk-monkey/ticker/${sym}/live`
    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        this.setState({ live: responseJson })
      })
  }

  render() {
    let body = this.props

    return (
      <div style={{ width: '100%', paddingLeft: 20, paddingRight: 20 }}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgb(255,255,255)',
              borderRadius: 7,
              overflow: 'hidden',
              width: 100,
              height: 100,
              justifySelf: 'flex-end',
            }}
          >
            <img
              // src={`https://drivewealth.imgix.net/symbols/${this.state.selected.symbol.toLowerCase()}.png`}
              src={body.img === 'not-found' ? NoImage : body.img}
              style={{ objectFit: 'contain', width: 100, height: 100 }}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '5%',
            paddingRight: '5%',
            paddingTop: '2%',
            paddingBottom: '2%',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ width: '50%' }}>
              <p style={{ margin: 0, fontSize: 36, fontWeight: 'bold' }}>
                {body.side}
              </p>
            </div>
            <div style={{ width: '50%' }}>
              <p
                style={{
                  margin: 0,
                  fontSize: 20,
                  fontWeight: 'bold',
                  textAlign: 'right',
                }}
              >
                {body.symbol}
              </p>
              <p
                style={{
                  margin: 0,
                  fontSize: 18,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textAlign: 'right',
                }}
              >
                {body.company}
              </p>
            </div>
          </div>
          <hr></hr>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p>Market Price</p>
              <p>${this.addCommas(body.amount.toFixed(2))}</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p>Estimated Quantity</p>
              <p>{parseFloat(body.quantity).toFixed(8)}</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p>Order Type</p>
              <p>MARKET</p>
            </div>
            {this.state.live ? (
              <div style={{ width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <p>Bid & Ask Price</p>
                  <p>
                    {this.state.live.bid}
                    {' - '}
                    {this.state.live.ask}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <p>Day's Range</p>
                  <p>
                    {this.state.live.low}
                    {' - '}
                    {this.state.live.high}
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <p style={{ margin: 0 }}>Volume</p>
                  <p style={{ margin: 0 }}>{this.state.live.volume}</p>
                </div>
              </div>
            ) : null}
          </div>
          <hr></hr>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p>Estimated Commision</p>
              <p>0.00</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ margin: 0 }}>Estimated Total</p>
              <p style={{ margin: 0 }}>{body.amount}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
