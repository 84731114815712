import React, { useEffect, useState } from 'react'
import '../scss/components/PasswordMessage.scss'
import {
  validateLength,
  validateUppercase,
  validateLowercase,
  validateNumber,
  validateSpecialCharacter,
} from '../utility-functions/validatePassword'

export default function PasswordMessage({ t, password }) {
  const [length, setLength] = useState(false)
  const [uppercase, setUppercase] = useState(false)
  const [lowercase, setLowercase] = useState(false)
  const [number, setNumber] = useState(false)
  const [specialChar, setSpecialChar] = useState(false)

  useEffect(() => {
    setLength(validateLength(password))
    setUppercase(validateUppercase(password))
    setLowercase(validateLowercase(password))
    setNumber(validateNumber(password))
    setSpecialChar(validateSpecialCharacter(password))
  }, [password])

  if (t) {
    return (
      <div className='password-msg'>
        <p className='password-msg-header'>{t('passwordmust')}</p>
        <ul>
          <li
            className={`password-msg-item ${
              length ? 'password-success' : 'password-error'
            }`}
          >
            {t('beatleast8characters')}
          </li>
          <li
            className={`password-msg-item ${
              uppercase ? 'password-success' : 'password-error'
            }`}
          >
            {t('haveatleastoneuppercase')}
          </li>
          <li
            className={`password-msg-item ${
              lowercase ? 'password-success' : 'password-error'
            }`}
          >
            {t('haveatleastonelowercase')}
          </li>
          <li
            className={`password-msg-item ${
              number ? 'password-success' : 'password-error'
            }`}
          >
            {t('haveatleastonenumber')}
          </li>
          <li
            className={`password-msg-item ${
              specialChar ? 'password-success' : 'password-error'
            }`}
          >
            {t('haveatleastonespecialcharacter')}
          </li>
        </ul>
      </div>
    )
  } else {
    return null
  }
}
