import React from "react";
import ENDPOINT from "../Endpoint";
import {
  FaEye,
  FaEyeSlash,
  FaChevronCircleDown,
  FaChevronCircleUp,
  FaUniversity,
  FaTimesCircle,
  FaCheckCircle,
} from "react-icons/fa";
import BrokerageRow from "../BrokerageRow";

const brandColor = "rgb(70,182,179)";

export default class RoboAcc extends React.Component {
  state = {
    tgData: "loading",
    show: "more",
    stratData: {},
    stratSelected: false,
    activeDropdown: false,
    showDesc: false,
    showAllStock: false,
  };

  componentDidMount() {
    if (this.props.acc.product === "robo") {
      this.getRoboStats2();
    } else {
      this.getGoalStats();
    }
    // this.fetchARstrategies()
  }

  getGoalStats = () => {
    // console.log('goal stats  starting')

    fetch(
      `${ENDPOINT}/mobile/goal-planning/data?account_id=${this.props.acc.accountId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.props.user.idToken.jwtToken,
        },
      }
    )
      .then((response) => response.json())
      .then((respJSON) => {
        //    console.log('goal stats  resp',respJSON)
        this.setState({ data: respJSON });
      });
  };

  // getRoboStats(){
  //     // console.log('inside Robo Acc')
  //     fetch(`${ENDPOINT}/trackers`,{
  //         headers: {
  //             "Content-Type":"application/json",
  //             "Authorization": "Bearer " + this.props.user.idToken.jwtToken,
  //         },
  //     })
  //    .then(response => response.json())
  //    .then(respJSON => {
  //        console.log('robo stats 1 resp',respJSON)
  //     //    this.setState({stratSelected:respJSON[0]})
  //     })
  // //    .then(()=>this.props.closeModal())
  // }
  getRoboStats2 = () => {
    // console.log('inside Robo Acc2')
    fetch(`${ENDPOINT}/strategies/strategy-assigned`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.user.idToken.jwtToken,
      },
    })
      .then((response) => response.json())
      .then((respJSON) => {
        //    console.log('robo stats2  resp',respJSON)
        this.setState({ data: respJSON.payload });
      });
    //    .then(()=>this.props.closeModal())
  };

  fetchARstrategies = () => {
    fetch(`${ENDPOINT}/strategies/1`, {
      "Access-Control-Allow-Headers": {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        this.setState({ stratData: responseJson });
      });
  };

  addCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  utcToLocal(timeAndDate) {
    let date = new Date(timeAndDate).toString().split(" ");
    let hour = date[4].substring(0, 2);
    let min = date[4].substring(3, 5);
    let day = date[1] + " " + date[2] + ", " + date[3];
    let amPm = "AM";
    if (hour > 12) {
      hour = hour - 12;
      amPm = "PM";
    } else if (hour === "00") {
      hour = "12";
    }
    let time = `${hour}:${min} ${amPm}`;
    return (
      <div style={{ width: "100%" }}>
        <p style={{ textAlign: "right", margin: 0, fontSize: 14 }}>
          {time.replace(/^0(?:0:0?)?/, "")}
        </p>
        <p style={{ textAlign: "right", margin: 0, fontSize: 14 }}>{day}</p>
      </div>
    );
  }

  checkLogo = (symbol) => {
    let url = `https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`;
    var http = new XMLHttpRequest();
    http.open("HEAD", url, false);
    http.send();
    if (http.status != 404) {
      return (
        <div
          style={{
            display: "flex",
            cursor: "pointer",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgb(255,255,255)",
            borderRadius: 7,
            overflow: "hidden",
            width: 70,
            height: 70,
            justifySelf: "flex-end",
          }}
        >
          <img
            // src={`https://drivewealth.imgix.net/symbols/${ele.symbol.toLowerCase()}.png`}
            src={`https://drivewealth.imgix.net/symbols/${symbol.toLowerCase()}.png`}
            onError="this.onerror=null; this.remove();"
            style={{ objectFit: "contain", width: 70, height: 70 }}
          />
        </div>
      );
    }
  };

  row(stock) {
    return (
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: 5,
            marginTop: 10,
          }}
        >
          <div style={{ width: "8%" }}>
            {stock.ticker === "CASH" ? null : this.checkLogo(stock.ticker)}
          </div>
          <p style={{ width: "10%", textAlign: "center", fontSize: "large" }}>
            {stock.ticker}
          </p>
          <p style={{ width: "20%", textAlign: "right", fontSize: "large" }}>
            {stock.bought_percentage === 100.0 ? "YES" : "NO"}
          </p>
          <p style={{ width: "20%", textAlign: "right", fontSize: "large" }}>
            {stock.allocated_percentage.toFixed(1)}%
          </p>
          <p style={{ width: "20%", textAlign: "right", fontSize: "large" }}>
            ${stock.bought_cost.toFixed(2)}
          </p>
          <p style={{ width: "20%", textAlign: "right", fontSize: "large" }}>
            {this.utcToLocal(stock.trend_date)}
          </p>
          <div style={{ width: "2%" }}></div>
        </div>
        <hr></hr>
      </div>
    );
  }

  accButtons = () => {
    let acc = this.props.acc;
    return (
      <div
        style={{
          display: "flex",
          width: "40%",
          justifyContent: "flex-end",
          flexWrap: "wrap",
        }}
      >
        {/* {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius: 5,borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                    onClick={()=>this.props.orders()}>
                                        <p style={{margin:0,fontWeight:'',fontSize:18, color:'rgb(17,17,17)'}}>Orders</p>
                                    </div>
                                :null
                                } */}
        {acc.inst === "DriveWealth" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              marginBottom: 5,
              border: "solid",
              borderRadius: 5,
              borderWidth: "1.25px",
              borderColor: "rgb(17,17,17)",
              marginLeft: 10,
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 15,
              paddingRight: 15,
            }}
            onClick={() => this.props.deposit()}
          >
            <p
              style={{
                margin: 0,
                fontWeight: "",
                fontSize: 18,
                color: "rgb(17,17,17)",
              }}
            >
              Transfer
            </p>
          </div>
        ) : null}
        {acc.inst === "DriveWealth" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              marginBottom: 5,
              border: "solid",
              borderRadius: 5,
              borderWidth: "1.25px",
              borderColor: "rgb(17,17,17)",
              marginLeft: 10,
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 15,
              paddingRight: 15,
            }}
            onClick={() => this.props.redemption()}
          >
            <p
              style={{
                margin: 0,
                fontWeight: "",
                fontSize: 18,
                color: "rgb(17,17,17)",
              }}
            >
              Redeem
            </p>
          </div>
        ) : null}
        {acc.inst === "DriveWealth" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              marginBottom: 5,
              border: "solid",
              borderRadius: 5,
              borderWidth: "1.25px",
              borderColor: "rgb(17,17,17)",
              marginLeft: 10,
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 15,
              paddingRight: 15,
            }}
            onClick={() => this.props.transactions()}
          >
            <p
              style={{
                margin: 0,
                fontWeight: "",
                fontSize: 18,
                color: "rgb(17,17,17)",
              }}
            >
              Transactions
            </p>
          </div>
        ) : null}
        {acc.inst === "DriveWealth" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              marginBottom: 5,
              border: "solid",
              borderRadius: 5,
              borderWidth: "1.25px",
              borderColor: "rgb(17,17,17)",
              marginLeft: 10,
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 15,
              paddingRight: 15,
            }}
            onClick={() => this.props.analyze()}
          >
            <p
              style={{
                margin: 0,
                fontWeight: "",
                fontSize: 18,
                color: "rgb(17,17,17)",
              }}
            >
              Analyze
            </p>
          </div>
        ) : null}

        {/* {
                                acc.inst  === 'DriveWealth'? 
                                    <div 
                                    style={{display:'flex',justifyContent:'center',alignItems:'center', cursor:'pointer', marginBottom:5, border:'solid', borderRadius: 5,borderWidth:'1.25px', borderColor: 'rgb(17,17,17)', marginLeft:10, paddingTop: 5, paddingBottom:5, paddingLeft:15,paddingRight:15, }}
                                    onClick={()=>this.setState({showStatements:acc})}
                                    // onClick={()=>this.getStatements(acc.accountId)}
                                    >
                                        <p style={{margin:0,fontWeight:'',fontSize:18, color:'rgb(17,17,17)'}}>Statements</p>
                                    </div>
                                :null
                                } */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            marginBottom: 5,
            border: "solid",
            borderRadius: 5,
            borderWidth: "1.25px",
            borderColor: "#ee5e68",
            marginLeft: 10,
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 15,
            paddingRight: 15,
          }}
          onClick={() => this.props.unlink()}
        >
          <p
            style={{
              margin: 0,
              fontWeight: "",
              fontSize: 18,
              color: "#ee5e68",
            }}
          >
            Deactivate
          </p>
        </div>
      </div>
    );
  };

  createRow = () => {
    return this.state.data.allocations.map((ele) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: 5,
            marginTop: 10,
          }}
        >
          <div>{this.checkLogo(ele.symbol)}</div>
          <p style={{ width: "20%", textAlign: "center", fontSize: "large" }}>
            {ele.symbol}
          </p>
          <p style={{ width: "20%", textAlign: "right", fontSize: "large" }}>
            {ele.sector}
          </p>
          <p style={{ width: "20%", textAlign: "right", fontSize: "large" }}>
            ${ele.value.toFixed(2)}
          </p>
          <p style={{ width: "20%", textAlign: "right", fontSize: "large" }}>
            {(ele.allocation * 100).toFixed(2)}%
          </p>
          <div style={{ width: "2%" }}></div>
        </div>
      );
    });
  };

  buttonDropdown = (acc) => {
    if (this.state.activeDropdown) {
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              marginBottom: 5,
              border: "solid",
              borderRadius: 5,
              borderWidth: "1.25px",
              borderColor: "rgb(17,17,17)",
              marginLeft: 10,
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <p
              style={{
                margin: 0,
                fontWeight: "",
                color: "rgb(17,17,17)",
                fontSize: 18,
              }}
            >
              Menu
            </p>
          </div>
          <div
            style={{
              position: "absolute",
              right: "5%",
              backgroundColor: "#f9f9f9",
              minWidth: "10%",
              overflow: "auto",
              boxShadow: "0px 8px 16px 0px rgb(0 0 0 / 20%)",
              zIndex: 100,
              marginRight: 25,
            }}
          >
            <div
              style={{
                color: "black",
                padding: "12px 16px",
                textDecoration: "none",
                display: "block",
                cursor: "pointer",
                backgroundColor:
                  this.state.hover === "orders" ? "rgb(200,200,200)" : "",
              }}
              onClick={() => this.props.orders()}
              onMouseEnter={() => this.setState({ hover: "orders" })}
            >
              <p style={{ margin: 0, fontWeight: "", color: "rgb(17,17,17)" }}>
                Orders
              </p>
            </div>
            <div
              style={{
                color: "black",
                padding: "12px 16px",
                textDecoration: "none",
                display: "block",
                cursor: "pointer",
                backgroundColor:
                  this.state.hover === "transactions" ? "rgb(200,200,200)" : "",
              }}
              onClick={() => this.props.transactions()}
              onMouseEnter={() => this.setState({ hover: "transactions" })}
            >
              <p style={{ margin: 0, fontWeight: "", color: "rgb(17,17,17)" }}>
                Transactionsa
              </p>
            </div>
            {acc.inst === "DriveWealth" ? (
              <div
                style={{
                  color: "black",
                  padding: "12px 16px",
                  textDecoration: "none",
                  display: "block",
                  cursor: "pointer",
                  backgroundColor:
                    this.state.hover === "statements" ? "rgb(200,200,200)" : "",
                }}
                onClick={() => this.setState({ showStatements: acc })}
                onMouseEnter={() => this.setState({ hover: "statements" })}
              >
                <p
                  style={{ margin: 0, fontWeight: "", color: "rgb(17,17,17)" }}
                >
                  Statements
                </p>
              </div>
            ) : null}
            <div
              style={{
                color: "black",
                padding: "12px 16px",
                textDecoration: "none",
                display: "block",
                cursor: "pointer",
                backgroundColor:
                  this.state.hover === "unlink" ? "rgb(200,200,200)" : "",
              }}
              onClick={() => this.setState({ showUnlink: acc })}
              onMouseEnter={() => this.setState({ hover: "unlink" })}
            >
              <p style={{ margin: 0, fontWeight: "", color: "rgb(17,17,17)" }}>
                Deactivate
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              marginBottom: 5,
              border: "solid",
              borderRadius: 5,
              borderWidth: "1.25px",
              borderColor: "rgb(17,17,17)",
              marginLeft: 10,
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <p
              style={{
                margin: 0,
                fontWeight: "",
                color: "rgb(17,17,17)",
                fontSize: 18,
              }}
            >
              Menu
            </p>
          </div>
        </div>
      );
    }
  };

  pending = (acc) => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "auto",
          marginRight: "auto",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            paddingLeft: "5%",
            paddingRight: "5%",
            paddingTop: 40,
            paddingBottom: 40,
            borderRadius: "50px",
            backgroundImage: "linear-gradient(97deg, #ffffff 1%, #f2f2f2)",
            width: "100%",
            marginTop: 25,
          }}
        >
          <div style={{ width: "100%", margin: "auto", marginBottom: 10 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ margin: 0, fontSize: 22, textAlign: "left" }}>
                <strong>
                  {acc.product === "robo"
                    ? "Robo"
                    : acc.product === "group"
                    ? "Trade Group"
                    : acc.ria
                    ? "Directed Account"
                    : "Brokerage Account"}
                  :{" "}
                </strong>
                {acc.inst === "DriveWealth"
                  ? "DriveWealth " + acc.mask_full
                  : `${acc.inst} ${acc.mask}`}
              </p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: 10,
              }}
            >
              <p style={{ fontWeight: "bold", fontSize: 22, margin: 0 }}>
                This account is pending approval.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  formatPnLDollars(val) {
    let x = "0.00";
    if (!val) {
      x = "0.00";
    } else {
      x = this.addCommas(val.toFixed(2));
      if (val < 0) {
        x = "-$" + x.substring(1);
      } else {
        x = "$" + x;
      }
    }
    return (
      <strong style={{ color: val < 0 ? "rgb(238,94,104)" : brandColor }}>
        ({x})
      </strong>
    );
  }
  formatPnLPercent(val) {
    let x = "0.00";
    if (!val) {
      x = "0.00";
    } else {
      x = this.addCommas(val.toFixed(2));
    }
    return (
      <strong style={{ color: val < 0 ? "rgb(238,94,104)" : brandColor }}>
        ({x}%)
      </strong>
    );
  }

  dateOnly(dt) {
    let arr = dt.split(" ");
    return arr[2] + " " + arr[1] + ", " + arr[3];
  }

  showAccBody = () => {
    let total = 0;
    let acc = this.props.acc;
    // console.log('acc', acc)

    acc.holdings.forEach((stock) => {
      if (!stock.cashEq) {
        total += stock.instValue;
      }
    });
    if (
      acc.balances[0].available === 0 &&
      acc.balances[0].buyingPower === 0 &&
      acc.balances[0].cashAvailableForWithdrawal === 0 &&
      acc.balances[0].current === 0 &&
      acc.holdings.length === 0
    ) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 15,
          }}
        >
          <a onClick={() => this.props.deposit()} href="#">
            <p
              style={{
                width: "100%",
                margin: 0,
                textAlign: "left",
                color: brandColor,
                fontSize: 22,
              }}
            >
              To Get Started Click Here and Deposit Funds
            </p>
          </a>
        </div>
      );
    } else {
      let strat = "";
      let desc = "";
      if (this.state.data) {
        if (this.props.acc.product === "robo") {
          strat = this.state.data.strategyInfo.displayName;
          desc = (
            <p style={{ margin: 0, fontSize: 18 }}>
              {this.state.data.strategyInfo.description}
            </p>
          );
        } else {
          strat = this.state.data.strategy;
          desc = (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <div>
                  <p
                    style={{
                      margin: 0,
                      fontSize: this.props.isSmallScreen ? null : 18,
                    }}
                  >
                    Start Date:{" "}
                    <strong style={{ color: brandColor, marginLeft: 5 }}>
                      {this.dateOnly(this.state.data.start_date)}
                    </strong>
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      margin: 0,
                      fontSize: this.props.isSmallScreen ? null : 18,
                    }}
                  >
                    End Date:{" "}
                    <strong style={{ color: brandColor, marginLeft: 5 }}>
                      {this.dateOnly(this.state.data.end_date)}
                    </strong>
                  </p>
                </div>
              </div>
              <div>
                {/* <div>
                                    <p>Total Contributed: ${this.state.data.total_deposit.toFixed(2)}</p>
                                </div>
                                <div>
                                    <p>Total Profit/Loss:  {this.state.data.profit.toFixed(2)}</p>
                                </div> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {this.state.data.ontrack ? (
                    <FaCheckCircle
                      style={{ color: brandColor, marginRight: 5 }}
                    />
                  ) : (
                    <FaTimesCircle
                      style={{ color: "rgb(238,94,104)", marginRight: 5 }}
                    />
                  )}
                  <p
                    style={{
                      margin: 0,
                      color: this.state.data.ontrack
                        ? brandColor
                        : "rgb(238,94,104)",
                      marginRight: 5,
                      fontSize: this.props.isSmallScreen ? null : 22,
                    }}
                  >
                    {this.state.data.ontrack ? "On Track" : "Off Track"}
                  </p>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: this.props.isSmallScreen ? null : 18,
                    }}
                  >
                    Month Target:{" "}
                    <strong style={{ color: brandColor, marginLeft: 5 }}>
                      ${this.state.data.month_target.toFixed(2)}
                    </strong>
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: this.props.isSmallScreen ? null : 18,
                    }}
                  >
                    Goal Target:{" "}
                    <strong style={{ color: brandColor, marginLeft: 5 }}>
                      ${this.state.data.target.toFixed(2)}
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          );
        }
      }
      return (
        <div style={{ width: "100%" }}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p style={{ margin: 0, fontSize: 22 }}>
                <strong>Strategy:</strong> {strat}
              </p>
              {/* <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>+/- {this.formatPnL(acc.balances[0]['profit-loss'])}</p> */}
              <p
                style={{
                  margin: 0,
                  fontSize: this.props.isSmallScreen ? null : 18,
                }}
              >
                Account Value:{" "}
                <strong style={{ color: brandColor, marginLeft: 5 }}>
                  $
                  {acc.balances && acc.balances[0] && acc.balances[0].current
                    ? this.addCommas(acc.balances[0].current.toFixed(2))
                    : 0}
                </strong>{" "}
              </p>
              <p
                style={{
                  margin: 0,
                  fontSize: this.props.isSmallScreen ? null : 18,
                }}
              >
                Account P/L:{" "}
                <strong
                  style={{
                    color:
                      acc.balances[0]["profit-loss"] < 0
                        ? "rgb(238,94,104)"
                        : brandColor,
                    marginLeft: 5,
                  }}
                >
                  ${acc.balances[0]["profit-loss"].toFixed(2)}
                </strong>
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent:
                  this.state.showDesc && this.props.acc === "goal"
                    ? "space-between"
                    : "flex-start",
                alignItems: "center",
              }}
            >
              <strong
                onClick={() =>
                  this.setState({ showDesc: !this.state.showDesc })
                }
                style={{
                  margin: 0,
                  fontSize: 18,
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: brandColor,
                }}
              >
                {this.state.showDesc ? "Show Less" : "About"}
              </strong>
              {this.state.data &&
              this.state.showDesc &&
              this.props.acc === "goal" ? (
                <p
                  style={{
                    margin: 0,
                    fontSize: this.props.isSmallScreen ? null : 18,
                  }}
                >
                  Deposited:{" "}
                  <strong style={{ color: brandColor, marginLeft: 5 }}>
                    $
                    {this.state.data
                      ? this.addCommas(this.state.data.total_deposit.toFixed(2))
                      : 0}
                  </strong>{" "}
                </p>
              ) : null}
            </div>
            {this.state.data && this.state.showDesc ? desc : null}
          </div>
          <div style={{ width: "100%", margin: 0 }}>
            {/* <div style={{display:'flex', justifyContent:'flex-end', width:'100%'}}>
                                    <p style={{textAlign:'right'}}>Current Value: <strong style={{color:'#64A3A2'}}>${this.state.data? this.state.data.accountBalance.toFixed(2):null}</strong></p>
                                </div> */}
            <div
              style={{
                width: "100%",
                maxHeight: this.state.show === "more" ? "" : 250,
                overflowY: "scroll",
              }}
            >
              {/* {this.state.stratData === 'loading'? <div style={{display:'flex', justifyContent:'center',alignItems:'center', width:'100%'}}><p style={{fontSize:24, fontWeight:'bold'}}>Loading...</p></div>:
                                        <div style={{width:'100%',paddingTop:25, paddingBottom:25}}>
                                            
                                            <div style={{display:'flex',width:'100%', justifyContent:'space-evenly'}}>
                                                
                                                <div>
                                                    <p style={{fontWeight:'bold'}}>Month to Date:</p>
                                                    <p style={{textAlign:'center'}}>{this.state.data?(this.state.data.strategyInfo.mtd*100).toFixed(2):null}%</p>
                                                </div>
                                                <div>
                                                    <p style={{fontWeight:'bold'}}>Year to Date:</p>
                                                    <p style={{textAlign:'center'}}>{this.state.data?(this.state.data.strategyInfo.ytd*100).toFixed(2):null}%</p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    } */}
            </div>
            {/* <div style={{display:'flex', justifyContent:'space-between', width:'100%', marginBottom:5, marginTop:10}}>
                                    <p style={{width:'20%', textAlign:'center', fontSize:'large'}}>Symbol</p>
                                    <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>Sector</p>
                                    <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>Price</p>
                                    <p style={{width:'20%', textAlign:'right', fontSize:'large'}}>Allocation</p>
                                    <div style={{width:'2%'}}></div>
                                </div>
                                <div>
                                    {this.state.data? this.createRow():null}
                                </div> */}
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: 5,
                  marginTop: 10,
                }}
              >
                <p
                  style={{
                    width: "18%",
                    textAlign: "center",
                    fontSize: "large",
                  }}
                >
                  Symbol
                </p>
                <p
                  style={{
                    width: "20%",
                    textAlign: "right",
                    fontSize: "large",
                  }}
                >
                  Cost Basis
                </p>
                <p
                  style={{
                    width: "20%",
                    textAlign: "right",
                    fontSize: "large",
                  }}
                >
                  # Shares
                </p>
                <p
                  style={{
                    width: "20%",
                    textAlign: "right",
                    fontSize: "large",
                  }}
                >
                  Mkt Val
                </p>
                <p
                  style={{
                    width: "20%",
                    textAlign: "right",
                    fontSize: "large",
                  }}
                >
                  Allocation
                </p>
                <div style={{ width: "2%" }}></div>
              </div>
            </div>
            <div
              style={{
                maxHeight: this.state.showAllStock ? "" : 300,
                overflowY: "auto",
              }}
            >
              {/* {acc.holdings.map(ele=>this.brokerageRow(ele,acc.balances[0].current))} */}
              {acc.holdings.map((ele) => (
                <BrokerageRow
                  key={ele.symbol}
                  stock={ele}
                  total={acc.balances[0].current}
                />
              ))}
            </div>
            {acc.holdings.length > 3 ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    color: brandColor,
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    this.setState({ showAllStock: !this.state.showAllStock })
                  }
                >
                  {this.state.showAllStock ? "Show Less" : "Show More"}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  };

  render() {
    let acc = this.props.acc;
    if (acc.status === "PENDING") {
      return this.pending(acc);
    } else {
      if (this.props.isSmallScreen) {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingTop: 40,
                paddingBottom: 10,
                borderRadius: "10px",
                backgroundImage: "linear-gradient(97deg, #ffffff 1%, #f2f2f2)",
                width: "100%",
                marginTop: 25,
              }}
            >
              <div style={{ width: "100%", margin: "auto", marginBottom: 10 }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "block", alignItems: "center" }}>
                    <p style={{ margin: 0, fontSize: 22, textAlign: "left" }}>
                      <strong>
                        {acc.product === "goal" ? "GOAL" : `ForeSCITE Robo`}{" "}
                      </strong>
                      <strong>
                        <p style={{ fontSize: "15px", color: "grey" }}>
                          {acc.inst === "DriveWealth"
                            ? "DriveWealth " + acc.mask_full
                            : `${acc.inst} ${acc.mask}`}
                        </p>
                      </strong>
                    </p>
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div
                      onClick={() =>
                        this.setState({
                          activeDropdown: !this.state.activeDropdown,
                        })
                      }
                    >
                      {this.buttonDropdown(acc)}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginLeft: 10 }}>
                    {/* <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>{acc.inst}</p> */}
                    {/* {acc.inst === 'DriveWealth' ? 
                                        <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>{acc.mask_full}</p>:
                                        <p style={{margin:0, fontSize:this.props.isSmallScreen?null:18,}}>{acc.mask}</p>} */}
                    <p
                      style={{
                        margin: 0,
                        fontSize: this.props.isSmallScreen ? null : 18,
                      }}
                    >
                      <strong style={{ color: brandColor }}>
                        $
                        {acc.balances &&
                        acc.balances[0] &&
                        acc.balances[0].current
                          ? this.addCommas(acc.balances[0].current.toFixed(2))
                          : 0}
                      </strong>
                    </p>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <div
                      style={{
                        height: 48,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginTop: 9,
                        marginBottom: 15,
                      }}
                    >
                      <FaUniversity style={{ height: "100%", width: "auto" }} />
                    </div>
                    <p
                      style={{
                        margin: 0,
                        fontSize: this.props.isSmallScreen ? null : 18,
                      }}
                    >
                      {acc.ria ? "RIA-Directed" : "Self-Directed"}
                    </p>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
              flexDirection: "column",
              marginTop: 20,
            }}
          >
            <div
              style={{
                paddingLeft: "5%",
                paddingRight: "5%",
                paddingTop: 40,
                paddingBottom: 10,
                borderRadius: "10px",
                backgroundImage: "linear-gradient(97deg, #ffffff 1%, #f2f2f2)",
                /* backgroundColor:'#FFFFFF', */ width: "100%",
                marginBottom: 25,
              }}
            >
              {/* <div>
                                                    <p style={{margin:0,fontSize:22}}><strong>Strategy:</strong> { this.state.data?this.state.data.strategyInfo.displayName:null}</p>
                                                    <p style={{margin:0,fontSize:22}}>{ this.state.data?this.state.data.strategyInfo.description:null}</p>
                                                </div> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  margin: "auto",
                  marginBottom: 10,
                }}
              >
                <div style={{ display: "block", alignItems: "center" }}>
                  <p style={{ margin: 0, fontSize: 22, textAlign: "left" }}>
                    <strong>
                      {acc.product === "goal" ? "GOAL" : `ForeSCITE Robo`}{" "}
                    </strong>
                    <strong>
                      <p style={{ fontSize: "15px", color: "grey" }}>
                        {acc.inst === "DriveWealth"
                          ? "DriveWealth " + acc.mask_full
                          : `${acc.inst} ${acc.mask}`}
                      </p>
                    </strong>
                  </p>
                </div>
                {this.accButtons()}
                {/* <div onMouseEnter={()=>this.setState({activeDropdown:true,hover:''})} onMouseLeave={()=>this.setState({activeDropdown:false})}>{this.buttonDropdown(acc)}</div> */}
              </div>
              {this.showAccBody()}
            </div>
          </div>
        );
      }
    }
  }
}
