import React from 'react'

export default function StructuredText({
  keys,
  group_reference,
  newt_res_data,
}) {
  if (!keys || keys?.length === 0) {
    return null
  }

  return (
    <div className='structured-text-container'>
      {keys?.map((key, index) => {
        const displayName =
          newt_res_data?.display_names?.[group_reference]?.[key] ?? '--'
        const value =
          newt_res_data?.result_data?.[group_reference]?.[key] ?? '--'
        return (
          <div key={index} className='structured-text-row'>
            <p className='structured-text-label'>{displayName}</p>
            <p className='structured-text-value'>{value}</p>
          </div>
        )
      })}
    </div>
  )
}
