// import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official'
import Highcharts3D from 'highcharts/highcharts-3d'
import HighchartGantt from 'highcharts/highcharts-gantt'
import HighchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts/highstock'

import React from 'react'

HighchartsMore(Highcharts)
Highcharts3D(Highcharts)

const DynamicChart = (props) => {
  const data = props?.content_data?.data?.data

  const highchartStr = props?.content_data?.data?.hs_string

  const extractObjectString = (str) => {
    return str
      .replace(/Highcharts\.chart\(\s*`\$\{chart_id\}`\s*,\s*\{/, '{')
      .replace(/\}\);?\s*$/, '}')
      .trim()
  }

  const parseOptions = (str, data) => {
    // Define a function to safely return the object
    try {
      // Define a function to safely return the object
      const createOptionsFunction = new Function(
        'Highcharts',
        'data',
        `return (${str});`
      )

      // Call the function with Highcharts and data as arguments
      return createOptionsFunction(Highcharts, data)
    } catch (error) {
      console.log('Error parsing options:', error)
      return null
    }
  }

  const objectString = extractObjectString(highchartStr)

  // console.log("objectString", objectString);

  if (!objectString) {
    return (
      <p>
        It seems there was an unexpected error while rendering the chart. Please
        give it another shot
      </p>
    )
  }

  const options = parseOptions(objectString, data)

  if (!options) {
    return (
      <p>
        It seems there was an unexpected error with the chart data. Please give
        it another shot.
      </p>
    )
  }

  // console.log("options", options);

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export const MemoizedDynamicChart = React.memo(DynamicChart)
