import React, { useEffect, useState } from 'react'
import { SplitButton } from 'primereact/splitbutton'
import { PlaidLink } from 'react-plaid-link'
import ENDPOINT from '../Endpoint'
import '../../scss/components/LinkPlaidBtn.scss'
import { Dialog } from 'primereact/dialog'
import {
  FaEye,
  FaEyeSlash,
  FaChevronCircleDown,
  FaChevronCircleUp,
  FaPlusCircle,
} from 'react-icons/fa'
import LinkPlaidIcon from '../../assets/link-plaid-icon.svg'
import useStateWithCallback from 'use-state-with-callback'
import { usePlaidLink } from 'react-plaid-link'
import Axios from 'axios'
import Endpoint from '../Endpoint'
export default function LinkPlaidBtn(props) {
  //PROPS
  //user: user object
  //getAllAccountInfo: function to call to refetch all account holdings
  //isBank: boolean (bank account = true, brokerage = false)
  //showButton: boolean, if true show button else show icon
  //reLink: boolean, if true call relink api else call regular api
  const [plusDropdown, setPlusDropdown] = useState(false)
  const [messagePopUp, setMessagePopUp] = useState(false)
  const [errorsSucceses, setErrorsSucceses] = useState('')
  const [responseStatus, setResponseStatus] = useState(null)
  const [plaidToken, setPlaidToken] = useState('')
  const [realReady, setRealReady] = useState(false)
  const [showDialog, setShowDialog] = useState(false)

  const config = {
    token: plaidToken,
    onSuccess: (public_token, metadata) => {
      linkPlaidAccount(public_token, metadata)
      // console.log('public token', public_token)
    },
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    onEvent: (eventName, metadata) => {},
  }

  const { open, ready } = usePlaidLink(config)

  // if (window.location.href.includes('?oauth_state_id=')) {
  //   console.log(window.location.href)
  //   config.receivedRedirectUri = window.location.href
  // }

  useEffect(() => {
    if (ready && realReady) {
      open()
    }
  }, [open, ready])

  const plaidHandleOnSuccess = (public_token, metadata) => {
    let accountId = metadata.account_id
    let itemId = metadata.institution.institution_id
    linkPlaidAccount(public_token, metadata)
  }
  const linkPlaidAccount = (token, meta) => {
    setRealReady(false)
    let id = meta.account_id
    let accounts = meta.accounts
    let mask = meta.account.mask
    let name = meta.account.name
    let subType = meta.account.subtype

    let inst_id = meta.institution.institution_id
    let inst_name = meta.institution.name
    let public_token = token
    let body = {
      // accounts: [
      //   {
      //     id,
      //     mask,
      //     name,
      //     subType,
      //     type: "banking",
      //   },
      // ],
      accounts: accounts,
      inst_id,
      inst_name,
      public_token,
      type: props.isBank ? 'depository' : 'investment',
    }
    if (props.reLink) body.relinking = true
    fetch(`${ENDPOINT}/mobile-plaid/plaid/link`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + props.user?.idToken?.jwtToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then(async (respJSON) => {
        if (respJSON && respJSON['Insert Successful']) {
          await props.getAllAccountInfo()
          if (props.isBank) {
            setErrorsSucceses('Your bank account has been successfully linked')
          } else
            setErrorsSucceses(
              'Your brokerage account has been successfully linked'
            )
          setResponseStatus(true)
          setMessagePopUp(true)
        } else {
          setErrorsSucceses(respJSON.Error)
          setResponseStatus(false)
          setMessagePopUp(true)
        }
      })
      .catch((err) => console.log('catching: ', err))
  }

  const toggleMessagePopUp = () => {
    setMessagePopUp((prevState) => !prevState)
  }

  const initiatePlaidLink = async () => {
    setRealReady(true)
    let url = ''
    if (props.isBank) {
      url = `${Endpoint}/plaid-new/plaid/linktoken/create?link=bank&app=react`
    } else {
      url = `${Endpoint}/plaid-new/plaid/linktoken/create?link=brokerage&app=react`
    }
    await Axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + props.user?.idToken?.jwtToken,
      },
    })
      .then((res) => {
        // console.log('res', res)
        if (res?.data?.link_token) {
          setPlaidToken(res.data.link_token)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  if (props.t) {
    return (
      <>
        {props.showButton ? (
          <div
            className='link-bank-btn'
            onClick={() => {
              initiatePlaidLink()
            }}
            // onMouseEnter={() => this.setState({ hover: "linkBank" })}
          >
            {props.reLink ? (
              <>
                {props.isBank ? (
                  <p className='link-bank-label'>
                    {props.t('relinkbankaccount')}
                  </p>
                ) : (
                  <p className='link-bank-label'>
                    {props.t('relinkbrokerageaccount')}
                  </p>
                )}
              </>
            ) : (
              <>
                {props.isBank ? (
                  <p className='link-bank-label'>{props.t('addbankaccount')}</p>
                ) : (
                  <p className='link-bank-label'>
                    {props.t('addbrokerageaccount')}
                  </p>
                )}
              </>
            )}
          </div>
        ) : (
          <div>
            <img
              src={LinkPlaidIcon}
              className='link-bank-icon'
              width={25}
              height={25}
              onClick={() => {
                initiatePlaidLink()
              }}
            />
          </div>
        )}
        <Dialog
          visible={messagePopUp}
          onHide={toggleMessagePopUp}
          header={errorsSucceses}
          style={{ width: props.isSmallScreen ? '80vh' : '30vw' }}
        ></Dialog>
      </>
    )
  } else return null
}
