export const formatPercentage = (val) => {
  if (val) {
    val = parseFloat(val)
    // if value is less than 0.001

    let convertedVal = val * 100

    if (val < 0.001 && val >= 0) {
      return convertedVal.toFixed(4) + '%'
    } else if (val < 0 && val > -0.001) {
      return '-' + convertedVal.toFixed(4) + '%'
    } else if (val <= 0.001) {
      return '-' + convertedVal.toFixed(2) + '%'
    } else {
      return convertedVal.toFixed(2) + '%'
    }
  } else return '-'
}
