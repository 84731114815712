import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import BaseURL from '../Endpoint'
import Loader from '../components/accountInfo/Loader'
import { Dropdown } from 'primereact/dropdown'
import { RadioButton } from 'primereact/radiobutton'
import { InputNumber } from 'primereact/inputnumber'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { OverlayPanel } from 'primereact/overlaypanel'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { useHistory } from 'react-router-dom'

const ScreenerPage = () => {
  const priceRef = useRef(null)
  const dividendRef = useRef(null)
  const PERatioRef = useRef(null)
  const nineDaysRef = useRef(null)
  const fourteenDaysRef = useRef(null)
  const dailyVolumeRef = useRef(null)
  const volumeRef = useRef(null)
  const betaRef = useRef(null)

  const [tickers, setTickers] = useState(null)
  const [pageLoading, setPageLoading] = useState(true)

  const [selectedInputValues, setSelectedInputValues] = useState({})

  const [sectorsLists, setSectorsLists] = useState(null)
  const [industryLists, setIndustryLists] = useState(null)

  const [rangeError, setRangeError] = useState({})
  const ERROR_MSG = 'Enter a valid range'
  const history = useHistory()

  // dropdown and radio options list
  const marketCapLists = [
    // { label: 'All', value: 'null' },
    { label: 'Large Caps', value: 'large' },
    { label: 'Mid Caps', value: 'mid' },
    { label: 'Small Caps', value: 'small' },
    { label: 'Micro Caps', value: 'micro' },
  ]

  const cyclicalLists = [
    { label: 'Both', value: 'null' },
    { label: 'Cyclical', value: 'true' },
    { label: 'Non-Cyclical', value: 'false' },
  ]

  const EMA3Lists = [
    // { label: 'All', value: 'null' },
    { label: 'Bearish', value: 'Bearish' },
    { label: 'Neutral', value: 'Neutral' },
    { label: 'Bullish', value: 'Bullish' },
  ]
  const EMA8Lists = [
    // { label: 'All', value: 'null' },
    { label: 'Bearish', value: 'Bearish' },
    { label: 'Neutral', value: 'Neutral' },
    { label: 'Bullish', value: 'Bullish' },
  ]

  const durationsOptions = [
    { label: '3 months', value: '3 months' },
    { label: '6 months', value: '6 months' },
    { label: '1 year', value: '1 year' },
    { label: '2 year', value: '2 year' },
  ]

  const changesOptions = [
    { label: '>= 5%', value: 5 },
    { label: '>= 10%', value: 10 },
    { label: '>= 15%', value: 15 },
    { label: '>= 20%', value: 20 },
    { label: '>= 25%', value: 25 },
    { label: '>= 30%', value: 30 },
  ]

  const trendsOptions = [
    { label: 'Up Trend', value: 'uptrend' },
    { label: 'Down Trend', value: 'downtrend' },
  ]

  useEffect(() => {
    fetchFilterData({
      filters: {},
      range_filters: [{}],
    })
    setPageLoading(false)
  }, [])

  // if get the tickers from api, then get some dropdown options from api
  useEffect(() => {
    if (tickers) {
      const sectorsLists = Array.from({ length: tickers.sector?.length })?.map(
        (_, i) => ({
          label: `${tickers.sector?.[i]}`,
          value: tickers.sector?.[i],
        })
      )

      const industryLists = Array.from({
        length: tickers.industry?.length,
      }).map((_, i) => ({
        label: `${tickers.industry?.[i]}`,
        value: tickers.industry?.[i],
      }))

      setSectorsLists(sectorsLists)
      setIndustryLists(industryLists)
    }
  }, [tickers])

  // every input value change from screener update the table
  useEffect(() => {
    let data = storedInputData()

    console.log(data)

    fetchFilterData(data)
  }, [selectedInputValues])

  const fetchFilterData = async (body) => {
    try {
      let res = await axios.post(
        `${BaseURL}/search/ticker-filter-with-forecast`,
        body,
        { withCredentials: false }
      )
      console.log(res.data.content)

      // get the % for the forecast table
      if (res.data.content.tickers) {
        for (let ele of res.data.content?.tickers) {
          ele.forecast_3m_percentage =
            ((ele.forecast_3m - ele.price) / ele.price) * 100
          ele.forecast_6m_percentage =
            ((ele.forecast_6m - ele.price) / ele.price) * 100
          ele.forecast_1y_percentage =
            ((ele.forecast_1y - ele.price) / ele.price) * 100
          ele.forecast_2y_percentage =
            ((ele.forecast_2y - ele.price) / ele.price) * 100

          if (!ele.forecast_3m) {
            ele.forecast_3m_percentage = -100
          }
          if (!ele.forecast_6m) {
            ele.forecast_6m_percentage = -100
          }
          if (!ele.forecast_1y) {
            ele.forecast_1y_percentage = -100
          }
          if (!ele.forecast_2y) {
            ele.forecast_2y_percentage = -100
          }
        }
      }

      setTickers(res.data.content)
    } catch (err) {
      console.log(err)
      return
    }
  }

  // clean the input
  const resetInputs = () => {
    setRangeError({})
    setSelectedInputValues({})
  }

  // store the input to json way for api body payload
  const storedInputData = () => {
    let body = {
      filters: {},
      range_filters: [],
    }
    if (selectedInputValues.sector) {
      body.filters['sector'] = selectedInputValues.sector
    }
    if (selectedInputValues.industry) {
      body.filters['industry'] = selectedInputValues.industry
    }
    if (selectedInputValues.market_caps) {
      body.filters['market_caps'] = selectedInputValues.market_caps
    }
    if (
      selectedInputValues.cyclical &&
      selectedInputValues.cyclical !== 'null'
    ) {
      if (selectedInputValues.cyclical === 'true') {
        body.filters['cyclical'] = true
      } else {
        body.filters['cyclical'] = false
      }
    }

    //default value for forecast data
    let forecast_data = {
      direction: 'Both',
      percentage: 0,
      period: 'All',
    }
    if (selectedInputValues.duration) {
      forecast_data['period'] = selectedInputValues.duration
    }
    if (selectedInputValues.change) {
      forecast_data['percentage'] = selectedInputValues.change
    }
    if (selectedInputValues.trend) {
      forecast_data['direction'] = selectedInputValues.trend
    }

    let obj = {}
    obj['forecast_data'] = forecast_data
    obj['range'] = []
    obj['ready'] = true
    obj['type'] = 'Forecast'
    body.range_filters.push(obj)

    // if volume existed
    if (selectedInputValues.volume) {
      body.range_filters.push({
        range: [
          selectedInputValues.volume['min'] || 0,
          selectedInputValues.volume['max'] || 0,
        ],
        range_type: 'range',
        ready: true,
        type: 'Volume (10D AVG)',
      })
    }

    if (selectedInputValues.beta) {
      body.range_filters.push({
        range: [
          selectedInputValues.beta['min'] || 0,
          selectedInputValues.beta['max'] || 0,
        ],
        range_type: 'range',
        ready: true,
        type: 'Beta',
      })
    }

    if (selectedInputValues.daily_volume) {
      body.range_filters.push({
        range: [
          selectedInputValues.daily_volume['min'] || 0,
          selectedInputValues.daily_volume['max'] || 0,
        ],
        range_type: 'range',
        ready: true,
        type: 'Daily Volume',
      })
    }

    if (selectedInputValues['9_day_rsi']) {
      body.range_filters.push({
        range: [
          selectedInputValues['9_day_rsi']['min'] || 0,
          selectedInputValues['9_day_rsi']['max'] || 0,
        ],
        range_type: 'range',
        ready: true,
        type: '9 Day RSI',
      })
    }

    if (selectedInputValues['14_day_rsi']) {
      body.range_filters.push({
        range: [
          selectedInputValues['14_day_rsi']['min'] || 0,
          selectedInputValues['14_day_rsi']['max'] || 0,
        ],
        range_type: 'range',
        ready: true,
        type: '14 Day RSI',
      })
    }

    if (selectedInputValues['ema_3_10']) {
      body.range_filters.push({
        choice: selectedInputValues['ema_3_10'],
        range: [],
        range_type: 'choice',
        ready: true,
        type: 'EMA-3/10',
      })
    }

    if (selectedInputValues['ema_8_20']) {
      body.range_filters.push({
        choice: selectedInputValues['ema_8_20'],
        range: [],
        range_type: 'choice',
        ready: true,
        type: 'EMA-8/20',
      })
    }

    if (selectedInputValues.dividend_yield) {
      body.range_filters.push({
        range: [
          selectedInputValues.dividend_yield['min'] || 0,
          selectedInputValues.dividend_yield['max'] || 0,
        ],
        range_type: 'range',
        ready: true,
        type: 'Dividend Yield',
      })
    }

    if (selectedInputValues['p/e_ratio']) {
      body.range_filters.push({
        range: [
          selectedInputValues['p/e_ratio']['min'] || 0,
          selectedInputValues['p/e_ratio']['max'] || 0,
        ],
        range_type: 'range',
        ready: true,
        type: 'PE Ratio',
      })
    }

    return body
  }

  // check if range is valid
  const checkRangeIsValid = (min, max, inputId) => {
    // if one of number has value, then go check
    if (min || max) {
      // if undefined or null, covert to 0 and compare
      min = min || 0
      max = max || 0

      if (min >= max) {
        setRangeError((prevState) => ({
          ...prevState,
          [inputId]: true, // Update the selected value for the speci fic dropdown
        }))
        // return
      } else {
        setRangeError((prevState) => ({
          ...prevState,
          [inputId]: false, // Update the selected value for the speci fic dropdown
        }))
      }
    } else {
      // if both to null or undfined, go with default , means no one enter before, or user clean both input
      setRangeError((prevState) => ({
        ...prevState,
        [inputId]: false, // Update the selected value for the speci fic dropdown
      }))
    }
  }

  const handleInputChange = (event, inputId, selectedValue, type = '') => {
    // event.preventDefault()
    // event.stopPropagation()

    if (type === 'range') {
      checkRangeIsValid(selectedValue[0], selectedValue[1], inputId)

      setSelectedInputValues((prevState) => ({
        ...prevState,
        [inputId]: {
          min: selectedValue[0],
          max: selectedValue[1],
        }, // Update the selected value for the speci fic dropdown
      }))
    } else {
      setSelectedInputValues((prevState) => ({
        ...prevState,
        [inputId]: selectedValue, // Update the selected value for the specific dropdown
      }))
    }
  }

  const DropdownComponent = (inputID, labelName, list) => {
    return (
      <div
        className={`dropdown-container ${
          selectedInputValues[inputID] ? 'dropdown-container-active' : ''
        }`}
      >
        <span className='p-float-label'>
          <Dropdown
            inputId={inputID}
            value={selectedInputValues[inputID]}
            onChange={(e) => handleInputChange(e, inputID, e.value)}
            options={list}
            optionLabel='label'
            // virtualScrollerOptions={{ itemSize: 18 }}
            className='dropdown-input'
          />
          <label htmlFor={inputID}>{labelName}</label>
        </span>
      </div>
    )
  }

  const RadioButtonComponent = (inputID, singleList, icon = {}) => {
    return (
      <div>
        <RadioButton
          inputId={inputID}
          value={singleList.value}
          onChange={(e) => handleInputChange(e, inputID, e.value)}
          checked={selectedInputValues[inputID] === singleList.value}
        />
        <label htmlFor={inputID} className='ml-2 single-raido-button'>
          {icon} {singleList.label}
        </label>
      </div>
    )
  }

  const RangeInputComponent = (inputID, title) => {
    return (
      <>
        <p>{title} Range</p>
        <div className='range-container'>
          <span className='p-float-label range-input'>
            <InputNumber
              value={selectedInputValues[inputID]?.min}
              onValueChange={(e) =>
                handleInputChange(
                  e,
                  inputID,
                  [e.value, selectedInputValues[inputID]?.max],
                  'range'
                )
              }
              min={0}
            />
            <label>Min</label>
          </span>

          <div>-</div>

          <span className='p-float-label range-input'>
            <InputNumber
              value={selectedInputValues[inputID]?.max}
              onValueChange={(e) =>
                handleInputChange(
                  e,
                  inputID,
                  [selectedInputValues[inputID]?.min, e.value],
                  'range'
                )
              }
              min={0}
            />
            <label>Max</label>
          </span>
        </div>
      </>
    )
  }

  const CustomInputRangeComponent = (inputId, inputRef, labelName) => {
    return (
      <>
        <div className='single-range-input'>
          <span
            className={`p-float-label p-input-icon-right ${
              selectedInputValues[inputId]?.max ||
              selectedInputValues[inputId]?.min
                ? 'input-active'
                : ''
            }`}
          >
            <i className='pi pi-chevron-down' />
            <InputText
              value={
                selectedInputValues[inputId]?.min ||
                selectedInputValues[inputId]?.max
                  ? `Min ${selectedInputValues[inputId]?.min || 0} - Max ${
                      selectedInputValues[inputId]?.max || 0
                    }`
                  : ''
              }
              readOnly
              onClick={(e) => inputRef.current.toggle(e)}
            />
            <label>{labelName}</label>
          </span>
          {rangeError[inputId] && (
            <span className='error-message'>{ERROR_MSG}</span>
          )}
        </div>

        {/* <Button
                label='Volume (10D Avg)'
                icon='pi pi-chevron-down'
                iconPos='right'
                onClick={(e) => volumeRef.current.toggle(e)}
              /> */}
      </>
    )
  }

  // reorder of columns of table
  // const onRowReorder = (e) => {
  //   let tempData = tickers

  //   tempData.tickers = e.value

  //   console.log(tempData)

  //   setTickers(tempData)
  // }

  return (
    <>
      {pageLoading ? (
        <Loader logoLoader={true} />
      ) : (
        <div className='screener-body-content'>
          <section className='fundamentals-section'>
            <p>Fundamentals</p>
            <div className='filter-options-container'>
              {DropdownComponent('sector', 'Sector', sectorsLists)}
              {DropdownComponent('industry', 'Industry', industryLists)}
              {DropdownComponent('market_caps', 'Market Cap', marketCapLists)}
              {DropdownComponent('cyclical', 'Cyclical', cyclicalLists)}
            </div>
          </section>

          <section className='quotes-section'>
            <p>Quotes</p>
            <div className='filter-options-container'>
              {CustomInputRangeComponent(
                'volume',
                volumeRef,
                'Volume (10D Avg)'
              )}
              <OverlayPanel ref={volumeRef}>
                {RangeInputComponent('volume', 'Volume')}
              </OverlayPanel>

              {CustomInputRangeComponent('beta', betaRef, 'Beta')}
              <OverlayPanel ref={betaRef}>
                {RangeInputComponent('beta', 'Beta')}
              </OverlayPanel>

              {CustomInputRangeComponent(
                'daily_volume',
                dailyVolumeRef,
                'Daily Volume'
              )}

              <OverlayPanel
                ref={dailyVolumeRef}
                className='screener-panel-container'
              >
                {RangeInputComponent('daily_volume', 'Daily Volume')}
              </OverlayPanel>
            </div>
          </section>

          <section className='technicals-section'>
            <p>Technicals</p>
            <div className='filter-options-container'>
              {CustomInputRangeComponent('9_day_rsi', nineDaysRef, '9 Day RSI')}

              <OverlayPanel ref={nineDaysRef}>
                {RangeInputComponent('9_day_rsi', '9 Day RSI')}
              </OverlayPanel>

              {CustomInputRangeComponent(
                '14_day_rsi',
                fourteenDaysRef,
                '14 Day RSI'
              )}

              <OverlayPanel ref={fourteenDaysRef}>
                {RangeInputComponent('14_day_rsi', '14 Day RSI')}
              </OverlayPanel>

              {DropdownComponent('ema_3_10', 'EMA 3/10', EMA3Lists)}
              {DropdownComponent('ema_8_20', 'EMA 8/20', EMA8Lists)}
            </div>
          </section>

          <section className='financials-section'>
            <p>Financials</p>
            <div className='filter-options-container'>
              {CustomInputRangeComponent(
                'dividend_yield',
                dividendRef,
                'Dividend Yield'
              )}
              <OverlayPanel ref={dividendRef}>
                {RangeInputComponent('dividend_yield', 'Dividend Yield')}
              </OverlayPanel>

              {CustomInputRangeComponent('p/e_ratio', PERatioRef, 'P/E Ratio')}
              <OverlayPanel ref={PERatioRef}>
                {RangeInputComponent('p/e_ratio', 'P/E Ratio')}
              </OverlayPanel>
            </div>
          </section>

          <section className='forecasts-section'>
            <p>Forecasts</p>

            <div className='filter-options-container'>
              <span
                className={`p-float-label p-input-icon-right ${
                  selectedInputValues['duration'] &&
                  selectedInputValues['change'] &&
                  selectedInputValues['trend']
                    ? selectedInputValues?.['duration'] !== 'Both' &&
                      selectedInputValues?.['change'] !== '0' &&
                      selectedInputValues?.['trend'] !== 'All'
                      ? 'input-active'
                      : ''
                    : ''
                }`}
              >
                <i className='pi pi-chevron-down' />
                <InputText
                  value={
                    selectedInputValues?.['duration'] &&
                    selectedInputValues?.['change'] &&
                    selectedInputValues?.['trend']
                      ? selectedInputValues?.['duration'] !== 'Both' &&
                        selectedInputValues?.['change'] !== '0' &&
                        selectedInputValues?.['trend'] !== 'All'
                        ? `${selectedInputValues['duration']} | >=${selectedInputValues?.['change']}% | ${selectedInputValues?.['trend']}`
                        : ''
                      : ''
                  }
                  readOnly
                  onClick={(e) => priceRef.current.toggle(e)}
                />
                <label>Price</label>
              </span>

              <OverlayPanel ref={priceRef}>
                <div className='radio-group-container'>
                  <p>Durations</p>

                  <div className='radio-buttons'>
                    {durationsOptions?.map((singleList, index) => {
                      return (
                        <div key={index} className='single-radio-button'>
                          <RadioButton
                            inputId={'duration'}
                            value={singleList.value}
                            onChange={(e) =>
                              handleInputChange(e, 'duration', e.value)
                            }
                            checked={
                              selectedInputValues['duration'] ===
                              singleList.value
                            }
                          />
                          <label
                            htmlFor={'duration'}
                            className='ml-2 single-raido-button'
                          >
                            {singleList.label}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>

                <div className='radio-group-container'>
                  <p>Percentage</p>
                  <div className='radio-buttons'>
                    {changesOptions?.map((singleList, index) => {
                      return (
                        <div key={index} className='single-radio-button'>
                          <RadioButton
                            inputId={'change'}
                            value={singleList.value}
                            onChange={(e) =>
                              handleInputChange(e, 'change', e.value)
                            }
                            checked={
                              selectedInputValues['change'] === singleList.value
                            }
                          />
                          <label
                            htmlFor={'change'}
                            className='ml-2 single-raido-button'
                          >
                            {singleList.label}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>

                <div className='radio-group-container'>
                  <p>Trend</p>

                  <div className='radio-buttons'>
                    {trendsOptions?.map((singleList, index) => {
                      return (
                        <div key={index} className='single-radio-button'>
                          <RadioButton
                            inputId={'trend'}
                            value={singleList.value}
                            onChange={(e) =>
                              handleInputChange(e, 'trend', e.value)
                            }
                            checked={
                              selectedInputValues['trend'] === singleList.value
                            }
                          />
                          <label
                            htmlFor={'trend'}
                            className='ml-2 single-raido-button'
                          >
                            <i
                              className={`pi ${
                                singleList.value === 'uptrend'
                                  ? 'pi-arrow-up-right'
                                  : 'pi-arrow-down-right'
                              } `}
                              style={
                                singleList.value === 'uptrend'
                                  ? { color: 'green', paddingRight: '0.4rem' }
                                  : { color: 'red', paddingRight: '0.4rem' }
                              }
                            ></i>
                            {singleList.label}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </OverlayPanel>
            </div>
          </section>

          {/* table of content */}
          <section className='table-section'>
            <div className='table-title-container'>
              <p>RESULTS ({tickers?.tickers?.length || 0})</p>
              <Button
                label='Reset Filter'
                icon='pi pi-filter-slash'
                iconPos='right'
                onClick={() => resetInputs()}
                className='p-button-danger'
              />
            </div>

            <DataTable
              value={tickers?.tickers}
              paginator={tickers?.tickers?.length > 0}
              scrollable
              // tableStyle={{ border: '1px solid #ccc' }}
              rows={10}
              onRowClick={(singleTicker) =>
                history.push(`/Dashboard/Trade/${singleTicker.data.symbol}`)
              }
              // reorderableColumns
              // onRowReorder={onRowReorder}
            >
              {/* <Column rowReorder style={{ width: '3em' }} /> */}
              <Column
                field='symbol'
                body={(rowData) => rowData.symbol || '--'}
                header='Symbol'
                sortable
                // frozen
                style={{ width: '12%' }}
              ></Column>
              <Column
                field='price'
                body={(rowData) => rowData.price || '--'}
                header='Last'
                sortable
                style={{ width: '12%' }}
              ></Column>
              <Column
                field='market_cap_string'
                body={(rowData) => rowData.market_cap_string || '--'}
                header='Market Cap'
                sortable
                sortField='marketCap'
                style={{ width: '12%' }}
              ></Column>
              <Column
                field='volume_string'
                body={(rowData) =>
                  rowData.volume_string === 'N/A' || !rowData.volume_string
                    ? '--'
                    : rowData.volume_string
                }
                header='Volume'
                sortable
                sortField='volume'
                style={{ width: '12%' }}
              ></Column>
              <Column
                body={(rowData) => (
                  <div className='forecast-container'>
                    {rowData.forecast_3m ? (
                      <>
                        <span
                          className={
                            rowData.forecast_3m_percentage < 0
                              ? 'decrease'
                              : 'increase'
                          }
                        >
                          {`${rowData.forecast_3m_percentage?.toFixed(2)}%`}
                        </span>
                        <span>{rowData.forecast_3m}</span>
                      </>
                    ) : (
                      '--'
                    )}
                  </div>
                )}
                header='Forecast (3M)'
                sortable
                sortField='forecast_3m_percentage'
                style={{ width: '12%' }}
              ></Column>
              <Column
                body={(rowData) => (
                  <div className='forecast-container'>
                    {rowData.forecast_6m ? (
                      <>
                        <span
                          className={
                            rowData.forecast_6m_percentage < 0
                              ? 'decrease'
                              : 'increase'
                          }
                        >{`${rowData.forecast_6m_percentage?.toFixed(
                          2
                        )}%`}</span>
                        <span>{rowData.forecast_6m}</span>
                      </>
                    ) : (
                      '--'
                    )}
                  </div>
                )}
                header='Forecast (6M)'
                sortable
                sortField='forecast_6m_percentage'
                style={{ width: '12%' }}
              ></Column>
              <Column
                body={(rowData) => (
                  <div className='forecast-container'>
                    {rowData.forecast_1y ? (
                      <>
                        <span
                          className={
                            rowData.forecast_1y_percentage < 0
                              ? 'decrease'
                              : 'increase'
                          }
                        >{`${rowData.forecast_1y_percentage?.toFixed(
                          2
                        )}%`}</span>
                        <span>{rowData.forecast_1y}</span>
                      </>
                    ) : (
                      '--'
                    )}
                  </div>
                )}
                header='Forecast (1Y)'
                sortable
                sortField='forecast_1y_percentage'
                style={{ width: '12%' }}
              ></Column>
              <Column
                body={(rowData) => (
                  <div className='forecast-container'>
                    {rowData.forecast_2y ? (
                      <>
                        <span
                          className={
                            rowData.forecast_2y_percentage < 0
                              ? 'decrease'
                              : 'increase'
                          }
                        >{`${rowData.forecast_2y_percentage?.toFixed(
                          2
                        )}%`}</span>
                        <span>{rowData.forecast_2y}</span>
                      </>
                    ) : (
                      '--'
                    )}
                  </div>
                )}
                header='Forecast (2Y)'
                sortable
                sortField='forecast_2y_percentage'
                style={{ width: '12%' }}
              ></Column>
            </DataTable>
          </section>
        </div>
      )}
    </>
  )
}

export default ScreenerPage
