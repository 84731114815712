import React, { Component } from 'react'
import { TailSpin } from 'react-loader-spinner'
import RecurringBuyForm from '../../RecurringBuyForm'
import RecurringBuyDialog from '../../RecurringBuyDialog'
import '../../../../scss/pages/account-page/non-product-account-info/NonProdRecurBuys.scss'
import editIcon from '../../../../assets/edit_icon.svg'

//BETTER POP UP LIBRARY
import { ConfirmDialog } from 'primereact/confirmdialog'
import Loader from '../Loader'

export default class NonProdRecurBuys extends Component {
  state = {
    showAllRecurringList: false,

    //delete recurring buy modal
    showDeleteModal: false,
    selectedRecurBuy: null,
    //edit recurring buy modal
    showEditModal: false,
    selectedEditRecurBuy: null,
  }

  toggleDeleteModal = (sym) => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
      selectedRecurBuy: sym,
    })
  }

  toggleEditModal = (sym) => {
    this.setState({
      showEditModal: !this.state.showEditModal,
      selectedEditRecurBuy: sym,
    })
  }

  closeAndResetEditModal = () => {
    // if (
    //   this.props.editRecBuyStatus &&
    //   this.props.editRecBuyStatus === "success"
    // )
    this.props.resetEditRecurBuyModal()
    this.toggleEditModal()
  }

  closeAndResetDeleteModal = () => {
    // if (
    //   this.props.deleteRecurBuyStatus &&
    //   this.props.deleteRecurBuyStatus === "success"
    // )
    this.props.resetDeleteModal()
    this.toggleDeleteModal()
  }

  showMessage = () => {
    if (this.props.deleteRecurBuyStatus === 'success') {
      return (
        <div>
          <p style={{ textAlign: 'center' }}>
            {this.state.selectedRecurBuy} {this.props.t('removedsuccessfully')}
          </p>
        </div>
      )
    } else if (this.props.deleteRecurBuyStatus === 'error') {
      return (
        <div>
          <p style={{ textAlign: 'center', color: 'red' }}>
            {this.props.t('somethingwentwrong')}
          </p>
        </div>
      )
    } else {
      return (
        <div style={{ width: '10%', margin: 'auto' }}>
          <Loader logoLoader={false} />
        </div>
      )
    }
  }

  deleteRecurModal = () => {
    return (
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: 'auto',
          backgroundColor: 'rgba(0,0,0, 0.5)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: '25%',
            right: '25%',
            top: '25%',
            bottom: '25%',
            margin: 'auto',
            borderRadius: '20px',
            background: 'white',
            padding: '15px',
          }}
        >
          <div
            style={{
              width: '10%',
              justifyContent: 'right',
              display: 'flex',
              margin: 'auto',
              marginRight: '0',
              textAlign: 'right',
              background: 'transparent',
              border: 'none',
              padding: '1px',
            }}
          >
            <p
              onClick={() => this.closeAndResetDeleteModal(null)}
              style={{ fontSize: '25px', cursor: 'pointer' }}
            >
              X
            </p>
          </div>
          <p style={{ textAlign: 'center', fontSize: '25px' }}>
            {this.props.t('areyousureyouwanttocancelrecurringbuyof')}{' '}
            {this.state.selectedRecurBuy}?
          </p>
          {this.props.deleteRecurBuyStatus ? (
            this.showMessage()
          ) : (
            <div
              style={{
                margin: 'auto',
                justifyContent: 'space-evenly',
                display: 'flex',
              }}
            >
              <button
                style={{
                  background: '#3F46F6',
                  borderRadius: '25px',
                  padding: '10px 15px',
                  color: 'white',
                  fontSize: '15px',
                }}
                onClick={() =>
                  this.props.deleteRec(this.state.selectedRecurBuy)
                }
              >
                {this.props.t('yes')}
              </button>
              <button
                style={{
                  background: '#3F46F6',
                  borderRadius: '25px',
                  padding: '10px 15px',
                  color: 'white',
                  fontSize: '15px',
                }}
                onClick={() => this.toggleDeleteModal(null)}
              >
                {this.props.t('no')}
              </button>
            </div>
          )}
        </div>
      </div>
    )
  }

  displayRecurringBuys() {
    const recurringBuys = this.props.accountTransactionsData.recurringBuys
    if (recurringBuys.length > 0) {
      return (
        <div className='non-prod-recurring-buys-container'>
          <p className='non-prod-recurring-buys-header'>
            {this.props.t('recurringbuys')}
          </p>
          <div className='non-prod-recurring-buys-table'>
            <div
              style={{ width: '100%', display: 'flex', marginBottom: '15px' }}
            >
              <div
                style={{
                  width: '20%',
                  borderBottom: '1px solid rgba(128,128,128,.5)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p className='recur-buys-table-header'>
                  {this.props.t('symbol')}
                </p>
              </div>
              <div
                style={{
                  width: '20%',
                  borderBottom: '1px solid rgba(128,128,128,.5)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p className='recur-buys-table-header'>
                  {this.props.t('nextbuy')}
                </p>
              </div>
              <div
                style={{
                  width: '20%',
                  borderBottom: '1px solid rgba(128,128,128,.5)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p className='recur-buys-table-header'>
                  {this.props.t('amount')}
                </p>
              </div>
              <div
                style={{
                  width: '20%',
                  borderBottom: '1px solid rgba(128,128,128,.5)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p className='recur-buys-table-header'>
                  {this.props.t('stop')}
                </p>
              </div>
              <div
                style={{
                  width: '20%',
                  borderBottom: '1px solid rgba(128,128,128,.5)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p className='recur-buys-table-header'>
                  {this.props.t('edit')}
                </p>
              </div>
            </div>

            <div style={{ maxHeight: '20vh', overflow: 'auto' }}>
              {recurringBuys.map((buy) => {
                return this.displayRecurringBuysRow(buy)
              })}
            </div>
          </div>
        </div>
      )
    } else return null
  }

  displayRecurringBuysRow(buy) {
    return (
      <div style={{ width: '100%', display: 'flex' }} key={buy.ticker}>
        <div style={{ width: '20%' }}>
          <p style={{ marginBottom: '0', color: 'black', fontWeight: 'bold' }}>
            {/* {buy.ticker} */}
            {buy.ticker.split(':')[0]}
          </p>
        </div>
        <div style={{ width: '20%' }}>
          <p style={{ color: 'black' }}>{buy.next_purchase_date}</p>
        </div>
        <div style={{ width: '20%' }}>
          <p style={{ color: 'black' }}>
            {this.props.formatCurrency(buy.amount)}
          </p>
        </div>
        <div style={{ width: '20%' }}>
          <button
            style={{
              border: '1px solid red',
              borderRadius: '25px',
              color: 'white',
              width: '25px',
              background: 'transparent',
            }}
            onClick={() => this.toggleDeleteModal(buy.ticker)}
          >
            <span
              style={{ color: 'red', fontWeight: 'bold', fontSize: '15px' }}
            >
              -
            </span>
          </button>
        </div>
        <div style={{ width: '20%' }}>
          {/* <p
            style={{ color: "black", fontWeight: "bold", cursor: "pointer" }}
            onClick={() => this.toggleEditModal(buy.ticker)}
          >
            {"EDIT"}
          </p> */}
          <img
            style={{ cursor: 'pointer' }}
            onClick={() => this.toggleEditModal(buy.ticker)}
            src={editIcon}
            height='20px'
            width='20px'
          ></img>
        </div>
      </div>
    )
  }
  accept = () => {
    this.props.deleteRec(this.state.selectedRecurBuy)
  }
  reject = () => {
    this.closeAndResetDeleteModal(null)
  }

  message = () => {
    if (this.props.deleteRecurBuyStatus) {
      return this.showMessage()
    } else {
      return `${this.props.t('areyousureyouwanttodelete')} ${
        this.state.selectedRecurBuy
      }${"'"}s ${this.props.t('recurringbuy')}?`
    }
  }

  render() {
    if (this.props.t) {
      return (
        <div style={{ width: this.props.isSmallScreen ? '100%' : '100%' }}>
          {this.displayRecurringBuys()}
          <ConfirmDialog
            visible={this.state.showDeleteModal}
            onHide={() => this.closeAndResetDeleteModal(false)}
            // message="Are you sure you want to proceed?"
            message={this.message}
            header={
              <p style={{ textAlign: 'center' }}>
                {this.props.t('confirmation')}
              </p>
            }
            accept={this.accept}
            // reject={this.closeAndResetDeleteModal}
          />
          <RecurringBuyDialog
            accounts={this.props.accounts}
            selectedAccount={this.props.selectedAccount}
            selectedEditRecurBuy={this.state.selectedEditRecurBuy}
            toggleEditModal={this.toggleEditModal}
            isSmallScreen={this.props.isSmallScreen}
            user={this.props.user}
            setRecBuy={this.props.setRecBuy}
            showEditModal={this.state.showEditModal}
            accountTransactionsData={this.props.accountTransactionsData}
            t={this.props.t}
          />
        </div>
      )
    }
  }
}
