import React, { useEffect } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import LinkPlaidCustom from '../components/components/LinkPlaidCustom'
import '../scss/components/LinkedAccounts.scss'

const LinkedAccounts = (props) => {
  console.log(props?.plaidAcctsInfo)

  useEffect(() => {
    props?.getPlaidAcctInfo()
  }, [])

  const onSuccess = async () => {
    await props?.checkUserPlaidLinkedAcct()
    await props?.getPlaidAcctInfo()
  }

  let groupedData = props?.plaidAcctsInfo?.reduce((result, item) => {
    const { type } = item

    // Check if there's already a group for this type, if not, create one
    if (!result[type]) {
      result[type] = []
    }

    // Push the item to the corresponding type's array
    result[type].push(item)

    return result
  }, {})

  // covert money to us format with 2 decimal places and $ sign
  const changedMoneyToUSFormat = (amount) => {
    const formattedNumber = amount?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    })

    return `${formattedNumber ? formattedNumber : '$0.00'}`
  }

  const confirmAccts = () => {
    props?.closeModal()
  }

  return (
    <Dialog
      visible={props?.showLinkedAccountsInfo}
      closable={false} // no "x" button
      className='linked-accounts-dialog'
    >
      <h1 style={{ color: 'white' }}>Congrats!</h1>
      <p>We've successfully linked the following accounts.</p>

      {props?.plaidAcctsInfo &&
        Object.entries(groupedData)?.map(([key, accts]) => (
          <div key={key} className='same-accounts-container'>
            <p className='header'>
              {key === 'depository'
                ? 'Bank Accounts'
                : key === 'credit'
                ? 'Credit Cards'
                : `${key?.charAt(0)?.toUpperCase()}${key?.slice(1)}s`}
            </p>
            {accts?.map((singleAcct, index) => {
              return (
                <div key={index} className='single-type-account'>
                  <p className='acct-info'>
                    {singleAcct?.inst_name} {singleAcct?.mask}
                    <span>{changedMoneyToUSFormat(singleAcct?.current)}</span>
                  </p>
                  {singleAcct?.sub_type && (
                    <span className='subtype-name'>{singleAcct?.sub_type}</span>
                  )}
                </div>
              )
            })}
          </div>
        ))}

      <LinkPlaidCustom
        user={props?.user}
        getAllAccountInfo={props?.getAllAccountInfo}
        isBank={true}
        reLink={false}
        t={props?.t}
        onSuccess={onSuccess}
        // onError={onError}
      >
        <div className='add-more-account-container'>
          <p>Link more accounts</p>
        </div>
      </LinkPlaidCustom>

      <span onClick={() => confirmAccts()}>This looks complete</span>
    </Dialog>
  )
}

export default LinkedAccounts
