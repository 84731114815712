import React, { useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { setUsername } from '../../store/slice/TestSlice' // Import your reducer action

// ! class compinent using react-redux
class ComponentThree extends React.Component {
  handleUsernameChange = () => {
    // Dispatch the setUsername action
    this.props.setUsername('EXAMPLE')
  }

  render() {
    return (
      <div className='ComponentThree'>
        <h5>3. Class Component</h5>
        <p>name: {this.props.username}</p>

        <button onClick={this.handleUsernameChange}>Change Username</button>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  username: state.test.testname, // Add the username to the props
})

const mapDispatchToProps = {
  setUsername,
}

export default connect(mapStateToProps, mapDispatchToProps)(ComponentThree)

// ! function compinent using react-redux
// const Testing = () => {
//   const dispatch = useDispatch()
//   const username = useSelector((state) => state.test.testname)

//   const handleUsernameChange = () => {
//     // Dispatch the setUsername action
//     dispatch(setUsername('EXAMPLE'))
//   }

//   return (
//     <div>
//       testing name: {username}
//       <button onClick={handleUsernameChange}>Change Username</button>
//     </div>
//   )
// }

// export default Testing
