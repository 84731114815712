import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { removeFromWatchlist } from '../../services/userServices'
import EditIconActive from '../../assets/edit_active_icon.svg'
import '../../scss/pages/EditWatchListTablePage.scss'
import BASEURL from '../../components/Endpoint'
import axios from 'axios'

import { Button } from 'primereact/button'
import { ConfirmDialog } from 'primereact/confirmdialog'

const EditWatchListTablePage = (props) => {
  const [tickers, setTickers] = useState(props?.tableData)
  const [isConfirmedModal, setIsConfirmedModal] = useState(false)
  const [isConfirmButtonClicked, setIsConfirmButtonClicked] = useState(false)

  const [deleteSingleTickerModal, setDeleteSingleTickerModal] = useState(false)
  const [isSingleTickerConfirmed, setIsSingleTickerConfirmed] = useState(false)
  const [selectedDeleteTicker, setSelectedDeleteTicker] = useState('')

  useEffect(() => {
    setTickers(props?.tableData)
  }, [props?.tableData])

  const remove = async (selected, symbol) => {
    setIsSingleTickerConfirmed(true)
    const res = await removeFromWatchlist(
      [selected],
      [symbol],
      props.user.idToken.jwtToken
    )
    console.log(res)
    if (res) {
      await props.refreshWl()
      setIsSingleTickerConfirmed(false)
    }
  }

  // reorder of columns of table
  const onRowReorder = async (e) => {
    let tempData = props?.tableData

    tempData = e.value

    setTickers(tempData)

    let reorderListSymbol = []

    for (let key in tempData) {
      reorderListSymbol.push(tempData[key].symbol)
    }

    await updateReorderList(reorderListSymbol)
  }

  const updateReorderList = async (reorderListSymbol) => {
    try {
      const url = `${BASEURL}/mobile/reorder/watchlist`

      const body = {
        names: [props.selectedWatchlistGroup],
        add: [...reorderListSymbol],
      }
      const header = {
        withCredentials: false,
        headers: {
          Authorization: 'Bearer ' + props.user.idToken.jwtToken,
        },
      }
      const res = await axios.post(url, body, header)

      if (res) {
        await props.refreshWl()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const deleteWatchlist = async (watchlistName) => {
    try {
      setIsConfirmButtonClicked(true)
      const url = `${BASEURL}/mobile/reorder/watchlist`

      const body = {
        names: [watchlistName],
        add: [],
      }
      const header = {
        withCredentials: false,
        headers: {
          Authorization: 'Bearer ' + props.user.idToken.jwtToken,
        },
      }
      const res = await axios.post(url, body, header)
      console.log(res)

      if (res) {
        await props.refreshWl()
        setIsConfirmButtonClicked(false)
      }
      // return res?.data
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='watchlist-edit-container'>
      <DataTable
        value={tickers}
        // paginator={props?.tableData?.length > 0}
        scrollable
        // rows={10}
        onRowReorder={onRowReorder}
      >
        <Column
          body={(rowData) => (
            <div className='forecast-container'>
              <i
                className='pi pi-minus-circle'
                style={{
                  color: '#D32F2F',
                  paddingRight: '1rem',
                }}
                onClick={() => {
                  console.log('click')
                  setSelectedDeleteTicker(rowData.symbol)
                  setDeleteSingleTickerModal(true)
                }}
              ></i>
              {rowData.symbol || '--'}
            </div>
          )}
          header='Symbol'
          frozen
          style={{ flexBasis: '33%' }}
        ></Column>
        <Column
          field='company'
          body={(rowData) => rowData.company || '--'}
          header='Company Name'
          style={{ flexBasis: '33%' }}
        ></Column>

        <Column
          style={{ flexBasis: '33%', justifyContent: 'flex-end' }}
          header={
            <img
              src={EditIconActive}
              onClick={() => {
                props?.toggleEditTable(false)
              }}
              draggable='false'
              style={{ cursor: 'pointer' }}
            />
          }
          rowReorder
        />
      </DataTable>
      <div>
        {props?.selectedWatchlistGroup !== 'My Watchlist' && (
          <Button
            label='Delete Watchlist'
            className='p-button-danger p-button-text'
            onClick={() => setIsConfirmedModal(true)}
          />
        )}
      </div>

      <ConfirmDialog
        visible={isConfirmedModal}
        onHide={() => setIsConfirmedModal(false)}
        message={`Are you sure you want to delete watchlist '${props?.selectedWatchlistGroup}'?`}
        header='Confirmation'
        icon='pi pi-exclamation-triangle'
        accept={() => {
          deleteWatchlist(props?.selectedWatchlistGroup)
        }}
        reject={() => setIsConfirmedModal(false)}
        acceptLabel={
          isConfirmButtonClicked ? (
            <i
              className='pi pi-spinner pi-spin'
              style={{ marginRight: '5px' }}
            />
          ) : (
            'Confirm'
          )
        }
        rejectLabel='Decline'
        className='delete-watchlist-confirm-dialog'
      />

      <ConfirmDialog
        visible={deleteSingleTickerModal}
        onHide={() => setDeleteSingleTickerModal(false)}
        message={`Are you sure you want to delete ticker '${selectedDeleteTicker}'?`}
        header='Confirmation'
        icon='pi pi-exclamation-triangle'
        accept={() => {
          remove(props?.selectedWatchlistGroup, selectedDeleteTicker)
        }}
        reject={() => setDeleteSingleTickerModal(false)}
        acceptLabel={
          isSingleTickerConfirmed ? (
            <>
              {console.log(isSingleTickerConfirmed)}
              <i
                className='pi pi-spinner pi-spin'
                style={{ marginRight: '5px' }}
              />
            </>
          ) : (
            'Confirm'
          )
        }
        rejectLabel='Decline'
        className='delete-watchlist-confirm-dialog'
      />
    </div>
  )
}

export default EditWatchListTablePage
