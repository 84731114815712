import React, { Component } from 'react'
import { Container, Row } from 'react-bootstrap'

class ScheduleADemo extends Component {
  constructor(props) {
    super(props)

    this.toSubmit = this.toSubmit.bind(this)
    this.toGetBack = this.toGetBack.bind(this)

    this.state = {
      isSubmit: false,
      name: ``,
      email: ``,
      phone: ``,
      mailSent: false,
      error: null,
    }
  }

  toSubmit(event) {
    event.preventDefault()
    this.setState({ isSubmit: true })
  }

  toGetBack() {
    this.setState({ isSubmit: false })
  }

  render() {
    return (
      <div>
        {this.state.isSubmit ? (
          <Container className='FreeTrial-right-submit-container'>
            <p className='FreeTrial-right-submit-text'>
              Thanks for you interests in AllocateRite.
            </p>
            <p className='FreeTrial-right-submit-text'>
              We will be in touch with you soon.
            </p>
            <Row className='FreeTrial-right-getback-row'>
              <input
                className='FreeTrial-right-getback'
                onClick={this.toGetBack}
                type='Submit'
                value='Get Back'
              />
            </Row>
          </Container>
        ) : (
          <Container className='FreeTrial-right-container'>
            <Row>
              <p className='FreeTrial-right-title'>Schedule a Demo Today.</p>
            </Row>

            <Row>
              <p className='FreeTrial-right-subtitle'>
                Fields marked with an{' '}
                <span className='FreeTrial-right-red-spark'>*</span> are
                required.
              </p>
            </Row>

            <Row>
              <span className='FreeTrial-right-field-tag'>Name</span>
              <span className='FreeTrial-right-red-spark'>*</span>
            </Row>

            <Row className='FreeTrial-right-field-input-row'>
              <input
                className='FreeTrial-right-field-input'
                id='demo-name'
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
                type='text'
              />
            </Row>

            <Row>
              <span className='FreeTrial-right-field-tag'>Email</span>
              <span className='FreeTrial-right-red-spark'>*</span>
            </Row>

            <Row className='FreeTrial-right-field-input-row'>
              <input
                className='FreeTrial-right-field-input'
                id='demo-email'
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                type='text'
              />
            </Row>

            <Row>
              <span className='FreeTrial-right-field-tag'>Phone</span>
            </Row>

            <Row className='FreeTrial-right-field-input-row'>
              <input
                className='FreeTrial-right-field-input'
                id='demo-phone'
                value={this.state.phone}
                onChange={(e) => this.setState({ phone: e.target.value })}
                type='text'
              />
            </Row>

            <Row>
              <input
                className='FreeTrial-right-field-submit'
                onClick={(e) => this.toSubmit(e)}
                type='Submit'
              />
            </Row>
          </Container>
        )}
      </div>
    )
  }
}

export default ScheduleADemo
