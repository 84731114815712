import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import FooterPageTitle from '../layout/FooterPageTitle'
import { FaPrint } from 'react-icons/fa'
import Footer from '../PublicFooter'
import Header from '../PublicHeader3'
import MainNavbar from '../components/MainNavBar'

export class Disclosures extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSmallScreen: false,
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener('resize', this.updateWindowDimensions)
    this.updateWindowDimensions()
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }

  apexLinks() {
    return (
      <div>
        {/* <div style={{ paddingBottom: 10 }}>
          <a href='/CryptoAgreement' style={{ color: '#3F46F6', fontSize: 20 }}>
            Crypto Agreement
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='/ApexAccountAgreement'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Apex Account Agreement
          </a>
        </div> */}
        <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/apex-crypto-risk-disclosures.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Apex Risk Disclosure Agreement
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/apex-crypto-user-agreement.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Apex Crypto User Agreement
          </a>
        </div>
      </div>
    )
  }

  IBLinks() {
    return (
      <div style={{ paddingBottom: 10 }}>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3071.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Day Trading Risk Disclosure Statement
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3203.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Interactive Brokers LLC Client Agreement
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form6108.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Agreement Limiting Interactive Brokers' Liability for Decisions and
            Actions of your Independent Financial Advisor.
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form2109.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Form2109
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3074.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Interactive Brokers Order Routing and Payment for Order Flow
            Disclosure
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3230.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Disclosure Regarding Trading of Certificates of Deposit
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form4304.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            REGULATION BEST INTEREST DISCLOSURE
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form6112.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Agreements & Disclosures - Advisor Client Agreement
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form2192.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Interactive Brokers Group Privacy Policy
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3076.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Nasdaq ISE Disclosure for Option Orders Over 500 Contracts
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form4399.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Interactive Brokers Group Cookie Policy
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form9130.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Stock Stop Order Disclosure
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3007.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Interactive Brokers LLC Customer Agreement
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form4009.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            RISK DISCLOSURE STATEMENT
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form4404.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            FINRA Investor Protection Information Resources
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form9490.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Penny Stock Trading Risk Disclosure
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3081.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Notice Regarding USA Patriot Act Section 311
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form4016.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Risks of After-Hours Trading
          </a>
        </div>

        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3089.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            GLOBAL FINANCIAL INFORMATION SERVICES SUBSCRIBER AGREEMENT
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form4024.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Customer Relationship Summary
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form5013.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Certification Regarding Trading Control and Ownership of the Account
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3070.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Interactive Brokers LLC Business Continuity Plan Disclosure
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form3094.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Notice Regarding NFAs BASIC System
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form6105.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Discretionary Trading Authorization/Power Of Attorney For Financial
            Advisor Request To Send Trade Confirmations And Account Statements
            To Advisor
          </a>
        </div>
      </div>
    )
  }

  dwLinks() {
    return (
      <div style={{ paddingBottom: 10 }}>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://legal.drivewealth.com/disclosures-disclaimers'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            DriveWealth Risk Disclosure
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://legal.drivewealth.com/bcp'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            DriveWealth Business Continuity Plan
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://legal.drivewealth.com/form-client-relationship-summary'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            DriveWealth Customer Relationship Summary
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://static1.squarespace.com/static/5d84d0802787ab07bdc5bc7e/t/5ef5ff44e5a2240b8bcae99d/1593179972919/Form+CRD+6-2020++DriveWealth.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Potential Conflicts of Interest
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://legal.drivewealth.com/customer-account-agreement'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            DriveWealth Account Agreement
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://legal.drivewealth.com/terms-of-use'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            DriveWealth Terms of Use
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://legal.drivewealth.com/funding-withdrawal-policies'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            DriveWealth Funding and Withdrawal Policies
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://legal.drivewealth.com/extended-hours-trading-disclosures'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            DriveWealth Extended Hours Trading Disclosures
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://legal.drivewealth.com/sec-rule-606'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            SEC Rule 606
          </a>
        </div>
        {/* <div style={{paddingBottom: 10}}>
                    <a href='' style={{color:'#3F46F6', fontSize:20}}>DriveWealth Clearing Brochure</a>
                </div> */}
      </div>
    )
  }

  govtLinks() {
    return (
      <div style={{ paddingBottom: 10 }}>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='http://www.finra.org/industry/customer-identification-program-notice'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            FINRA Customer ID Program Notice
          </a>
        </div>
      </div>
    )
  }

  fsLinks() {
    return (
      <div style={{ paddingBottom: 20 }}>
        <div style={{ paddingBottom: 10 }}>
          {/* <a href='/PrivacyPolicy' style={{ color: '#3F46F6', fontSize: 20 }}>
            ForeSCITE Privacy Policy
          </a> */}
          <a
            href='/Legal/Investall-Privacy-Policy.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Investall {this.props.t('privacypolicy')}
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/Investall-Terms-of-use.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Investall {this.props.t('termsofuse')}
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          {/* <a
            href='/GeneralDisclaimer'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            ForeSCITE General Disclaimer
          </a> */}
          <a
            href='/Legal/Investall-General-Disclaimer.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Investall General Disclaimer
          </a>
        </div>

        <div style={{ paddingBottom: 10 }}>
          <a href='/Legal/EULA.pdf' style={{ color: '#3F46F6', fontSize: 20 }}>
            EULA
          </a>
        </div>

        <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/Investall-Inc-2023_03_30-Form-ADV-Part-2A.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Investall ADV Part 2
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/Investall-Inc-Form-CRS-2023_03_30.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Investall CRS
          </a>
        </div>
        {/* <div style={{ paddingBottom: 10 }}> */}
        {/* <a href='/EULA' style={{ color: '#3F46F6', fontSize: 20 }}>
            ForeSCITE EULA
          </a> */}
        {/* <a href='/Legal/EULA.pdf' style={{ color: '#3F46F6', fontSize: 20 }}>
            {this.props.t('eula')}
          </a> */}
        {/* </div> */}
        {/* <div style={{ paddingBottom: 10 }}>
          <a
            href='/Low-Priced-Securities-Disclosure'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Public Low Price Securities Disclosure
          </a>
        </div> */}
        {/* <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/ForeSCITE-2021-ADV-Part2A.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            ForeSCITE ADV Part 2
          </a>
        </div> */}
        {/* <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/ForeSCITE-2021-CRS.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            ForeSCITE CRS
          </a>
        </div> */}
        {/* <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/Investall-Inc-2023_03_30-Form-ADV-Part-2A.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Investall ADV Part 2
          </a>
        </div> */}
        {/* <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/Investall-Inc-Form-CRS-2023_03_30.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Investall CRS
          </a>
        </div> */}
        {/* <div style={{paddingBottom: 10}}>
                    <a href='/Legal/OpenToPublicBrokerageAgreement.pdf' style={{color:'#3F46F6', fontSize:20}}>Open to the Public Brokerage Agreement</a>
                </div> */}
        <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/ForeSCITE-Annual-Disclosure-2021.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            2021 Investall Annual Disclosure
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/ForeSCITE-Customer-Relationship-Summary.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Investall Customer Information Brochure
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='/Legal/ForeSCITE-Investment-Advisory-Agreement.pdf'
            style={{ color: '#3F46F6', fontSize: 20 }}
          >
            Investall Investment Advisory Agreement
          </a>
        </div>
      </div>
    )
  }

  faq() {
    return (
      <div style={{ paddingBottom: 20 }}>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://www.allocaterite.com/PrivacyPolicy'
            style={{ color: '#3F46F6' }}
          >
            What is ForeSCITE?
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://www.allocaterite.com/TermsofUse'
            style={{ color: '#3F46F6' }}
          >
            Who is DriveWealth
          </a>
        </div>
        <div style={{ paddingBottom: 10 }}>
          <a
            href='https://www.allocaterite.com/GeneralDisclaimer'
            style={{ color: '#3F46F6' }}
          >
            My accounts and subscription{' '}
          </a>
        </div>
      </div>
    )
  }

  render() {
    //   console.log('small screen?', this.state.isSmallScreen)
    if (this.props.t) {
      return (
        <div style={{ width: '100%' }}>
          {/* <Header
          isSmallScreen={this.state.isSmallScreen}
          user={this.props.user}
          signOut={this.props.signOut}
        /> */}
          <MainNavbar {...this.props} colorChange={true} />
          <div style={{ width: '100%' }}>
            {/* <FooterPageTitle title="ForeSCITE Legal" /> */}
            <div
              style={{
                display: 'flex',
                width: '100%',
                backgroundColor: 'rgb(237,237,237)',
                marginTop: this.state.isSmallScreen ? '' : '75px',
                justifyContent: 'space-between',
                paddingLeft: '20%',
                paddingRight: '20%',
                paddingTop: 40,
                paddingBottom: 40,
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: 40,
                  fontFamily: 'Akkurat-Bold',
                }}
              >
                Disclosures
              </p>
              {/* <FaPrint size={45}  style={{cursor:'pointer'}} onClick={()=>window.print()}/> */}
              <div />
            </div>
            <div
              style={{
                width: '100%',
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div style={{ width: '50%', marginBottom: 50, marginTop: 30 }}>
                {/* <h2 style={{ textAlign: 'center', color: '#3f4174' }}>
                {' '}
                Disclosures
              </h2> */}
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginTop: 20,
                  paddingLeft: '20%',
                  marginBottom: 20,
                }}
              >
                <div
                  style={{
                    width: this.state.isSmallScreen ? '90%' : '40%',
                  }}
                >
                  <h4 style={{ fontFamily: 'Akkurat-Bold' }}>Investall</h4>
                  {this.fsLinks()}
                  <h4 style={{ fontFamily: 'Akkurat-Bold' }}>Regulatory</h4>
                  {this.govtLinks()}

                  {!this.state.isSmallScreen && (
                    <h4 style={{ fontFamily: 'Akkurat-Bold' }}>DriveWealth</h4>
                  )}
                  {!this.state.isSmallScreen && this.dwLinks()}

                  {!this.state.isSmallScreen && (
                    <h4 style={{ fontFamily: 'Akkurat-Bold' }}>Apex</h4>
                  )}
                  {!this.state.isSmallScreen && this.apexLinks()}

                  {this.state.isSmallScreen ? (
                    <h4 style={{ fontFamily: 'Akkurat-Bold' }}>
                      Interactive Brokers
                    </h4>
                  ) : null}
                  {this.state.isSmallScreen ? this.IBLinks() : null}

                  {this.state.isSmallScreen ? (
                    <h4 style={{ fontFamily: 'Akkurat-Bold' }}>DriveWealth</h4>
                  ) : null}
                  {this.state.isSmallScreen ? this.dwLinks() : null}

                  {this.state.isSmallScreen ? (
                    <h4 style={{ fontFamily: 'Akkurat-Bold' }}>Apex</h4>
                  ) : null}
                  {this.state.isSmallScreen ? this.apexLinks() : null}
                </div>
                {this.state.isSmallScreen ? null : (
                  <div style={{ width: '40%' }}>
                    <h4 style={{ fontFamily: 'Akkurat-Bold' }}>
                      Interactive Brokers
                    </h4>
                    {this.IBLinks()}
                    {/* <h4 style={{ fontFamily: 'Akkurat-Bold' }}>DriveWealth</h4>
                    {this.dwLinks()}
                    <h4 style={{ fontFamily: 'Akkurat-Bold' }}>Apex</h4>
                    {this.apexLinks()} */}
                  </div>
                )}
                {/* {this.state.isSmallScreen ? null : (
                <div style={{ width: '30%' }}>
                </div>
              )} */}
              </div>
            </div>
            <Footer t={this.props.t} isSmallScreen={this.state.isSmallScreen} />
          </div>
        </div>
      )
    } else return null
  }
}

export default Disclosures
