import React from 'react'

export default class AccountModal extends React.Component{

    state = {
       
    }

    addCommas(x){
        if(x === ''){
            return ''
        }else{
            let arr = x.toString().split(".")
            if(arr[1]){
                return (arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","))+ '.' + arr[1]
            }
            else{
                return  (arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","))
            }
        } 
    }

    render(){
        const {acc} = this.props
        // console.log( 'orders resp',this.state.ordersResp)
        return(
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', minWidth:725, height: '90%', paddingBottom:25}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}>Account Summary</p>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.props.closeModal()}> X </p>
                    </div>
                    <div style={{width:'100%', overflowY:'scroll'}}>
                        <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center', backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%', }}>
                            <p style={{fontSize:28}}>
                                Total Value ${this.addCommas(acc.balances[0].current.toFixed(2))} 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}