import React, { Component } from 'react'

export default class GoalsRecurBuys extends Component {
  state = {
    showAllRecurringList: false,
  }

  displayRecurringBuys() {
    const recurringBuys = this.props.accountTransactionsData.recurringBuys
    if (recurringBuys.length > 0) {
      return (
        <div
          style={{
            width: this.props.isSmallScreen ? '100%' : '100%',
            boxShadow:
              '-3px -2px 4px rgba(255, 255, 255, 0.92), 3px 1px 5px rgba(86, 86, 86, 0.2), inset 0px 0px 2px rgba(255, 255, 255, 0.21)',
            borderRadius: '19px',
          }}
        >
          <div style={{ padding: '20px' }}>
            <p
              style={{
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '15px',
              }}
            >
              {this.props.t('recurringbuys')}
            </p>
            <div
              style={{ width: '100%', display: 'flex', marginBottom: '15px' }}
            >
              <div
                style={{
                  width: '20%',
                  borderBottom: '1px solid rgba(128,128,128,.5)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p
                  style={{
                    color: 'rgba(128,128,128,.8)',
                    fontSize: '15px',
                    verticalAlign: 'middle',
                    marginBottom: '0',
                  }}
                >
                  {this.props.t('symbol')}
                </p>
              </div>
              <div
                style={{
                  width: '30%',
                  borderBottom: '1px solid rgba(128,128,128,.5)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p
                  style={{
                    color: 'rgba(128,128,128,.8)',
                    fontSize: '15px',
                    marginBottom: '0',
                  }}
                >
                  {this.props.t('nextbuy')}
                </p>
              </div>
              <div
                style={{
                  width: '20%',
                  borderBottom: '1px solid rgba(128,128,128,.5)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p
                  style={{
                    color: 'rgba(128,128,128,.8)',
                    fontSize: '15px',
                    marginBottom: '0',
                  }}
                >
                  {this.props.t('amount')}
                </p>
              </div>
              <div
                style={{
                  width: '15%',
                  borderBottom: '1px solid rgba(128,128,128,.5)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p
                  style={{
                    color: 'rgba(128,128,128,.8)',
                    fontSize: '15px',
                    marginBottom: '0',
                  }}
                >
                  {this.props.t('stop')}
                </p>
              </div>
              <div
                style={{
                  width: '15%',
                  borderBottom: '1px solid rgba(128,128,128,.5)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p
                  style={{
                    color: 'rgba(128,128,128,.8)',
                    fontSize: '15px',
                    marginBottom: '0',
                  }}
                >
                  {this.props.t('edit')}
                </p>
              </div>
            </div>
            <div style={{ maxHeight: '30vh', overflowY: 'scroll' }}>
              {recurringBuys.map((buy) => {
                return this.displayRecurringBuysRow(buy)
              })}
            </div>
          </div>
        </div>
      )
    } else return null
  }

  displayRecurringBuysRow(trade) {
    return (
      <div style={{ width: '100%', display: 'flex' }}>
        <div style={{ width: '20%' }}>
          <p style={{ marginBottom: '0', color: 'black', fontWeight: 'bold' }}>
            {trade.ticker}
          </p>
        </div>
        <div style={{ width: '30%' }}>
          <p style={{ color: 'black' }}>{trade.next_purchase_date}</p>
        </div>
        <div style={{ width: '20%' }}>
          <p style={{ color: 'black' }}>
            {this.props.formatCurrency(trade.amount)}
          </p>
        </div>
        <div style={{ width: '15%' }}>
          <button
            style={{
              border: '1px solid red',
              borderRadius: '50%',
              color: 'white',
              width: '50%',
              background: 'transparent',
            }}
          >
            <span
              style={{ color: 'red', fontWeight: 'bold', fontSize: '15px' }}
            >
              -
            </span>
          </button>
        </div>
        <div style={{ width: '15%' }}>
          <p style={{ color: 'black', fontWeight: 'bold' }}>{'EDIT'}</p>
        </div>
      </div>
    )
  }

  render() {
    if (this.props.t) {
      return <div>{this.displayRecurringBuys()}</div>
    } else return null
  }
}
