import React from 'react'
import { NavLink, Redirect } from 'react-router-dom'

import { Button, FormGroup, FormControl } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'

import history from '../History'
import Header from '../PublicHeader'
import ARlogo from '../../assets/ar_logo.png'
import ForeSciteLogo from '../../assets/newUI/foreScite_noText.png'
import Entry from '../../assets/newUI/entry.svg'
import Locked from '../../assets/newUI/locked.svg'
import Mail from '../../assets/newUI/mail.svg'
import Verified from '../../assets/newUI/verified.svg'
import Amplify, { Auth } from 'aws-amplify'
import awsExports from '../../aws-config'

Amplify.configure(awsExports)

const brandColor = '#00A99D'

export default class Login extends React.Component {
  state = {
    username: this.props.retryUser ? this.props.retryUser : '',
    password: '',
    showFP: false,
    confirmationCode: '',
    errors: this.props.errors,
    sendCodeClicked: false,
    authCode: '',
    disableConfirmationBtn: false,
  }

  componentDidMount() {
    this.updateWindowDimensions()
  }

  validateForm() {
    if (this.state.username.length > 0 && this.state.password.length > 3) {
      console.log('validated true')
      return true
    } else {
      console.log('validated false')
      return false
    }
  }

  showCodeAndSuccess = () => {
    if (this.state.errors) {
      this.setState({ showCode: true, signUpClicked: true })
    } else {
      return null
    }
  }

  showErrors = () => {
    if (this.state.errors) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '75%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgb(253,180,181)',
              borderRadius: 10,
              padding: 10,
              marginBottom: 5,
              width: '100%',
            }}
          >
            <p style={{ margin: 0 }}>{this.state.errors}</p>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  handleSubmit(event) {
    event.preventDefault()
  }

  sendCode = () => {
    if (this.state.username.length > 5) {
      Auth.forgotPassword(this.state.username)
        .then((resp) => {
          // console.log('send code 1: ', resp)
          // console.log('send code 2: ', resp.CodeDeliveryDetails)
          // console.log('send code 3: ', )
          this.setState({
            sendCodeClicked: resp.CodeDeliveryDetails.Destination,
            errors: false,
          })
        })
        .catch((error) => this.setState({ errors: 'email address not found' }))
      // .catch(error=>console.log(error))
    }
  }

  forgotSubmit = () => {
    Auth.forgotPasswordSubmit(
      this.state.username,
      this.state.confirmationCode,
      this.state.password
    )
      .catch((err) => this.setState({ errors: err.message }))
      .then(() => this.signInRedirect())
  }

  showPhoneNum(str) {
    str.replace('+', '')
  }

  resendCode = () => {
    Auth.resendSignUp(this.state.username)
      .then((res) => {
        console.log('resend code response: ' + JSON.stringify(res))
        window.alert('Your code has been resent please check your email.')
      })
      .catch((error) =>
        console.log('resend code error: ' + JSON.stringify(error))
      )
  }

  confirmSignUp = () => {
    const { username, authCode } = this.state
    // const username = email;
    this.setState({ disableConfirmationBtn: true })
    console.log('username1', username)
    Auth.confirmSignUp(username, authCode)
      .then(() => {
        window.alert('Sign up successful. Please Login to your account.')
        // this.props.signIn(this.state.username, this.state.password)
        this.setState({ errors: '', disableConfirmationBtn: false })
      })
      .catch((err) => {
        if (!err.message) {
          console.log('Error when entering confirmation code: ', err)
          window.alert('Error when entering confirmation code: ' + err)
          this.setState({
            authCodeText: 'Resend Code',
            disableConfirmationBtn: false,
          })
        } else {
          console.log('Error when entering confirmation code: ', err.message)
          window.alert('Error when entering confirmation code: ' + err.message)
          this.setState({
            authCodeText: 'Resend Code',
            disableConfirmationBtn: false,
          })
        }
      })
  }

  showSuccess = () => {
    if (!this.state.errors && this.state.sendCodeClicked) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '75%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgb(234,255,219)',
              borderRadius: 10,
              padding: 10,
              marginBottom: 5,
              width: '100%',
            }}
          >
            <p
              style={{ margin: 0 }}
            >{`A confirmation code has been sent to ${this.state.sendCodeClicked}`}</p>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  forgotForm = () => {
    const { username, password, confirmationCode } = this.state
    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup controlId='username'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginBottom: 10,
            }}
          >
            <p
              style={{
                color: '#53b9b6',
                fontSize: 28,
                paddingBottom: 10,
                fontWeight: 'bold',
              }}
            >
              FORGOT PASSWORD
            </p>
          </div>
          <div
            style={{
              backgroundColor: '#f0f0f0',
              display: 'flex',
              paddingTop: '10px',
              paddingBottom: '10px',
              marginBottom: 10,
            }}
          >
            <div
              style={{
                width: 75,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={Mail} />
            </div>
            <div
              style={{
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 7,
                paddingRight: 7,
                width: '80%',
              }}
            >
              <p>Email Address</p>
              <input
                style={{
                  minWidth: 200,
                  border: 'none',
                  width: '100%',
                  backgroundColor: 'transparent',
                  outline: 'none',
                }}
                autoFocus
                type='username'
                placeholder='yourname@example.com'
                value={username}
                onChange={(e) =>
                  this.setState({ ['username']: e.target.value })
                }
              />
            </div>
          </div>
          <Button
            block
            bsSize='large'
            style={{
              border: 'solid',
              borderWidth: '.5px',
              borderColor: '#3b8e8c',
              width: '50%',
              backgroundColor: 'transparent',
              margin: this.state.isSmallScreen ? 'auto' : '',
            }}
            onClick={() => this.sendCode()}
            type='submit'
          >
            <p style={{ color: '#3b8e8c', margin: 0 }}>Send Code</p>
          </Button>
        </FormGroup>
        <FormGroup controlId='password' bsSize='large'>
          <div
            style={{
              backgroundColor: '#f0f0f0',
              display: 'flex',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          >
            <div
              style={{
                width: 75,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={Locked} />
            </div>
            <div
              style={{
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 7,
                paddingRight: 7,
                width: '80%',
              }}
            >
              <p>Confirmation Code</p>
              <input
                style={{
                  minWidth: 200,
                  border: 'none',
                  width: '100%',
                  backgroundColor: 'transparent',
                  outline: 'none',
                }}
                placeholder='Confirmation Code'
                value={confirmationCode}
                onChange={(e) =>
                  this.setState({ ['confirmationCode']: e.target.value })
                }
                type='password'
              />
            </div>
          </div>
          <div
            style={{
              border: 'solid',
              borderColor: '#f0f0f0',
              borderWidth: '.25px',
              display: 'flex',
              display: 'flex',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          >
            <div
              style={{
                width: 75,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={Locked} />
            </div>
            <div
              style={{
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 7,
                paddingRight: 7,
                width: '80%',
              }}
            >
              <p>Password</p>
              <input
                style={{
                  minWidth: 200,
                  border: 'none',
                  width: '100%',
                  backgroundColor: 'transparent',
                  outline: 'none',
                }}
                placeholder='New Password'
                value={password}
                onChange={(e) =>
                  this.setState({ ['password']: e.target.value })
                }
                type='password'
              />
            </div>
          </div>
        </FormGroup>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {this.showErrors()}
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {this.showSuccess()}
        </div>
        <Button
          block
          bsSize='large'
          onClick={!this.validateForm() ? null : () => this.forgotSubmit()}
          style={{
            border: 'solid',
            borderWidth: '.5px',
            borderColor: '#3b8e8c',
            width: '50%',
            backgroundColor: 'transparent',
            margin: this.state.isSmallScreen ? 'auto' : '',
          }}
          type='submit'
        >
          <p style={{ color: '#3b8e8c', margin: 0 }}>
            {' '}
            Change Password & Login
          </p>
        </Button>
        <Button
          block
          bsSize='large'
          onClick={() => this.setState({ showFP: !this.state.showFP })}
          style={{
            border: 'solid',
            borderWidth: '.5px',
            borderColor: '#3b8e8c',
            width: '50%',
            backgroundColor: 'transparent',
            margin: this.state.isSmallScreen ? 'auto' : '',
            marginTop: this.state.isSmallScreen ? 20 : '',
          }}
          type='submit'
        >
          <p style={{ color: '#3b8e8c', margin: 0 }}>
            {this.state.showFP ? 'Return to Login' : ' Forgot Password'}
          </p>
        </Button>
        <div style={{ display: 'flex', marginTop: 20 }}>
          <p style={{ color: '#959595' }}>
            Don't have an account?{' '}
            <NavLink style={{ color: '#3b8e8c' }} to='/SignUp'>
              sign up
            </NavLink>
          </p>
        </div>
      </form>
    )
  }

  showErrors = () => {
    if (this.state.errors) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '75%',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgb(253,180,181)',
              borderRadius: 10,
              padding: 10,
              marginBottom: 5,
              width: '100%',
            }}
          >
            <p style={{ margin: 0 }}>{this.state.errors}</p>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  signInRedirect = () => {
    const { history } = this.props
    this.props.signIn(this.state.username, this.state.password)
    if (this.props.errors) {
      console.log('errors present, not going to watchlist')
      return null
    } else {
      console.log('going to watchlist?')
    }
  }

  loginForm = () => {
    const { username, password } = this.state
    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup controlId='username' bsSize='large'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginBottom: 10,
            }}
          >
            <p
              style={{
                color: '#53b9b6',
                fontSize: 28,
                paddingBottom: 10,
                fontWeight: 'bold',
              }}
            >
              LOGIN
            </p>
          </div>
          <div
            style={{
              backgroundColor: '#f0f0f0',
              display: 'flex',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          >
            <div
              style={{
                width: 75,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={Mail} />
            </div>
            <div
              style={{
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 7,
                paddingRight: 7,
                width: '80%',
              }}
            >
              <p>Email Address</p>
              <input
                id='username'
                style={{
                  minWidth: 200,
                  border: 'none',
                  width: '100%',
                  backgroundColor: 'transparent',
                  outline: 'none',
                }}
                autoFocus
                type='username'
                placeholder='yourname@example.com'
                value={username}
                onChange={(e) =>
                  this.setState({ ['username']: e.target.value })
                }
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup controlId='password' bsSize='large'>
          <div
            style={{
              border: 'solid',
              borderColor: '#f0f0f0',
              borderWidth: '.25px',
              display: 'flex',
              display: 'flex',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          >
            <div
              style={{
                width: 75,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={Locked} />
            </div>
            <div
              style={{
                paddingTop: 5,
                paddingBottom: 5,
                paddingLeft: 7,
                paddingRight: 7,
                width: '80%',
              }}
            >
              <p>Password</p>
              <input
                id='password'
                style={{
                  minWidth: 200,
                  border: 'none',
                  width: '100%',
                  backgroundColor: 'transparent',
                  outline: 'none',
                }}
                placeholder='Enter Password Here'
                value={password}
                onChange={(e) =>
                  this.setState({ ['password']: e.target.value })
                }
                type='password'
              />
            </div>
          </div>
        </FormGroup>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {this.showErrors()}
        </div>
        {this.state.errors ===
        'User is not confirmed. Please enter the confirmation code sent previously or request a new one.' ? (
          <div style={{ marginBottom: '10px', marginTop: '10px' }}>
            <FormGroup controlId='passwordconfirm' bsSize='large'>
              <div
                style={{
                  backgroundColor: '#f0f0f0',
                  display: 'flex',
                  paddingTop: '10px',
                  paddingBottom: '10px',
                }}
              >
                <div
                  style={{
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 7,
                    paddingRight: 7,
                    width: '80%',
                  }}
                >
                  <p>Confirmation Code</p>
                  <input
                    style={{
                      minWidth: 200,
                      border: 'none',
                      width: '100%',
                      backgroundColor: 'transparent',
                      outline: 'none',
                    }}
                    placeholder='Confirmation Code'
                    value={this.state.authCode}
                    onChange={(e) =>
                      this.setState({ authCode: e.target.value })
                    }
                    type='text'
                  />
                </div>
              </div>
            </FormGroup>
            {this.state.disableConfirmationBtn ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: this.state.isSmallScreen ? '80%' : '30%',
                  marginBottom: 10,
                }}
              >
                <Spinner
                  animation='border'
                  id='spinner'
                  variant='dark'
                  role='status'
                >
                  <span className='sr-only'>Loading...</span>
                </Spinner>
              </div>
            ) : (
              <Button
                block
                bsSize='large'
                id='submitLogin'
                onClick={() => this.confirmSignUp()}
                style={{
                  border: 'solid',
                  borderWidth: '.5px',
                  borderColor: '#3b8e8c',
                  width: this.state.isSmallScreen ? '80%' : '30%',
                  backgroundColor: 'transparent',
                  margin: this.state.isSmallScreen ? 'auto' : '',
                  marginBottom: this.state.isSmallScreen ? 20 : '',
                }}
                type='submit'
              >
                <p style={{ color: '#3b8e8c', margin: 0 }}>Validate Account</p>
              </Button>
            )}
            <Button
              block
              bsSize='large'
              id='submitLogin'
              onClick={() => this.resendCode()}
              style={{
                border: 'solid',
                borderWidth: '.5px',
                borderColor: '#3b8e8c',
                width: this.state.isSmallScreen ? '80%' : '30%',
                backgroundColor: 'transparent',
                margin: this.state.isSmallScreen ? 'auto' : '',
                marginBottom: this.state.isSmallScreen ? 20 : '',
              }}
              disabled={this.state.disableConfirmationBtn}
              type='submit'
            >
              <p style={{ color: '#3b8e8c', margin: 0 }}>Resend Code</p>
            </Button>
          </div>
        ) : null}
        <Button
          block
          bsSize='large'
          id='submitLogin'
          onClick={!this.validateForm() ? null : () => this.signInRedirect()}
          style={{
            border: 'solid',
            borderWidth: '.5px',
            borderColor: '#3b8e8c',
            width: this.state.isSmallScreen ? '80%' : '30%',
            backgroundColor: 'transparent',
            margin: this.state.isSmallScreen ? 'auto' : '',
            marginBottom: this.state.isSmallScreen ? 20 : '',
          }}
          disabled={
            this.state.errors ===
            'User is not confirmed. Please enter the confirmation code sent previously or request a new one.'
          }
          type='submit'
        >
          <p style={{ color: '#3b8e8c', margin: 0 }}>Login</p>
        </Button>
        <Button
          block
          bsSize='large'
          onClick={() => this.setState({ showFP: !this.state.showFP })}
          style={{
            border: 'solid',
            borderWidth: '.5px',
            borderColor: '#3b8e8c',
            width: this.state.isSmallScreen ? '80%' : '30%',
            backgroundColor: 'transparent',
            margin: this.state.isSmallScreen ? 'auto' : '',
          }}
          disabled={
            this.state.errors ===
            'User is not confirmed. Please enter the confirmation code sent previously or request a new one.'
          }
          type='submit'
        >
          <p style={{ color: '#3b8e8c', margin: 0 }}>
            {this.state.showFP ? 'Return to Login' : ' Forgot Password'}
          </p>
        </Button>
        <div
          style={{
            display: 'flex',
            margin: this.state.isSmallScreen ? 'auto' : '',
            marginTop: 20,
            width: this.state.isSmallScreen ? '80%' : '',
          }}
        >
          <p
            style={{
              color: '#959595',
              marginTop: this.state.isSmallScreen ? 20 : 0,
            }}
          >
            Don't have an account?{' '}
            <NavLink style={{ color: '#3b8e8c' }} to='/SignUp'>
              sign up
            </NavLink>
          </p>
        </div>
      </form>
    )
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  cutURL() {
    return window.location.pathname.split('/Login/')[1]
  }

  render() {
    let params = this.cutURL()
    if (this.props.user && params) {
      window.location.replace(`/Dashboard/Trade/${params}`)
      return (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h2>Loading... </h2>
          </div>
        </div>
      )
    } else if (this.props.user) {
      window.location.replace('/Dashboard')
      // history.push(`/Dashboard/Watchlist`)
      return (
        <div style={{ width: '100%' }}>
          <Header
            hiddenSearch={true}
            user={this.props.user}
            signOut={this.props.signOut}
            isSmallScreen={this.props.isSmallScreen}
          />
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              marginTop: 75,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 100,
              }}
            >
              <h2>Logging in... </h2>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div style={{ width: '100%' }}>
          <Header
            hiddenSearch={true}
            user={this.props.user}
            signOut={this.props.signOut}
            isSmallScreen={this.props.isSmallScreen}
          />
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              marginTop: 75,
            }}
          >
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20 }}>
                        <img
                            src={ForeSciteLogo}
                            style={{ height: 75 }}
                        />
                    </div> */}
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                justifyContent: 'space-between',
                flexDirection: this.state.isSmallScreen
                  ? 'column-reverse'
                  : 'row',
              }}
            >
              <div
                className='Login'
                style={{
                  borderRadius: 20,
                  width: this.state.isSmallScreen ? '100%' : '50%',
                  alignSelf: 'flex-start',
                  marginTop: 75,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 0,
                    height: '12vh',
                  }}
                >
                  <img src={ForeSciteLogo} style={{ height: '100%' }} />
                </div>
                <div
                  style={{
                    paddingLeft: this.state.isSmallScreen ? '2%' : 100,
                    paddingRight: this.state.isSmallScreen ? '2%' : 100,
                    paddingTop: 30,
                  }}
                >
                  {this.state.showFP ? this.forgotForm() : this.loginForm()}
                </div>
                {/* <div style={{ display: 'flex', justifyContent: 'space-around', padding: 20, }}>
                                <div style={{borderRadius: 6, backgroundColor:brandColor, paddingTop:5,paddingBottom:5,paddingLeft:10,paddingRight:10, width:'20%', minWidth:this.state.isSmallScreen?100:150, display:'flex', justifyContent:'center', alignItems:'center'}}>
                                
                                    <NavLink
                                        to={'/SignUp'}
                                        style={{ color: 'rgb(255,255,255)',textDecoration:'none' }}
                                    >
                                        <p style={{margin:0, textDecoration:'none'}}>
                                        Sign Up
                                        </p>
                                    </NavLink>
                                </div>
                                <div 
                                    style={{ cursor: 'pointer',borderRadius: 6, backgroundColor:brandColor, paddingTop:5,paddingBottom:5,paddingLeft:10,paddingRight:10, width:'20%', minWidth:this.state.isSmallScreen?100:150, display:'flex', justifyContent:'center', alignItems:'center' }}
                                >
                                    <p
                                        onClick={() => this.setState({ showFP: !this.state.showFP })}
                                        style={{ color: 'rgb(255,255,255)',margin:0, textAlign:'center' }}
                                    >
                                        {this.state.showFP ? 'Return to Login' : ' Forgot Password'}
                                    </p>
                                </div>
                            </div> */}
              </div>
              {this.state.isSmallScreen ? null : (
                <div style={{ width: '50%', height: '100%' }}>
                  <img
                    src={Entry}
                    style={{
                      width: '100%',
                      height: this.state.isSmallScreen ? '30%' : 'auto',
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }
  }
}
