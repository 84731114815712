import React, { Component } from 'react'

export default class TransferHistory extends Component {
  state = {
    showAllTransferHistory: false,
  }

  displayTransferHistory() {
    const transferHistoryList = [
      ...this.props.accountTransactionsData.accountOrderHistory.transferHistory,
    ]
    // transferHistoryList.reverse()
    if (transferHistoryList.length > 0) {
      return (
        <div
          style={{
            width: this.props.isSmallScreen ? '100%' : '100%',
            height: '100%',
          }}
        >
          <div style={{}}>
            {transferHistoryList.map((transfer, index) => {
              return this.displayTransferHistoryRow(transfer, index)
            })}
          </div>
        </div>
      )
    } else return <p>{this.props.t('notransferhistory')}</p>
  }

  displayTransferHistoryRow(transfer, index) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '15px 0',
        }}
        key={index}
      >
        <div
          style={{
            alignContent: 'center',
            width: '50%',
            wordWrap: 'break-word',
          }}
        >
          <p
            style={{
              color: transfer.type === 'DEPOSITS' ? '#3F46F6' : '#3D5062',
              fontSize: '15px',
              marginBottom: '0',
            }}
          >
            {transfer.type}
          </p>
          <p
            style={{
              marginBottom: '0',
              marginRight: '0',
              fontSize: '15px',
            }}
          >
            {transfer.name}
          </p>
          <p
            style={{
              marginBottom: '0',
              color: 'rgba(128,128,128,.5)',
              fontSize: '10px',
            }}
          >
            {transfer.createdOn ? transfer.createdOn : transfer.transactionDate}
          </p>
        </div>
        <div style={{ alignContent: 'center' }}>
          <p style={{ fontSize: '15px', marginBottom: '0' }}>
            {this.props.formatCurrency(transfer.transactionAmount)}
          </p>
          <p
            style={{
              fontSize: '10px',
              color: 'rgba(128,128,128,.8)',
              textAlign: 'right',
            }}
          >
            mkt value
          </p>
        </div>
      </div>
    )
  }
  render() {
    return <div style={{ height: '100%' }}>{this.displayTransferHistory()}</div>
  }
}
