import React from 'react'
import RiskForecast from './RiskForecast'
import KeyStats from './KeyStats'
import DdChart from './DdChart'

export default class RsCi extends React.Component{
    state={

    }
    
    render(){
        if(this.props.isSmallScreen){
            return(
                <div style={{width:'100%', display:'flex', flexDirection:'column'}}>
                    <div style={{ backgroundColor:'rgb(255,255,255)', borderRadius:10, display:'flex', flexDirection:'column', width:'100%',marginBottom:20, marginTop:20}}>
                        <div style={{marginBottom:9, marginTop:9, width:'100%', display:'flex', justifyContent:'space-between'}}>
                            <div style={{display:'flex', alignItems:'center',justifyContent:'center', marginLeft:10}}>
                                <p style={{margin:0, fontSize: 22, fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 0.75, letterSpacing: '0.56px', textAlign: 'left', color: 'rgb(36, 42, 56)',}}>
                                    HISTORICAL RISK
                                </p>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center',marginRight:10 }}>
                                <p style={{margin:0, fontSize: 22, fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 0.75, letterSpacing: '0.56px', textAlign: 'left', color: 'rgb(36, 42, 56)', marginRight:5}}>
                                    Max:
                                </p>
                                <p style={{backgroundColor:'rgb(194,192,233)', borderRadius: 20, padding: 7,margin:0, fontWeight:'bold'}}>{this.props.sourceData.maxDrawdownVal.toFixed(2)}%</p> 
                            </div>
                        </div>
                        <div style={{width:'90%', }}>
                            {this.props.sourceData && this.props.sourceData.maxDrawdownData?
                                <DdChart drawdownChart={this.props.sourceData.maxDrawdownData}/>
                            :null}
                        </div>
                    </div>
                    <div style={{backgroundColor:'rgb(255,255,255)', padding: 40, marginTop: '5%', borderRadius:10, display:'flex', flexDirection:'column', width:'100%',marginBottom:20 }}>
                        <div style={{marginBottom:9, marginTop:9}}>
                            <p style={{margin:0, fontSize: 22, fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 0.75, letterSpacing: '0.56px', textAlign: 'center', color: 'rgb(36, 42, 56)',}}>
                                Risk Forecast
                            </p>
                        </div>
                        <RiskForecast myStrats={false} isSmallScreen={true} maxDrawdownVal={this.props.sourceData.maxDrawdownVal} cvar={Math.abs(this.props.sourceData.cvar)} symbol={this.props.sourceData.symbol}/>
                        <p style={{ fontSize: 22, fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', textAlign: 'center', color: 'rgb(36, 42, 56)',}}>
                            Stock risk forecasted by ForeSCITE
                        </p>
                    </div>
                    <div style={{backgroundColor:'rgb(255,255,255)', width:'100%', height:'fit-content', borderRadius:18, padding:20,marginBottom:20  }}>
                        <KeyStats isSmallScreen={true} sourceData={this.props.sourceData}/>
                    </div>
                </div>
            )
        }
        else{
            return(
                <div style={{height:'100%', width:'100%', display:'flex', justifyContent:'center',}}>
                    
                    <div style={{width:'41%',marginRight: '1.5%', }}>
                        
                        <div style={{  width:'100%', display:'flex', justifyContent:'center',alignItems:'center',backgroundColor:'rgb(255,255,255)',padding: 40, borderRadius:10 }}>
                            
                            <div style={{ width:'100%',display:'flex', justifyContent:'center',alignItems:'center', flexDirection:'column', }}>
                                <div style={{marginBottom:9, marginTop:9, width:'100%', display:'flex', justifyContent:'space-between'}}>
                                    <div style={{display:'flex', alignItems:'center',justifyContent:'center', marginLeft:10}}>
                                        <p style={{margin:0, fontSize: 22, fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 0.75, letterSpacing: '0.56px', textAlign: 'left', color: 'rgb(36, 42, 56)',marginTop:-80}}>
                                            HISTORICAL RISK
                                        </p>
                                    </div>
                                    {this.props.sourceData && this.props.sourceData.maxDrawdownVal?
                                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center',marginRight:10 }}>
                                        <p style={{margin:0, fontSize: 22, fontWeight: 'normal', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 0.75, letterSpacing: '0.56px', textAlign: 'left', color: 'rgb(36, 42, 56)', marginRight:5}}>
                                            Max:
                                        </p>
                                        <p style={{backgroundColor:'rgb(194,192,233)', borderRadius: 20, padding: 7,margin:0, fontWeight:'bold'}}>{this.props.sourceData.maxDrawdownVal.toFixed(2)}%</p>
                                    </div>
                                    :null} 
                                </div>
                                <div style={{width:'90%', }}>
                                {this.props.sourceData?
                                <DdChart drawdownChart={this.props.sourceData.maxDrawdownData}/>
                                :null}
                                </div>
                            </div>
                        </div>
                        {this.props.sourceData && this.props.sourceData && this.props.sourceData.cvar ?
                            <div style={{backgroundColor:'rgb(255,255,255)', padding: 40, marginTop: '5%', borderRadius:10, display:'flex', flexDirection:'column'}}>
                                <div style={{marginBottom:9, marginTop:9}}>
                                    <p style={{margin:0, fontSize: 22, fontWeight: 'bold', fontStretch: 'normal', fontStyle: 'normal', lineHeight: 0.75, letterSpacing: '0.56px', textAlign: 'left', color: 'rgb(36, 42, 56)',}}>
                                        Risk Forecast
                                    </p>
                                </div>
                                <RiskForecast maxDrawdownVal={this.props.sourceData.maxDrawdownVal} cvar={Math.abs(this.props.sourceData.cvar)} symbol={this.props.sourceData.symbol}/>
                            </div>
                        :null}
                    </div>
                    <div style={{backgroundColor:'rgb(255,255,255)', width:'29%', height:'fit-content', borderRadius:18, padding:20 }}>
                        <KeyStats sourceData={this.props.sourceData}/>
                    </div>
                </div>
            )
        }
    }
}