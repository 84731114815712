import React from 'react'
import ReactMarkdown from 'react-markdown'

export default function TextContent(props) {
  const { data } = props.content_data

  return (
    <div key={props.key}>
      {Array.isArray(data) ? (
        data.map((item, index) => (
          // Use ReactMarkdown to render each item if it's in markdown format
          <ReactMarkdown key={index}>{item?.toString()}</ReactMarkdown>
        ))
      ) : (
        // Use ReactMarkdown if the data is a single string in markdown format
        <ReactMarkdown>{data?.toString()}</ReactMarkdown>
      )}
    </div>
  )
}
