import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'

import '../../../scss/pages/Newt.scss'
import NewtNewThread from '../../components/newt/NewtNewThread'
import NewtMessages from '../../components/newt/NewtMessages'

import newtLogo from '../../../assets/newt/newt-logo-black-name.png'
import { BsLayoutSidebarInset, BsPencilSquare } from 'react-icons/bs'
// import Endpoint from '../../Endpoint'
const Endpoint = 'https://dev-api.allocaterite.com'

const CHECK_PROMPT_STATUS_INTERVALS = [
  { duration: 0.5, times: 20 },
  { duration: 1, times: 20 },
  { duration: 1.5, times: 20 },
  { duration: 2, times: 10 },
  { duration: 3, times: 10 },
]

const CHATGPT_STOP_STATUS = ['DONE', 'FAILED', 'INCOMPLETE']

export default function NewtMain(props) {
  const [stage, setStage] = useState(0)
  const [showSideMenu, setShowSideMenu] = useState(false)
  const [currentThreadId, setCurrentThreadId] = useState(null)
  const [promptProcessing, setPromptProcessing] = useState(false)
  const [loadingResMsg, setLoadingResMsg] = useState('')
  const [promptInput, setPromptInput] = useState('')
  const [messages, setMessages] = useState([])
  const [currentProcessThreads, setCurrentProcessThreads] = useState(null)
  const [threadHistory, setThreadHistory] = useState([])

  const abortControllerRef = useRef(null)
  const promptInterval = useRef(null)

  useEffect(() => {
    fetchThreadHistory()
  }, [])

  useEffect(() => {
    console.log('promptProcessing change', promptProcessing)
  }, [promptProcessing])

  const stageRender = () => {
    switch (stage) {
      case 1:
        return (
          <NewtMessages
            jwtToken={props?.jwtToken}
            setStage={setStage}
            backToNewThread={backToNewThread}
            messages={messages}
            currentProcessThreads={currentProcessThreads}
            promptProcessing={promptProcessing}
            loadingResMsg={loadingResMsg}
            sendPrompt={sendPrompt}
            setPromptInput={setPromptInput}
            promptInput={promptInput}
          />
        )
      default:
        return (
          <NewtNewThread
            jwtToken={props?.jwtToken}
            setStage={setStage}
            sendPrompt={sendPrompt}
            setPromptInput={setPromptInput}
            promptInput={promptInput}
          />
        )
    }
  }

  const fetchThreadHistory = async () => {
    try {
      let res = await axios.get(`${Endpoint}/gpt/thread/history`, {
        headers: {
          Authorization: 'Bearer ' + props.jwtToken,
        },
      })
      console.log('fetch history', res)
      setThreadHistory(res.data)
    } catch (error) {
      console.log('fetch history error: ', error)
      setThreadHistory([])
    }
  }

  const sendChatGPTPrompt = async (message) => {
    let body = {
      prompt: message,
      thread_id: currentThreadId,
    }

    try {
      let res = await axios.post(`${Endpoint}/gpt/prompt`, body, {
        headers: {
          Authorization: 'Bearer ' + props.jwtToken,
        },
      })

      console.log('[API Response] - /prompt', res?.data)

      return res?.data
    } catch (error) {
      console.log(error)
      return { error: true, error_msg: error }
    }
  }

  const sendPrompt = useCallback(
    async (text, trackingId) => {
      console.log('send prompt 1', text)
      if (!text || text.trim() === '') {
        return false
      }
      console.log('promptProcessing', promptProcessing)
      if (!promptProcessing) {
        console.log('send prompt 2', text)
        try {
          setPromptProcessing(true)
          addPromptToMessages(text)
          setStage(1)
          console.log('currentThreadId in sendPrompt', currentThreadId)
          let init_prompt_res = await sendChatGPTPrompt(text?.trim())
          if (init_prompt_res?.error) {
            throw new Error(init_prompt_res?.error_msg)
          }
          setCurrentThreadId(init_prompt_res?.thread_id)
          setLoadingResMsg(init_prompt_res?.message)
          if (init_prompt_res?.status?.toLowerCase() === 'pending') {
            // Call check prompt status

            promptIntervalCall(init_prompt_res, text)
          }
          console.log('before remove', text)
          setPromptInput('')
        } catch (error) {
          console.log('error', error)
        }
      } else {
        setPromptInput(text)
      }
    },
    [messages, promptProcessing]
  )

  const promptIntervalCall = useCallback(
    (prompt_res, text) => {
      let currentIndex = 0
      // let startTime = new Date();
      let responseTracking = {}

      const runNextNewtInterval = () => {
        // if it is exhausted all the preset interval calls
        if (currentIndex >= CHECK_PROMPT_STATUS_INTERVALS?.length) {
          fetchThreadHistory()
          abortControllerRef.current = null
          setCurrentProcessThreads(null)
          setPromptProcessing(false)
          setLoadingResMsg('')
          return
        }

        let prompt_metadata = {
          thread_id: prompt_res?.thread_id,
          tracking_id: prompt_res?.tracking_id,
        }

        let calls = 1
        let interval = CHECK_PROMPT_STATUS_INTERVALS[currentIndex]

        console.log('responseTracking initial', responseTracking)

        // let promptInterval

        promptInterval.current = setInterval(async () => {
          try {
            console.log('[Newt]: Prompt Processing Check ', calls)

            // if number of of this preset interval call is more than max number
            if (calls > interval.times) {
              clearInterval(promptInterval.current)
              currentIndex++
              abortControllerRef.current.abort()

              const controller = new AbortController()
              abortControllerRef.current = controller

              setTimeout(runNextNewtInterval, interval.duration * 1000) // Start next interval after a delay
              return
            }

            // if there is old api reference, then cancel it
            if (abortControllerRef.current) {
              abortControllerRef.current.abort() // Abort the previous API call
              abortControllerRef.current = null
            }

            // create new reference for new api calling
            const controller = new AbortController()
            const signal = controller.signal
            abortControllerRef.current = controller

            //todo- uncoment when test
            // call check prompt status API
            let prompt_status_res = await checkChatGPTPromptStatus(
              prompt_metadata,
              { signal }
            )

            console.log('prompt_status_res', prompt_status_res)
            calls++
            // initial few api response just has error status but rest of them are good to check, this if to avoid go to the catch block
            if (isArrayofObjects(prompt_status_res)) {
              let newMsg = prompt_status_res[0]?.processing_message
                ? prompt_status_res[0]?.processing_message
                : 'Finalizing data checks to ensure accuracy and integrity'

              setLoadingResMsg((prevMsg) => {
                if (newMsg !== prevMsg) {
                  return newMsg
                }
                return prevMsg
              })

              // calls++;

              prompt_status_res?.forEach((ele, index) => {
                let eleId = ele.prompt_id

                // if the prompt is already added, just return the current one else add the new prompt to the object with inital value
                if (responseTracking[eleId] !== undefined) {
                  // If eleId already exists, preserve its current 'handled' value
                  responseTracking[eleId] = {
                    ...responseTracking[eleId],
                    handled: responseTracking[eleId].handled,
                  }
                } else {
                  // If eleId does not exist, initialize it with 'handled' set to false
                  responseTracking[eleId] = {
                    handled: false,
                  }
                }

                // if this query is already in finished status (failed or done)
                if (
                  CHATGPT_STOP_STATUS?.includes(ele.status) &&
                  !responseTracking[eleId].handled
                ) {
                  console.log('come to complete ', ele)
                  addResultToMessages(ele)

                  responseTracking[eleId].handled = true
                }
              })

              setCurrentProcessThreads((prevThreads) => {
                const newThreads = {
                  ...prevThreads,
                  ...responseTracking,
                }
                console.log('Updated process threads:', newThreads)
                return newThreads
              })

              // if all the response data are handle, clear the interval
              if (
                Object?.values(responseTracking)?.every(
                  (state) => state.handled
                )
              ) {
                console.log('finished processing')
                setPromptProcessing(false)
                setLoadingResMsg('')
                setCurrentProcessThreads(null)
                fetchThreadHistory()
                clearInterval(promptInterval.current)
                abortControllerRef.current = null
                return
              }
            }
          } catch (error) {
            console.log('error', error)
          }
        }, interval.duration * 1000)
      }

      runNextNewtInterval()
    },
    [messages]
  )
  const addPromptToMessages = (text) => {
    let unique_id = generateUUID()
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages]
      newMessages.push({
        message_type: 'prompt',
        prompt: text,
        unique_id: unique_id,
      })
      return newMessages
    })
  }

  const addResultToMessages = (result_data) => {
    const unique_id = generateUUID()
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages]
      newMessages.push({
        message_type: 'newt',
        ...result_data,
        unique_id: unique_id,
      })
      return newMessages
    })
  }

  const generateUUID = () => {
    return uuidv4()
  }

  const checkChatGPTPromptStatus = async (prompt_metadata, options) => {
    const { signal } = options

    try {
      let res = await axios.get(
        // `${ChatgptBaseUrl}/prompt?user_id=${USER_ID}&js_id=${JS_ID}&thread_id=${prompt_metadata?.thread_id}&tracking_id=${prompt_metadata?.tracking_id}`,
        `${Endpoint}/gpt/prompt?thread_id=${prompt_metadata?.thread_id}&tracking_id=${prompt_metadata?.tracking_id}`,
        {
          withCredentials: false,

          headers: {
            Authorization: 'Bearer ' + props.jwtToken,
          },
          signal: signal, // Add the signal here
        }
      )

      console.log('[API Response] - /prompt?user_id', res?.data)
      return res.data
    } catch (error) {
      console.log(error)
    }
  }

  const getPreviousThreadMessages = async (thread) => {
    try {
      let { data } = await axios.get(
        Endpoint + `/gpt/thread/prompts?thread_id=${thread.thread_id}`,
        {
          headers: {
            Authorization: 'Bearer ' + props.jwtToken,
          },
        }
      )
      console.log('thread data', data)
      if (data?.length > 0) {
        setCurrentThreadId(thread.thread_id)
        setMessages([])
        for (let msg in data) {
          addResultToMessages(data[msg])
        }
        setStage(1)
        setShowSideMenu(false)
      }
    } catch (error) {}
  }

  const backToNewThread = () => {
    setStage(0)
    setMessages([])
    setPromptInput('')
    setCurrentThreadId(null)
    setShowSideMenu(false)
  }

  const isArrayofObjects = (data) => {
    // Check if it's an array
    if (!Array.isArray(data) || data.length === 0) {
      return false
    }

    // Check if every item in the array is an object
    return data.every((item) => {
      return typeof item === 'object' && item !== null && !Array.isArray(item)
    })
  }

  return (
    <div className='newt-main-container'>
      <div
        className={
          showSideMenu
            ? 'newt-thread-history-container active'
            : 'newt-thread-history-container'
        }
      >
        <div className='btns-container'>
          <button
            className='toggle-side-menu-btn'
            onClick={() => setShowSideMenu(!showSideMenu)}
          >
            <img src={newtLogo} alt='newt'></img>
            <BsLayoutSidebarInset />
          </button>
          {!showSideMenu && (
            <button
              className='new-thread-icon-btn'
              onClick={() => backToNewThread()}
            >
              <BsPencilSquare />
            </button>
          )}
        </div>
        {showSideMenu && (
          <div className='newt-threads-container'>
            <button
              className='new-thread-btn'
              onClick={() => backToNewThread()}
            >
              <BsPencilSquare />
              Start a new Thread
            </button>
            {threadHistory.map((thread, i) => (
              <button key={i} onClick={() => getPreviousThreadMessages(thread)}>
                {thread.prompt}
              </button>
            ))}
          </div>
        )}
      </div>
      {stageRender()}
    </div>
  )
}
