import React, { Component } from 'react'
import '../../../../../scss/pages/account-activation/goals/GoalInvestments.scss'
import { InputNumber } from 'primereact/inputnumber'
import { Dropdown } from 'primereact/dropdown'
import DepositDialog from '../../../DepositDialog'
import { Button } from 'primereact/button'
import NextArrow from '../../../../../assets/next-arrow.svg'

export default class GoalInvestments extends Component {
  state = {
    errorMessage: '',
    showDepositForm: false,
  }

  toggleErrorMessage = (msg) => {
    this.setState({ errorMessage: msg })
    setTimeout(() => {
      this.setState({ errorMessage: '' })
    }, 2500)
  }

  toggleShowDepositForm = () => {
    this.setState({ showDepositForm: !this.state.showDepositForm })
  }

  showMessage() {
    let value =
      this.props.initialAmount -
      this.props.selectedAccount.balances[0].virtualAccountValue
    return (
      <div className='message-container'>
        {value > 0 ? (
          <p className='message'>
            {this.props.t('additional')} {this.props.formatCurrency(value)}{' '}
            {this.props.t('isrequiredtomeetyourinitialinvestment')}
          </p>
        ) : (
          <>
            <p className='message'>
              {this.props.t('buyingpower')}:{' '}
              {this.props.formatCurrency(
                this.props.selectedAccount.balances[0].virtualAccountValue
              )}
            </p>
            <p className='message'>
              {this.props.t('goalplanningwilluseyourentireaccountvalue')}
            </p>
          </>
        )}
      </div>
    )
  }

  initialInvestmentForm() {
    return (
      <div className='initial-investment-container'>
        <p className='header'>{this.props.t('initialinvestment')}</p>{' '}
        <InputNumber
          value={this.props.initialAmount}
          onValueChange={(e) => this.props.handleInitialAmountChange(e.value)}
          mode='currency'
          currency='USD'
          locale='en-US'
          minFractionDigits={0}
          maxFractionDigits={2}
          min={Math.max(
            500,
            Math.floor(
              this.props.selectedAccount.balances[0].virtualAccountValue
            )
          )}
          style={{ width: '30%' }}
        />
      </div>
    )
  }

  goalAmountForm() {
    return (
      <div className='goal-amount-container'>
        <p className='header'>{this.props.t('investmentgoal')}</p>{' '}
        <InputNumber
          value={this.props.goalAmount}
          onValueChange={(e) => this.props.handleGoalAmountChange(e.value)}
          min={this.props.initialAmount}
          mode='currency'
          currency='USD'
          locale='en-US'
          style={{ width: '30%' }}
          minFractionDigits={0}
          maxFractionDigits={2}
        />
      </div>
    )
  }

  yearsToAccumulateForm() {
    return (
      <div className='years-container'>
        <p className='header'>{this.props.t('yearstoaccumulate')}</p>{' '}
        <Dropdown
          value={this.props.yearsAccumulate}
          options={this.props.yearsOptions}
          onChange={(e) => this.props.handleYearsChange(e.value)}
        />
      </div>
    )
  }

  submitButton() {
    return (
      <div className='button-container'>
        {this.props.selectedAccount.balances[0].virtualAccountValue >=
        this.props.initialAmount ? (
          <Button
            className='submit-button'
            label={
              <div className='next-button-container'>
                <p className='next-text'>{this.props.t('submit')}</p>
                <img src={NextArrow} width={15} height={15} />
              </div>
            }
            onClick={() => {
              this.validateForm()
            }}
          />
        ) : (
          <Button
            className='submit-button'
            label={this.props.t('addcash')}
            onClick={() => {
              this.toggleShowDepositForm()
            }}
          />
        )}
      </div>
    )
  }

  validateForm = () => {
    if (this.props.initialAmount <= this.props.goalAmount) {
      this.props.addHistory('Goal Results')
    } else {
      this.toggleErrorMessage(
        this.props.t('investmentgoalamountmustbegreaterthaninitialinvestment')
      )
    }
  }

  render() {
    if (this.props.t) {
      return (
        <div className='goal-investments-container'>
          <p className='header'>
            {this.props.t(
              `${this.props.selectedGoal?.toUpperCase()} TERM GOAL`
            )}
          </p>
          {this.showMessage()}
          {this.initialInvestmentForm()}
          {this.goalAmountForm()}
          {this.yearsToAccumulateForm()}
          {this.submitButton()}
          <DepositDialog
            toggleShowDepositForm={this.toggleShowDepositForm}
            selectedAccount={this.props.selectedAccount}
            user={this.props.user}
            getAllAccountInfo={this.props.getAllAccountInfo}
            showDepositForm={this.state.showDepositForm}
            isSmallScreen={this.props.isSmallScreen}
            accounts={this.props.accounts}
            t={this.props.t}
          />
        </div>
      )
    } else return null
  }
}
