import React from 'react'
import {faArrowRight, FaArrowRight} from "react-icons/fa";
import flower from "../../assets/steps/flower.svg"
import optimize from "../../assets/steps/optimize.svg"
import paperAirplane from "../../assets/steps/paperAirplane.svg"

const brandColor = "#00A99D"



export default class Steps extends React.Component{

    state={
        
    }
    placeholderFunction = ()=>{
        console.log('nope')
    }
    displayOne = (ob)=>{
        return(
            <div style={{display:'flex', flexDirection:'column', width: 250, marginLeft: 20, backgroundColor: 'rgb(246,246,246)', borderRadius: 10, padding:10, height:'100%'}}>
                <div style={{marginBottom: 10}}>
                    <img
                        src={ob.img}
                        style={{
                        marginTop: 5,
                        width: 30,
                        height: 30,
                        }}
                    />
                </div>
                <div>
                    <p style={{fontWeight:'bold'}}>{ob.headline}</p>
                </div>
                <div>
                    <p style={{fontWeight:''}}>{ob.tagline}</p>
                </div>
                <div onClick={()=>ob.buttonLink()} style={{display:'flex', cursor:'pointer', justifyContent:'center', alignItems:'center', backgroundColor: brandColor, width:'70%', borderRadius: 10}}>
                    <p style={{margin:0, fontWeight:"bold", color:'rgb(255,255,255)'}}>{ob.buttonText}</p>
                    <FaArrowRight style={{marginLeft:5, color: 'rgb(255,255,255)'}}/>
                </div>
            </div>
        )

    }

    render(){
        const cards = {
            optimize:{
                headline:'Optimize your portfolio',
                tagline:'Let us help you minimize the risk of your portfolio while maintaining the return',
                img:optimize,
                buttonText:'lower my risk',
                buttonLink: this.placeholderFunction
            },
            grow:{
                headline:'Grow your portfolio',
                tagline:"Get exposed to AllocateRite's flagship US Domestic strategy to help grow your net worth",
                img:flower,
                buttonText:'invest',
                buttonLink:this.props.toWm
            },
            reach:{
                headline:'Reach your financial goals',
                tagline:"Want to save to buy the house you've always wanted? We can help you like nobody else",
                img:paperAirplane,
                buttonText:'reach that goal',
                buttonLink: this.placeholderFunction
            }
        }
        if(this.props.loading){
            return(
                <div>
                    <h6>Loading...</h6>
                </div>
            )
        }
        return(
            <div style={{display:'flex', justifyContent:'center',alignItems:'center', flexDirection:this.props.isSmallScreen? 'column':'row', padding:10, height:'80%'}}>
                <div style={{height:'100%'}}>
                    {this.displayOne(cards.optimize)}
                </div>
                {/* <div style={{height:'100%'}}>
                    {this.displayOne(cards.grow)}
                </div> */}
                <div style={{height:'100%'}}>
                    {this.displayOne(cards.reach)}
                </div>
            </div>
        )
    }
}