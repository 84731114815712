import React, { Component } from "react";
import { Card, Row, Button, Form, } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import SingleCompanyCard from "../singleCompanyCard"
import Header from "../layout/Header";
import ENDPOINT from '../Endpoint'

export default class CompanySearchPage extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      searchMomentumText: "",
      isSmallScreen: false,
      momDataSource: [],
      isMomDataLoading: false,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.input.current.focus();
    document.title = `AllocateRite - Search`;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth });
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth });
    }
  };

  onSubmitSearchMomentum = () => {
    const { searchMomentumText } = this.state;
    this.setState({ isMomDataLoading: true });
    let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchMomentumText}`;
    fetch(url, {
      "Access-Control-Allow-Headers": {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        "Access-Control-Request-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        var data = responseJson.content;
        // console.log("SEARCHING RESULT: " , data);

        for (var i = 0; i < data.length; i++) {
          var item = data[i];
          var color = "";
          if (item.rar) {
            switch (item.rar) {
              case "H":
                color = "#dc143c";
                break;
              case "M":
                color = "#fcac0c";
                break;
              default:
                color = "#00A99D";
            }
          } else {
            color = "rgb(0,0,0)";
          }

          item.symbolColor = color;
          data[i] = item;
        }

        this.setState({
          isMomDataLoading: false,
          momDataSource: data,
        });
      })
      .catch((err) => {
        this.setState({ isMomDataLoading: false });
        console.log(err);
      });
  };

  titleColor = (index) => {
    let remainder = index % 5;

    switch (remainder) {
      case 0:
        return "#2EC61B";
      case 1:
        return "#1BC6A5";
      case 2:
        return "#1B8BC6";
      case 3:
        return "#561BC6";
      default:
        return "#B21BC6";
    }
  };

  individuleCompanyCard = () => {
    const { momDataSource } = this.state;

    return (
      <Row
        style={{
          padding: "2%",
          textDecoration: "none",
        }}
      >
        {momDataSource.map((item, index) => {
          return (
           <SingleCompanyCard item={item} index={index} titleColor={this.titleColor(index)}/>
          );
        })}
      </Row>
    );
  };

  trendPredictordiv = () => {
    const {
      isMomDataLoading,
      momDataSource,
      searchMomentumText,
      isSmallScreen,
      isMomCurSelected,
    } = this.state;

    let loadingdiv = (
      <div
        style={{
          height: 300,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>Loading...</p>
      </div>
    );
    let momdiv =
      momDataSource.length > 0 ? (
        this.individuleCompanyCard()
      ) : (
        <div
          style={{
            height: 300,
            paddingTop: isSmallScreen ? 80 : null,
            paddingBottom: isSmallScreen ? 80 : null,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5
            style={{
              margin: isSmallScreen ? 40 : 20,
              color: "#808080",
              fontSize: isSmallScreen ? 15 : 18,
            }}
          >
            {searchMomentumText.length < 2
                ? "Enter a symbol or company name to see price trend and stock info"
              : "Searching..."}
          </h5>
        </div>
      );

    return (
      <Card
        style={{
          width: "90%",
          marginTop: 20,
        }}
      >
        {isMomDataLoading ? loadingdiv : momdiv}
      </Card>
    );
  };

  render() {
    return (
      <div>
        <Header hiddenSearch={true} />
        <div
          style={{
            paddingTop: 100,
          }}
        >
          <div>
            <Button
              variant="light"
              style={{
                fontWeight: "bolder",
                marginLeft: 20,
                marginBottom: 7,
              }}
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              <FaArrowLeft size={20} style={{ marginRight: 5 }} />
              Back
            </Button>
          </div>
          {/* {console.log('searching')} */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              flex: 1,
              alignItems: "center",
            }}
          >
            <Form.Control
              type="text"
              style={{ width:'75%', marginBottom: 20 }}
              value={this.state.searchMomentumText}
              onChange={(event) => {
                let searchMomentumText = event.target.value;
                this.setState({ searchMomentumText }, () => {
                  const { isSearching } = this.state;
                  if (isSearching) {
                    return;
                  } else {
                    this.setState({ isSearching: true });
                    setTimeout(() => {
                      this.setState(
                        { isSearching: false },
                        this.onSubmitSearchMomentum
                      );
                    }, 2000);
                  }
                });
              }}
              onFocus={() => {
                this.setState({ showTrendPredictor: true });
              }}
              placeholder="Enter a symbol or company name to see price trend and stock info"
              ref={this.input}
            />
            {this.trendPredictordiv()}
          </div>
        </div>
      </div>
    );
  }
}
