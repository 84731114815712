//REACT
import React from 'react'
import { useEffect } from 'react'
import { useState, createContext } from 'react'

//REACT LIBRARIES
import { Dialog } from 'primereact/dialog'
import { TabView, TabPanel } from 'primereact/tabview'

//STYLES
import '../../scss/components/budgeting/Budgeting.scss'

//APIS
import {
  fetchWalletAddress,
  fetchWalletData,
  getBudgetingData,
} from '../../services/userServices'

//ICONS
import PlusIcon from '../../assets/plus.svg'

//COMPONENTS
import Balance from './Balance'
import Loader from '../../components/components/accountInfo/Loader'
import TransactionsHistory from './TransactionsHistory'
import ActivityCard from './ActivityCard'
import CreditCard from './CreditCard'
import PieChartsCard from './PieChartsCard'
import ExternalAccountsCard from './ExternalAccountsCard'
import ExternalAccountsList from './ExternalAccountsList'
import ActivityDetails from './ActivityDetails'
import CreditDetails from './CreditDetails'
import AddExternalAccount from './AddExternalAccount'
import AddCreditScore from './AddCreditScore'
import CreditScoreForm from './CreditScoreForm'
import ExternalAccountDetails from './ExternalAccountDetails'
import Notifications from './Notifications'
import NotificationsCard from './NotificationsCard'
import SubModal from '../SubModal'
import { useHistory } from 'react-router-dom'
import NewBudgetPage from './NewBudgetPage'

import axios from 'axios'
import Endpoint from '../Endpoint'

export const BudgetingContext = createContext()

export default function Budgeting(props) {
  const [budgetingData, setBudgetingData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showExternalAccounts, setShowExternalAccounts] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [page, setPage] = useState(['home'])
  const history = useHistory()

  const [addAccountDialog, setAddAccountDialog] = useState(false)
  const [creditScoreDialog, setCreditScoreDialog] = useState(false)
  const [showDisclaimer, setShowDisclaimer] = useState(false)
  const [showNotifications, setShowNotifications] = useState(false)
  const [subMessageDialog, setSubMessageDialog] = useState(true)
  const [activeTab, setActiveTab] = useState(0)

  const [budgetData, setBudgetData] = useState(null)

  const [currComponent, setCurrComponent] = useState('main')

  const [incomeHighlightInfo, setIncomeHighlightInfo] = useState(false)

  useEffect(() => {
    fetchBudgetingInfo()
  }, [])

  const fetchBudgetingInfo = async () => {
    setLoading(true)
    const responses = await Promise.all([
      getBudgetingData(props.user?.idToken?.jwtToken),
      fetchWalletAddress(props.user?.idToken?.jwtToken),
      fetchBudgetingInformation(),
      fetchIncomeReserveHightlight(),
    ])
    // let res = await getBudgetingData(props.user?.idToken?.jwtToken)
    const physicalAssets = aggregatePhysicalAssets(
      responses?.[0]?.payload?.budgeting
    )
    if (responses[0]) {
      responses[0].payload.budgeting.finStat.asset.sumPhysicalAssets =
        physicalAssets
    }
    if (responses[1]) {
      responses[0].digital_wallets = responses[1]
      const walletData = await getWalletData(responses[1])
      responses[0].wallet_data = walletData
    }
    setBudgetingData(responses?.[0])
    console.log(responses?.[0])
    console.log(responses?.[1])
    setLoading(false)
  }

  // get new budget summary information
  const fetchBudgetingInformation = async () => {
    try {
      let res = await axios.get(`${Endpoint}/budget-new/get_budget_summary`, {
        headers: {
          Authorization: 'Bearer ' + props.user?.idToken?.jwtToken,
        },
      })
      setBudgetData(res?.data?.payload)
    } catch (error) {
      console.log(error)
    }
  }

  // get new budget specific for the income reserve section
  const fetchIncomeReserveHightlight = async () => {
    try {
      let res = await axios.get(`${Endpoint}/budget-new/get_highlights`, {
        headers: {
          Authorization: 'Bearer ' + props.user?.idToken?.jwtToken,
        },
      })

      setIncomeHighlightInfo(res?.data?.payload)
    } catch (error) {
      console.log(error)
    }
  }

  const getWalletData = async (walletData) => {
    const res = {}
    for (let i = 0; i < walletData?.length; i++) {
      const data = await fetchWalletData(walletData?.[i]?.address)
      if (data) {
        res[walletData?.[i]?.address] = data
      }
    }
    return res
  }

  const refreshBudgetingInfo = (res) => {
    const physicalAssets = aggregatePhysicalAssets(res?.payload?.budgeting)
    res.payload.budgeting.finStat.asset.sumPhysicalAssets = physicalAssets
    setBudgetingData(res)
    console.log(res)
  }

  const aggregatePhysicalAssets = (details) => {
    let total = 0
    for (let i = 0; i < details?.properties.length; i++) {
      total += details?.properties[i]?.estimated_value
    }
    return total
  }

  useEffect(() => {
    console.log(page)
  }, [page])

  const home = () => {
    return (
      <div className='budgeting'>
        {!props.subStatus && (
          <div
            className='blur'
            style={{ width: '100%', height: '100%', zIndex: 999 }}
          >
            <Dialog
              visible={subMessageDialog}
              header={props.t('pleaseupgradetoinvestallprotoaccessthisproduct')}
              onHide={() => {
                setSubMessageDialog(false)
                history.push('/Dashboard')
                props.changeTab('Dashboard')
              }}
              style={{
                width: props.isSmallScreen ? '90vw' : '50vw',
                height: props.isSmallScreen ? '90vh' : '80vh',
              }}
            >
              <SubModal
                subStatus={props.subStatus}
                getSubStatus={props.getSubStatus}
                modal={true}
                user={props.user}
                userEmail={props.userEmail}
                subStatusMessage={props.subStatusMessage}
                subStatusExpirationDate={props.subStatusExpirationDate}
                appleStatus={props.appleStatus}
                freeTrialUsed={
                  props.userDetails?.accountSub &&
                  props.userDetails?.accountSub?.trialUsed
                }
                t={props.t}
                getAllAccountInfo={props.getAllAccountInfo}
              />
            </Dialog>
          </div>
        )}
        {/* new ui for the budget page  */}
        <div className='left-budgeting'>
          <NewBudgetPage
            jwtToken={props.user?.idToken?.jwtToken}
            budgetData={budgetData}
            oldBudgetData={budgetingData}
            t={props.t}
            isSmallScreen={props.isSmallScreen}
            setCurrComponent={setCurrComponent}
            currComponent={currComponent}
            fetchBudgetingInformation={fetchBudgetingInformation}
            incomeHighlightInfo={incomeHighlightInfo}
            userInfo={props.userInfo}
            user={props.user}
            plaidAcctsInfo={props.plaidAcctsInfo}
          />
          {/* old ui here */}
          {/* <TabView
            activeIndex={activeTab}
            onTabChange={(e) => setActiveTab(e.index)}
            className='categorized-investments-tab'
          >
            <TabPanel className='investments-tab' header={props.t('summary')}>
              <div className='summary shadow-card balance-scrollable budgeting-block'>
                <Balance />
              </div>
              <div className='budgeting-block'>
                <PieChartsCard />
              </div>
            </TabPanel>
            <TabPanel
              className='investments-tab'
              header={props.t('transactions')}
            >
              <div className='transactions'>
                <TransactionsHistory />
              </div>
            </TabPanel>

          
            <TabPanel
              className='investments-tab'
              header={props.t('external_accounts')}
            >
              <div
                className='add-account-container'
                onClick={() => {
                  setAddAccountDialog(true)
                }}
              >
                <p className='add-account-title'>{props.t('add_account')}</p>
                <img src={PlusIcon} className='plus-icon' />
              </div>
              <div className='budgeting-block'>
                <ExternalAccountsList />
              </div>
            </TabPanel>
          
            <TabPanel className='investments-tab' header={props.t('activity')}>
              <div className='budgeting-block'>
                <ActivityDetails />
              </div>
            </TabPanel>
            <TabPanel className='investments-tab' header={props.t('credit')}>
              <div className='budgeting-block'>
                {budgetingData?.payload?.credit_score ? (
                  <CreditDetails />
                ) : (
                  <Dialog
                    visible={!budgetingData?.payload?.credit_score}
                    header={props.t('add_credit_score')}
                    onHide={() => setActiveTab(0)}
                    style={{
                      width: props.isSmallScreen ? '90vw' : '50vw',
                      height: props.isSmallScreen ? '90vh' : '70vh',
                    }}
                  >
                    <CreditScoreForm />
                  </Dialog>
                )}
              </div>
            </TabPanel>
          </TabView> */}
          {/* old ui end here */}
        </div>
        {/* <div className='right'>
          {budgetingData?.payload?.notifications?.length > 0 && (
            <div
              className='budgeting-block'
              onClick={() => {
                setShowNotifications(true)
              }}
            >
              <NotificationsCard />
            </div>
          )} */}
        {/* <div
            className='budgeting-block half-size'
            onClick={() => {
              setPage((prevState) => [...prevState, 'activity'])
            }}
          >
            <ActivityCard />
          </div>
          {budgetingData?.payload?.credit_score ? (
            <div
              className='budgeting-block half-size'
              onClick={(e) => {
                setPage((prevState) => [...prevState, 'credit'])
              }}
            >
              <CreditCard />
            </div>
          ) : (
            <div
              className='budgeting-block'
              onClick={() => {
                setCreditScoreDialog(true)
              }}
            >
              <AddCreditScore />
            </div>
          )} */}

        {/* <div className='budgeting-block'>
            <PieChartsCard />
          </div> */}
        {/* <div
            className='budgeting-block'
            onClick={(e) => {
              setShowExternalAccounts((prevState) => !prevState)
            }}
          >
            <ExternalAccountsCard />
          </div>
          {showExternalAccounts && (
            <div className='budgeting-block'>
              <ExternalAccountsList />
            </div>
          )}
        </div> */}

        <Dialog
          visible={creditScoreDialog}
          header={props.t('add_credit_score')}
          onHide={() => setCreditScoreDialog(false)}
          style={{
            width: props.isSmallScreen ? '90vw' : '50vw',
            height: props.isSmallScreen ? '90vh' : '70vh',
          }}
        >
          <CreditScoreForm />
        </Dialog>

        <Dialog
          visible={addAccountDialog}
          header={props.t('add_account')}
          onHide={() => setAddAccountDialog(false)}
          style={{
            width: props.isSmallScreen ? '90vw' : '40vw',
            height: props.isSmallScreen ? '90vh' : '70vh',
          }}
          className='grey-dialog'
        >
          <AddExternalAccount />
        </Dialog>

        <Dialog
          visible={showDisclaimer}
          header={props.t('credit_score_disclaimer')}
          onHide={() => setShowDisclaimer(false)}
          style={{ width: props.isSmallScreen ? '90vw' : '50vw' }}
        >
          <p>{props.t('info_credit_score')}</p>
        </Dialog>

        <Dialog
          visible={showNotifications}
          header={props.t('all_budgeting_notifications')}
          onHide={() => setShowNotifications(false)}
          style={{
            width: props.isSmallScreen ? '90vw' : '50vw',
            height: props.isSmallScreen ? '90vh' : '70vh',
          }}
        >
          <Notifications />
        </Dialog>
      </div>
    )
  }

  const renderPage = () => {
    const currentPage = page[page.length - 1]

    switch (currentPage) {
      case 'home':
        return home()
      case 'activity':
        return <ActivityDetails />
      case 'credit':
        return <CreditDetails />
      case 'external-account':
        return <ExternalAccountDetails />
      default:
        return null
    }
  }

  if (props.t) {
    if (loading) {
      return <Loader logoLoader={true} />
    } else {
      return (
        <BudgetingContext.Provider
          value={{
            budgetingData,
            setBudgetingData,
            t: props.t,
            user: props.user,
            isSmallScreen: props.isSmallScreen,
            showExternalAccounts,
            page,
            setPage,
            getAllAccountInfo: props.getAllAccountInfo,
            setAddAccountDialog,
            refreshBudgetingInfo,
            fetchBudgetingInfo,
            selectedAccount,
            setSelectedAccount,
            setShowDisclaimer,
          }}
        >
          {renderPage()}
        </BudgetingContext.Provider>
      )
    }
  } else {
    return null
  }
}
