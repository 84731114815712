import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import VerificationInput from 'react-verification-input'
import Spinner from 'react-bootstrap/Spinner'
import PhoneInput from 'react-phone-input-2'
import axios from 'axios'

import { IoIosClose } from 'react-icons/io'
import CryptoBlob from '../../../assets/newLandingPage/crypto-blob.jpg'
import Modal from 'react-modal'
import Footer from '../../PublicFooter'
import AnalyticsTable from '../../components/AnalyticsTable'
import { handleAppRedirect } from '../../../utility-functions/handleAppRedirect'
import MainNavbar from '../../components/MainNavBar'

import GooglePlay from '../../../assets/newUI/googlePlay.png'
import Appstore from '../../../assets/newUI/appStore.png'
import SearchEndpoint from '../../SearchEndpoint'

const listOfCryptos = [
  {
    symbol: 'BTC:USD',
    name: 'Bitcoin',
  },
  {
    symbol: 'ETH:USD',
    name: 'Ethereum',
  },
  {
    symbol: 'DOGE:USD',
    name: 'Dogecoin',
  },
  {
    symbol: 'SOL:USD',
    name: 'Solana',
  },
  {
    symbol: 'LTC:USD',
    name: 'Litecoin',
  },
  {
    symbol: 'MATIC:USD',
    name: 'Polgon',
  },
]

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    overflow: 'auto',
    borderRadius: '5px',
    border: 'none',
    boxShadow: '0px 4px 23px rgba(0, 0, 0, 0.08)',
  },
}

export default class Crypto extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: false,
      dropdown: false,
      isSmallScreen: false,
      tradeDropdown: false,
      cryptoDropdown: false,
      financialDropdown: false,
      changePasswordStep: 0,
    }
    this.topOfPage = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
    window.addEventListener('resize', this.updateWindowDimensions)
    this.updateWindowDimensions()
    this.getCryptoData()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
    window.removeEventListener('scroll', this.listenScrollEvent)
  }

  listenScrollEvent = () => {
    if (window.scrollY > window.screen.height - 100) {
      this.setState({ scrolled: true })
    } else {
      this.setState({ scrolled: false })
    }
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  getCryptoData = async () => {
    let symbols = listOfCryptos.map((crypto) => crypto.symbol)
    let bulkRes = await axios.get(
      `${SearchEndpoint}/ticker-bulk-live?search=${symbols}`
    )
    this.setState({
      mostActiveData: bulkRes.data.content_list,
    })
    // Promise.all(
    //   listOfCryptos.map(async (stock) => {
    //     let res = await axios.get(
    //       `https://api.allocaterite.com/search/ticker-bulk-live?search=${stock.symbol}`
    //     );
    //     return res.data.content[0][stock.symbol];
    //   })
    // ).then((res) => {
    //   this.setState({
    //     mostActiveData: res,
    //   });
    // });
  }

  handleNavigation = (link) => {
    document.body.style.overflow = 'unset'
    this.props.history.push(link)
  }

  render() {
    if (this.props.t) {
      return (
        <>
          <MainNavbar {...this.props} colorChange={true} />
          <div className='center-container'>
            <div className='crypto-hero-container'>
              <div className='text-side'>
                <p className='crypto-main-title'>
                  {this.props.t('cryptotabtitle')}
                </p>
                <p className='crypto-subtext'>
                  {this.props.t('cryptotabinfo')}
                </p>
                <p
                  className='default__button bold-text'
                  onClick={() => handleAppRedirect()}
                >
                  {this.props.t('gettheapp')}
                </p>
              </div>
              <div className='image-side'>
                <img src={CryptoBlob} alt='' />
              </div>
            </div>
          </div>
          <div className='data-container'>
            <div className='crypto-title'>
              <p className='title-text bold-text'>
                {this.props.t('cryptocurrencyforecast')}
              </p>
            </div>

            <div className='crypto-table'>
              <div className='wrapper-container'>
                {this.state.mostActiveData && (
                  <AnalyticsTable
                    data={this.state.mostActiveData}
                    t={this.props.t}
                  />
                )}
              </div>
            </div>
            {/* TRANSLATE LINE BELOW */}
            <p className='table-disclaimer-text'>
              *Investall's forecasts are only predictions and do not represent
              actual returns.
            </p>
            {/* <div className='get-the-app'>
              <p style={{ fontSize: 24 }}>
                {this.props.t('findouttheshortterm')}
              </p>
              <div
              >
                {this.props.t('gettheapp')}
              </div>
              <div
                style={{
                  display: 'flex',
                  margin: '15px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <a
                  href='https://apps.apple.com/us/app/forescite/id1567589436'
                  target='_blank'
                >
                  <img
                    target
                    style={{
                      height: 35,
                      width: 35,
                      marginRight: '5px',
                      cursor: 'pointer',
                    }}
                    src={Appstore}
                  />
                </a>
                <a
                  href='https://play.google.com/store/apps/details?id=com.forescite'
                  target='_blank'
                >
                  <img
                    style={{
                      height: 35,
                      widht: 35,
                      cursor: 'pointer',
                      marginLeft: '5px',
                    }}
                    src={GooglePlay}
                  />
                </a>
              </div>
            </div> */}

            <div className='spacer' />
          </div>
          <Footer t={this.props.t} isSmallScreen={this.state.isSmallScreen} />
        </>
      )
    } else return null
  }
}
