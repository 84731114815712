import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";

class ChineseStrategiesPolicy extends Component {
  render() {
    return (
      <Container fluid className="strategies-policy-container">
        <Container>
          <Row>
            <p className="strategies-policy-text">
              *{" "}
              <span style={{ fontWeight: "bold" }}>
                所示的绩效数据代表推测的回报，不能保证未来的结果。
              </span>{" "}
              AllocateRite
              LLC或“基金”投资的投资回报和本金价值将会波动，因此在兑换时，其价值可能高于或低于原始成本。当前的表现可能高于或低于所报的数据。少于一年的回报为累积值。指数的表现并不是任何特定投资的精确表示，因为您不能直接投资于指数。投资不是FDIC保险，它们可能会失去价值。回报包括股价变动和再投资股息，但不考虑买卖证券的交易成本，资本收益或个人投资的所得税。所呈现的业绩数据基于2005年1月1日至当前期末日期的基金业绩的模拟指数数据，使用买入和持有策略以及基金每月或每月内重新平衡的开始时的后测表现。回溯测试的表现是假设的（它不反映实际账户中的交易），仅供参考，以表明在相关时间段内指数组合的历史表现。
              AllocateRite将此假设数据称为模拟被动返回（“SPR”）。每月SPR从2005年1月1日到最新日期计算。由AllocateRite准备的这些模拟结果正在由第三方来源验证。除了指出的实际情况外，所有绩效回报都应假设为模拟假设回报。
            </p>
          </Row>
          <Row>
            <p className="strategies-policy-text">
              注：过去的表现不代表将来的回报。组合回报包括再投资收益。
            </p>
          </Row>
          <Row>
            <p className="strategies-policy-text">
              60:40的回报是指由60%的S&PP500回报以及40%7-10年美国国债ETF
            </p>
          </Row>
          <Row>
            <p className="strategies-policy-text">
              更多信息请参看{" "}
              <a
                className="terms-link"
                href="http://localhost:3000/DisclaimersDisclosures"
              >
                Disclaimers and Disclosures
              </a>{" "}
              申明及披露。
            </p>
          </Row>
        </Container>
      </Container>
    );
  }
}

export default ChineseStrategiesPolicy;
