import React from 'react'
import LogoWithText from '../assets/newUI/foreScite_yestext.png'
import LogoNoText from '../assets/newUI/foreScite_noText.png'
import SearchGlass from '../assets/newUI/searchGlass.png'
import { NavLink /*Redirect*/ } from 'react-router-dom'

import upArrow from '../assets/single_up.svg'
import downArrow from '../assets/single_down.svg'
import strongUpArrow from '../assets/double_up.svg'
import strongDownArrow from '../assets/double_down.svg'
import flatArrow from '../assets/flat.svg'
import { FaBars } from 'react-icons/fa'
import ENDPOINT from './Endpoint'

const GREEN = '#669890'
const LIGHTGREEN = '#7bc0c3'
const WHITE = 'rgb(255,255,255)'

export default class PublicHeader extends React.Component {
  state = {
    searchActive: false,
    searchText: '',
    searchResults: [],
    dataLoading: false,
    scrollY: 0,
    dropdown: false,
  }

  getArrow = (direction) => {
    switch (direction) {
      case -1:
        return downArrow
      case 1:
        return upArrow
      case 2:
        return strongUpArrow
      case -2:
        return strongDownArrow
      default:
        return flatArrow
    }
  }

  search = (event) => {
    let searchText = event.target.value.toUpperCase()
    this.setState({ searchText }, () => {
      const { isSearching } = this.state
      if (isSearching) {
        return
      } else {
        this.setState({ isSearching: true })
        setTimeout(() => {
          this.setState({ isSearching: false }, this.onSubmitSearchMomentum)
        }, 2000)
      }
    })
  }

  onSubmitSearchMomentum = () => {
    const { searchText } = this.state
    this.setState({ dataLoading: true })
    // console.log(searchMomentumText);
    let url = `${ENDPOINT}/risk-monkey/v2/momentum?search=${searchText}`

    fetch(url, {
      'Access-Control-Allow-Headers': {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Access-Control-Request-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => {
        var data = responseJson.content
        // console.log("SEARCHING RESULT: " , data);

        for (var i = 0; i < data.length; i++) {
          var item = data[i]
          var color = ''
          if (item.rar) {
            switch (item.rar) {
              case 'H':
                color = '#dc143c'
                break
              case 'M':
                color = '#fcac0c'
                break
              default:
                color = '#00A99D'
            }
          } else {
            color = 'rgb(0,0,0)'
          }
          item.symbolColor = color
          data[i] = item
        }
        this.setState({
          dataLoading: false,
          searchResults: data,
        })
      })
      .catch((err) => {
        this.setState({ searchResults: false })
        console.log(err)
      })
  }

  showResults = () => {
    let arr = []
    // this.state.searchResults.forEach(ele=>{
    for (let i = 0; i < 5; i++) {
      arr.push(
        <NavLink
          onClick={() => this.setState({ searchActive: false })}
          onMouseLeave={() => this.setState({ hover: false })}
          onMouseEnter={() =>
            this.setState({ hover: this.state.searchResults[i] })
          }
          to={`/Dashboard/Trade/${this.state.searchResults[i].symbol}`}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor:
              this.state.hover === this.state.searchResults[i]
                ? '#f1f1f1'
                : '#fafafa',
            cursor: 'pointer',
            textDecoration: 'none',
            color: 'rgb(17,17,17)',
            borderBottom: 'solid',
            borderWidth: 0.25,
            borderColor: '#cccccc',
          }}
        >
          <div
            style={{
              width: '80%',
              margin: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '30%' }}>
              <p style={{ margin: 0 }}>{this.state.searchResults[i].symbol}</p>
            </div>
            <div style={{ width: '30%' }}>
              <p style={{ margin: 0 }}>
                {this.state.searchResults[i].priceIntraday
                  ? `$${this.state.searchResults[i].priceIntraday.toFixed(2)}`
                  : '$00.00'}
              </p>
            </div>
            <div style={{ width: '30%', textAlign: 'right' }}>
              <img
                src={this.getArrow(this.state.searchResults[i].direction)}
                style={{
                  // marginTop: 5,
                  width: 20,
                  height: 20,
                }}
              />
            </div>
          </div>
        </NavLink>
      )
      if (i + 1 >= this.state.searchResults.length) {
        i += 5
      }
    }
    return <div style={{ position: 'absolute', width: '100%' }}>{arr}</div>
  }

  searchbar = () => {
    if (this.state.searchActive) {
      // console.log('this.state.searchText',this.state.searchText)
      // console.log('this.state.searchResults',this.state.searchResults)
      if (this.props.isSmallScreen) {
        return (
          <div style={{ position: 'relative' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: 25,
                width: 250,
                borderRadius:
                  this.state.searchResults.length > 0 ? '20px 20px 0 0 ' : 20,
                backgroundColor: '#fafafa',
                paddingLeft: 20,
              }}
            >
              <img
                src={SearchGlass}
                style={{ height: 15, marginRight: 5 }}
                onClick={() =>
                  this.setState({ searchActive: !this.state.searchActive })
                }
              />
              <input
                autoFocus
                value={this.state.searchText}
                id='headerSearch'
                onChange={(e) => this.search(e)}
                type='text'
                placeholder='Enter any stock symbol'
                style={{
                  border: 'none',
                  outline: 'none',
                  backgroundColor: '#fafafa',
                }}
              />
            </div>
            {this.state.searchResults.length > 0 ? (
              <div style={{ position: 'relative' }}>{this.showResults()}</div>
            ) : null}
          </div>
        )
      }
      return (
        <li style={{ marginRight: '1.5rem', position: 'relative' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: 25,
              width: 250,
              borderRadius:
                this.state.searchResults.length > 0 ? '20px 20px 0 0 ' : 20,
              backgroundColor: '#fafafa',
              paddingLeft: 20,
            }}
          >
            <img
              src={SearchGlass}
              style={{ height: 15, marginRight: 5 }}
              onClick={() =>
                this.setState({ searchActive: !this.state.searchActive })
              }
            />
            <input
              autoFocus
              value={this.state.searchText}
              id='headerSearch'
              onChange={(e) => this.search(e)}
              type='text'
              placeholder='Enter any stock symbol'
              style={{
                border: 'none',
                outline: 'none',
                backgroundColor: '#fafafa',
              }}
            />
          </div>
          {this.state.searchResults.length > 0 ? (
            <div style={{ position: 'relative' }}>{this.showResults()}</div>
          ) : null}
        </li>
      )
    } else {
      if (this.props.isSmallScreen) {
        return (
          <div style={{ position: 'relative' }}>
            <img
              src={SearchGlass}
              style={{ height: 20 }}
              onClick={() => {
                this.setState({ searchActive: !this.state.searchActive })
              }}
            />
          </div>
        )
      }
      return (
        <li style={{ marginRight: '1.5rem', position: 'relative' }}>
          <img
            src={SearchGlass}
            style={{ height: 20, cursor: 'pointer' }}
            onClick={() => {
              this.setState({ searchActive: !this.state.searchActive })
            }}
          />
        </li>
      )
    }
  }

  showDropdown = () => {
    return (
      <ul
        style={{
          flexDirection: 'column',
          listStyleType: 'none',
          margin: 0,
          padding: 5,
          position: 'relative',
          right: '80%',
          backgroundColor: 'rgb(255,255,255)',
          borderLeft: 'solid',
          borderRight: 'solid',
          borderBottom: 'solid',
          borderWidth: '0.25px',
          borderColor: '#dddddd',
        }}
      >
        <li
          style={{
            position: 'relative',
            cursor: 'pointer',
            borderBottom: 'solid',
            borderColor: '#dddddd',
            borderWidth: '0.25px',
            padding: 15,
          }}
        >
          <NavLink
            style={{
              color: 'rgb(17,17,17)',
              textAlign: 'right',
              textDecoration: 'none',
            }}
            to='/platform-info'
          >
            <p
              style={{ textAlign: 'right', color: 'rgb(17,17,17)', margin: 0 }}
            >
              Platform
            </p>
          </NavLink>
        </li>
        <li
          style={{
            position: 'relative',
            cursor: 'pointer',
            borderBottom: 'solid',
            borderColor: '#dddddd',
            borderWidth: '0.25px',
            padding: 15,
          }}
        >
          <NavLink
            style={{
              color: 'rgb(17,17,17)',
              textAlign: 'right',
              textDecoration: 'none',
            }}
            to='/About'
          >
            <p
              style={{ textAlign: 'right', color: 'rgb(17,17,17)', margin: 0 }}
            >
              About
            </p>
          </NavLink>
        </li>
        <li
          style={{
            position: 'relative',
            cursor: 'pointer',
            borderBottom: 'solid',
            borderColor: '#dddddd',
            borderWidth: '0.25px',
            padding: 15,
          }}
        >
          <NavLink
            style={{
              color: 'rgb(17,17,17)',
              textAlign: 'right',
              textDecoration: 'none',
            }}
            to='/Strategies'
          >
            <p
              style={{ textAlign: 'right', color: 'rgb(17,17,17)', margin: 0 }}
            >
              Strategies
            </p>
          </NavLink>
        </li>
        <li
          style={{
            position: 'relative',
            cursor: 'pointer',
            borderBottom: 'solid',
            borderColor: '#dddddd',
            borderWidth: '0.25px',
            padding: 15,
          }}
        >
          <NavLink
            style={{
              color: 'rgb(17,17,17)',
              textAlign: 'right',
              textDecoration: 'none',
            }}
            to='/Login'
          >
            <p
              style={{ textAlign: 'right', color: 'rgb(17,17,17)', margin: 0 }}
            >
              {this.props.user ? 'Dashboard' : 'Sign In'}
            </p>
          </NavLink>
        </li>
        {this.props.user ? (
          <li
            style={{
              position: 'relative',
              cursor: 'pointer',
              borderBottom: 'solid',
              borderColor: '#dddddd',
              borderWidth: '0.25px',
              padding: 15,
            }}
          >
            <div
              style={{
                color: 'rgb(17,17,17)',
                textAlign: 'right',
                textDecoration: 'none',
              }}
              onClick={() => this.props.signOut()}
            >
              <p
                style={{
                  textAlign: 'right',
                  color: 'rgb(17,17,17)',
                  margin: 0,
                }}
              >
                Sign Out
              </p>
            </div>
          </li>
        ) : (
          <li
            style={{
              position: 'relative',
              cursor: 'pointer',
              borderBottom: 'none',
              borderColor: '#dddddd',
              borderWidth: '0.25px',
              padding: 15,
            }}
          >
            <NavLink
              style={{
                color: 'rgb(17,17,17)',
                textAlign: 'right',
                textDecoration: 'none',
              }}
              to='/Login'
            >
              <p
                style={{
                  textAlign: 'right',
                  color: 'rgb(17,17,17)',
                  margin: 0,
                }}
              >
                Sign Up
              </p>
            </NavLink>
          </li>
        )}
      </ul>
    )
  }

  render() {
    if (this.props.cp) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: '1rem 0',
            clear: 'both',
            position: 'fixed',
            top: 0,
            backgroundColor: WHITE,
            boxShadow: '0px 0px 5px 1px #cccccc',
            zIndex: 199,
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              width: '80%',
              margin: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {this.props.isSmallScreen ? (
              <NavLink to='/' style={{}}>
                {' '}
                {/** header left */}
                <img src={LogoNoText} style={{ height: 20 }} />
              </NavLink>
            ) : (
              <NavLink to='/'>
                {' '}
                {/** header left */}
                <img src={LogoWithText} style={{ height: 40 }} />
              </NavLink>
            )}
            {this.props.user ? (
              <div
                style={{
                  marginRight: '1.5rem',
                  position: 'relative',
                  cursor: 'pointer',
                }}
              >
                <div
                  onClick={() => this.props.signOut()}
                  style={{
                    display: 'flex',
                    cursor: 'pointer',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 20,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 2,
                    paddingBottom: 2,
                    backgroundColor: GREEN,
                  }}
                >
                  <p
                    style={{ margin: 0, color: WHITE, textDecoration: 'none' }}
                  >
                    Sign Out
                  </p>
                </div>
              </div>
            ) : (
              <div
                style={{
                  marginRight: '1.5rem',
                  position: 'relative',
                  cursor: 'pointer',
                }}
              >
                <NavLink
                  to='/Login'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 20,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 2,
                    paddingBottom: 2,
                    backgroundColor: GREEN,
                  }}
                >
                  <p
                    style={{ margin: 0, color: WHITE, textDecoration: 'none' }}
                  >
                    Sign In
                  </p>
                </NavLink>
              </div>
            )}
          </div>
        </div>
      )
    }
    if (this.props.isSmallScreen) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            padding: '1rem 0',
            clear: 'both',
            position: 'fixed',
            top: 0,
            backgroundColor: WHITE,
            boxShadow: '0px 0px 5px 1px #cccccc',
            zIndex: 199,
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              width: '80%',
              margin: 'auto',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <NavLink to='/' style={{}}>
              {' '}
              {/** header left */}
              <img src={LogoNoText} style={{ height: 20 }} />
            </NavLink>
            {/* <div style={{ width:'250px', display:'flex', justifyContent:'flex-end'}}>
                            {this.searchbar()}
                        </div> */}
            <div
              onClick={() => this.setState({ dropdown: !this.state.dropdown })}
              style={{ marginLeft: 10 }}
            >
              <div style={{ right: 5 }}>
                <FaBars />
              </div>
              {this.state.dropdown ? (
                <div
                  style={{
                    backgroundColor: 'white',
                    position: 'absolute',
                    marginTop: 15,
                    marginRight: 80,
                  }}
                >
                  {this.showDropdown()}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            margin: '0 auto',
            padding: '1rem 0',
            clear: 'both',
            position: 'fixed',
            top: 0,
            backgroundColor: WHITE,
            paddingLeft: '5%',
            paddingRight: '5%',
            boxShadow: '0px 0px 5px 1px #cccccc',
            zIndex: 199,
          }}
        >
          <NavLink to='/'>
            {' '}
            {/** header left */}
            <img src={LogoWithText} style={{ height: 40 }} />
          </NavLink>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {/**header right */}

            <ul
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-end',
                alignItems: 'center',
                fontSize: '18px',
                listStyleType: 'none',
                margin: 0,
              }}
            >
              {/* {this.searchbar()} */}

              <li
                style={{
                  marginRight: '1.5rem',
                  position: 'relative',
                  cursor: 'pointer',
                }}
              >
                <NavLink
                  style={{ color: 'rgb(17,17,17)', textDecoration: 'none' }}
                  to='/platform-info'
                >
                  Platform
                </NavLink>
              </li>
              <li
                style={{
                  marginRight: '1.5rem',
                  position: 'relative',
                  cursor: 'pointer',
                }}
              >
                <NavLink
                  style={{ color: 'rgb(17,17,17)', textDecoration: 'none' }}
                  to='/About'
                >
                  About
                </NavLink>
              </li>
              <li
                style={{
                  marginRight: '1.5rem',
                  position: 'relative',
                  cursor: 'pointer',
                }}
              >
                <NavLink
                  style={{ color: 'rgb(17,17,17)', textDecoration: 'none' }}
                  to='/Strategies'
                >
                  Strategies
                </NavLink>
              </li>
              {this.props.user ? (
                <li
                  style={{
                    marginRight: '1.5rem',
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                >
                  <NavLink
                    style={{ color: 'rgb(17,17,17)', textDecoration: 'none' }}
                    to='/Dashboard'
                  >
                    Dashboard
                  </NavLink>
                </li>
              ) : (
                <li
                  style={{
                    marginRight: '1.5rem',
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                >
                  <NavLink
                    to='/SignUp'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 20,
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingTop: 2,
                      paddingBottom: 2,
                      backgroundColor: GREEN,
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        color: WHITE,
                        textDecoration: 'none',
                      }}
                    >
                      Sign Up
                    </p>
                  </NavLink>
                </li>
              )}
              {this.props.user ? (
                <li
                  style={{
                    marginRight: '1.5rem',
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                >
                  <div
                    onClick={() => this.props.signOut()}
                    style={{
                      display: 'flex',
                      cursor: 'pointer',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 20,
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingTop: 2,
                      paddingBottom: 2,
                      backgroundColor: GREEN,
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        color: WHITE,
                        textDecoration: 'none',
                      }}
                    >
                      Sign Out
                    </p>
                  </div>
                </li>
              ) : (
                <li
                  style={{
                    marginRight: '1.5rem',
                    position: 'relative',
                    cursor: 'pointer',
                  }}
                >
                  <NavLink
                    to='/Login'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 20,
                      paddingLeft: 10,
                      paddingRight: 10,
                      paddingTop: 2,
                      paddingBottom: 2,
                      backgroundColor: GREEN,
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        color: WHITE,
                        textDecoration: 'none',
                      }}
                    >
                      Sign In
                    </p>
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>
      )
    }
  }
}
