import React, { Component } from 'react';

export class FooterPageTitle extends Component {
    render() {
        return (
            <div className="footerPageTitle" style={Style.Title}>
                <div style={Style.Text}>{this.props.title}</div>
                <img width="100%" alt="footer-title-background" src="/images/footerTitle.jpg" />
            </div>
        )
    }
}

const Style={
    Title:{
        position: 'relative'

    },
    Text:{
        position: 'absolute',
        fontSize: '4.3vw',
        color: '#FFF',
        left: '50%', 
        top: '50%', 
        transform: 'translate(-50%,-50%)'

    }
}

export default FooterPageTitle
