import React, { Component } from "react";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";

class ChineseRouletteChart extends Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.state = {
      isSmallScreen: false,
      tooltips: [
        {
          id: 1,
          placement: `top`,
          smallScreenPlacement: `bottom`,
          title: `便捷的申请`,
          text: `今天就开始更聪明地投资。我们的申请流程可帮助您便捷的创建账户并开始使用AllocateRite投资平台。`
        },
        {
          id: 2,
          placement: `right`,
          smallScreenPlacement: `bottom`,
          title: `量身定制的投资组合`,
          text: `使用我们的模型或选择最符合您的业务模式的基金系列或特定ETF。 我们的平台将自动回溯您的选择，以确保与AllocateRite策略兼容。`
        },
        {
          id: 3,
          placement: `right`,
          smallScreenPlacement: `bottom`,
          title: `自动化贸易再平衡`,
          text: `您指定每个账户内的总投资资金，我们的分配引擎会优化交易订单以帮助您或您的客户的最佳执行。`
        },
        {
          id: 4,
          placement: `bottom`,
          smallScreenPlacement: `bottom`,
          title: `定制化的投资组合构建`,
          text: `使用我们的模型或选择最适合您商业模式的基金系列或特定ETF。我们的平台会自动回溯您的选择，以确保与AllocateRite战略的兼容性。`
        },
        {
          id: 5,
          placement: `left`,
          smallScreenPlacement: `bottom`,
          title: `信息资源中心`,
          text: `建立每月的市场评论，投资收益报告，白皮书和演示文稿。`
        },
        {
          id: 6,
          placement: `left`,
          smallScreenPlacement: `bottom`,
          title: `灵活的税务优化`,
          text: `ETF本身具有税收效率，我们会根据税收效率选择适合的投资。但是我们的系统可以进一步的让您从优化选项菜单中选择您的税收服务，包括完全自动化的基于AI的解决方案。`
        }
      ]
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    if (window.innerWidth < 600) {
      this.setState({ isSmallScreen: true });
    }
  }

  render() {
    return (
      <Container className="roulette-chart-container">
        <Row>
          <Col>
            <img
              id="roulette-chart"
              src="/images/Roulette-Chart-cn.png"
              alt="roulette-chart"
            />
            {this.state.tooltips.map(tip => (
              <OverlayTrigger
                key={tip.id}
                placement={
                  this.state.isSmallScreen
                    ? tip.smallScreenPlacement
                    : tip.placement
                }
                overlay={
                  <Tooltip
                    key={tip.id}
                    className="roulette-chart-tooltip"
                    id={`tooltip-${tip.id}`}
                  >
                    <p className="roulette-chart-tooltip-title">{tip.title}</p>
                    <p className="roulette-chart-tooltip-text">{tip.text}</p>
                  </Tooltip>
                }
              >
                <button
                  key={tip.id}
                  className="roulette-chart-tooltip-button"
                  id={`tooltip-button-${tip.id}`}
                >
                  i
                </button>
              </OverlayTrigger>
            ))}
          </Col>
        </Row>
        <Row>
          <Col className="platform-title">
            <p className="roulette-chart-title">综合数字顾问平台</p>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ChineseRouletteChart;
