import React, { Component } from 'react'
import { TailSpin } from 'react-loader-spinner'

export default class MyLoader extends Component {
  render() {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TailSpin
          heigth='50'
          width='50'
          color='#3525e4'
          background='white'
          ariaLabel='loading'
        />
      </div>
    )
  }
}
