//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//CONTEXT
import { BudgetingContext } from './Budgeting'

//ICONS
import BackIcon from '../../assets/product-activation-back-icon.svg'

//STYLES
import '../../scss/components/budgeting/ActivityDetails.scss'

//UTILS
import formatCurrency from '../../utility-functions/formatCurrency'

//COMPONENTS
import ActivityExpenses from './ActivityExpenses'
import ActivityEmergencyFunds from './ActivityEmergencyFunds'
import ActivityLineChart from './ActivityLineChart'

export default function ActivityDetails() {
  const { t, budgetingData, setPage } = useContext(BudgetingContext)
  const details = budgetingData?.payload?.budgeting

  const Insights = () => {
    const data = calculateInsights()
    return (
      <div className='insights'>
        <p className='activity-details-section-header'>{t('insights')}</p>
        <p className='insights-desc'>{data.message}</p>
        <div className='bar-content'>
          <p className='bar-label'>
            <p className='bar-label'>
              {t('last_month_expenditure')} - {formatCurrency(data.lastExpense)}
            </p>
          </p>
          <div
            className='bar-container'
            style={{
              backgroundColor: '#3627E4',
              width: data.lastMonthWidth,
            }}
          ></div>
        </div>
        <div className='bar-content'>
          <p className='bar-label'>
            {t('monthly_average_expenditure')} -{' '}
            {formatCurrency(data.monthlyAverage)}
          </p>
          <div
            className='bar-container'
            style={{
              backgroundColor: '#7B61FF',
              width: data.monthWidth,
            }}
          ></div>
        </div>
      </div>
    )
  }

  const calculateInsights = () => {
    const monthlyAverage =
      details?.expense?.expense_compare?.six_full_months_average?.Expense
    const lastExpense =
      details?.expense?.expense_compare?.last_full_months?.Expense
    const data = {}
    if (lastExpense < monthlyAverage) {
      data.message = t('you_have_spent_less_last_month')
      data.monthWidth = '100%'
      data.lastMonthWidth = `${(lastExpense / monthlyAverage) * 100}%`
    } else if (lastExpense > monthlyAverage) {
      data.message = t('you_have_spent_more_last_month')
      data.monthWidth = `${(monthlyAverage / lastExpense) * 100}%`
      data.lastMonthWidth = '100%'
    } else {
      data.message = t('you_have_spent_the_same_amount')
      data.monthWidth = '100%'
      data.lastMonthWidth = '100%'
    }
    data.monthlyAverage = monthlyAverage
    data.lastExpense = lastExpense
    return data
  }

  return (
    <div className='activity-details'>
      {/* <img
        src={BackIcon}
        className='back-icon'
        onClick={() => {
          setPage((prevState) => {
            const pages = [...prevState]
            pages.pop()
            return pages
          })
        }}
      /> */}
      <div className='activity-details-content'>
        <p className='activity-details-header'>{t('activity')}</p>
        <p className='big-price'>
          {formatCurrency(details?.incStat?.incStatSummary?.totCash)}
        </p>
        <div className='activity-details-section'>
          <div className='activity-row'>
            <p className='activity-label'>{t('income')}</p>
            <p className='activity-value'>
              {formatCurrency(details?.incStat?.incStatSummary?.totIncome)}
            </p>
          </div>
          <div className='activity-row'>
            <p className='activity-label'>{t('expenses')}</p>
            <p className='activity-value'>
              {formatCurrency(details?.incStat?.incStatSummary?.totEssExpense)}
            </p>
          </div>
          <div className='activity-row'>
            <p className='activity-label'>{t('ne_expense')}</p>
            <p className='activity-value'>
              {formatCurrency(
                details?.incStat?.incStatSummary?.totNonEssExpense
              )}
            </p>
          </div>
        </div>
        <div className='activity-details-section shadow-card'>
          <ActivityExpenses />
        </div>
        <div className='activity-details-section shadow-card'>
          <Insights />
        </div>
        <div className='activity-details-section shadow-card'>
          <ActivityLineChart />
        </div>
        <div className='activity-details-section shadow-card'>
          <ActivityEmergencyFunds />
        </div>
      </div>
    </div>
  )
}
