import React, { Component } from 'react'
import '../../../../scss/pages/account-page/non-product-account-info/NonProdPendingTransfers.scss'

export default class NonProdPendingTransfers extends Component {
  state = {
    showAllPendingTransfers: false,
  }

  displayPendingTransfers() {
    const pendingTransfersList =
      this.props.accountTransactionsData.accountOrderHistory.pendingTransfers
    if (pendingTransfersList.length > 0) {
      return (
        <div className='non-prod-pending-transfers-container'>
          <p className='non-prod-pending-transfers-header'>
            {this.props.t('pendingtransfers')}
          </p>
          <div className='non-prod-pending-transfers-list shadow-card'>
            <div
              style={{ width: '100%', display: 'flex', marginBottom: '15px' }}
            ></div>
            {/* {pendingTransfersList.length > 5 ? (
              <div>
                {this.state.showAllPendingTransfers ? (
                  <div>
                    {pendingTransfersList.map((transfer) => {
                      return this.displayPendingTransfersRow(transfer);
                    })}
                  </div>
                ) : (
                  <div>
                    {pendingTransfersList.map((transfer, index) => {
                      if (index < 5)
                        return this.displayPendingTransfersRow(transfer);
                    })}
                  </div>
                )}
              </div>
            ) : (
              pendingTransfersList.map((transfer) => {
                return this.displayPendingTransfersRow(transfer);
              })
            )} */}
            <div
              style={{ maxHeight: '30vh', overflow: 'auto', paddingRight: 5 }}
            >
              {pendingTransfersList.map((transfer) => {
                return this.displayPendingTransfersRow(transfer)
              })}
            </div>
          </div>
          {/* {pendingTransfersList.length > 5 ? (
            <div>
              {this.state.showAllPendingTransfers ? (
                <button
                  style={{
                    width: '100%',
                    borderRadius: '0 0 25px 25px',
                    background: 'black',
                    color: 'grey',
                    padding: '8px',
                  }}
                  onClick={() => {
                    this.setState({ showAllPendingTransfers: false })
                  }}
                >
                  Show Less
                </button>
              ) : (
                <button
                  style={{
                    width: '100%',
                    borderRadius: '0 0 25px 25px',
                    background: 'black',
                    color: 'grey',
                    padding: '8px',
                  }}
                  onClick={() => {
                    this.setState({ showAllPendingTransfers: true })
                  }}
                >
                  Show More
                </button>
              )}
            </div>
          ) : null} */}
        </div>
      )
    } else return null
  }

  displayPendingTransfersRow(transfer) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '15px 0',
        }}
        key={transfer.transactionDate}
      >
        <div
          style={{
            alignContent: 'center',
            width: '50%',
            wordWrap: 'break-word',
          }}
        >
          <p
            style={{
              color: transfer.type === 'DEPOSITS' ? '#3F46F6' : '#3D5062',
              fontSize: '15px',
              marginBottom: '0',
            }}
          >
            {transfer.type}
          </p>
          <p
            style={{
              marginBottom: '0',
              marginRight: '0',
              fontSize: '15px',
            }}
          >
            {transfer.name}
          </p>
          <p
            style={{
              marginBottom: '0',
              color: 'rgba(128,128,128,.5)',
              fontSize: '10px',
            }}
          >
            {transfer.transactionDate}
          </p>
        </div>
        <div style={{ alignContent: 'center' }}>
          <p style={{ fontSize: '20px', marginBottom: '0' }}>
            {this.props.formatCurrency(transfer.transactionAmount)}
          </p>
        </div>
      </div>
    )
  }
  render() {
    if (this.props.t) {
      return (
        <div style={{ width: this.props.isSmallScreen ? '100%' : '100%' }}>
          {this.displayPendingTransfers()}
        </div>
      )
    }
  }
}
