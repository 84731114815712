import React, { Component } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";

class ConceptOnHomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChinese: this.props.isChinese,
      phoneImages: [
        {
          id: 1,
          src: "../images/app_preview_1.png"
        },
        {
          id: 2,
          src: "../images/app_preview_2.png"
        },
        {
          id: 3,
          src: "../images/app_preview_3.png"
        },
        {
          id: 4,
          src: "../images/app_preview_4.png"
        },
        {
          id: 5,
          src: "../images/app_preview_5.png"
        }
      ],
      prevIcon: <span className="glyphicon glyphicon-glass" />,
      nextIcon: <span className="glyphicon glyphicon-glass" />
    };
  }

  render() {
    const { nextIcon, prevIcon } = this.state;
    return (
      <Container className="concept-investment">

        <Row>
          <Col md={6} className="concept-title-col">
            <Container className="concept-investment-sub">

              <Row>
                {this.state.isChinese ? (
                  <p className="concept-investment-text">
                    值得您
                    <span className="concept-investment-text-100"> 100%</span>
                    <span className="concept-investment-text-r"> ® </span>
                    的投资
                  </p>
                ) : (
                  <p className="concept-investment-text">
                    AI-powered FINTECH platform for the
                    <span className="concept-investment-text-100"> 100%</span>
                    <span className="concept-investment-text-r">®</span>
                  </p>
                )}
              </Row>
              <Row>
                <p className="concept-investment-text-subtitle">
                  {this.state.isChinese
                    ? `给银行，财务顾问和您的专属服务`
                    // : `The technology financial advisors and banks use, in your hands.`}
                    : `For banks, financial advisors, and you.`}
                </p>
              </Row>
            </Container>
          </Col>
          <Col md={6} className="concept-phoneview-col">
            <Row>

              <div className="mobile-buttons-holder">
                <a className = "mobile-button" href ="https://play.google.com/store/apps/details?id=com.forescite">
                  <img
                    className="features-store-download"
                    src="../images/google_download_icon.png"
                    alt="google-store"
                  />
                </a>
                <a className = "mobile-button" href="https://apps.apple.com/us/app/forescite/id1567589436">
                  <img
                    className="features-store-download"
                    src="../images/app_download_icon.png"
                    alt="apple-store"
                  />
                </a>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ConceptOnHomePage;
