import React, { useState } from 'react'
import '../scss/components/NewSubscriptionInfo.scss'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import BoxImg from '../assets/new-sub-box-icon.svg'
import { Galleria } from 'primereact/galleria'
import { Carousel } from 'primereact/carousel'
import { Divider } from 'primereact/divider'
import { AiFillApple, AiOutlineTwitter } from 'react-icons/ai'
import { BsMeta } from 'react-icons/bs'
import { SiTesla } from 'react-icons/si'

import AssistantImg from '../assets/new-sub-assistant-icon.svg'
import InsightImg from '../assets/new-sub-insight-icon.svg'
import RoboImg from '../assets/new-sub-robo-icon.svg'
import TraderImg from '../assets/new-sub-trader-icon.svg'
import axios from 'axios'
import Endpoint from './Endpoint'

const NewSubscriptionInfo = (props) => {
  // user={this.props.user}
  //         getAllAccountInfo={this.getAllAccountInfo}
  //         t={this.props.t}
  //         signOut={this.props.signOut}
  //         isSmallScreen={this.state.isSmallScreen}
  //         prefinraData={this.state.prefinraData}
  console.log(props?.plaidAcctsInfo)
  console.log(props?.userDetails)

  // const firstDepositoryAccount = props?.plaidAcctsInfo?.find((account) => {
  //   return account?.type === 'depository' && account?.reconnect_link === 0
  // })

  const investment = props?.prefinraData?.payload?.smart_investing

  const [isLoading, setIsLoading] = useState(false)

  const DynamicHeaderBlock = () => {
    return (
      <section className='dynmaic-header-section'>
        <div className='new-subscription-card-container'>
          <div className='subscription-info-container'>
            <p>Spend dynamically</p>
            <span>
              Use AI to prioritize spending on what's important to you and set
              aside income for investing!
            </span>
          </div>
          <img src={BoxImg} alt='' />
        </div>

        <div className='new-subscription-card-container'>
          <div className='subscription-info-container'>
            <p>View insights 🔮</p>
            <span>Explore your trends, prepare for the unexpected.</span>
          </div>
          <img src={InsightImg} alt='' />
        </div>

        <div className='new-subscription-card-container'>
          <div className='subscription-info-container'>
            <p>Save, plan & invest.</p>
            <span>
              Your personal AI will guide you to save, plan and invest
              effortlessly.
            </span>
          </div>
          <img src={BoxImg} alt='' />
        </div>
      </section>
    )
  }

  const DynamicHeaderSlideBlock = () => {
    const dynamicOptions = [
      {
        name: 'Manage expenses',
        img: BoxImg,
        description:
          "Use AI to prioritize spending on what's important to you and set aside income for investing!",
      },
      {
        name: 'View insights 🔮',
        img: InsightImg,
        description: 'Explore your trends, prepare for the unexpected.',
      },
      {
        name: 'Save, plan & invest',
        img: BoxImg,
        description:
          'Your personal AI will guide you to save, plan and invest effortlessly.',
      },
    ]

    const responsiveOptions = [
      {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1,
      },
    ]

    const itemTemplate = (item) => {
      return (
        <>
          <div className='new-subscription-card-container'>
            <div className='subscription-info-container'>
              <p>{item?.name}</p>
              <span>{item?.description}</span>
            </div>
            <img src={item?.img} alt='' />
          </div>
        </>
      )
    }

    return (
      <section className='dynmaic-header-section'>
        <Carousel
          value={dynamicOptions}
          numScroll={1}
          numVisible={1}
          circular
          // responsiveOptions={responsiveOptions}
          itemTemplate={itemTemplate}
        />
      </section>
    )
  }

  const CustomInsight = () => {
    const insights = ['Cash Flow', 'Top Spending']

    const responsiveOptions = [
      {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1,
      },
    ]

    const itemTemplate = (name) => {
      return (
        <>
          {name === 'Cash Flow' && (
            <div className='single-card-container'>
              <p>Cash Flow</p>
              <div>
                <p>
                  Income <span>$3,250</span>
                </p>
                <p>
                  Expense <span>$3,100</span>
                </p>
                <p>
                  Cash on hand <span>$150</span>
                </p>
              </div>
            </div>
          )}

          {name === 'Top Spending' && (
            <div className='single-card-container'>
              <p>Top Spending</p>
              <div>
                <p>
                  Rent <span>$1,400.00</span>
                </p>
                <p>
                  Food <span>$670.12</span>
                </p>
                <p>
                  Cloth <span>$370.38</span>
                </p>
              </div>
            </div>
          )}
        </>
      )
    }

    return (
      <section className='custom-insight-container'>
        <Carousel
          value={insights}
          numScroll={1}
          numVisible={1}
          circular
          responsiveOptions={responsiveOptions}
          itemTemplate={itemTemplate}
        />
      </section>
    )
  }

  const BuildWithSliderAI = () => {
    const aiOptions = [
      {
        name: 'Investall Robo',
        img: RoboImg,
        description: 'Let our AI invest for you',
      },
      {
        name: 'AI Trader',
        img: TraderImg,
        description: 'Let our AI trade for you',
      },
      {
        name: 'AI Assistant 🔮',
        img: AssistantImg,
        description: 'Pick from a personalized range of stocks',
      },
    ]

    const responsiveOptions = [
      {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1,
      },
    ]

    const itemTemplate = (item) => {
      return (
        <>
          <div className='single-card-container'>
            <p>{item?.name}</p>
            <div>
              <img src={item?.img} alt='' />
              <p>{item?.description}</p>
            </div>
          </div>
        </>
      )
    }

    return (
      <section className='build-ai-container'>
        {/* <Galleria
          value={aiOptions}
          // numVisible={5}
          circular
          // style={{ maxWidth: '640px' }}
          showThumbnails={false}
          showItemNavigators
          showItemNavigatorsOnHover
          item={itemTemplate}
        /> */}

        <Carousel
          value={aiOptions}
          numScroll={1}
          numVisible={1}
          circular
          responsiveOptions={responsiveOptions}
          itemTemplate={itemTemplate}
        />
      </section>
    )
  }

  const BuildWithAI = () => {
    return (
      <section className='build-ai-container'>
        <div className='single-card-container'>
          <p>Investall Robo</p>
          <div>
            <p>Let our AI invest for you</p>
            <img src={RoboImg} alt='' />
          </div>
        </div>
        <div className='single-card-container'>
          <p>AI Trader</p>
          <div>
            <p>Let our AI trade for you</p>
            <img src={TraderImg} alt='' />
          </div>
        </div>
        <div className='single-card-container'>
          <p>AI Assistant 🔮</p>
          <div>
            <p>Pick from a personalized range of stocks</p>
            <img src={AssistantImg} alt='' />
          </div>
        </div>
      </section>
    )
  }

  const StocksBlock = () => {
    const stockOptions = [
      {
        name: 'AAPL',
        img: <AiFillApple />,
        trend: 'decrease',
        price: '$115.12',
      },
      {
        name: 'META',
        img: <BsMeta style={{ color: 'blue' }} />,
        trend: 'increase',
        price: '$115.42',
      },
      {
        name: 'TSLA',
        img: <SiTesla style={{ color: 'red' }} />,
        trend: 'decrease',
        price: '$115.23',
      },
      {
        name: 'TWTR',
        img: <AiOutlineTwitter style={{ color: 'blue' }} />,
        trend: 'increase',
        price: '$115.63',
      },
    ]

    const responsiveOptions = [
      {
        breakpoint: '480px',
        numVisible: 1,
        numScroll: 1,
      },
      // {
      //   breakpoint: '991px',
      //   numVisible: 2,
      //   numScroll: 1,
      // },
      // {
      //   breakpoint: '767px',
      //   numVisible: 1,
      //   numScroll: 1,
      // },
    ]

    const itemTemplate = (item) => {
      return (
        <>
          <div className='single-card-container'>
            {item?.img}
            <p>{item?.name}</p>
            <div
              className='price-box'
              style={
                item?.trend === 'decrease'
                  ? { background: '#ff835b' }
                  : { background: '#2DAD00' }
              }
            >
              <p>
                {item?.trend === 'decrease' ? '\u2193' : '\u2191'}
                <span>{item?.price}</span>
              </p>
            </div>
          </div>
        </>
      )
    }

    return (
      <section className='stocks-container'>
        <p>Pick from a personalized range of stocks.</p>
        <Carousel
          value={stockOptions}
          numScroll={1}
          numVisible={2}
          circular
          responsiveOptions={responsiveOptions}
          itemTemplate={itemTemplate}
        />
      </section>
    )
  }

  const FutureFinanceBlock = () => {
    return (
      <div className='future-finance'>
        <p>The future of finance is here</p>
        <div>
          <div>
            <p>
              Traditional <span>Financial Advisor</span>
            </p>
            <p>
              {'>'}$2000 <span>Fees per year</span>
            </p>
            <p>
              $100,000<span>Minimum investments</span>
            </p>
          </div>
          <Divider layout='vertical' />

          <div>
            <p>
              Investall <span>AI driven</span>
            </p>
            <p>
              $96<span>Fees per year</span>
            </p>
            <p>
              $20<span>Minimum investments</span>
            </p>
          </div>
        </div>
      </div>
    )
  }

  const SubscriptionStatement = () => {
    return (
      <div className='subscription-footer'>
        <p>Cancel Anytime</p>
        <span>
          By clicking Confirm, you authorize Investall to debit the bank account
          specified below for any amount owed for changes arising from your use
          of Investall, pursuant to Investall’s website and terms until this
          authorization is revoked. You may amend on cancel this authorization
          at any time by providing notice to Investall with 30 (thirty) davs
          notice.
        </span>

        <span>
          If you use Investall's services or purchase additional products
          periodically pursuant to Investall’s terms, you authorize Investall to
          debit your bank account periodically. Payments that fall outside of
          the regular debits authorized above will only be debited after your
          authorization is obtained.
        </span>

        <span>
          I further acknowledge that I have read to and agree to the below Terms
          & Conditions.
        </span>

        <div className='clickable-text'>
          <span>Terms & Conditions</span>
          <p>
            <span onClick={() => props?.signOut()}>Login</span> using a
            different account
          </p>
        </div>
      </div>
    )
  }

  const subscriptPlaidAcct = async () => {
    setIsLoading(true)
    try {
      const res = await axios.get(
        // `${Endpoint}/mobile-plaid/stripe/monthly-subscription?id=${firstDepositoryAccount?.account_id}`,
        `${Endpoint}/mobile-plaid/stripe/monthly-subscription?trial=1`,
        {
          headers: {
            Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          },
        }
      )
      console.log('subscribe response', res)
      setIsLoading(false)
      return true
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      return false
    }
  }

  const getSuscriptionButtonLabel = (status) => {
    if (status?.new_status === 'unsubscribed') {
      if (status?.usedTrail === 0) {
        return 'Start free trial'
      } else {
        return 'Subscribe'
      }
    } else {
      return 'Subscribe'
    }
  }
  // const getSuscriptionButtonLabel = () => {
  //   let status = props?.userDetails?.accountSub?.status
  //   let trialStatus = props?.userDetails?.accountSub?.trialUsed
  //   if (status !== 'subscribed') {
  //     if (trialStatus) {
  //       return 'Subscribe'
  //     } else {
  //       return 'Start free trial'
  //     }
  //   } else {
  //     return 'Continue'
  //   }
  // }

  const handleSubscriptButton = async (sub_data) => {
    if (sub_data?.new_status === 'unsubscribed') {
      if (sub_data?.usedTrail === 0) {
        if (await subscriptPlaidAcct()) {
          if (investment === 0) {
            props?.setShowLinkedAccountsInfo()
          } else {
            props?.setShowIBQuestionnaire()
          }
        }
      } else {
        props?.setCallSubscribeTrue()
        props.openPlaidModal()
      }
    } else {
      props?.setCallSubscribeTrue()
      props.openPlaidModal()
    }
    props?.closeModal()
    // if (await subscriptPlaidAcct()) {
    //   props?.closeModal()
    // }

    // props?.closeModal()
  }

  const subscriptionMessage = (data) => {
    switch (data?.new_status) {
      case 'grace':
        return (
          <p>
            Your free trial has expired. Your account will be switched to
            liquidate only mode {data?.exp_date ? 'on ' + data?.exp_date : ''}.
            Link an account to subscribe before then.
          </p>
        )
      case 'trial_ending':
        return (
          <p>
            Your free trial is expiring{' '}
            {data?.exp_date ? 'on ' + data?.exp_date : ''}. Link an account to
            subscribe. You will not be charged until the trial period ends.
          </p>
        )
      case 'trial':
        return (
          <p>
            Your free trial is expiring{' '}
            {data?.exp_date ? 'on ' + data?.exp_date : ''}. Link an account to
            subscribe. You will not be charged until the trial period ends.
          </p>
        )
      case 'canceled':
      case 'unsubscribed':
        if (data?.usedTrail === 0) {
          return null
        } else {
          return <p>Your free trial has been expired. Please subscribe to continue...</p>
        }
      default:
        return null
    }
  }

  const subscriptionButton = (data) => {
    switch (data?.new_status) {
      case 'grace':
      case 'trial':
      case 'trial_ending':
      case 'canceled':
        return (
          <div className='service-charge-container'>
            <Button
              label={isLoading ? 'Processing' : getSuscriptionButtonLabel(data)}
              onClick={() => (isLoading ? {} : handleSubscriptButton(data))}
              icon={isLoading && 'pi pi-spin pi-spinner'}
              iconPos='right'
              className='subscribe-button'
            />
            <p>$8.99 / month</p>
          </div>
        )
      case 'unsubscribed':
        return (
          <div className='service-charge-container'>
            <p>30 Day Free Trial</p>
            <p>then $8.99 / month</p>
            <Button
              label={isLoading ? 'Processing' : getSuscriptionButtonLabel(data)}
              onClick={() => (isLoading ? {} : handleSubscriptButton(data))}
              icon={isLoading && 'pi pi-spin pi-spinner'}
              iconPos='right'
              className='subscribe-button'
            />
          </div>
        )
      default:
        return (
          <div className='service-charge-container'>
            <p>30 Day Free Trial</p>
            <p>then $8.99 / month</p>
            <Button
              label={isLoading ? 'Processing' : getSuscriptionButtonLabel(data)}
              onClick={() => (isLoading ? {} : handleSubscriptButton(data))}
              icon={isLoading && 'pi pi-spin pi-spinner'}
              iconPos='right'
              className='subscribe-button'
            />
          </div>
        )
    }
  }

  return (
    <Dialog
      visible={props?.showSubscriptionStatusDialog}
      // style={{
      //   // width: props?.isSmallScreen ? '90vw' : '60vw',
      //   width: '35vw',
      // }}
      // closable={false} // no "x" button
      // Only not closeable if it is a first time user that does not have a subscription
      closable={
        props?.subscriptionData?.new_status === 'unsubscribed' &&
        props?.subscriptionData?.usedTrail === 0
          ? false
          : true
      }
      onHide={() => {
        localStorage.removeItem('showSubMessage')
        props.hideShowSubscriptionModal()
      }}
      className='new-subscription-dialog'
    >
      {subscriptionMessage(props.subscriptionData)}

      <p>
        {props?.prefinraData?.payload?.first_name}, meet your personal wealth
        manager
      </p>

      {investment === 0 && (
        <>
          <DynamicHeaderBlock />
          <p>Custom insights 🔥</p>
          <CustomInsight />
          <p>Build wealth with AI</p>
          <BuildWithSliderAI />
        </>
      )}

      {investment === 1 && (
        <>
          <BuildWithAI />
          <p>Buy stocks you like</p>
          <StocksBlock />
          <p>Spend Dynamically</p>
          <DynamicHeaderSlideBlock />
        </>
      )}

      {/* {investment === '0' ? <DynamicHeaderBlock /> : <BuildWithAI />}

      <p>{investment === '0' ? 'Custom insights' : 'Buy stocks you like'}</p>

      {investment === '0' ? <CustomInsight /> : <StocksBlock />}

      <p>{investment === '0' ? 'Build wealth with AI' : 'Spend Dynamically'}</p>

      {investment === '0' ? <BuildWithSliderAI /> : <DynamicHeaderSlideBlock />} */}

      <section className='new-subscription-bottom-section'>
        <FutureFinanceBlock />
        <SubscriptionStatement />
        {subscriptionButton(props.subscriptionData)}
      </section>
    </Dialog>
  )
}

export default NewSubscriptionInfo
