import React from 'react'
import '../../scss/components/IBStatusScreen.scss'
import IBLiquidateDWAccountsCard from './IBLiquidateDWAccountsCard'

export default function IBAccountRejectScreen(props) {
  return (
    <div className='ib-status-main-container'>
      <p className='ib-status-main-text'>Your application was not approved.</p>
      <p className='ib-status-sub-text'>Restart application to try again.</p>
      <button onClick={() => props?.showIBQuestionnaire()}>Continue</button>
      <div className='dw-accounts-card'>
        {props?.driveWealthStatus && (
          <IBLiquidateDWAccountsCard
            {...props}
            user={props.user}
            filteredAccounts={props.accounts?.filter(
              (account) =>
                account?.inst === 'DriveWealth' || account?.inst === 'Apex'
            )}
          />
        )}
      </div>
    </div>
  )
}
