import React, { Component, PureComponent } from 'react'
import ENDPOINT from '../Endpoint'
import axios from 'axios'
import moment from 'moment-timezone'
import ReactHighcharts from 'react-highcharts/ReactHighstock.src'
import { TailSpin } from 'react-loader-spinner'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import SearchEndpoint from '../SearchEndpoint'
import BalanceHistoryGraph from './BalanceHistoryGraph'
import Loader from './accountInfo/Loader'

export default class StockPriceGraph extends Component {
  state = {
    graphData1D: {},
    graphData1W: {},
    graphData1M: {},
    graphDataYTD: {},
    graphData1Y: {},
    activeTab: '1D',
    dateFormat: 'hh:mm',
  }

  interval = null
  componentDidMount() {
    this.fetchAllGraphData()
    this.interval = setInterval(() => this.fetchAllGraphData(), 60000) //USED TO REFRESH GRAPH DATA
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.symbol !== this.props.symbol) {
      this.fetchAllGraphData()
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  fetchAllGraphData = () => {
    if (this.props.isDwSupported) {
      const current = new Date()
      //Dates
      const currDate = moment(current).format('YYYY-MM-DDThh:mm:ss[Z]')
      const weekPrev = moment(current)
        .add('-1', 'W')
        .format('YYYY-MM-DDThh:mm:ss[Z]')
      const monthPrev = moment(current)
        .add('-1', 'M')
        .format('YYYY-MM-DDThh:mm:ss[Z]')
      const ytdPrev = current.getFullYear() + '-01-01T00:00:00Z'
      const yearPrev = moment(current)
        .add('-1', 'Y')
        .format('YYYY-MM-DDThh:mm:ss[Z]')

      //API Calls
      // this.getDWgraphData(1, null, null, true, '1D')
      // this.getDWgraphData(9, weekPrev, currDate, false, '1W')
      // this.getDWgraphData(0, monthPrev, currDate, false, '1M')
      // this.getDWgraphData(0, ytdPrev, currDate, false, 'YTD')
      // this.getDWgraphData(0, yearPrev, currDate, false, '1Y')
      this.getNonDWgraphData('1D')
      this.getNonDWgraphData('1W')
      this.getNonDWgraphData('1M')
      this.getNonDWgraphData('YTD')
      this.getNonDWgraphData('1Y')
    } else {
      this.getNonDWgraphData('1D')
      this.getNonDWgraphData('1W')
      this.getNonDWgraphData('1M')
      this.getNonDWgraphData('YTD')
      this.getNonDWgraphData('1Y')
    }
  }

  saveVariable(data, name) {
    if (name === '1D') {
      this.setState({
        graphData1D: Object.assign({}, data),
      })
    } else if (name === '1W') {
      this.setState({
        graphData1W: Object.assign({}, data),
      })
    } else if (name === '1M') {
      this.setState({
        graphData1M: Object.assign({}, data),
      })
    } else if (name === 'YTD') {
      this.setState({
        graphDataYTD: Object.assign({}, data),
      })
    } else if (name === '1Y') {
      this.setState({
        graphData1Y: Object.assign({}, data),
      })
    }
  }

  getDWgraphData = async (
    compression,
    dateStart,
    dateEnd,
    tradingDays,
    name
  ) => {
    if (tradingDays) {
      await axios
        .get(`${SearchEndpoint}/market-data/chart`, {
          params: {
            ticker: this.props.symbol,
            compression: compression,
            tradingDays: 1,
          },
          headers: {
            Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          this.saveVariable(response.data, name)
          return
        })
        .catch((err) => console.log(`catching errors`, err))
    } else {
      await axios
        .get(`${SearchEndpoint}/market-data/chart`, {
          params: {
            ticker: this.props.symbol,
            compression: compression,
            dateStart: dateStart,
            dateEnd: dateEnd,
          },
          headers: {
            Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          this.saveVariable(response.data, name)
          return
        })
        .catch((err) => console.log(`catching errors`, err))
    }
  }
  getNonDWgraphData = async (period) => {
    await axios
      .get(`${SearchEndpoint}/alpha/price`, {
        params: {
          ticker: this.props.symbol,
          period: period,
        },
        headers: {
          Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        this.saveVariable(response.data, period)
      })
      .catch((err) => console.log(`catching errors`, err))
  }

  getConfig(tab) {
    let rawData = null
    let coordinates = []
    let xCoordinates = []
    let yCoordinates = []
    let max = Number.MIN_VALUE
    let min = Number.MAX_VALUE
    const formatCurrency = this.props.formatCurrency
    const dateFormat = this.state.dateFormat
    // if (this.props.isDwSupported) {
    //   if (tab === '1D') {
    //     rawData = this.state.graphData1D?.data?.split('|')
    //   } else if (tab === '1W') {
    //     rawData = this.state.graphData1W?.data?.split('|')
    //   } else if (tab === '1M') {
    //     rawData = this.state.graphData1M?.data?.split('|')
    //   } else if (tab === 'YTD') {
    //     rawData = this.state.graphDataYTD?.data?.split('|')
    //   } else if (tab === '1Y') {
    //     rawData = this.state.graphData1Y?.data?.split('|')
    //   }
    //   if (rawData) {
    //     rawData.forEach((data) => {
    //       const dataString = data.split(',')
    //       if (dataString.length > 4) {
    //         min = Math.min(min, parseFloat(dataString[4]))
    //         max = Math.max(max, parseFloat(dataString[4]))
    //         // coordinates.push([
    //         //   moment(dataString[0]).unix() * 1000,
    //         //   parseFloat(dataString[4]),
    //         // ]);
    //         coordinates.push({
    //           x: moment
    //             .unix(moment(dataString[0]).unix())
    //             .format(this.state.dateFormat),
    //           // x: moment(dataString[0]).unix(),
    //           Price: parseFloat(dataString[4]),
    //         })
    //       }
    //     })
    //   }
    // } else {
    //   if (tab === '1D') {
    //     rawData = this.state.graphData1D?.prices
    //   } else if (tab === '1W') {
    //     rawData = this.state.graphData1W?.prices
    //   } else if (tab === '1M') {
    //     rawData = this.state.graphData1M?.prices
    //   } else if (tab === 'YTD') {
    //     rawData = this.state.graphDataYTD?.prices
    //   } else if (tab === '1Y') {
    //     rawData = this.state.graphData1Y?.prices
    //   }
    //   for (let i = 0; i < rawData?.length; i++) {
    //     if (rawData[i].length >= 2) {
    //       // coordinates.push([rawData[i][0] * 1000, rawData[i][1]]);
    //       // min = Math.min(min, rawData[i][1]);
    //       // max = Math.max(max, rawData[i][1]);
    //       coordinates.push({
    //         x: moment(rawData[i][0] * 1000).format(this.state.dateFormat),
    //         Price: parseFloat(rawData[i][1]),
    //       })
    //     }
    //   }
    // }

    if (tab === '1D') {
      rawData = this.state.graphData1D?.prices
    } else if (tab === '1W') {
      rawData = this.state.graphData1W?.prices
    } else if (tab === '1M') {
      rawData = this.state.graphData1M?.prices
    } else if (tab === 'YTD') {
      rawData = this.state.graphDataYTD?.prices
    } else if (tab === '1Y') {
      rawData = this.state.graphData1Y?.prices
    }

    const startOfDay = Math.floor(
      moment()
        // .tz('America/New_York')
        .tz('UTC')
        .startOf('day')
        .add(9, 'hours')
        .add(30, 'minutes')
        .toDate()
        .getTime() / 1000
    )
    const endOfDay = Math.floor(
      moment()
        .tz('UTC')
        // .tz('America/New_York')
        .startOf('day')
        .add(16, 'hours')
        .add(30, 'minutes')
        .toDate()
        .getTime() / 1000
    )

    for (let i = 0; i < rawData?.length; i++) {
      if (rawData[i].length >= 2) {
        const time = Math.floor(moment(rawData[i][0] * 1000).unix())
        if (tab === '1D') {
          // console.log('startOfDay', startOfDay)
          // console.log('time', time)
          if (time >= startOfDay && time <= endOfDay) {
            coordinates.push({
              x: moment(rawData[i][0] * 1000)
                .tz('UTC')
                .format(this.state.dateFormat),
              //x: time,
              Price: parseFloat(rawData[i][1]),
            })
          }
        } else {
          coordinates.push({
            x: moment(rawData[i][0] * 1000).format(this.state.dateFormat),
            //x: time,
            Price: parseFloat(rawData[i][1]),
          })
        }
      }
    }

    // console.log('rawdata', rawData, tab)
    // console.log('coordinates', coordinates)

    return coordinates
  }

  tabs() {
    let selectedTab = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      borderRadius: '19px',
      fontSize: '15px',
      width: '65px',
      height: '40px',
      boxShadow:
        '0px 4px 4px rgba(0, 0, 0, 0.07), -3px -2px 4px rgba(255, 255, 255, 0.92), 3px 1px 5px rgba(86, 86, 86, 0.2), inset 0px 0px 2px rgba(255, 255, 255, 0.21)',
    }
    let selectedText = {
      fontSize: '15px',
      textAlign: 'center',
      color: '#3F46F6',
      margin: 0,
    }
    let unSelectedTab = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      borderRadius: '19px',
      fontSize: '15px',
      width: '65px',
      height: '40px',
    }
    let unSelectedText = {
      fontSize: '15px',
      textAlign: 'center',
      color: '#a7a7a7',
      margin: 0,
    }
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div
          onClick={() =>
            this.setState({ activeTab: '1D', dateFormat: 'HH:mm' })
          }
          style={this.state.activeTab === '1D' ? selectedTab : unSelectedTab}
        >
          <p
            style={
              this.state.activeTab === '1D' ? selectedText : unSelectedText
            }
          >
            {this.props.t('oneday')}
          </p>
        </div>
        <div
          onClick={() =>
            this.setState({ activeTab: '1W', dateFormat: 'MMM DD' })
          }
          style={this.state.activeTab === '1W' ? selectedTab : unSelectedTab}
        >
          <p
            style={
              this.state.activeTab === '1W' ? selectedText : unSelectedText
            }
          >
            {this.props.t('oneweek')}
          </p>
        </div>
        <div
          onClick={() =>
            this.setState({ activeTab: '1M', dateFormat: 'MMM DD' })
          }
          style={this.state.activeTab === '1M' ? selectedTab : unSelectedTab}
        >
          <p
            style={
              this.state.activeTab === '1M' ? selectedText : unSelectedText
            }
          >
            {this.props.t('onemonth')}
          </p>
        </div>
        <div
          onClick={() =>
            this.setState({ activeTab: 'YTD', dateFormat: 'MM/DD/YYYY' })
          }
          style={this.state.activeTab === 'YTD' ? selectedTab : unSelectedTab}
        >
          <p
            style={
              this.state.activeTab === 'YTD' ? selectedText : unSelectedText
            }
          >
            {this.props.t('ytd')}
          </p>
        </div>
        <div
          onClick={() =>
            this.setState({ activeTab: '1Y', dateFormat: 'MM/DD/YYYY' })
          }
          style={this.state.activeTab === '1Y' ? selectedTab : unSelectedTab}
        >
          <p
            style={
              this.state.activeTab === '1Y' ? selectedText : unSelectedText
            }
          >
            {this.props.t('oneyear')}
          </p>
        </div>
      </div>
    )
  }

  showSelectedTab = () => {
    if (this.state.activeTab === '1D')
      return (
        <div style={{ height: '50vh', width: '100%' }}>
          {this.state.graphData1D &&
          Object.keys(this.state.graphData1D).length > 0 ? (
            <BalanceHistoryGraph
              seriesData={this.getConfig('1D')}
              t={this.props}
              formatCurrency={this.props.formatCurrency}
              xLabel={'x'}
              yLabel={'Price'}
            />
          ) : (
            <div style={{ width: '10%', margin: 'auto' }}>
              <Loader logoLoader={false} />
            </div>
          )}
        </div>
      )
    else if (this.state.activeTab === '1W')
      return (
        <div>
          {this.state.graphData1W &&
          Object.keys(this.state.graphData1W).length > 0 ? (
            // <ReactHighcharts config={this.getConfig("1W")} />
            // <HighchartsReact
            //   containerProps={{ style: { height: "100%" } }}
            //   highcharts={Highcharts}
            //   options={this.getConfig("1W")}
            // />
            <BalanceHistoryGraph
              seriesData={this.getConfig('1W')}
              t={this.props}
              formatCurrency={this.props.formatCurrency}
              xLabel={'x'}
              yLabel={'Price'}
            />
          ) : (
            <div style={{ width: '10%', margin: 'auto' }}>
              <Loader logoLoader={false} />
            </div>
          )}
        </div>
      )
    else if (this.state.activeTab === '1M')
      return (
        <div>
          {this.state.graphData1M &&
          Object.keys(this.state.graphData1M).length > 0 ? (
            // <ReactHighcharts config={this.getConfig("1M")} />
            // <HighchartsReact
            //   containerProps={{ style: { height: "100%" } }}
            //   highcharts={Highcharts}
            //   options={this.getConfig("1M")}
            //>
            <BalanceHistoryGraph
              seriesData={this.getConfig('1M')}
              t={this.props}
              formatCurrency={this.props.formatCurrency}
              xLabel={'x'}
              yLabel={'Price'}
            />
          ) : (
            <div style={{ width: '10%', margin: 'auto' }}>
              <Loader logoLoader={false} />
            </div>
          )}
        </div>
      )
    else if (this.state.activeTab === 'YTD')
      return (
        <div>
          {this.state.graphDataYTD &&
          Object.keys(this.state.graphDataYTD).length > 0 ? (
            // <ReactHighcharts config={this.getConfig("YTD")} />
            // <HighchartsReact
            //   containerProps={{ style: { height: "100%" } }}
            //   highcharts={Highcharts}
            //   options={this.getConfig("YTD")}
            // />
            <BalanceHistoryGraph
              seriesData={this.getConfig('YTD')}
              t={this.props}
              formatCurrency={this.props.formatCurrency}
              xLabel={'x'}
              yLabel={'Price'}
            />
          ) : (
            <div style={{ width: '10%', margin: 'auto' }}>
              <Loader logoLoader={false} />
            </div>
          )}
        </div>
      )
    else if (this.state.activeTab === '1Y')
      return (
        <div>
          {this.state.graphData1Y &&
          Object.keys(this.state.graphData1Y).length > 0 ? (
            // <ReactHighcharts config={this.getConfig("1Y")} />
            // <HighchartsReact
            //   containerProps={{ style: { height: "100%" } }}
            //   highcharts={Highcharts}
            //   options={this.getConfig("1Y")}
            // />
            <BalanceHistoryGraph
              seriesData={this.getConfig('1Y')}
              t={this.props}
              formatCurrency={this.props.formatCurrency}
              xLabel={'x'}
              yLabel={'Price'}
            />
          ) : (
            <div style={{ width: '10%', margin: 'auto' }}>
              <Loader logoLoader={false} />
            </div>
          )}
        </div>
      )
    else return null
  }

  render() {
    if (this.props.t) {
      return (
        <div style={{ height: '100%' }}>
          {this.showSelectedTab()}
          <div
            style={{
              width: '100%',
              height: '40',
              marginBottom: '25',
              marginTop: '15px',
            }}
          >
            {this.tabs()}
          </div>
        </div>
      )
    } else return null
  }
}
