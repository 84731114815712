import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { InputText } from 'primereact/inputtext'
import { Dropdown } from 'primereact/dropdown'
import { Toast } from 'primereact/toast'
import { InputMask } from 'primereact/inputmask'
import { Dialog } from 'primereact/dialog'

import DynaIcon from '../assets/dyna-plan-icon.svg'
import AutoIcon from '../assets/auto-plan-icon.svg'

import '../scss/components/PrefinraQuestions.scss'
import axios from 'axios'
import Endpoint from './Endpoint'
import { event } from 'react-ga'
import PhoneInput from 'react-phone-input-2'

const yesOrNo = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]

const PrefinraQuestions = (props) => {
  const [step, setStep] = useState(1)

  const [userForm, setUserForm] = useState({})
  const [userInfo, setUserInfo] = useState(null)
  const toastErrorMsg = useRef()

  const FooterContent = () => {
    return (
      <div className='user-info-dialog-footer-content'>
        <Button
          label={'Continue'}
          onClick={async () => {
            if (validateForm()) {
              if (userForm?.ustaxpayer === 'yes') setStep(2)
              else {
                // Not us tax payer needs is just investing
                console.log('non us tax payer')
                await storePrefinraAnswers(1)
                props?.closeModal()
              }
            }
          }}
        />
      </div>
    )
  }

  const monthNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    )
  }

  // year template for the prime react ui calander
  const yearNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className='p-ml-2'
        style={{ lineHeight: 1 }}
      />
    )
  }

  const handleBirthdayInputChange = (name, value) => {
    setUserForm((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const parseBirthday = (birthday) => {
    const date = new Date(birthday)
    handleBirthdayInputChange('birthday', birthday)
    handleBirthdayInputChange('birthdayDay', date.getDate())
    handleBirthdayInputChange('birthdayMonth', date.getMonth() + 1)
    handleBirthdayInputChange('birthdayYear', date.getFullYear())
  }

  const handleUserFormInputChange = (event) => {
    let { name, value } = event.target

    value = value?.replace(/[^A-Za-z0-9\s]/g, '')

    setUserForm((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleUsTaxPayerChange = (event) => {
    console.log('event', event)
    setUserForm((prevData) => ({
      ...prevData,
      ustaxpayer: event,
    }))
  }

  // check if the user is at least 18 years old before create the account
  const isAtLeast18YearsOld = (year, month, day) => {
    const currentDate = new Date()
    const inputDate = new Date(year, month - 1, day) // JavaScript months are 0-based (0 = January, 1 = February, etc.)

    const ageDifferenceInMilliseconds = currentDate - inputDate
    const ageInYears =
      ageDifferenceInMilliseconds / (365.25 * 24 * 60 * 60 * 1000)

    return ageInYears >= 18
  }

  const validateForm = () => {
    let {
      first_name,
      last_name,
      birthdayDay,
      birthdayMonth,
      birthdayYear,
      phone_no,
      ustaxpayer,
    } = userForm

    // if (userInfo?.phone && !phone_no) phone_no = userInfo?.phone

    // step 1 form validateion for empty fields
    if (
      !first_name ||
      first_name?.trim() === '' ||
      !last_name ||
      last_name?.trim() === '' ||
      !birthdayDay ||
      !birthdayMonth ||
      !birthdayYear ||
      !phone_no ||
      !ustaxpayer
    ) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Incompleted',
        detail: 'Please make sure all required fields are filled',
        life: 3000,
      })
      return false
    }

    // not age 18 or older
    if (!isAtLeast18YearsOld(birthdayYear, birthdayMonth, birthdayDay)) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Birthday Invalid',
        detail: 'You need to be at least 18 years old',
        life: 3000,
      })
      return false
    }

    return true
  }

  const getUserInfo = async () => {
    try {
      let res = await axios.get(`${Endpoint}/mobile/get-email`, {
        headers: {
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          'Content-Type': 'application/json',
        },
      })

      console.log(res)
      setUserInfo(res?.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getUserInfo()
  }, [])

  const storePrefinraAnswers = async (investing) => {
    let body = {
      first_name: userForm?.first_name,
      last_name: userForm?.last_name,
      // phone_number: userInfo?.phone
      //   ? userInfo?.phone
      //   : `+${userForm?.phone_no?.replace(/\D/g, '')}`,
      phone_number: `${userForm?.phone_no?.replace(/\D/g, '')}`,
      smart_investing: investing,
      birth_day: userForm?.birthdayDay?.toString(),
      birth_month: userForm?.birthdayMonth?.toString(),
      birth_year: userForm?.birthdayYear?.toString(),
      ustaxpayer: userForm?.ustaxpayer === 'yes' ? true : false,
    }

    console.log(body)

    try {
      let res = await axios.post(`${Endpoint}/mobile/prefinra-data/add`, body, {
        headers: {
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          'Content-Type': 'application/json',
        },
      })
      console.log('[api response] - prefinra-data/add ', res?.data)

      // if taxpayer is non us user show the ibquestionnaire
      if (res?.data?.success && userForm?.ustaxpayer === 'no') {
        props?.setShowIBQuestionnaire()
      }
      props?.closeModal()
    } catch (error) {
      console.log(error)
    }
  }

  const investmentOptions = () => {
    return (
      <div className='smart-investing-container'>
        <div className='single-investing-option'>
          <p>
            <img src={DynaIcon} alt='' />
            Spend Dynamically
          </p>
          <p>
            Scan your accounts with AI to identify spending habits and set rules
            to save, plan and invest.
          </p>
          <Button
            label="Let's start planning >"
            className='p-button-outlined'
            onClick={async () => await storePrefinraAnswers(0)}
            style={{ color: '#FFD4F3' }}
          />
        </div>
        <div className='single-investing-option'>
          <p>
            <img src={AutoIcon} alt='' />
            Invest and Trade Automatically
          </p>
          <p>
            Buy stocks tailor picked for you. Automate trading and investing
            with AI.
          </p>
          <Button
            label="Let's start investing >"
            className='p-button-outlined'
            onClick={async () => await storePrefinraAnswers(1)}
            style={{ color: '#D1F4FF' }}
          />
        </div>
      </div>
    )
  }

  const additionalInputs = () => {
    return (
      <div className='user-info-container'>
        <div className='input-container'>
          <label htmlFor='first_name'>What's your name?</label>
          <InputText
            id='first_name'
            name='first_name'
            value={userForm?.first_name}
            placeholder='First name '
            onChange={handleUserFormInputChange}
            style={{ marginBottom: '1rem' }}
          />
          <InputText
            id='last_name'
            name='last_name'
            placeholder='Last name'
            value={userForm?.last_name}
            onChange={handleUserFormInputChange}
          />
        </div>

        <div className='input-container'>
          <label>What is your date of birth?</label>
          <Calendar
            value={userForm?.birthday}
            onChange={(e) => parseBirthday(e.target.value)}
            dateFormat='mm/dd/yy'
            name='birthday'
            showIcon
            icon='pi pi-calendar'
            placeholder='birthday'
            monthNavigator={true}
            yearNavigator={true}
            monthNavigatorTemplate={monthNavigatorTemplate}
            yearNavigatorTemplate={yearNavigatorTemplate}
            yearRange={`1900:${new Date().getFullYear()}`}
            className='calendar-input'
          ></Calendar>
        </div>

        <div className='input-container'>
          <label htmlFor='phone'>What is your phone number</label>

          <PhoneInput
            className='phone-input'
            id='phone'
            // placeholder='Phone number'
            name='phone_no'
            value={userForm?.phone_no}
            onChange={(e) => {
              setUserForm((prevData) => ({
                ...prevData,
                ['phone_no']: e,
              }))
            }}
            // countryCodeEditable={false}
            country='us'
            // enableSearch={true}
          />
        </div>

        {/* {!userInfo?.phone && (
          <div className='input-container'>
            <label htmlFor='phone'>What is your phone number</label>

            <PhoneInput
              className='phone-input'
              id='phone'
              // placeholder='Phone number'
              name='phone_no'
              value={userForm?.phone_no}
              onChange={(e) => {
                setUserForm((prevData) => ({
                  ...prevData,
                  ['phone_no']: e,
                }))
              }}
              // countryCodeEditable={false}
              country='us'
              // enableSearch={true}
            />
          </div>
        )} */}

        <div className='input-container'>
          <label>Are you a US-Tax Payer?</label>
          <Dropdown
            value={userForm?.ustaxpayer}
            options={yesOrNo}
            onChange={(e) => handleUsTaxPayerChange(e.value)}
            style={{ lineHeight: 1 }}
          />
        </div>

        <Toast ref={toastErrorMsg} position='top-right' />
      </div>
    )
  }

  return (
    <Dialog
      header={
        <>
          <div className='unremoveable-header-container'>
            {step === 2 && (
              <p
                className='back-arrow'
                onClick={() => {
                  setStep(1)
                }}
              >
                {'<'}
              </p>
            )}
            <p className='unremoveable-header'>
              {step === 1
                ? ' User Basic Info'
                : 'Where would you like to start?'}
            </p>

            {step === 1 && (
              <p
                onClick={() => {
                  props?.signOut()
                }}
                className='unremoveable-signout'
              >
                Sign out
              </p>
            )}
          </div>
        </>
      }
      footer={step === 1 && FooterContent}
      visible={true}
      style={{
        width: props?.isSmallScreen ? '90vw' : '40vw',
      }}
      closable={false} // no "x" button
      className={`prefinra-dialog ${step === 2 && 'smart-investing-dialog'}`}
    >
      {step === 1 && additionalInputs()}

      {step === 2 && investmentOptions()}
    </Dialog>
  )
}

export default PrefinraQuestions
