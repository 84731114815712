import Axios from 'axios'
import { FileUpload } from 'primereact/fileupload'
import React, { useRef, useState } from 'react'
import BASEURL from '../../../src/components/Endpoint'
import { Dialog } from 'primereact/dialog'
import { Calendar } from 'primereact/calendar'
import PDFImg from '../../assets/PDF_file_icon.svg.png'
import { Button } from 'primereact/button'
import { Tag } from 'primereact/tag'
import { Dropdown } from 'primereact/dropdown'
import { Panel } from 'primereact/panel'
import IBLiquidateDWAccountsCard from './IBLiquidateDWAccountsCard'

export default function IBAccountAdditionalInfoScreen(props) {
  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false)
  const [optionsDetail, setOptionsDetail] = useState(null)
  const [selectedOptions, setSelectedOptions] = useState({})
  const [documentsExpiration, setDocumentsExpiration] = useState(null)
  const [selectedOptionsDoc, setSelectedOptionsDoc] = useState({})
  const toastErrorMsg = useRef()

  const fetchIBDocumentsDetails = async () => {
    try {
      let res = await Axios.get(`${BASEURL}/ibkr/get-pending-documents`, {
        headers: {
          Authorization: 'Bearer ' + props?.jwtToken,
        },
      })

      console.log(res?.data)

      setOptionsDetail(res?.data)
      // setPageloading(false)
      setShowFileUploadDialog(true)
    } catch (error) {
      console.log(error)
    }
  }

  const submitDocuments = async () => {
    //  setLoading(true)

    console.log('inside submitDocuments')
    const formData = new FormData()

    const metadataObj = {}

    for (const [key, value] of Object.entries(selectedOptionsDoc)) {
      // if it is image, then do the image extension else if pdf do pdf extension
      if (value?.type?.includes('image')) {
        formData.append('file', value, `${key || ''}--${Date.now()}.jpeg`)
      } else if (value?.type?.includes('pdf')) {
        formData.append('file', value, `${key || ''}--${Date.now()}.pdf`)
      }

      // if you can find it is existed then it has options dropdown
      if (selectedOptions?.[key]) {
        metadataObj[key || ''] = selectedOptions?.[key]

        // if it is passport and driver license then add the expiration date

        if (selectedOptions?.[key] === 'Driver License') {
          console.log('inside driver lience pass')
          metadataObj[`${key} expiry` || ''] =
            formatDateObj(documentsExpiration) ?? ''
        } else if (selectedOptions?.[key] === 'Passport') {
          console.log('inside passport pass')
          metadataObj[`${key} expiry` || ''] =
            formatDateObj(documentsExpiration) ?? ''
        }
      } else {
        metadataObj[key || ''] = ''
      }
    }
    formData.append('metadata', JSON.stringify(metadataObj))

    for (let entry of formData.entries()) {
      console.log(entry)
    }

    try {
      let res = await Axios.post(
        `${BASEURL}/ibkr/upload-pending-documents`,
        formData,
        {
          headers: {
            Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          },
        }
      )

      console.log(res?.data)

      if (res?.data.success) {
        console.log('good to go')
        return true
      } else {
        console.log('failed')
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Upload Documents failed',
          detail: 'Something wrong with data uploaded. Please try again',
          life: 3000,
        })
        return false
      }
    } catch (error) {
      console.log(error)
      //  setLoading(false)
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Upload Documents failed',
        detail: 'Please upload the files and try again',
        life: 3000,
      })
    }

    return false
  }

  const formatDateObj = (date) => {
    const originalDate = new Date(date)

    const year = originalDate.getFullYear()
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0')
    const day = originalDate.getDate().toString().padStart(2, '0')

    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
  }

  const UploadDocumentsForm = () => {
    // document layout template for the uploaded
    const itemListTemplate = (docType) => (file, props) => {
      return (
        <div className='upload-content-container'>
          <div className='upload-content-part1-container'>
            <img
              alt={file.name}
              role='presentation'
              src={file.type === 'application/pdf' ? PDFImg : file.objectURL}
              width={file.type === 'application/pdf' ? 60 : 100}
            />
            <span>{file.name}</span>
          </div>
          <Tag
            value={props.formatSize}
            severity='warning'
            className='px-3 py-2'
          />
          <Button
            type='button'
            icon='pi pi-times'
            className='p-button-outlined p-button-rounded p-button-danger ml-auto'
            onClick={() => {
              setSelectedOptionsDoc({
                ...selectedOptionsDoc,
                [docType]: null,
              })
              props.onRemove()
            }}
            style={{ marginLeft: 'auto' }}
          />
        </div>
      )
    }

    // empty template when no doc is uploaded yet
    const emptyTemplate = (docType) => {
      return selectedOptionsDoc?.[docType] ? (
        <div className='upload-content-container'>
          <div className='upload-content-part1-container'>
            <img
              alt={selectedOptionsDoc?.[docType]?.name}
              role='presentation'
              src={
                selectedOptionsDoc?.[docType]?.type === 'application/pdf'
                  ? PDFImg
                  : selectedOptionsDoc?.[docType]?.objectURL
              }
              width={
                selectedOptionsDoc?.[docType]?.type === 'application/pdf'
                  ? 60
                  : 100
              }
            />
            <span>{selectedOptionsDoc?.[docType]?.name}</span>
          </div>
          <Tag
            value={selectedOptionsDoc?.[docType]?.size}
            severity='warning'
            className='px-3 py-2'
          />
          <Button
            type='button'
            icon='pi pi-times'
            className='p-button-outlined p-button-rounded p-button-danger ml-auto'
            onClick={() => {
              setSelectedOptionsDoc({
                ...selectedOptionsDoc,
                [docType]: null,
              })
              // props.onRemove()
            }}
            style={{ marginLeft: 'auto' }}
          />
        </div>
      ) : (
        <div className='empty-upload-container'>
          <i className='pi pi-image'></i>
          <span className='my-5'>Please upload your document</span>
        </div>
      )
    }

    // check if the doc is more than one or not, if it is 1, then update the list, if not popup the latest one and show error message
    const uploadDocsCheck =
      (docType) =>
      async ({ files }) => {
        if (files) {
          if (files?.length > 1) {
            files.pop()
            toastErrorMsg.current.show({
              severity: 'error',
              summary: 'Upload Documents failed',
              detail: 'Please remove old files and upload new one',
              life: 3000,
            })
          } else {
            setSelectedOptionsDoc({
              ...selectedOptionsDoc,
              [docType]: files[0],
            })
          }
        }
      }

    // update the option value based on the doc_type option
    const handleOptionChange = (docType, selectedOption) => {
      setSelectedOptions({
        ...selectedOptions,
        [docType]: selectedOption,
      })

      if (docType === 'Proof of identity and date of birth') {
        setDocumentsExpiration(null)
      }
    }

    // make single array of string into object
    const transformedOptionObj = (options) => {
      let data = options?.map((option) => ({
        label: option,
        value: option,
      }))

      return data
    }

    return (
      <>
        <div className='upload-documents-container'>
          {/* 
          Filter
          
         */}
          {optionsDetail?.payload
            ?.filter((doc) => {
              return doc?.action !== 'to sign'
            })
            ?.map((singleDoc, index) => {
              return (
                <Panel header={singleDoc?.doc_type} toggleable>
                  <div className='single-container' key={index}>
                    {/* <p>{singleDoc?.doc_type}</p> */}

                    {singleDoc?.has_options && (
                      <Dropdown
                        value={selectedOptions?.[singleDoc?.doc_type]}
                        name='Select category'
                        onChange={(e) =>
                          handleOptionChange(
                            singleDoc?.doc_type,
                            e.target.value
                          )
                        }
                        placeholder='Select category'
                        options={transformedOptionObj(singleDoc?.options)}
                      />
                    )}

                    {singleDoc?.is_uploaded ? (
                      <p>
                        <span>&#x2705;</span> Document already uploaded
                      </p>
                    ) : (
                      <FileUpload
                        customUpload={true}
                        uploadHandler={uploadDocsCheck(singleDoc?.doc_type)}
                        auto
                        mode='advanced'
                        chooseLabel={'Upload Document'}
                        accept='.jpg,.png,.pdf'
                        emptyTemplate={emptyTemplate(singleDoc?.doc_type)}
                        itemTemplate={itemListTemplate(singleDoc?.doc_type)}
                      />
                    )}

                    {selectedOptions?.[singleDoc?.doc_type] ===
                      'Driver License' && (
                      <>
                        <p style={{ fontSize: 16 }}>Expiration Date</p>
                        <Calendar
                          value={documentsExpiration}
                          dateFormat='yy/mm/dd'
                          onChange={(e) =>
                            setDocumentsExpiration(e.target.value)
                          }
                          showIcon
                        />
                      </>
                    )}

                    {selectedOptions?.[singleDoc?.doc_type] === 'Passport' && (
                      <>
                        <p style={{ fontSize: 16 }}>Expiration Date</p>

                        <Calendar
                          value={documentsExpiration}
                          dateFormat='yy/mm/dd'
                          onChange={(e) =>
                            setDocumentsExpiration(e.target.value)
                          }
                          showIcon
                        />
                      </>
                    )}
                  </div>
                </Panel>
              )
            })}
        </div>
      </>
    )
  }

  return (
    <div className='ib-status-main-container'>
      <p className='ib-status-main-text'> Additional Information Required</p>
      <p className='ib-status-sub-text'>
        Please upload additional documents to verify your identity or proof of
        address.
      </p>
      <div className='ib-status-additional-info-input'>
        <button onClick={() => fetchIBDocumentsDetails()}>
          Upload Document
        </button>
        <Dialog
          visible={showFileUploadDialog}
          // Only not closeable if it is a first time user that does not have a subscription
          closable
          onHide={() => {
            setShowFileUploadDialog(false)
          }}
          header={'Upload Additional Documents'}
          className='additional-documents-dialog'
          draggable={false}
        >
          {UploadDocumentsForm()}
          <Button
            type='button'
            onClick={() => submitDocuments()}
            disabled={
              optionsDetail?.payload?.filter((doc) => {
                return doc?.action !== 'to sign'
              })?.length !== Object?.keys(selectedOptionsDoc)?.length ?? false
            }
            className='submit-additional-documents-btn'
          >
            Submit
          </Button>
        </Dialog>
      </div>
      <div className='dw-accounts-card'>
        {props?.driveWealthStatus && (
          <IBLiquidateDWAccountsCard
            {...props}
            user={props.user}
            filteredAccounts={props.accounts?.filter(
              (account) =>
                account?.inst === 'DriveWealth' || account?.inst === 'Apex'
            )}
          />
        )}
      </div>
    </div>
  )
}
