const graphColor = [
    "#0BDA51",
    "#0BDA51",
    "#0BDA51",
    "#0BDA51",
    "#0BDA51",
    "#0BDA51",
    "#0BDA51",
    "yellow",
    "yellow",
    "yellow",
    "yellow",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
    "red",
];
export {graphColor}