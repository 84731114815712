import React from "react";
import DoubleUp from "../../assets/double_up.svg";
import Flat from "../../assets/flat.svg";
import Up from "../../assets/single_up.svg";
import Down from "../../assets/single_down.svg";
import DoubleDown from "../../assets/double_down.svg";

export default class CurrPrevTrend extends React.Component {
  state = {};

  setSrc = () => {
    // console.log('this.props', this.props)
    let direction = this.props.direction;
    if (this.props.isCrypto) {
      let priceForecast = this.props.priceForecast;
      let currentPrice = this.props.currentPrice.currentPrice;

      if (priceForecast > 1.08 * currentPrice) {
        direction = 1;
      } else if (priceForecast < 0.92 * currentPrice) {
        direction = -1;
      } else {
        direction = 0;
      }
    }
    switch (direction) {
      case 0:
        return Flat;
      case 1:
        return Up;
      case -1:
        return Down;
      case 2:
        return DoubleUp;
      case -2:
        return DoubleDown;
      default:
        return null;
    }
  };
  setText = () => {
    let direction = this.props.direction;
    if (this.props.isCrypto) {
      let priceForecast = this.props.priceForecast;
      let currentPrice = this.props.currentPrice.currentPrice;

      if (priceForecast > 1.08 * currentPrice) {
        direction = 1;
      } else if (priceForecast < 0.92 * currentPrice) {
        direction = -1;
      } else {
        direction = 0;
      }
    }
    switch (direction) {
      case 0:
        return "Flat";
      case 1:
        return "Up";
      case -1:
        return "Down";
      case 2:
        return "Strong Up";
      case -2:
        return "Strong Down";
    }
  };

  parseDate = (dt) => {
    let full = new Date(dt * 1000);
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    let month = full.getMonth() + 1;
    let day = full.getDate();
    let year = full.getFullYear();
    let hours = full.getHours();
    let mins = full.getMinutes();
    let secs = full.getSeconds();
    let ampm = "AM";
    if (hours > 12) {
      ampm = "PM";
      hours = hours - 12;
    }
    if (secs === 0) {
      secs = "00";
    }
    return `${month}/${day}/${year} ${hours}:${mins} ${ampm}`;
  };

  addCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "5%",
          borderRadius: 18,
          backgroundColor: "rgb(255,255,255)",
        }}
      >
        <div style={{ width: "60%" }}>
          <p
            style={{
              margin: 0,
              fontSize: this.props.isSmallScreen ? "45px" : "60px",
              fontWeight: "bold",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 0.83,
              letterSpacing: "1.2px",
              textAlign: "left",
              color: "rgb(36, 42, 56)",
            }}
          >
            {this.props.prev ? "Previous" : "Current"}
          </p>
          <p
            style={{
              margin: "",
              fontSize: this.props.isSmallScreen ? "45px" : "60px",
              fontWeight: "bold",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: 0.83,
              letterSpacing: "1.2px",
              textAlign: "left",
              color: "rgb(36, 42, 56)",
            }}
          >
            Trend
          </p>
          {/* {this.props.prev || !this.props.signalPrice? null : <p style={{margin:0}}>Signal Price <strong style={{color:'#7bc0c3'}}>${this.addCommas(this.props.signalPrice.toFixed(2))}</strong></p>} */}
          {this.props.isCrypto ? null : (
            <p style={{ margin: 0 }}>
              Generated on{" "}
              <strong>{this.parseDate(this.props.generated)}</strong>
            </p>
          )}
        </div>
        <div
          style={{
            width: "30%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={this.setSrc()}
            style={{
              height: this.props.isSmallScreen ? 50 : 100,
              marginTop: this.props.isSmallScreen ? 20 : null,
            }}
          />
          <p
            style={{ fontSize: 36, fontWeight: "normal", textAlign: "center" }}
          >
            {this.setText()}
          </p>
        </div>
      </div>
    );
  }
}
