import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import React, { useState } from 'react'
import '../../scss/components/UnopenedAccountCard.scss'
import ApexForm from '../components/ApexForm'
import ConfirmationDialog from '../components/ConfirmationDialog'
import DwApexQuestionnaire from '../DwApexQuestionnaire'
import Axios from 'axios'
import Endpoint from '../Endpoint'
import { useSelector } from 'react-redux'
import SubModal from '../SubModal'

export default function UnopenedAccountCard(props) {
  //PROPS
  //name: STOCK, CRYPTO, ROBO, GOALS, AI STOCK TRADER, AI CRYPTO TRADER
  //driveWealthStatus
  //apexStatus
  const [apexForm, setApexForm] = useState(false)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [btnLabel, setBtnLabel] = useState('')
  const [cryptoAllowedDialog, setCryptoAllowedDialog] = useState(false)
  const [subMessageDialog, setSubMessageDialog] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')

  const { user } = useSelector((state) => state)

  const toggleShowConfirmationDialog = () => {
    setShowConfirmationDialog(!showConfirmationDialog)
  }

  const activateDW = async () => {
    let name = ''
    let ria = ''
    switch (props.name) {
      case 'ROBO':
        name = 'robo'
        ria = true
        break
      case 'GOALS':
        name = 'goal'
        ria = true
        break
      case 'STOCK':
        name = 'equity'
        ria = false
        break
      case 'CRYPTO':
        name = 'crypto'
        ria = false
        break
      case 'AI STOCK TRADER':
        name = 'group'
        ria = false
        break
      case 'AI CRYPTO TRADER':
        name = 'group-crypto'
        ria = false
        break
      default:
        break
    }
    return await Axios.get(
      // `${Endpoint}/dw/accounts/create?ria=${ria}&product=${name}`,
      `${Endpoint}/mobile/dw/create-dw-account?ria=${ria}&product=${name}`,
      {
        headers: {
          Authorization: 'Bearer ' + props.user?.idToken?.jwtToken,
        },
      }
    )
      .then(async (res) => {
        if (res?.data?.success) {
          //CALL V2 get account and return true
          let status = await props.getAllAccountInfoV2()
          if (status) {
            return true
          } else {
            return true
          }
        } else {
          return false
        }
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  }

  const getStockCard = () => {
    return (
      <div className='unopened-card stock'>
        <p className='unopened-card-header'>{props.t('stockaccount')}</p>
        <p className='unopened-card-details'></p>
        <Button
          className='unopened-card-btn stock'
          label={props.t('getstock')}
          onClick={() => {
            setBtnLabel(props.t('createastockaccount'))
            if (props.driveWealthStatus) {
              setShowConfirmationDialog(true)
            } else {
              setApexForm(true)
            }
          }}
        />
      </div>
    )
  }

  const getCrytoCard = () => {
    return (
      <div className='unopened-card crypto'>
        <p className='unopened-card-header'>{props.t('cryptoaccount')}</p>
        <p className='unopened-card-details'></p>
        <Button
          className='unopened-card-btn crypto'
          label={props.t('getcrypto')}
          onClick={() => {
            if (user.isCryptoAllowed) setApexForm(true)
            else setCryptoAllowedDialog(true)
          }}
        />
      </div>
    )
  }

  const getRoboCard = () => {
    return (
      <div className='unopened-card robo'>
        {' '}
        <p className='unopened-card-header'>{props.t('roboaccount')}</p>
        <p className='unopened-card-details'>
          {props.t('unopenedrobodetails')}
        </p>
        <Button
          className='unopened-card-btn robo'
          label={props.t('getrobo')}
          onClick={() => {
            setBtnLabel(props.t('createroboaccount'))
            if (props.driveWealthStatus) {
              setShowConfirmationDialog(true)
            } else {
              setApexForm(true)
            }
          }}
        />
      </div>
    )
  }

  const getAiStockCard = () => {
    return (
      <div className='unopened-card aistock'>
        {' '}
        <p className='unopened-card-header'>
          {props.t('ai stock traderaccount')}
        </p>
        <p className='unopened-card-details'>
          {props.t('unopenedaistockdetails')}
        </p>
        <Button
          className='unopened-card-btn aistock'
          label={props.t('getaistocktrader')}
          onClick={() => {
            setBtnLabel(props.t('createaistocktraderaccount'))
            if (props.subStatus) {
              if (props.driveWealthStatus) {
                setShowConfirmationDialog(true)
              } else {
                setApexForm(true)
              }
            } else {
              setSubMessageDialog(true)
            }
          }}
        />
      </div>
    )
  }

  const getAiCryptoCard = () => {
    return (
      <div className='unopened-card aicrypto'>
        {' '}
        <p className='unopened-card-header'>
          {props.t('ai crypto traderaccount')}
        </p>
        <p className='unopened-card-details'>
          {props.t('unopenedaicryptodetails')}
        </p>
        <Button
          className='unopened-card-btn aicrypto'
          label={props.t('getaicryptotrader')}
          onClick={() => {
            if (props.subStatus) {
              setApexForm(true)
            } else {
              setSubMessageDialog(true)
            }
          }}
        />
      </div>
    )
  }

  const getGoals = () => {
    return (
      <div className='unopened-card goal'>
        {' '}
        <p className='unopened-card-header'>{props.t('goalaccount')}</p>
        <p className='unopened-card-details'>
          {props.t('unopenedgoalsdetails')}
        </p>
        <Button
          className='unopened-card-btn goal'
          label={props.t('getgoals')}
          onClick={() => {
            setBtnLabel(props.t('creategoalsaccount'))
            if (props.subStatus) {
              if (props.driveWealthStatus) {
                setShowConfirmationDialog(true)
              } else {
                setApexForm(true)
              }
            } else {
              setSubMessageDialog(true)
            }
          }}
        />
      </div>
    )
  }

  const renderCards = () => {
    switch (props.name) {
      case 'STOCK':
        return getStockCard()
      case 'CRYPTO':
        return getCrytoCard()
      case 'ROBO':
        return getRoboCard()
      case 'AI STOCK TRADER':
        return getAiStockCard()
      case 'AI CRYPTO TRADER':
        return getAiCryptoCard()
      case 'GOALS':
        return getGoals()
      default:
        return null
    }
  }

  const customHeader = () => {
    switch (props.name) {
      case 'STOCK':
        return `${props.t('thiswillcreatea')} ${props.t('stock')} ${props.t(
          'accountforyou'
        )}. ${props.t('wouldyouliketoproceed')}`
      case 'CRYPTO':
        return props.t('thiswillcreatecrypto')
      case 'ROBO':
        return `${props.t('thiswillcreatea')} ${props.t('robo')} ${props.t(
          'accountforyou'
        )}. ${props.t('wouldyouliketoproceed')}`
      case 'AI STOCK TRADER':
        return props.t('thiswillcreateaistock')
      case 'AI CRYPTO TRADER':
        return props.t('thiswillcreateaicrypto')
      case 'GOALS':
        return props.t('thiswillcreategoals')
      default:
        return null
    }
  }

  const customClassName = () => {
    switch (props.name) {
      case 'STOCK':
        return ''
      case 'CRYPTO':
        return ''
      case 'ROBO':
        return 'robo'
      case 'AI STOCK TRADER':
        return 'group'
      case 'AI CRYPTO TRADER':
        return 'group'
      case 'GOALS':
        return 'goals'
      default:
        return null
    }
  }

  if (props.t) {
    return (
      <>
        {renderCards()}
        <Dialog
          visible={apexForm}
          header={props.t('createcryptoaccount')}
          onHide={() => setApexForm(false)}
          style={{ width: props.isSmallScreen ? '90vw' : '50vw' }}
        >
          {/* <ApexForm /> */}
          <DwApexQuestionnaire
            user={props.user}
            action={props.getAllAccountInfoV2}
            driveWealthStatus={props.driveWealthStatus}
            apexStatus={props.apexStatus}
            selectedAccount={props.name}
            getUserFullName={props.getUserFullName}
          />
        </Dialog>
        <ConfirmationDialog
          isSmallScreen={props.isSmallScreen}
          name={btnLabel}
          successMessage={successMessage}
          toggleShowConfirmationDialog={toggleShowConfirmationDialog}
          showConfirmationDialog={showConfirmationDialog}
          customHeader={customHeader()}
          action={activateDW}
          btnClassName={customClassName()}
          t={props.t}
        />
        <Dialog
          visible={cryptoAllowedDialog}
          header={props.t('cryptoisnotsupportedinyourarea')}
          onHide={() => setCryptoAllowedDialog(false)}
        ></Dialog>
        <Dialog
          visible={subMessageDialog}
          header={props.t('pleaseupgradetoinvestallprotoaccessthisproduct')}
          onHide={() => setSubMessageDialog(false)}
        >
          <SubModal
            subStatus={props.subStatus}
            getSubStatus={props.getSubStatus}
            modal={true}
            user={props.user}
            userEmail={props.userEmail}
            subStatusMessage={props.subStatusMessage}
            subStatusExpirationDate={props.subStatusExpirationDate}
            appleStatus={props.appleStatus}
            freeTrialUsed={
              props.userDetails?.accountSub &&
              props.userDetails?.accountSub?.trialUsed
            }
            t={props.t}
          />
        </Dialog>
      </>
    )
  } else return null
}
