import React, { Component } from 'react'
import { TailSpin } from 'react-loader-spinner'
import Loader from './accountInfo/Loader'

export default class RecurringBuyForm extends Component {
  state = {
    amountValue: 0,
    frequency: 'MONTHLY',
    roundUp: false,
    selectedAccountId: null,
    formError: null,
  }

  showMessage() {
    if (this.props.editRecBuyStatus === 'success') {
      return (
        <div style={{ padding: '15px' }}>
          <p style={{ fontSize: '15px', textAlign: 'center' }}>
            {this.props.selectedEditRecurBuy} updated successfully.
          </p>
        </div>
      )
    } else if (this.props.editRecBuyStatus === 'error') {
      return (
        <div style={{ padding: '15px' }}>
          <p style={{ fontSize: '15px', color: 'red', textAlign: 'center' }}>
            An error has occured. Please try again
          </p>
        </div>
      )
    } else {
      return (
        <div style={{ width: '10%', margin: 'auto' }}>
          <Loader logoLoader={false} />
        </div>
      )
    }
  }

  handleAmountChange = (event) => {
    this.setState({ amountValue: event.target.value })
  }

  handleFrequencyChange = (event) => {
    this.setState({
      frequency: event.target.value,
    })
  }

  handleRoundUpChange = (event) => {
    this.setState({
      roundUp: !this.state.roundUp,
    })
  }

  handleAccountChange = (event) => {
    this.setState({
      selectedAccountId: event.target.value,
    })
  }

  buyAmountForm() {
    return (
      <div>
        <form>
          <div
            style={{
              display: this.props.isSmallScreen ? 'block' : 'flex',
              justifyContent: 'space-between',
              padding: '15px',
            }}
          >
            <div style={{ position: 'relative' }}>
              <i
                style={{
                  position: 'absolute',
                  display: 'block',
                  transform: 'translate(0, -50%)',
                  top: '50%',
                  left: '5%',
                  pointerEvents: 'none',
                  width: '25px',
                  textElign: 'center',
                  fontStyle: 'normal',
                }}
              >
                $
              </i>
              <input
                style={{ padding: '5px 10px 5px 25px', borderRadius: '15px' }}
                type='number'
                name='amountInput'
                value={this.state.amountValue}
                onChange={this.handleAmountChange}
              ></input>
            </div>
            <div
              style={{
                display: this.props.isSmallScreen ? 'block' : 'flex',
                alignContent: 'center',
                marginTop: this.props.isSmallScreen ? '15px' : '0',
              }}
            >
              <label
                style={{ paddingRight: '5px', marginTop: '5px' }}
                for='frequency'
              >
                Change Frequency:
              </label>
              <select
                onChange={this.handleFrequencyChange}
                id='frequency'
                name='frequency'
                value={this.state.frequency}
                style={{ borderRadius: '15px' }}
              >
                <option value='DAILY'>Daily</option>
                <option value='WEEKLY'>Weekly</option>
                <option value='BI-WEEKLY'>Bi-Weekly</option>
                <option value='MONTHLY'>Monthly</option>
              </select>
            </div>
          </div>
        </form>
      </div>
    )
  }

  roundUpForm() {
    return (
      <div style={{ padding: '15px' }}>
        <label>
          <input
            style={{ marginRight: '5px' }}
            type='checkbox'
            value={this.state.roundUp}
            onChange={this.handleRoundUpChange}
          />
          Round Up
        </label>
        {this.state.roundUp && !this.props.isSmallScreen ? (
          <div
            style={{
              border: '1px solid rgb(8, 143, 143)',
              background: 'rgb(175, 225, 175)',
              color: 'rgb(8, 143, 143)',
              padding: '15px',
              borderRadius: '15px',
            }}
          >
            <p>
              The spare change from your daily purchases will be aggregated and
              invested into this account every day and used to buy
              {this.props.selectedEditRecurBuy} every week.
            </p>
            <p>
              This feature will round up the spare change from all your linked
              bankng and credit accounts.
            </p>
          </div>
        ) : null}
      </div>
    )
  }

  selectAccountForm() {
    let accountList =
      this.props.accounts.length > 0 &&
      this.props.accounts.map((account, i) => {
        if (account.type === 'depository')
          return (
            <option key={i} value={account.accountId}>
              {account.name}(${account.cash_account})
            </option>
          )
      })
    return (
      <div
        style={{
          padding: '15px',
          display: this.props.isSmallScreen ? 'block' : 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <label
          style={{ paddingRight: '5px', marginTop: '15px' }}
          for='chooseAccount'
        >
          Choose Account:
        </label>
        <select
          id='chooseAccount'
          name='chooseAccount'
          style={{
            borderRadius: '10px',
            padding: '15px',
            width: this.props.isSmallScreen ? '100%' : '50%',
          }}
          onChange={this.handleAccountChange}
        >
          {accountList}
        </select>
      </div>
    )
  }

  submitButton() {
    return (
      <div
        style={{ padding: '15px', display: 'flex', justifyContent: 'center' }}
      >
        <button
          style={{
            color: 'white',
            background: '#3F46F6',
            borderRadius: '25px',
            padding: '10px 15px 10px 15px',
            fontSize: '15px',
          }}
          onClick={() => {
            this.validateForm()
          }}
        >
          Submit
        </button>
      </div>
    )
  }

  validateForm() {
    if (this.state.amountValue > 0 || this.state.roundUp) {
      this.props.setRecBuy(
        this.props.selectedAccount.accountId,
        this.state.selectedAccountId,
        this.state.amountValue,
        this.props.selectedEditRecurBuy,
        this.state.roundUp,
        this.state.frequency
      )
    } else {
      this.setState({
        formError: 'Please choose a value greater than one or round up option',
      })
    }
  }

  showError() {
    return (
      <div style={{ padding: '15px' }}>
        <p style={{ color: 'red', fontSize: '15px', textAlign: 'right' }}>
          {this.state.formError}
        </p>
      </div>
    )
  }

  recurringBuyForm() {
    return (
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: 'auto',
          backgroundColor: 'rgba(0,0,0, 0.5)',
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: this.props.isSmallScreen ? '5%' : '25%',
            right: '25%',
            top: '25%',
            bottom: '25%',
            margin: 'auto',
            borderRadius: '20px',
            background: 'white',
            padding: '15px',
            height: this.props.isSmallScreen
              ? '60%'
              : this.state.roundUp
              ? '70%'
              : '50%',
            width: this.props.isSmallScreen ? '90%' : '60%',
          }}
        >
          <div
            style={{
              width: '10%',
              justifyContent: 'right',
              display: 'flex',
              margin: 'auto',
              marginRight: '0',
              textAlign: 'right',
              background: 'transparent',
              border: 'none',
              padding: '1px',
            }}
          >
            <p
              onClick={() => this.props.closeAndResetEditModal()}
              style={{ fontSize: '25px', cursor: 'pointer' }}
            >
              X
            </p>
          </div>
          <p style={{ textAlign: 'center', fontSize: '25px' }}>
            Edit {this.props.selectedEditRecurBuy} Recurring Buy
          </p>
          {this.props.editRecBuyStatus ? (
            this.showMessage()
          ) : (
            <div
              style={{
                margin: 'auto',
                justifyContent: 'space-evenly',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: this.props.isSmallScreen ? '100%' : '80%',
                  margin: 'auto',
                }}
              >
                {this.buyAmountForm()}
                {this.roundUpForm()}
                {this.selectAccountForm()}
                {this.submitButton()}
                {this.state.formError ? this.showError() : null}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
  render() {
    return <div>{this.recurringBuyForm()}</div>
  }
}
