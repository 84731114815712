import { createSlice } from '@reduxjs/toolkit'

export const navbarSlice = createSlice({
  name: 'navbar',
  initialState: {
    username: '',
  },
  reducers: {
    setUsername: (state, action) => {
      return {
        ...state,
        username: action.payload,
      }
    },
  },
})

export const { setUsername } = navbarSlice.actions

export default navbarSlice.reducer
