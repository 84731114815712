import React, { Component } from "react";
import Commentary from "./Commentary";
import { Row, Container } from "react-bootstrap";

class CommentayYearList extends Component {
  render() {
    return (
      <Row>
        <Container className="year-list-commentary">
          <Row>
            <h4>{this.props.value[0].year}</h4>
          </Row>
          <Row>
            {this.props.value.map(commentary => (
              <Commentary key={commentary.id} value={commentary} />
            ))}
          </Row>
        </Container>
      </Row>
    );
  }
}

export default CommentayYearList;
