//REACT
import i18next from 'i18next'
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//CONTEXT
import { ProductPageContext } from './ProductPage'

export default function AutomateTrading() {
  const { t, isSmallScreen } = useContext(ProductPageContext)
  return (
    <div className='blue-background'>
      <div className='center-container-small'>
        {i18next.language === 'zh' ? (
          <p className='big-text light-text'>{t('automate_trading_with')}</p>
        ) : (
          <>
            <p className='big-text light-text'>{t('automate_trading_with')}</p>
            <p className='big-text light-text'>
              <span className='big-text bold-text'>{t('robo')}</span>{' '}
              <span className='big-text bold-text'>{t('aitrader')}</span>{' '}
              {t('and')}{' '}
              <span className='big-text bold-text'>{t('goals')}</span>
            </p>
          </>
        )}
      </div>
    </div>
  )
}
