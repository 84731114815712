import Axios from 'axios'
import { ProgressBar } from 'primereact/progressbar'
import React, { useEffect, useState } from 'react'
import Endpoint from '../Endpoint'
import { FaDev } from 'react-icons/fa'
import {
  IoChevronDown,
  IoCloseCircle,
  IoCheckmarkCircle,
  IoArrowForward,
  IoArrowDown,
  IoCheckmark,
} from 'react-icons/io5'

import CoinStackIcon from '../../assets/newBudgetPage/coin-stack-icon.svg'
import CharitableGivingIcon from '../../assets/newBudgetPage/charitable-gifting-icon-black.svg'
import InvestmentIcon from '../../assets/newBudgetPage/investment-icon-black.svg'
import RentIcon from '../../assets/newBudgetPage/Icon=rent.svg'
import DebtIcon from '../../assets/newBudgetPage/Icon=debt.svg'
import MedicalIcon from '../../assets/newBudgetPage/Icon=health.svg'
import PetIcon from '../../assets/newBudgetPage/Icon_pet.svg'
import HomeImprovementIcon from '../../assets/newBudgetPage/Icon=home_repair.svg'
import GeneralServiceIcon from '../../assets/newBudgetPage/Icon=General_Services.svg'
import InsuranceIcon from '../../assets/newBudgetPage/Icon_insurance.svg'
import ChildcareIcon from '../../assets/newBudgetPage/Icon_childcare.svg'
import VehicleIcon from '../../assets/newBudgetPage/Icon=car_repair.svg'
import GeneralMerchandiseIcon from '../../assets/newBudgetPage/Icon_General_Merchandise.svg'
import PersonalCareIcon from '../../assets/newBudgetPage/Icon_Personal_Care.svg'
import TravelIcon from '../../assets/newBudgetPage/Icon_Travel.svg'
import EntertainmentIcon from '../../assets/newBudgetPage/Icon=Entertainment.svg'
import FoodIcon from '../../assets/newBudgetPage/Icon=restaurants.svg'
import TransportationIcon from '../../assets/newBudgetPage/Icon_transportation.svg'
import BankFeeIcon from '../../assets/newBudgetPage/Icon=subscription.svg'
import GovFeeIcon from '../../assets/newBudgetPage/Icon=Government_Non_Profit.svg'
import MiscIcon from '../../assets/newBudgetPage/Icon_miscelaneous.svg'

import moment from 'moment'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import LinkPlaidCustom from '../components/LinkPlaidCustom'

export default function FinancialYoga({
  setCurrComponent,
  userInfo,
  user,
  budgetData,
  fetchBudgetingInformation,
  t,
}) {
  const getSpecificCategory = (name) => {
    return budgetData?.category_details?.find((item) => item.category === name)
  }

  const incomeMonthlyAverage = getSpecificCategory('Income')?.['6m_avg']
  const incomeMonthToDate = getSpecificCategory('Income')?.['mtd']
  const expenseMonthlyAverage = getSpecificCategory('Expense')?.['6m_avg']
  const expenseMonthToDate = getSpecificCategory('Expense')?.['mtd']

  const [step, setStep] = useState('review')
  const [categories, setCategories] = useState(null)
  const [transactionIndex, setTransactionIndex] = useState(0)
  const [showChangeCategoryModal, setShowChangeCategoryModal] = useState(false)
  const [currentTransaction, setCurrentTransaction] = useState(null)
  const [categorySelectValue, setCategorySelectValue] = useState(null)
  const [transactionsToChange, setTransactionsToChange] = useState(null)
  const [changeCategoryList, setChangeCategoryList] = useState(
    budgetData.transactions_with_steal?.map(() => {
      return {
        status: false,
        primary: '',
        secondary: '',
      }
    })
  )
  const [linkedAccounts, setLinkedAccounts] = useState({})

  useEffect(() => {
    getChangeCategoriesList()
  }, [])

  useEffect(() => {
    if (step) {
      console.log('step', step)
    }
  }, [step])

  useEffect(() => {
    if (currentTransaction) {
      setCategorySelectValue(null)
      if (step !== 'review_opportunity_cost') setStep('change_transaction')
    }
  }, [currentTransaction])

  const getPlaidAccounts = async () => {
    try {
      let res = await Axios.get(`${Endpoint}/budget-new/get_linked_accounts`, {
        headers: {
          Authorization: 'Bearer ' + user?.idToken?.jwtToken,
        },
      })
      return res?.data?.payload
    } catch (error) {
      console.log(error)
      return []
    }
  }

  // API get all categories
  const getChangeCategoriesList = async () => {
    try {
      let res = await Axios.get(
        `${Endpoint}/budget-new/get_investall_categories`,
        {
          headers: {
            Authorization: 'Bearer ' + user?.idToken?.jwtToken,
          },
        }
      )

      // Transform the API data to the desired format for dropdown
      const transformedData = res?.data?.payload?.map((item) => ({
        label: item.category, // Map "category" to "label"
        sub_categories: item?.sub_cat?.map((subCategory) => ({
          label: subCategory,
          value: item.category + '|' + subCategory,
        })),
      }))
      setCategories(transformedData)
    } catch (error) {
      console.log(error)
    }
  }

  const changeTransactionCategoryFromParams = async (
    primary,
    secondary,
    transactionId
  ) => {
    try {
      let body = {
        investall_catogry_primary: primary,
        investall_catogry_detailed: secondary,
        transaction_id: transactionId,
      }
      console.log('body', body)
      let res = await Axios.post(
        Endpoint + '/budget-new/change_category',
        body,
        {
          headers: {
            Authorization: 'Bearer ' + user?.idToken?.jwtToken,
          },
        }
      )
      console.log(res)
      return true
    } catch (error) {
      console.log('change category for transaction error', error)
      return false
    }
  }

  const changeTransactionCategory = async () => {
    try {
      let body = {
        investall_catogry_primary: categorySelectValue.split('|')[0],
        investall_catogry_detailed: categorySelectValue.split('|')[1],
        transaction_id: currentTransaction.transactionId,
      }
      let res = await Axios.post(
        Endpoint + '/budget-new/change_category',
        body,
        {
          headers: {
            Authorization: 'Bearer ' + user?.idToken?.jwtToken,
          },
        }
      )
      console.log(res)
      return
    } catch (error) {
      console.log('change category for transaction error', error)
      return
    }
  }

  // API get suggested transactions to change
  const fetchChangeTransactionSuggestions = async () => {
    try {
      let body = {
        previous_cat: {
          primary: currentTransaction.primaryCategory,
          detailed: currentTransaction.secondaryCategory,
        },
        new_cat: {
          primary: categorySelectValue.split('|')[0],
          detailed: categorySelectValue.split('|')[1],
        },
        transaction_id: currentTransaction.transactionId,
      }
      let res = await Axios.post(
        Endpoint + '/budget-new/change_suggestion',
        body,
        {
          headers: {
            Authorization: 'Bearer ' + user?.idToken?.jwtToken,
          },
        }
      )
      console.log(res)
      const transformedData = res?.data?.payload?.map((transaction) => {
        return {
          ...transaction,
          selected: true,
        }
      })
      if (transformedData.length === 0) {
        // setStep('completed_transaction_review')
        setStep('review_transactions')
      } else {
        setTransactionsToChange(transformedData)
        setStep('suggested_transactions')
      }
      return
    } catch (error) {
      console.log('Suggested Transaction API error', error)
      return
    }
  }

  // API Mark as read
  const markAsRead = async (id) => {
    try {
      let body = {}
      // MARK AS READ SINGLE
      if (id) {
        body = {
          transaction_ids: [id],
          is_read: 1,
        }
      }
      // IF: MARK AS READ 10 AT A TIME
      else {
        let idList = []
        for (let i = transactionIndex; i < transactionIndex + 10; i++) {
          const id = budgetData?.transactions_to_check[i]?.transaction_id
          idList.push(id)
        }
        body = {
          transaction_ids: idList,
          is_read: 1,
        }
      }
      console.log(body)
      let res = await Axios.post(Endpoint + '/budget-new/mark_read', body, {
        headers: {
          Authorization: 'Bearer ' + user?.idToken?.jwtToken,
        },
      })
      console.log(res)

      // AFTERWARDS refresh data
      fetchBudgetingInformation()
    } catch (error) {
      console.log('Mark as read Error', error)
    }
  }

  const TransactionCard = (props) => {
    return (
      <div className='content-container-box'>
        <div className='transaction-card'>
          <div className='transaction-header-row'>
            <p className='gray'>Card Name</p>
            <p className='gray'>
              {props.date &&
                moment.unix(props.date / 1000).format('MMM. DD, YYYY')}
            </p>
          </div>
          <div className='transaction-info'>
            {props.suggested ? (
              props.selected === true ? (
                <div
                  className='icon-container'
                  onClick={() => {
                    const index = transactionsToChange.findIndex(
                      (e) => e.transaction_id === props.transactionId
                    )
                    const tempList = [...transactionsToChange]
                    tempList[index].selected = false
                    setTransactionsToChange(tempList)
                  }}
                >
                  <IoCheckmark />
                </div>
              ) : (
                <div
                  className='icon-container'
                  onClick={() => {
                    const index = transactionsToChange.findIndex(
                      (e) => e.transaction_id === props.transactionId
                    )
                    const tempList = [...transactionsToChange]
                    tempList[index].selected = true
                    setTransactionsToChange(tempList)
                  }}
                ></div>
              )
            ) : (
              <div className='icon-container'>
                <img src={renderIcon(props.primaryCategory)} alt='' />
              </div>
            )}
            <div className='info'>
              <p className='transaction-title'>
                <span
                  className='change-category-btn'
                  onClick={() => {
                    if (props.opportunityCost) {
                      setCurrentTransaction({
                        ...props,
                      })
                      setShowChangeCategoryModal(true)
                    } else {
                      if (props.changeCategory) {
                        setShowChangeCategoryModal(true)
                      } else {
                        setCurrentTransaction({
                          ...props,
                        })
                      }
                    }
                  }}
                >
                  <IoChevronDown />
                </span>
                {props?.changeCategoryList &&
                props?.changeCategoryList[props.transactionIndex]?.status ===
                  true ? (
                  <span className='edit-highlight'>
                    {props.changeCategoryList[props.transactionIndex]?.primary}{' '}
                    -{' '}
                    {
                      props.changeCategoryList[props.transactionIndex]
                        ?.secondary
                    }
                  </span>
                ) : (
                  <span className={props.edited ? 'edit-highlight' : ''}>
                    {props.primaryCategory} - {props.secondaryCategory}
                  </span>
                )}
              </p>
              <p className='transaction-description'>{props.name}</p>
            </div>
            <p>{changedMoneyToUSFormat(props.amount)}</p>
          </div>
          {props.showReadButtons && (
            <div className='read-btns'>
              <IoCloseCircle
                size={28}
                className='read-btn incorrect'
                onClick={() =>
                  setCurrentTransaction({
                    ...props,
                  })
                }
              />
              <IoCheckmarkCircle
                size={28}
                className='read-btn correct'
                onClick={() => markAsRead(props.transactionId)}
              />
            </div>
          )}
          {props?.steals && (
            <div className='steal-container'>
              <p>Steals from:</p>
              {props?.steals?.map((row) => {
                return (
                  <div className='steal-row'>
                    <p>{row.category}</p>
                    <p>{changedMoneyToUSFormat(row.steal_amount * -1)}</p>
                  </div>
                )
              })}
            </div>
          )}
          {}
        </div>
      </div>
    )
  }

  const reviewUI = () => {
    return (
      <div className='financial-yoga-component-container'>
        <p className='instructions'>
          {t('hi_s_lets_begin', { name: userInfo?.firstName })}
        </p>
        <div className='content-container-box'>
          <p className='progress-label-right gray'>
            {t('mtd_monthly_average')}/
          </p>
          <div className='progress-container'>
            <div className='progress-bar-numbers'>
              <p className='title'>{t('income')}</p>
              <p className='numbers'>
                {changedMoneyToUSFormat(budgetData?.income)}
              </p>
            </div>
            <ProgressBar
              value={
                incomeMonthlyAverage
                  ? (
                      Math.abs(budgetData?.income / incomeMonthlyAverage) * 100
                    )?.toFixed(0)
                  : 0
              }
              className={getProgressBarClassName(
                incomeMonthlyAverage
                  ? (
                      (budgetData?.income / incomeMonthlyAverage) *
                      100
                    )?.toFixed(0)
                  : 0
              )}
            ></ProgressBar>
          </div>
          <div className='progress-container'>
            <div className='progress-bar-numbers'>
              <p className='title'>{t('expenses')}</p>
              <p className='numbers'>
                {changedMoneyToUSFormat(budgetData?.expenses)}
              </p>
            </div>
            <ProgressBar
              value={
                expenseMonthlyAverage
                  ? (
                      Math.abs(budgetData?.expenses / expenseMonthlyAverage) *
                      100
                    )?.toFixed(0)
                  : 0
              }
              className={'netural'}
            ></ProgressBar>
          </div>
          <p></p>
        </div>
        <button
          className='rounded-button'
          onClick={() => {
            setChangeCategoryList(
              budgetData.transactions_with_steal?.map(() => {
                return {
                  status: false,
                  primary: '',
                  secondary: '',
                }
              })
            )
            setStep('review_opportunity_cost')
          }}
        >
          {t('this_looks_correct')}
        </button>
        <button
          className='rounded-button inverse'
          onClick={() => setStep('incorrect_choice')}
        >
          {t('something_is_not_right')}
        </button>
      </div>
    )
  }

  const defineOpportunityCostUI = () => {
    return (
      <div className='financial-yoga-component-container'>
        <p className='title'>💆 Opportunity Cost</p>
        <p className='instructions'>{t('oppurtunity_cost_subtext')}</p>
        <p className='instructions'>
          Let's review opportunity costs you've had recently.
        </p>
        <TransactionCard />
        <button
          className='rounded-button'
          onClick={() => setStep('review_opportunity_cost')}
        >
          {t('text')}
        </button>
        <button
          className='rounded-button inverse'
          onClick={() => handleBackButton()}
        >
          {t('previous')}
        </button>
      </div>
    )
  }

  const reviewOpportunityCostUI = () => {
    return (
      <div className='financial-yoga-component-container'>
        <p className='title'>💆 {t('opportunity_cost')}</p>
        <p className='instructions'>{t('review_opportunity_cost_1')}</p>
        {budgetData?.transactions_with_steal?.map((transaction, k) => {
          return (
            <TransactionCard
              key={k}
              transactionIndex={k}
              transactionId={transaction.transaction_id}
              date={transaction.date}
              amount={transaction.amount}
              primaryCategory={transaction?.investall_category_primary}
              secondaryCategory={transaction?.investall_category_detailed}
              name={transaction.name}
              steals={transaction?.steal_froms}
              opportunityCost={true}
              changeCategoryList={changeCategoryList}
            />
          )
        })}
        <p className='instructions'>{t('review_opportunity_cost_2')}</p>
        <button
          className='rounded-button'
          onClick={() => {
            checkIfStealCategoryChanges()
          }}
        >
          {t('next')}
        </button>
        <button
          className='rounded-button inverse'
          onClick={() => handleBackButton()}
        >
          {t('previous')}
        </button>
      </div>
    )
  }

  const incorrectChoiceUI = () => {
    return (
      <div className='financial-yoga-component-container'>
        <p className='instructions'>
          {t('ok_let_s_figure_out_why_things_are_off')}
        </p>
        <p className='instructions'>
          {t('you_may_be_missing_income_and_expense_transactions')}
        </p>
        <div style={{ width: '100%' }}>
          <button
            className='rounded-button inverse'
            onClick={() => fetchLinkedAccounts()}
          >
            {t('link_additional_accounts')}
          </button>
        </div>
        <p className='instructions'>
          {t('some_transactions_may_be_going_to_the_wrong_category')}
        </p>
        <button
          className='rounded-button inverse'
          onClick={() => {
            setTransactionIndex(0)
            setCurrentTransaction(null)
            setCategorySelectValue(null)
            setChangeCategoryList(
              budgetData.transactions_with_steal?.map(() => {
                return {
                  status: false,
                  primary: '',
                  secondary: '',
                }
              })
            )
            setStep('review_transactions')
          }}
        >
          {t('review')}
        </button>
      </div>
    )
  }

  const reviewTransactionsUI = () => {
    return (
      <div className='financial-yoga-component-container'>
        <p className='title'>{t('transaction_review')}</p>
        <div className='instructions'>
          {t('were_these_ten_transactions_categorized_correctly')}
        </div>
        <div className='transactions'>
          <div className='transaction-review-btns'>
            <p className='gray' onClick={() => markAsRead()}>
              {t('all_good')}
            </p>
            {budgetData?.transactions_to_check?.length - transactionIndex >
              10 && (
              <p className='gray' onClick={() => nextTransactionPage()}>
                {t('next')} 10 <IoArrowForward />
              </p>
            )}
          </div>
          {budgetData?.transactions_to_check
            ?.slice(transactionIndex, transactionIndex + 10)
            ?.map((transaction, i) => {
              return (
                <TransactionCard
                  key={i}
                  transactionId={transaction.transaction_id}
                  date={transaction.date}
                  amount={transaction.amount}
                  primaryCategory={transaction?.investall_category_primary}
                  secondaryCategory={transaction?.investall_category_detailed}
                  name={transaction.name}
                  showReadButtons={true}
                />
              )
            })}
        </div>
        <button
          className='rounded-button inverse'
          onClick={() => {
            setStep('review_opportunity_cost')
          }}
        >
          {t('skip_all_transactions')}
        </button>
      </div>
    )
  }

  const changeSingleTransactionUI = () => {
    return (
      <div className='financial-yoga-component-container'>
        <p className='instructions'>{t('transaction_correction_subtext')}</p>
        <p className='instructions'>{t('transaction_correction_subtext_2')}</p>
        {currentTransaction && (
          <TransactionCard
            transactionId={currentTransaction.transaction_id}
            date={currentTransaction.date}
            amount={currentTransaction.amount}
            primaryCategory={
              categorySelectValue
                ? categorySelectValue.split('|')[0]
                : currentTransaction?.primaryCategory
            }
            secondaryCategory={
              categorySelectValue
                ? categorySelectValue.split('|')[1]
                : currentTransaction?.secondaryCategory
            }
            name={currentTransaction.name}
            changeCategory={true}
            edited={categorySelectValue}
          />
        )}
        <button
          disabled={!categorySelectValue}
          className='rounded-button'
          onClick={() => updateCategory()}
        >
          {t('next')}
        </button>
      </div>
    )
  }

  const showSuggestedTransactionsUI = () => {
    return (
      <div className='financial-yoga-component-container'>
        <p className='instructions'>
          {t('category_change_suggestions_subtext')}
        </p>
        <p className='instructions'>
          {t('category_change_suggestions_subtext_2')}
        </p>
        {transactionsToChange?.map((transaction, i) => {
          return (
            <TransactionCard
              key={i}
              transactionId={transaction.transaction_id}
              date={transaction.date}
              amount={transaction.amount}
              primaryCategory={transaction?.investall_category_primary}
              secondaryCategory={transaction?.investall_category_detailed}
              name={transaction.name}
              selected={transaction?.selected}
              suggested={true}
            />
          )
        })}
        <button
          className='rounded-button'
          onClick={() => submitChangesForSuggestedTransactions()}
        >
          {t('change_selected_to_s', {
            category:
              categorySelectValue.split('|')[0] +
              ' - ' +
              categorySelectValue.split('|')[1],
          })}
        </button>
      </div>
    )
  }

  const completedTransactionReviewUI = () => {
    return (
      <div className='financial-yoga-component-container'>
        <p className='title'>Complete!</p>
        <p className='title'>
          Great, you've reviewed your transactions! Your Dynamic Spending will
          update automatically to reflect any new changes.
        </p>
        <p className='instructions'>💆 Review Opportunity Cost</p>
        <p className='instructions'>
          Training your brain to think about “opportunity cost” helps you spend
          smarter naturally and it leads to better life outcomes.
        </p>
        <button
          className='rounded-button'
          onClick={() => setStep('review_opportunity_cost')}
        >
          Review Opportunity Cost
        </button>
      </div>
    )
  }

  const completeUI = () => {
    return (
      <div className='financial-yoga-component-container'>
        <p className='title'>{t('complete')}!</p>
        <p className='title'>{t('oppurtunity_cost_complete_subtext1')}</p>
        <button
          className='rounded-button'
          onClick={() => setCurrComponent('main')}
        >
          {t('finish')}
        </button>
      </div>
    )
  }

  const linkedAccountsUI = () => {
    return (
      <div className='financial-yoga-component-container'>
        <div>
          <p className='linked-accounts-title'>{t('connected_accounts')}</p>
          <p className='linked-accounts-subtitle'>
            {t('linked_accounts_subtext')}
          </p>
        </div>
        <div className='linked-accounts-section'>
          {Object.keys(linkedAccounts).map((type, i) => {
            if (linkedAccounts[type].length > 0) {
              return (
                <div className='account-type-container' key={i}>
                  <p className='account-type'>{t(type)}</p>
                  {linkedAccounts[type].map((account, j) => {
                    return (
                      <div className='account-row'>
                        <p>
                          {account?.inst_name}...{account?.mask}
                        </p>
                        <p>{changedMoneyToUSFormat(account?.current)}</p>
                      </div>
                    )
                  })}
                </div>
              )
            }
            return null
          })}
        </div>
        <LinkPlaidCustom
          user={user}
          isBank={true}
          reLink={false}
          t={t}
          onSuccess={onSuccess}
        >
          <button className='rounded-button'>{t('link_more_accounts')}</button>
        </LinkPlaidCustom>
      </div>
    )
  }

  const yogaUI = () => {
    switch (step) {
      case 'review_opportunity_cost':
        return reviewOpportunityCostUI()
      case 'incorrect_choice':
        return incorrectChoiceUI()
      case 'linked_accounts':
        return linkedAccountsUI()
      case 'review_transactions':
        return reviewTransactionsUI()
      case 'change_transaction':
        return changeSingleTransactionUI()
      case 'suggested_transactions':
        return showSuggestedTransactionsUI()
      case 'completed_transaction_review':
        return completedTransactionReviewUI()
      case 'complete':
        return completeUI()
      default:
        return reviewUI()
    }
  }

  const renderIcon = (category) => {
    switch (category) {
      case 'Income':
        return CoinStackIcon
      case 'Transfers':
        return CoinStackIcon
      case 'Charitable Giving':
        return CharitableGivingIcon
      case 'Investment':
        return InvestmentIcon
      case 'Expense':
        return CoinStackIcon
      case 'Rent & Utilities':
        return RentIcon
      case 'Debt Payments':
        return DebtIcon
      case 'Medical':
        return MedicalIcon
      case 'Pet Care':
        return PetIcon
      case 'Home Improvement':
        return HomeImprovementIcon
      case 'General Services':
        return GeneralServiceIcon
      case 'Insurance':
        return InsuranceIcon
      case 'Childcare & Education':
        return ChildcareIcon
      case 'Vehicle Maintenance':
        return VehicleIcon
      case 'General Merchandise':
        return GeneralMerchandiseIcon
      case 'Personal Care':
        return PersonalCareIcon
      case 'Travel':
        return TravelIcon
      case 'Entertainment':
        return EntertainmentIcon
      case 'Food & Drink':
        return FoodIcon
      case 'Transportation':
        return TransportationIcon
      case 'Bank Fees':
        return BankFeeIcon
      case 'Government Fees':
        return GovFeeIcon
      case 'Miscellaneous':
        return MiscIcon
      default:
        return MiscIcon
    }
  }

  const onSuccess = async () => {
    await fetchLinkedAccounts()
  }

  const fetchLinkedAccounts = async () => {
    let accounts = await getPlaidAccounts()
    console.log('accounts', accounts)
    let accountsObj = {
      depository: [],
      credit: [],
      investment: [],
      loan: [],
      other: [],
    }
    for (let i in accounts) {
      accountsObj[accounts[i].type].push(accounts[i])
    }
    setLinkedAccounts(accountsObj)
    setStep('linked_accounts')
  }

  const checkIfStealCategoryChanges = async () => {
    let changed = false
    for (let i in changeCategoryList) {
      if (changeCategoryList[i].status === true) {
        console.log('i', i)
        await changeTransactionCategoryFromParams(
          changeCategoryList[i].primary,
          changeCategoryList[i].secondary,
          budgetData?.transactions_with_steal?.[i]?.transaction_id
        )
        changed = true
      }
    }
    if (changed) await fetchBudgetingInformation()
    setStep('complete')
  }

  const updateCategory = async () => {
    try {
      await changeTransactionCategory()
      await fetchChangeTransactionSuggestions()
    } catch (error) {
      console.log('update category error', error)
    }
  }

  const submitChangesForSuggestedTransactions = async () => {
    const ids = []
    for (let transaction in transactionsToChange) {
      if (transactionsToChange[transaction].selected === true) {
        ids.push(transactionsToChange[transaction].transaction_id)
      }
    }
    // previous_cat: {
    //   primary: currentTransaction.primaryCategory,
    //   detailed: currentTransaction.secondaryCategory,
    // },
    // next_cat: {
    //   primary: categorySelectValue.split('|')[0],
    //   detailed: categorySelectValue.split('|')[1],
    // },
    let body = {
      investall_catogry_primary: categorySelectValue.split('|')[0],
      investall_catogry_detailed: categorySelectValue.split('|')[1],
      transaction_ids: ids,
    }

    console.log('change suggestion body', body)

    let res = await Axios.post(Endpoint + '/budget-new/change_category', body, {
      headers: {
        Authorization: 'Bearer ' + user?.idToken?.jwtToken,
      },
    })

    console.log('change category for suggested transactions res', res)

    await fetchBudgetingInformation()

    setStep('review_transactions')
  }

  const nextTransactionPage = () => {
    // Check for last page
    if (budgetData?.transactions_to_check?.length - transactionIndex > 10)
      setTransactionIndex(transactionIndex + 10)
  }

  const handleBackButton = () => {
    if (step === 'review') {
      setCurrComponent('main')
    } else {
      switch (step) {
        case 'change_transaction':
          setStep('review_transactions')
          return
        case 'review_transactions':
          setStep('incorrect_choice')
          return
        case 'complete':
          setStep('review_opportunity_cost')
          return
        default:
          setStep('review')
          return
      }
    }
  }

  const getProgressBarClassName = (ratio) => {
    if (Number(ratio) === 0) {
      return 'netural'
    } else if (Number(ratio) < 0) {
      return 'negative-bar'
    } else {
      return 'positive-bar'
    }
  }

  const changedMoneyToUSFormat = (amount) => {
    const formattedNumber = amount?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    })

    return `${formattedNumber ? formattedNumber : '$0.00'}`
  }

  const formatTitleText = (string) => {
    const words = string.split(' ')

    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1)
    }

    return words.join(' ')
  }

  return (
    <div>
      <span
        // TODO: Handle custom back button logic
        // If on first step go back to main component
        // If on further steps go back to previous step
        onClick={() => handleBackButton()}
        className='budget-back-button'
      >
        {'<'} {t('back')}
      </span>
      {yogaUI()}
      <Dialog
        header={t('change_category')}
        visible={showChangeCategoryModal}
        onHide={() => {
          // IF: opportunity cost flow
          if (currentTransaction?.transactionIndex) {
            let temp = [...changeCategoryList]
            temp[currentTransaction?.transactionIndex] = {
              status: false,
              primary: '',
              secondary: '',
            }
            setChangeCategoryList([...temp])
          }
          setCategorySelectValue(null)
          setShowChangeCategoryModal(false)
        }}
        draggable={false}
        resizable={false}
        className='change-category'
      >
        <div className='change-category-modal-content'>
          <Dropdown
            value={categorySelectValue}
            options={categories}
            onChange={(e) => setCategorySelectValue(e.value)}
            optionLabel='label'
            optionGroupLabel='label'
            optionGroupChildren='sub_categories'
            style={{ width: '100%' }}
            scrollHeight='300px'
          />
          <p className='category-title'>{t('current_category')}:</p>
          <p className='category-value'>
            {currentTransaction?.primaryCategory} -{' '}
            {currentTransaction?.secondaryCategory}
          </p>
          <IoArrowDown className='middle' size={48} />
          <p className='category-title'>{t('new_category')}:</p>
          <p className='category-value'>
            {categorySelectValue &&
              categorySelectValue.split('|')[0] +
                ' - ' +
                categorySelectValue.split('|')[1]}
          </p>
          <button
            className='rounded-button end'
            disabled={
              !categorySelectValue
                ? true
                : categorySelectValue.split('|')[0] ===
                    currentTransaction?.primaryCategory &&
                  categorySelectValue.split('|')[1] ===
                    currentTransaction?.secondaryCategory
            }
            onClick={() => {
              // If there is a transaction id then this is opportunity cost flow
              if (
                currentTransaction?.transactionIndex ||
                currentTransaction?.transactionIndex === 0
              ) {
                let temp = [...changeCategoryList]
                temp[currentTransaction?.transactionIndex] = {
                  status: true,
                  primary: categorySelectValue.split('|')[0],
                  secondary: categorySelectValue.split('|')[1],
                }
                console.log('temp', temp)
                setChangeCategoryList([...temp])
              }
              setShowChangeCategoryModal(false)
            }}
          >
            {t('update')}
          </button>
        </div>
      </Dialog>
    </div>
  )
}
