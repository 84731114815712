import Axios from "axios";
import React, { Component } from "react";
import ENDPOINT from "../Endpoint";
import { Line } from "react-chartjs-2";
export default class SingleForecastChart extends Component {
  stockPrices = {};

  componentDidMount() {
    //this.getStockInformation()
  }
  //   getStockInformation = async ()=>{
  //     await Axios.get(`${ENDPOINT}/mobile/forecast/stock`,{
          // params: {
          //   stock: this.props.symbol
          // },
          // headers:{
          //   Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
          //   'Content-Type': 'application/json'
          // },
        // })
        // .then(response => console.log(response))
        // .catch(err=>console.log(`catching errors`, err))
  // }
  forecastChange = (price, forecast) => {
    return (
      (
        ((parseFloat(forecast) - parseFloat(price)) / parseFloat(price)) *
        100
      ).toFixed(2) + "%"
    );
  };
  render() {
    return (
      <>
        <div className="table-body">
          <p
            className="bold-text"
            style={{
              textAlign: "center",
              marginRight: "22px",
              fontSize: "30px",
            }}
          >
            Forecast
          </p>
          <div className="robust-data-analysis">
            <div className="data-container">
              <div className="analysis-row header" style={{ padding: "5px 0" }}>
                <p className="bold-text" style={{ fontSize: "20px" }}>
                  Forecast
                </p>
                <p className="bold-text" style={{ fontSize: "20px" }}>
                  Price
                </p>
                <p className="bold-text" style={{ fontSize: "20px" }}>
                  % Change
                </p>
              </div>
              <div className="analysis-row" style={{ padding: "10px 0" }}>
                <p style={{ fontSize: "20px" }}>3-month</p>
                <p style={{ fontSize: "20px" }}>
                  {this.props.sourceData.forecast_3m}
                </p>
                <div style={{ flex: "1" }}>
                  <p
                    style={{ fontSize: "20px", width: "auto", float: "right" }}
                    className="primary-color-text highlight"
                  >
                    {this.forecastChange(
                      this.props.sourceData.priceUSD,
                      this.props.sourceData.forecast_3m
                    )}
                  </p>
                </div>
              </div>
              <div className="analysis-row" style={{ padding: "10px 0" }}>
                <p style={{ fontSize: "20px" }}>6-month</p>
                <p style={{ fontSize: "20px" }}>
                  {this.props.sourceData.forecast_6m === "nan"
                    ? "-"
                    : this.props.sourceData.forecast_6m}
                </p>
                <div style={{ flex: 1 }}>
                  <p
                    style={{ fontSize: "20px", width: "auto", float: "right" }}
                    className="primary-color-text highlight"
                  >
                    {this.props.sourceData.forecast_6m === "nan"
                      ? "-"
                      : this.forecastChange(
                          this.props.sourceData.priceUSD,
                          this.props.sourceData.forecast_6m
                        )}
                  </p>
                </div>
              </div>
              <div
                style={{ fontSize: "20px", padding: "10px 0" }}
                className="analysis-row"
              >
                <p style={{ fontSize: "20px" }}>1-year</p>
                <p style={{ fontSize: "20px", width: "auto", float: "right" }}>
                  {this.props.sourceData.forecast_1y === "nan"
                    ? "-"
                    : this.props.sourceData.forecast_1y}
                </p>
                <div style={{ flex: "1" }}>
                  <p
                    style={{ fontSize: "20px", width: "auto", float: "right" }}
                    className="primary-color-text highlight"
                  >
                    {this.props.sourceData.forecast_1y === "nan"
                      ? "-"
                      : this.forecastChange(
                          this.props.sourceData.priceUSD,
                          this.props.sourceData.forecast_1y
                        )}
                  </p>
                </div>
              </div>
              <div className="analysis-row" style={{ padding: "10px 0" }}>
                <p style={{ fontSize: "20px" }}>2-year</p>
                <p style={{ fontSize: "20px" }}>
                  {this.props.sourceData.forecast_2y === "nan"
                    ? "-"
                    : this.props.sourceData.forecast_2y}
                </p>
                <div style={{ flex: 1 }}>
                  <p
                    style={{ fontSize: "20px", width: "auto", float: "right" }}
                    className="primary-color-text highlight"
                  >
                    {this.props.sourceData.forecast_2y === "nan"
                      ? "-"
                      : this.forecastChange(
                          this.props.sourceData.priceUSD,
                          this.props.sourceData.forecast_2y
                        )}
                  </p>
                </div>
              </div>
              {/* <div className="graph">
            <Line
              data={{
                labels: [
                  "current",
                  this.props.sourceData.forecast_3m ? "3m" : null,
                  this.props.sourceData.forecast_6m ? "6m" : null,
                  this.props.sourceData.forecast_1y ? "1y" : null,
                  this.props.sourceData.forecast_2y ? "2y" : null,
                ],
                datasets: [
                  {
                    data: [
                      this.props.sourceData.priceUSD,
                      this.props.sourceData.forecast_3m,
                      this.props.sourceData.forecast_6m,
                      this.props.sourceData.forecast_1y,
                      this.props.sourceData.forecast_2y,
                    ],
                    borderColor: "white",
                    backgroundColor: "#716ff6",
                  },
                ],
              }}
              options={graphOptions}
            />
          </div> */}
              {/* <div className="centered-row">
            <div className="highlight">
              {riskNum(this.props.sourceData.maxDrawdownVal, this.props.sourceData.cvar)}%
            </div>
            {riskWord(riskNum(this.props.sourceData.maxDrawdownVal, this.props.sourceData.cvar))}{" "}
            Risk
          </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

//jan 4th 2022
//dec 10th 2022
