import React from 'react'
import { FaArrowUp } from 'react-icons/fa'

export default function NewtInput(props) {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      props.sendPrompt(props.promptInput)
    }
  }

  return (
    <div className='newt-input-container'>
      <div className='input-wrapper'>
        <input
          placeholder='Enter your message here'
          value={props.promptInput} // Added to ensure the input value is controlled
          onChange={(e) => props.setPromptInput(e.target.value)}
          onKeyDown={handleKeyDown} // Added onKeyDown handler
        />
        {/* <button className='small-circular-container'>M</button> */}
        <button
          className='small-circular-container'
          onClick={() => props.sendPrompt(props.promptInput)}
        >
          <FaArrowUp />
        </button>
      </div>
    </div>
  )
}
