import React, { Component } from 'react'
import moment from 'moment'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import '../../scss/components/BalanceHistoryGraph.scss'
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  Area,
  Rectangle,
  ReferenceLine,
} from 'recharts'

// const formatCurrency = (value) => {
//   return this.props.formatCurrnecy(value)
// }

const formatCurrency = (val) => {
  const options = { style: 'currency', currency: 'USD' }
  const numberFormat = new Intl.NumberFormat('en-US', options)
  if (!val) return numberFormat.format(0)
  return numberFormat.format(val)
}

export default class BalanceHistoryGraph extends Component {
  //PROPS
  //seriesData: [{}]
  //xLabel: String
  //yLabel: String

  setHoverData = (e) => {
    this.props.changePortfolioAmount(e.target.y)
  }

  // getConfig() {
  //   const options = { style: "currency", currency: "USD" };
  //   const numberFormat = new Intl.NumberFormat("en-US", options);
  //   const formatCurrency = this.props.formatCurrency;
  //   const value = formatCurrency(this.props.portfolioAmount);
  //   const config = {
  //     credits: false,
  //     chart: {
  //       zoomType: "x",
  //       backgroundColor: "transparent",
  //     },
  //     rangeSelector: {
  //       enabled: false,
  //     },
  //     legend: {
  //       enabled: false,
  //     },
  //     yAxis: {
  //       visible: false,
  //       labels: {
  //         format: "${value}",
  //       },
  //       title: false,
  //       gridLineColor: "transparent",
  //       opposite: false,
  //       max: this.props.max,
  //       min: this.props.min,
  //     },

  //     tooltip: {
  //       formatter(e) {
  //         const { x, y } = this;
  //         return `${moment.unix(this.x / 1000).format("MMM DD")}  ($${this.y})`;
  //       },
  //     },
  //     credits: {
  //       enabled: false,
  //     },
  //     title: undefined,
  //     series: [
  //       {
  //         marker: {
  //           enabled: false,
  //         },
  //         color: "#3525e4",
  //         data: this.props.seriesData,
  //         tooltip: {
  //           valueDecimals: 2,
  //         },
  //         type: "area",
  //         fillColor: {
  //           linearGradient: [0, 0, 0, 200],
  //           stops: [
  //             [0, "rgba(53,37,228,.8)"],
  //             [1, "rgba(53,37,228,0)"],
  //           ],
  //         },
  //       },
  //     ],

  //     xAxis: {
  //       tickLength: 0,
  //       visible: false,
  //       type: "datetime",
  //       labels: {
  //         formatter: function () {
  //           return moment.unix(this.value / 1000).format("MMM DD");
  //         },
  //       },
  //     },
  //     plotOptions: {
  //       series: {
  //       },
  //     },
  //   };
  //   return config;
  // }
  CustomTooltip({ payload, label, active }) {
    // console.log('label', label)
    if (active && payload && label) {
      return (
        <div className='custom-tooltip'>
          <p className='value'>{formatCurrency(payload[0].value)}</p>
          <p className='label'>{`${label}`}</p>
        </div>
      )
    }

    return null
  }

  drawIndicators = (item) => {
    if (item) {
      const [label, value] = item
      const color = value >= this.props.price ? '#3f46f6' : 'red'
      return (
        <ReferenceLine
          y={value}
          label={`${label} (${formatCurrency(value)})`}
          stroke={color}
          strokeDasharray='3 3'
        />
      )
    }
  }

  render() {
    // console.log('this.props.seriesData', this.props.seriesData)
    if (this.props.t && this.props.seriesData) {
      return (
        <ResponsiveContainer width={'100%'} height={'100%'}>
          <AreaChart
            data={this.props.seriesData}
            margin={{ left: 0, right: 0, top: 15 }}
            padding={{ left: 0, right: 0 }}
          >
            <defs>
              <linearGradient id='colorUv' x1='0.4' y1='0' x2='0.6' y2='1'>
                <stop offset='0%' stopColor={'#3F46F6'} />
                <stop offset='100%' stopColor={'rgba(63,70,246,0)'} />
              </linearGradient>
            </defs>
            <XAxis
              dataKey={this.props.xLabel}
              axisLine={false}
              tick={false}
              // type='number'
              // domain={[
              //   Math.floor(startOfDay.getTime() / 1000),
              //   Math.floor(endOfDay.getTime() / 1000),
              // ]}
            />
            <YAxis
              width={0}
              axisLine={false}
              tick={false}
              domain={[undefined, undefined]}
            />
            <Tooltip
              content={<this.CustomTooltip />}
              // wrapperStyle={{ width: '80%', backgroundColor: 'transparent' }}
              // cursor={<this.CustomCursor />}
            />
            <Area
              type='linear'
              dataKey={this.props.yLabel}
              stroke='#3F46F6'
              fillOpacity={1}
              fill='url(#colorUv)'
            />
            {/* {this.props.indicators &&
              this.props.price &&
              this.props.indicators.map(([label, value], index) => {
                return this.drawIndicators(label, value)
              })} */}
            {this.props.indicators && this.props.price && (
              <>
                {this.props.indicators?.length > 0 &&
                  this.drawIndicators(this.props.indicators[0])}
                {this.props.indicators?.length > 1 &&
                  this.drawIndicators(this.props.indicators[1])}
                {this.props.indicators?.length > 2 &&
                  this.drawIndicators(this.props.indicators[2])}
                {this.props.indicators?.length > 3 &&
                  this.drawIndicators(this.props.indicators[3])}
                {this.props.indicators?.length > 4 &&
                  this.drawIndicators(this.props.indicators[4])}
                {this.props.indicators?.length > 5 &&
                  this.drawIndicators(this.props.indicators[5])}
              </>
            )}
          </AreaChart>
        </ResponsiveContainer>
      )
    } else return null
  }
}
