import React from 'react'
import { Container, Card, Image } from 'react-bootstrap'
import StripeCheckout from 'react-stripe-checkout'

import ENDPOINT from './Endpoint'

import Bank from '../assets/submodal/bank.svg'
import Hands from '../assets/submodal/hands.svg'
import Peace from '../assets/submodal/peace.svg'
import Improve from '../assets/submodal/improve.svg'
import Protection from '../assets/submodal/protection.svg'

import BudgetingSub from '../assets/submodal/budgeting-sub.png'
import AiTraderSub from '../assets/submodal/ai-trader-stock-sub.png'
import GoalsSub from '../assets/submodal/goals-sub.png'

import SubscriptionCard from './SubscriptionCard'
import RedesignedLogo from '../assets/redesigned-logo.png'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { unsubscribe } from '../services/userServices'
import Loader from './components/accountInfo/Loader'

const brandColor = '#00A99D'

export default class SubModal extends React.Component {
  state = {
    shouldShowPaymentForm: true,
    subSuccessful: false,
    isSmallScreen: false,
    subStatus: this.props.subStatus,
    errorMsg: '',
    successMsg: '',
    showSuccessDialog: false,
    isLoading: false,
    showDowngradeDialog: false,
  }

  subscribe = () => {
    const { shouldShowPaymentForm } = this.state
    this.setState({ shouldShowPaymentForm: !shouldShowPaymentForm })
  }

  componentDidMount = () => {
    // this._sendUpdateSubscribeToBackend()
  }

  _upgradeSubscription = (upgradeTrueDowngradeFalse) => {
    const body = {
      productType: upgradeTrueDowngradeFalse
        ? 'ar_wealth_management'
        : 'ar_generic',
    }
    let URL = `${ENDPOINT}/stripe/subscription/activate`

    fetch(URL, {
      method: 'POST',
      'Access-Control-Allow-Headers': {
        'Access-Control-Request-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        'Access-Control-Allow-Credentials': true,
      },
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        //     'Content-Type': 'application/json',
        //     'Cache-Control': 'no-cache',
        //     'Access-Control-Allow-Origin': "*",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json()
      })
      .then((responseJson) => {
        var succeed = responseJson.status
        if (succeed) {
          this.setState({ subSuccessful: true })
        } else {
          alert(
            "We either don't have your card on file or your card on file has expired, please add a new credit card"
          )
          this.setState({ subStatus: false })
        }
      })
      .catch((err) => {
        alert(
          'Something went wrong, please double check your card info or try a different card.'
        )
      })
  }

  _sendUpdateSubscribeToBackend = async (token, upgrade) => {
    // const{email} = this.props.userEmail
    // console.log(token.id)
    this.setState({ isLoading: true })
    const body = {
      // sourceId: token.id,
      // email,
      // origin:'web'
      // phone: phoneNumber
      sourceId: token.id,
      productType: 'pro',
    }
    // console.log(this.state.userToken)
    fetch(`${ENDPOINT}/stripe/customer-sources`, {
      method: 'POST',
      'Access-Control-Allow-Headers': {
        'Access-Control-Request-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        'Access-Control-Allow-Credentials': true,
      },
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        //     'Content-Type': 'application/json',
        //     'Cache-Control': 'no-cache',
        //     'Access-Control-Allow-Origin': "*",
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        return response.json()
      })
      .then((responseJson) => {
        var succeed = responseJson.status
        if (succeed) {
          // this.props.updatePaymentStatus()
          // this.setState({ subSuccessful: true })
          this.setState({ showSuccessDialog: true })
        } else {
          // alert('Something went wrong, please double check your card info or try a different card.')
        }
        this.setState({ isLoading: false })
      })
      .catch((err) => {
        this.setState({ isLoading: false })
      })
  } // end _sendSubscribeToBackend

  checkoutButtonUpgrade = (basic) => {
    return (
      <div
        style={{
          width: '30%',
          backgroundColor: 'rgb(110,188,186)',
          borderRadius: 10,
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 5,
          marginBottom: basic ? 50 : 0,
          cursor: 'pointer',
        }}
      >
        <div onClick={() => this._upgradeSubscription(true)}>
          <p style={{ color: 'rgb(255,255,255)', margin: 0 }}>Upgrade</p>
        </div>
      </div>
    )
  }

  checkoutButtonStripe() {
    return (
      <div
        style={{
          width: '100%',
          borderRadius: 10,
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 15,
          marginBottom: 0,
          cursor: 'pointer',
          background: 'rgb(63, 70, 246)',
        }}
      >
        <StripeCheckout
          name='ForeSCITE' // the pop-in header title
          description={'Subscription'} // the pop-in header subtitle
          // image={require('../assets/newUI/ForesciteNoText.png')} // the pop-in header image (default none)
          image={RedesignedLogo}
          ComponentClass='div'
          label='Confirm Purchase' // text inside the Stripe button
          panelLabel='Subscribe' // prepended to the amount in the bottom pay button
          // amount={basic ? 9.99 * 100 : 29.99 * 100 } // cents
          amount={3.99 * 100} // cents
          email={this.props.userEmail}
          // amount={ 0.01 * 100 } // cents
          currency='USD'
          stripeKey='pk_live_Z5TBzWXmsRTQXelPUooM7GhI'
          // stripeKey={'pk_test_7gJdr9OXIGK3QCgELwzeE3dd'}
          // locale='us'
          locale='auto'
          // Note: Enabling either address option will give the user the ability to
          // fill out both. Addresses are sent as a second parameter in the token callback.
          shippingAddress={false}
          billingAddress={false}
          // Note: enabling both zipCode checks and billing or shipping address will
          // cause zipCheck to be pulled from billing address (set to shipping if none provided).
          zipCode={false}
          alipay={false} // accept Alipay (default false)
          bitcoin={false} // accept Bitcoins (default false)
          allowRememberMe={false} // "Remember Me" option (default true)
          token={(token) => {
            this._sendUpdateSubscribeToBackend(token)
          }} // submit callback
          opened={this.onOpened} // called when the checkout popin is opened (no IE6/7)
          closed={this.onClosed} // called when the checkout popin is closed (no IE6/7)
          // Note: `reconfigureOnUpdate` should be set to true IFF, for some reason
          // you are using multiple stripe keys
          reconfigureOnUpdate={false}
          // Note: you can change the event to `onTouchTap`, `onClick`, `onTouchStart`
          // useful if you're using React-Tap-Event-Plugin
          triggerEvent='onClick'
        >
          <div>
            <p
              style={{
                // color: 'rgb(255,255,255)',
                color: 'white',
                fontFamily: 'Akkurat-Bold',
                margin: 0,
                fontSize: 25,
              }}
            >
              {!this.props.freeTrialUsed
                ? this.props.t('start1monthsfreetrial')
                : this.props.t('upgradetoinvestallpro')}
            </p>
          </div>
        </StripeCheckout>
      </div>
    )
  }

  buildSubscriptionCard = () => {
    const { shouldShowPaymentForm, subSuccessful, isSmallScreen } = this.state
    // if(stage > 4){
    return (
      <Card
        className='registration-card'
        style={{ marginTop: 50, padding: 20 }}
      >
        <div
          style={{
            marginLeft: 20,
            marginRight: 20,
            // display: "flex",
            // justifyContent: "center",
            alignItems: 'center',
            padding: 20,
            width: isSmallScreen ? '100%' : '75%',
          }}
        >
          <p
            style={{
              marginTop: 8,
              fontWeight: '600',
              textAlign: 'left',
              fontSize: isSmallScreen ? 16 : 20,
            }}
          >
            Subscribe to ForeSCITE:
          </p>
          <h5 style={{ color: '#00A99D' }}>
            Enjoy UNLIMITED ForeSCITE access FOR LIFE and get COMMISSION-FREE
            Planner Securities trades.
          </h5>
          <h2
            style={{
              color: '#00A99D',
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          >
            For only $5.99 per month.
          </h2>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              flexDirection: 'row',
            }}
          >
            {subSuccessful ? (
              <h5 style={{ color: '#00A99D', marginTop: 50 }}>
                (You have successfully subscribed.)
              </h5>
            ) : shouldShowPaymentForm ? (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <button
                  style={{
                    color: '#00A99D',
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 5,
                    paddingBottom: 5,
                    marginRight: 20,
                  }}
                  onClick={this.subscribe}
                >
                  Cancel
                </button>
                <StripeCheckout
                  stripeKey={'pk_live_Z5TBzWXmsRTQXelPUooM7GhI'}
                  // stripeKey={'pk_test_7gJdr9OXIGK3QCgELwzeE3dd'}
                  token={(token) => {
                    this._sendUpdateSubscribeToBackend(token)
                  }}
                  billingAddress
                  amount={9.99 * 100}
                  name={'Monthly Subscription'}
                />
              </div>
            ) : (
              <button
                style={{
                  backgroundColor: 'rgb(110,188,186)',
                  color: 'rgb(255,255,255)',
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
                onClick={this.subscribe}
              >
                Subscribe Now
              </button>
            )}
          </div>
        </div>
      </Card>
    )
  }

  showUpgradeVsshowSubscribe = () => {
    // console.log('substatus: ',this.props.subStatus)
    if (this.state.subStatus === 'subscribed') {
      return true
    } else {
      return false
    }
  }

  basicSub = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          border: 'solid',
          borderWeight: 0.25,
          borderColor: 'rgb(200,200,200)',
          paddingLeft: '5%',
          paddingRight: '5%',
          paddingTop: '2%',
          paddingBottom: '2%',
          borderRadius: 10,
          width: this.checkBasicSub() ? '80%' : '100%',
          height: '100%',
        }}
      >
        <div
          style={{
            border: 'solid',
            borderWeight: 0.25,
            borderColor: 'rgb(200,200,200)',
            borderRadius: 10,
            padding: '1%',
            backgroundColor: 'rgb(110,188,186)',
            marginBottom: 20,
          }}
        >
          <div style={{ marginBottom: 10, padding: 10 }}>
            <p
              style={{
                color: 'rgb(255,255,255)',
                fontSize: 20,
                fontWeight: 'bold',
              }}
            >
              AllocateRite Basic
            </p>
            <p
              style={{
                color: 'rgb(255,255,255)',
                fontSize: 18,
                fontWeight: 'bold',
              }}
            >
              1 month free trial*
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '95%',
              margin: 'auto',
            }}
          >
            <p style={{ margin: 0 }}>
              then <strong>$9.99 per month</strong>
            </p>
            <p
              style={{
                fontSize: 'smaller',
                margin: 0,
                color: 'rgb(255,255,255)',
              }}
            >
              Cancel anytime
            </p>
          </div>
        </div>
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '50%',
              alignItems: 'center',
            }}
          >
            <img src={Bank} style={{ width: 40, marginRight: 15 }} />
            <p style={{ margin: 0 }}>Multiple Accounts</p>
          </div>
          <div style={{ marginBottom: 10 }}>
            <p style={{ color: 'rgb(120,120,120)', margin: 0 }}>
              Link Mutiple Accounts and Get Access to Professional Risk Analysis
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '55%',
              alignItems: 'center',
            }}
          >
            <img src={Protection} style={{ width: 40, marginRight: 15 }} />
            <p style={{ margin: 0 }}>Price Trend Prediction</p>
          </div>
          <div>
            <p style={{ color: 'rgb(120,120,120)', margin: 0 }}>
              Get access to price trend predictions of thousands of stocks
              worldwide
            </p>
          </div>

          <div style={{ marginTop: 10 }}>
            <p style={{ color: 'rgb(120,120,120)', margin: 0 }}>
              *Trial begins immediately after you create an account with
              AllocateRite. You will be only changed if you subscribe.
            </p>
          </div>
          <div style={{ marginTop: 20, marginBottom: 125 }}>
            {/* {this.showUpgradeVsshowSubscribe() ? this.checkoutButtonUpgrade(true):this.checkoutButtonStripe(true) } */}
            {this.showUpgradeVsshowSubscribe()
              ? this.checkoutButtonUpgrade(true)
              : this.checkoutButtonStripe(true)}
          </div>
        </div>
      </div>
    )
  }

  checkBasicSub = () => {
    return (
      this.props.subLevel === 'arapp_monthly' ||
      this.props.subLevel === 'prod_FaN0loj0oRP7de'
    )
  }

  wmSub = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          border: this.props.modal ? 'none' : 'solid',
          borderWeight: 0.25,
          borderColor: 'rgb(200,200,200)',
          paddingLeft: '5%',
          paddingRight: '5%',
          paddingTop: '2%',
          paddingBottom: '2%',
          borderRadius: 10,
          width: this.checkBasicSub() ? '80%' : '100%',
          margin: 'auto',
        }}
      >
        <div
          style={{
            borderRadius: 10,
            padding: 25,
            backgroundColor: '#3F46F6',
            marginBottom: 20,
          }}
        >
          <p
            style={{
              color: 'rgb(255,255,255)',
              fontSize: 30,
              textAlign: 'center',
              fontFamily: 'Akkurat-Bold',
            }}
          >
            {this.props.t('investall_pro')}
          </p>
          <p
            style={{
              fontFamily: 'Akkurat LL',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 15,
              textAlign: 'center',
              letterSpacing: '0.01em',
              color: '#DADCFF',
            }}
          >
            {this.props.t('sub_message')}
          </p>
          {/* <div style={{ marginBottom: 10, padding: 10 }}>
            <p
              style={{
                color: 'rgb(255,255,255)',
                fontSize: 30,
                textAlign: 'center',
                fontFamily: 'Akkurat-Bold',
              }}
            >
              {this.props.t('investall_pro')}
            </p>
            <p
              style={{
                color: 'rgb(255,255,255)',
                fontSize: 15,
                fontFamily: 'Akkurat-Bold',
                margin: 0,
              }}
            >
              {this.props.t('includesallfeaturesofinvestallbasicandmore')}
            </p>
          </div> */}
          {/* <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '95%',
              margin: 'auto',
              position: 'relative',
            }}
          >
            <div>
              {!this.props.freeTrialUsed && (
                <p
                  style={{
                    margin: 0,
                    fontWeight: 'Akkurat-Light',
                    color: 'white',
                    fontSize: '12px',
                  }}
                >
                  {this.props.t('freetrial')}
                </p>
              )}
              <p
                style={{
                  margin: 0,
                  fontWeight: 'Akkurat-Light',
                  color: 'white',
                  fontSize: '12px',
                }}
              >
                {this.props.t('afterward')}
              </p>
            </div>
            <div style={{ marginTop: 25 }}>{this.checkoutButtonStripe()}</div>
            <p
              style={{
                margin: 0,
                fontWeight: 'Akkurat-Light',
                color: 'white',
                fontSize: '12px',
              }}
            >
              {this.props.t('cancelanytime')}
            </p>
          </div> */}
        </div>
        <p style={{ textAlign: 'center', color: 'red' }}>
          {this.state.errorMsg}
        </p>
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              marginBottom: 15,
            }}
          >
            <img
              src={BudgetingSub}
              style={{
                width: 50,
                height: 45,
                marginRight: 25,
                marginTop: 5,
              }}
            />
            <div>
              <p
                style={{
                  fontSize: 25,
                  fontFamily: 'Akkurat-Bold',
                  textTransform: 'capitalize',
                  marginBottom: 5,
                }}
              >
                {this.props.t('budgeting')}
              </p>
              <p style={{ fontSize: 15 }}>
                {this.props.t('budgeting_subs_info')}
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              marginBottom: 15,
            }}
          >
            <img
              src={AiTraderSub}
              style={{ width: 50, height: 45, marginRight: 25, marginTop: 5 }}
            />
            <div>
              <p
                style={{
                  fontSize: 25,
                  fontFamily: 'Akkurat-Bold',
                  textTransform: 'capitalize',
                  marginBottom: 5,
                }}
              >
                {this.props.t('ai_trader_stock_n_crypto')}
              </p>
              <p style={{ fontSize: 15 }}>
                {this.props.t('ai_trader_stock_n_crypto_sub_info')}
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              marginBottom: 15,
            }}
          >
            <img
              src={GoalsSub}
              style={{ width: 50, height: 45, marginRight: 25, marginTop: 5 }}
            />
            <div>
              <p
                style={{
                  fontSize: 25,
                  fontFamily: 'Akkurat-Bold',
                  textTransform: 'capitalize',
                  marginBottom: 5,
                }}
              >
                {this.props.t('Goals')}
              </p>
              <p style={{ fontSize: 15 }}>{this.props.t('goals_sub_info')}</p>
            </div>
          </div>
          {/* <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '60%',
              alignItems: 'center',
            }}
          >
            <img src={Protection} style={{ width: 40, marginRight: 15 }} />
            <p style={{ margin: 0 }}>{this.props.t('investingmaderight')}</p>
          </div>
          <div style={{ marginBottom: 10, margin: '15px 0' }}>
            <p style={{ color: 'black', margin: 0 }}>
              {this.props.t('investinoursignaturerobo')}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '55%',
              alignItems: 'center',
            }}
          >
            <img src={Hands} style={{ width: 40, marginRight: 15 }} />
            <p style={{ margin: 0 }}>{this.props.t('convenience')}</p>
          </div>
          <div style={{ margin: '15px 0' }}>
            <p style={{ color: 'black', margin: 0 }}>
              {this.props.t('letouraidaytradeforyou')}
            </p>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '55%',
              alignItems: 'center',
            }}
          >
            <img src={Improve} style={{ width: 40, marginRight: 15 }} />
            <p style={{ margin: 0 }}>{this.props.t('improveyourspending')}</p>
          </div>
          <div style={{ margin: '15px 0 ' }}>
            <p style={{ color: 'black', margin: 0 }}>
              {this.props.t('letourbudgetingsoftware')}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '55%',
              alignItems: 'center',
            }}
          >
            <img src={Peace} style={{ width: 40, marginRight: 15 }} />
            <p style={{ margin: 0 }}>{this.props.t('peaceofmind')}</p>
          </div>
          <div style={{ margin: '15px 0' }}>
            <p style={{ color: 'black', margin: 0 }}>
              {this.props.t('prepareforyourfuture')}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '55%',
              alignItems: 'center',
            }}
          >
            <img src={Protection} style={{ width: 40, marginRight: 15 }} />
            <p style={{ margin: 0 }}>{this.props.t('financialsecurity')}</p>
          </div>
          <div style={{ margin: '15px 0' }}>
            <p style={{ color: 'black', margin: 0 }}>
              {this.props.t('getthestatstobackup')}
            </p>
          </div>
          <div
            style={{
              textAlign: 'right',
              marginTop: '15px',
              color: 'rgba(128,128,128,.5)',
              fontSize: '15px',
            }}
          >
            <p>{this.props.t('trialmessage')}</p>
            <p style={{ margin: 0 }}>{this.props.t('trialmessage2')}</p>
          </div> */}
        </div>
        <div style={{ marginTop: 25 }}>{this.checkoutButtonStripe()}</div>
        {!this.props.freeTrialUsed && (
          <p style={{ fontSize: 12, marginTop: 25 }}>
            {this.props.t('free_trial_msg')}
          </p>
        )}
      </div>
    )
  }

  setTopText = () => {
    // if(this.checkBasicSub()){
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p style={{ fontSize: 22 }}>
          To access this feature you need a Pro subscription
        </p>
      </div>
    )
    // }
    // else{
    //     return(
    //         <div style={{display:'flex', flexDirection:'column', justifyContent:'center',alignItems:'center'}}>
    //             <p style={{fontSize:22,margin:'auto' }}>Subscribe to ForeSCITE</p>
    //             {/* <p style={{fontSize:22, }}>Pick the right plan for you, upgrade or downgrade at any time</p> */}
    //         </div>
    //     )
    // }
  }

  multipleSubOptions = () => {
    return (
      <div
        style={{
          width: this.props.modal ? '100%' : '60%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: 'auto',
        }}
      >
        {this.props.modal ? null : this.setTopText()}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          {/* <div style={{width: this.checkBasicSub()? '0%':'100%'}}>
                        {this.checkBasicSub() ? null :this.basicSub()}
                    </div> */}
          <div style={{ width: '100%', marginLeft: 5 }}>{this.wmSub()}</div>
        </div>
      </div>
    )
  }

  downgradeSub = async () => {
    if (this.props.appleStatus) {
      this.setState({
        errorMsg:
          'Please use your Apple device to manage your subscription under settings',
      })
      return false
    } else {
      // let status = await this.props.downgradeSub()
      this.setState({ isLoading: true })
      let status = await unsubscribe(this.props.user?.idToken?.jwtToken)
      if (status) {
        this.setState({ showDowngradeDialog: true })
      } else {
        this.setState({ errorMsg: this.props.t('somethingwentwrong') })
      }
      this.setState({ isLoading: false })
      return status
    }
  }
  render() {
    // console.log('props', this.props)
    if (this.props.t) {
      if (this.state.isLoading) {
        return <Loader logoLoader={false} />
      } else {
        return (
          <div>
            {/* {this.buildSubscriptionCard()} */}
            {this.props.subStatus ? (
              <>
                <p style={{ textAlign: 'center', color: 'red' }}>
                  {this.state.errorMsg}
                </p>
                <SubscriptionCard
                  subStatusMessage={this.props.subStatusMessage}
                  subStatusExpirationDate={this.props.subStatusExpirationDate}
                  downgradeSub={this.downgradeSub}
                  getSubStatus={this.props.getSubStatus}
                  isSmallScreen={this.state.isSmallScreen}
                  t={this.props.t}
                />
              </>
            ) : (
              this.multipleSubOptions()
            )}
            <ConfirmDialog
              visible={this.state.showSuccessDialog}
              onHide={() => window.location.reload(false)}
              message={this.props.t(
                'youhavebeensuccessfullysubscribedtoinvestallpro'
              )}
              header={this.props.t('successfullysubscribed')}
              style={{ width: this.props.isSmallScreen ? '80vw' : '30vw' }}
              rejectClassName='rejectButton'
              acceptLabel='OK'
            />
            <ConfirmDialog
              visible={this.state.showDowngradeDialog}
              onHide={() => window.location.reload(false)}
              message={this.props.t('you_have_successfully_unsubscribed')}
              header={this.props.t('successfully_unsubscribed')}
              style={{ width: this.props.isSmallScreen ? '80vw' : '30vw' }}
              rejectClassName='rejectButton'
              acceptLabel='OK'
            />
          </div>
        )
      }
    } else return null
  }
}
