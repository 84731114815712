import React, { useEffect, useRef } from 'react'
import NewtResponse from './messageTypes/NewtResponse'
import UserPrompt from './messageTypes/UserPrompt'
import NewtInput from './NewtInput'
import NewtIcon from '../../../assets/newt/newt_black.png'

function NewtMessages(props) {
  const chatRef = useRef(null)

  const scrollToRecent = () => {
    if (chatRef.current) {
      chatRef.current.scrollIntoView({
        // block: "end",
        // behavior: "smooth",
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    }
  }

  useEffect(() => {
    console.log('-=-=-=-=-=- Results Init -=-=-=-=-=-=-')
    scrollToRecent()
  }, [])

  useEffect(() => {
    console.log('-=-=-=-=-=- NEW MESSAGE Init -=-=-=-=-=-=-')

    setTimeout(() => {
      scrollToRecent()
    }, 100)

    console.log('props?.messages', props?.messages)
  }, [props?.messages, props?.currentProcessThreads])

  // Find the index of the last 'prompt' message
  const lastPromptIndex = props.messages?.reduce(
    (lastIndex, message, index) =>
      message.message_type === 'prompt' ? index : lastIndex,
    -1
  )

  const renderMessage = (message, i) => {
    switch (message.message_type) {
      case 'prompt':
        return (
          <UserPrompt
            key={i}
            message={message}
            promptProcessing={props.promptProcessing}
            loadingResMsg={props.loadingResMsg}
            isLast={i === lastPromptIndex} // Pass isLast as true for the last 'prompt' message
          />
        )
      case 'newt':
        return (
          <NewtResponse
            key={i}
            message={message}
            sendPrompt={props.sendPrompt}
            setPromptInput={props.setPromptInput}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className='newt-messages-container'>
      {props.messages?.map((message, i) => renderMessage(message, i))}
      <div className='skeleton-container'>
        {/* add the # skeleton that is currently process and not complete yet */}
        {props?.currentProcessThreads &&
          Object?.entries(props?.currentProcessThreads)
            ?.filter(([key, value]) => value.handled === false)
            ?.map(([key, value], index) => {
              return (
                <div key={index} className='skeleton-card'>
                  <div className='skeleton-content'>
                    <div className='avatar-container'>
                      <img src={NewtIcon} alt='' className='avatar-image' />
                    </div>
                    <div className='text-container'>
                      <div className='loading-animation'>
                        <div className='short-line'></div>
                        <div>
                          <div className='long-line'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
      </div>
      <div
        ref={chatRef}
        className={`chat-container ${
          props?.currentProcessThreads ? 'hidden' : 'active'
        }`}
      ></div>
      <div className='newt-floating-input-container'>
        <NewtInput
          sendPrompt={props.sendPrompt}
          setPromptInput={props.setPromptInput}
          promptInput={props.promptInput}
        />
      </div>
    </div>
  )
}

export default React.memo(NewtMessages)
