import React from 'react'
import ForeSCITELogo from "../../assets/newUI/ForeSCITE_Final_Logo_Dark.png"

export default class Links extends React.Component{
    render(){
        return(
            <div style={{width:'100%',}}>
                <div style={{maxWidth:500, display:"flex",flexDirection:'column', justifyContent:'center', margin:'auto'}}>
                    <div>
                        <img
                            src={ForeSCITELogo}
                            style={{width:'90%'}}
                        />
                    </div>
                    <a href='https://apps.apple.com/us/app/forescite/id1567589436'>
                        <div style={{width:'90%',margin:'auto', backgroundColor:'rgb(200,220,220)', marginBottom:20, padding:20, borderRadius:10}}>
                            <p style={{textDecoration:'none', margin:0, textAlign:'center', color:'rgb(0,0,0)', fontSize:24}}>App Store</p>
                        </div>
                    </a>
                    <a href='https://play.google.com/store/apps/details?id=com.forescite'>
                        <div style={{width:'90%',margin:'auto', backgroundColor:'rgb(200,220,220)', marginBottom:20, padding:20, borderRadius:10}}>
                            <p style={{textDecoration:'none', margin:0, textAlign:'center', color:'rgb(0,0,0)', fontSize:24}}>Google Play</p>
                        </div>
                    </a>
                    <a href='https://forescite.ai'>
                        <div style={{width:'90%',margin:'auto', backgroundColor:'rgb(200,220,220)', marginBottom:20, padding:20, borderRadius:10}}>
                            <p style={{textDecoration:'none', margin:0, textAlign:'center', color:'rgb(0,0,0)', fontSize:24 }}>Web</p>
                        </div>
                    </a>
                </div>
            </div>
        )
    }
}