import React from 'react'
import MainNavBar from '../components/MainNavBar'
import '../../scss/pages/Support.scss'
import PublicFooter from '../PublicFooter'

export default function Partnership(props) {
  return (
    <>
      <MainNavBar {...props} colorChange={true} />
      <div className='support-content'>
        <p className='support-header'>{props.t('Category')}</p>
        <div className='support-container'>
          <a
            href='/religious-partners'
            target='_blank'
            className='support-link'
          >
            {props.t('Religious')}
          </a>
          <a
            href='/insurance-partners'
            target='_blank'
            className='support-link'
          >
            {props.t('Insurance')}
          </a>
          <a href='/advisor-partners' target='_blank' className='support-link'>
            {props.t('Advisor')}
          </a>
        </div>
      </div>
      <PublicFooter isSmallScreen={props.isSmallScreen} t={props.t} />
    </>
  )
}
