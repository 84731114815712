//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

//REACT LIBRARIES
import { InputSwitch } from 'primereact/inputswitch'
import { Dialog } from 'primereact/dialog'
import { Accordion, AccordionTab } from 'primereact/accordion'

//CONTEXT
import { BudgetingContext } from './Budgeting'

//APIS
import {
  editAsset,
  fetchAccountInfoLimited,
  unlinkAccount,
  unlinkAsset,
} from '../../services/userServices'
import {
  fetchPropertyData,
  fetchPropertySuggestions,
} from '../../services/generalService'

//STYLES
import '../../scss/components/budgeting/ExternalAccountDetails.scss'

//ICONS
import BackIcon from '../../assets/product-activation-back-icon.svg'
import EditIcon from '../../assets/edit_icon.svg'

//UTILS
import formatCurrency from '../../utility-functions/formatCurrency'
import { formatAddress } from '../../utility-functions/formatAddress'

//COMPONENTS
import ConfirmationDialog from '../components/ConfirmationDialog'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import Loader from '../components/accountInfo/Loader'
import NftCard from '../NftCard'
import AllocationPieChart2 from '../components/AllocationPieChart2'
import Axios from 'axios'
import Endpoint from '../Endpoint'
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts'

export default function ExternalAccountDetails() {
  const {
    t,
    budgetingData,
    selectedAccount,
    setPage,
    user,
    getAllAccountInfo,
    isSmallScreen,
    fetchBudgetingInfo,
  } = useContext(BudgetingContext)

  const details = budgetingData?.payload?.budgeting
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [showAssetUnlinkDialog, setShowAssetUnlinkDialog] = useState(false)
  const [showAssetEditDialog, setShowAssetEditDialog] = useState(false)
  const [itemId, setItemId] = useState(null)
  const [suggestions, setSuggestions] = useState([])
  const [success, setSuccess] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [investmentAccountData, setInvestmentAccountData] = useState(null)
  const [sortedTypesData, setSortedTypesData] = useState(null)
  const [riskData, setRiskData] = useState(null)
  const [totalNumOfHoldings, setTotalNumOfHoldings] = useState(0)
  const [allocationChartData, setAllocationChartData] = useState(null)

  const [homeData, setHomeData] = useState({
    address: '',
    estimated_value: '',
    mortgage_balance: 0,
    nickname: '',
    type: 'home',
  })

  const [vehicleData, setVehicleData] = useState({
    model: '',
    make: '',
    year: '',
    estimated_value: '',
    mortgage_balance: 0,
    nickname: '',
    type: 'auto',
  })

  const [valuableData, setValuableData] = useState({
    model: '',
    make: '',
    year: '',
    estimated_value: '',
    mortgage_balance: 0,
    nickname: '',
    type: 'valuable',
  })

  const [walletData, setWalletData] = useState({
    walletAddress: '',
    nickname: '',
  })

  useEffect(() => {
    if (selectedAccount?.accountDetails) {
      getAccountId()
    } else {
      preFillData()
    }
    console.log(selectedAccount)
  }, [])

  useEffect(() => {
    if (homeData?.address) {
      getPropertySuggestions()
    } else {
      setSuggestions([])
    }
  }, [homeData.address])

  useEffect(() => {
    if (investmentAccountData) {
      // Loop through the holdings to sort all the types of holdings
      let all_types = {}
      let holdings_count = 1
      let chartData = []
      for (let i in investmentAccountData?.holdings) {
        let type = investmentAccountData?.holdings[i]['plaid_type']
        if (type !== 'Cash') {
          if (all_types[type]) {
            all_types[type].push(investmentAccountData?.holdings[i])
          } else {
            all_types[type] = [investmentAccountData?.holdings[i]]
            holdings_count++
          }
        }
      }
      for (let i in investmentAccountData?.allocations) {
        if (i !== 'all') {
          chartData.push({
            name: i,
            value: investmentAccountData?.allocations[i].sum,
          })
        }
      }
      setAllocationChartData(chartData)
      setTotalNumOfHoldings(holdings_count)
      setSortedTypesData(all_types)
    }
  }, [investmentAccountData])

  const preFillData = () => {
    if (selectedAccount?.type === 'home') {
      setHomeData({ ...selectedAccount })
    } else if (selectedAccount?.type === 'auto') {
      const address = selectedAccount?.address.split(',')
      setVehicleData({
        ...selectedAccount,
        model: address?.[1],
        make: address?.[0],
        year: address?.[2],
      })
    } else {
      setValuableData({ ...selectedAccount })
    }
  }

  const getPropertySuggestions = async () => {
    const res = await fetchPropertySuggestions(
      user?.idToken?.jwtToken,
      homeData.address,
      '10'
    )
    setSuggestions(res)
  }

  const saveHomeData = (label, value) => {
    setHomeData((prevState) => ({ ...prevState, [label]: value }))
  }

  const saveVehicleData = (label, value) => {
    setVehicleData((prevState) => ({ ...prevState, [label]: value }))
  }

  const saveValuableData = (label, value) => {
    setValuableData((prevState) => ({ ...prevState, [label]: value }))
  }

  const saveWalletData = (label, value) => {
    setWalletData((prevState) => ({ ...prevState, [label]: value }))
  }

  const suggestionsList = () => {
    return (
      <div className='suggestions'>
        <p
          onClick={() => {
            setSuggestions([])
          }}
          className='suggestions-close'
        >
          x
        </p>
        {suggestions.map((item, index) => {
          return (
            <div
              className='suggestions-row'
              key={index}
              onClick={() => {
                saveHomeData('address', item)
                getPropertyEstimate()
                setSuggestions([])
              }}
            >
              <p className='suggestion-item'>{item}</p>
            </div>
          )
        })}
      </div>
    )
  }

  const getPropertyEstimate = async () => {
    const res = await fetchPropertyData(
      user?.idToken?.jwtToken,
      homeData?.address
    )
    console.log(res)
    saveHomeData('estimated_value', res?.property_forecast?.appraisal)
  }

  const getAccountId = async () => {
    const res = await fetchAccountInfoLimited(
      user?.idToken?.jwtToken,
      selectedAccount?.accountDetails?.accountId
    )
    console.log('account res', res)
    if (res?.payload?.accountInfo?.type === 'investment') {
      setInvestmentAccountData(res?.payload)
      let { data } = await Axios.get(
        Endpoint +
          '/portfolio-risk/risk-monkey/mobile/all?account_id=' +
          selectedAccount?.accountDetails?.accountId,
        {
          headers: {
            Authorization: 'Bearer ' + user?.idToken?.jwtToken,
          },
        }
      )
      console.log('risk data', data)
      setRiskData(data?.data)
    }
    setItemId(res?.payload?.accountInfo?.itemId)
  }

  const removeAccount = async () => {
    const res = await unlinkAccount(user?.idToken?.jwtToken, itemId)
    console.log(res)
    if (res) {
      await getAllAccountInfo()
      await fetchBudgetingInfo()
      setPage((prevState) => {
        const pages = [...prevState]
        pages.pop()
        return pages
      })
      return true
    } else {
      return false
    }
  }

  const removeAsset = async () => {
    const res = await unlinkAsset(user?.idToken?.jwtToken, selectedAccount?.id)
    if (res) {
      await fetchBudgetingInfo()
      setPage((prevState) => {
        const pages = [...prevState]
        pages.pop()
        return pages
      })
      return true
    } else {
      return false
    }
  }

  const validateHomeDetails = () => {
    if (homeData?.address) {
      if (homeData?.estimated_value) {
        if (homeData?.nickname) {
          submitApi()
        } else {
          setError(t('pleasecompleteallfields'))
        }
      } else {
        setError(t('pleasecompleteallfields'))
      }
    } else {
      setError(t('pleasecompleteallfields'))
    }
  }

  const validateVehicleDetails = () => {
    if (vehicleData?.model) {
      if (vehicleData?.make) {
        if (vehicleData?.year) {
          if (vehicleData?.estimated_value) {
            if (vehicleData?.nickname) {
              submitApi()
            } else {
              setError(t('pleasecompleteallfields'))
            }
          } else {
            setError(t('pleasecompleteallfields'))
          }
        } else {
          setError(t('pleasecompleteallfields'))
        }
      } else {
        setError(t('pleasecompleteallfields'))
      }
    } else {
      setError(t('pleasecompleteallfields'))
    }
  }

  const validateValuableDetails = () => {
    if (valuableData?.nickname) {
      if (valuableData?.estimated_value) {
        submitApi()
      } else {
        setError(t('pleasecompleteallfields'))
      }
    } else {
      setError(t('pleasecompleteallfields'))
    }
  }

  const validateWalletForm = () => {
    if (walletData?.walletAddress && walletData?.nickname) {
    } else {
      setError(t('pleasecompleteallfields'))
    }
  }

  const submitApi = async () => {
    setLoading(true)
    const type = selectedAccount?.type
    const data =
      type === 'home' ? homeData : type === 'auto' ? vehicleData : valuableData
    const address =
      type === 'auto'
        ? `${data.make},${data.model},${data.year}`
        : type === 'home'
        ? homeData?.address
        : null
    const res = await editAsset(
      user?.idToken?.jwtToken,
      data.nickname,
      data.mortgage_balance,
      null,
      address,
      data.estimated_value,
      selectedAccount?.id,
      data.type
    )
    if (res) {
      await fetchBudgetingInfo()
      setPage((prevState) => {
        const pages = [...prevState]
        pages.pop()
        return pages
      })
      setLoading(false)
      return true
    } else {
      setLoading(false)
      return false
    }
  }

  const accountDetails = () => {
    return (
      <div>
        <div className='header-row'>
          <p className='account-name'>
            {selectedAccount?.accountDetails?.source}-
            {selectedAccount?.accountDetails?.mask}
          </p>
          <div className='actions-buttons-container'>
            <InputSwitch
              checked={true}
              onChange={(e) => setShowConfirmationDialog(true)}
            />
          </div>
        </div>
        <div className='account-summary'>
          <div className='account-summary-content'>
            <div className='budgeting-row space-bottom'>
              <p className='budgeting-label no-margin'>{t('account_number')}</p>
              <p className='budgeting-value no-margin'>
                ****{selectedAccount?.accountDetails?.mask}
              </p>
            </div>
            <div className='budgeting-row space-bottom'>
              <p className='budgeting-label no-margin'>{t('balance')}</p>
              <p className='budgeting-value no-margin'>
                {formatCurrency(selectedAccount?.amount)}
              </p>
            </div>
            {investmentAccountData?.accountInfo?.type === 'investment' ? (
              <div className='budgeting-row'>
                <p className='budgeting-label no-margin'>Available Cash</p>
                <p className='budgeting-value no-margin'>
                  {formatCurrency(
                    investmentAccountData?.accountInfo?.cash_account
                  )}
                </p>
              </div>
            ) : null}
          </div>
        </div>
        {investmentAccountData?.accountInfo?.type === 'investment' ? (
          <div className='investment-positions-container'>
            <p className='section-title'>Portfolio Allocations</p>
            <div className='allocation-chart'>
              <AllocationPieChart2
                seriesData={allocationChartData}
                t={t}
                COLORS={[
                  '#3627E4',
                  'rgba(123, 97, 255, .61)',
                  '#7B61FF',
                  '#474280',
                ]}
                isSmallScreen={false}
                verticalAlign={true}
              />
            </div>
            <p className='section-title'>Portfolio Risk</p>
            <div className='risk-data-container'>
              <ResponsiveContainer width={'100%'} height={'80%'}>
                <PieChart height={200}>
                  <Pie
                    startAngle={180}
                    endAngle={0}
                    innerRadius={70}
                    outerRadius={115}
                    data={[
                      { value: riskData?.CVar },
                      { value: 1 - riskData?.CVar },
                    ]}
                    dataKey='value'
                    labelLine={false}
                    blendStroke
                    isAnimationActive={false}
                    cy={'70%'}
                  >
                    <Cell fill='#3627E4' />
                    <Cell fill='#eaeaea' />
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
              {/* riskData?.CVar <= 0.15
                          ? 'Low Risk'
                          : riskData?.CVar > 0.15 && riskData?.CVar < 0.25
                          ? 'Medium Risk'
                          : 'High Risk' */}
              <div className='bottom-row'>
                <div className='risk-data'>
                  <p className='larger'>
                    {parseFloat(parseFloat(riskData?.CVar * 100).toFixed(2)) +
                      '%'}
                  </p>
                </div>
                <div className='risk-data'>
                  <p className='larger'>
                    {' $' +
                      0.2 * investmentAccountData?.accountBalance.toFixed(0)}
                  </p>
                </div>
              </div>
              <div className='bottom-row'>
                <div className='risk-data'>
                  <p>Correlation with S&amp;P</p>
                  <p>{riskData?.rSquared?.toFixed(2)}</p>
                </div>
                <div className='risk-data'>
                  <p>Holdings</p>
                  <p>{totalNumOfHoldings}</p>
                </div>
              </div>
            </div>
            <div className='sorted-positions-table-container'>
              {sortedTypesData &&
                Object.keys(sortedTypesData)?.map((type, key) => {
                  return (
                    <div className='type-container' key={key}>
                      <div className='type-title-container'>{type}</div>
                      <div className='type-positions-container'>
                        <div className='row-container header'>
                          <div className='row-element symbol'>Symbol</div>
                          <div className='row-element'>Shares</div>
                          <div className='row-element'>Cost Basis</div>
                          <div className='row-element'>Mkt Value</div>
                          <div className='row-element'>Allocation</div>
                          <div className='row-element'>PnL</div>
                        </div>
                        {sortedTypesData[type]?.map((position, key) => {
                          return (
                            <div className='row-container' key={key}>
                              <div className='row-element symbol'>
                                {position?.symbol}
                              </div>
                              <div className='row-element'>
                                {formatCurrency(position?.quantity)}
                              </div>
                              <div className='row-element'>
                                {formatCurrency(position?.averagePrice)}
                              </div>
                              <div className='row-element'>
                                {formatCurrency(position?.marketValue)}
                              </div>
                              <div className='row-element'>
                                {parseFloat(
                                  position?.allocation * 100
                                )?.toFixed(2)}
                                %
                              </div>
                              <div className='row-element'>
                                {formatCurrency(position?.unrealizedPL)}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        ) : (
          <div className='transactions-table-values'>
            {details?.transactions?.transactionDetails?.map((item, index) => {
              if (
                item.accountId === selectedAccount?.accountDetails?.accountId
              ) {
                return (
                  <div className='transactions-table-value' key={index}>
                    <div className='budgeting-row'>
                      <p className='transactions-row-value blue-text'>
                        {`${item.source}-${item.mask}`}
                      </p>
                      <p className='transactions-row-value'>{item.date}</p>
                    </div>
                    <div className='budgeting-row'>
                      <p className='transactions-row-value light-text'>
                        {item.sub_category}
                      </p>
                      <p className='transactions-row-value'>
                        {formatCurrency(item.amount)}
                      </p>
                    </div>
                    <p className='transactions-row-value no-margin'>
                      {item.name}
                    </p>
                  </div>
                )
              }
            })}
          </div>
        )}
      </div>
    )
  }

  const propertyDetails = () => {
    return (
      <div>
        <div className='header-row'>
          <p className='account-name'>{selectedAccount?.nickname}</p>
          <div className='actions-buttons-container'>
            <img
              src={EditIcon}
              className='edit-icon'
              onClick={() => {
                setShowAssetEditDialog(true)
              }}
            />
            <InputSwitch
              checked={true}
              onChange={(e) => setShowAssetUnlinkDialog(true)}
            />
          </div>
        </div>
        {selectedAccount?.type === 'home' && homeDetails()}
        {selectedAccount?.type === 'auto' && autoDetails()}
        {selectedAccount?.type === 'valuable' && valuableDetails()}
      </div>
    )
  }

  const walletDetails = () => {
    const walletInfo =
      budgetingData?.wallet_data?.[selectedAccount?.address]?.collections
    return (
      <div>
        <div className='header-row'>
          <p className='account-name'>
            {selectedAccount?.nickname
              ? selectedAccount?.nickname
              : 'test wallet'}
          </p>
          {/* <InputSwitch
            checked={true}
            onChange={(e) => setShowAssetUnlinkDialog(true)}
          />
          <img
            src={EditIcon}
            className='edit-icon'
            onClick={() => {
              setShowAssetEditDialog(true)
            }}
          /> */}
        </div>
        <div className='wallet-summary'>
          {walletInfo && Object.entries(walletInfo)?.length > 0 ? (
            <Accordion
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
            >
              {Object.entries(walletInfo).map(([key, value], index) => {
                return (
                  <AccordionTab
                    headerTemplate={headerTemplate(key, value)}
                    key={index}
                    className='accordion-tab'
                  >
                    {renderNfts(key, value)}
                  </AccordionTab>
                )
              })}
            </Accordion>
          ) : (
            <p className='no-nft-msg'>
              {t('this_wallet_does_not_own_any_nfts')}
            </p>
          )}
        </div>
      </div>
    )
  }

  const headerTemplate = (key, value) => {
    return (
      <div className='nft-header'>
        <div className='collection-image-container'>
          <NftCard>
            <NftCard.Image
              url={
                value?.collection_info?.collection_image_name
                  ? value?.collection_info?.collection_image_name
                  : null
              }
              label={value?.collection_info?.collection_name
                ?.charAt(0)
                ?.toUpperCase()}
            />
          </NftCard>
        </div>
        <div className='header-container'>
          <p className='nft-label'>{value?.collection_info?.collection_name}</p>
          <p className='nft-label-small'>
            {isSmallScreen ? formatAddress(key, 6) : key}
          </p>
        </div>
      </div>
    )
  }

  const renderNfts = (key, collectionData) => {
    return (
      <div className='nfts'>
        {collectionData?.tokens?.map((token, index) => {
          return (
            <Link
              className='nft-card-container'
              key={index}
              to={{
                pathname: `https://swopx.com/single-collection/${key}/token/${token?.token_721}`,
                // pathname:
                //   'https://www.swopx.com/single-collection/0xc1caf0c19a8ac28c41fe59ba6c754e4b9bd54de9/token/70',
              }}
              target='_blank'
              rel='noopener noreferrer'
            >
              <NftCard>
                <NftCard.Image
                  url={
                    token?.image_name
                      ? `https://swopx-price-suggestion-images.s3.amazonaws.com/${token?.image_name}.png`
                      : null
                  }
                  label={collectionData?.collection_info?.collection_name
                    ?.charAt(0)
                    ?.toUpperCase()}
                />
                <NftCard.Info
                  name={`#${token?.token_721}`}
                  price={parseFloat(token?.price)}
                />
              </NftCard>
            </Link>
          )
        })}
      </div>
    )
  }

  const homeDetails = () => {
    return (
      <div className='account-summary'>
        <div className='account-summary-content'>
          <div className='budgeting-row space-bottom'>
            <p className='budgeting-label no-margin'>{t('address')}</p>
            <p className='budgeting-value no-margin'>
              {selectedAccount?.address}
            </p>
          </div>
          <div
            className='budgeting-row space-bottom'
            style={{ alignItems: 'flex-start' }}
          >
            <p className='budgeting-label no-margin'>{t('estimated_value')}</p>
            <div className='honely-container'>
              <p className='budgeting-value no-margin'>
                {formatCurrency(selectedAccount?.estimated_value)}
              </p>
              <Link
                className='budgeting-value-sub-text no-margin'
                to={{
                  pathname: `https://honely.com`,
                }}
                target='_blank'
                rel='noopener noreferrer'
              >
                {t('powered_by_honely')}
              </Link>
            </div>
          </div>
          <div className='budgeting-row'>
            <p className='budgeting-label no-margin'>{t('mortgage_balance')}</p>
            <p className='budgeting-value no-margin'>
              {formatCurrency(selectedAccount?.mortgage_balance)}
            </p>
          </div>
        </div>
      </div>
    )
  }

  const autoDetails = () => {
    return (
      <div className='account-summary'>
        <div className='account-summary-content'>
          <div className='budgeting-row space-bottom'>
            <p className='budgeting-label no-margin'></p>
            <p className='budgeting-value no-margin'>{autoName()}</p>
          </div>
          <div className='budgeting-row space-bottom'>
            <p className='budgeting-label no-margin'>{t('estimated_value')}</p>
            <p className='budgeting-value no-margin'>
              {formatCurrency(selectedAccount?.estimated_value)}
            </p>
          </div>
          <div className='budgeting-row'>
            <p className='budgeting-label no-margin'>{t('loan_balance')}</p>
            <p className='budgeting-value no-margin'>
              {formatCurrency(selectedAccount?.mortgage_balance)}
            </p>
          </div>
        </div>
      </div>
    )
  }

  const autoName = () => {
    const name = selectedAccount?.address?.split(',')
    return `${name?.[2]} ${name?.[0]} ${name?.[1]}`
  }

  const valuableDetails = () => {
    return (
      <div className='account-summary'>
        <div className='account-summary-content'>
          <div className='budgeting-row space-bottom'>
            <p className='budgeting-label no-margin'>{t('estimated_value')}</p>
            <p className='budgeting-value no-margin'>
              {formatCurrency(selectedAccount?.estimated_value)}
            </p>
          </div>
          <div className='budgeting-row'>
            <p className='budgeting-label no-margin'>{t('loan_balance')}</p>
            <p className='budgeting-value no-margin'>
              {formatCurrency(selectedAccount?.mortgage_balance)}
            </p>
          </div>
        </div>
      </div>
    )
  }

  const propertyForm = () => {
    return (
      <div className='add-account-forms'>
        <p className='add-account-header'>
          {t('find_your_homes_estimated_value')}
        </p>
        <div className='property-input-container'>
          <p className='input-label small-height'>{t('enter_full_address')}</p>
          <InputText
            value={homeData.address}
            onChange={(e) => saveHomeData(e.target.name, e.target.value)}
            name='address'
            placeholder={t('enter_full_address')}
            className='add-account-input-fixed big-height'
          />
          {suggestions?.length > 0 && suggestionsList()}
        </div>
        <div className='property-input-container'>
          <p className='input-label'>{t('enter_home_estimated_value')}</p>
          <InputNumber
            value={homeData?.estimated_value}
            onValueChange={(e) => saveHomeData(e.target.name, e.target.value)}
            name='estimated_value'
            placeholder={t('enter_home_estimate')}
            className='add-account-input-fixed'
            format={true}
            prefix='$'
          />
        </div>
        <div className='property-input-container'>
          <p className='input-label'>{t('enter_your_mortgage_balance')}</p>
          <InputNumber
            value={homeData?.mortgage_balance}
            onValueChange={(e) => saveHomeData(e.target.name, e.target.value)}
            name='mortgage_balance'
            placeholder={t('enter_mortgage_balance')}
            className='add-account-input-fixed'
            format={true}
            prefix='$'
          />
        </div>
        <div className='property-input-container'>
          <p className='input-label'>{t('give_your_property_a_nickname')}</p>
          <InputText
            value={homeData.nickname}
            onChange={(e) => saveHomeData(e.target.name, e.target.value)}
            name='nickname'
            placeholder={t('enter_nickname')}
            className='add-account-input-fixed'
          />
        </div>
        <button
          className='add-account-form-btn'
          onClick={() => {
            validateHomeDetails()
          }}
        >
          {t('add_property')}
        </button>
      </div>
    )
  }

  const vehicleForm = () => {
    return (
      <div className='add-account-forms'>
        <p className='add-account-header'>{t('enter_your_vehicle_details')}</p>
        <div className='property-input-container'>
          <p className='input-label'>{t('vehicle_model')}</p>
          <InputText
            value={vehicleData?.model}
            onChange={(e) => saveVehicleData(e.target.name, e.target.value)}
            name='model'
            placeholder={t('enter_vehicle_model')}
            className='add-account-input'
          />
        </div>
        <div className='property-input-container'>
          <p className='input-label'>{t('vehicle_make')}</p>
          <InputText
            value={vehicleData?.make}
            onChange={(e) => saveVehicleData(e.target.name, e.target.value)}
            name='make'
            placeholder={t('enter_vehicle_make')}
            className='add-account-input'
          />
        </div>
        <div className='property-input-container'>
          <p className='input-label'>{t('vehicle_year')}</p>
          <InputNumber
            value={vehicleData?.year}
            onValueChange={(e) =>
              saveVehicleData(e.target.name, e.target.value)
            }
            name='year'
            placeholder={t('enter_vehicle_year')}
            className='add-account-input'
            format={false}
          />
        </div>
        <div className='property-input-container'>
          <p className='input-label'>{t('estimated_value')}</p>
          <InputNumber
            value={vehicleData?.estimated_value}
            onValueChange={(e) =>
              saveVehicleData(e.target.name, e.target.value)
            }
            name='estimated_value'
            placeholder={t('enter_vehicle_estimate')}
            className='add-account-input'
            prefix='$'
          />
        </div>
        <div className='property-input-container'>
          <p className='input-label'>{t('loan_balance')}</p>
          <InputNumber
            value={vehicleData?.mortgage_balance}
            onValueChange={(e) =>
              saveVehicleData(e.target.name, e.target.value)
            }
            name='mortgage_balance'
            placeholder={t('enter_vehicle_loan')}
            className='add-account-input'
            prefix='$'
          />
        </div>
        <div className='property-input-container'>
          <p className='input-label'>{t('vehicle_nickname')}</p>
          <InputText
            value={vehicleData?.nickname}
            onChange={(e) => saveVehicleData(e.target.name, e.target.value)}
            name='nickname'
            placeholder={t('enter_vehicle_nickname')}
            className='add-account-input'
          />
        </div>
        <button
          className='add-account-form-btn'
          onClick={() => {
            validateVehicleDetails()
          }}
        >
          {t('add_vehicle')}
        </button>
      </div>
    )
  }

  const valuableForm = () => {
    return (
      <div className='add-account-forms'>
        <p className='add-account-header'>{t('enter_your_valuable_details')}</p>
        <div className='property-input-container'>
          <p className='input-label'>{t('name')}</p>
          <InputText
            value={valuableData?.nickname}
            onChange={(e) => saveValuableData(e.target.name, e.target.value)}
            name='nickname'
            placeholder={t('ex_art_jewelery')}
            className='add-account-input'
          />
        </div>
        <div className='property-input-container'>
          <p className='input-label'>{t('estimated_value')}</p>
          <InputNumber
            value={valuableData?.estimated_value}
            onValueChange={(e) =>
              saveValuableData(e.target.name, e.target.value)
            }
            name='estimated_value'
            placeholder={t('')}
            prefix='$'
            className='add-account-input'
          />
        </div>
        <div className='property-input-container'>
          <p className='input-label'>{t('loan_balance')}</p>
          <InputNumber
            value={valuableData?.mortgage_balance}
            onValueChange={(e) =>
              saveValuableData(e.target.name, e.target.value)
            }
            name='mortgage_balance'
            placeholder={t('')}
            className='add-account-input'
            prefix='$'
          />
        </div>
        <button
          className='add-account-form-btn'
          onClick={() => {
            validateValuableDetails()
          }}
        >
          {t('add_valuable')}
        </button>
      </div>
    )
  }

  const walletForm = () => {
    return (
      <div className='add-account-forms'>
        <p className='add-account-header'>{t('enter_wallet_address')}</p>
        <div className='input-container'>
          <InputText
            value={walletData?.walletAddress}
            onChange={(e) => saveWalletData(e.target.name, e.target.value)}
            name='walletAddress'
            placeholder={t('enter_wallet_address')}
            className='add-account-input'
          />
        </div>
        <div className='input-container'>
          <InputText
            value={walletData?.nickname}
            onChange={(e) => saveWalletData(e.target.name, e.target.value)}
            name='nickname'
            placeholder={t('enter_nickname')}
            className='add-account-input'
          />
        </div>
        <button
          className='add-account-form-btn'
          onClick={() => {
            validateWalletForm()
          }}
        >
          {t('add_wallet')}
        </button>
      </div>
    )
  }

  return (
    <div className='external-account-details'>
      <img
        src={BackIcon}
        className='back-icon'
        onClick={() => {
          setPage((prevState) => {
            const pages = [...prevState]
            pages.pop()
            return pages
          })
        }}
      />
      {selectedAccount?.accountDetails
        ? accountDetails()
        : selectedAccount?.type === 'wallet'
        ? walletDetails()
        : propertyDetails()}

      <ConfirmationDialog
        isSmallScreen={isSmallScreen}
        toggleShowConfirmationDialog={() => {
          setShowConfirmationDialog((prevState) => !prevState)
        }}
        showConfirmationDialog={showConfirmationDialog}
        action={removeAccount}
        name={t('remove_account')}
        successMessage={t('account_removed_successfully')}
        customHeader={t('remove_account')}
        customMessage={t('remove_account_msg')}
        t={t}
      />

      <ConfirmationDialog
        isSmallScreen={isSmallScreen}
        toggleShowConfirmationDialog={() => {
          setShowAssetUnlinkDialog(false)
        }}
        showConfirmationDialog={showAssetUnlinkDialog}
        action={removeAsset}
        name={t('remove_asset')}
        successMessage={t('asset_removed_successfully')}
        customHeader={t('remove_asset')}
        customMessage={t('remove_asset_msg')}
        t={t}
      />

      <Dialog
        visible={showAssetEditDialog}
        header={t('edit_asset')}
        onHide={() => setShowAssetEditDialog(false)}
        style={{
          width: isSmallScreen ? '90vw' : '40vw',
        }}
      >
        {loading ? (
          <Loader logoLoader={false} />
        ) : (
          <>
            {selectedAccount?.type === 'home' && propertyForm()}
            {selectedAccount?.type === 'auto' && vehicleForm()}
            {selectedAccount?.type === 'valuable' && valuableForm()}
            {selectedAccount?.type === 'wallet' && walletForm()}
            {success && <p className='success space-top'>{success}</p>}
            {error && <p className='error space-top'>{error}</p>}
          </>
        )}
      </Dialog>
    </div>
  )
}
