import React from 'react'
import { StickyTable, Row, Cell } from 'react-sticky-table'
import upArrow from '../../assets/single_up.svg'
import downArrow from '../../assets/single_down.svg'
import strongUpArrow from '../../assets/double_up.svg'
import strongDownArrow from '../../assets/double_down.svg'
import flatArrow from '../../assets/flat.svg'
import { NavLink } from 'react-router-dom'

import DoubleNegative from '../../assets/double-negative.svg'
import SingleNegative from '../../assets/single-negative.svg'
import Neutral from '../../assets/neutral.svg'
import SinglePositive from '../../assets/single-positive.svg'
import DoublePositive from '../../assets/double-positive.svg'
import '../../scss/pages/mobileWatchList.scss'
import EditWatchListTablePage from '../pages/EditWatchListTablePage'
import EditIcon from '../../assets/edit_icon.svg'

export default class TickersTable extends React.Component {
  getArrow = (direction) => {
    switch (direction) {
      case -1:
        return SingleNegative
      case 1:
        return SinglePositive
      case 2:
        return DoublePositive
      case -2:
        return DoubleNegative
      default:
        return Neutral
    }
  }

  addCommas(x) {
    return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  createCells = () => {
    console.log(this.props.data)
    return this.props.data?.map((ele) => {
      return (
        <Row
          style={{
            backgroundColor: 'transparent',
            // position: 'inherit !important',
          }}
        >
          <Cell
            style={{
              width: '25%',
              backgroundColor: 'transparent',
              position: 'inherit',
            }}
          >
            <NavLink
              to={`/Dashboard/Trade/${ele.symbol}`}
              style={{
                fontSize: 19,
                color: '#272748',
                backgroundColor: 'transparent',
                borderRadius: 12,
                padding: '10px 20px',
              }}
            >
              {ele.symbol}
            </NavLink>
          </Cell>
          <Cell
            style={{
              width: '35%',
              backgroundColor: 'transparent',
              position: 'inherit',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={this.getArrow(ele.direction)}
                style={{
                  width: 20,
                  height: 20,
                }}
              />
            </div>
          </Cell>
          {/* <Cell style={{width:'25%'}}>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            <img
                                src={this.getArrow(ele.directionPrev)}
                                style={{
                                width: 20,
                                height: 20,
                                }}
                            />
                        </div>
                    </Cell> */}
          <Cell
            style={{
              width: '30%',
              backgroundColor: 'transparent',
              position: 'inherit',
            }}
          >
            ${this.addCommas(ele.priceUSD?.toFixed(2) || '--')}
          </Cell>
          <Cell
            style={{
              // width: '15%',
              backgroundColor: 'transparent',
              position: 'inherit',
            }}
          ></Cell>
        </Row>
      )
    })
  }

  editCells = () => {
    return this.props.data?.map((ele) => {
      return (
        <Row>
          <Cell style={{ width: '25%', backgroundColor: 'transparent' }}>
            <NavLink
              to={`/Dashboard/Trade/${ele.symbol}`}
              style={{
                fontFamily: 'Akkurat-Bold',
                fontSize: 19,
                color: '#272748',
                fontSize: '19px',
                borderRadius: 12,
                padding: '10px 20px',
              }}
            >
              {ele.symbol}
            </NavLink>
          </Cell>
          <Cell
            style={{ width: '75%', padding: 3, backgroundColor: 'transparent' }}
          >
            <div
              style={{
                backgroundColor: '#f6f6f6',
                borderRadius: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => this.props.remove(ele.symbol)}
            >
              <p style={{ color: '#272748', margin: 'auto', fontSize: '19px' }}>
                {`${this.props.t('remove')} ${ele.symbol}`}
              </p>
            </div>
          </Cell>
        </Row>
      )
    })
  }

  render() {
    if (this.props.t) {
      return (
        <>
          {this.props.editMode ? (
            <EditWatchListTablePage
              toggleEditTable={this.props?.toggleEditTable}
              tableData={this.props?.data}
              selectedWatchlistGroup={this.props?.selectedWatchlistGroup}
              user={this.props.user}
              refreshWl={this.props.refreshWl}
            />
          ) : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                // justifyContent: 'center',
                // alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 20,
                }}
              >
                <StickyTable style={{ width: '100%', margin: 0, padding: 0 }}>
                  {/* PICK UP FROM HERE */}
                  <Row>
                    <Cell
                      style={{
                        width: '25%',
                        height: 18,
                        backgroundColor: 'transparent',
                        fontFamily: 'Akkurat-Bold',
                        position: 'inherit ',
                      }}
                    >
                      {this.props.t('symbol')}
                    </Cell>
                    {/* {this.props.editMode ? (
                      <Cell
                        style={{
                          width: '75%',
                          backgroundColor: 'transparent',
                          position: 'inherit ',
                        }}
                      >
                        {this.props.t('remove')}
                      </Cell>
                    ) : null} */}
                    {this.props.editMode ? null : (
                      <Cell
                        style={{
                          width: '35%',
                          height: 18,
                          backgroundColor: 'transparent',
                          position: 'inherit ',
                          textAlign: 'center',
                        }}
                      >
                        {this.props.t('trend')}
                      </Cell>
                    )}
                    {/* {this.props.editMode ? null :  <Cell style={{width:'25%',height:18}}>Prior</Cell>} */}
                    {this.props.editMode ? null : (
                      <Cell
                        style={{
                          width: '30%',
                          height: 18,
                          backgroundColor: 'transparent',
                          position: 'inherit ',
                        }}
                      >
                        {this.props.t('price')}
                      </Cell>
                    )}
                    {this.props.editMode ? null : (
                      <Cell
                        style={{
                          // width: '15%',
                          height: 18,
                          backgroundColor: 'transparent',
                          position: 'inherit ',
                        }}
                      >
                        <img
                          src={EditIcon}
                          onClick={() => this.props?.toggleEditTable(true)}
                          style={{ cursor: 'pointer' }}
                        />
                      </Cell>
                    )}
                  </Row>

                  {/* {this.props.editMode ? this.editCells() : this.createCells()} */}
                  {this.props.editMode ? null : this.createCells()}
                </StickyTable>
              </div>
            </div>
          )}
        </>
      )
    } else return null
  }
}
