//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//CONTEXT
import { BudgetingContext } from './Budgeting'

//STYLES
import '../../scss/components/budgeting/Balance.scss'

//UTILS
import formatCurrency from '../../utility-functions/formatCurrency'

export default function Balance() {
  const { t, budgetingData } = useContext(BudgetingContext)
  const details = budgetingData?.payload?.budgeting

  const incomeRatioDetails = details?.credit_score_data?.find(
    (item) => item.name === 'Debt / Income Ratio'
  )

  return (
    <div className='balance'>
      {/* <p className='sub-header uppercase'>{t('net_worth')}</p>
      <p className='big-price'>{formatCurrency(details?.netWorth)}</p> */}
      <div className='balace-section-small'>
        <div className='balance-section'>
          <p className='title underline' style={{ fontSize: '1rem' }}>
            {t('assets')}
          </p>
          <div className='budgeting-row'>
            <p className='budgeting-label'>{t('cash')}</p>
            <p className='budgeting-value'>
              {formatCurrency(details?.finStat?.asset?.sumDepository)}
            </p>
          </div>
          <div className='budgeting-row'>
            <p className='budgeting-label'>{t('investments')}</p>
            <p className='budgeting-value'>
              {formatCurrency(details?.finStat?.asset?.sumInvestment)}
            </p>
          </div>
          <div className='budgeting-row'>
            <p className='budgeting-label'>{t('physical_assets')}</p>
            <p className='budgeting-value'>
              {formatCurrency(details?.finStat?.asset?.sumPhysicalAssets)}
            </p>
          </div>
        </div>
        <div className='balance-section space-top'>
          <p className='title underline' style={{ fontSize: '1rem' }}>
            {t('liabilities')}
          </p>
          <div className='budgeting-row'>
            <p className='budgeting-label'>{t('credit_cards')}</p>
            <p className='budgeting-value'>
              {formatCurrency(details?.finStat?.liability?.sumCredit)}
            </p>
          </div>
          <div className='budgeting-row'>
            <p className='budgeting-label'>{t('loans')}</p>
            <p className='budgeting-value'>
              {formatCurrency(details?.loan_extra)}
            </p>
          </div>
          <div className='budgeting-row'>
            <p className='budgeting-label'>{t('Debt / Income Ratio')}</p>
            <p className='budgeting-value'>{incomeRatioDetails?.value}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
