import React from 'react'

export default function ForesciteDisclosures() {
  return (
    <div className='mobile-disclosure-main-conatiner'>
      <p className='title'>Investall Disclosures</p>
      <ul>
        <li>
          <a
            href='/Legal/ForeSCITE-Investment-Advisory-Agreement.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Investment Advisory Agreement
          </a>
        </li>
        <li>
          <a href='/Legal/EULA.pdf' target='_blank' rel='noopener noreferrer'>
            EULA
          </a>
        </li>

        <li>
          <a
            href='/Legal/Investall-Inc-2023_03_30-Form-ADV-Part-2A.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Investall ADV Part 2
          </a>
        </li>

        <li>
          <a
            href='/Legal/Investall-Inc-Form-CRS-2023_03_30.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Investall CRS
          </a>
        </li>

        <li>
          <a
            href='/Legal/Investall-Privacy-Policy.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Investall Privacy Policy
          </a>
        </li>

        <li>
          <a
            href='/Legal/Investall-Terms-of-use.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Investall Terms of Use
          </a>
        </li>

        <li>
          <a
            href='/Legal/Investall-General-Disclaimer.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Investall General Disclaimer
          </a>
        </li>

        <li>
          <a
            href='/Legal/ForeSCITE-Annual-Disclosure-2021.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            2021 Investall Annual Disclosure
          </a>
        </li>

        <li>
          <a
            href='/Legal/ForeSCITE-Customer-Relationship-Summary.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Investall Customer Information Brochure
          </a>
        </li>
      </ul>
    </div>
  )
}
