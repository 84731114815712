import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import Amplify, { Auth } from 'aws-amplify'
import awsExports from '../../aws-config'
import VerificationInput from 'react-verification-input'
import { Link } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import { Dropdown } from 'primereact/dropdown'
import Endpoint from '../Endpoint'
import CN from '../../assets/cn.svg'
import US from '../../assets/us.svg'
import PT from '../../assets/br.svg'
import i18n from 'i18next'
import axios from 'axios'

import {
  FaEye,
  FaEyeSlash,
  FaChevronCircleDown,
  FaChevronCircleUp,
  FaPlusCircle,
} from 'react-icons/fa'

import { IoIosClose } from 'react-icons/io'
import ProfilePicture from '../../assets/newLandingPage/profile.svg'
// import Logo from '../../assets/newLandingPage/investall-logo.svg'
import Logo from '../../assets/white-logo.svg'
import BlackLogo from '../../assets/black-logo.svg'
import Bars from '../../assets/newLandingPage/Bars.svg'
import BarsWhite from '../../assets//bars-white.svg'
import Modal from 'react-modal'
import ChangePasswordModal from './ChangePasswordModal'
import { Button } from 'primereact/button'
import { RadioButton } from 'primereact/radiobutton'
import '../../scss/components/MainNavBar.scss'
import Loader from './accountInfo/Loader'
import { handleAppRedirect } from '../../utility-functions/handleAppRedirect'
import { LandingPageContext } from '../pages/landing-page'
import { connect } from 'react-redux'
import { setUsername } from '../../store/slice/NavbarSlice'
import { setTimeOut } from '../../store/slice/UserSlice'

Amplify.configure(awsExports)

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    overflow: 'auto',
    borderRadius: '5px',
    border: 'none',
    boxShadow: '0px 4px 23px rgba(0, 0, 0, 0.08)',
  },
}

class MainNavbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: false,
      dropdown: false,
      showSignUpPopup: false,
      isSmallScreen: false,
      signUpModalOpen: false,
      loginModalOpen: false,
      showLoginPopup: false,
      showCode: false,
      showForgetPassword: false,
      password: '',
      errors: '',
      authCode: '',
      functionDropdown: false,
      tradeDropdown: false,
      stockDropdown: false,
      agree: false,
      cryptoDropdown: false,
      financialDropdown: false,
      loginLoading: false,
      username: '',
      changePasswordStep: 0,
      openPanel: false,
      showChangePasswordModal: false,
      showOptions: false,
      verificationLoading: false,
      verificationErrorMsg: '',
      verificationSuccessMsg: '',
      sendCodeDestination: '',
      showPassword: false,
      language: i18n.language,
      nameInitials: '-',
      colorChange: false,
    }
  }

  componentDidMount() {
    document.body.style.overflow = 'unset'
    window.addEventListener('resize', this.updateWindowDimensions)
    window.addEventListener('scroll', this.handleScrollToElement)
    this.updateWindowDimensions()
    if (this.props.user) {
      this.getInitials()
    }

    // if (i18n.language === 'zh') {
    //   this.setState({ language: 'zh' })
    //   console.log('zh')
    // } else {
    //   this.setState({ language: 'eng' })
    //   console.log('en')
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    // if (
    //   prevState.signUpModalOpen === false &&
    //   this.state.signUpModalOpen === true
    // ) {
    //   document.body.style.overflow = "hidden";
    // }
    // if (
    //   prevState.signUpModalOpen === true &&
    //   this.state.signUpModalOpen === false
    // ) {
    //   document.body.style.overflow = "unset";
    //   this.setState({
    //     showCode: false,
    //     errors: "",
    //     username: "",
    //     password: "",
    //     passwordConfirm: "",
    //     authCode: "",
    //   });
    // }
    // if (
    //   prevState.showSignUpPopup === false &&
    //   this.state.showSignUpPopup === true
    // ) {
    //   document.body.style.overflow = "hidden";
    // }
    // if (
    //   prevState.showSignUpPopup === true &&
    //   this.state.showSignUpPopup === false
    // ) {
    //   document.body.style.overflow = "unset";
    //   this.setState({
    //     showCode: false,
    //     errors: "",
    //     username: "",
    //     password: "",
    //     passwordConfirm: "",
    //     authCode: "",
    //   });
    // }
    // if (
    //   prevState.showLoginPopup === true &&
    //   this.state.showLoginPopup === false
    // ) {
    //   document.body.style.overflow = "unset";
    //   this.setState({
    //     showForgetPassword: false,
    //     errors: "",
    //     username: "",
    //     password: "",
    //   });
    // }
    if (prevState.dropdown === false && this.state.dropdown === true) {
      document.body.style.overflow = 'hidden'
    }
    if (prevState.dropdown === true && this.state.dropdown === false) {
      document.body.style.overflow = 'unset'
    }
    if (prevState.openPanel === false && this.state.openPanel === true) {
      document.body.style.overflow = 'hidden'
    }
    if (prevState.openPanel === true && this.state.openPanel === false) {
      document.body.style.overflow = 'unset'
    }
    if (
      prevState.changePasswordStep === 2 &&
      this.state.changePasswordStep === 3
    ) {
      setTimeout(() => this.setState({ showForgetPassword: false }), 5000)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
    window.removeEventListener('scroll', this.handleScrollToElement)
  }

  handleScrollToElement = (event) => {
    if (window.scrollY >= 80) {
      this.setState({ colorChange: true })
    } else {
      this.setState({ colorChange: false })
    }
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  getInitials = async () => {
    let url = `${Endpoint}/mobile/get-email`
    try {
      let response = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
          'Content-Type': 'application/json',
        },
      })
      if (
        response &&
        response.data &&
        response.data.firstName &&
        response.data.lastName &&
        response.data.firstName.length !== 0 &&
        response.data.firstName.length !== 0
      ) {
        const initials =
          response.data.firstName.charAt(0).toUpperCase() +
          response.data.lastName.charAt(0).toUpperCase()
        this.setState({
          nameInitials: initials,
        })
      } else {
        this.setState({ nameInitials: '-' })
      }
    } catch (error) {
      console.log(error)
      this.setState({ nameInitials: '-' })
    }
  }

  toggleShowChangePasswordModal = () => {
    this.setState({
      showChangePasswordModal: !this.state.showChangePasswordModal,
    })
  }

  toggleLoginModal = () => {
    this.setState({ loginModalOpen: !this.state.loginModalOpen })
  }

  validateusername = () => {
    const { username } = this.props
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(String(username).toLowerCase())) {
      return true
    } else {
      this.setState({ errors: this.props.t('pleaseentervalidemail') })
      return false
    }
  }
  validateUsername = () => {
    const { username } = this.props
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(String(username).toLowerCase())) {
      return true
    } else {
      this.setState({ errors: this.props.t('pleaseentervalidemail') })
      return false
    }
  }
  validatePassword = () => {
    const { password } = this.state
    if (password) {
      if (password.length < 8) {
        this.setState({
          errors: this.props.t('passwordmustbegreaterthan8characters'),
        })
        return false
      } else {
        return true
      }
    }
    this.setState({ errors: this.props.t('pleaseenterpassword') })
    return false
  }

  validateNewPassword = () => {
    const { newPassword } = this.state
    if (newPassword) {
      if (newPassword.length < 8) {
        this.setState({
          errors: this.props.t('passwordmustbegreaterthan8characters'),
        })
        return false
      } else {
        return true
      }
    }
    this.setState({ errors: this.props.t('pleaseenterpassword') })
    return false
  }
  validatePhoneNumber = () => {
    if (typeof this.state.phoneNumber === 'undefined') {
      this.setState({ errors: this.props.t('invalidphonenumber') })
      return false
    } else {
      if (this.state.phoneNumber.length > 6) {
        return true
      }
      this.setState({ errors: this.props.t('invalidphonenumber') })
      return false
    }
  }

  validateForm() {
    const { agree } = this.state
    if (agree) {
      return (
        this.validateUsername() &&
        this.validatePhoneNumber() &&
        this.validatePassword()
      )
    } else {
      this.setState({
        errors: this.props.t('pleaseagreetoprivacypolicyandeula'),
      })
      return false
    }
  }

  confirmSignUp() {
    this.setState({
      verificationLoading: true,
      verificationErrorMsg: '',
      verificationSuccessMsg: '',
    })
    const { authCode } = this.state
    const { username } = this.props
    // const username = email;
    Auth.confirmSignUp(username, authCode)
      .then(() => {
        // window.alert(
        //   "Sign up successful! Please Log In in the top left-hand corner.\nYou can also manage your account, using the same credentials, with your mobile device using our iOS or Android apps!"
        // );
        let loginVar = Auth.signIn(this.props.username, this.state.password)
          .catch((error) => {
            switch (error.message) {
              case 'User does not exist.':
                error = this.props.t('usernamenotfound')
                break
              case 'Incorrect username or password.':
                error = this.props.t('incorrectpassword')
                break
              case 'User is not confirmed.':
                error = this.props.t('userisnotconfirmed')
                this.setState({
                  retryUser: this.props.username,
                  loginModalOpen: false,
                  signUpModalOpen: true,
                  showCode: true,
                })
                this.setState({ loginModalOpen: false, erorrs: '' })
                break
              default:
                // code block
                console.log('Not Caught Error: ' + error.message)
                break
            }
            this.setState({
              errors: error,
              signUpModalOpen: false,
              loginModalOpen: true,
              showLoginPopup: true,
              showSignUpPopup: false,
            })
          })
          //Auth.signIn(this.state.username, this.state.password)

          // .catch((err) => {
          //   console.log("login err", err);
          //   return;
          // })
          .then((res) => {
            if (res) {
              this.setState({
                verificationSuccessMsg: this.props.t('signupsuccessful'),
              })
              Auth.currentSession()
                .then((resp) => {
                  let user = resp
                  if (user) {
                    this.props.setUser(user)
                  }
                })
                .catch((err) => {
                  console.log('login', err)
                })
              // .then(() => {
              //   if (!this.state.isSmallScreen)
              //     window.location.replace('/Dashboard')
              // })
            }
          })
        this.setState({ verificationLoading: false })
        if (this.state.isSmallScreen) {
          this.setState({ showSignUpPopup: false })
        }
      })
      .catch((err) => {
        console.log('login err', err)
        this.setState({
          verificationLoading: false,
          verificationErrorMsg: this.props.t('invalidcode'),
        })
      })
  }

  inputCode = (e, type) => {
    if (type === 'password') {
      this.setState({ passwordCode: e }, () => {
        if (this.state.passwordCode.length === 6) {
          this.setState({ changePasswordStep: 2, errors: '' })
        }
      })
    } else {
      this.setState({ authCode: e }, () => {
        if (this.state.authCode.length === 6) {
          this.confirmSignUp()
        }
      })
    }
  }

  resendCode = () => {
    Auth.resendSignUp(this.props.username)
      .then((res) => {
        // window.alert("Your code has been resent.");
        this.setState({
          verificationSuccessMsg: this.props.t('codehasbeensent'),
        })
      })
      .catch((error) => {
        this.setState({
          verificationErrorMsg: this.props.t('failedtoresendcode'),
        })
      })
  }

  nextStep = () => {
    if (this.validateForm()) {
      this.setState({ showOptions: true, errors: '' })
      // this.setState({ showCode: true });
    }
  }

  sendVerifCode = (isEmail, username) => {
    this.setState({ isEmail, verificationLoading: true })
    const { password, phoneNumber } = this.state
    let email = username
    const phone_number = phoneNumber
    let attributes = {
      email,
    }
    if (!isEmail) attributes.phone_number = `+${phone_number}`
    Auth.signUp({
      username,
      password,
      attributes,
    })
      .then((resp) => {
        this.setState({
          showCode: true,
          showOptions: false,
          verificationLoading: false,
          sendCodeDestination: resp?.codeDeliveryDetails?.Destination,
        })
      }) //CATCH ERRORS
      .catch((error) =>
        this.setState({ errors: error.message, verificationLoading: false })
      )
  }

  displayPhoneNumber = () => {
    return (
      '+' +
      this.state.phoneNumber?.substring(0, 1) +
      '-' +
      this.state.phoneNumber?.substring(1, 4) +
      '-' +
      this.state.phoneNumber?.substring(4, 7) +
      '-' +
      this.state.phoneNumber?.substring(7, this.state.phoneNumber?.length)
    )
  }

  validateLoginPassword = () => {
    const { password } = this.state
    if (!password) {
      this.setState({ errors: this.props.t('pleaseenterpassword') })
      return false
    } else return true
  }

  login = async () => {
    if (this.validateusername() && this.validateLoginPassword()) {
      this.setState({ loginLoading: true })
      let loginVar = Auth.signIn(this.props.username, this.state.password)
        .catch((error) => {
          console.log(error)
          switch (error.message) {
            case 'User does not exist.':
              error = this.props.t('usernamenotfound')
              break
            case 'Incorrect username or password.':
              error = this.props.t('incorrectpassword')
              break
            case 'User is not confirmed.':
              error = this.props.t('userisnotconfirmed')
              this.setState({
                retryUser: this.props.username,
                loginModalOpen: false,
                showLoginPopup: false,
                showSignUpPopup: true,
                signUpModalOpen: true,
                showCode: true,
                username: this.props.username,
              })
              this.setState({ loginModalOpen: false, errors: '' })
              break
            default:
              // code block
              console.log('Not Caught Error: ' + error.message)
              this.setState({ errors: error, loginLoading: false })
              return
          }
          this.setState({ errors: error, loginLoading: false })
        })
        .then((res) => {
          // CHECK FOR SESSSION AND CHALLENGE NAME
          //if true
          if (res) {
            if (
              !res.signInUserSession &&
              res.challengeName === 'NEW_PASSWORD_REQUIRED'
            ) {
              //SHOW CHANGE PASSWORD FORM
              this.setState({ loginLoading: false, loginModalOpen: false })
              this.toggleShowChangePasswordModal()
            } else {
              Auth.currentSession()
                .then((resp) => {
                  let user = resp
                  if (user) {
                    this.props.setUser(user)
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
              // .then(() => {
              //   if (!this.state.isSmallScreen)
              //     window.location.replace('/Dashboard')
              // })
            }
          }
        })
        .catch((err) => {
          console.log('login err', err)
          return
        })
    }
  }

  sendChangePasswordCode = () => {
    if (this.state.email && this.state.email.length > 5) {
      Auth.forgotPassword(this.state.email)
        .then((resp) => {
          this.setState({
            sendCodeDestination: resp.CodeDeliveryDetails.Destination,
            changePasswordStep: 1,
            errors: false,
          })
        })
        .catch((error) => {
          console.log(error)
          this.setState({ errors: error.message })
        })
    } else {
      this.setState({ errors: this.props.t('pleaseentervalidemail') })
    }
  }

  submitNewPassword = () => {
    if (this.validateNewPassword()) {
      Auth.forgotPasswordSubmit(
        this.state.email,
        this.state.passwordCode,
        this.state.newPassword
      )
        .then((res) => {
          this.setState({ changePasswordStep: 3, errors: '' })
        })
        .catch((err) => {
          console.log('err', err)
          this.setState({ errors: err.message, changePasswordStep: 1 })
        })
    }
  }

  resetForms = () => {
    this.setState({
      showSignUpPopup: false,
      signUpModalOpen: false,
      loginModalOpen: false,
      showLoginPopup: false,
      showCode: false,
      showForgetPassword: false,
      errors: '',
      authCode: '',
      loginLoading: false,
      changePasswordStep: 0,
      openPanel: false,
      showChangePasswordModal: false,
      showOptions: false,
      verificationLoading: false,
      dropdown: false,
      verificationErrorMsg: '',
      agree: false,
      verificationSuccessMsg: '',
      showPassword: false,
    })
  }

  renderChangePasswordSteps = () => {
    switch (this.state.changePasswordStep) {
      case 1:
        return (
          <div className='codeConfirm__container'>
            <div
              className='forgot-pw-close-icon'
              onClick={() => this.resetForms()}
            >
              <IoIosClose className='icon' />
            </div>
            <p>
              {this.props.t('wesentaverificationcodeto')}{' '}
              <span className='bold'>{this.state.sendCodeDestination}</span>.
              {this.props.t('pleaseenterthecodetoverifythisisyouremail')}
            </p>
            <VerificationInput
              autoFocus
              removeDefaultStyles
              classNames={{
                container: 'verificationInput__container',
                character: 'verificationInputCharacter__container',
                characterInactive:
                  'verificationInputCharacter__container__inactive',
                characterSelected:
                  'verificationInputCharacter__container__selected',
              }}
              validChars='0-9'
              placeholder=''
              onChange={(e) => this.inputCode(e, 'password')}
              value={this.state.passwordCode}
            />
            <p className='error-msg'>{this.state.errors}</p>
          </div>
        )
      case 2:
        return (
          <div className='codeConfirm__container'>
            <div
              className='forgot-pw-close-icon'
              onClick={() => this.resetForms()}
            >
              <IoIosClose className='icon' />
            </div>
            <div className='container'>
              <p>{this.props.t('enteryourpasswordbelow')}</p>
              <div className='password-container'>
                <input
                  name='newPassword'
                  type={this.state.showPassword ? 'text' : 'password'}
                  autoComplete='password'
                  value={this.state.newPassword}
                  className='forgot-pw-input'
                  onChange={(e) =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                />
                {this.state.showPassword ? (
                  <FaEyeSlash
                    className='password-toggle-icon'
                    onClick={() => {
                      this.setState({ showPassword: false })
                    }}
                  />
                ) : (
                  <FaEye
                    className='password-toggle-icon'
                    onClick={() => {
                      this.setState({ showPassword: true })
                    }}
                  />
                )}
              </div>
              {/* <div
                className="signup__button"
                onClick={() => this.submitNewPassword()}
              >
                Change your password
              </div> */}
              <p className='error-msg'>{this.state.errors}</p>
              <div className='main-navbar-submt-btn-container'>
                <Button
                  label={this.props.t('changepassword')}
                  className='main-navbar-submit-btn'
                  onClick={() => {
                    this.submitNewPassword()
                  }}
                ></Button>
              </div>
            </div>
          </div>
        )
      case 3:
        return (
          <div className='codeConfirm__container'>
            <div
              className='forgot-pw-close-icon'
              onClick={() => this.resetForms()}
            >
              <IoIosClose className='icon' />
            </div>
            <p>{this.props.t('yourpasswordhasbeenreset')}</p>
          </div>
        )
      default:
        return (
          <div className='codeConfirm__container'>
            <div
              className='forgot-pw-close-icon'
              onClick={() => this.resetForms()}
            >
              <IoIosClose className='icon' />
            </div>
            <div className='container'>
              <p>{this.props.t('pleaseenteryouremaildownbelow')}</p>
              <input
                name='email'
                autoComplete='username'
                value={this.state.email}
                className='forgot-pw-input'
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              />
              <div className='errors__container'>{this.state.errors}</div>
              <div className='main-navbar-submt-btn-container'>
                <Button
                  label={this.props.t('confirm')}
                  className='main-navbar-submit-btn'
                  onClick={() => {
                    this.sendChangePasswordCode()
                  }}
                ></Button>
              </div>
            </div>
          </div>
        )
    }
  }

  showDropdown = () => {
    return (
      <div className='navbar__dropdown__container'>
        <div className='main__container'>
          <div className='top__container'>
            <NavLink className='logo' to='/'>
              <img src={BlackLogo} alt='Logo' height={30} />
            </NavLink>
            <div
              className='icon'
              onClick={() => this.resetForms()}
              style={{ cursor: 'pointer' }}
            >
              <IoIosClose />
            </div>
          </div>
          <div className='bottom__container'>
            <div
              className='item__container'
              onClick={() =>
                window.open('https://www.allocaterite.ai', '_blank')
              }
            >
              {this.props.t('business')}
            </div>
            {/* <NavLink exact={true} to='/stock-info' className='item__container'>
              {this.props.t('stock')}
            </NavLink>
            <NavLink exact={true} to='/crypto-info' className='item__container'>
              {this.props.t('crypto')}
            </NavLink> */}
            <NavLink exact={true} to='/products' className='item__container'>
              {this.props.t('products2')}
            </NavLink>
            {this.props.user ? (
              <NavLink className='item__container' to='/Dashboard'>
                {this.props.t('dashboard')}
              </NavLink>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  handleScrollIntoView = () => {
    this.analyticsScrollRef &&
      this.analyticsScrollRef.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
  }

  renderVerificationOptions = () => {
    return (
      <div
        className={`${
          this.state.isSmallScreen
            ? 'verification-options-container-small'
            : 'verification-options-container'
        }`}
      >
        <div className='options-row'>
          <div className='options-container'>
            <RadioButton
              value={this.state.isEmail}
              onChange={(e) => this.setState({ isEmail: !this.state.isEmail })}
              checked={this.state.isEmail}
            />
            <p className='option-label'>{this.props.t('email')}</p>
          </div>
          <div className='options-container'>
            <RadioButton
              value={this.state.isEmail}
              onChange={(e) => this.setState({ isEmail: !this.state.isEmail })}
              checked={!this.state.isEmail}
            />
            <p className='option-label'>{this.props.t('phone')}</p>
          </div>
        </div>
        <p className='error'>{this.state.errors}</p>
        <div className='options-submit-btn-container'>
          <Button
            label={this.props.t('submit')}
            className='options-submit-btn'
            onClick={() => {
              this.setState({ errors: '' })
              if (this.state.isEmail) {
                this.sendVerifCode(true, this.props.username)
              } else {
                this.sendVerifCode(false, this.props.username)
              }
            }}
            loading={this.state.verificationLoading}
          />
        </div>
        <div
          className='have-code-container'
          onClick={() => {
            this.setState({ showOptions: false, showCode: true })
          }}
        >
          {this.props.t('alreadyreceivedcode')}
        </div>
      </div>
    )
  }

  renderChangeLanguage = () => {
    return (
      <div className='language-toggle-container-navbar'>
        {/* <p className='language-header'>{this.props.t('language')}</p> */}
        <Dropdown
          value={i18n.language}
          options={[
            { label: <img src={US} className='flag' />, value: 'en' },
            { label: <img src={CN} className='flag' />, value: 'zh' },
            { label: <img src={PT} className='flag' />, value: 'pt' },
          ]}
          onChange={(e) => this.changeLanguage(e.value)}
          className={
            (this.state.colorChange || this.props.colorChange) &&
            !this.props.blue
              ? 'changed-color-language-toggle'
              : ''
          }
        />
        {/* <div className='flag-container'>
          <img src={US} className='flag' />
          <img src={CN} className='flag' />
        </div> */}
      </div>
    )
  }

  signUpBtn = () => {
    return (
      <div
        className='signup-btn-container'
        onClick={() => {
          this.setState({ loginModalOpen: false, errors: '' })
        }}
      >
        <button className='navbar-signup-btn'>
          <p className='signup-label'>{this.props.t('signup')}</p>
        </button>
      </div>
    )
  }

  changeLanguage = (value) => {
    // if (i18n.language === 'zh') {
    //   console.log('zh')
    //   i18n.changeLanguage('en')
    //   if (this.props.user) this.changeLanguageAPI('en')
    // } else if (i18n.language === 'pt') {
    //   i18n.changeLanguage('pt')
    //   //if (this.props.user) this.changeLanguageAPI('en')
    // } else {
    //   console.log('en')
    //   i18n.changeLanguage('zh')
    //   if (this.props.user) this.changeLanguageAPI('zh')
    // }
    if (value === 'zh') {
      i18n.changeLanguage('zh')
      if (this.props.user) this.changeLanguageAPI('zh')
    } else if (value === 'pt') {
      i18n.changeLanguage('pt')
      if (this.props.user) this.changeLanguageAPI('pt')
    } else {
      i18n.changeLanguage('en')
      if (this.props.user) this.changeLanguageAPI('en')
    }
  }

  changeLanguageAPI = async (value) => {
    axios
      .get(`${Endpoint}/mobile/get-email?lang=${value}`, {
        headers: {
          Authorization: 'Bearer ' + this.props.user?.idToken?.jwtToken,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        if (res) {
        } else {
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  bigHeader() {
    return (
      // <div className={'navbar__container__background__white'}>
      <div
        className={
          this.props.scrolled
            ? 'navbar_container scrolled'
            : this.props.blue
            ? 'navbar_container blue'
            : this.props.white
            ? 'navbar_container white'
            : this.state.colorChange
            ? 'navbar_container scrolled'
            : 'navbar_container '
        }
        id='nav'
      >
        {/* {this.renderChangeLanguage()} */}
        <div className='navbar__container'>
          <NavLink className='navLink__container logo' to='/'>
            <img
              src={
                this.props.scrolled
                  ? BlackLogo
                  : this.props.blue
                  ? Logo
                  : this.props.white
                  ? BlackLogo
                  : (this.state.colorChange || this.props.colorChange) &&
                    !this.props.blue
                  ? BlackLogo
                  : Logo
              }
              height={30}
              alt='Logo'
            />
          </NavLink>
          <div className='left__container'>
            <div className='menu__container'>
              {/* <div
                className='title__container'
                onMouseEnter={() => this.setState({ stockDropdown: true })}
                onMouseLeave={() => this.setState({ stockDropdown: false })}
              >
                <NavLink
                  to='/stock-info'
                  className={
                    this.state.stockDropdown ? 'highlight active' : 'highlight'
                  }
                  style={{
                    color:
                      (this.state.colorChange || this.props.colorChange) &&
                      !this.props.blue &&
                      !this.props.blue
                        ? 'black'
                        : 'white',
                  }}
                >
                  {this.props.t('stocks')}
                </NavLink>
              </div> */}
              {/* <div
                className='title__container'
                onMouseEnter={() => this.setState({ cryptoDropdown: true })}
                onMouseLeave={() => this.setState({ cryptoDropdown: false })}
              >
                <NavLink
                  to='/crypto-info'
                  className={
                    this.state.cryptoDropdown ? 'highlight active' : 'highlight'
                  }
                  style={{
                    color:
                      (this.state.colorChange || this.props.colorChange) &&
                      !this.props.blue
                        ? 'black'
                        : 'white',
                  }}
                >
                  {this.props.t('crypto')}
                </NavLink>
              </div> */}
              <div
                className='title__container'
                onMouseEnter={() => this.setState({ tradeDropdown: true })}
                onMouseLeave={() => this.setState({ tradeDropdown: false })}
              >
                <NavLink
                  to='/products'
                  className={
                    this.state.tradeDropdown ? 'highlight active' : 'highlight'
                  }
                  style={{
                    color: this.props.scrolled
                      ? 'black'
                      : this.props.white
                      ? 'black'
                      : (this.state.colorChange || this.props.colorChange) &&
                        !this.props.blue
                      ? 'black'
                      : 'white',
                  }}
                >
                  {this.props.t('products2')}
                </NavLink>
              </div>
              {/* <div
                className='title__container'
                onMouseEnter={() => this.setState({ functionDropdown: true })}
                onMouseLeave={() => this.setState({ functionDropdown: false })}
              >
                <a
                  href='https://www.allocaterite.ai/'
                  rel='noopener noreferrer'
                  target='_blank'
                  className={
                    this.state.functionDropdown
                      ? 'highlight active'
                      : 'highlight'
                  }
                  style={{
                    color: this.props.scrolled
                      ? 'black'
                      : this.props.white
                      ? 'black'
                      : (this.state.colorChange || this.props.colorChange) &&
                        !this.props.blue
                      ? 'black'
                      : 'white',
                  }}
                >
                  {this.props.t('business')}
                </a>
              </div> */}
            </div>
          </div>
          {this.props.user ? (
            <NavLink
              to={`/Dashboard`}
              exact={true}
              style={{ textDecoration: 'none' }}
            >
              <p
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                  backgroundColor: '#3F46F6',
                  color: 'white',
                  textAlign: 'center',
                  fontSize: '15px',
                  height: '40px',
                  width: '40px',
                  marginBottom: 0,
                  marginRight: 5,
                }}
              >
                {this.state.nameInitials}
              </p>
            </NavLink>
          ) : (
            <div className='navLink__container left'>
              <NavLink
                to={`/signin`}
                exact={true}
                style={{ textDecoration: 'none' }}
              >
                <p
                  onClick={() => {
                    // this.setState({ loginModalOpen: true, errors: '' })
                    // this.props.setSignUpModalOpen(false)
                  }}
                  style={{
                    cursor: 'pointer',
                    // color:
                    //   (this.state.colorChange || this.props.colorChange) &&
                    //   !this.props.blue
                    //     ? 'black'
                    //     : 'white',
                    color: this.props.scrolled
                      ? 'black'
                      : this.props.white
                      ? 'black'
                      : (this.state.colorChange || this.props.colorChange) &&
                        !this.props.blue
                      ? 'black'
                      : 'white',
                  }}
                >
                  {this.props.t('signin')}
                </p>
              </NavLink>
              <NavLink
                to={`/signup`}
                exact={true}
                style={{ textDecoration: 'none' }}
              >
                <p
                  onClick={() => {
                    // this.setState({
                    //   signUpModalOpen: true,
                    //   loginModalOpen: false,
                    //   erros: '',
                    // })
                    // this.props.setSignUpModalOpen(true)
                  }}
                  style={{
                    cursor: 'pointer',
                    // color:
                    //   (this.state.colorChange || this.props.colorChange) &&
                    //   !this.props.blue
                    //     ? 'black'
                    //     : 'white',
                    color: this.props.blue ? '#3627e4' : 'white',
                    borderRadius: 35,
                    background: this.props.blue ? 'white' : '#3627e4',
                    width: 116,
                    height: 40,
                    display: 'flex',
                    justifyContent: 'center',
                    border: this.props.whiteBorder ? '1px solid white' : 'none',
                    alignItems: 'center',
                  }}
                >
                  {this.props.t('signup')}
                </p>
              </NavLink>
            </div>
          )}
          {this.renderChangeLanguage()}
          {/* {this.renderChangeLanguage()} */}
        </div>
        {/* Signup Modal  */}
        <Modal
          style={customStyles}
          // isOpen={this.state.signUpModalOpen}
          isOpen={this.props.signUpModalOpen}
          ariaHideApp={false}
          // onRequestClose={() => this.setState({ signUpModalOpen: false })}
          onRequestClose={() => {
            this.setState({ errors: '' })
          }}
        >
          <div
            className='modal__container'
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            <div className='top__container'>
              <p className='top-container-header'>
                {this.state.showOptions
                  ? this.props.t('chooseaverificationmethod')
                  : this.state.showCode
                  ? this.props.t('entercode')
                  : this.props.t('createanaccount')}
              </p>
              <IoIosClose className='icon' onClick={() => this.resetForms()} />
            </div>
            {!this.state.showCode && !this.state.showOptions ? (
              <div className='form__container'>
                <p>{this.props.t('email')}:</p>
                <input
                  name='username'
                  autoComplete='username'
                  value={this.props.username}
                  className='input__line'
                  onChange={(e) =>
                    // this.setState({ [e.target.name]: e.target.value })
                    this.props.setUsername(e.target.value)
                  }
                />
                <p>{this.props.t('phonenumber')}:</p>
                <PhoneInput
                  containerClass='phone__input__container'
                  inputClass='phone__input'
                  disableDropdown
                  country={'us'}
                  onChange={(e) => this.setState({ phoneNumber: e })}
                />
                <p>{this.props.t('password')}:</p>
                <div className='password-container'>
                  <input
                    name='password'
                    type={this.state.showPassword ? 'text' : 'password'}
                    autoComplete='new-password'
                    value={this.state.password}
                    className='input__line'
                    onChange={(e) =>
                      this.setState({ [e.target.name]: e.target.value })
                    }
                  />{' '}
                  {this.state.showPassword ? (
                    <FaEyeSlash
                      className='password-toggle-icon'
                      onClick={() => {
                        this.setState({ showPassword: false })
                      }}
                    />
                  ) : (
                    <FaEye
                      className='password-toggle-icon'
                      onClick={() => {
                        this.setState({ showPassword: true })
                      }}
                    />
                  )}
                </div>
                <div className='check__container'>
                  <input
                    type='checkbox'
                    onClick={() => this.setState({ agree: !this.state.agree })}
                  />
                  <span>
                    {this.props.t('iagreetothe')}{' '}
                    <Link
                      to='/PrivacyPolicy'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      {this.props.t('privacypolicy')}
                    </Link>{' '}
                    {' & '}
                    <a href='/Legal/EULA.pdf' className='link'>
                      {this.props.t('eula')}
                    </a>
                  </span>
                </div>
                <div onClick={() => this.nextStep()} className='signup__button'>
                  {this.props.t('createaccount')}
                </div>
                <p
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    cursor: 'pointer',
                    paddingTop: '15px',
                    marginBottom: 0,
                  }}
                  onClick={() => {
                    this.setState({
                      signUpModalOpen: false,
                      loginModalOpen: true,
                      errors: '',
                    })
                  }}
                >
                  {this.props.t('logintoyour')}{' '}
                  <span style={{ color: '#007bff' }}>Investall</span>{' '}
                  {this.props.t('account')}
                </p>
                <div className='errors__container'>{this.state.errors}</div>
              </div>
            ) : (
              <>
                {this.state.showOptions ? (
                  <div className=''>{this.renderVerificationOptions()}</div>
                ) : (
                  <>
                    {this.state.showCode ? (
                      <>
                        {this.state.verificationLoading ? (
                          <>
                            <Loader
                              isSmallScreen={this.state.isSmallScreen}
                              logoLoader={false}
                            />
                          </>
                        ) : (
                          <div className='codeConfirm__container'>
                            <p>
                              {/* We sent a verification code to{" "}
                              {this.state.isEmail ? (
                                <span className="bold">
                                  {this.state.username}
                                </span>
                              ) : (
                                <span className="bold">
                                  {this.displayPhoneNumber()}
                                </span>
                              )} */}
                              {this.props.t('verificationcodesentmessage')}
                            </p>
                            <VerificationInput
                              autoFocus
                              removeDefaultStyles
                              classNames={{
                                container: 'verificationInput__container',
                                character:
                                  'verificationInputCharacter__container',
                                characterInactive:
                                  'verificationInputCharacter__container__inactive',
                                characterSelected:
                                  'verificationInputCharacter__container__selected',
                              }}
                              validChars='0-9'
                              placeholder=''
                              onChange={(e) => this.inputCode(e)}
                              value={this.state.authCode}
                            />
                            <p className='verification-error-msg'>
                              {this.state.verificationErrorMsg}
                            </p>
                            <p className='verification-success-msg'>
                              {this.state.verificationSuccessMsg}
                            </p>
                            <p style={{ cursor: 'pointer' }}>
                              {this.props.t('didntgetthecode')}
                              <span
                                className='resend__button'
                                onClick={() => this.resendCode()}
                              ></span>
                            </p>
                          </div>
                        )}
                      </>
                    ) : null}
                  </>
                )}
              </>
            )}
          </div>
        </Modal>
        {/* Login Modal */}
        <Modal
          style={customStyles}
          isOpen={this.state.loginModalOpen}
          ariaHideApp={false}
          onRequestClose={() => {
            this.setState({ loginModalOpen: false, errors: '' })
            this.props.setTimeOut(false)
          }}
        >
          <div
            className='modal__container'
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            {!this.state.showForgetPassword ? (
              <>
                <div className='top__container'>
                  {this.props.t('signin')}
                  <IoIosClose
                    className='icon'
                    onClick={() => {
                      this.resetForms()
                      this.props.setTimeOut(false)
                    }}
                  />
                </div>
                <div className='form__container'>
                  {/* {this.props.timeOut && (
                    <div className='session-timeout-message-container'>
                      <p className='session-timeout-message'>
                        Your session has expired. Please log back in.
                      </p>
                    </div>
                  )} */}
                  <p>{this.props.t('email')}:</p>
                  <input
                    name='username'
                    autoComplete='username'
                    value={this.props.username}
                    className='input__line'
                    onChange={(e) =>
                      // this.setState({ [e.target.name]: e.target.value })
                      this.props.setUsername(e.target.value)
                    }
                  />
                  <p>{this.props.t('password')}:</p>
                  <div className='password-container'>
                    <input
                      name='password'
                      type={this.state.showPassword ? 'text' : 'password'}
                      autoComplete='new-password'
                      value={this.state.password}
                      className='input__line'
                      onChange={(e) =>
                        this.setState({ [e.target.name]: e.target.value })
                      }
                    />
                    {this.state.showPassword ? (
                      <FaEyeSlash
                        className='password-toggle-icon'
                        onClick={() => {
                          this.setState({ showPassword: false })
                        }}
                      />
                    ) : (
                      <FaEye
                        className='password-toggle-icon'
                        onClick={() => {
                          this.setState({ showPassword: true })
                        }}
                      />
                    )}
                  </div>
                  <div
                    onClick={() => {
                      this.setState({ showForgetPassword: true })
                    }}
                    className='forgot-pw-btn'
                  >
                    {this.props.t('forgotyourpassword')}?
                  </div>
                  {this.state.loginLoading ? (
                    <Spinner
                      animation='border'
                      id='spinner'
                      variant='dark'
                      role='status'
                      style={{ marginTop: '20px' }}
                    >
                      <span className='sr-only'>Loading...</span>
                    </Spinner>
                  ) : (
                    <div
                      onClick={() => this.login()}
                      className='login__button__2'
                    >
                      {this.props.t('signin')}
                    </div>
                  )}
                  <div className='errors__container'>{this.state.errors}</div>
                </div>
              </>
            ) : (
              <>{this.renderChangePasswordSteps()}</>
            )}
          </div>
        </Modal>

        <ChangePasswordModal
          customStyles={customStyles}
          showChangePasswordModal={this.state.showChangePasswordModal}
          toggleShowChangePasswordModal={this.toggleShowChangePasswordModal}
          previousPassword={this.state.password}
          email={this.props.username}
          toggleLoginModal={this.toggleLoginModal}
          t={this.props.t}
        />
      </div>
    )
  }

  smallHeader() {
    return (
      <div>
        {' '}
        {/* <div
          className={
            this.props.signUpModalOpen
              ? 'translateSignUp active'
              : 'translateSignUp'
          }
        >
          <div className='popup__container'>
            <div className='form__container'>
              <div className='top__container'>
                <div
                  className='close__container'
                  onClick={() => this.resetForms()}
                >
                  <IoIosClose className='icon' />
                </div>
              </div>
              {!this.state.showCode && !this.state.showOptions ? (
                <>
                  <div className='title__container'>
                    {this.props.t('createanaccount')}
                  </div>
                  <p>{this.props.t('email')}:</p>
                  <input
                    name='username'
                    autoComplete='username'
                    value={this.props.username}
                    className='input__line'
                    onChange={(e) =>
                      this.props.setUsername(e.target.value)
                    }
                  />
                  <p>{this.props.t('password')}:</p>
                  <input
                    name='password'
                    type='password'
                    autoComplete='new-password'
                    className='input__line'
                    onChange={(e) =>
                      this.setState({ [e.target.name]: e.target.value })
                    }
                  />
                  <p>{this.props.t('phonenumber')}:</p>
                  <PhoneInput
                    containerClass='phone__input__container'
                    inputClass='phone__input'
                    country='us'
                    disableDropdown
                    onChange={(e) => this.setState({ phoneNumber: e })}
                  />
                  <div className='check__container'>
                    <input
                      onClick={() =>
                        this.setState({ agree: !this.state.agree })
                      }
                      type='checkbox'
                    />
                    <span>
                      {this.props.t('iagreetothe')}{' '}
                      <Link
                        to='/PrivacyPolicy'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {this.props.t('privacypolicy')}
                      </Link>{' '}
                      {' & '}
                      <a href='/Legal/EULA.pdf' className='link'>
                        {this.props.t('eula')}
                      </a>
                    </span>
                  </div>
                  <div
                    onClick={() => this.nextStep()}
                    className='signup__button'
                  >
                    {this.props.t('createaccount')}
                  </div>
                  <p className='error-msg'>{this.state.errors}</p>
                </>
              ) : (
                <>
                  {this.state.showOptions ? (
                    <>
                      <div className='title__container'>
                        {this.props.t('chooseaverificationmethod')}
                      </div>
                      {this.renderVerificationOptions()}
                    </>
                  ) : (
                    <>
                      {this.state.showCode ? (
                        <>
                          {this.state.verificationLoading ? (
                            <>
                              <Loader
                                isSmallScreen={this.state.isSmallScreen}
                                logoLoader={false}
                              />
                            </>
                          ) : (
                            <div className='codeConfirm__container'>
                              <p>
                                {this.props.t('verificationcodesentmessage')}
                              </p>
                              <VerificationInput
                                autoFocus
                                removeDefaultStyles
                                classNames={{
                                  container: 'verificationInput__container',
                                  character:
                                    'verificationInputCharacter__container',
                                  characterInactive:
                                    'verificationInputCharacter__container__inactive',
                                  characterSelected:
                                    'verificationInputCharacter__container__selected',
                                }}
                                validChars='0-9'
                                placeholder=''
                                onChange={(e) => this.inputCode(e)}
                                value={this.state.authCode}
                              />
                              <p className='verification-error-msg'>
                                {this.state.verificationErrorMsg}
                              </p>
                              <p className='verification-success-msg'>
                                {this.state.verificationSuccessMsg}
                              </p>
                              <p
                                className='resend__button'
                                style={{ cursor: 'pointer' }}
                              >
                                {this.props.t('didntgetthecode')}
                                <span
                                  onClick={() => this.resendCode()}
                                  className='resend__button'
                                >
                                  {this.props.t('resendcode')}
                                </span>
                              </p>
                            </div>
                          )}
                        </>
                      ) : null}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div> */}
        {/* <div
          className={
            this.state.showLoginPopup
              ? 'translateSignUp active'
              : 'translateSignUp'
          }
        >
          <div className='popup__container'>
            {!this.state.showForgetPassword ? (
              <div className='form__container'>
                <div className='top__container'>
                  <div
                    className='close__container'
                    onClick={() => this.resetForms()}
                  >
                    <IoIosClose className='icon' />
                  </div>
                </div>
                <div className='title__container'>{this.props.t('signin')}</div>
                <p>{this.props.t('email')}:</p>
                <input
                  name='username'
                  autoComplete='username'
                  value={this.props.username}
                  className='input__line'
                  onChange={(e) =>
                    // this.setState({ [e.target.name]: e.target.value })
                    this.props.setUsername(e.target.value)
                  }
                />
                <p>{this.props.t('password')}:</p>
                <input
                  name='password'
                  type='password'
                  autoComplete='new-password'
                  className='input__line'
                  onChange={(e) =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                />
                <div className='check__container'>
                  <div
                    onClick={() => {
                      this.setState({ showForgetPassword: true })
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {this.props.t('forgotyourpassword')}?
                  </div>
                  <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                    •
                  </div>
                  <div
                    onClick={() => {
                      // this.setState({ showSignUpPopup: true }, () => {
                      //   setTimeout(
                      //     () =>
                      //       this.setState({
                      //         showLoginPopup: false,
                      //       }),
                      //     250
                      //   )
                      // })
                      this.setState({ showLoginPopup: false, errors: '' })
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {this.props.t('donthaveanaccount')}
                  </div>
                </div>
                {this.state.loginLoading ? (
                  <Spinner
                    animation='border'
                    id='spinner'
                    variant='dark'
                    role='status'
                    style={{ marginTop: '20px' }}
                  >
                    <span className='sr-only'>Loading...</span>
                  </Spinner>
                ) : (
                  <div onClick={() => this.login()} className='signup__button'>
                    {this.props.t('signin')}
                  </div>
                )}
                <div className='errors__container'>{this.state.errors}</div>
              </div>
            ) : (
              this.renderChangePasswordSteps()
            )}
          </div>
        </div> */}
        <div
          className={
            this.props.blue
              ? 'navbar_container blue'
              : this.props.white
              ? 'navbar_container white'
              : this.state.colorChange
              ? 'navbar_container scrolled'
              : 'navbar_container'
          }
        >
          <div className='navbar'>
            <NavLink className='navLink__container logo' to='/'>
              <img
                src={
                  this.props.scrolled
                    ? BlackLogo
                    : (this.state.colorChange || this.props.colorChange) &&
                      !this.props.blue
                    ? BlackLogo
                    : this.props.white
                    ? BlackLogo
                    : Logo
                }
                alt='Logo'
                height={30}
              />{' '}
            </NavLink>
            <div className='top-header-right-container'>
              {this.renderChangeLanguage()}
              <div
                className='icon'
                onClick={() =>
                  this.setState({ dropdown: !this.state.dropdown })
                }
                style={{ cursor: 'pointer' }}
              >
                <img
                  src={
                    this.props.scrolled
                      ? Bars
                      : (this.state.colorChange || this.props.colorChange) &&
                        !this.props.blue
                      ? Bars
                      : this.props.white
                      ? Bars
                      : BarsWhite
                  }
                  alt=''
                  height={17}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={this.state.dropdown ? 'dropdown active' : 'dropdown'}>
          {this.showDropdown()}
        </div>
        {this.state.openPanel ? null : (
          <div
            className='signupPopUp__container'
            style={{ visibility: this.state.dropdown ? 'visible' : 'hidden' }}
          >
            {!this.state.dropdown ? null : (
              <div className='login__container'>
                {this.props.user ? (
                  <div
                    className='left__button'
                    onClick={() => this.props.signOut()}
                  >
                    {this.props.t('signout')}
                  </div>
                ) : (
                  <NavLink
                    to={'signin'}
                    exact={true}
                    style={{ textDecoration: 'none' }}
                  >
                    <div className='left__button'>{this.props.t('signin')}</div>
                  </NavLink>
                )}
                <div
                  className='right__button'
                  onClick={() => handleAppRedirect()}
                >
                  {this.props.t('gettheapp')}
                </div>
              </div>
            )}
          </div>
        )}
        <div
          className={
            this.state.showChangePasswordModal
              ? 'translateSignUp active'
              : 'translateSignUp'
          }
        >
          <ChangePasswordModal
            customStyles={customStyles}
            showChangePasswordModal={this.state.showChangePasswordModal}
            toggleShowChangePasswordModal={this.toggleShowChangePasswordModal}
            previousPassword={this.state.password}
            email={this.props.username}
            isSmallScreen={this.state.isSmallScreen}
            toggleLoginModal={this.toggleLoginModal}
            t={this.props.t}
          />
        </div>
      </div>
    )
  }
  render() {
    if (this.props.t) {
      return this.state.isSmallScreen ? this.smallHeader() : this.bigHeader()
    } else return null
  }
}

const mapStateToProps = (state) => {
  return {
    signUpModalOpen: state.navbar.signUpModalOpen,
    username: state.navbar.username,
    timeOut: state.user.timeOut,
  }
}

const mapDispatchToProps = { setUsername, setTimeOut }

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainNavbar)
)
// export default UserDashboard
