import React, { Component } from 'react'
import '../../scss/components/RecurringBuyDialog.scss'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { Button } from 'primereact/button'
import { InputNumber } from 'primereact/inputnumber'
import { Checkbox } from 'primereact/checkbox'

export default class RecurringBuyDialog extends Component {
  state = {
    amountValue: this.props.recurBuyStatus
      ? this.props.recurBuyStatus.amount
      : null,
    frequency: 'MONTHLY',
    roundUp: false,
    selectedAccountId: null,
    formError: null,
    errorMessage: '',
    successMessage: '',
    isLoading: false,
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.accountTransactionsData !==
        this.props.accountTransactionsData &&
      this.state.isLoading
    ) {
      this.setState({ isLoading: false })
      if (prevProps.recurBuyStatus) {
        this.toggleSuccessMessage(
          this.props.t('recurringbuyupdatedsuccessfully')
        )
      } else {
        this.toggleSuccessMessage(this.props.t('recurringbuysetupsuccessfully'))
      }
    }

    if (prevProps.recurBuyStatus !== this.props.recurBuyStatus) {
      this.setState({
        amountValue: this.props.recurBuyStatus
          ? this.props.recurBuyStatus.amount
          : null,
      })
    }
  }
  toggleErrorMessage = (msg) => {
    this.setState({ errorMessage: msg })
    setTimeout(() => {
      this.setState({ errorMessage: '' })
    }, 10000)
  }

  toggleSuccessMessage = (msg) => {
    this.setState({ successMessage: msg })
    setTimeout(() => {
      this.setState({ successMessage: '' })
    }, 10000)
  }

  handleAmountChange = (value) => {
    this.setState({ amountValue: value })
  }

  handleFrequencyChange = (value) => {
    this.setState({
      frequency: value,
    })
  }

  handleRoundUpChange = (value) => {
    this.setState({
      roundUp: value,
    })
  }

  handleAccountChange = (value) => {
    this.setState({
      selectedAccountId: value,
    })
  }

  buyAmountForm() {
    return (
      <div className='buy-amount-form-container'>
        <InputNumber
          value={this.state.amountValue}
          onValueChange={(e) => this.handleAmountChange(e.value)}
          mode='currency'
          currency='USD'
          locale='en-US'
          minFractionDigits={0}
          placeholder='$0.00'
        />
      </div>
    )
  }

  frequencyForm() {
    return (
      <div className='frequency-form-container'>
        <Dropdown
          value={this.state.frequency}
          options={[
            { label: this.props.t('daily'), value: 'DAILY' },
            { label: this.props.t('weekly'), value: 'WEEKLY' },
            { label: this.props.t('biweekly'), value: 'BI-WEEKLY' },
            { label: this.props.t('monthly'), value: 'MONTHLY' },
          ]}
          onChange={(e) => this.handleFrequencyChange(e.value)}
          placeholder={this.props.t('selectfrequency')}
        />
      </div>
    )
  }

  roundUpForm() {
    return (
      <div className='roundup-form-container'>
        <Checkbox
          onChange={(e) => this.handleRoundUpChange(e.checked)}
          checked={this.state.roundUp}
          inputId='roundup'
        />
        <p className='label'>{this.props.t('roundup')}</p>
      </div>
    )
  }

  submitButton() {
    return (
      <div className='btn-container'>
        {this.state.isLoading ? (
          <Button
            className='submit-btn'
            label={this.props.t('submit')}
            loading
          />
        ) : (
          <Button
            className='submit-btn'
            label={this.props.t('submit')}
            onClick={() => {
              this.validateForm()
            }}
          />
        )}
      </div>
    )
  }

  validateForm() {
    if (this.state.amountValue > 0 || this.state.roundUp) {
      if (this.state.selectedAccountId) {
        this.submitApi()
      } else {
        this.toggleErrorMessage(this.props.t('pleasechooseabankaccount'))
      }
    } else {
      this.toggleErrorMessage(
        this.props.t('pleasechooseavaluegreaterthan0orroundupoption')
      )
    }
  }

  submitApi = async () => {
    this.setState({ isLoading: !this.state.isLoading })
    let status = await this.props.setRecBuy(
      this.props.selectedAccount.accountId,
      this.state.selectedAccountId,
      this.state.amountValue,
      this.props.selectedEditRecurBuy,
      this.state.roundUp,
      this.state.frequency
    )
    if (!status) {
      this.setState({ isLoading: !this.state.isLoading })
      this.toggleErrorMessage(this.props.t('somethingwentwrong'))
    }
  }

  selectAccountForm() {
    let accountList = []
    if (this.props.accounts.length > 0) {
      this.props.accounts.map((account, i) => {
        if (account.type === 'depository')
          accountList.push({
            label: `${account.name} ($${account.cash_account})`,
            value: account.accountId,
          })
      })
    }

    return (
      <div className='select-account-form-container'>
        <Dropdown
          value={this.state.selectedAccountId}
          options={accountList}
          onChange={(e) => this.handleAccountChange(e.value)}
          placeholder={this.props.t('selectanaccount')}
        />
      </div>
    )
  }

  resetModal = () => {
    this.props.toggleEditModal()
    this.setState({
      amountValue: this.props.recurBuyStatus
        ? this.props.recurBuyStatus.amount
        : null,
      frequency: 'MONTHLY',
      roundUp: false,
      selectedAccountId: null,
      formError: null,
      errorMessage: '',
      successMessage: '',
      isLoading: false,
    })
  }

  render() {
    if (this.props.t) {
      return (
        <div className='recurring-buy-dialog-container'>
          <Dialog
            header={`${this.props.selectedEditRecurBuy} ${this.props.t(
              'recurringbuys'
            )}`}
            visible={this.props.showEditModal}
            style={{ width: this.props.isSmallScreen ? '100vw' : '50vw' }}
            onHide={() => this.resetModal()}
            closable={!this.state.isLoading}
            closeOnEscape={!this.state.isLoading}
          >
            <div className='top-row'>
              {this.buyAmountForm()}
              {this.frequencyForm()}
            </div>
            {this.roundUpForm()}
            {this.selectAccountForm()}
            <p className='error'>{this.state.errorMessage}</p>
            <p className='success'>{this.state.successMessage}</p>
            {this.submitButton()}
          </Dialog>
        </div>
      )
    } else return null
  }
}
