import React from 'react'
import ReactHighcharts from "react-highcharts";
import ENDPOINT from './Endpoint'



export default class DdChart extends React.Component{
    state={}
    
    componentDidMount(){
        this.fetchChart()
    }

    fetchChart(){
        fetch(`${ENDPOINT}/mobile/investment-forecasting`,{
        // console.log({
            
                method:"POST",
                headers:{
                 "Access-Control-Allow-Origin": "*",
                 "Content-Type":"application/json",
                 'Accept': '*/*',
                 'User-Agent': 'python-requests/2.23.0',
                },
                body:{
                 goalData:{
                  compoundInterest: "Compound Monthly",
                  contributionAmount: undefined,
                  contributionFrequency: "Monthly",
                  inflationRate: 0.1,
                  roi: 0.0825,
                  startingAmount: 5000,
                  totalTaxRate: 0.1,
                 },
                 option:{
                     action:'calculate'
                    }
                }   
            })
          .then(res => res.json())
          .then(responseJson => console.log('chart result: ', responseJson))
    }

    render(){
        // console.log('props',this.props)
        // let drawdown = this.props.drawdownChart
        // const drawdownData = drawdown.map(ele => [ele[0], Math.abs(ele[1].toFixed(2))])
        // let pcnts = drawdownData.map(ele => ele[1])
        // let maxVal = Math.max(...pcnts)
        // let minVal = Math.min(...pcnts)
        // let maxRange = maxVal + ((maxVal - minVal) * 0.02)
        // let minRange = minVal - ((maxVal - minVal) * 0.02)
        // let lastDrawdown = drawdown[drawdown.length - 1]
        const config = {
          credits: {
            enabled: false,
          },
          chart: {
            zoomType: "x",
          },
          title: {
            text: "",
          },
          xAxis: {
            type: "datetime",
          },
          tooltip:{
            valueSuffix: '%'
          },
          yAxis: {
            title: {
              text: "Portfolio Value",
            },
            maxZoom: 150,
            
            labels: {
              format: "{value}"
            }
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            area: {
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1,
                },
                stops: [
                  [0, '#8682cb'],
                  [1, 'rgba(120, 116, 182, 0)'],
                ],
              },
              marker: {
                radius: 5,
              },
              lineColor: "#8682cb",
              lineWidth: 2,
              states: {
                hover: {
                  lineWidth: 3,
                },
              },
              threshold: null,
            },
          },
          chart:{
            // width: '100%',
            height: 200
          },
          series: [
            {
              type: "area",
              name: "Max Drawdown",
              data: [],
            },
          ],
        }; 
        return(
            <div>
                <ReactHighcharts config={config} />
            </div>
        )
    }
}