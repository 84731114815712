import { isAndroid, isIOS } from 'react-device-detect'

export const handleAppRedirect = () => {
  const androidURL =
    'intent://www.allocaterite.com/#Intent;scheme=https;package=com.forescite;end'
  // const androidURL =
  //   'intent://www.forescite.ai/open/#Intent;scheme=https;package=com.forescite;end'
  const iosURL = 'forescitedeepLink://'
  if (window.innerWidth < 1000) {
    if (isAndroid) {
      window.location.replace(androidURL)
      setTimeout(() => {
        window.location.replace(
          'https://play.google.com/store/apps/details?id=com.forescite'
        )
      }, 100)
    } else if (isIOS) {
      window.location.replace(
        'https://apps.apple.com/us/app/forescite/id1567589436'
      )
    } else {
      if (window.navigator.userAgent.toLowerCase().includes('iphone')) {
        window.open(
          'https://apps.apple.com/us/app/forescite/id1567589436',
          '_blank'
        )
      }
      if (window.navigator.userAgent.toLowerCase().includes('macintosh')) {
        window.open(
          'https://apps.apple.com/us/app/forescite/id1567589436',
          '_blank'
        )
      }
      if (window.navigator.userAgent.toLowerCase().includes('android')) {
        window.open(
          'https://play.google.com/store/apps/details?id=com.forescite',
          '_blank'
        )
      }
      if (window.navigator.userAgent.toLowerCase().includes('windows')) {
        window.open(
          'https://play.google.com/store/apps/details?id=com.forescite',
          '_blank'
        )
      }
    }
  } else {
    if (window.navigator.userAgent.toLowerCase().includes('iphone')) {
      window.open(
        'https://apps.apple.com/us/app/forescite/id1567589436',
        '_blank'
      )
    }
    if (window.navigator.userAgent.toLowerCase().includes('macintosh')) {
      window.open(
        'https://apps.apple.com/us/app/forescite/id1567589436',
        '_blank'
      )
    }
    if (window.navigator.userAgent.toLowerCase().includes('android')) {
      window.open(
        'https://play.google.com/store/apps/details?id=com.forescite',
        '_blank'
      )
    }
    if (window.navigator.userAgent.toLowerCase().includes('windows')) {
      window.open(
        'https://play.google.com/store/apps/details?id=com.forescite',
        '_blank'
      )
    }
  }
  // if (window.navigator.userAgent.toLowerCase().includes('iphone')) {
  // window.open(
  //   'https://apps.apple.com/us/app/forescite/id1567589436',
  //   '_blank'
  // )
  // }
  // if (window.navigator.userAgent.toLowerCase().includes('macintosh')) {
  //   window.open(
  //     'https://apps.apple.com/us/app/forescite/id1567589436',
  //     '_blank'
  //   )
  // }
  // if (window.navigator.userAgent.toLowerCase().includes('android')) {
  // window.open(
  //   'https://play.google.com/store/apps/details?id=com.forescite',
  //   '_blank'
  // )
  // }
  // if (window.navigator.userAgent.toLowerCase().includes('windows')) {
  //   window.open(
  //     'https://play.google.com/store/apps/details?id=com.forescite',
  //     '_blank'
  //   )
  // }
}
