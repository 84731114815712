import React from 'react'

export default function FailedContent(props) {
  return (
    <div>
      <p>
        Sorry, the request cannot be completed, please try again or{' '}
        <span className='gradient-text start-new-thread-link'>
          start a new thread
        </span>
      </p>
    </div>
  )
}
