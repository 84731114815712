//REACT
import React from 'react'
import { useEffect } from 'react'
import { useState, createContext } from 'react'

import MainNavBar from '../../components/MainNavBar'
import PublicFooter from '../../PublicFooter'

import InvestallImg from '../../../assets/partnership/investall_app.svg'

import '../../../scss/pages/ParnershipPage.scss'

export const PartnershipInfoContext = createContext()

const PartnershipInfo = (props) => {
  const [pageInfo, setPageInfo] = useState(null)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    if (props?.version === 'religious') {
      setPageInfo({
        title: 'Help your congregation become better stewards of their money. ',
        titleSmallText:
          'Tithes and offerings made simple with reminders and automated contributions.',
        firstOptionTitle: 'Get 2 months free',
        firstOptionInfo:
          'Your unique code gives your congregation free access to Investall for 2 months.',
        secondOptionTitle: 'Better planning',
        secondOptionInfo:
          'Investall uses AI to help people create a financial plan, including automatically setting aside money for tithes and offerings out of every deposit.',
        thirdOptionTitle: 'Fund the Kingdom',
        thirdOptionInfo:
          'As your congregation’s financial health improves, so will their ability to help fight the programs and services important to your congregation.',
      })
    } else if (props?.version === 'insurance') {
      setPageInfo({
        title: 'Write more policies with Investall’s personal finance.',
        titleSmallText:
          'We show your customers their insurance coverage gaps in a way that helps them naturally come to the conclusion that they should purchase or increase their coverages.',
        firstOptionTitle: 'Share',
        firstOptionInfo:
          'Your unique code gives your congregation free access to Investall for 2 months.',
        secondOptionTitle: 'Identify opportunities',
        secondOptionInfo:
          'Investall uses AI to help users plan spending and set goals. We then illustrate their financial weak spots including insurance coverage gaps.',
        thirdOptionTitle: 'Receive leads',
        thirdOptionInfo:
          'Customers who want additional insurance coverage are referred back to you.',
      })
    } else if (props?.version === 'advisor') {
      setPageInfo({
        title:
          'Automate the busy work of creating and following a financial plan.',
        titleSmallText:
          "Your time is valuable, let Investall guide your clients through the basics so you can focus on your client's high level and personal goals.",
        firstOptionTitle: 'Free to use',
        firstOptionInfo:
          'Your unique code gives your congregation free access to Investall for 2 months.',
        secondOptionTitle: 'Identify issues',
        secondOptionInfo:
          'Investall uses AI to create a spending plan, identify areas of concern, and help customers set goals for the future.',
        thirdOptionTitle: 'Earn more',
        thirdOptionInfo:
          'Take on more clients, add more value and increase your bottom line.',
      })
    }

    // Attach the event listener
    window.addEventListener('resize', handleResize)

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (props.t) {
    return (
      <>
        <MainNavBar {...props} colorChange={true} />
        <PartnershipInfoContext.Provider
          value={{
            t: props.t,
            isSmallScreen: props.isSmallScreen,
            user: props.user,
          }}
        >
          {/* <div className='parnership-bg'> */}
          <div className='parnership-page'>
            <section className='header-section'>
              <div className='info-container'>
                <p>{pageInfo?.title}</p>
                <p>{pageInfo?.titleSmallText}</p>

                {windowWidth > 1000 && (
                  <button
                    className='header-button'
                    onClick={() =>
                      window.open('https://calendly.com/investall', '_blank')
                    }
                  >
                    Book a demo
                  </button>
                )}
              </div>
              <div className='image-container'>
                <img src={InvestallImg} alt='' />
              </div>
            </section>

            {windowWidth <= 1000 && (
              <button
                className='header-button'
                onClick={() =>
                  window.open('https://calendly.com/investall', '_blank')
                }
              >
                Book a demo
              </button>
            )}
            <section className='options-section'>
              <div className='option-container'>
                <p className='option-title'>1. {pageInfo?.firstOptionTitle}</p>
                <p className='option-info'>{pageInfo?.firstOptionInfo}</p>
              </div>
              <div className='option-container'>
                <p className='option-title'>2. {pageInfo?.secondOptionTitle}</p>
                <p className='option-info'>{pageInfo?.secondOptionInfo}</p>
              </div>
              <div className='option-container'>
                <p className='option-title'>3. {pageInfo?.thirdOptionTitle}</p>
                <p className='option-info'>{pageInfo?.thirdOptionInfo}</p>
              </div>
            </section>
            <hr />
            <section className='book-section'>
              <p>Set up a 10 minute demo with our COO</p>
              <p>
                Learn more by signing up for a 10-minute demo with Ryan Ruff,
                Investall COO
              </p>
              <button
                onClick={() =>
                  window.open('https://calendly.com/investall', '_blank')
                }
              >
                Book on Calendly
              </button>
            </section>

            <hr />
          </div>
          {/* </div> */}
          <PublicFooter t={props.t} isSmallScreen={props.isSmallScreen} />
        </PartnershipInfoContext.Provider>
      </>
    )
  } else {
    return null
  }
}

export default PartnershipInfo
