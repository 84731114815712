import { Dropdown } from 'primereact/dropdown'
import { FileUpload } from 'primereact/fileupload'
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import BaseURL from './Endpoint'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Tag } from 'primereact/tag'
import '../scss/components/IBKYC.scss'
import PDFImg from '../assets/PDF_file_icon.svg.png'
import BASEURL from '../../src/components/Endpoint'
import Loader from '../../src/components/components/accountInfo/Loader'
import { Divider } from 'primereact/divider'

const IBKYC = (props) => {
  const toastErrorMsg = useRef()

  const [loading, setLoading] = useState(false)

  const [pageloading, setPageloading] = useState(true)

  const [optionsDetail, setOptionsDetail] = useState(null)
  const [selectedOptions, setSelectedOptions] = useState({})
  const [selectedOptionsDoc, setSelectedOptionsDoc] = useState({})

  // get the dynamic option from the api
  useEffect(() => {
    fetchIBDocumentsDetails()
  }, [])

  const fetchIBDocumentsDetails = async () => {
    try {
      let res = await axios.get(`${BASEURL}/ibkr/get-pending-documents`, {
        headers: {
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
        },
      })

      console.log(res?.data)

      setOptionsDetail(res?.data)
      setPageloading(false)
    } catch (error) {
      console.log(error)
    }
  }

  // submit the document for kyc
  const submitDocuments = async () => {
    setLoading(true)

    const formData = new FormData()

    const metadataObj = {}

    // formData.append('metadata', JSON.stringify(metadataObj))

    for (const [key, value] of Object.entries(selectedOptionsDoc)) {
      // if it is image, then do the image extension else if pdf do pdf extension
      if (value?.type?.includes('image')) {
        formData.append('file', value, `${key || ''}--${Date.now()}.jpeg`)
      } else if (value?.type?.includes('pdf')) {
        formData.append('file', value, `${key || ''}--${Date.now()}.pdf`)
      }

      // if you can find it is existed then it has options dropdown
      if (selectedOptions?.[key]) {
        metadataObj[key || ''] = selectedOptions?.[key]
      } else {
        metadataObj[key || ''] = ''
      }
    }
    formData.append('metadata', JSON.stringify(metadataObj))

    try {
      // let res = await axios.post(`${BaseURL}/ibkr/upload-test`, formData, {
      //   headers: {
      //     Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
      //   },
      // })

      let res = await axios.post(
        `${BaseURL}/ibkr/upload-pending-documents`,
        formData,
        {
          headers: {
            Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          },
        }
      )

      console.log(res?.data)

      if (res?.data.status === 'Endpoint is working!') {
        console.log('good to go')
        setLoading(false)
        await props?.getIBStatus()
        localStorage.setItem('isIBUserKYC', 'true')
        localStorage.setItem('userId', props?.ibInfoObj?.userID)

        props?.toggleUploaded()
        props?.closeModal()
      } else {
        console.log('failed')
        setLoading(false)
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Upload Documents failed',
          detail: 'Something wrong with data uploaded. Please try again',
          life: 3000,
        })
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Upload Documents failed',
        detail: 'Please upload the files and try again',
        life: 3000,
      })
    }
  }

  // document layout template for the uploaded
  const itemListTemplate = (docType) => (file, props) => {
    return (
      <div className='upload-content-container'>
        <div className='upload-content-part1-container'>
          <img
            alt={file.name}
            role='presentation'
            src={file.type === 'application/pdf' ? PDFImg : file.objectURL}
            width={file.type === 'application/pdf' ? 60 : 100}
          />
          <span>{file.name}</span>
        </div>
        <Tag
          value={props.formatSize}
          severity='warning'
          className='px-3 py-2'
        />
        <Button
          type='button'
          icon='pi pi-times'
          className='p-button-outlined p-button-rounded p-button-danger ml-auto'
          onClick={() => {
            setSelectedOptionsDoc({
              ...selectedOptionsDoc,
              [docType]: null,
            })
            props.onRemove()
          }}
          style={{ marginLeft: 'auto' }}
        />
      </div>
    )
  }

  // const itemAddressTemplate = (file, props) => {
  //   return (
  //     <div className='upload-content-container'>
  //       <div className='upload-content-part1-container'>
  //         {console.log(file)}
  //         <img
  //           alt={file.name}
  //           role='presentation'
  //           src={file.type === 'application/pdf' ? PDFImg : file.objectURL}
  //           width={file.type === 'application/pdf' ? 60 : 100}
  //         />
  //         <span>{file.name}</span>
  //       </div>
  //       <Tag
  //         value={props.formatSize}
  //         severity='warning'
  //         className='px-3 py-2'
  //       />
  //       <Button
  //         type='button'
  //         icon='pi pi-times'
  //         className='p-button-outlined p-button-rounded p-button-danger ml-auto'
  //         onClick={() => {
  //           setAddressDocument(null)
  //           props.onRemove()
  //         }}
  //         style={{ marginLeft: 'auto' }}
  //       />
  //     </div>
  //   )
  // }

  // const itemIdentityTemplate = (file, props) => {
  //   return (
  //     <div className='upload-content-container'>
  //       <div className='upload-content-part1-container'>
  //         <img
  //           alt={file.name}
  //           role='presentation'
  //           src={file.objectURL}
  //           width={100}
  //         />
  //         <span>{file.name}</span>
  //       </div>
  //       <Tag
  //         value={props.formatSize}
  //         severity='warning'
  //         className='px-3 py-2'
  //       />
  //       <Button
  //         type='button'
  //         icon='pi pi-times'
  //         className='p-button-outlined p-button-rounded p-button-danger '
  //         onClick={() => {
  //           setIdentityDocument(null)
  //           props.onRemove()
  //         }}
  //         style={{ marginLeft: 'auto' }}
  //       />
  //     </div>
  //   )
  // }

  // empty template when no doc is uploaded yet
  const emptyTemplate = () => {
    return (
      <div className='empty-upload-container'>
        <i className='pi pi-image'></i>
        <span className='my-5'>Please upload your document</span>
      </div>
    )
  }

  // check if the doc is more than one or not, if it is 1, then update the list, if not popup the latest one and show error message
  const uploadDocsCheck =
    (docType) =>
    async ({ files }) => {
      if (files) {
        if (files?.length > 1) {
          files.pop()
          toastErrorMsg.current.show({
            severity: 'error',
            summary: 'Upload Documents failed',
            detail: 'Please remove old files and upload new one',
            life: 3000,
          })
        } else {
          setSelectedOptionsDoc({
            ...selectedOptionsDoc,
            [docType]: files[0],
          })
        }
      }
    }

  // update the option value based on the doc_type option
  const handleOptionChange = (docType, selectedOption) => {
    setSelectedOptions({
      ...selectedOptions,
      [docType]: selectedOption,
    })
  }

  // make single array of string into object
  const transformedOptionObj = (options) => {
    let data = options?.map((option) => ({
      label: option,
      value: option,
    }))

    return data
  }

  // check if all the data is filled
  const isAllDataFilled = () => {
    // get total size for api and the length that user filled
    let totalSize = optionsDetail?.payload?.length
    let totalFilled = Object?.values(selectedOptionsDoc)?.filter(
      (value) => value !== null && value !== ''
    )?.length

    // get the dropdown length from api and user entered
    let totalDropdownCount = optionsDetail?.payload?.filter(
      (item) => item.has_options === true
    )?.length
    let totalDropdownFilled = Object?.keys(selectedOptions)?.length

    if (
      totalSize !== totalFilled ||
      totalDropdownCount !== totalDropdownFilled
    ) {
      return false
    }
    return true
  }

  return pageloading ? (
    <Loader logoLoader={true} iconHeight='65vh' />
  ) : (
    <div className='upload-documents-container'>
      {optionsDetail?.payload?.map((singleDoc, index) => {
        return (
          <div className='single-container' key={index}>
            <p>{singleDoc?.doc_type}</p>

            {singleDoc?.has_options && (
              <Dropdown
                value={selectedOptions?.[singleDoc?.doc_type]}
                name='Select category'
                onChange={(e) =>
                  handleOptionChange(singleDoc?.doc_type, e.target.value)
                }
                placeholder='Select category'
                options={transformedOptionObj(singleDoc?.options)}
              />
            )}

            {singleDoc?.is_uploaded ? (
              <p>
                <span>&#x2705;</span> Document already uploaded
              </p>
            ) : (
              <FileUpload
                customUpload={true}
                uploadHandler={uploadDocsCheck(singleDoc?.doc_type)}
                auto
                mode='advanced'
                chooseLabel={'Click to upload'}
                accept='.jpg,.png,.pdf'
                emptyTemplate={emptyTemplate}
                itemTemplate={itemListTemplate(singleDoc?.doc_type)}
              />
            )}
          </div>
        )
      })}

      <Button
        disabled={!isAllDataFilled() || loading}
        label={loading ? 'Processing' : 'Submit'}
        icon={`${loading ? 'pi pi-spin pi-spinner' : 'no-icon'}`}
        className='submit-button'
        onClick={() => {
          submitDocuments()
        }}
      />

      <Toast ref={toastErrorMsg} position='top-right' />
    </div>
  )
}

export default IBKYC
