import React, { Component } from "react";
import { Container } from "react-bootstrap";

class FundDashboard extends Component {
  render() {
    return (
      <Container>
        <iframe
          title="funds dashboard"
          className="fund-dash-chart"
          src="https://app.allocaterite.com/portfolioCalcPublic"
          frameBorder="0"
          width="100%"
          scrolling="no"
        />
      </Container>
    );
  }
}

export default FundDashboard;
