import React from 'react'
import { Carousel } from "react-bootstrap";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import TradeSummary from './TradeSummary';


const prevTrade = <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center',marginRight:20}}>
                    <FaChevronLeft color={'rgb(17,17,17)'} height={200} />
                    <p style={{margin:0, color:'rgb(17,17,17)', textAlign:'left'}}>Prev</p>
                </div>
const nextTrade = <div style={{display:'flex', justifyContent:'flex-end', alignItems:'center',marginLeft:20}}>
                    <p style={{margin:0, color:'rgb(17,17,17)', textAlign:'right'}}>Next</p>
                    <FaChevronRight color={'rgb(17,17,17)'} height={200} />
                </div>

export default class MultiTrades extends React.Component{

    createCarouselItems = ()=>{
        let mapped = []
        this.props.trades.forEach(trade=>{
            if(trade.symbol !== 'BIL'){
                mapped.push(
                    <Carousel.Item>
                      <TradeSummary
                        key={trade.symbol}
                        symbol={trade.symbol}
                        side={trade.buy? 'BUY' : 'SELL'}
                        quantity={trade.qty}
                        amount={trade.priceUSD}
                        img={trade.imageURL}
                        company={trade.company}
                        user={this.props.user}
                      />
                    </Carousel.Item>
                )
            }
        })
        return mapped
    }



    render(){
        return(
            <div style={{width:'100%'}}>
                <Carousel
                className="diversified-charts-switch"
                // nextIcon={<FaChevronRight color={'rgb(17,17,17)'} height={100} style={{marginLeft:20}}/>}
                // prevIcon={<FaChevronLeft color={'rgb(17,17,17)'} height={100} style={{marginRight:20}}/>}
                nextIcon={nextTrade}
                prevIcon={prevTrade}
                nextLabel='Next Trade'
                prevLabel='Prev Trade'
                wrap={true}
                slide={false}
                interval={999999999}
              >
               {this.createCarouselItems()}
              </Carousel>
              
            </div>
        )
    }
}