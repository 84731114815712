import React, { useEffect, useState } from 'react'
import { ReactTyped } from 'react-typed'

export default function UserPrompt({
  message,
  promptProcessing,
  loadingResMsg,
  isLast,
}) {
  const [isMsgDone, setIsMsgDone] = useState(false)

  useEffect(() => {
    if (loadingResMsg) {
      setIsMsgDone(false)
    }
  }, [loadingResMsg])
  return (
    <div className='user-prompt-container'>
      <p className='prompt-message'>{message.prompt}</p>
      {promptProcessing &&
        isLast && ( // Only show loading message if this is the last prompt
          <div className='flex gap-2 items-center text-primary relative flex-wrap text-sm'>
            {!isMsgDone && (
              <ReactTyped
                strings={[loadingResMsg]}
                showCursor={false}
                typeSpeed={20}
                onComplete={() => {
                  setIsMsgDone(true)
                }}
                style={{
                  background:
                    'linear-gradient(88deg, #3F46F6 30%, #FA00FF 70%)',
                  WebkitBackgroundClip: 'text', // Vendor prefix for WebKit browsers
                  backgroundClip: 'text',
                  color: 'transparent', // Ensure text color is transparent
                  WebkitTextFillColor: 'transparent', // Specific to WebKit browsers
                }}
              />
            )}

            {isMsgDone && (
              <div>
                <span>
                  <span
                    style={{
                      background:
                        'linear-gradient(88deg, #3F46F6 30%, #FA00FF 70%)',
                      WebkitBackgroundClip: 'text', // Vendor prefix for WebKit browsers
                      backgroundClip: 'text',
                      color: 'transparent', // Ensure text color is transparent
                      WebkitTextFillColor: 'transparent', // Specific to WebKit browsers
                    }}
                  >
                    {loadingResMsg}{' '}
                  </span>
                  <span className='loading-dots'>
                    <span className='dot'></span>
                    <span className='dot'></span>
                    <span className='dot'></span>
                  </span>
                </span>
              </div>
            )}
          </div>
        )}
    </div>
  )
}
