import React, { Component } from 'react'
import '../../../../../scss/pages/account-activation/goals/ChooseGoal.scss'
export default class ChooseGoal extends Component {
  render() {
    if (this.props.t) {
      return (
        <div className='choose-goal-container'>
          <div
            className='short-term'
            onClick={() => {
              this.props.setGoal('Short')
            }}
          >
            <p className='header'>{this.props.t('shorttermgoallabel')}</p>
            <p className='info'>{this.props.t('shorttermgoalexample')}</p>
            <p className='info'>{this.props.t('shorttermgoaldescription')}</p>
          </div>
          <div
            className='medium-term'
            onClick={() => {
              this.props.setGoal('Medium')
            }}
          >
            <p className='header'>{this.props.t('mediumtermgoallabel')}</p>
            <p className='info'>{this.props.t('mediumtermgoallabel')}</p>
            <p className='info'>{this.props.t('mediumtermgoallabel')}</p>
          </div>
          <div
            className='long-term'
            onClick={() => {
              this.props.setGoal('Long')
            }}
          >
            <p className='header'>{this.props.t('longtermgoallabel')}</p>
            <p className='info'>{this.props.t('longtermgoallabel')}</p>
            <p className='info'>{this.props.t('longtermgoallabel')}</p>
          </div>
        </div>
      )
    } else return null
  }
}
