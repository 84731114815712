import React, { Component } from 'react'
import ReactHighcharts from 'react-highcharts'
import moment from 'moment'

export default class StrategyInfoGraph extends Component {
  //ACCEPTS PROPS seriesData
  getConfig() {
    const options = { style: 'currency', currency: 'USD' }
    const numberFormat = new Intl.NumberFormat('en-US', options)
    const config = {
      credits: false,
      chart: {
        zoomType: 'x',
        backgroundColor: 'transparent',
      },
      rangeSelector: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      yAxis: {
        labels: {
          format: '{value}%',
        },
        title: false,
        gridLineColor: 'transparent',
        opposite: false,
        // max: max,
        // min: min,
      },

      tooltip: {
        // valueSuffix: "%",
        formatter: function () {
          return (
            moment.unix(this.x / 1000).format('MM/yyyy') +
            '<br/>' +
            '$' +
            this.y
          )
        },
      },
      credits: {
        enabled: false,
      },
      title: undefined,
      series: [
        {
          marker: {
            enabled: false,
          },
          color: '#3525e4',
          data: this.props.seriesData,
          tooltip: {
            valueDecimals: 2,
          },
          type: 'area',
          fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
              [0, '#3525e4'],
              [1, 'rgba(53,37,228,.1)'],
            ],
          },
        },
      ],

      xAxis: {
        tickLength: 0,
        type: 'datetime',
        startOnTick: true,
        labels: {
          formatter: function () {
            return moment(this.value).format('yyyy-MM')
          },
        },
      },
    }
    return config
  }
  render() {
    return (
      <div>
        <ReactHighcharts config={this.getConfig()} />
      </div>
    )
  }
}
