import React from 'react'
import Chart from 'react-apexcharts'

const PieChart = ({ group_data, group_reference, newt_res_data }) => {
  let pieData = newt_res_data?.['result_data']?.[group_reference]

  let lableArr = pieData?.map(
    (item) => item?.[group_data?.chart_info?.label_index]
  )
  let dataArr = pieData?.map(
    (item) => item?.[group_data?.chart_info?.data_index]
  )

  let chartOption = {
    chart: {
      animations: {
        enabled: false,
      },
    },

    labels: lableArr,
    legend: {
      show: true,
      position: 'bottom',
      itemMargin: {
        horizontal: 7,
        vertical: 5,
      },
      horizontalAlign: 'center',
    },
    dataLabels: {
      enabled: false,
    },

    tooltip: {
      // shared: true, // Enable shared tooltip
      // intersect: false, // Disable intersect tooltip
      y: {
        formatter: (value) => {
          return `${value}${group_data?.chart_info?.format}`
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '80%',
        },
      },
    },
  }

  let chartSeries = dataArr

  return (
    <>
      <Chart
        options={chartOption}
        series={chartSeries}
        type='donut'
        width='100%'
        height={400}
      />
    </>
  )
}

// export default PieChart;

export const MemoizedPieChart = React.memo(PieChart)
