import React from 'react'

export default class References extends React.Component {
    render() {
        return(
            <div>
                <h4>
                    References
                </h4>
            </div>
        )
    }
}