import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

export default class GroupCryptoInvestments extends Component {
  state = {
    showAllInvestmentList: false,
    holdingsMap: null,
  }

  componentDidMount() {
    this.getHoldingsMapping()
  }

  getHoldingsMapping = () => {
    let holdingsMap = new Map()
    this.props.selectedAccount.holdings.forEach((holding) => {
      holdingsMap.set(holding.symbol, holding)
    })
    this.setState({
      holdingsMap: holdingsMap,
    })
  }

  displayMyInvestments() {
    const holdings = this.props.productInstanceData.stocksList
    if (holdings.length > 0) {
      return (
        <div
          style={{
            width: this.props.isSmallScreen ? '100%' : '100%',
            boxShadow:
              '-3px -2px 4px rgba(255, 255, 255, 0.92), 3px 1px 5px rgba(86, 86, 86, 0.2), inset 0px 0px 2px rgba(255, 255, 255, 0.21)',
            borderRadius: '19px',
            margin: '25px 0',
          }}
        >
          <div style={{ padding: '20px' }}>
            <p
              style={{
                textAlign: 'center',
                fontFamily: 'Akkurat-Bold',
                fontSize: '15px',
              }}
            >
              {this.props.t('myinvestments')}
            </p>
            <div
              style={{ width: '100%', display: 'flex', marginBottom: '15px' }}
            >
              <div
                style={{
                  width: '20%',

                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p
                  style={{
                    color: 'rgba(128,128,128,.8)',
                    fontSize: '15px',
                    verticalAlign: 'middle',
                    marginBottom: '0',
                  }}
                >
                  {this.props.t('symbol')}
                </p>
              </div>
              <div
                style={{
                  width: '20%',

                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p
                  style={{
                    color: 'rgba(128,128,128,.8)',
                    fontSize: '15px',
                    marginBottom: '0',
                  }}
                >
                  {this.props.t('shares')}
                </p>
              </div>
              <div
                style={{
                  width: '20%',

                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p
                  style={{
                    color: 'rgba(128,128,128,.8)',
                    fontSize: '15px',
                    marginBottom: '0',
                  }}
                >
                  {this.props.t('costbasis')}
                </p>
              </div>
              <div
                style={{
                  width: '20%',

                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p
                  style={{
                    color: 'rgba(128,128,128,.8)',
                    fontSize: '15px',
                    marginBottom: '0',
                  }}
                >
                  {this.props.t('marketvalue')}
                </p>
              </div>
              <div
                style={{
                  width: '20%',

                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p
                  style={{
                    color: 'rgba(128,128,128,.8)',
                    fontSize: '15px',
                    marginBottom: '0',
                  }}
                >
                  {this.props.t('pl')}
                </p>
              </div>
            </div>
            <div style={{ maxHeight: '30vh', overflowY: 'scroll' }}>
              {' '}
              {holdings.map((stock, index) => {
                return this.renderInvestmentRow(stock)
              })}
            </div>
            {/* {holdings.length > 5 ? (
              <div>
                {this.state.showAllInvestmentList ? (
                  <div>
                    {holdings.map((stock) => {
                      return this.renderInvestmentRow(stock);
                    })}
                  </div>
                ) : (
                  <div>
                    {holdings.map((stock, index) => {
                      if (index < 5) return this.renderInvestmentRow(stock);
                    })}
                  </div>
                )}
              </div>
            ) : (
              holdings.map((stock) => {
                return this.renderInvestmentRow(stock);
              })
            )} */}
          </div>
          {/* {holdings.length > 5 ? (
            <div>
              {this.state.showAllInvestmentList ? (
                <button
                  style={{
                    width: "100%",
                    borderRadius: "0 0 25px 25px",
                    background: "black",
                    color: "grey",
                    padding: "8px",
                  }}
                  onClick={() => {
                    this.setState({ showAllInvestmentList: false });
                  }}
                >
                  Show Less
                </button>
              ) : (
                <button
                  style={{
                    width: "100%",
                    borderRadius: "0 0 25px 25px",
                    background: "black",
                    color: "grey",
                    padding: "8px",
                  }}
                  onClick={() => {
                    this.setState({ showAllInvestmentList: true });
                  }}
                >
                  Show More
                </button>
              )}
            </div>
          ) : null} */}
        </div>
      )
    } else {
      return null
    }
  }

  renderInvestmentRow(stock) {
    if (this.state.holdingsMap.has(stock)) {
      stock = this.state.holdingsMap.get(stock)
      return (
        <NavLink
          style={{ textDecoration: 'none' }}
          key={stock.symbol}
          to={`/Dashboard/Trade/${stock.symbol}`}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              borderBottom: '1px solid rgba(128,128,128,.1)',
              alignItems: 'center',
              padding: '10px 0',
            }}
          >
            <div style={{ width: '20%' }}>
              <p style={{ marginBottom: '0', color: 'black' }}>
                {stock.symbol}
              </p>
              <p
                style={{
                  fontSize: '10px',
                  color: 'rgba(128,128,128,.8)',
                  margin: 0,
                }}
              >
                {this.props.formatCurrency(stock.instPrice)}
              </p>
            </div>
            <div style={{ width: '20%' }}>
              <p style={{ color: 'black', margin: 0 }}>
                {' '}
                {stock.quantity < 1
                  ? stock.quantity.toFixed(4)
                  : stock.quantity.toFixed(2)}
              </p>
            </div>
            <div style={{ width: '20%' }}>
              <p style={{ color: 'black', margin: 0 }}>
                {this.props.formatCurrency(stock.averagePrice)}
              </p>
            </div>
            <div style={{ width: '20%' }}>
              {stock.type === 'Crypto' ? (
                <p style={{ color: 'black', margin: 0 }}>
                  {this.props.formatCurrency(stock.instValue)}
                </p>
              ) : (
                <p style={{ color: 'black', margin: 0 }}>
                  {this.props.formatCurrency(stock.instValue)}
                </p>
              )}
            </div>
            <div style={{ width: '20%' }}>
              {stock.unrealizedPL > 0 ? (
                <p
                  style={{
                    color: '#3525e4',
                    textAlign: 'left',
                    fontSize: '17px',
                    margin: 0,
                  }}
                >
                  +{this.props.formatCurrency(stock.unrealizedPL)}
                </p>
              ) : (
                <>
                  {stock.unrealizedPL < 0 ? (
                    <p
                      style={{
                        color: '#3D5062',
                        textAlign: 'left',
                        fontSize: '17px',
                        margin: 0,
                      }}
                    >
                      {this.props.formatCurrency(stock.unrealizedPL)}
                    </p>
                  ) : (
                    <p
                      style={{
                        color: 'black',
                        textAlign: 'left',
                        fontSize: '17px',
                        margin: 0,
                      }}
                    >
                      {this.props.formatCurrency(stock.unrealizedPL)}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </NavLink>
      )
    } else {
      return (
        <NavLink
          style={{ textDecoration: 'none' }}
          key={stock.symbol}
          to={`/Dashboard/Trade/${stock}`}
        >
          <div style={{ width: '100%', display: 'flex' }}>
            <div style={{ width: '20%' }}>
              <p style={{ marginBottom: '0', color: 'grey' }}>{stock}</p>
            </div>
            <div style={{ width: '20%' }}>
              <p style={{ color: 'grey' }}>-</p>
            </div>
            <div style={{ width: '20%' }}>
              <p style={{ color: 'grey' }}>-</p>
            </div>
            <div style={{ width: '20%' }}>
              {stock.type === 'Crypto' ? (
                <p style={{ color: 'grey' }}>-</p>
              ) : (
                <p style={{ color: 'grey' }}>-</p>
              )}
            </div>
            <div style={{ width: '20%' }}>
              {stock.unrealizedPL >= 0 ? (
                <p style={{ color: 'grey' }}>-</p>
              ) : (
                <p style={{ color: 'red' }}>-</p>
              )}
            </div>
          </div>
        </NavLink>
      )
    }
  }

  render() {
    if (this.props.t) {
      return (
        <div>{this.state.holdingsMap ? this.displayMyInvestments() : null}</div>
      )
    } else return null
  }
}
