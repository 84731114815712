import React, { Component } from 'react'
import PL from '../PL'
import '../../../../scss/pages/account-page/ai-crypto-account-info/GroupCryptoBasicInfo.scss'
export default class GroupCryptoBasicInfo extends Component {
  displayGroupCryptoBasicInfo() {
    const name = this.props.selectedAccount.name
      ? this.props.selectedAccount.name
      : '-'
    const totalAccountValue =
      this.props.selectedAccount.balances[0].virtualAccountValue !== null
        ? this.props.selectedAccount.balances[0].virtualAccountValue.toFixed(2)
        : '-'
    const equityValue =
      this.props.selectedAccount.balances[0].equityValue !== null
        ? this.props.selectedAccount.balances[0].equityValue.toFixed(2)
        : '-'
    const netPL =
      this.props.productInstanceData?.groupData?.groupPNL?.toFixed(2)

    return (
      <div
        style={{
          width: this.props.isSmallScreen ? '100%' : '100%',
        }}
        className='group-crypto-basic-info-container'
      >
        <p className='group-crypto-name'>
          {this.props.t('ai crypto trader')} ({this.props.selectedAccount?.name}
          )
        </p>
        <p className='group-crypto-value'>
          {this.props.formatCurrency(totalAccountValue)}
        </p>
        <div className='details-container'>
          {' '}
          <div className='pl-container'>
            <p className='pl-label'>P/L</p>
            {netPL === '-' ? (
              <p className='pl-value'>{'-'}</p>
            ) : (
              <PL formatCurrency={this.props.formatCurrency} value={netPL} />
            )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (this.props.t) {
      return <div>{this.displayGroupCryptoBasicInfo()}</div>
    } else return null
  }
}
