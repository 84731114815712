import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Amplify, { Auth } from 'aws-amplify'
import awsExports from '../../../aws-config'
import VerificationInput from 'react-verification-input'
import Spinner from 'react-bootstrap/Spinner'
import PhoneInput from 'react-phone-input-2'

import { IoIosClose } from 'react-icons/io'

import Logo from '../../../assets/newLandingPage/InvestAll_logo.png'
import ProfilePicture from '../../../assets/newLandingPage/profile.svg'
import HeroPhone from '../../../assets/newLandingPage/hero_phone.svg'
import ChooseYourGoal from '../../../assets/newLandingPage/chooseYourGoal.svg'
import YearlyPerformance from '../../../assets/newLandingPage/TenYearPerformance.svg'
import RiskIcon from '../../../assets/newLandingPage/planner.svg'
import RiskManagement from '../../../assets/newLandingPage/riskManagement.svg'
import BarGraph from '../../../assets/platform/bars-graphic@2x.png'
import DarkBlob from '../../../assets/newLandingPage/Dark3D.png'
import Swirl from '../../../assets/newLandingPage/Swirl.svg'

import Bars from '../../../assets/newLandingPage/Bars.svg'
import Modal from 'react-modal'

import Footer from '../../PublicFooter'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    overflow: 'auto',
    borderRadius: '5px',
    border: 'none',
    boxShadow: '0px 4px 23px rgba(0, 0, 0, 0.08)',
  },
}

export default class Tools extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: false,
      dropdown: false,
      showSignUpPopup: false,
      isSmallScreen: false,
      signUpModalOpen: false,
      loginModalOpen: false,
      showLoginPopup: false,
      showCode: false,
      showForgetPassword: false,
      username: '',
      errors: '',
      authCode: '',
      functionDropdown: false,
      tradeDropdown: false,
      cryptoDropdown: false,
      financialDropdown: false,
      loginLoading: false,
      loginUsername: '',
      changePasswordStep: 0,
    }
    this.topOfPage = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
    window.addEventListener('resize', this.updateWindowDimensions)
    this.updateWindowDimensions()
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.signUpModalOpen === false &&
      this.state.signUpModalOpen === true
    ) {
      document.body.style.overflow = 'hidden'
    }
    if (
      prevState.signUpModalOpen === true &&
      this.state.signUpModalOpen === false
    ) {
      document.body.style.overflow = 'unset'
      this.setState({
        showCode: false,
        errors: '',
        username: '',
        password: '',
        passwordConfirm: '',
        authCode: '',
      })
    }
    if (
      prevState.showSignUpPopup === false &&
      this.state.showSignUpPopup === true
    ) {
      document.body.style.overflow = 'hidden'
    }
    if (
      prevState.showSignUpPopup === true &&
      this.state.showSignUpPopup === false
    ) {
      document.body.style.overflow = 'unset'
      this.setState({
        showCode: false,
        errors: '',
        username: '',
        password: '',
        passwordConfirm: '',
        authCode: '',
      })
    }
    if (
      prevState.showLoginPopup === true &&
      this.state.showLoginPopup === false
    ) {
      document.body.style.overflow = 'unset'
      this.setState({
        showForgetPassword: false,
        errors: '',
        username: '',
        password: '',
      })
    }
    if (prevState.dropdown === false && this.state.dropdown === true) {
      document.body.style.overflow = 'hidden'
    }
    if (prevState.dropdown === true && this.state.dropdown === false) {
      document.body.style.overflow = 'unset'
    }
    if (
      prevState.changePasswordStep === 2 &&
      this.state.changePasswordStep === 3
    ) {
      setTimeout(() => this.setState({ showForgetPassword: false }), 5000)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
    window.removeEventListener('scroll', this.listenScrollEvent)
  }

  listenScrollEvent = () => {
    if (window.scrollY > window.screen.height - 100) {
      this.setState({ scrolled: true })
    } else {
      this.setState({ scrolled: false })
    }
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  handleAppRedirect = () => {
    // if(window.navigator.userAgent)
    console.log(window.navigator.userAgent)
    if (window.navigator.userAgent.toLowerCase().includes('iphone')) {
      window.open(
        'https://apps.apple.com/us/app/forescite/id1567589436',
        '_blank'
      )
    }
    if (window.navigator.userAgent.toLowerCase().includes('android')) {
      window.open(
        'https://play.google.com/store/apps/details?id=com.forescite',
        '_blank'
      )
    }
  }

  validateUsername = () => {
    const { username } = this.state
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    console.log('Username test', re.test(String(username).toLowerCase()))
    if (re.test(String(username).toLowerCase())) {
      return true
    } else {
      this.setState({ errors: 'Please enter a valid email' })
      return false
    }
  }
  validatePassword = () => {
    const { password } = this.state
    if (password) {
      if (password.length < 5) {
        this.setState({ errors: 'Invalid Password' })
        return false
      } else {
        return true
      }
    }
    this.setState({ errors: 'Please Enter Password(s)' })
    return false
  }
  validatePhoneNumber = () => {
    console.log('type of ', typeof this.state.phoneNumber)
    if (typeof this.state.phoneNumber === 'undefined') {
      this.setState({ errors: 'Invalid Phone Number' })
      return false
    } else {
      if (this.state.phoneNumber.length > 6) {
        return true
      }
      this.setState({ errors: 'Invalid Phone Number' })
      return false
    }
  }

  validateForm() {
    const { agree } = this.state
    if (agree) {
      return (
        this.validateUsername() &&
        this.validatePhoneNumber() &&
        this.validatePassword()
      )
    } else {
      this.setState({ errors: 'Please agree to the Privacy Policy and EULA' })
      return false
    }
  }

  confirmSignUp() {
    const { username, authCode } = this.state
    // const username = email;
    console.log('username', username)
    Auth.confirmSignUp(username, authCode)
      .then(() => {
        window.alert(
          'Sign up successful! Please Log In in the top left-hand corner.\nYou can also manage your account, using the same credentials, with your mobile device using our iOS or Android apps!'
        )
        // this.props.signIn(this.state.username, this.state.password);
        if (this.state.isSmallScreen) {
          this.setState({ showSignUpPopup: false })
        }
      })
      .catch((err) => {
        if (!err.message) {
          console.log('Error when entering confirmation code: ', err)
          window.alert('Error when entering confirmation code: ', err)
          this.setState({ authCodeText: 'Resend Code' })
        } else {
          console.log('Error when entering confirmation code: ', err.message)
          window.alert('Error when entering confirmation code: ', err.message)
          this.setState({ authCodeText: 'Resend Code' })
        }
      })
  }

  inputCode = (e, type) => {
    if (type === 'password') {
      this.setState({ passwordCode: e }, () => {
        if (this.state.passwordCode.length === 6) {
          this.setState({ changePasswordStep: 2 })
        }
      })
    } else {
      this.setState({ authCode: e }, () => {
        if (this.state.authCode.length === 6) {
          this.confirmSignUp()
        }
      })
    }
  }

  resendCode = () => {
    console.log('resend')
    Auth.resendSignUp(this.state.username)
      .then((res) => {
        console.log('resend code response: ' + JSON.stringify(res))
        window.alert('Your code has been resent to your phone number.')
      })
      .catch((error) =>
        console.log('resend code error: ' + JSON.stringify(error))
      )
  }

  nextStep = () => {
    console.log('got here')
    const { username, password, phoneNumber } = this.state
    let email = username
    const phone_number = phoneNumber
    if (this.validateForm()) {
      Auth.signUp({
        username,
        password,
        attributes: {
          email,
          phone_number: `+${phone_number}`,
        },
      })
        .then((resp) => console.log('signup response', resp)) //CATCH ERRORS
        .catch((error) => this.setState({ errors: error.message }))
        .then(() => {
          this.setState({ showCode: true })
        })
      console.log('show Code')
      this.setState({ showCode: true })
    }
  }

  displayPhoneNumber = () => {
    return (
      '+' +
      this.state.phoneNumber.substring(0, 1) +
      '-' +
      this.state.phoneNumber.substring(1, 4) +
      '-' +
      this.state.phoneNumber.substring(4, 7) +
      '-' +
      this.state.phoneNumber.substring(7, this.state.phoneNumber.length)
    )
  }

  login = async () => {
    this.setState({ loginLoading: true })
    Auth.signIn(this.state.loginUsername, this.state.password)
      .catch((error) => {
        switch (error.message) {
          case 'User does not exist.':
            error = 'Username not found'
            break
          case 'Incorrect username or password.':
            error = 'Incorrect Password'
            break
          case 'User is not confirmed.':
            error =
              'User is not confirmed. Please enter the confirmation code sent previously or request a new one.'
            this.setState({ retryUser: this.state.username })
            break
          default:
            // code block
            console.log('Not Caught Error: ' + error.message)
            break
        }
        this.setState({ errors: error, loginLoading: false })
      })
      .then(() => Auth.currentSession())
      .then((resp) => {
        let user = resp
        if (user) {
          this.props.setUser(user)
        }
      })
    // .then(() => {
    //   window.location.replace('/Dashboard')
    // })
  }

  sendChangePasswordCode = () => {
    if (this.state.email.length > 5) {
      Auth.forgotPassword(this.state.email)
        .then((resp) => {
          this.setState({
            sendCodeClicked: resp.CodeDeliveryDetails.Destination,
            changePasswordStep: 1,
            errors: false,
          })
        })
        .catch((error) => this.setState({ errors: 'email address not found' }))
    } else {
      this.setState({ errors: 'Please enter a valid email address' })
    }
  }

  submitNewPassword = () => {
    Auth.forgotPasswordSubmit(
      this.state.email,
      this.state.passwordCode,
      this.state.newPassword
    )
      .catch((err) => this.setState({ errors: err.message }))
      .then(() => this.setState({ changePasswordStep: 3 }))
  }

  renderChangePasswordSteps = () => {
    switch (this.state.changePasswordStep) {
      case 1:
        return (
          <div className='codeConfirm__container'>
            <p>
              We sent a verification code to{' '}
              <span className='bold'>{this.state.email}</span>. Please enter the
              code to verify this is your email.
            </p>
            <VerificationInput
              autoFocus
              removeDefaultStyles
              classNames={{
                container: 'verificationInput__container',
                character: 'verificationInputCharacter__container',
                characterInactive:
                  'verificationInputCharacter__container__inactive',
                characterSelected:
                  'verificationInputCharacter__container__selected',
              }}
              validChars='0-9'
              placeholder=''
              onChange={(e) => this.inputCode(e, 'password')}
              value={this.state.passwordCode}
            />
          </div>
        )
      case 2:
        return (
          <div className='codeConfirm__container'>
            <div className='container'>
              <p>Enter your new password below</p>
              <input
                name='newPassword'
                autoComplete='username'
                value={this.state.newPassword}
                className='input__line'
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              />
              <div
                className='signup__button'
                onClick={() => this.submitNewPassword()}
              >
                Change your password
              </div>
            </div>
          </div>
        )
      case 3:
        return (
          <div className='codeConfirm__container'>
            <p>
              Your password as been reset. Please log in with your new
              credentials.
            </p>
          </div>
        )
      default:
        return (
          <div className='codeConfirm__container'>
            <div className='top__container'>
              <div
                className='close__container'
                onClick={() => this.setState({ showForgetPassword: false })}
              >
                <IoIosClose className='icon' />
              </div>
            </div>
            <div className='container'>
              <p>Please enter your email down below</p>
              <input
                name='email'
                autoComplete='username'
                value={this.state.email}
                className='input__line'
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              />
              <div className='errors__container'>{this.state.errors}</div>
              <div
                className='signup__button'
                onClick={() => this.sendChangePasswordCode()}
              >
                Confirm
              </div>
            </div>
          </div>
        )
    }
  }

  handleNavigation = (link) => {
    document.body.style.overflow = 'unset'
    window.location.replace(link)
  }

  showDropdown = () => {
    return (
      <div className='navbar__dropdown__container'>
        <div className='main__container'>
          <div className='top__container'>
            <NavLink to='/' className='logo'>
              <img src={Logo} alt='' />
            </NavLink>
            <div
              className='icon'
              onClick={() => this.setState({ dropdown: false })}
            >
              <IoIosClose />
            </div>
          </div>
          <div className='bottom__container'>
            <div
              className='item__container'
              onClick={() =>
                window.open('https://www.allocaterite.ai', '_blank')
              }
            >
              Business
            </div>
            <div
              className='item__container'
              onClick={() => this.handleNavigation('/')}
            >
              Home
            </div>
            {/* <div
              className="item__container"
              onClick={() => this.handleNavigation("/products-info")}
            >
              Products
            </div> */}
            <div
              className='item__container'
              onClick={() => this.handleNavigation('/stocks-info')}
            >
              Stocks
            </div>
            <div
              className='item__container'
              onClick={() => this.handleNavigation('/crypto-info')}
            >
              Crypto
            </div>
            <div
              className='item__container'
              onClick={() => this.handleNavigation('/ai-tools-info')}
            >
              Tools
            </div>
            {this.props.user ? (
              <div
                className='item__container'
                onClick={() => this.handleNavigation('/Dashboard')}
              >
                Dashboard
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        {this.state.isSmallScreen ? (
          <>
            <div className={'navbar__container__mobile__white'}>
              <div className='navbar'>
                <NavLink to='/' className='logo'>
                  <img src={Logo} alt='' />
                </NavLink>
                <div
                  className='icon'
                  onClick={() =>
                    this.setState({ dropdown: !this.state.dropdown })
                  }
                >
                  <img src={Bars} alt='' />
                </div>
              </div>
            </div>
            <div
              className={this.state.dropdown ? 'dropdown active' : 'dropdown'}
            >
              {this.showDropdown()}
            </div>
            <div
              className={
                this.state.showSignUpPopup
                  ? 'translateSignUp active'
                  : 'translateSignUp'
              }
            >
              <div className='popup__container'>
                {!this.state.showCode ? (
                  <div className='form__container'>
                    <div className='top__container'>
                      <div
                        className='close__container'
                        onClick={() =>
                          this.setState({ showSignUpPopup: false })
                        }
                      >
                        <IoIosClose className='icon' />
                      </div>
                    </div>
                    <div className='title__container'>Create an account</div>
                    <p>Email:</p>
                    <input
                      name='username'
                      autoComplete='username'
                      value={this.state.username}
                      className='input__line'
                      onChange={(e) =>
                        this.setState({ [e.target.name]: e.target.value })
                      }
                    />
                    <p>Password:</p>
                    <input
                      name='password'
                      type='password'
                      autoComplete='new-password'
                      className='input__line'
                      onChange={(e) =>
                        this.setState({ [e.target.name]: e.target.value })
                      }
                    />
                    <p>Phone Number:</p>
                    <PhoneInput
                      containerClass='phone__input__container'
                      inputClass='phone__input'
                      country='us'
                      disableDropdown
                      onChange={(e) => this.setState({ phoneNumber: e })}
                    />
                    <div className='check__container'>
                      <input
                        onClick={() => this.setState({ agree: true })}
                        type='checkbox'
                      />
                      <span>
                        I agree to the{' '}
                        <NavLink to='/PrivacyPolicy'>Privacy Policy</NavLink> &{' '}
                        <a href='/Legal/EULA.pdf'>{this.props.t('eula')}</a>
                      </span>
                    </div>
                    <div
                      onClick={() => this.nextStep()}
                      className='signup__button'
                    >
                      Create Account
                    </div>
                  </div>
                ) : (
                  <div className='codeConfirm__container'>
                    <p>
                      We sent a verification code to{' '}
                      <span className='bold'>{this.displayPhoneNumber()}</span>.
                      Please enter the code to get started.
                    </p>
                    <VerificationInput
                      autoFocus
                      removeDefaultStyles
                      classNames={{
                        container: 'verificationInput__container',
                        character: 'verificationInputCharacter__container',
                        characterInactive:
                          'verificationInputCharacter__container__inactive',
                        characterSelected:
                          'verificationInputCharacter__container__selected',
                      }}
                      validChars='0-9'
                      placeholder=''
                      onChange={(e) => this.inputCode(e)}
                      value={this.state.authCode}
                    />
                    <p>
                      <span
                        className='resend__button'
                        onClick={() => this.resendCode()}
                      >
                        Didn't get the code?
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div
              className={
                this.state.showLoginPopup
                  ? 'translateSignUp active'
                  : 'translateSignUp'
              }
            >
              <div className='popup__container'>
                {!this.state.showForgetPassword ? (
                  <div className='form__container'>
                    <div className='top__container'>
                      <div
                        className='close__container'
                        onClick={() => this.setState({ showLoginPopup: false })}
                      >
                        <IoIosClose className='icon' />
                      </div>
                    </div>
                    <div className='title__container'>Log In</div>
                    <p>Email:</p>
                    <input
                      name='loginUsername'
                      autoComplete='username'
                      value={this.state.loginUsername}
                      className='input__line'
                      onChange={(e) =>
                        this.setState({ [e.target.name]: e.target.value })
                      }
                    />
                    <p>Password:</p>
                    <input
                      name='password'
                      type='password'
                      autoComplete='new-password'
                      className='input__line'
                      onChange={(e) =>
                        this.setState({ [e.target.name]: e.target.value })
                      }
                    />
                    <div className='check__container'>
                      <div
                        onClick={() => {
                          this.setState({ showForgetPassword: true })
                        }}
                      >
                        Forgot your password?
                      </div>
                      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                        •
                      </div>
                      <div
                        onClick={() => {
                          this.setState({ showSignUpPopup: true }, () => {
                            setTimeout(
                              () =>
                                this.setState({
                                  //   showSignUpPopup: true,
                                  showLoginPopup: false,
                                }),
                              250
                            )
                          })
                        }}
                      >
                        Don't have an account?
                      </div>
                    </div>
                    {this.state.loginLoading ? (
                      <Spinner
                        animation='border'
                        id='spinner'
                        variant='dark'
                        role='status'
                        style={{ marginTop: '20px' }}
                      >
                        <span className='sr-only'>Loading...</span>
                      </Spinner>
                    ) : (
                      <div
                        onClick={() => this.login()}
                        className='signup__button'
                      >
                        Log In
                      </div>
                    )}
                    <div className='errors__container'>{this.state.errors}</div>
                  </div>
                ) : (
                  this.renderChangePasswordSteps()
                )}
              </div>
            </div>
            {/* <div className="signup">
                  <div className="left">Start your Robo Trader today.</div>
                  <div
                    onClick={() => this.handleAppRedirect()}
                    className="app__button"
                  >
                    Get the App
                  </div>
                </div> */}
            <div className='signupPopUp__container'>
              {!this.state.dropdown ? null : (
                <div className='login__container'>
                  {this.props.user ? (
                    <div
                      className='left__button'
                      onClick={() => this.props.signOut()}
                    >
                      Sign Out
                    </div>
                  ) : (
                    <div
                      className='left__button'
                      onClick={() => this.setState({ showLoginPopup: true })}
                    >
                      Log In
                    </div>
                  )}
                  <div
                    className='right__button'
                    onClick={() => this.handleAppRedirect()}
                  >
                    Get Investall
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className='navbar__container__background__white'>
              <div className='navbar__container'>
                <NavLink className='navLink__container' to='/'>
                  <img src={Logo} alt='Logo' />
                </NavLink>
                <div className='left__container'>
                  <div className='menu__container'>
                    <div
                      className='title__container'
                      onMouseEnter={() =>
                        this.setState({ functionDropdown: true })
                      }
                      onMouseLeave={() =>
                        this.setState({ functionDropdown: false })
                      }
                    >
                      <NavLink
                        to='/products-info'
                        className={
                          this.state.functionDropdown
                            ? 'highlight active'
                            : 'highlight'
                        }
                      >
                        Products
                      </NavLink>
                    </div>
                    <div
                      className='title__container'
                      onMouseEnter={() =>
                        this.setState({ tradeDropdown: true })
                      }
                      onMouseLeave={() =>
                        this.setState({ tradeDropdown: false })
                      }
                    >
                      <NavLink
                        to='/stocks-info'
                        className={
                          this.state.tradeDropdown
                            ? 'highlight active'
                            : 'highlight'
                        }
                      >
                        Stocks
                      </NavLink>
                    </div>
                    <div
                      className='title__container'
                      onMouseEnter={() =>
                        this.setState({ cryptoDropdown: true })
                      }
                      onMouseLeave={() =>
                        this.setState({ cryptoDropdown: false })
                      }
                    >
                      <NavLink
                        to='/crypto-info'
                        className={
                          this.state.cryptoDropdown
                            ? 'highlight active'
                            : 'highlight'
                        }
                      >
                        Crypto
                      </NavLink>
                    </div>
                    <div
                      className='title__container'
                      onMouseEnter={() =>
                        this.setState({ finantialDropdown: true })
                      }
                      onMouseLeave={() =>
                        this.setState({ finantialDropdown: false })
                      }
                    >
                      <NavLink
                        to='/ai-tools-info'
                        className={
                          this.state.finantialDropdown
                            ? 'highlight active'
                            : 'highlight'
                        }
                      >
                        AI Tools
                      </NavLink>
                    </div>
                  </div>
                </div>
                {this.props.user ? (
                  <NavLink className='navLink__container' to='/Dashboard'>
                    <img src={ProfilePicture} alt='profile' />
                  </NavLink>
                ) : (
                  <div
                    className='navLink__container'
                    onClick={() => this.setState({ loginModalOpen: true })}
                  >
                    <img src={ProfilePicture} alt='profile' />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div className='navbar__container__background__white'>
          <div className='navbar__container'>
            <NavLink className='navLink__container' to='/'>
              {/* <img src={Logo} alt="Logo" /> */}
              <p className='logo-text'>Investall</p>
            </NavLink>
            <div className='left__container'>
              <div className='menu__container'>
                {/* <div
                  className="title__container"
                  onMouseEnter={() => this.setState({ functionDropdown: true })}
                  onMouseLeave={() =>
                    this.setState({ functionDropdown: false })
                  }
                >
                  <NavLink
                    to="/products-info"
                    className={
                      this.state.functionDropdown
                        ? "highlight active"
                        : "highlight"
                    }
                  >
                    Products
                  </NavLink>
                </div> */}
                <div
                  className='title__container'
                  onMouseEnter={() => this.setState({ tradeDropdown: true })}
                  onMouseLeave={() => this.setState({ tradeDropdown: false })}
                >
                  <NavLink
                    to='/stocks-info'
                    className={
                      this.state.tradeDropdown
                        ? 'highlight active'
                        : 'highlight'
                    }
                  >
                    Stocks
                  </NavLink>
                </div>
                <div
                  className='title__container'
                  onMouseEnter={() => this.setState({ cryptoDropdown: true })}
                  onMouseLeave={() => this.setState({ cryptoDropdown: false })}
                >
                  <NavLink
                    to='/crypto-info'
                    className={
                      this.state.cryptoDropdown
                        ? 'highlight active'
                        : 'highlight'
                    }
                  >
                    Crypto
                  </NavLink>
                </div>
                <div
                  className='title__container'
                  onMouseEnter={() =>
                    this.setState({ finantialDropdown: true })
                  }
                  onMouseLeave={() =>
                    this.setState({ finantialDropdown: false })
                  }
                >
                  <NavLink
                    to='/ai-tools-info'
                    className={
                      this.state.finantialDropdown
                        ? 'highlight active'
                        : 'highlight'
                    }
                  >
                    AI Tools
                  </NavLink>
                </div>
              </div>
            </div>
            {this.props.user ? (
              <NavLink className='navLink__container' to='/Dashboard'>
                <img src={ProfilePicture} alt='profile' />
              </NavLink>
            ) : (
              <div
                className='navLink__container'
                onClick={() => this.setState({ loginModalOpen: true })}
              >
                <img src={ProfilePicture} alt='profile' />
              </div>
            )}
          </div>
        </div>

        {/* PAGE CONTENT START */}

        {this.state.isSmallScreen ? (
          <>
            <div className='info__container__mobile darkBackground'>
              <div className='image__container'>
                <img src={DarkBlob} alt='' />
              </div>
              {/* <p className="subtitle__text">AI TOOLS</p> */}
              <p className='title__text'>Where will Bitcoin go next?</p>
              <p>
                Get access to state-of-the-art price trend predictions of
                thousands of stocks and cryptos worldwide. Add your favorites to
                a watchlist for automated price trend notifications.
              </p>
            </div>
            <div className='info__container__mobile'>
              <div className='image__container' style={{ marginBottom: 50 }}>
                <img src={BarGraph} alt='' />
              </div>
              <p className='title__text'>Tax Harvesting & Optimization</p>
              <ul>
                <li>Utilize cost basis of the portfolio</li>
                <li>Minimize capital gains & maximize returns</li>
                <li>Optimize performance & minimize tax liability</li>
              </ul>
            </div>
            <div className='info__container__mobile gray'>
              <div className='image__container' style={{ marginBottom: 50 }}>
                <img src={RiskManagement} alt='' />
              </div>
              <p className='title__text'>Risk Management</p>
              <p>
                Our AI provides proactive risk monitoring and disciplined
                execution of investment decisions. Keeping you informed about
                your portfolio every step of the way.
              </p>
            </div>
            <div className='info__container__mobile'>
              <div className='image__container' style={{ marginBottom: 50 }}>
                <img src={YearlyPerformance} alt='' />
              </div>
              <p className='title__text'>Technical Analysis</p>
              <p>
                We can forecast the statistics such as rate of change, RSI,
                moving average, and others using the past market action.
              </p>
            </div>
            <div className='info__container__mobile blackBackground'>
              <div className='image__container'>
                <img src={Swirl} alt='' />
              </div>
              <p className='subtitle__text'>Round Up</p>
              <p className='title__text'>
                Let’s get you some cash you didn’t know you had
              </p>
              <p>
                Round up your spare change to automatically invest in stocks you
                like with reoccurring buy
              </p>
            </div>
          </>
        ) : (
          <>
            <div className='infoSection__reg dark'>
              <div className='largeScreenWrapper__container'>
                <div className='fullRow__container even'>
                  <div className='text__container'>
                    <div className='wrapper__container white'>
                      <p className='subtitle__text'>AI TOOLS</p>
                      <p className='title__text'>Where will Bitcoin go next?</p>
                      <p>
                        Get access to state-of-the-art price trend predictions
                        of thousands of stocks and cryptos worldwide. Add your
                        favorites to a watchlist for automated price trend
                        notifications.
                      </p>
                    </div>
                  </div>
                  <div className='image__container'>
                    <img src={DarkBlob} alt='' />
                  </div>
                </div>
              </div>
            </div>
            <div className='infoSection__small'>
              <div className='largeScreenWrapper__container'>
                <div
                  className={
                    this.state.isSmallScreen
                      ? 'fullRow__container even'
                      : 'fullRow__container smallLeft'
                  }
                >
                  {!this.state.isSmallScreen && (
                    <div className='image__container'>
                      <img src={BarGraph} alt='' style={{ width: '400px' }} />
                    </div>
                  )}
                  <div className='text__container'>
                    <div className='wrapper__container'>
                      <p className='title__text'>
                        Tax Harvesting & Optimization
                      </p>
                      <ul>
                        <li>Utilize cost basis of the portfolio</li>
                        <li>Minimize capital gains & maximize returns</li>
                        <li>Optimize performance & minimize tax liability</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="infoSection__small">
          <div className="largeScreenWrapper__container">
            <div className="fullRow__container even">
              <div className="small__wrapper">
                <div className="image__container">
                  <img src={RiskIcon} alt=""></img>
                </div>
                <div className="text__container">
                  <div className="wrapper__container">
                    <p className="subtitle__text">AI TOOLS</p>
                    <p className="title__text">Auto Calibration</p>
                    <p>
                      Get our most advanced trading strategies, with GIPS
                      audited track record of outperforming other top
                      robo-advisors.
                    </p>
                    <p>Dynamic asset management</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
            <div className='infoSection__small gray'>
              <div className='largeScreenWrapper__container'>
                <div className='fullRow__container even'>
                  <div className='small__wrapper'>
                    <div className='text__container'>
                      <div className='wrapper__container'>
                        {/* <p className="subtitle__text">AI TOOLS</p> */}
                        <p className='title__text'>Risk Management</p>
                        <p>
                          Our AI provides proactive risk monitoring and
                          disciplined execution of investment decisions. Keeping
                          you informed about your portfolio every step of the
                          way.
                        </p>
                      </div>
                    </div>
                    {!this.state.isSmallScreen && (
                      <div className='image__container'>
                        <img src={RiskManagement} alt=''></img>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='infoSection__reg'>
              <div className='largeScreenWrapper__container'>
                <div
                  className={
                    this.state.isSmallScreen
                      ? 'fullRow__container even'
                      : 'fullRow__container smallLeft'
                  }
                >
                  <div className='image__container'>
                    <img src={YearlyPerformance} alt='' />
                  </div>
                  <div className='text__container'>
                    <div className='wrapper__container'>
                      <p className='title__text'>Technical Analysis</p>
                      <p>
                        We can forecast the statistics such as rate of change,
                        RSI, moving average, and others using the past market
                        action.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='infoSection__reg black'>
              <div className='largeScreenWrapper__container'>
                <div className='fullRow__container even'>
                  <div className='text__container'>
                    <div className='wrapper__container white'>
                      <p className='subtitle__text'>Round Up</p>
                      <p className='title__text'>
                        Let’s get you some cash you didn’t know you had
                      </p>
                      <p>
                        Round up your spare change to automatically invest in
                        stocks you like with reoccurring buy
                      </p>
                    </div>
                  </div>
                  <div className='image__container'>
                    <img src={Swirl} alt='' />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* PAGE CONTENT END */}
        <Modal
          style={customStyles}
          isOpen={this.state.signUpModalOpen}
          ariaHideApp={false}
          onRequestClose={() => this.setState({ signUpModalOpen: false })}
        >
          <div
            className='modal__container'
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            <div className='top__container'>
              Create an Account
              <IoIosClose
                className='icon'
                onClick={() => this.setState({ signUpModalOpen: false })}
              />
            </div>
            {!this.state.showCode ? (
              <div className='form__container'>
                <p>Email:</p>
                <input
                  name='username'
                  autoComplete='username'
                  value={this.state.username}
                  className='input__line'
                  onChange={(e) =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                />
                <p>Phone Number:</p>
                <PhoneInput
                  containerClass='phone__input__container'
                  inputClass='phone__input'
                  disableDropdown
                  country={'us'}
                  onChange={(e) => this.setState({ phoneNumber: e })}
                />
                <p>Password:</p>
                <input
                  name='password'
                  type='password'
                  autoComplete='new-password'
                  value={this.state.password}
                  className='input__line'
                  onChange={(e) =>
                    this.setState({ [e.target.name]: e.target.value })
                  }
                />
                <div className='check__container'>
                  <input
                    type='checkbox'
                    onClick={() => this.setState({ agree: true })}
                  />
                  <span>
                    I agree to the{' '}
                    <NavLink to='/PrivacyPolicy'>Privacy Policy</NavLink> &{' '}
                    <a href='/Legal/EULA.pdf'>{this.props.t('eula')}</a>
                  </span>
                </div>
                <div onClick={() => this.nextStep()} className='signup__button'>
                  Create Account
                </div>
                <div className='errors__container'>{this.state.errors}</div>
              </div>
            ) : (
              <div className='codeConfirm__container'>
                <p>
                  We sent a verification code to{' '}
                  <span className='bold'>{this.displayPhoneNumber()}</span>.
                  Please enter the code to get started.
                </p>
                <VerificationInput
                  autoFocus
                  removeDefaultStyles
                  classNames={{
                    container: 'verificationInput__container',
                    character: 'verificationInputCharacter__container',
                    characterInactive:
                      'verificationInputCharacter__container__inactive',
                    characterSelected:
                      'verificationInputCharacter__container__selected',
                  }}
                  validChars='0-9'
                  placeholder=''
                  onChange={(e) => this.inputCode(e)}
                  value={this.state.authCode}
                />
                <p>
                  <span
                    className='resend__button'
                    onClick={() => this.resendCode()}
                  >
                    Didn't get the code?
                  </span>
                </p>
              </div>
            )}
          </div>
        </Modal>
        {/* Login Modal */}
        <Modal
          style={customStyles}
          isOpen={this.state.loginModalOpen}
          ariaHideApp={false}
          onRequestClose={() => this.setState({ loginModalOpen: false })}
        >
          <div
            className='modal__container'
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            <div className='top__container'>
              Log In
              <IoIosClose
                className='icon'
                onClick={() => this.setState({ loginModalOpen: false })}
              />
            </div>
            <div className='form__container'>
              <p>Email:</p>
              <input
                name='loginUsername'
                autoComplete='username'
                value={this.state.loginUsername}
                className='input__line'
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              />
              <p>Password:</p>
              <input
                name='password'
                type='password'
                autoComplete='new-password'
                value={this.state.password}
                className='input__line'
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              />
              {this.state.loginLoading ? (
                <Spinner
                  animation='border'
                  id='spinner'
                  variant='dark'
                  role='status'
                  style={{ marginTop: '20px' }}
                >
                  <span className='sr-only'>Loading...</span>
                </Spinner>
              ) : (
                <div onClick={() => this.login()} className='login__button__2'>
                  Log In
                </div>
              )}
              <div className='errors__container'>{this.state.errors}</div>
            </div>
          </div>
        </Modal>
        <Footer t={this.props.t} isSmallScreen={this.state.isSmallScreen} />
      </>
    )
  }
}
