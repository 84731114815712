import React, { useEffect, useState } from 'react'
import PriceTrendPredictor from '../dashboard/PriceTrendPredictor2'
import UADisplay from '../UADisplay'
import { Card, Row } from 'react-bootstrap'
import { TabPanel, TabView } from 'primereact/tabview'
import MobileWl from '../dashboard/mobileWl'
import ScreenerPage from './ScreenerPage'
import '../../scss/pages/ExplorerPage.scss'

const Explorer = (props) => {
  const [activeTab, setActiveTab] = useState(0)

  // unusual activity part
  const UnusualActivityComponent = () => {
    let stocks = props?.unusualActivity.payload
    let options = props?.unusualActivity.payload_options

    const [displayList, setDisplayList] = useState('UAStocks')

    const [activeTab, setActiveTab] = useState(0)
    const [editMode, setEditMode] = useState(false)

    // refresh the data every 5 secs
    useEffect(() => {
      let refreshIntervalRef = setInterval(() => refreshWl(), 5000)
      return () => {
        clearInterval(refreshIntervalRef)
      }
    }, [])

    const refreshWl = () => {
      console.log('refreshing wl')
      // props?.refreshWl()
      props?.refreshUA()
    }

    let mappedStocks = null
    let mappedOptions = null

    let handleTabChange = (val) => {
      if (val == 0) {
        setActiveTab(val)
        setDisplayList('UAStocks')
        //  this.setState({ activeTab: val, displayList:  })
      } else {
        setActiveTab(val)
        setDisplayList('UAOptions')
        //  this.setState({ activeTab: val, displayList: 'UAOptions' })
      }
    }

    if (stocks) {
      mappedStocks = stocks.map((ele) => {
        return <UADisplay data={ele} type={displayList} />
      })
    }
    if (options) {
      mappedOptions = options.map((ele, index) => {
        return <UADisplay data={ele} type={displayList} key={index} />
      })
    }
    return (
      <Card
        style={{
          width: '100%',
          // marginTop: 20,
          border: 'none',
          // backgroundColor: '#f6f6f6',
        }}
      >
        <div className='ua-content'>
          {' '}
          <TabView
            activeIndex={activeTab}
            onTabChange={(e) => handleTabChange(e.index)}
          >
            <TabPanel header={props?.t('stocks')}>
              {' '}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  padding: '0rem 2%',
                  margin: props?.isSmallScreen ? '' : 'auto',
                }}
              >
                {/* {props?.isSmallScreen ? (
                  stocks ? (
                    <MobileWl
                      remove={props?.removeFromWl}
                      editMode={editMode}
                      data={stocks}
                      t={props?.t}
                    />
                  ) : null
                ) : ( */}
                <div
                  className='mapped'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    overflow: 'auto',
                  }}
                >
                  <div
                    style={{
                      // width: '100%',
                      display: 'flex',
                      color: '#272748',
                      width: '800px',
                      // overflow: 'auto',
                    }}
                  >
                    {/* <div style={{ flex: 0.65 }}></div> */}
                    <p
                      style={{
                        flex: 1,
                        textAlign: 'left',
                        fontSize: 15,
                        fontFamily: 'Akkurat-Light',
                        color: '#272748',
                      }}
                    >
                      {props?.t('symbol')}
                    </p>
                    <p
                      style={{
                        flex: 1,
                        textAlign: 'center',
                        fontSize: 15,
                        fontFamily: 'Akkurat-Light',
                        color: '#272748',
                      }}
                    >
                      {props?.t('company')}
                    </p>
                    <p
                      style={{
                        flex: 1,
                        textAlign: 'center',
                        fontSize: 15,
                        fontFamily: 'Akkurat-Light',
                        color: '#272748',
                      }}
                    >
                      {props?.t('changeinprice')}
                    </p>
                    <p
                      style={{
                        flex: 1,
                        textAlign: 'center',
                        fontSize: 15,
                        fontFamily: 'Akkurat-Light',
                        color: '#272748',
                      }}
                    >
                      {props?.t('volumechange')}
                    </p>
                  </div>

                  <div
                    style={{
                      width: '800px',
                      maxHeight: '800px',
                      overflow: 'auto',
                    }}
                  >
                    {mappedStocks}
                  </div>
                </div>
                {/* )} */}
              </div>
            </TabPanel>
            <TabPanel header={props?.t('options')}>
              {' '}
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  padding: '0rem 2%',
                  margin: props?.isSmallScreen ? '' : 'auto',
                }}
              >
                {/* {props?.isSmallScreen ? (
                  options ? (
                    <MobileWl
                      remove={props?.removeFromWl}
                      editMode={editMode}
                      data={options}
                      t={props?.t}
                    />
                  ) : null
                ) : ( */}
                <div
                  className='mapped'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      maxWidth: 1280,
                    }}
                  >
                    <p
                      style={{
                        flex: 2,
                        textAlign: 'left',
                        fontSize: 15,
                        color: 'rgb(39, 39, 72)',
                        fontFamily: 'Akkurat-Light',
                      }}
                    >
                      {/* {props?.t("symbol")} */}
                      {props?.t('unusual')} {props?.t('options')}
                    </p>
                    <p
                      style={{
                        flex: 1,
                        textAlign: 'center',
                        fontSize: 15,
                        color: 'rgb(39, 39, 72)',
                        fontFamily: 'Akkurat-Light',
                      }}
                    >
                      {props?.t('volume')}
                    </p>
                    <p
                      style={{
                        flex: 1,
                        textAlign: 'center',
                        fontSize: 15,
                        color: 'rgb(39, 39, 72)',
                        fontFamily: 'Akkurat-Light',
                      }}
                    >
                      {props?.t('volumechange')}
                    </p>
                  </div>
                  {mappedOptions}
                </div>
                {/* )} */}
              </div>
            </TabPanel>
          </TabView>
        </div>
      </Card>
    )
  }

  return (
    <div className='main-body-container'>
      <TabView
        activeIndex={activeTab}
        onTabChange={(e) => setActiveTab(e.index)}
      >
        {/* tab 1 */}
        <TabPanel header={props.t('Sectors')}>
          <div>
            <PriceTrendPredictor
              userType={props?.userType}
              t={props?.t}
              presetTickers={props?.presetTickers}
              dash={false}
              isSmallScreen={props?.isSmallScreen}
              formatCurrency={props?.formatCurrency}
            />
          </div>
        </TabPanel>
        {/* tab 2 */}
        <TabPanel header={props.t('Unusual Activity')}>
          <div>
            <UnusualActivityComponent />
          </div>
        </TabPanel>

        {/* tab 3 */}
        <TabPanel header={props.t('Stock Screener')}>
          <ScreenerPage />
        </TabPanel>
      </TabView>
    </div>
  )
}

export default Explorer
