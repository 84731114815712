import { createSlice } from '@reduxjs/toolkit'

export const TestSlice = createSlice({
  name: 'navbar',
  initialState: {
    testname: 'honly',
  },
  reducers: {
    setUsername: (state, action) => {
      return {
        ...state,
        testname: action.payload,
      }
    },
  },
})

export const { setUsername } = TestSlice.actions

export default TestSlice.reducer
