import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';

class StrategiesTitle extends Component {
    render() {
        return (
            <Container className="strategies-title-container">
                <Row><p className="strategies-title">{this.props.value.title}</p></Row>
                <Row><p className="strategies-subtitle">{this.props.value.subtitle}</p></Row>
            </Container>
        )
    }
}

export default StrategiesTitle
