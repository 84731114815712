import React from 'react'

export default function StockNews({ groupReference, newtResData }) {
  const newsData = newtResData?.result_data?.[groupReference]

  if (!newsData?.url) {
    return null
  }

  const { url, title, text, site, published_date, image_url } = newsData

  const dateToMDY = (date) => {
    // Assuming date is in ISO format and converting it to MM/DD/YYYY format
    const d = new Date(date)
    return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`
  }

  return (
    <div className='news-container'>
      <div className='news-content'>
        <a
          href={url}
          target='_blank'
          rel='noopener noreferrer'
          className='news-link'
        >
          <p className='news-title'>{title}</p>
          <p className='news-text'>{text}</p>
        </a>
        <div className='news-info'>
          <p className='news-site'>{site}</p>
          <p className='news-date'>{dateToMDY(published_date)}</p>
        </div>
      </div>

      {image_url && (
        <a
          className='news-image-link'
          href={url}
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src={image_url}
            alt={`${groupReference}_img`}
            className='news-image'
          />
        </a>
      )}

      <div className='news-info-lg'>
        <p className='news-site'>{site}</p>
        <p className='news-date'>{dateToMDY(published_date)}</p>
      </div>
    </div>
  )
}
