import React, { Component } from 'react'
import Footer from '../PublicFooter'
import Header from '../PublicHeader3'
import { Container, Row } from 'react-bootstrap'

export default class CryptoAgreement extends Component {
  state = {
    isSmallScreen: false,
  }

  componentDidMount() {
    // window.addEventListener("scroll", this.listenScrollEvent);
    window.addEventListener('resize', this.updateWindowDimensions)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
    // window.removeEventListener("scroll", this.listenScrollEvent);
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }
  render() {
    return (
      <div style={{ width: '100%' }}>
        <Header
          isSmallScreen={this.state.isSmallScreen}
          user={this.props.user}
          signOut={this.props.signOut}
        />
        <div style={{ width: '100%' }}>
          {/* <FooterPageTitle title="GENERAL DISCLAIMER" /> */}
          <div
            style={{
              display: 'flex',
              width: '100%',
              backgroundColor: 'rgb(237,237,237)',
              marginTop: 75,
              justifyContent: 'space-between',
              paddingLeft: '20%',
              paddingRight: '20%',
              paddingTop: 40,
              paddingBottom: 40,
              alignItems: 'center',
            }}
          >
            <p style={{ margin: 0, fontSize: 40, fontWeight: 'bold' }}>
              Apex Crypto User Agreement
            </p>
          </div>
          <Container style={{ width: '95%', margin: 'auto' }}>
            <Row className='terms-top-paragraph'>
              <p className='terms-text'>Last Updated: June 25, 2019</p>
              <p className='terms-text'>
                This user account agreement ("User Agreement") sets forth the
                respective rights and obligations between you, Apex Clearing
                Corporation ("Apex Clearing"), and Apex Crypto LLC, a limited
                liability company formed under the laws of Delaware ("Apex
                Crypto," "we," or "us"). Your brokerage firm shall be referred
                to as the "Correspondent" hereunder but shall not be considered
                a party to this User Agreement. Please read through this User
                Agreement carefully before registering an account and accessing
                or using the Services (as defined herein). BY REGISTERING AN
                ACCOUNT WITH APEX CRYPTO ("Account"), OR BY ACCESSING OR USING
                OUR SERVICES, YOU AGREE TO BE LEGALLY BOUND TO THE TERMS AND
                CONDITIONS CONTAINED IN THIS USER AGREEMENT IN THEIR ENTIRETY,
                INCLUDING ALL TERMS INCORPORATED BY REFERENCE. IF YOU DO NOT
                AGREE TO ANY OF THESE TERMS AND CONDITIONS, INCLUDING THE
                MANDATORY ARBITRATION PROVISION AND CLASS ACTION WAIVER IN
                SECTION 17, AND THE MANDATORY INFORMATION SHARING PROVISION IN
                SECTION 5.1, DO NOT REGISTER AN ACCOUNT OR ACCESS OR USE THE
                SERVICES.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                1. Scope of Services
              </h3>
              <p className='terms-text'>
                This User Agreement applies to your access to and use of our
                Services, as described herein. By registering an Account with
                Apex Crypto, in accordance with the terms and conditions
                contained in this User Agreement, you will be able to: (i) enter
                into transactions with Apex Crypto through your Correspondent's
                mobile application or website(s) (the "Platform") to purchase
                supported cryptocurrencies for personal, non-commercial
                purposes; (ii) enter into transactions with Apex Crypto through
                the Platform to sell cryptocurrencies that you previously
                purchased from Apex Crypto (purchase and sell transactions are
                referred to herein, collectively, as "Cryptocurrency
                Transactions"); (iii) store cryptocurrency that you acquired
                through Cryptocurrency Transactions in one or more hosted
                cryptocurrency wallets ("Hosted Wallets"); and (iv) track your
                Cryptocurrency Transactions and Hosted Wallet balance(s) through
                the Platform, (collectively, the "Services"). You agree,
                represent and warrant that the Services constitute financial and
                investment transactions and that no consumer transactions are
                contemplated by or permitted under this User Agreement.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                2. Modifications to this User Agreement
              </h3>
              <p className='terms-text'>
                We reserve the right to make changes to this User Agreement at
                any time and at our sole discretion. If we make changes, we will
                post the amended User Agreement to apexcrypto.com and update the
                "Last Updated" date above. We may also attempt to notify you by
                sending an email notice to the address associated with your
                Account or by providing notice through the Platform. Using a
                particular form of notice in some instances does not obligate us
                to use the same form in other instances. Unless we say otherwise
                in our notice, the amended User Agreement will be effective
                immediately and will apply to any then current and subsequent
                uses of the Services, including any pending Cryptocurrency
                Transactions. You are responsible for reviewing this User
                Agreement each time you access or use our Services. Your
                continued access to and use of your Account and/or the Services
                after we provide notice will constitute your acceptance of the
                changes. If you do not agree to any of the amended terms and
                conditions, you must stop accessing and using the Services and
                close your Account immediately.
              </p>
              <div>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  3. Eligibility
                </h3>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  3.1 General Requirements
                </h3>
              </div>
              <p className='terms-text'>
                To register for an Account or access or use the Services you
                must be an individual at least 18 years of age and have the
                legal capacity to enter into this User Agreement. In addition,
                you must have a brokerage account, in good standing, held at
                Apex Clearing ("Brokerage Account").
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                3.2 General Requirements
              </h3>
              <p className='terms-text'>
                You may not register an Account or use the Services if you are a
                resident of any state in which Apex Crypto is not authorized to
                provide the Services ("Restricted States"). A current list of
                Restricted States, which is incorporated by reference, is
                available at apexcrypto.com and is subject to change at any
                time.
              </p>
              <p className='terms-text'>
                You hereby represent and warrant that you are not a resident of
                any Restricted State and that you will not register an Account
                or use the Services even if our methods to prevent you from
                registering an account or using the Services are not effective
                or can be bypassed.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                3.3 Restricted States
              </h3>
              <p className='terms-text'>
                You may not register an Account or access or use the Services if
                you are located in, under the control of, or a citizen or
                resident of any state, country, territory or other jurisdiction
                to which the United States has embargoed goods or services, or
                where your use of the Services would be illegal or otherwise
                violate any applicable law of such jurisdiction or of the United
                States ("Restricted Jurisdiction"). We may implement controls to
                restrict access to the Services from any Restricted
                Jurisdiction.{' '}
              </p>
              <p className='terms-text'>
                You hereby represent and warrant that you are not under the
                control of, or a citizen or resident of any Restricted
                Jurisdiction and that you will not access or use any Services
                while located in any Restricted Jurisdiction, even if our
                methods to prevent access to and use of the Services from these
                jurisdictions are not effective or can be bypassed.{' '}
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                3.4 Restricted Persons
              </h3>
              <p className='terms-text'>
                You hereby represent and warrant that you have not been
                identified as a Specially Designated National or placed on any
                sanctions list by the U.S. Treasury Department's Office of
                Foreign Assets Control, the U.S. Commerce Department, or the
                U.S. Department of State; and you will not use our Services to
                conduct any illegal or illicit activity.
              </p>
              <div>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  4. Account Registration
                </h3>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  4.1 Personal Information
                </h3>
              </div>
              <p className='terms-text'>
                During the Account registration process you must provide us with
                information and documentation that we request for the purpose of
                establishing and verifying your identity ("Personal
                Information"). In addition, as a condition to accessing and
                using the Services, you must authorize Apex Clearing to share
                your Personal Information with us pursuant to Section 5.1.
                Personal Information may include your name, email address,
                residential address, phone number, date of birth, and taxpayer
                identification number. Personal Information may also include
                documentation, such as copies of your government-issued photo
                identification (for example, your passport, driver's license, or
                military identification card), account statements, and other
                documents as we may require. Personal Information will be
                retained by us at our discretion and in accordance with
                applicable laws. You agree to provide us accurate, current and
                complete Personal Information.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                4.2 Verification
              </h3>
              <p className='terms-text'>
                You hereby authorize us, or a third-party service provider that
                we designate, to take any measures that we consider necessary to
                confirm the Personal Information you provide, verify and
                authenticate your Personal Information, and take any action we
                deem necessary based on the results. You acknowledge that this
                process may result in a delay in registering your Account, and
                that you will not be authorized to access or use the Services
                until your Account registration has been successfully completed.
              </p>
              <div>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  5. Account Authorizations
                </h3>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  5.1 Mandatory Information Sharing
                </h3>
              </div>
              <p className='terms-text'>
                You hereby authorize your Correspondent and Apex Clearing to
                provide Apex Crypto any of your personal information maintained
                in connection with your Brokerage Account, and to continue
                sharing such information, and any revisions or additions
                thereto, with Apex Crypto on an ongoing basis until your Account
                is either closed or terminated in accordance with this User
                Agreement. You hereby authorize Apex Crypto to provide your
                Correspondent and Apex Clearing any of your Personal
                Information, and to continue sharing such information, and any
                revisions or additions thereto, with them on an ongoing basis
                until your account(s) with your Correspondent or Apex Clearing
                is closed pursuant to the terms and conditions governing such
                account(s).
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                5.2 Authorization to Apex Crypto
              </h3>
              <p className='terms-text'>
                You understand and acknowledge that your Account is
                self-directed, and you hereby appoint Apex Crypto as your agent
                for the purpose of carrying out your instructions in accordance
                with this User Agreement. You hereby authorize Apex Crypto to
                open and close your Account, place and cancel orders to purchase
                and sell cryptocurrency ("Orders"), collect and offset any fees
                or other amounts due to Apex Crypto, and take such other steps
                as are reasonable to carry out your instructions.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                5.3 Authorization to Apex Clearing
              </h3>
              <p className='terms-text'>
                You understand and acknowledge that when you submit an Order
                through the Platform to purchase cryptocurrency, you are
                authorizing and instructing Apex Crypto to instruct Apex
                Clearing to accept such instruction to transfer the funds
                required for the Cryptocurrency Transaction from your Brokerage
                Account to either (i) an account held at Apex Clearing in the
                name of Apex Crypto; or (ii) an external bank account in the
                name of Apex Crypto, (each such account, an "Entity Account").
                You understand and acknowledge that your Account is provided by
                Apex Crypto and is separate from your Brokerage Account. You
                further understand and acknowledge that Apex Crypto is not under
                the control of Apex Clearing, that any Entity Account is outside
                of Apex Clearing's possession and control, and that Apex
                Clearing does not have the ability to monitor or recall the
                funds after such funds have been wired or transferred to an
                Entity Account. You further understand and acknowledge that,
                upon transfer from your Brokerage Account, the funds will no
                longer be provided protection under the Federal Deposit
                Insurance Corporation ("FDIC") or the Securities Investor
                Protector Corporation ("SIPC").
              </p>
              <p className='terms-text'>
                You agree to hold Apex Clearing harmless with respect to Apex
                Clearing accepting and following your instructions to transfer
                funds from your Brokerage Account to an Entity Account. You
                hereby authorize and instruct Apex Clearing to accept all
                instructions to deposit funds into your Brokerage Account from
                an Entity Account at the sole instruction of Apex Crypto. You
                understand that you may revoke this authorization at any time by
                terminating your Apex Crypto Account.
              </p>
              <p className='terms-text'>
                You understand and acknowledge that cryptocurrencies in your
                Apex Crypto Account are not held at Apex Clearing and that Apex
                Clearing is not involved in the purchase, sale, execution,
                custody, or trading of cryptocurrencies, and only takes
                instructions regarding transferring funds as described in this
                User Agreement.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                6. Use of Margin is Strictly Prohibited
              </h3>
              <div>
                <p className='terms-text'>
                  Apex Crypto does not provide margin trading, and margin
                  trading is not including as part of the Services.
                </p>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  7. Account Management
                </h3>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  7.1 Account Access
                </h3>
              </div>
              <p className='terms-text'>
                Once you have successfully registered an Account, you will be
                able to access your Account by logging into the Platform using
                the username and password associated with the Platform
                (collectively, "Login Credentials"). You may only access your
                Account using your Login Credentials, or as may be authorized or
                required by us and your Correspondent. From time to time, Apex
                Crypto or your Correspondent may require you to change your
                Login Credentials and re-verify your Personal Information at
                their discretion and as a condition to your continued access to
                and use of your Account and the Services.
              </p>
              <p className='terms-text'>
                You hereby represent and warrant that you will not share your
                Login Credentials with any third-party or permit any third-party
                to gain access to your Account, and you hereby assume
                responsibility for any instructions, orders, or actions provided
                or taken by anyone who has accessed your Account regardless of
                whether the access was authorized or unauthorized.
              </p>
              <p className='terms-text'>
                Only individuals that have successfully registered an Account
                ("Users") are authorized to access and use the Services.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                7.2 Account Maintenance
              </h3>
              <p className='terms-text'>
                You are required to maintain the information associated with
                your Account ("Account Profile") updated at all times, and you
                agree to update your account profile immediately of any changes
                to Personal Information that you previously provided. YOU
                UNDERSTAND THAT A CHANGE IN YOUR STATE OF RESIDENCE MAY IMPACT
                YOUR ELIGIBILITY TO ACCESS AND USE THE SERVICES, AND HEREBY
                AGREE TO NOTIFY US IN ADVANCE OF ANY CHANGE IN YOUR STATE OF
                RESIDENCE. PLEASE REFER TO OUR LIST OF RESTRICTED STATES, WHICH
                IS AVAILABLE at apexcrypto.com.
              </p>
              <p className='terms-text'>
                As part of our legal compliance program ("AML Program"), we will
                monitor your Account and your use of the Services, and review
                your Personal Information on an ongoing basis, as may be
                required by law and/or pursuant to our internal policies and
                procedures. At any time, we may require you to provide us with
                additional Personal Information as a condition to your continued
                access to and use of your Account and the Services. During such
                time, your access to and use of your Account and the Services
                may be temporarily restricted.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                7.3 Account Security
              </h3>
              <p className='terms-text'>
                You are solely responsible for managing and maintaining the
                security of your Login Credentials and any other forms of
                authentication, and you understand and acknowledge that we are
                not responsible (and you will not hold us responsible) for any
                unauthorized access to and or use of your Login Credentials
                and/or Account ("Unauthorized Access").
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                7.4 Unauthorized Account Activity
              </h3>
              <p className='terms-text'>
                You are solely responsible for monitoring your Account for
                unauthorized or suspicious instructions, orders, actions, or
                transactions ("Unauthorized Activity"), and agree that we are
                not responsible (and you will not hold us responsible) for any
                Unauthorized Activity.
              </p>
              <p className='terms-text'>
                You agree to protect your Account from Unauthorized Activity by
                (i) reviewing, on an ongoing basis, your transaction history and
                your Account Profile; (ii) reviewing, immediately upon receipt,
                any Confirmations, confirmations and notices we provide through
                the Platform, post to your Account or send to the email address
                associated with your Account; and (iii) verifying that you
                receive a confirmation from us that an Order or instruction you
                provide was received, executed or cancelled, as applicable, and
                (iv) in the event of not having received any such confirmation,
                notifying us within twenty-four (24) hours of such Order or
                instruction.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                7.5 Potentially Fraudulent Activity
              </h3>
              <p className='terms-text'>
                Any actual or suspected Unauthorized Access and/or Unauthorized
                Activity will be treated by Apex Crypto as potentially
                fraudulent ("Potentially Fraudulent Activity"). You agree to
                notify us within twenty-four (24) hours if you become aware of
                or suspect any Potentially Fraudulent Activity, by emailing
                AccountSecurity@apexcrypto.com. For avoidance of doubt, you are
                deemed to be aware of Potentially Fraudulent Activity upon
                receipt of any notice of the occurrence of such activity. Upon
                receipt of written notice from you any Potentially Fraudulent
                Activity, we will take reasonable steps to protect your Account,
                including, for example, by temporarily restricting access to
                your Account, suspending any pending Cryptocurrency
                Transactions, and/or requiring you to change your Login
                Credentials.
              </p>
              <p className='terms-text'>
                You agree to promptly report any Potentially Fraudulent Activity
                to legal authorities and provide us a copy of any report
                prepared by such legal authorities. In the event of an
                investigation of any Potentially Fraudulent Activity, you
                further agree to (i) cooperate fully with the legal authorities
                and Apex Crypto in such investigation; (ii) complete any
                required affidavits promptly, accurately and thoroughly; and
                (iii) allow Apex Crypto, or any third-party designated by us,
                access to your mobile device, computer, and network as may be
                relevant to such investigation. You understand and acknowledge
                that any failure to cooperate in any such investigation may
                cause delays in regaining access to your Account and any
                cryptocurrency held in your Hosted Wallet(s).
              </p>
              <div>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  8. Account Closure
                </h3>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  8.1 Account Closure
                </h3>
              </div>
              <p className='terms-text'>
                You may close your Account at any time and for any reason unless
                Apex Crypto believes, at our sole discretion, that such closure
                is being performed in an effort to evade an investigation.
                Closing an Account will not affect any rights or obligations
                incurred prior to the date of closure in accordance with this
                User Agreement. Prior to closing your Account, you must
                liquidate any cryptocurrency balance(s) maintained in your
                Hosted Wallet(s), the proceeds of which shall be transferred to
                your Brokerage Account.{' '}
              </p>
              <p className='terms-text'>
                You understand and acknowledge that you are solely responsible
                for any fees, costs, expenses, charges or obligations
                (collectively, "Costs") associated with the closing of your
                Account. In the event any incurred Costs exceed the value of
                your Account, you understand and acknowledge that you are
                responsible for reimbursing Apex Crypto the value of such Costs
                and that you will remain liable to Apex Crypto for all
                obligations incurred in your Account, pursuant to this User
                Agreement, or otherwise, whether arising before or after the
                closure of your Account or the termination of this User
                Agreement.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                8.2 Account Suspension or Termination
              </h3>
              <p className='terms-text'>
                By using the Services, you understand and acknowledge that we
                have the right, at our sole discretion, to immediately suspend
                or terminate your Account and/or freeze any cryptocurrency
                balance(s) in your Hosted Wallet(s), without prior notice if (i)
                we suspect, at our sole discretion, you to be in violation of
                any provision of this User Agreement, our AML Program, or any
                applicable laws or regulations; (ii) we are required to do so by
                law or by any regulatory authority, court order, facially valid
                subpoena, or binding order of a government authority; (iii) we
                suspect any suspicious or Unauthorized Activity or any actual or
                attempted unauthorized access to your Account or Login
                Credentials; (iv) your account with the correspondent has been
                suspended or terminated or you no longer have access to the
                Platform; or (v) the Account has not been accessed in two years
                or more. You must notify Apex Crypto if you intend to, or
                actually do, close your account with Correspondent.
              </p>
              <p className='terms-text'>
                If your Account has been suspended or terminated, you will be
                notified when you attempt to access the Services. We may, at our
                sole discretion and as permitted by law, provide written notice
                that your Account has been suspended or terminated.
              </p>
              <p className='terms-text'>
                Upon termination of your Account, unless prohibited by law, any
                remaining cryptocurrency balance(s) in your Hosted Wallet(s) may
                be liquidated and the proceeds, less any Costs, will be returned
                to your Brokerage Account, at the discretion of Apex Crypto. In
                the event your Account is terminated, you hereby authorize Apex
                Crypto to sell any such remaining cryptocurrency balance(s) in
                your Hosted Wallet(s) at the prevailing market price(s), and
                transfer the proceeds to your Brokerage Account, less any Costs
                associated with such sale(s), the Account, or its termination.
              </p>
              <div>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  9. Cryptocurrencies
                </h3>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  9.1 Definition
                </h3>
              </div>
              <p className='terms-text'>
                As used herein, "cryptocurrency" (also referred to as "virtual
                currency," "digital currency," "digital asset," and "digital
                commodity") means a digital representation of value which is
                based on a cryptographic protocol that can be digitally traded
                and functions as: (1) a medium of exchange; and/or (2) a unit of
                account; and/or (3) a store of value, but does not have legal
                tender status and is not issued or guaranteed by any government.
                Cryptocurrency is distinguished from "fiat currency" (such as
                U.S. dollars), which is the coin and paper money of a country
                that is designated as its legal tender. For more information,
                please refer to the FATF Report, Virtual Currencies, Key
                Definitions and Potential AML/CFT Risks, FINANCIAL ACTION TASK
                FORCE (June 2014), available here.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                9.2 Supported Cryptocurrencies
              </h3>
              <p className='terms-text'>
                A list of cryptocurrencies that Apex Crypto currently supports
                is available via the Platform. Apex Crypto may delist a
                cryptocurrency, at any time and for any reason at our sole
                discretion, including due to changes in a given cryptocurrency's
                characteristics after Apex Crypto has listed the cryptocurrency
                or due to a change in the cryptocurrency's regulatory
                classification.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                9.3 Network Control
              </h3>
              <p className='terms-text'>
                Apex Crypto does not own or control any of the underlying
                software through which blockchain networks are formed and
                cryptocurrencies are created and transacted. In general, the
                underlying software for blockchain networks tends to be open
                source such that anyone can use, copy, modify, and distribute
                it. By using the Services, you understand and acknowledge that
                Apex Crypto is not responsible for operation of the underlying
                software and networks that support cryptocurrencies and that
                Apex Crypto makes no guarantee of functionality, security, or
                availability of such software and networks.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                9.4 Forks
              </h3>
              <p className='terms-text'>
                The underlying protocols are subject to sudden changes in
                operating rules, and third parties may from time to time create
                a copy of a cryptocurrency network and implement changes in
                operating rules or other features ("Forks") that may result in
                more than one version of a network (each, a "Forked Network")
                and more than one version of a cryptocurrency ("Forked Assets").
              </p>
              <p className='terms-text'>
                You understand and acknowledge that Forked Networks and the
                available supply of any Forked Assets are wholly outside of the
                control of Apex Crypto, and you further acknowledge that our
                ability to deliver Forked Assets resulting from a Forked Network
                may depend on third-parties that are outside of our control. You
                understand and acknowledge that Forks may materially affect the
                value, function, and/or even the name of the cryptocurrencies
                you store in your Hosted Wallet(s). In the event of a Fork, you
                understand and acknowledge that Apex Crypto may temporarily
                suspend the Services (with or without advance notice to you)
                while we determine, at our sole discretion, which Forked
                Network(s) to support.
              </p>
              <p className='terms-text'>
                YOU ACKNOWLEDGE AND HEREBY AGREE THAT APEX CRYPTO MAY DETERMINE,
                IN OUR SOLE DISCRETION, NOT TO SUPPORT A FORKED NETWORK AND THAT
                YOU HAVE NO RIGHT, CLAIM, OR OTHER PRIVILEGE TO FORKED ASSETS ON
                SUCH UNSUPPORTED FORKED NETWORK. YOU HEREBY FURTHER AGREE THAT
                APEX CRYPTO MAY, IN OUR SOLE DISCRETION, DETERMINE THE APPROACH
                TO SUCH FORKED ASSETS, WHICH MAY INCLUDE ABANDONING OR OTHERWISE
                ELECTING NOT TO SUPPORT SUCH FORKED ASSETS AS PART OF THE
                SERVICES. YOU UNDERSTAND AND HEREBY ACKNOWLEDGE THAT APEX CRYPTO
                IS UNLIKELY TO SUPPORT MOST FORKED NETWORKS AND THAT MOST FORKED
                ASSETS WILL LIKELY NOT BE MADE AVAILABLE TO YOU.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                9.5 Risks of Cryptocurrency Trading
              </h3>
              <p className='terms-text'>
                There are several risks associated with cryptocurrencies and
                cryptocurrency trading. By accessing and using the Services, you
                hereby represent and warrant that you have read our
                Cryptocurrency Risk Disclosure available at
                apexcrypto.com\\legal, which is incorporated by reference.
              </p>
              <div>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  10. Cryptocurrency Transactions
                </h3>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  10.1 Market Orders
                </h3>
              </div>
              <p className='terms-text'>
                Orders to purchase or sell cryptocurrency at the prevailing
                market price ("Market Orders") are based on the prevailing
                market price at the time the Market Order is filled. You
                understand and acknowledge that the price at which your Market
                Order is filled may vary from the price displayed at the time
                your Market Order is placed, and you further understand and
                acknowledge that Apex Crypto may, at its sole discretion, cancel
                any Market Order if the prevailing market price changes
                significantly from the time the Market Order was placed. You
                further understand and acknowledge that Apex Crypto may, at its
                sole discretion, cancel any Market Order. Apex Crypto, or Apex
                Crypto through your Correspondent, will send you a notification
                upon the occurrence of a cancellation of any Market Order.{' '}
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                10.2 Order Placement
              </h3>
              <p className='terms-text'>
                Orders are placed on the Platform through your Account using
                your Login Credentials. You understand that all Orders to
                purchase or sell cryptocurrency will be executed in reliance on
                your promise that an actual purchase or sale is intended, and
                that Apex Crypto requires full payment in cleared, non-margined
                funds at the time an Order is filled. You further understand and
                acknowledge that you are not permitted to place an Order to
                purchase cryptocurrency unless you have enough non-margined
                funds available in your Brokerage Account, and that you are not
                permitted to place an Order to sell cryptocurrency unless you
                have enough cryptocurrency available in your Apex Crypto
                Account.
              </p>
              <p className='terms-text'>
                You understand and acknowledge that Apex Crypto does not
                guarantee that an Order you place will be filled, and you
                further understand and acknowledge that Apex Crypto reserves the
                right to cancel any Order or part of an Order, if such Order (i)
                was placed during a scheduled or unscheduled downtime of Apex
                Crypto, Apex Clearing, or your Correspondent, (ii) violates the
                terms of this User Agreement, or (iii) is non-marketable.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                10.3 Order Routing
              </h3>
              <p className='terms-text'>
                All Orders will be routed to us by or through your
                Correspondent. By using the Services, you understand and
                acknowledge that all Orders routed to us by or through your
                Correspondent which are placed through your Account using your
                Login Credentials are your Orders, and you agree to be bound by
                all terms and conditions pertaining to such Orders in accordance
                with this User Agreement.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                10.4 Source of Funds; Destination of Proceeds
              </h3>
              <p className='terms-text'>
                Apex Crypto only authorizes the purchase of cryptocurrency using
                non-margined funds maintained in your Brokerage Account. You
                understand and acknowledge that you are not allowed to use funds
                that are not owned by you to enter into Cryptocurrency
                Transactions, and that you are not authorized to enter into
                Cryptocurrency Transactions on behalf of any third party. You
                hereby represent and warrant that all funds used by you to enter
                into Cryptocurrency Transactions (presently and in the future)
                are owned by you and are not the direct or indirect proceeds of
                any criminal or fraudulent activity.
              </p>
              <p className='terms-text'>
                You understand and acknowledge that any proceeds from the sale
                of cryptocurrency shall be returned to your Brokerage Account,
                and that you will not have the option to transfer proceeds to
                any other account, including an account that you own.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                10.5 Transaction Limits
              </h3>
              <p className='terms-text'>
                You understand and acknowledge that your use of the Services is
                subject to a limit on volume (in USD terms) that you may
                transact in a given time period. Such limits may vary, at our
                sole discretion, depending on a variety of factors, and we may
                apply higher limits to certain Users at our discretion.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                10.6 Transaction Record
              </h3>
              <p className='terms-text'>
                When your Order is filled, Apex Crypto, or your Correspondent on
                behalf of Apex Crypto, will send your confirmation (a
                "Confirmation") through the Platform or to the email address
                associated with your Account. We may choose, at our sole
                discretion and without prior notice to you, to periodically
                consolidate Orders into a single Confirmation. Confirmations
                contain the following information: (i) Cryptocurrency
                Transaction date; (ii) Order type; (iii) trading pair; (iv)
                amount and U.S. dollar value of cryptocurrency purchased or
                sold; (v) price information; (vi) fees; (vii) a unique Order
                identification number; and (viii) any other information we may
                elect to include at our sole discretion or as required by law.
              </p>
              <p className='terms-text'>
                A record of your Cryptocurrency Transactions ("Transaction
                History") conducted during the preceding twelve (12) months is
                available through your Account on the Platform. Transaction
                History that is older than twelve (12) months may be requested
                by emailing apexcrypto.com or by accessing your monthly
                statements on the Platform.
              </p>
              <div>
                <p className='terms-text'>
                  Apex Crypto maintains records of all Cryptocurrency
                  Transactions for a period as required by law.
                </p>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  10.7 Right to Offset
                </h3>
              </div>
              <p className='terms-text'>
                In the event that you fail to provide sufficient funds at the
                time an Order is filled, Apex Crypto reserves the right, in our
                sole discretion, to cancel, reverse or fill such Order in whole
                or in part. You agree to be responsible for the costs of any
                such action taken by Apex Crypto. You understand and acknowledge
                that if a failure to provide sufficient funds results in an
                amount due and owing by you to Apex Crypto (a "Negative
                Balance"), Apex Crypto shall have the right to (i) charge a
                reasonable rate of interest on any such Negative Balance; (ii)
                offset any such Negative Balance, including any accrued interest
                and consequential losses, by transferring funds that are or
                become available in your Brokerage Account to Apex Crypto.
              </p>
              <div>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  10.8 Cancellations
                </h3>
                <p className='terms-text'>
                  Orders may not be cancelled after they are filled.
                </p>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  10.9 Errors
                </h3>
              </div>
              <p className='terms-text'>
                You are solely responsible for reviewing your Confirmations and
                for monitoring your Transaction History for any errors (or any
                Potentially Fraudulent Activity, as outlined in Section 7.5).
                You understand and acknowledge that if you have not received a
                Confirmation or an Order cancellation notice, you are
                responsible for notifying us via email at support@apexcrypto.com
                within twenty-four (24) hours of placing the Order. You further
                understand and acknowledge that you are solely responsible for
                reviewing your Confirmations for accuracy and for notifying us
                of any suspected errors via email at support@apexcrypto.com
                within twenty-four (24) hours of the time any such Confirmation
                was sent. You hereby waive your right to dispute any
                Cryptocurrency Transaction unless you notify us of any
                objections within twenty-four (24) hours of the time in which
                the applicable Confirmation was sent.
              </p>
              <p className='terms-text'>
                You understand and acknowledge that Apex Crypto reserves the
                right, but has no obligation, to declare null and void a
                Cryptocurrency Transaction that Apex Crypto considers to be
                erroneous. A Cryptocurrency Transaction may be deemed erroneous
                when its price is substantially inconsistent with the prevailing
                price at the time the Order is filled (each, an "Erroneous
                Transaction"). You understand and acknowledge that you are
                responsible for ensuring that the appropriate price and Order
                type is submitted when you place an Order to purchase or sell
                cryptocurrency with Apex Crypto, and that a simple assertion by
                you that a mistake was made in entering an Order, or that you
                failed to pay attention to or update an Order, shall not be
                sufficient to establish it as an Erroneous Transaction. You
                further understand and acknowledge that if Apex Crypto
                determines that a given Cryptocurrency Transaction is an
                Erroneous Transaction, Apex Crypto may, at our sole discretion,
                declare it null and void, in whole and in part, even if you do
                not agree to cancel or modify it, in which case you shall return
                the cryptocurrency or funds received in the Erroneous
                Transaction.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                10.10 Downtime
              </h3>
              <p className='terms-text'>
                Apex Crypto, Apex Clearing, and your Correspondent use
                commercially reasonable efforts to provide the Services in a
                reliable and secure manner. From time to time, interruptions,
                errors, delays, or other deficiencies in providing the Services
                may occur due to a variety of factors, some of which are outside
                of Apex Crypto's control, and some which may require or result
                in scheduled maintenance or unscheduled downtime of the Platform
                or the Services (collectively, "Downtime").
              </p>
              <p className='terms-text'>
                You understand and acknowledge that part or all of the Platform
                or Services may be unavailable during any such period of
                Downtime, and you acknowledge that Apex Crypto is not liable or
                responsible to you for any inconvenience or losses to you as a
                result of Downtime. Following Downtime, you further understand
                and acknowledge that the prevailing market prices of
                cryptocurrency may differ significantly from the prices prior to
                such downtime.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                11. Hosted Wallets
              </h3>
              <p className='terms-text'>
                Cryptocurrencies that you purchase from Apex Crypto shall be
                stored as part of the Services in one or more omnibus Hosted
                Wallets. Apex Crypto shall track the balance and ownership of
                cryptocurrencies purchased through the Services and stored in
                Hosted Wallets, and you understand that you can view the balance
                of cryptocurrencies in your Account through the Platform.
              </p>
              <div>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  12. General Terms of Service{' '}
                </h3>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  12.1 Fees
                </h3>
              </div>
              <p className='terms-text'>
                A schedule of our fees is accessible by logging into the
                Platform. Fees are subject to change, at our sole discretion,
                and you understand and acknowledge that you are solely
                responsible for accessing the fee schedule prior to placing an
                Order. You hereby acknowledge that your placement of an Order
                will constitute your acceptance of the fee published at the time
                the Order is placed.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                12.2 Self-Directed Account
              </h3>
              <p className='terms-text'>
                You understand and acknowledge that your Account is
                self-directed, you are solely responsible for any and all Orders
                placed through your Account, and all Orders entered by you are
                unsolicited and based on your own investment decisions. You
                understand and acknowledge that you have not received and do not
                expect to receive any investment advice from Apex Crypto or any
                of its Affiliates (including Apex Clearing) in connection with
                your Orders. Notwithstanding anything in this User Agreement,
                you understand and acknowledge that Apex Crypto accepts no
                responsibility whatsoever for, and shall in no circumstances be
                liable to you in connection with, your decisions. You further
                understand and acknowledge that under no circumstances will your
                use of the Services be deemed to create a relationship that
                includes the provision or tendering of investment advice. You
                acknowledge that neither Apex Crypto, nor any of our Affiliates
                (including Apex Clearing), nor any of our or our Affiliates'
                employees, agents, principals, or representatives (i) provide
                investment advice in connection with this Account, (ii)
                recommend any cryptocurrencies, Cryptocurrency Transactions, or
                Orders, or (iii) solicit Orders. To the extent research
                materials or similar information are available through the
                Platform, I understand that these materials are intended for
                informational and educational purposes only and they do not
                constitute a recommendation to enter into any Cryptocurrency
                Transactions or to engage in any investment strategies.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                12.3 Communications
              </h3>
              <p className='terms-text'>
                You understand and acknowledge that all notices and
                communication with you will be through the Platform, your
                Account, or via an email sent by us, or by your Correspondent on
                behalf of us, to the email address associated with your Account.
                To ensure that you receive all of our communications, you agree
                to keep your email address up-to-date and immediately notify us
                if there are any changes. Delivery of any communication to the
                email address associated with your Account is considered valid.
                If any email communication is returned as undeliverable, we
                retain the right to block your access to your Account until you
                provide and confirm a new and valid email address.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                12.4 Taxes
              </h3>
              <p className='terms-text'>
                You understand and acknowledge that Apex Crypto does not provide
                tax or legal advice. You further understand and acknowledge that
                Apex Crypto will report Cryptocurrency Transactions and the
                proceeds from cryptocurrency sales to the Internal Revenue
                Service to the extent and manner in which so required by law.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                12.4.1 U.S. Persons
              </h3>
              <p className='terms-text'>
                This subsection is applicable if you are a U.S. person
                (including a U.S. resident alien) as such term is defined in
                section 7701(a) of the Internal Revenue Code of 1986, as amended
                ("U.S. Person"). Under penalties of perjury, you hereby certify
                that the taxpayer identification number that you have provided
                or will provide to Apex Crypto in connection with your Account
                (including any taxpayer identification number on any Form W-9
                that you have provided or will provide) is your correct taxpayer
                identification number.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                12.4.2 Non-U.S. Persons
              </h3>
              <p className='terms-text'>
                This subsection is applicable if you are not a U.S. Person. You
                hereby certify that you fully understand all the information on
                any Form W-8BEN that you have submitted or will submit to Apex
                Crypto in connection with your Account. Under penalties of
                perjury, you declare that (i) you have examined all the
                information on any Form W-8BEN that you have submitted or will
                submit to Apex Crypto and (ii) to the best of your knowledge and
                belief all such information is true, correct, and complete.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                13. Privacy Policy
              </h3>
              <p className='terms-text'>
                Please refer to your Correspondent's Privacy Policy for
                information about how your Correspondent collects, uses and
                discloses information about you when you engage with the
                Platform.
              </p>
              <p className='terms-text'>
                Please refer to Apex Crypto's Privacy Policy at apexcrypto.com
                for information about how we collect, use and disclose
                information about you when you visit our website.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                14. Remedies for Breach; Remedies in General
              </h3>
              <p className='terms-text'>
                In the event that you or your officers, managers, partners,
                directors, Affiliates, employees, independent agents or
                contractors breach any of your representations, warranties,
                agreements or covenants set forth in this Agreement or otherwise
                fail to comply with the requirements of this Agreement, any
                policies of Apex Crypto, Apex Clearing or the terms of service
                set forth on our website, including without limitation, giving
                Apex Crypto inaccurate or incomplete information for any reason,
                Apex Crypto will have the right to terminate this Agreement and
                to close your Account. Apex Crypto will also have the right to
                restrict your transactions by refusing to either accept or
                execute any transactions for your Account. You are liable for
                all damages suffered by Apex Crypto and its Affiliates resulting
                from any such breach by you or your officers, managers,
                partners, directors, Affiliates, employees, independent agents
                or contractors. Further, in the event of such breach, Apex
                Crypto will have the rights of a secured creditor under all
                applicable laws with respect to your Account and can (i) recover
                all damages incurred by Apex Crypto and its Affiliates by
                selling any or all of the cryptocurrencies held in your hosted
                wallet. Apex Crypto has sole discretion over what actions, if
                any, it takes in the event of such breach and may take action
                without prior notice to you and/or (ii) recover against your
                accounts with your Correspondent. If a breach by you or your
                officers, managers, partners, directors, Affiliates, employees,
                independent agents or contractors involves participation by
                other parties with accounts at Apex Crypto, you and such parties
                will be jointly and severally liable for all resulting damages
                to Apex Crypto and its Affiliates.
              </p>
              <p className='terms-text'>
                The enumeration in this Agreement of specific remedies shall not
                be exclusive of any other remedies that may be available at law
                or in equity. Any delay or failure by any party to this
                Agreement to exercise any right, power, remedy or privilege
                herein contained, or now or hereafter existing under any
                applicable statute or law, shall not be construed to be a waiver
                of such right, power, remedy or privilege, nor to limit the
                exercise of such right, power, remedy or privilege, nor shall it
                preclude the further exercise thereof or the exercise of any
                other right, power, remedy or privilege.
              </p>
              <div>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  15. Indemnification
                </h3>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  15.1 Indemnification by You
                </h3>
              </div>
              <p className='terms-text'>
                In addition to any other obligations you may have under other
                provisions of this Agreement, you agree to indemnify, defend and
                hold harmless Correspondent, Apex Crypto, Apex Clearing, each of
                their Affiliates and all of their officers, directors, managers,
                partners, employees, independent contractors, or agents,
                (collectively, the "Apex Crypto Indemnified Persons") from and
                against all claims, demands, proceedings, suits and actions and
                all liabilities, losses, expenses, costs (including reasonable
                legal and accounting fees and expenses), damages, penalties,
                fines, taxes or amounts due of any kind (collectively, "Losses")
                arising out of, in connection with or relating to (A) you or
                your officers, managers, partners, directors, Affiliates,
                employees, independent agents or contractors, for any reason,
                fraudulent or otherwise, failing to perform or comply with any
                obligation, agreement or covenant under this Agreement, the
                Services or your use of the Services or any other services
                provided in connection with your Account, the Platform or your
                use of the Platform, (B) a breach by you or your officers,
                managers, partners, directors, Affiliates, employees,
                independent agents or contractors, of any representation or
                warranty made by you under this Agreement or your provision of
                any inaccurate or incomplete information to any of the Apex
                Crypto Indemnified Parties for any reason in connection with
                this Agreement, the Services, any other services provided in
                connection with your Account or the Platform, (C) any negligent,
                dishonest, fraudulent, or criminal act or omission by your or
                any of your officers, managers, partners, directors, Affiliates,
                employees, or independent agents or contractors, (D) any claim
                or dispute between you and your Correspondent and (E) any
                failure by you or your officers, managers, partners, directors,
                Affiliates, employees, independent agents or contractors to
                comply with any laws, rules or regulations applicable to you or
                them. Notwithstanding the foregoing, an Apex Crypto Indemnified
                Person shall not be entitled under this paragraph to receive
                indemnification for that portion, if any, of any liabilities and
                costs which is solely caused by its own individual gross
                negligence, willful misconduct or fraud, as determined by
                arbitration in accordance with Section 17 below.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                15.2 Indemnification Procedures
              </h3>
              <p className='terms-text'>
                Upon receipt by any Apex Crypto Indemnified Person(s) under this
                Section 15 of notice of the commencement of any action, and if a
                claim is to be made against you under this Section 15, the Apex
                Crypto Indemnified Person(s) will promptly notify you; provided,
                however, that no delay on the part of the Apex Crypto
                Indemnified Person(s) in notifying you shall relieve you from
                any obligation hereunder unless (and then solely to the extent)
                you thereby are prejudiced. Further, the omission to promptly
                notify you will not relieve you from any liability that you may
                have to any Apex Crypto Indemnified Person(s) otherwise than
                under this Section 15. In any such action brought against any
                Apex Crypto Indemnified Person(s), you will be entitled to
                participate in and, to the extent that you may wish, to assume
                the defense thereof, subject to the provisions herein stated,
                with counsel reasonably satisfactory to such Apex Crypto
                Indemnified Person(s). After notice from you to such Apex Crypto
                Indemnified Person(s) of your election to assume the defense
                thereof, you will not be liable to such Apex Crypto Indemnified
                Person(s) under this Section 15 for any legal or other expense
                subsequently incurred by such Apex Crypto Indemnified Person(s)
                in connection with the defense thereof other than reasonable
                costs of investigation or in the case of an actual or potential
                conflict of interest between you and any Apex Crypto Indemnified
                Person(s), identified in writing by counsel to the Apex Crypto
                Indemnified Person(s). You shall keep the Apex Crypto
                Indemnified Person(s) informed of the status of the defense of
                such claims, and you will not agree to any settlement without
                consent of the Apex Crypto Indemnified Person(s), which consent
                will not be unreasonably withheld. The Apex Crypto Indemnified
                Person(s) shall have the right to employ separate counsel in any
                such action and to participate in the defense thereof, but the
                fees and expenses of such counsel shall not, other than in the
                case of an actual or potential conflict of interest between you
                and Apex Crypto Indemnified Person(s), be at your expense if you
                have assumed the defense of the action with counsel satisfactory
                to the Apex Crypto Indemnified Person(s). In the event that the
                Apex Crypto Indemnified Person(s) reasonably believes that you
                are not adequately defending a claim, the Apex Crypto
                Indemnified Person(s) will have the right to assume the defense
                of such claims at your sole expense. You will not settle any
                action unless such settlement completely and finally releases
                the Apex Crypto Indemnified Person(s) from any and all liability
                and otherwise is acceptable to the Apex Crypto Indemnified
                Person(s). Except with your prior written consent, the Apex
                Crypto Indemnified Person(s), you may not confess any claim or
                make any compromise in any case in which you may be required to
                provide indemnification.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                16. Limitation of Liability
              </h3>
              <p className='terms-text'>
                YOUR ACCOUNT AT APEX CRYPTO, THE SERVICES, YOUR USE OF THE
                ACCOUNT, THE PLATFORM, YOUR USE OF THE PLATFORM AND ANY OTHER
                SERVICES PROVIDED TO YOU IN CONNECTION THEREWITH ARE PROVIDED BY
                CORRESPONDENT, APEX CRYPTO AND THEIR AFFILIATES AND ANY OTHER
                SERVICE PROVIDERS ON AN "AS IS" AND "AS AVAILABLE" BASIS AND
                WITHOUT WARRANTY OF ANY KIND, TO THE MAXIMUM EXTENT PERMITTED BY
                APPLICABLE LAW. CORRESPONDENT AND APEX CRYPTO DISCLAIM ALL
                WARRANTIES, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED
                WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
                PURPOSE. THERE IS NO WARRANTY THAT ANY OF THE SERVICES OR THE
                PLATFORM, OR ANY DATA OR OTHER INFORMATION PROVIDED TO YOU BY
                CORRESPONDENT, APEX CRYPTO OR THEIR AFFILIATES OR THROUGH THE
                PLATFORM WILL FULFILL ANY PARTICULAR PURPOSES OR NEEDS. THERE IS
                NO WARRANTY THAT THE SERVICES PROVIDED HEREUNDER OR THE PLATFORM
                WILL BE ERROR FREE, UNINTERRUPTED, TIMELY, RELIABLE, COMPLETE OR
                ACCURATE.
              </p>
              <p className='terms-text'>
                None of Correspondent, Apex Crypto, Apex Clearing, any of their
                Affiliates nor any of their officers, directors, managers,
                partners, employees or independent agents or contractors will be
                liable to you or any of your Affiliates for any losses, expenses
                (including legal fees and costs), liabilities, damages, costs,
                demands, obligations, penalties, charges, causes of action,
                claims, fines, taxes or amounts due of any kind (both known and
                unknown, absolute or contingent, liquidated or unliquidated,
                direct or indirect, payable now or payable in the future,
                accrued or not accrued) (collectively, "Losses") arising out of,
                related to or resulting from your Account, the Services or your
                use of the Services, including without limitation any trading or
                other activities conducted in the Account, the Platform or your
                use of the Platform, including without limitation, your sending
                orders for the purchase and sale of cryptocurrencies to Apex
                Crypto and directing the transfer of funds to Apex Crypto, and
                the storage of your cryptocurrencies, except to the extent and
                only to the extent that your Losses are actual and have been
                finally determined by a court of competent jurisdiction or
                arbitration panel to have resulted solely from the gross
                negligence, intentional misconduct or fraud of Correspondent,
                Apex Crypto, Apex Clearing, any of their Affiliates or any of
                their officers, directors, managers, partners, employees or
                independent agents or contractors. You will not be held liable
                for such actual Losses that have been finally determined to have
                resulted solely from the gross negligence, intentional
                misconduct or fraud of Correspondent, Apex Crypto, Apex
                Clearing, any of their Affiliates or any of their officers,
                directors, managers, partners, employees or independent agents
                or contractors. Further, none of Correspondent, Apex Crypto,
                Apex Clearing, any of their Affiliates or any of their officers,
                directors, managers, partners, employees or agents will have
                responsibility for Losses or have any other liability to you (i)
                arising out of or resulting from any actions or inactions or
                performance of services by any third party or actions of other
                participants in the markets with respect to your Account or the
                activities conducted through your Account or (ii) arising out of
                or resulting from system failures, outages, unauthorized access
                to the Account, conversion of property, errors of any kind,
                government actions, force majeure events, trading suspensions,
                or any other causes over which Apex Crypto does not have direct
                control. Further, none of Correspondent, Apex Crypto, Apex
                Clearing, any of their Affiliates or any of their officers,
                directors, managers, partners, employees or agents shall be
                liable for any Losses that are not direct damages, including
                without limitation, indirect, special, incidental, punitive,
                consequential or exemplary damages, which includes trading
                losses, lost profits and other lost business opportunities
                relating to the Account, the Services and your use of the
                Services, the Platform and your use of the Platform, including
                without limitation for sending orders for the purchase and sale
                of cryptocurrencies to Apex Crypto, directing the transfer of
                funds to Apex Crypto and the storage of your cryptocurrencies.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                17. Third Party Beneficiaries.
              </h3>
              <p className='terms-text'>
                Except for Correspondent, who shall be considered a third-party
                beneficiary under Sections 15 and 16 of this User Agreement, but
                for the avoidance of doubt not under Section 15.1(D), this User
                Agreement is intended to be solely for the benefit of you, Apex
                Clearing and Apex Crypto and is not intended to confer any
                benefits upon, or create any rights in favor of, any person
                other than you, Apex Clearing and Apex Crypto.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                18. Dispute Resolution
              </h3>
              <div style={{ paddingLeft: '5%' }}>
                <p className='terms-text'>
                  (a) In the event of a dispute between the parties regarding
                  the subject matter of this User Agreement, such dispute shall
                  be settled by arbitration before one arbitrator sitting in
                  Chicago, Illinois, unless the amount in dispute exceeds
                  $1,000,000, in which case the arbitration shall be before
                  three independent arbitrators sitting in Chicago, Illinois.
                </p>
                <p className='terms-text'>(b) Arbitration Disclosure.</p>
                <div style={{ paddingLeft: '5%' }}>
                  <p className='terms-text'>
                    (i) ARBITRATION IS FINAL AND BINDING ON THE PARTIES.
                  </p>
                  <p className='terms-text'>
                    (ii) THE PARTIES ARE WAIVING THEIR RIGHT TO SEEK REMEDIES IN
                    COURT, INCLUDING THE RIGHT TO JURY TRIAL; PROVIDED, HOWEVER,
                    THAT THE PARTIES RESERVE THE RIGHT TO SEEK PURELY INTERIM OR
                    EQUITABLE RELIEF IN A COURT OF COMPETENT JURISDICTION AS SET
                    FORTH BELOW.
                  </p>
                  <p className='terms-text'>
                    (iii) PRE-ARBITRATION DISCOVERY IS GENERALLY MORE LIMITED
                    THAN AND DIFFERENT FROM COURT PROCEEDINGS.
                  </p>
                  <p className='terms-text'>
                    (iv) THE ARBITRATOR OR ARBITRATION PANEL SHALL ISSUE A
                    REASONED AWARD.
                  </p>
                </div>
                <p className='terms-text'>(c) ARBITRATION AGREEMENT.</p>
                <div style={{ paddingLeft: '5%' }}>
                  <p className='terms-text'>
                    EACH PARTY AGREES TO ADVISE THE OTHER PARTY PROMPTLY IN THE
                    EVENT A DISPUTE ARISES BETWEEN THEM (OR ANY OF THEIR
                    AFFILIATES, IT BEING UNDERSTOOD THAT AFFILIATES ARE BOUND BY
                    THIS USER AGREEMENT). THE PARTIES AGREE TO ATTEMPT TO
                    RESOLVE ANY SUCH DISPUTE INFORMALLY BEFORE FILING A FORMAL
                    ACTION. IF A DISPUTE CANNOT BE RESOLVED INFORMALLY WITHIN
                    THIRTY (30) DAYS, AND MEDIATION (DESCRIBED BELOW) FAILS TO
                    RESOLVE THE DISPUTE, EITHER PARTY MAY FILE AN ARBITRATION
                    DEMAND. NOTWITHSTANDING THE FOREGOING, EITHER PARTY MAY FILE
                    AN ARBITRATION DEMAND PRIOR TO SUCH TIME TO COMPLY WITH ANY
                    STATUTE OF LIMITATION OR TIMING REQUIREMENTS TO PRESERVE ITS
                    CLAIMS.
                  </p>
                  <p className='terms-text'>
                    ANY CONTROVERSY, DEMAND, CAUSE OF ACTION, WHETHER IN TORT,
                    CONTRACT, BY STATUTE OR OTHERWISE, BETWEEN YOU AND APEX
                    CRYPTO (OR ITS AFFILIATES) INCLUDING THOSE ARISING OUT OF OR
                    RELATING TO THIS AGREEMENT, THE ACCOUNT, ANY ACTIVITIES IN
                    THE ACCOUNT, INCLUDING WITHOUT LIMITATION, ANY USE OF THE
                    SERVICES, USE OF THE PLATFORM, THIRD PARTY SERVICES, SENDING
                    ORDERS FOR THE PURCHASE OR SALE OF CRYPTOCURRENCIES, AND THE
                    STORAGE OF YOUR CRYPTOCURRENCIES, SHALL BE EXCLUSIVELY,
                    FULLY AND FINALLY RESOLVED BY BINDING ARBITRATION PURSUANT
                    TO THE JAMS COMPREHENSIVE ARBITRATION RULES AND PROCEDURES
                    (INCLUDING THE EXPEDITED PROCEDURES FOR SUCH RULES IF THE
                    MATTER IS ARBITRATED BY A SINGLE ARBITRATOR). THE
                    ARBITRATION WILL BE HELD IN CHICAGO, ILLINOIS, UNLESS SUCH
                    LOCATION IS NOT CONVENIENT, THE ARBITRATION WILL BE HELD IN
                    THE COUNTY OF YOUR RESIDENCE. ARBITRATION MUST BE COMMENCED
                    BY SERVICE UPON THE OTHER PARTY OF A WRITTEN DEMAND FOR
                    ARBITRATION OR A WRITTEN NOTICE OF INTENTION TO ARBITRATE.
                    THE MATTER SHALL BE DECIDED BY ONE INDEPENDENT ARBITRATOR,
                    UNLESS THE AMOUNT IN DISPUTE IS IN EXCESS OF $1,000,000,
                    THEN THE ARBITRATION SHALL BE DECIDED BY A PANEL OF THREE
                    INDEPENDENT ARBITRATORS, ONE CHOSEN BY APEX CRYPTO, ONE
                    CHOSEN BY YOU AND THE THIRD CHOSEN BY THE FIRST TWO
                    ARBITRATORS. THE ARBITRATOR(S) SHALL HAVE INDUSTRY RELATED
                    EXPERIENCE. THE ARBITRATOR(S) SHALL APPLY THE SUBSTANTIVE
                    LAW OF THE STATE OF DELAWARE WITHOUT GIVING EFFECT TO ANY
                    CONFLICT OF LAWS PRINCIPLES THAT MAY APPLY. THE ARBITRATION
                    ITSELF SHALL BE GOVERNED BY FEDERAL ARBITRATION ACT 9 USC ß1
                    ET. SEQ. AND JUDGMENT UPON THE AWARD MAY BE ENTERED BY ANY
                    COURT HAVING COMPETENT JURISDICTION. THE ARBITRATOR OR PANEL
                    SHALL ISSUE A REASONED AWARD. APEX CRYPTO SHALL BE
                    RESPONSIBLE FOR THE COSTS OF THE ARBITRATION AND THE PARTIES
                    OWN LEGAL FEES AND COSTS, UNLESS APEX CRYPTO IS THE
                    PREVAILING PARTY, IN WHICH CASE YOU WILL PAY ONE-HALF OF THE
                    COSTS OF THE ARBITRATION AND YOUR LEGAL FEES AND COSTS. THE
                    ARBITRATORS SHALL BE SPECIFICALLY AUTHORIZED TO AWARD LEGAL
                    FEES AND COSTS OF ARBITRATION TO THE PREVAILING PARTY. THE
                    DUTY TO ARBITRATE DESCRIBED HEREIN SHALL SURVIVE THE
                    TERMINATION OF THIS AGREEMENT. THE PARTIES HEREBY WAIVE
                    TRIAL IN A COURT OF LAW OR BY JURY.
                  </p>
                  <p className='terms-text'>
                    ALL ARBITRATION CLAIMS WILL BE BROUGHT ON AN INDIVIDUAL
                    BASIS AND YOU WAIVE ANY RIGHTS YOU HAVE TO PARTICIPATE IN A
                    CLASS ACTION RELATING TO ANY MATTERS DESCRIBED IN THIS
                    DISPUTE RESOLUTION SECTION.
                  </p>
                  <p className='terms-text'>
                    ANY DISPUTE REQUIRING INJUNCTIVE OR OTHER EMERGENCY
                    EQUITABLE RELIEF MAY BE BROUGHT DIRECTLY TO A COURT OF
                    COMPETENT JURISDICTION. THE PARTIES SUBMIT TO THE EXCLUSIVE
                    JURISDICTION AND VENUE OF THE STATE OR FEDERAL COURT LOCATED
                    IN CHICAGO, ILLINOIS FOR ANY SUIT SEEKING INTERIM OR
                    INJUNCTIVE RELIEF OR TO CONFIRM AND EXECUTE UPON THE AWARD.
                    YOU HEREBY AGREE TO SUCH EXCLUSIVE JURISDICTION AND VENUE
                    AND WAIVE ANY RIGHTS YOU MAY HAVE TO CLAIM THAT THE FORUM IS
                    NOT CONVENIENT AND OTHER CLAIMS TO INVALIDATE SUCH
                    JURISDICTION.
                  </p>
                  <p className='terms-text'>
                    BEFORE COMMENCING AN ACTION IN ARBITRATION, THE PARTIES
                    SHALL PROMPTLY SUBMIT ANY DISPUTE THAT THEY HAVE FAILED TO
                    PROMPTLY RESOLVE TO MEDIATION BEFORE JAMS IN CHICAGO,
                    ILLINOIS OR AT A LOCATION AGREED TO BY THE PARTIES, THE
                    PARTIES SHALL JOINTLY SELECT THE MEDIATOR, OR IF THE PARTIES
                    ARE UNABLE TO AGREE UPON A MEDIATOR, THEN THE DISPUTE SHALL
                    BE SUBMITTED TO NON-BINDING MEDIATION BEFORE JAMS. THE COST
                    OF THE MEDIATION SHALL BE PAID BY APEX CRYPTO. IF THE
                    PARTIES ARE UNABLE TO ACHIEVE A MUTUALLY AGREEABLE
                    RESOLUTION OF THE DISPUTE THROUGH MEDIATION WITHIN 60 DAYS
                    AFTER COMMENCEMENT OF MEDIATION PROCEEDINGS, THE PARTIES
                    HEREBY AGREE TO SUBMIT THEIR DISPUTE TO BINDING ARBITRATION
                    DESCRIBED ABOVE. NOTWITHSTANDING THE FOREGOING, EITHER PARTY
                    MAY FILE AN ARBITRATION DEMAND AT ANY TIME TO COMPLY WITH
                    ANY STATUTE OF LIMITATION OR TIMING REQUIREMENTS TO PRESERVE
                    ITS CLAIMS.
                  </p>
                </div>
              </div>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                19. Governing Law and Venue
              </h3>
              <p className='terms-text'>
                This Agreement shall be governed by the internal laws of the
                State of Delaware applicable to agreements made and to be
                performed entirely within the State of Delaware, without regard
                to the conflicts of law principles that would apply the laws of
                any other jurisdiction.
              </p>
              <div>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  20. Legal
                </h3>
                <h3 style={{ color: '#3f4174' }} className='terms-title'>
                  20.1 Regulation
                </h3>
              </div>
              <p className='terms-text'>
                Apex Crypto is registered with the U.S. Department of Treasury
                Financial Crimes Enforcement Network ("FinCEN") as a money
                services business ("MSB"). As a registered MSB, Apex Crypto is
                subject to the Bank Secrecy Act and its implementing regulations
                (collectively, the "BSA") which set out the requirements imposed
                upon financial institutions to implement policies and procedures
                reasonably designed to detect and prevent money laundering and
                terrorist financing. You understand and acknowledge that your
                access to and use of the Services is subject to compliance with
                Apex Crypto's AML Program.
              </p>
              <p className='terms-text'>
                You understand and acknowledge that Apex Crypto is not a
                registered broker-dealer and is not a member of the Financial
                Industry Regulatory Authority or the SIPC. You further
                understand and acknowledge that your cryptocurrency holdings are
                not protected by the FDIC or SIPC, or any other insurance.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                20.2 Compliance with Applicable Laws
              </h3>
              <p className='terms-text'>
                Cryptocurrency Transactions are subject to applicable laws,
                regulations, and rules of federal and state governmental and
                regulatory authorities (collectively, "Applicable Laws"). You
                understand that compliance with Applicable Laws may include
                compliance with any guidance or direction of any regulatory
                authority or government agency, any writ of attachment, lien,
                levy, subpoena, warrant, or other legal order (collectively,
                "Legal Orders").
              </p>
              <p className='terms-text'>
                You understand and acknowledge that in no event will Apex Crypto
                be obligated to affect any Cryptocurrency Transaction it
                believes would violate any Applicable Law. You further
                understand and acknowledge that Apex Crypto is not responsible
                for any losses, whether direct or indirect, that you may incur
                as a result of Apex Crypto's good faith efforts to comply with
                any Applicable Law, including any Legal Order.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                20.3 State Licenses and Disclosures
              </h3>
              <p className='terms-text'>
                Apex Crypto maintains licenses to engage in money transmission
                activities in certain states, and these licenses may impact our
                provision and your use of certain Services depending on where
                you live. Apex Crypto's licenses and corresponding required
                disclosures can be found at apexcrypto.com\\legal, which is
                incorporated by reference.
              </p>
              <h3 style={{ color: '#3f4174' }} className='terms-title'>
                20.4 Authorizing Apex Clearing to Share Your Information
              </h3>
              <p className='terms-text'>
                `acceptedCrypto` By checking this box, I hereby authorize Apex
                Clearing Corporation to provide all information provided to Apex
                Clearing Corporation in my new account application to Apex
                Crypto LLC for purposes of opening and maintaining an Apex
                Crypto LLC account.
              </p>
              <p className='terms-text'>
                By clicking the "Agree and Submit" button below, I acknowledge
                and agree that: 1) I am opening an account to trade digital
                assets (such as cryptocurrencies); 2) I have read the Agreement
                and am legally bound by its terms and conditions; and 3) I have
                reviewed the Risk Disclosures.
              </p>
              <p className='terms-text'>
                Disclosures: Apex Crypto LLC is not a broker-dealer and is a
                separate company from Apex Clearing Corporation. Assets held at
                Apex Crypto LLC are held away from Apex Clearing Corporation and
                are not protected by SIPC or the FDIC. Purchasing
                cryptocurrencies comes with a number of risks, including
                volatile market price swings or flash crashes, fraud, market
                manipulation, and cybersecurity risks. Please review your
                account agreement with Apex Crypto LLC for further elaboration
                on these risks.
              </p>
              <p className='terms-text'>Signature: `signature`</p>
            </Row>
            {/* <Row>
                        <p className="terms-bottom-paragraph">
                        Subscribers may submit questions to AllocateRite directly through
                        the website.
                        </p>
                    </Row> */}
          </Container>
          <Footer t={this.props.t} isSmallScreen={this.state.isSmallScreen} />
        </div>
      </div>
    )
  }
}
