import React, { Component } from 'react'
import ReactHighcharts from 'react-highcharts'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import '../../scss/components/AllocationPieChart.scss'
import {
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer,
  Cell,
  Legend,
  Surface,
  Symbols,
} from 'recharts'

//PROPS
//seriesData: [[value,name]]
//t: translation function
//COLORS: array of colors
//verticalAlign: boolean, True if legend to be vertical aligned
const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
]

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <text x={cx} y={cy - cy * 0.07} dy={8} textAnchor='middle' fill={fill}>
        {payload.name}
      </text>
      <text x={cx} y={cy + cy * 0.07} dy={8} textAnchor='middle' fill={fill}>
        {(percent * 100).toFixed(2)}%
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  )
}

const renderEmptyActiveShape = (props) => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <text x={cx} y={cy - cy * 0.05} dy={8} textAnchor='middle' fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  )
}

export default class AllocationPieChart2 extends Component {
  state = {
    activeIndex: 0,
    COLORS: [],
  }

  componentDidMount() {
    if (this.props.seriesData) this.generateColors()
  }

  generateColors = () => {
    let COLORS = []
    for (let i = 0; i < this.props.seriesData.length; i++) {
      COLORS.push('#' + (((1 << 24) * Math.random()) | 0).toString(16))
    }
    this.setState({ COLORS: COLORS })
  }

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    })
  }
  getConfig() {
    let first = true
    let width = 0
    const config = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        marginTop: 15,
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 0,
        backgroundColor: 'transparent',
        events: {
          render: function () {
            var seriesElPos = this.seriesGroup.getBBox()

            this.title.attr({
              x: seriesElPos.width / 2 + seriesElPos.x,
            })
          },
        },
      },
      title: {
        text: `${this.props.t('allocation')}`,
        align: 'center',
        verticalAlign: 'middle',
        // x: -65,
        // y: 20,
        style: {
          color: 'rgba(128,128,128,.5)',
          fontFamily: 'Akkurat-Bold',
        },
      },
      // subtitle: {
      //   text: "subtitle",
      //   align: "center",
      //   verticalAlign: "middle",
      //   y: 0
      // },
      tooltip: {
        valueSuffix: '%',
        enabled: false,
      },
      plotOptions: {
        //   series: {
        //     states: {
        //       hover: {
        //         enabled: false
        //       }
        //     }
        //   },
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          point: {
            events: {
              click: function (e) {
                // this.series.chart.innerText.attr({
                //   text: this.y
                // });
                this.series.chart.setTitle({
                  text: `<div><p>${this.name}</p><br/><p>${this.y}%</p></div>`,
                })
                var seriesElPos = this.series.chart.seriesGroup.getBBox()
                if (first) {
                  width = seriesElPos.width / 2 + seriesElPos.x
                  first = false
                }
                this.series.chart.title.attr({
                  x: width,
                })
              },
            },
          },
          showInLegend: true,
          borderWidth: 0,
        },
      },
      legend: {
        enabled: true,
        useHTML: true,
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        itemMarginTop: 10,
        itemMarginBottom: 10,
        labelFormatter: function () {
          return this.name + '     (' + this.y + '%)'
        },
      },
      series: [
        {
          innerSize: '67%',
          data: this.props.seriesData,
          name: 'Allocation',
        },
      ],
      credits: {
        enabled: false,
      },
    }
    return config
  }

  CustomLegend = (props) => {
    const { payload } = props
    if (payload) {
      payload.sort((obj1, obj2) => {
        return obj2?.payload?.percent - obj1?.payload?.percent
      })
      let index = 0
      let element
      element = (
        <div className='customized-legend'>
          {payload.map((entry, index) => {
            const color = entry?.color
            const name = entry?.value
            const percent = (entry?.payload?.percent * 100).toFixed(2)
            let style = { color: color, marginRight: '15px' }
            return (
              <div className='value-row' key={entry.color}>
                {/* <p className='custom-legend-label' style={style} key={index}>
                  {name} {percent}%
                </p> */}
                <p style={style}>{name}</p>
                <p style={style}>{percent}%</p>
              </div>
            )
          })}
        </div>
      )
      return element
    } else return null
  }
  render() {
    if (
      this.props.t &&
      this.props.seriesData &&
      this.props.seriesData.length > 0
    ) {
      const COLORS = this.props.COLORS
      return (
        <ResponsiveContainer width={'100%'} height={'100%'}>
          <PieChart>
            <Pie
              activeIndex={this.state.activeIndex}
              data={this.props.seriesData}
              activeShape={renderActiveShape}
              dataKey='value'
              innerRadius={'60%'}
              outerRadius={'80%'}
              onMouseEnter={this.onPieEnter}
            >
              {COLORS ? (
                <>
                  {this.props.seriesData.map((entry, index) => (
                    <Cell fill={COLORS[index % COLORS.length]} key={entry} />
                  ))}
                </>
              ) : (
                <>
                  {this.props.seriesData.map((entry, index) => (
                    <Cell
                      fill={this.state.COLORS[index % this.state.COLORS.length]}
                      key={entry}
                    />
                  ))}
                </>
              )}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      )
    } else {
      return (
        <ResponsiveContainer width={'100%'} height={'100%'}>
          <PieChart>
            <Pie
              activeIndex={this.state.activeIndex}
              data={[
                {
                  value: 100,
                  name: this.props.t('noallocations'),
                },
              ]}
              activeShape={renderEmptyActiveShape}
              dataKey='value'
              innerRadius={'60%'}
              outerRadius={'80%'}
              onMouseEnter={this.onPieEnter}
            ></Pie>
          </PieChart>
        </ResponsiveContainer>
      )
    }
  }
}
