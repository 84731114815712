import React, { useState, useEffect, useRef, useContext } from 'react'
import '../../scss/pages/LandingPage3.scss'
import MainNavBar from '../components/MainNavBar'
import { Carousel } from 'primereact/carousel'
import Axios from 'axios'
import SearchEndpoint from '../SearchEndpoint'
import { useSpring, animated, useTrail } from 'react-spring'
import { IoCaretDown, IoCaretUp, IoRemove } from 'react-icons/io5'

import AppleStore from '../../assets/landingPage/v3/apple-store-transparent.svg'
import PlayStore from '../../assets/landingPage/v3/play-store-transparent.svg'
import APKIcon from '../../assets/landingPage/v3/apk-transparent.svg'
import SDTIcon from '../../assets/landingPage/v3/sdt-icon.svg'
import RAIcon from '../../assets/landingPage/v3/ra-icon.svg'
import AITIcon from '../../assets/landingPage/v3/ait-icon.svg'
import GoalsIcon from '../../assets/landingPage/v3/g-icon.svg'
import PFIconDark from '../../assets/landingPage/v3/pf-icon-dark.png'
import GroupedIcons from '../../assets/landingPage/v3/grouped-icons.png'

import RightHand from '../../assets/landingPage/v3/right-hand.png'
import LeftHand from '../../assets/landingPage/v3/left-hand.png'

import PFIcon from '../../assets/landingPage/v3/pf-icon.png'
import VIIcon from '../../assets/landingPage/v3/vi-icon.svg'
import FYIcon from '../../assets/landingPage/v3/fy-icon.svg'
import IRIcon from '../../assets/landingPage/v3/ir-icon.svg'
import ARTIcon from '../../assets/landingPage/v3/art-icon.svg'
import GoalsIconLight from '../../assets/landingPage/v3/g-icon-light.svg'
import SubscriptionIcon from '../../assets/landingPage/v3/subscription-icon.svg'

import FutureFinance from '../../assets/landingPage/v3/future-finance.png'

import SpendingVideo from '../../assets/landingPage/v3/dynamic_spending.mp4'
import InvestVideo from '../../assets/landingPage/v3/Invest_wisely.mp4'
import PublicFooter from '../PublicFooter'
import { handleAppRedirect } from '../../utility-functions/handleAppRedirect'
import { NavLink } from 'react-router-dom'
import { LandingPageContext } from './landing-page'
import { getApkData, sendApkIncrement } from '../../services/generalService'
import { HashLink } from 'react-router-hash-link'

const ffCards = [
  {
    line1: 'Traditional',
    line2: 'Financial Advisor',
    line3: '>$2000',
    line4: 'Fees per year',
    line5: '$100,000',
    line6: 'Minimum investments',
  },
  {
    line1: 'Traditional',
    line2: 'Financial Advisor',
    line3: '>$250',
    line4: 'Fees per year',
    line5: 'Limited to ',
    line6: 'Budgeting advice',
  },
  {
    line1: 'Investall',
    line2: 'AI Powered',
    line3: '$96',
    line4: 'Fees per year',
    line5: 'No Minimum Investment',
    line6: 'Includes all offerings',
  },
]

const features1 = [
  {
    title: 'View Insights 🔮',
    image: VIIcon,
    description:
      'Effortlessly track your income, expenses and gain insights into your spending.',
    url: '/products#insights',
  },
  {
    title: 'Financial Yoga',
    image: FYIcon,
    description:
      'Strengthen your financial mindset and train our A.I. to serve you better.',
    url: '/products#financial-yoga',
  },
  {
    title: 'Take Control of Subscriptions',
    image: SubscriptionIcon,
    description:
      'Use AI to identify your subscriptions you no longer need and stop paying for them.',
    url: '/products#subscriptions',
  },
]
const features2 = [
  {
    title: 'Investall Robo',
    image: IRIcon,
    description: 'Let our AI invest for you',
    url: '/products#robo',
  },
  {
    title: 'AI Trader',
    image: ARTIcon,
    description: 'Let our AI trade for you',
    url: '/products#ai-trader',
  },
  {
    title: 'Goals',
    image: GoalsIconLight,
    description:
      'A powerful digital tool that helps you work towards your short- and long- term goals.',
    url: '/products#goals',
  },
  {
    title: 'Self Directed Trading',
    image: SDTIcon,
    description:
      'Don’t trade blind, use Investall price forecasts and advanced statistics to make informed decisions.',
    url: '/products#self-directed-trading',
  },
]

const productTemplate = (product) => {
  return (
    <div className='outer-edge'>
      <div className='slider-content'>
        <img src={product.image} alt='' />
        <p>{product.name}</p>
        <p className='product-description'>{product.desc}</p>
      </div>
    </div>
  )
}

const futureFinanceTemplate = (card) => {
  return (
    <div className='ff-card'>
      <div>
        <p>{card?.line1}</p>
        <p>{card?.line2}</p>
      </div>
      <div>
        <p>{card?.line3}</p>
        <p>{card?.line4}</p>
      </div>
      <div>
        <p>{card?.line5}</p>
        <p>{card?.line6}</p>
      </div>
    </div>
  )
}

const FeatureCard = (props) => {
  return (
    <HashLink to={props?.url} className='feature-card'>
      <div className='icon-side'>
        <img src={props?.image} alt='' />
      </div>
      <div className='text-side'>
        <p className='card-title'>{props?.title}</p>
        <p>{props?.description}</p>
      </div>
    </HashLink>
  )
}

const StockCard = (props) => {
  const changedMoneyToUSFormat = (amount) => {
    const formattedNumber = amount?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    })

    return `${formattedNumber ? formattedNumber : '$0.00'}`
  }
  return (
    <div className='stock-card'>
      <div className='ticker-icon-border'>
        <img className='ticker-icon' alt='' src={props?.ticker?.imageURL}></img>
      </div>
      <p>{props?.ticker?.symbol}</p>
      {props?.ticker?.priceChangeValue ? (
        props?.ticker?.priceChangeValue > 0 ? (
          <div className='price-block positive'>
            <IoCaretUp /> <p>{changedMoneyToUSFormat(props?.ticker?.price)}</p>
          </div>
        ) : (
          <div className='price-block negative'>
            <IoCaretDown />
            <p>{changedMoneyToUSFormat(props?.ticker?.price)}</p>
          </div>
        )
      ) : (
        <div className='price-block'>
          <IoRemove /> <p>{changedMoneyToUSFormat(props?.ticker?.price)}</p>
        </div>
      )}
    </div>
  )
}

export default function LandingPage3(props) {
  const products = [
    {
      id: 0,
      image: GroupedIcons,
      name: '',
      desc: '',
    },
    {
      id: 1,
      image: SDTIcon,
      name: 'Self Directed Trading',
      desc: 'Trade thousands of stocks with price and fundamental forecasts supported by AI.',
    },
    {
      id: 2,
      image: RAIcon,
      name: 'Robo Advisor',
      desc: 'Hands free, automated investing done for you, powered by AI.',
    },
    {
      id: 3,
      image: AITIcon,
      name: 'AI Trader',
      desc: 'Select a few stocks for automated trading powered by AI. ',
    },
    {
      id: 4,
      image: GoalsIcon,
      name: 'Goals',
      desc: 'Save, plan and reach your financial goals using automatic investing.',
    },
    {
      id: 5,
      image: PFIconDark,
      name: 'Personal Finance',
      desc: 'Personal finance tools with premium insights to help you build wealth.',
    },
  ]
  const isComputer =
    window.navigator.userAgent.toLowerCase().includes('macintosh') ||
    window.navigator.userAgent.toLowerCase().includes('windows')

  const [apkData, setApkData] = useState(null)

  const [activeTickers, setActiveTickers] = useState([])
  const [showRightHand, setShowRightHand] = useState(false)
  const [showLeftHand, setShowLeftHand] = useState(false)
  const [showFeatureCards1, setShowFeatureCards1] = useState(false)
  const [showFeatureCards2, setShowFeatureCards2] = useState(false)

  const spendDynamicallyRef = useRef(null)
  const investWiselyRef = useRef(null)

  // Animations
  const rightHandAnimation = useSpring({
    transform: showRightHand ? 'translateX(0%)' : 'translateX(50%)',
    opacity: showRightHand ? 1 : 0,
  })
  const spendDynamicAnimation = useSpring({
    opacity: showRightHand ? 1 : 0,
  })
  const spendDynamicFeaturesAnimation = useSpring({
    transform: showRightHand ? 'translateY(0%)' : 'translateY(50%)',
    opacity: showRightHand ? 1 : 0,
  })
  const leftHandAnimation = useSpring({
    transform: showLeftHand ? 'translateX(0%)' : 'translateX(-50%)',
    opacity: showLeftHand ? 1 : 0,
  })
  const investWiselyAnimation = useSpring({
    opacity: showLeftHand ? 1 : 0,
  })
  const investWiselyFeaturesAnimation = useSpring({
    // transform: showLeftHand ? 'translateY(0%)' : 'translateY(50%)',
    opacity: showLeftHand ? 1 : 0,
  })

  const trail = useTrail(features1.length, {
    config: { mass: 1, tension: 2000, friction: 150 },
    opacity: showFeatureCards1 ? 1 : 0,
    transform: showFeatureCards1 ? 'translateY(0%)' : 'translateY(50%)',
    from: { opacity: 0, transform: 'translateY(50%)' },
  })
  const trail2 = useTrail(features2.length, {
    config: { mass: 1, tension: 2000, friction: 150 },
    opacity: showFeatureCards2 ? 1 : 0,
    transform: showFeatureCards2 ? 'translateY(0%)' : 'translateY(50%)',
    from: { opacity: 0, transform: 'translateY(50%)' },
  })

  const getMostActiveTickers = async () => {
    try {
      let res = await Axios.get(`${SearchEndpoint}/ticker-active`)
      setActiveTickers(res.data.lists.slice(0, 3))
      return
    } catch (error) {
      setActiveTickers([])
      return
    }
  }

  const incrementApk = async () => {
    console.log('apkData?.version', apkData?.version)
    const response = await sendApkIncrement(apkData?.version)
    console.log('res', response)
  }

  const fetchApkData = async () => {
    const res = await getApkData()
    setApkData(res)
  }

  useEffect(() => {
    fetchApkData()
    getMostActiveTickers()
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowRightHand(true)
        } else {
          setShowRightHand(false)
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.2,
      }
    )
    const observer2 = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowFeatureCards1(true)
        } else {
          setShowFeatureCards1(false)
        }
      },
      {
        root: null,
        rootMargin: '200px 0px -200px 0px',
        threshold: 0.2,
      }
    )

    if (spendDynamicallyRef.current) {
      observer.observe(spendDynamicallyRef.current)
      observer2.observe(spendDynamicallyRef.current)
    }
  }, [spendDynamicallyRef])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowLeftHand(true)
        } else {
          setShowLeftHand(false)
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.2,
      }
    )
    const observer2 = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowFeatureCards2(true)
        } else {
          setShowFeatureCards2(false)
        }
      },
      {
        root: null,
        rootMargin: '0px 0px 400px 0px',
        threshold: 0.35,
      }
    )
    if (investWiselyRef.current) {
      observer.observe(investWiselyRef.current)
      observer2.observe(investWiselyRef.current)
    }
  }, [investWiselyRef])

  return (
    <>
      <MainNavBar {...props} white />
      <div className='navbar-spacer'></div>
      <div className='white-container'>
        <section className='header-section'>
          <div className='inner-container'>
            <div className='desktop-row gap-64'>
              <div className='desktop-side text'>
                <p className='header-title'>
                  Personal Finance & Investing powered by AI
                </p>
                <p className='content'>
                  A one of a kind finance app for beginners and advanced users
                  that delivers unmatched automated trading and investing
                  technology powered by AI. Take control of your finances with a
                  premium personal finance dashboard. Available for iOS, android
                  and web app.
                </p>
              </div>
              <div className='desktop-side slider'>
                <div className='slider-container'>
                  <Carousel
                    value={products}
                    numVisible={1}
                    numScroll={1}
                    itemTemplate={productTemplate}
                    autoPlay
                    autoplayInterval={2000}
                    circular
                    page={0}
                  />
                </div>
              </div>
            </div>
            <div className='desktop-row justify-between align-center'>
              <div className='get-app-wrapper'>
                {isComputer ? (
                  <NavLink to='/signup'>
                    <button className='rounded-blue-button'>SIGN UP</button>
                  </NavLink>
                ) : (
                  <button
                    className='rounded-blue-button'
                    onClick={() => handleAppRedirect()}
                  >
                    GET THE APP
                  </button>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className='gradient-container'>
        <div className='centered-content'>
          <section className='hand-text-section' ref={spendDynamicallyRef}>
            <div className='desktop-row gap-100'>
              <div className='text-content'>
                <animated.div>
                  <p className='bold-title' style={{ fontSize: 40 }}>
                    💸 MY FINANCE
                  </p>
                  <p className='content desktop-text'>
                    Identify opportunity costs and insights into your financial
                    situation. Wether you want to pay off debt, start investing
                    or put money aside for emergencies, Investall trains your
                    financial muscle to make better decisions.
                  </p>
                  <p className='content mobile-text'>
                    Identify opportunity costs and insights into your financial
                    situation. Wether you want to pay off debt, start investing
                    or put money aside for emergencies, Investall trains your
                    financial muscle to make better decisions.
                  </p>
                </animated.div>
                <div className='card-container desktop'>
                  {trail.map(({ ...style }, index) => (
                    <animated.div key={index} style={style}>
                      <FeatureCard
                        image={features1[index].image}
                        title={features1[index].title}
                        description={features1[index].description}
                        url={features1[index].url}
                      />
                    </animated.div>
                  ))}
                  {isComputer ? (
                    <NavLink to='/signup'>
                      <button className='rounded-blue-button'>
                        TRY FOR FREE
                      </button>
                    </NavLink>
                  ) : (
                    <button
                      className='rounded-blue-button'
                      onClick={() => handleAppRedirect()}
                    >
                      TRY FOR FREE
                    </button>
                  )}
                </div>
              </div>
              <animated.div
                style={rightHandAnimation}
                className='hand-animation-container'
              >
                <img src={RightHand} alt='' />
                <div className='animation-container'>
                  <video src={SpendingVideo} autoPlay muted loop></video>
                </div>
              </animated.div>
            </div>
            <div className='card-container mobile'>
              {trail.map(({ ...style }, index) => (
                <animated.div key={index} style={style}>
                  <FeatureCard
                    image={features1[index].image}
                    title={features1[index].title}
                    description={features1[index].description}
                    url={features1[index].url}
                  />
                </animated.div>
              ))}
              {isComputer ? (
                <NavLink to='/signup'>
                  <button className='rounded-blue-button'>TRY FOR FREE</button>
                </NavLink>
              ) : (
                <button
                  className='rounded-blue-button'
                  onClick={() => handleAppRedirect()}
                >
                  TRY FOR FREE
                </button>
              )}
            </div>
          </section>
          <section className='hand-text-section' ref={investWiselyRef}>
            <div className='desktop-row gap-100'>
              <animated.div className='invest-wisely-text mobile'>
                <p className='bold-title'>💆 INVEST WISELY</p>
                <p className='content'>
                  Use our AI to help guide your investing. Investall provides
                  short and long term price forecasts for thousands of stocks.
                </p>
              </animated.div>
              <animated.div
                style={leftHandAnimation}
                className='hand-animation-container-left-hand'
              >
                <img src={LeftHand} alt='' />
                <div className='animation-container'>
                  <video src={InvestVideo} autoPlay muted loop></video>
                </div>
                <div className='block'></div>
              </animated.div>
              <div className='text-content'>
                <div>
                  <div className='invest-wisely-text desktop'>
                    <p className='bold-title'>💆 INVEST WISELY</p>
                    <p className='content'>
                      Use our AI to help guide your investing. Investall
                      provides short and long term price forecasts for thousands
                      of stocks.
                    </p>
                  </div>
                </div>
                <div>
                  <div className='card-container desktop'>
                    {trail2.map(({ ...style }, index) => (
                      <animated.div key={index} style={style}>
                        <FeatureCard
                          image={features2[index].image}
                          title={features2[index].title}
                          description={features2[index].description}
                          url={features2[index].url}
                        />
                      </animated.div>
                    ))}
                  </div>
                  <animated.div
                    style={investWiselyFeaturesAnimation}
                    className='buy-stocks-container desktop'
                  >
                    <p className='buy-stocks-title'>PERSONALIZED WATCHLISTS</p>
                    <p className='content'>
                      Get insights and forecast prices on stocks before you
                      trade. Investall personalizes stock suggestions based on
                      your spending patterns and provides advanced analytics to
                      help you make informed decisions.
                    </p>
                    <div className='stock-cards'>
                      {activeTickers?.map((ticker, i) => {
                        return <StockCard key={i} ticker={ticker} />
                      })}
                    </div>
                  </animated.div>
                  <div
                    style={{ marginTop: 40 }}
                    className='card-container desktop'
                  >
                    {isComputer ? (
                      <NavLink to='/signup'>
                        <button className='rounded-blue-button'>
                          TRY FOR FREE
                        </button>
                      </NavLink>
                    ) : (
                      <button
                        className='rounded-blue-button'
                        onClick={() => handleAppRedirect()}
                      >
                        TRY FOR FREE
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='card-container mobile'>
              {trail2.map(({ ...style }, index) => (
                <animated.div key={index} style={style}>
                  <FeatureCard
                    image={features2[index].image}
                    title={features2[index].title}
                    description={features2[index].description}
                    url={features2[index].url}
                  />
                </animated.div>
              ))}
              <p className='bold-title subtitle'>PERSONALIZED WATCHLISTS</p>
              <p className='content'>
                Get insights and forecast prices on stocks before you trade.
                Investall personalizes stock suggestions based on your spending
                patterns and provides advanced analytics to help you make
                informed decisions.
              </p>
              <div className='stock-cards'>
                {activeTickers?.map((ticker, i) => {
                  return <StockCard key={i} ticker={ticker} />
                })}
              </div>
              {isComputer ? (
                <NavLink to='/signup'>
                  <button className='rounded-blue-button'>TRY FOR FREE</button>
                </NavLink>
              ) : (
                <button
                  className='rounded-blue-button'
                  onClick={() => handleAppRedirect()}
                >
                  TRY FOR FREE
                </button>
              )}
            </div>
          </section>
          <section className='future-finance-section'>
            <p className='bold-title subtitle'>
              USE AI TO MANAGE YOUR FINANCES, AND SPEND MORE TIME DOING THE
              THINGS YOU LOVE.
            </p>
            <p className='content'>
              Training your brain to think about “opportunity cost” helps you
              spend smarter naturally and it leads to better life outcomes. With
              Investall, you can save, plan and invest with ease from one app.
            </p>
            <div className='ff-image-container'>
              <img src={FutureFinance} alt='' />
            </div>
            <div className='ff-info'>
              <p className='ff-title'>The future of finance is here</p>
              <div className='info-box-container'>
                <div className='info-box'>
                  <div>
                    <p className='content'>Traditional</p>
                    <p className='content'>Financial Advisor</p>
                  </div>
                  <div>
                    <p className='content'>{'>$2000'}</p>
                    <p className='content'>Fees per year</p>
                  </div>
                  <div>
                    <p className='content'>$100,000</p>
                    <p className='content'>Minimum investments</p>
                  </div>
                </div>
                <div className='info-box'>
                  <div>
                    <p className='content'>Traditional</p>
                    <p className='content'>Financial Advisor</p>
                  </div>
                  <div>
                    <p className='content'>{'>$250'}</p>
                    <p className='content'>Fees per year</p>
                  </div>
                  <div>
                    <p className='content'>Limited to</p>
                    <p className='content'>Budgeting advice</p>
                  </div>
                </div>
                <div className='info-box'>
                  <div>
                    <p className='content'>Investall</p>
                    <p className='content'>AI Powered</p>
                  </div>
                  <div>
                    <p className='content'>$96</p>
                    <p className='content'>Fees per year</p>
                  </div>
                  <div>
                    <p className='content'>No Minimum Investment</p>
                    <p className='content'>Includes all offerings</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='ff-slider'>
              <p className='content'>The future of finance is here</p>
              <Carousel
                value={ffCards}
                numScroll={1}
                numVisible={1}
                itemTemplate={futureFinanceTemplate}
                renderArrowPrev={null}
                renderArrowNext={null}
              />
            </div>
            <div className='free-trial-button'>
              <button
                className='rounded-blue-button'
                onClick={() => handleAppRedirect()}
              >
                Start your free trial
              </button>
            </div>
            <p className='content text-black'>Cancel Anytime</p>
            <div className='app-store-buttons'>
              <div className='desktop-row gap-20'>
                <a
                  style={{ width: '100%', marginBottom: 20 }}
                  href='https://apps.apple.com/us/app/forescite/id1567589436'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={AppleStore} alt='' />
                </a>
                <a
                  style={{ width: '100%', marginBottom: 20 }}
                  href='https://play.google.com/store/apps/details?id=com.forescite'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={PlayStore} alt='' />
                </a>
                <div className='icon-container' onClick={() => incrementApk()}>
                  <img src={APKIcon} alt='' />
                </div>
              </div>
            </div>
          </section>
          <section className='disclaimer-text-section'>
            <p className='sub-text'>
              Investall is a SEC-Registered Investment Advisor that facilitates
              account openings through Interactive Brokers (“IB”) and Apex
              Clearing Corporation(“Apex”). IB and Apex are members of SIPC.
              SIPC which protects securities and cash for customers of its
              members up to $500,000 (including $250,000 for claims for cash).
              Explanatory brochures are available upon request or at
              www.sipc.org. By using this website, you accept our Terms of Use
              and Privacy Policy. Investall’s investment advisory services are
              available only to residents of the United States in jurisdictions
              where Investall is registered. Nothing on this website should be
              considered an offer, solicitation of an offer, or advice to buy or
              sell securities. Past performance is no guarantee of future
              results. Any historical returns, expected returns [or probability
              projections] are hypothetical in nature and may not reflect actual
              future performance. Account holdings are for illustrative purposes
              only and are not investment recommendations. Registration with the
              SEC does not imply a certain level of skill or training. The
              content on this website is for informational purposes only and
              does not constitute a comprehensive description of Investall’s
              investment advisory services. Certain investments are not suitable
              for all investors. Before investing, consider your investment
              objectives. The rate of return on investments can vary widely over
              time, especially for long term investments. Investment losses are
              possible, including the potential loss of all amounts invested.
              Brokerage services are provided to Investall Clients by
              Interactive Brokers LLC and Apex Clearing Corporation, which are
              SEC registered broker-dealers and members of FINRA/SIPC. For more
              information, see our disclosures. Contact: help@investall.ai. For
              all information or inquiries regarding Investall’s Custodians,
              please visit:
              <a href='https://www.interactivebrokers.com/en/accounts/forms-and-disclosures-client-agreements.php'>
                https://www.interactivebrokers.com/en/accounts/forms-and-disclosures-client-agreements.php
              </a>{' '}
              for IB; and{' '}
              <a href='https://apexfintechsolutions.com/legal/privacy-and-legal/'>
                https://apexfintechsolutions.com/legal/privacy-and-legal/
              </a>{' '}
              for Apex. Apple, the Apple logo, and iPhone are trademarks of
              Apple, Inc., registered in the U.S.
            </p>
          </section>
          <PublicFooter isSmallScreen={false} t={props.t} />
        </div>
      </div>
    </>
  )
}
