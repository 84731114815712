//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//CONTEXT
import { BudgetingContext } from './Budgeting'

//STYLES
import '../../scss/components/budgeting/Notifications.scss'

//UTILS
import formatCurrency from '../../utility-functions/formatCurrency'

export default function Notifications() {
  const { t, budgetingData, isSmallScreen, setShowDisclaimer } =
    useContext(BudgetingContext)

  return (
    <div className='budgeting-notifications'>
      {budgetingData?.payload?.notifications?.map((item, index) => {
        return (
          <div className='budgeting-notification' key={index}>
            <div className='budgeting-notifications-row'>
              <div>
                <p className='budgeting-notifications-bold'>{item.type}</p>
                <p className='budgeting-notifications-light'>
                  {item.dateGenerated}
                </p>
              </div>
              <p className='budgeting-notifications-bold'>
                {formatCurrency(item.amount)}
              </p>
            </div>
            <p className='budgeting-notifications-regular'>{item.content}</p>
          </div>
        )
      })}
    </div>
  )
}
