//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//CONTEXT
import { ProductPageContext } from './ProductPage'

//IMAGE
import DashboardImage from '../../../assets/budgeting-insights.png'

export default function GetInsights() {
  const { t, isSmallScreen } = useContext(ProductPageContext)
  return (
    <div className='grey-background'>
      <div className='default-container'>
        <div className='block'>
          <div className='phone-img-container-left'>
            <img src={DashboardImage} className='double-screen-img' />
          </div>
        </div>
        <div className='block'>
          <p className='big-text light-text'>
            {t('get_insights_on_all_your_accounts')}
          </p>
          <p className='small-text'>{t('our_ai_aggregates_message')}</p>
        </div>
      </div>
    </div>
  )
}
