import React, { useState, useRef, useEffect } from 'react'
import { Line } from 'react-chartjs-2'

import { useTransition, animated } from 'react-spring'
import formatCurrency from '../../utility-functions/formatCurrency'

const graphOptions = {
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          stepSize: 50,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
  elements: {
    point: {
      radius: 0,
    },
  },
}

export default function AnalyticsTable(props) {
  const [selected, setSelected] = useState(null)
  const [show, set] = useState(false)
  const scrollRef = useRef(null)

  // useEffect(() => {
  //   handleScroll();
  // }, [selected])

  const transitions = useTransition(show, null, {
    from: { transform: 'translateX(-50%)', opacity: 0, position: 'relative' },
    enter: { transform: 'translateX(0%)', opacity: 1, position: 'relative' },
    leave: { transform: 'translateX(-50%)', opacity: 0, position: 'relative' },
  })

  const forecastChange = (price, forecast) => {
    return (
      ((parseFloat(forecast) - parseFloat(price)) / parseFloat(price)) *
      100
    ).toFixed(2)
  }
  const bearOrBull = (stat) => {
    switch (stat) {
      case 'rsi':
        if (parseFloat(selected.rsi_9_value) < 20.0) {
          return 'Bearish'
        } else if (parseFloat(selected.rsi_9_value) > 80.0) {
          return 'Bullish'
        } else {
          return 'Neutral'
        }
      case 'ema':
        if (selected.ema_3 < selected.ema_10) {
          return 'Bearish'
        } else if (selected.ema_3 > selected.ema_10) {
          return 'Bullish'
        } else {
          return 'Neutral'
        }
      case 'cmf':
        if (selected.cmf_21 < -0.1) return 'Bearish'
        else if (selected.cmf_21 > 0.1) return 'Bullish'
        else return 'Neutral'
      default:
        return 'Neutral'
    }
  }

  const riskNum = (maxDropdown, cvar) => {
    return (
      Math.pow(Math.abs(maxDropdown) / 100, 0.25) *
      Math.pow(Math.abs(cvar), 0.75) *
      100
    ).toFixed(0)
  }

  const riskWord = (riskNum) => {
    switch (riskNum) {
      case riskNum <= 0.15:
        return 'Low'
      case 0.15 < riskNum <= 0.25:
        return 'Moderate'
      default:
        return 'High'
    }
  }

  const handleScroll = () => {
    scrollRef &&
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
  }

  if (props.t) {
    return (
      <div className='analytics-table'>
        {selected ? (
          <>
            <div className='analysis-title'>
              <div
                className='element primary-color-text'
                onClick={() => setSelected(null)}
              >
                <p>{`< ${props.t('back')}`}</p>
              </div>
              <div className='element'></div>
              <div className='element bold-text'>{selected.symbol}</div>
              <div className='element'></div>
              <div className='element'></div>
            </div>
            <div className='table-body'>
              <div className='robust-data-analysis'>
                <div className='data-container'>
                  <p className='bold-text'>{props.t('technicalanalysis')}</p>
                  <div className='analysis-row'>
                    <p>{props.t('currenttrend')}: </p>
                    <p className='bold-text'>
                      {props.t(
                        (
                          selected.trend.charAt(0).toUpperCase() +
                          selected.trend.slice(1)
                        ).toLowerCase()
                      )}
                    </p>
                  </div>
                  <div className='analysis-row'>
                    <p>{props.t('realtiveindexstrength')}: </p>
                    <p className='bold-text'>
                      {props.t(bearOrBull('rsi').toLowerCase())}
                    </p>
                  </div>
                  <div className='analysis-row'>
                    <p>9 {props.t('day')}: </p>
                    <p className='bold-text'>
                      {parseFloat(selected.rsi_9_value).toFixed(2)}
                    </p>
                  </div>
                  <div className='analysis-row'>
                    <p>{props.t('expmovingaverage')}:</p>
                    <p className='bold-text'>
                      {props.t(bearOrBull('ema').toLowerCase())}
                    </p>
                  </div>
                  <div className='analysis-row'>
                    <p>3 {props.t('day')}: </p>
                    <p className='bold-text'>
                      {parseFloat(selected.ema_3).toFixed(2)}
                    </p>
                  </div>
                  <div className='analysis-row'>
                    <p>10 {props.t('day')}: </p>
                    <p className='bold-text'>
                      {parseFloat(selected.ema_10).toFixed(2)}
                    </p>
                  </div>
                  <div className='analysis-row'>
                    <p>{props.t('chaikinmoneyflow')}: </p>
                    <p className='bold-text'>
                      {props.t(bearOrBull('cmf').toLowerCase())}
                    </p>
                  </div>
                  <div className='analysis-row'>
                    <p>21 {props.t('day')}: </p>
                    <p className='bold-text'>
                      {parseFloat(selected.cmf_21).toFixed(2)}
                    </p>
                  </div>
                  <p className='bold-text space-top'>
                    {props.t('technicallevels')}
                  </p>
                  <div className='analysis-row'>
                    <div className='inner-row'>
                      {props.t('support')}
                      <div className='analysis-row'>
                        <p>S1</p>
                        <p className='bold-text'>
                          {formatCurrency(selected.s1)}
                        </p>
                      </div>
                      <div className='analysis-row'>
                        <p>S2</p>
                        <p className='bold-text'>
                          {formatCurrency(selected.s2)}
                        </p>
                      </div>
                      <div className='analysis-row' style={{ border: 'none' }}>
                        <p>S3</p>
                        <p className='bold-text'>
                          {selected.bollinger_down
                            ? formatCurrency(selected.bollinger_down.toFixed(2))
                            : formatCurrency(selected.bollinger_down)}
                        </p>
                      </div>
                    </div>
                    <div className='inner-row'>
                      {props.t('resistance')}
                      <div className='analysis-row'>
                        <p>R1</p>
                        <p className='bold-text'>
                          {formatCurrency(selected.r1)}
                        </p>
                      </div>
                      <div className='analysis-row'>
                        <p>R2</p>
                        <p className='bold-text'>
                          {formatCurrency(selected.r2)}
                        </p>
                      </div>
                      <div className='analysis-row' style={{ border: 'none' }}>
                        <p>R3</p>
                        <p className='bold-text'>
                          {selected.bollinger_up
                            ? formatCurrency(selected.bollinger_up.toFixed(2))
                            : formatCurrency(selected.bollinger_up)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='data-container'>
                  <p className='bold-text'>{props.t('forecast')}</p>
                  <div className='analysis-row header'>
                    <p className='bold-text primary-color-text'>
                      {props.t('forecast')}
                    </p>
                    <p className='bold-text primary-color-text'>
                      {props.t('price')}
                    </p>
                    <p className='bold-text primary-color-text'>
                      {props.t('change')}
                    </p>
                  </div>
                  <div className='analysis-row'>
                    <p>3 {props.t('month')}</p>
                    <p>{formatCurrency(selected.forecast_3m)}</p>
                    <p>
                      {forecastChange(selected.priceUSD, selected.forecast_3m)}%
                    </p>
                  </div>
                  <div className='analysis-row'>
                    <p>6 {props.t('month')}</p>
                    <p>
                      {selected.forecast_6m === 'nan'
                        ? '-'
                        : formatCurrency(selected.forecast_6m)}
                    </p>
                    <p>
                      {selected.forecast_6m === 'nan'
                        ? '-'
                        : forecastChange(
                            selected.priceUSD,
                            selected.forecast_6m
                          )}
                      %
                    </p>
                  </div>
                  <div className='analysis-row'>
                    <p>1 {props.t('year')}</p>
                    <p>
                      {selected.forecast_1y === 'nan'
                        ? '-'
                        : formatCurrency(selected.forecast_1y)}
                    </p>
                    <p>
                      {selected.forecast_1y === 'nan'
                        ? '-'
                        : forecastChange(
                            selected.priceUSD,
                            selected.forecast_1y
                          )}
                      %
                    </p>
                  </div>
                  <div className='analysis-row'>
                    <p>2 {props.t('year')}</p>
                    <p>
                      {selected.forecast_2y === 'nan'
                        ? '-'
                        : formatCurrency(selected.forecast_2y)}
                    </p>
                    <p>
                      {selected.forecast_2y === 'nan'
                        ? '-'
                        : forecastChange(
                            selected.priceUSD,
                            selected.forecast_2y
                          )}
                      %
                    </p>
                  </div>
                  <div className='graph space-top'>
                    <Line
                      data={{
                        labels: [
                          'current',
                          selected.forecast_3m ? '3m' : null,
                          selected.forecast_6m ? '6m' : null,
                          selected.forecast_1y ? '1y' : null,
                          selected.forecast_2y ? '2y' : null,
                        ],
                        datasets: [
                          {
                            data: [
                              selected.priceUSD,
                              selected.forecast_3m,
                              selected.forecast_6m,
                              selected.forecast_1y,
                              selected.forecast_2y,
                            ],
                            borderColor: 'white',
                            backgroundColor: '#716ff6',
                          },
                        ],
                      }}
                      options={graphOptions}
                    />
                  </div>
                  <div className='centered-row'>
                    <div className='highlight'>
                      {riskNum(selected.maxDrawdownVal, selected.cvar)}%
                    </div>
                    {props.t(
                      `${riskWord(
                        riskNum(selected.maxDrawdownVal, selected.cvar)
                      ).toLowerCase()}risk`
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='table-head' onClick={() => handleScroll()}>
              <div className='element'>
                <p></p>
              </div>
              <div className='element'>
                <p>{props.t('currentprice')}</p>
              </div>
              <div className='element-2'>
                <p>{props.t('threemopriceforecast')}</p>
              </div>
              <div className='element'>
                <p></p>
              </div>
            </div>
            <div className='table-body'>
              {props.data.map((symbol, i) => {
                return (
                  <div
                    className='table-row'
                    key={i}
                    onClick={() => {
                      setSelected(symbol)
                    }}
                  >
                    <div className='element bold-text'>
                      {symbol.type === 'Crypto'
                        ? symbol.symbol.substring(0, symbol.symbol.length - 4)
                        : symbol.symbol}
                    </div>
                    <div className='element'>${symbol.priceUSD}</div>
                    <div className='element'>${symbol.forecast_3m}</div>
                    <div className='element highlight'>
                      <p>
                        {forecastChange(symbol.priceUSD, symbol.forecast_3m)}%
                      </p>
                    </div>
                    <div className='element'>{'>'}</div>
                  </div>
                )
              })}
            </div>
          </>
        )}
        {/* <div className="analytics-ref" ref={scrollRef}></div> */}
      </div>
    )
  } else return null
}
