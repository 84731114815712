import React, { useEffect, useState } from 'react'
import NewtInput from './NewtInput'
import axios from 'axios'
import NewtIcon from '../../../assets/newt/newt_white_gradient.png'
import { FaExternalLinkAlt } from 'react-icons/fa'

const Endpoint = 'https://dev-api.allocaterite.com'

export default function NewtNewThread(props) {
  const [examplePrompts, setExamplePrompts] = useState(null)
  const [loading, setLoading] = useState(true)
  const [selectedCategory, setSelectedCategory] = useState('ALL')
  const [allQueries, setAllQueries] = useState([]) // Store all queries

  useEffect(() => {
    fetchPrompts()
  }, [])

  useEffect(() => {
    if (examplePrompts) {
      console.log('examplePrompts', examplePrompts)
      const all_queries = Object?.values(examplePrompts?.queries)?.flat()
      setAllQueries(all_queries) // Store all queries in state
      setLoading(false)
    }
  }, [examplePrompts])

  const fetchPrompts = async () => {
    try {
      let res = await axios.get(`${Endpoint}/gpt/prompt/examples`, {
        withCredentials: false,
        headers: {
          Authorization: 'Bearer ' + props.jwtToken,
        },
      })

      console.log('[API RESPONSE] - /prompt/examples', res?.data)

      if (res?.data) {
        setExamplePrompts(res?.data)
      }
    } catch (error) {
      console.log(error)

      if (error?.response?.data?.code === 401) {
        console.log('Token expired in fetchprompts function')
        window.location.reload()
      }
    }
  }

  return (
    <div className='new-thread-container'>
      <div className='newt-home-header'>
        <img src={NewtIcon} alt='newt' style={{ height: 64, aspectRatio: 1 }} />
        <p style={{ fontSize: 28, fontWeight: 'bold' }} className=''>
          Newt
        </p>
        <p style={{ fontSize: 18 }}>
          The most powerful{' '}
          <span
            style={{
              background: 'linear-gradient(88deg, #3F46F6 20%, #FA00FF 80%)',
              WebkitBackgroundClip: 'text', // Vendor prefix for WebKit browsers
              backgroundClip: 'text',
              color: 'transparent', // Ensure text color is transparent
              WebkitTextFillColor: 'transparent', // Specific to WebKit browsers
              fontWeight: 'bold',
            }}
          >
            AI agent
          </span>{' '}
          for finance
        </p>
      </div>
      <NewtInput
        sendPrompt={props.sendPrompt}
        setPromptInput={props.setPromptInput}
        promptInput={props.promptInput}
      />
      {!loading && (
        <div className='newt-categories-container'>
          {/* Add button for "ALL" category */}
          <button
            onClick={() => setSelectedCategory('ALL')}
            key='ALL'
            style={{
              background:
                selectedCategory === 'ALL'
                  ? 'linear-gradient(90deg, rgb(62, 77, 240) 0%, rgb(193, 27, 197) 100%)'
                  : '#e7e7e7',
              color: selectedCategory === 'ALL' ? 'white' : 'inherit',
            }}
          >
            ALL
          </button>
          {Object?.keys(examplePrompts?.category_names)?.map((category, i) => {
            return (
              <button
                onClick={() => setSelectedCategory(category)}
                key={i}
                style={{
                  background:
                    selectedCategory === category
                      ? 'linear-gradient(90deg, rgb(62, 77, 240) 0%, rgb(193, 27, 197) 100%)'
                      : '#e7e7e7',
                  color: selectedCategory === category ? 'white' : 'inherit',
                }}
              >
                {examplePrompts?.category_names?.[category]}
              </button>
            )
          })}
        </div>
      )}

      {/* Display the prompts based on selected category */}
      {selectedCategory === 'ALL' && (
        <div className='newt-prompts'>
          {allQueries.map((prompt, index) => (
            <div
              className='sample-prompt'
              key={index}
              onClick={() => props.sendPrompt(prompt)}
            >
              {prompt}
              <FaExternalLinkAlt style={{ marginTop: 2 }} />
            </div>
          ))}
        </div>
      )}
      {selectedCategory !== 'ALL' &&
        examplePrompts?.queries[selectedCategory] && (
          <div className='newt-prompts'>
            {examplePrompts?.queries[selectedCategory].map((prompt, index) => (
              <div
                className='sample-prompt'
                key={index}
                onClick={() => props.sendPrompt(prompt)}
              >
                {prompt}
                <FaExternalLinkAlt style={{ marginTop: 2 }} />
              </div>
            ))}
          </div>
        )}
    </div>
  )
}
