import { Button } from 'primereact/button'
import React, { Component } from 'react'
import '../../../../../scss/pages/account-activation/robo/Intro.scss'
import Loader from '../../Loader'
import StrategyInfoGraph from '../../StrategyInfoGraph'
import AllocationPieChart from '../../../AllocationPieChart'
import { Dialog } from 'primereact/dialog'

export default class Intro extends Component {
  state = {
    showAllocationPieChart: false,
  }

  toggleAllocationPieChart = () => {
    this.setState({
      showAllocationPieChart: !this.state.showAllocationPieChart,
    })
  }
  activateContainer = () => {
    return (
      <div className='activate-container'>
        <div className='top-info'>
          <p className='heading'>{this.props.t('roboisnotactiveyet')}</p>
          <p className='info'>{this.props.t('startrobonowtoinvest')}</p>
        </div>
        <div
          className='start-button'
          onClick={() => {
            this.props.addHistory('Investment-Form')
          }}
        >
          <p className='button-label'>{this.props.t('activaterobo')}</p>
        </div>
      </div>
    )
  }

  strategyInformation() {
    const isCustom =
      this.props.productInstanceData?.strategyInfo?.id === 0 ? true : false
    const strategyInfo = isCustom
      ? this.props.productInstanceData.strategyInfo
      : this.props.strategyInfo[this.props.productInstanceData.strategyInfo?.id]
    let seriesData = []
    if (!isCustom) {
      strategyInfo.graph.forEach((ele) => {
        seriesData.push([
          Date.parse(ele.date),
          parseFloat((ele.aggregate_return * 100).toFixed(2)),
        ])
      })
    }
    let mtd = (this.props.productInstanceData.strategyInfo?.mtd * 100).toFixed(
      2
    )
    let ytd = (this.props.productInstanceData.strategyInfo?.ytd * 100).toFixed(
      2
    )
    let cvar = Math.abs(
      (this.props.productInstanceData.strategyInfo?.cvar * 100).toFixed(2)
    )

    let allocationsData = []
    if (
      this.props.productInstanceData.allocations &&
      this.props.productInstanceData.allocations.length > 0
    ) {
      this.props.productInstanceData.allocations.forEach((allocation) => {
        allocationsData.push({
          name: allocation.symbol,
          value: parseFloat((allocation.allocation * 100).toFixed(2)),
        })
      })
    }
    console.log('allocations', allocationsData)
    return (
      <div className='strategy-info-container'>
        <p className='name'>
          {isCustom ? strategyInfo?.name : strategyInfo?.fullname}
        </p>
        <p className='description'>{strategyInfo?.description}</p>
        {!isCustom && (
          <StrategyInfoGraph seriesData={seriesData} t={this.props.t} />
        )}
        {isCustom && (
          <div
            className=''
            style={{ height: 800, maxHeight: 800, overflow: 'auto' }}
          >
            <AllocationPieChart
              seriesData={allocationsData}
              t={this.props.t}
              verticalAlign={false}
              isSmallScreen={this.props.isSmallScreen}
            />
          </div>
        )}
        {!isCustom && <p className='disclaimer'>{strategyInfo.disclaimer}</p>}
        {!isCustom && (
          <div className='specific-info'>
            <div className='row'>
              <p className='label'>{this.props.t('monthtodate')}</p>
              <p className={`value ${mtd < 0 ? 'negative' : ''}`}>{mtd}%</p>
            </div>
            <div className='row'>
              <p className='label'>{this.props.t('yeartodate')}</p>
              <p className={`value ${ytd < 0 ? 'negative' : ''}`}>{ytd}%</p>
            </div>
            <div className='row'>
              <p className='label'>{this.props.t('expectedpotentialrisk')}</p>
              <p className='value'>{cvar}%</p>
            </div>
          </div>
        )}

        {!isCustom && this.allocation()}
      </div>
    )
  }

  allocation() {
    if (
      this.props.productInstanceData.allocations &&
      this.props.productInstanceData.allocations.length > 0
    ) {
      let seriesData = []
      this.props.productInstanceData.allocations.forEach((allocation) => {
        seriesData.push({
          name: allocation.symbol,
          value: parseFloat((allocation.allocation * 100).toFixed(2)),
        })
      })
      return (
        <div>
          <p
            style={{
              fontSize: '15px',
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: '0',
              cursor: 'pointer',
              color: '#7147E9',
            }}
            onClick={() => {
              this.toggleAllocationPieChart()
            }}
          >
            {this.props.t('viewallocations')}
          </p>
          <Dialog
            visible={this.state.showAllocationPieChart}
            style={{
              width: this.props.isSmallScreen ? '90vw' : '30vw',
              height: this.props.isSmallScreen ? '90vh' : '50vh',
            }}
            onHide={() => this.toggleAllocationPieChart()}
            header={
              <p className='allocation-dialog-header'>
                {this.props.t('allocation')}
              </p>
            }
          >
            <AllocationPieChart
              seriesData={seriesData}
              t={this.props.t}
              verticalAlign={false}
              isSmallScreen={this.props.isSmallScreen}
            />
          </Dialog>
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    if (this.props.t) {
      return (
        <div className='intro-container'>
          <p onClick={() => this.props.toggleShowRoboStrategyInfo()}>Back</p>
          {/* {this.activateContainer()} */}
          {this.props.strategyInfo && this.props.productInstanceData ? (
            this.strategyInformation()
          ) : (
            <Loader
              isSmallScreen={this.props.isSmallScreen}
              logoLoader={false}
            />
          )}
        </div>
      )
    } else return null
  }
}
