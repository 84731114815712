//REACT
import React from 'react'
import { useState, useContext, useEffect } from 'react'

//CONTEXT
import { ProductPageContext } from './ProductPage'

//IMAGE
import DashboardImage from '../../../assets/investall-dashboard.png'

export default function GetPersonalizedDashboard() {
  const { t, isSmallScreen } = useContext(ProductPageContext)
  return (
    <div className='blue-background'>
      <div className='default-container'>
        <div className='block'>
          <p className='big-text light-text space-bottom'>
            {t('get_personalized_dashboard_with_your_full_financial_picture')}
          </p>
          <p className='small-text'>
            {t('connect_your_accounts_to_investall_msg')}
          </p>
          {/* TRANSLATE LINE BELOW */}
          <p className='small-disclaimer-text'>
            Chart is not indicative of future performance, it is for simulation
            purposes only.
          </p>
        </div>
        <div className='block'>
          <div className='phone-img-container-right'>
            <div className='center-content'>
              <img src={DashboardImage} className='phone-img' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
