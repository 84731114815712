import React from 'react'
import LinkToPlaid from '../LinkToPlaid'
import PlaidLogo from '../../assets/newUI/Plaid_Logo.svg'
import DepositLogo from '../../assets/newUI/deposit.png'
import CheckDemo from '../../assets/newUI/CheckDemo.jpg'
import Tranfers from './Transfers'
import ENDPOINT from '../Endpoint'



export default class DepositModal extends React.Component{

    state = {
        paymentType:'false',
        depositAmount:0,
        recurringTime:'ONE',
        routingNumber:'',
        accountNumber:'',
        // step:0, 
        step:1.1,//NO ACH 
        dwBanks: [],
        depositStatus:false,
        plaidAccountID:false,
        plaid:true,
    }

    componentDidMount(){
        // this.getDwBankAccs()
    }

    singleDeposit=()=>{
        if(this.state.plaid){
            this.singleDepositPlaid()
        }
        else{
            this.singleDepositACH()
        }
    }

    singleDepositACH = ()=>{

        let body = {
            "dwAccountID": this.props.acc.accountId,
            "sourceType": "manual",
            "plaidAccountID": this.state.plaidAccountID,
            "amount": this.state.depositAmount
        }

        // fetch(`${ENDPOINT}/dw/deposits/onetime`,{
        fetch(`${ENDPOINT}/dw/deposits/onetime?sandbox=true`,{
            method: "POST",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          })
        .catch(err => console.log('single deposit error message: ', err))
        .then(res => res.json())
        .then(responseJson => {
            console.log('ach single resp: ', responseJson)
            console.log('ach single resp.success: ', responseJson.success)
            if(responseJson.success){
                this.setState({step:4})
            }
            else{
              this.setState({depositStatus:responseJson.payload})
            }
          }
      )
    }


    singleDepositPlaid = ()=>{
        console.log('running single!')
        this.setState({depositStatus:'Loading...'})
        let body = {
            "dwAccountID": this.props.acc.accountId,
            "sourceType": "plaid",
            "plaidAccountID": this.state.plaidAccountID,
            "amount": this.state.depositAmount,
        }
        console.log('depo body',body )
        fetch(`${ENDPOINT}/dw/deposits/onetime`,{
            method: "POST",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          })
          .then(res => res.json())
          .catch(err => console.log('single deposit error message: ', err))
          .then(responseJson => {
              console.log('single resp: ', responseJson)
              console.log('single resp.success: ', responseJson.success)
              if(responseJson.success){
                  this.setState({step:4})
              }
              else{
                this.setState({depositStatus:responseJson.payload})
              }
            }
        )
    }

    recurringDeposit = ()=>{
        console.log('running recurring!')
        this.setState({depositStatus:'Loading...'})

        let body = {
            "dwAccountID": this.props.acc.accountId,
            "sourceType": "manual",
            "dwBankAccountID": this.state.dwBankAccountID,
            "amount": this.state.depositAmount,
            "frequency": "MONTHLY_MIDDLE"
        }
        fetch(`${ENDPOINT}/dw/deposits/recurring`,{
            method: "POST",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          })
          .then(res => res.json())
          .then(responseJson => {
              console.log('recurring resp: ', responseJson)
              console.log('recurring resp.success: ', responseJson.success)
              if(responseJson.success){
                this.setState({step:4})
            }
            else{
                this.setState({depositStatus:responseJson.payload})
              }
            }
        )
        .catch(err => console.log('recurring deposit error message: ', err))

    }

    createDwBank = ()=>{
        let body = {
            "routingNumber": this.state.routingNumber,
            "accountNumber": this.state.accountNumber
        }
        fetch(`${ENDPOINT}/dw/bank-accounts/create`,{
            method: "POST",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          })
          .then(res => res.json())
          .then(responseJson => {
            //   console.log('creation res: ', responseJson)
            //   console.log('creation res.payload: ', responseJson.payload)
            //   console.log('creation res.payload.dwBankAccountID: ', responseJson.payload.dwBankAccountID)
              let dwBankAccountID = responseJson.payload.dwBankAccountID
              this.setState({dwBankAccountID}) 
            }
        )
        .catch(err => console.log('create DW bank error message: ', err))
    }

    getDwBankAccs=()=>{
        fetch(`${ENDPOINT}/dw/bank-accounts`,{
            method:"GET",
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(responseJson => {
            console.log( 'DwBank Accs',responseJson)
            this.setState({dwBanks:responseJson})
        })
        .catch(err => console.log('get dwBank Accs error message: ', err))
    }

    setDeposit = ()=>{
        const {acc} = this.props 
        return(
            <div style={{width:'100%'}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%',marginTop:25 }}>
                        <p style={{fontSize:20}}>
                            Deposit into Account {acc.inst === 'DriveWealth'? 'DriveWealth '+ acc.mask_full  :`${acc.inst} ${acc.mask}`}
                        </p>
                    </div>
                    
                    <div style={{paddingTop:25, display:'flex', alignItems:'center',justifyContent:'space-between', width:'95%',margin:'auto', padding:20 }}>
                        <p style={{margin:0, marginRight:25,fontSize:20}}>
                            Deposit Amount: 
                        </p>
                        <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:'40%'}}>
                            <input 
                                style={{border:'none',textAlign:'right', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent',}}
                                type="text" 
                                value={this.state.depositAmount} 
                                onChange={(e)=>this.setState({depositAmount: e.target.value})}
                            />
                            <p style={{margin:0,color:'rgb(52,131,129)'}}>(USD)</p>
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%', }}>
                        <p style={{fontSize:20}}>
                            How often would you like these payments to occur?
                        </p>
                        <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:'40%'}}>
                            <select placeholder="Select Below" onChange={(e)=>this.setState({recurringTime:e.target.value})} value={this.state.recurringTime} style={{border:'none',textAlign:'right', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent',}}>
                                <option value='ONE' selected={this.state.recurringTime == 'ONE'}>One Time Deposit</option>
                                <option value='MONTHLY' selected={this.state.recurringTime == 'MONTHLY'}>Monthly</option>
                                <option value='YEARLY' selected={this.state.recurringTime == 'YEARLY'}>Yearly</option>                            
                            </select>
                        </div>
                    </div>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'rgb(255,255,255)', padding:20, margin:'auto', width:'95%', }}>
                        <p>*All Amounts above should be in US Dollars</p>
                    </div>
                    {this.state.depositStatus ? 
                        <div style={{display:'flex',justifyContent:'center',alignItems:'center', width:'100%'}}>
                            <div style={{display:'flex', margin:'auto', width:'60%', backgroundColor:this.state.depositStatus === 'Loading...'?'rgb(225,225,235)':'rgb(253,180,181)', padding:12, marginBottom:10, borderRadius:8,}}>
                                <p style={{margin:'auto'}}>
                                {this.state.depositStatus}
                                </p> 
                            </div>
                        </div>:null
                    }
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center', width:'100%'}}>
                        <div 
                            style={{display:'flex', cursor:'pointer',justifyContent:'center',alignItems:'center', width:'50%', paddingLeft:12, paddingRight:12, paddingTop:10, paddingBottom:10, border:'solid', borderWeight: '0.5px',borderColor:'#3b8e8c', borderRadius:10}}
                            onClick={this.state.recurringTime === 'ONE'?()=>this.singleDeposit():()=>this.recurringDeposit()}
                        >
                            <p style={{color:'#3b8e8c', margin:0, fontWeight:'bold'}}>Deposit Funds</p>
                        </div>
                    </div>
            </div>
        )
    }

    addCommas(x){
        if(x === ''){
            return ''
        }else{
            let arr = x.toString().split(".")
            if(arr[1]){
                return (arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","))+ '.' + arr[1]
            }
            else{
                return  (arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","))
            }
        } 
    }

    manualPlaid = ()=>{
        return(
            <div style={{width:'95%', margin:'auto'}}>
                <div style={{width:'100%', marginTop:25, marginBottom:25 }}>
                    <p style={{fontSize:28, textAlign:'center'}}>How would you like to Deposit Funds?</p>
                </div>
                <div style={{ display:'flex', justifyContent:'space-evenly'}}>
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', borderRadius:20, width:this.props.isSmallScreen? '100%':'45%', backgroundColor:'rgb(110,183,181)', marginBottom:25, paddingTop:'10%',paddingBottom:'10%', cursor:'pointer'}} onClick={()=>this.setState({step:1.1,plaid:true})}> 
                        <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', height:'50%'}}>
                            <img
                                src={PlaidLogo}
                                style={{width:200}}
                            />
                        </div>
                        <div style={{width:'95%',margin:'auto', marginTop:50}}>
                            <p style={{margin:0, fontSize: 22, color:'rgb(255,255,255)', textAlign:'center'}}>Deposit With Plaid</p>
                            <p style={{margin:0, marginTop:20, fontSize: 18, color:'rgb(255,255,255)', textAlign:'center'}}>Link a checking account to deposit funds once</p>
                        </div>
                        
                    </div>
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center', borderRadius:20, width:this.props.isSmallScreen? '100%':'45%', backgroundColor:'rgb(95,128,244)', marginBottom:25, paddingTop:'10%',paddingBottom:'10%', cursor:'pointer'}} onClick={()=>this.setState({step:1.2,plaid:false})}>
                        <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', height:'50%'}}>   
                            <img
                                src={DepositLogo}
                                style={{width:100}}

                            />
                        </div>
                        <div style={{width:'95%',margin:'auto', marginTop:50}}>
                            <p style={{margin:0, fontSize: 22, color:'rgb(255,255,255)', textAlign:'center'}}>Manually Deposit Funds </p>
                            <p style={{margin:0, marginTop:20, fontSize: 18, color:'rgb(255,255,255)', textAlign:'center'}}>Using a bank account number and routing number to deposit funds </p>
                        </div>
                    </div>
                    
                </div>
            </div>
        )
    }


    selectAccount = (type)=>{
        let accounts = []
        console.log('type', type)
        if(type==='plaid'){
            this.props.allAccounts.forEach(ele=>{
                if(ele.type === 'depository'){
                    accounts.push({
                        instName:ele.inst,
                        mask:ele.mask,
                        balances:ele.balances,
                        accountId: ele.accountId
                    })
                }
            })
        }else{
            accounts = [...this.state.dwBanks]
        }
        console.log('accounts', accounts)
        let mapped = [
            <div
                style={{
                    padding: 20,
                    width: '100%',
                    borderRadius: 8,
                    // margin: 10,
                    height: "85%",
                    textDecoration: "none",
                    cursor: 'pointer',
                }}
            >
                <div style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 8,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 8,
                    textDecoration: "none",
                }}>
                    <div style={{width:'30%', display:'flex', justifyContent:"flex-start", alignItems:'center'}}>
                        <p style={{margin:0, fontSize:20}}> Inst</p>
                    </div>
                    <div style={{width:'20%', display:'flex', justifyContent:"center", alignItems:'center'}}>
                        <p style={{margin:0, fontSize:20}}> Acc # </p>
                    </div>
                    <div style={{width:'30%', display:'flex', justifyContent:"flex-end", alignItems:'center'}}>
                        <p style={{margin:0, fontSize:20}}> Value</p>
                    </div>
                </div>
            </div>,
        ]
        accounts.forEach(ele=>{
            console.log('ele',ele)
            mapped.push(
                <div
                    style={{
                        padding: 25,
                        borderRadius: 8,
                        margin: 10,
                        height: "85%",
                        backgroundColor: this.state.hover === ele.itemId ? '#F8F9FA': "rgb(248,249,250)",
                        textDecoration: "none",
                        cursor: 'pointer',
                    }}
                    onClick={()=>this.setState({plaidAccountID:ele.accountId, step:3})}
                >
                    <div style={{
                        // paddingLeft: 10,
                        // paddingRight: 10,
                        borderRadius: 8,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 8,
                        textDecoration: "none",
                    }}>
                        <div style={{width:'30%', display:'flex', justifyContent:"flex-start", alignItems:'center'}}>
                            <p style={{margin:0}}>{ele.instName}</p>
                        </div>
                        
                        <div style={{width:'20%', display:'flex', justifyContent:"flex-end", alignItems:'center'}}>
                            <p style={{margin:0}}>{ele.mask}</p>
                        </div>
                        <div style={{width:'30%', display:'flex', justifyContent:"flex-end", alignItems:'center'}}>
                            <p style={{margin:0}}><strong style={{fontSize:20}}>${ele.balances&&ele.balances.length>0&&ele.balances[0].current? this.addCommas(ele.balances[0].current.toFixed()):'0.00'}</strong></p>
                        </div>
                    </div>
                </div>
            )
        })
        return(
            <div style={{display:'flex', flexDirection:'column'}}>
            <div style={{width:'90%', margin:'auto',marginBottom:25, marginTop:25}}>
                <h4 style={{color:'rgb(17,17,17)'}}>Select account</h4>
            </div>
            <div style={{width:'95%', margin:'auto', backgroundColor:'rgb(255,255,255)',borderRadius:12 }}>
                {mapped.length ===1? null :mapped}
            </div>
            <div>
                {
                type === 'plaid'? 
                    <div style={{width:'40%', margin:'auto'}}>
                        <LinkToPlaid bank={true} linkedPorts={this.props.allAccounts} numAccounts={0} user={this.props.user}/>
                    </div>
                :
                    <div 
                        onClick ={()=>this.setState({step:2.2})}
                        style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20, width: '40%', cursor:'pointer', margin:'auto'}}>
                        <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold'}}>
                            Use New Account
                        </p>
                    </div>
                }
            </div>
        </div>
        )
    }

    enterBankInfo = ()=>{
        return(
            <div style={{width:'100%',}}>
                <div style={{width:'95%', margin:'auto',paddingBottom:10, paddingTop:25}}>
                    <h4 style={{color:'rgb(17,17,17)'}}>Set Up Bank Transfer</h4>
                </div>
                <div style={{ display:'flex', alignItems:'center',justifyContent:'space-between', width:'95%',margin:'auto', padding:10 }}>
                    <p style={{margin:0, marginRight:25,fontSize:20}}>
                        Account Number: 
                    </p>
                    <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:'40%'}}>
                        <input 
                            style={{border:'none',textAlign:'right', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent',}}
                            type="text" 
                            placeholder="1234567890"
                            value={this.state.accountNumber} 
                            onChange={(e)=>this.setState({accountNumber: e.target.value})}
                        />
                    </div>
                </div>
                <div style={{paddingTop:25, display:'flex', alignItems:'center',justifyContent:'space-between', width:'95%',margin:'auto', padding:10 }}>
                    <p style={{margin:0, marginRight:25,fontSize:20}}>
                        Routing Number: 
                    </p>
                    <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:'40%'}}>
                        <input 
                            style={{border:'none',textAlign:'right', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent',}}
                            type="text" 
                            placeholder="123456789"
                            value={this.state.routingNumber} 
                            onChange={(e)=>this.setState({routingNumber: e.target.value})}
                        />
                    </div>
                </div>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                    <img
                        src={CheckDemo}
                        style={{width:'80%'}}
                    />
                </div>
                <div style={{width:'100%'}}>
                    <div 
                        onClick={()=>{
                            this.createDwBank()
                            this.setState({step:3})
                        }}
                        style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20, width: '40%', cursor:'pointer',margin:'auto'}}>
                        <p style={{margin: 0, color: '#3b8e8c', fontWeight: 'bold'}}>
                            Submit
                        </p>
                    </div>
                </div>
            </div>
        )

    }

    successfulDeposit = ()=>{
        return(
            <div>
                 <div style={{width:'95%', margin:'auto',paddingBottom:10, paddingTop:25}}>
                    <h4 style={{color:'rgb(17,17,17)'}}>Deposit Successful!</h4>
                </div>
                <div style={{width:'95%',margin:'auto'}}>
                    <p>You have deposited {this.state.depositAmount} into your account. It may take up to 48 hours for funds to successfully get to your account. You will receive an email when the funds are successfully deposited. In the event we could not deposit the funds you will recieve and email explaining what went wrong.</p>
                </div>
            </div>
        )
    }

    whatStep = ()=>{
        switch (this.state.step){
            case 0:
                return this.manualPlaid()
            case 1.1:
                return this.selectAccount('plaid')
            case 1.2:
                if(this.state.dwBanks.length > 1){
                    return this.selectAccount('manual')
                }
                else{
                    return this.enterBankInfo()
                }
            case 2.2:
                return this.enterBankInfo()
            case 3:
                return this.setDeposit()
            case 4:
                return this.successfulDeposit()
        }
    }

    render(){
        return(
            <div style={{
                backgroundColor: 'rgba(0,0,0,0.5)',
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'auto',
                zIndex: 99999,
                }}>
                <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', height: '90%', paddingBottom:25}}>
                    <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                        <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}> Transfer Funds</p>
                        <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.props.closeModal()}> X </p>
                    </div>
                    <div style={{width:'100%'}}>
                        {/* {this.whatStep()} */}
                        <Tranfers isSmallScreen={this.props.isSmallScreen} user={this.props.user} allAccounts={this.props.allAccounts}/>
                    </div>
                </div>
            </div>
        )
    }
}