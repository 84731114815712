import React from 'react'

export default function NewtDownloadButton({
  downloadUrl,
  children,
  icon,
  style,
}) {
  return (
    <a
      href={downloadUrl}
      download
      className={`flex items-center gap-1 text-primary ${style ? style : ''}`}
    >
      {icon}
      {children}
    </a>
  )
}
