import React, { Component } from 'react'
import Loader from './Loader'
import GroupBasicInfo from './group/GroupBasicInfo'
import GroupInvestments from './group/GroupInvestments'
import GroupRecurBuys from './group/GroupRecurBuys'
import GroupPendingTransfers from './group/GroupPendingTransfers'
import GroupSpecificInfo from './group/GroupSpecificInfo'
import OrderHistory from './OrderHistory'
import TransferHistory from './TransferHistory'
import TransferFundsDW from './TransferFundsDW'
import Statements from '../Statements'
import ProductActivationBackIcon from '../../../assets/product-activation-back-icon.svg'
import AccountActivity from '../AccountActivity'
import {
  selectedTab,
  unSelectedTab,
  selectedText,
  unSelectedText,
} from '../../../utility-functions/tabStyles'
import { getTradeGroupSuggestions } from '../../../services/userServices'

export default class GroupAccountInfo extends Component {
  state = {
    activeTab: 'General Info',
    stockSuggestions: null,
  }

  componentDidMount() {
    this.getStockSuggestions()
  }

  getStockSuggestions = async () => {
    const res = await getTradeGroupSuggestions(
      this.props.user.idToken.jwtToken,
      this.props.selectedAccount?.accountId
    )
    this.setState({ stockSuggestions: res })
  }

  tabs() {
    const customSelectedTab = { ...selectedTab, backgroundColor: '#5C21D8' }
    return (
      <div
        style={{
          width: this.props.isSmallScreen ? '100%' : '100%',
          display: 'flex',
          justifyContent: 'space-evenly',
          flexWrap: 'wrap',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <div
          onClick={() => this.setState({ activeTab: 'General Info' })}
          style={
            this.state.activeTab === 'General Info'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'General Info'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('ai stock trader')}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Transfer Funds' })}
          style={
            this.state.activeTab === 'Transfer Funds'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Transfer Funds'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('transferfunds')}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Account Activity' })}
          style={
            this.state.activeTab === 'Account Activity'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Account Activity'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('accountactivity')}
          </p>
        </div>
        <div
          onClick={() => this.setState({ activeTab: 'Documents' })}
          style={
            this.state.activeTab === 'Documents'
              ? customSelectedTab
              : unSelectedTab
          }
        >
          <p
            style={
              this.state.activeTab === 'Documents'
                ? selectedText
                : unSelectedText
            }
          >
            {this.props.t('documents')}
          </p>
        </div>
      </div>
    )
  }

  showSelectedTab = () => {
    if (this.state.activeTab === 'General Info') return this.generalInfo()
    else if (this.state.activeTab === 'Transfer Funds') {
      return (
        <TransferFundsDW
          accounts={this.props.accounts}
          isSmallScreen={this.props.isSmallScreen}
          formatCurrency={this.props.formatCurrency}
          user={this.props.user}
          getAllAccountInfo={this.props.getAllAccountInfo}
          t={this.props.t}
          selectedAccount={this.props.selectedAccount}
          ibStatus={this.props?.ibStatus}
          accountType={'AI Trader'}
        />
      )
    } else if (this.state.activeTab === 'Account Activity')
      // return (
      //   <div style={{ height: this.props.isSmallScreen ? '100vh' : '50vh' }}>
      //     {this.props.accountTransactionsData ? (
      //       <OrderHistory {...this.props} />
      //     ) : (
      //       <Loader
      //         isSmallScreen={this.props.isSmallScreen}
      //         logoLoader={false}
      //       />
      //     )}
      //     {this.props.accountTransactionsData ? (
      //       <TransferHistory {...this.props} />
      //     ) : (
      //       <Loader
      //         isSmallScreen={this.props.isSmallScreen}
      //         logoLoader={false}
      //       />
      //     )}
      //   </div>
      // )
      return (
        <div style={{ height: this.props.isSmallScreen ? '100vh' : '50vh' }}>
          {this.props.accountTransactionsData ? (
            <AccountActivity {...this.props} />
          ) : (
            <Loader
              isSmallScreen={this.props.isSmallScreen}
              logoLoader={false}
            />
          )}
        </div>
      )
    else if (this.state.activeTab === 'Documents')
      return (
        <div>
          {this.props.statements ? (
            <Statements
              statements={this.props.statements}
              selectedAccount={this.props.selectedAccount}
              user={this.props.user}
              t={this.props.t}
            />
          ) : (
            <Loader
              isSmallScreen={this.props.isSmallScreen}
              logoLoader={false}
            />
          )}
        </div>
      )
    else return null
  }

  generalInfo() {
    return (
      <div>
        {this.props.productInstanceData &&
        this.props.accountTransactionsData ? (
          <>
            <GroupBasicInfo {...this.props} />
            <GroupSpecificInfo {...this.props} />
            <GroupInvestments
              {...this.props}
              stockSuggestions={this.state.stockSuggestions}
            />
            {/* <GroupRecurBuys {...this.props} /> */}
            <GroupPendingTransfers {...this.props} />
          </>
        ) : (
          <Loader isSmallScreen={this.props.isSmallScreen} logoLoader={false} />
        )}
      </div>
    )
  }

  render() {
    if (this.props.t) {
      return (
        <div>
          <div
            style={{
              width: this.props.isSmallScreen ? '100%' : '60%',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                gap: '0.5rem',
              }}
            >
              <img
                src={ProductActivationBackIcon}
                height={25}
                width={25}
                onClick={() => {
                  this.props.resetSelectedAccount()
                  if (this.props?.toggleAccount) {
                    this.props.toggleAccount()
                  }
                }}
                style={{ cursor: 'pointer', marginBottom: '25px' }}
              />
              <p style={{ paddingTop: '0.2rem' }}>
                {' '}
                {this.props?.t('allaccounts')}
              </p>
            </div>
            {this.tabs()}
            <div
              style={{
                width: this.props.isSmallScreen ? '100%' : '80%',
                margin: 'auto',
                height: '100%',
              }}
            >
              {this.showSelectedTab()}
            </div>
          </div>
        </div>
      )
    } else return null
  }
}
