import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

class SecureYourFuture extends Component {
  state = {
    isChinese: this.props.isChinese
  };

  render() {
    return (
      <Container>
        <Row>
          <Col className="secure-you-future-title-col">
            {this.state.isChinese ? (
              <p className="secure-you-future-title">确保您的未来</p>
            ) : (
              <p className="secure-you-future-title">
                Secure Your Future, today.
              </p>
            )}
          </Col>
        </Row>
        
      </Container>
    );
  }
}

export default SecureYourFuture;
