import React from 'react'
import { NavLink } from 'react-router-dom'

export default class oneIndex extends React.Component {
  state = {
    hover: false,
  }

  addCommas = (x) => {
    x = x.toString()
    if (x === '') {
      return ''
    } else {
      let y = x.split('.')
      let z = y[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      if (typeof y[1] === 'undefined') {
        return z + '.00'
      }
      return z + '.' + y[1]
      // return z
    }
  }

  render() {
    // console.log('oi props: ', this.props)
    if (!this.props.is_index) {
      return (
        // <div style={{paddingLeft:20,paddingRight:20, paddingTop:10, paddingBottom:10, backgroundColor:this.props.price_delta<0?'rgb(253,180,181)':'rgb(200,238,210)', width:'20%', borderRadius:12,}}>
        <NavLink
          exact={true}
          to={`/Dashboard/Trade/${this.props.symbol}`}
          style={{
            textDecoration: 'inherit',
            backgroundColor: this.state.hover ? '#bbbbbb' : '',
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <div
            onMouseEnter={() => this.setState({ hover: true })}
            onMouseLeave={() => this.setState({ hover: false })}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                            <p style={{margin:0,fontSize:20, color:this.props.price_delta<0?'rgb(234,50,35)':'rgb(82,140,139)'}}>{this.props.symbol}</p>
                        <div style={{}}>
                            <p style={{margin:0, textAlign:'right', color:this.props.price_delta<0?'rgb(234,50,35)':'rgb(82,140,139)'}}>${this.props.real_time_price?this.addCommas(this.props.real_time_price.toFixed(2)):null}</p>
                            <p style={{margin:0, textAlign:'right', color:this.props.price_delta<0?'rgb(234,50,35)':'rgb(82,140,139)'}}>{this.props.price_delta>0?'+':''}{this.props.price_delta?this.addCommas((this.props.price_delta).toFixed(2)):null}%</p>
                        </div>
                        </div> */}
            <p
              style={{
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 20,
                marginRight: 0,
                fontSize: 20,
                color: 'white',
                whiteSpace: 'nowrap',
              }}
            >
              {this.props.symbol}
            </p>
            <p
              style={{
                marginLeft: '5%',
                marginRight: '5%',
                marginTop: 0,
                marginBottom: 0,
                color: 'white',
                fontSize: 20,
              }}
            >
              {this.addCommas(this.props.real_time_price.toFixed(2))}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: 20,
                color:
                  this.props.price_delta < 0
                    ? 'rgb(234,50,35)'
                    : 'rgb(82,140,139)',
              }}
            >
              {this.props.price_delta > 0 ? '+' : ''}
              {this.addCommas(this.props.price_delta.toFixed(2))}%
            </p>
            <div
              style={{
                width: 4,
                backgroundColor: 'white',
                height: '50%',
                color: 'white',
                marginRight: 0,
                marginLeft: 20,
              }}
            >
              &nbsp;
            </div>
          </div>
        </NavLink>
      )
    } else {
      // console.log('OI props' ,this.props)
      return (
        <div
          style={{
            cursor: 'default',
            textDecoration: 'inherit',
            backgroundColor: this.state.hover ? '#dddddd' : '',
            marginRight: 30,
          }}
        >
          <div
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            <p
              style={{
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 20,
                marginRight: 0,
                fontSize: 20,
                color: 'white',
                whiteSpace: 'nowrap',
              }}
            >
              {this.props.symbol}
            </p>
            <p
              style={{
                marginLeft: '5%',
                marginRight: '5%',
                marginTop: 0,
                marginBottom: 0,
                color: 'white',
                fontSize: 20,
              }}
            >
              {this.addCommas(this.props.real_time_price.toFixed(2))}
            </p>
            <p
              style={{
                margin: 0,
                fontSize: 20,
                color:
                  this.props.price_delta < 0
                    ? 'rgb(234,50,35)'
                    : 'rgb(82,140,139)',
              }}
            >
              {this.props.price_delta > 0 ? '+' : ''}
              {this.addCommas(this.props.price_delta.toFixed(2))}%
            </p>
            <div
              style={{
                width: 4,
                backgroundColor: 'white',
                height: '50%',
                color: 'white',
                marginRight: 0,
                marginLeft: 20,
              }}
            >
              &nbsp;
            </div>
          </div>
        </div>
      )
    }
  }
}
