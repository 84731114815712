import React from 'react'
import Header from '../layout/Header'
import Footer from '../layout/Footer'
import Portfolio from '../dashboard/Portfolio'
import PortConstructor from '../dashboard/PortConstructor'
import References from '../dashboard/References'
import PriceTrendPredictor from '../dashboard/PriceTrendPredictor'
import WealthManagement from '../dashboard/WealthManagement'
import MyStrats from '../dashboard/MyStrats'
import Watchlist from '../dashboard/Watchlist'
import LoginHalf from '../loginHalf'
import SubModal from '../SubModal'
import { /**FaBars,*/ FaVideo } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import ARlogo from '../../assets/ar_logo.png'
import ENDPOINT from '../Endpoint'

const brandColor = '#00A99D'

export default class UserDashboard extends React.Component {
  state = {
    activeTab: 'Price Trend Predictor',
    myWatchlist: [],
    presetTickers: [],
    optimizedResults: {},
    userInputs: [],
    showListOptions: false,
    prevSet: [],
    subLevel: null,
    customPorts: false,
    userEmail: '',
    itemsResp: [],
  }

  componentDidMount() {
    if (this.props.user) {
      this.subStatus()
      this.fetchWatchList()
      this.fetchCustomStrategies()
      this.getPlaidItems()
    }
    this.updateWindowDimensions()
    document.title = `AR - Dashboard`
  }

  fetchCustomStrategies() {
    fetch(`${ENDPOINT}/risk-monkey/portfolios/ALL_PORTFOLIOS`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => this.setState({ customPorts: responseJson }))
  }

  updateWindowDimensions = () => {
    if (window.innerWidth < 1000) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  subStatus = () => {
    let URL = `${ENDPOINT}/users?details=true`
    fetch(URL, {
      'Access-Control-Allow-Headers': {
        'Access-Control-Request-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        'Access-Control-Allow-Credentials': true,
      },
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        // console.log('sub status resp:', responseJson)
        this.setState({
          subLevel: responseJson.accountSub.productId,
          subStatus: responseJson.accountSub.status,
          freeTrial: responseJson.validSub,
          userEmail: responseJson.email,
        })
      })
      .catch((err) => console.log('err sub status: ', err))
  }

  alterWatchlist(symbol, addRemove) {
    fetch(`${ENDPOINT}/risk-monkey/watchlist`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
      body: JSON.stringify({ [addRemove]: [symbol] }),
    })
  }

  fetchWatchList = () => {
    fetch(`${ENDPOINT}/risk-monkey/watchlist`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let watchListData = responseJson.watchlist
        let predefinedLists = responseJson.predefinedLists
        let watchlistLastUpdated = new Date(
          responseJson.lastUpdated * 1000
        ).toString()
        let sortedPredefinedLists = this.arrayToObject(predefinedLists)
        this.setState({
          myWatchlist: watchListData,
          presetTickers: sortedPredefinedLists,
          watchlistLastUpdated,
        })
      })
      .catch((error) => console.log(error))
  }

  getPlaidItems = () => {
    // an Item is all the accounts at one place (multiple accounts at robinhood)
    fetch(`${ENDPOINT}/plaid/items`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => this.setState({ itemsResp: responseJson }))
      .catch((err) => console.log(`catching errors`, err))
  }

  getPlaidAccounts = () => {
    // an Account is one account at one place (ie. one account at robinhood)
    fetch(`${ENDPOINT}/plaid/accounts`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((responseJson) => console.log('Accounts response', responseJson))
      .catch((err) => console.log(`catching errors`, err))
  }

  updatePaymentStatus = () => {
    this.setState({ subLevel: 'Wealth Management', freeTrial: false })
  }

  arrayToObject = (arr) => {
    let result = {}
    for (let i = 0; i < arr.length; i++) {
      result[arr[i].listTitle] = arr[i].tickers
    }
    return result
  }

  optimizeButton = (newData, userInputs, prevSet) => {
    // let sorted = userInputs.sort((a, b) => (a.symbol > b.symbol) ? 1 : -1)
    this.setState({
      activeTab: 'Portfolio',
      optimizedResults: newData,
      userInputs,
      prevSet,
    })
  }

  toPc = () => {
    window.scrollTo(0, 325)
    this.setState({ activeTab: 'Portfolio Constructor' })
  }
  toMs = () => {
    window.scrollTo(0, 325)
    this.setState({ activeTab: 'My Strategies' })
  }

  toWm = () => {
    window.scrollTo(0, 325)
    this.setState({ activeTab: 'Wealth Management' })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }
  updateWindowDimensions = () => {
    if (window.innerWidth < 850) {
      this.setState({ isSmallScreen: true, screenWidth: window.innerWidth })
    } else {
      this.setState({ isSmallScreen: false, screenWidth: window.innerWidth })
    }
  }

  showAllTabs = () => {
    if (this.props.user) {
      switch (this.state.activeTab) {
        case 'Portfolio Constructor':
          return (
            <PortConstructor
              linkedPorts={this.state.itemsResp}
              customPorts={this.state.customPorts}
              prevSet={this.state.prevSet}
              toWm={this.toWm}
              myWatchlist={this.state.myWatchlist}
              addWl={this.addToWl}
              removeWl={this.removeFromWl}
              optimizeButton={this.optimizeButton}
              user={this.props.user}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'Portfolio':
          return (
            <Portfolio
              userEmail={this.state.userEmai}
              updatePaymentStatus={this.updatePaymentStatus}
              subStatus={this.state.subStatus}
              activeTab={this.state.activeTab}
              user={this.props.user}
              subLevel={this.state.subLevel}
              fetchCustomStrategies={this.fetchCustomStrategies}
              refreshStrats={this.fetchCustomStrategies}
              toPc={this.toPc}
              data={this.state.optimizedResults}
              userInputs={this.state.userInputs}
              user={this.props.user}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'Wealth Management':
          return (
            <WealthManagement
              toMs={this.toMs}
              customPorts={this.state.customPorts}
              user={this.props.user}
              toPc={this.toPc}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'References':
          return <References isSmallScreen={this.state.isSmallScreen} />
        case 'Price Trend Predictor':
          return (
            <PriceTrendPredictor
              dash={false}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'My Strategies':
          return (
            <MyStrats
              linkedPorts={this.state.itemsResp}
              refreshStrats={this.fetchCustomStrategies}
              customPorts={this.state.customPorts}
              user={this.props.user}
              toPc={this.toPc}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'My Watchlist':
          return (
            <Watchlist
              myWatchlist={this.state.myWatchlist}
              removeFromWl={this.removeFromWl}
              presetTickers={this.state.presetTickers}
              user={this.props.user}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        default:
          return (
            <Watchlist
              myWatchlist={this.state.myWatchlist}
              removeFromWl={this.removeFromWl}
              presetTickers={this.state.presetTickers}
              user={this.props.user}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
      }
    } else {
      switch (this.state.activeTab) {
        // case 'Portfolio Constructor':
        //     return <PortConstructor linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts} toWm={this.toWm} myWatchlist={this.state.myWatchlist} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
        case 'Wealth Management':
          return (
            <WealthManagement
              toMs={this.toMs}
              customPorts={this.state.customPorts}
              user={this.props.user}
              toPc={this.toPc}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'Price Trend Predictor':
          return (
            <PriceTrendPredictor
              dash={false}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        default:
          return (
            <LoginHalf
              errors={this.props.errors}
              signIn={this.props.signIn}
              signOut={this.props.signOut}
              user={this.props.user}
            />
          )
      }
    }
  }

  showTabsPaywall = () => {
    if (this.props.user) {
      // console.log('this.state.freeTrial',this.state.freeTrial)
      // console.log('this.state.subLevel',this.state.subLevel)
      if (this.state.freeTrial === true) {
        // console.log('free trial')
        //Free Trial
        switch (this.state.activeTab) {
          case 'Portfolio Constructor':
            return (
              <PortConstructor
                linkedPorts={this.state.itemsResp}
                customPorts={this.state.customPorts}
                prevSet={this.state.prevSet}
                toWm={this.toWm}
                myWatchlist={this.state.myWatchlist}
                addWl={this.addToWl}
                removeWl={this.removeFromWl}
                optimizeButton={this.optimizeButton}
                user={this.props.user}
                isSmallScreen={this.state.isSmallScreen}
              />
            )
          case 'Portfolio':
            return (
              <Portfolio
                userEmail={this.state.userEmai}
                updatePaymentStatus={this.updatePaymentStatus}
                subStatus={this.state.subStatus}
                activeTab={this.state.activeTab}
                user={this.props.user}
                subLevel={this.state.subLevel}
                fetchCustomStrategies={this.fetchCustomStrategies}
                refreshStrats={this.fetchCustomStrategies}
                toPc={this.toPc}
                data={this.state.optimizedResults}
                userInputs={this.state.userInputs}
                user={this.props.user}
                isSmallScreen={this.state.isSmallScreen}
              />
            )
          case 'Wealth Management':
            return (
              <WealthManagement
                toMs={this.toMs}
                customPorts={this.state.customPorts}
                user={this.props.user}
                toPc={this.toPc}
                isSmallScreen={this.state.isSmallScreen}
              />
            )
          case 'References':
            return <References isSmallScreen={this.state.isSmallScreen} />
          case 'Price Trend Predictor':
            return (
              <PriceTrendPredictor
                dash={false}
                isSmallScreen={this.state.isSmallScreen}
              />
            )
          case 'My Strategies':
            return (
              <SubModal
                userEmail={this.state.userEmai}
                updatePaymentStatus={this.updatePaymentStatus}
                subStatus={this.state.subStatus}
                activeTab={this.state.activeTab}
                user={this.props.user}
                subLevel={this.state.subLevel}
              />
            )
          // return <MyStrats linkedPorts={this.state.itemsResp} refreshStrats={this.fetchCustomStrategies} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
          case 'My Watchlist':
            return (
              <Watchlist
                myWatchlist={this.state.myWatchlist}
                removeFromWl={this.removeFromWl}
                presetTickers={this.state.presetTickers}
                user={this.props.user}
                isSmallScreen={this.state.isSmallScreen}
              />
            )
          default:
            return (
              <Watchlist
                myWatchlist={this.state.myWatchlist}
                removeFromWl={this.removeFromWl}
                presetTickers={this.state.presetTickers}
                user={this.props.user}
                isSmallScreen={this.state.isSmallScreen}
              />
            )
        }
      } else if (
        this.state.subLevel === 'arapp_monthly' ||
        this.state.subLevel === 'prod_FaN0loj0oRP7de'
      ) {
        // BASIC SUB
        // console.log('basic sub')

        switch (this.state.activeTab) {
          case 'My Strategies':
            return (
              <SubModal
                userEmail={this.state.userEmai}
                updatePaymentStatus={this.updatePaymentStatus}
                subStatus={this.state.subStatus}
                activeTab={this.state.activeTab}
                user={this.props.user}
                subLevel={this.state.subLevel}
              />
            )
          // return <MyStrats linkedPorts={this.state.itemsResp} refreshStrats={this.fetchCustomStrategies} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
          case 'Portfolio Constructor':
            return (
              <SubModal
                userEmail={this.state.userEmai}
                updatePaymentStatus={this.updatePaymentStatus}
                subStatus={this.state.subStatus}
                activeTab={this.state.activeTab}
                user={this.props.user}
                subLevel={this.state.subLevel}
              />
            )
          // return <PortConstructor linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts}  prevSet={this.state.prevSet} toWm={this.toWm} myWatchlist={this.state.myWatchlist} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
          case 'Portfolio':
            return (
              <SubModal
                userEmail={this.state.userEmai}
                updatePaymentStatus={this.updatePaymentStatus}
                subStatus={this.state.subStatus}
                activeTab={this.state.activeTab}
                user={this.props.user}
                subLevel={this.state.subLevel}
              />
            )
          // return <Portfolio userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}fetchCustomStrategies={this.fetchCustomStrategies} refreshStrats={this.fetchCustomStrategies} toPc={this.toPc} data={this.state.optimizedResults} userInputs={this.state.userInputs} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
          case 'Wealth Management':
            return (
              <SubModal
                userEmail={this.state.userEmai}
                updatePaymentStatus={this.updatePaymentStatus}
                subStatus={this.state.subStatus}
                activeTab={this.state.activeTab}
                user={this.props.user}
                subLevel={this.state.subLevel}
              />
            )
          // return <WealthManagement toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
          case 'References':
            return <References isSmallScreen={this.state.isSmallScreen} />
          case 'Price Trend Predictor':
            return (
              <PriceTrendPredictor
                dash={false}
                isSmallScreen={this.state.isSmallScreen}
              />
            )
          case 'My Watchlist':
            return (
              <Watchlist
                myWatchlist={this.state.myWatchlist}
                removeFromWl={this.removeFromWl}
                presetTickers={this.state.presetTickers}
                user={this.props.user}
                isSmallScreen={this.state.isSmallScreen}
              />
            )
          default:
            return (
              <Watchlist
                myWatchlist={this.state.myWatchlist}
                removeFromWl={this.removeFromWl}
                presetTickers={this.state.presetTickers}
                user={this.props.user}
                isSmallScreen={this.state.isSmallScreen}
              />
            )
        }
      } else if (!this.state.subLevel) {
        // No Sub
        // console.log('nosub')
        return (
          <SubModal
            userEmail={this.state.userEmai}
            updatePaymentStatus={this.updatePaymentStatus}
            subStatus={this.state.subStatus}
            activeTab={this.state.activeTab}
            user={this.props.user}
            subStatus={this.state.subLevel}
          />
        )
        // switch (this.state.activeTab) {
        //     case 'Portfolio Constructor':
        //         return <PortConstructor linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts}  prevSet={this.state.prevSet} toWm={this.toWm} myWatchlist={this.state.myWatchlist} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
        //     case 'Portfolio':
        //         return <Portfolio userEmail={this.state.userEmai} updatePaymentStatus={this.updatePaymentStatus} subStatus={this.state.subStatus} activeTab={this.state.activeTab} user={this.props.user} subLevel={this.state.subLevel}fetchCustomStrategies={this.fetchCustomStrategies} refreshStrats={this.fetchCustomStrategies} toPc={this.toPc} data={this.state.optimizedResults} userInputs={this.state.userInputs} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
        //     case 'Wealth Management':
        //         return <WealthManagement toMs={this.toMs} customPorts={this.state.customPorts} user={this.props.user} toPc={this.toPc} isSmallScreen={this.state.isSmallScreen}/>
        //     case 'References':
        //         return <References isSmallScreen={this.state.isSmallScreen}/>
        //     case 'Price Trend Predictor':
        //         return <PriceTrendPredictor dash={false} isSmallScreen={this.state.isSmallScreen}/>
        //     case 'My Watchlist':
        //         return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
        //     default:
        //         return <Watchlist myWatchlist={this.state.myWatchlist} removeFromWl={this.removeFromWl} presetTickers={this.state.presetTickers} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
        // }
      } else {
        // console.log('WMsub')
        // WM Sub
        switch (this.state.activeTab) {
          case 'Portfolio Constructor':
            return (
              <PortConstructor
                linkedPorts={this.state.itemsResp}
                customPorts={this.state.customPorts}
                prevSet={this.state.prevSet}
                toWm={this.toWm}
                myWatchlist={this.state.myWatchlist}
                addWl={this.addToWl}
                removeWl={this.removeFromWl}
                optimizeButton={this.optimizeButton}
                user={this.props.user}
                isSmallScreen={this.state.isSmallScreen}
              />
            )
          case 'Portfolio':
            return (
              <Portfolio
                userEmail={this.state.userEmai}
                updatePaymentStatus={this.updatePaymentStatus}
                subStatus={this.state.subStatus}
                activeTab={this.state.activeTab}
                user={this.props.user}
                subLevel={this.state.subLevel}
                wm={true}
                fetchCustomStrategies={this.fetchCustomStrategies}
                refreshStrats={this.fetchCustomStrategies}
                toPc={this.toPc}
                data={this.state.optimizedResults}
                userInputs={this.state.userInputs}
                user={this.props.user}
                isSmallScreen={this.state.isSmallScreen}
              />
            )
          case 'Wealth Management':
            return (
              <WealthManagement
                toMs={this.toMs}
                customPorts={this.state.customPorts}
                user={this.props.user}
                toPc={this.toPc}
                isSmallScreen={this.state.isSmallScreen}
              />
            )
          case 'References':
            return <References isSmallScreen={this.state.isSmallScreen} />
          case 'Price Trend Predictor':
            return (
              <PriceTrendPredictor
                dash={false}
                isSmallScreen={this.state.isSmallScreen}
              />
            )
          case 'My Strategies':
            return (
              <MyStrats
                linkedPorts={this.state.itemsResp}
                refreshStrats={this.fetchCustomStrategies}
                customPorts={this.state.customPorts}
                user={this.props.user}
                toPc={this.toPc}
                isSmallScreen={this.state.isSmallScreen}
              />
            )
          case 'My Watchlist':
            return (
              <Watchlist
                myWatchlist={this.state.myWatchlist}
                removeFromWl={this.removeFromWl}
                presetTickers={this.state.presetTickers}
                user={this.props.user}
                isSmallScreen={this.state.isSmallScreen}
              />
            )
          default:
            return (
              <Watchlist
                myWatchlist={this.state.myWatchlist}
                removeFromWl={this.removeFromWl}
                presetTickers={this.state.presetTickers}
                user={this.props.user}
                isSmallScreen={this.state.isSmallScreen}
              />
            )
        }
      }
    } else {
      switch (this.state.activeTab) {
        // case 'Portfolio Constructor':
        //     return <PortConstructor linkedPorts={this.state.itemsResp} customPorts={this.state.customPorts} toWm={this.toWm} myWatchlist={this.state.myWatchlist} addWl={this.addToWl} removeWl={this.removeFromWl} optimizeButton={this.optimizeButton} user={this.props.user} isSmallScreen={this.state.isSmallScreen}/>
        case 'Wealth Management':
          return (
            <WealthManagement
              toMs={this.toMs}
              customPorts={this.state.customPorts}
              user={this.props.user}
              toPc={this.toPc}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        case 'Price Trend Predictor':
          return (
            <PriceTrendPredictor
              dash={false}
              isSmallScreen={this.state.isSmallScreen}
            />
          )
        default:
          return (
            <LoginHalf
              errors={this.props.errors}
              signIn={this.props.signIn}
              signOut={this.props.signOut}
              user={this.props.user}
            />
          )
      }
    }
  }
  cutURL() {
    return window.location.pathname.split('/Dashboard')[1]
  }

  showLists = () => {
    if (this.state.showListOptions) {
      let categories = [
        'Price Trend Predictor',
        'My Watchlist',
        'Portfolio Constructor',
        'Wealth Management',
      ]
      // categories = categories.filter(ele => ele !== this.state.activeTab)
      let mapped = categories.map((ele) => (
        <li
          style={{
            marginBottom: 5,
            cursor: 'pointer',
            backgroundColor:
              this.state.activeTab !== ele ? 'rgb(146,146,146)' : brandColor,
            padding: 7,
            borderRadius: 5,
            width: '100%',
          }}
          onClick={() =>
            this.setState({ activeTab: ele, showListOptions: false })
          }
        >
          {ele}
        </li>
      ))
      return (
        <ul
          style={{
            backgroundColor: 'rgb(248,248,250)',
            listStyleType: 'none',
            padding: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '60%',
            borderRadius: 12,
            marginTop: -10,
            zIndex: 100,
          }}
        >
          {mapped}
        </ul>
      )
    }
  }

  showHeader = () => {
    if (!this.state.isSmallScreen) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '80%',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 40,
              backgroundColor: '#F2F2F999',
              borderRadius: 10,
            }}
          >
            <div
              style={{
                width: '20%',
                height: '100%',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.activeTab === 'Price Trend Predictor'
                    ? brandColor
                    : '#F2F2F999',
                borderRadius: '10px 0px 0px 10px',
                borderRadius: 10,
              }}
              onClick={() =>
                this.setState({ activeTab: 'Price Trend Predictor' })
              }
            >
              <p
                style={{
                  margin: 0,
                  fontSize: this.state.isSmallScreen ? 14 : 16,
                }}
              >
                Price Trend Predictor
              </p>
            </div>
            <div
              style={{
                width: '20%',
                height: '100%',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.activeTab === 'My Watchlist'
                    ? brandColor
                    : '#F2F2F999',
                borderRadius: 10,
              }}
              onClick={() => this.setState({ activeTab: 'My Watchlist' })}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: this.state.isSmallScreen ? 14 : 16,
                }}
              >
                My Watchlist
              </p>
            </div>
            <div
              style={{
                width: '20%',
                height: '100%',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.activeTab === 'Portfolio Constructor' ||
                  this.state.activeTab === 'Portfolio'
                    ? brandColor
                    : '#F2F2F999',
                borderRadius: 10,
              }}
              onClick={
                this.state.activeTab === 'Portfolio Constructor' ||
                this.state.activeTab === 'Portfolio'
                  ? null
                  : () => this.setState({ activeTab: 'Portfolio Constructor' })
              }
            >
              <p
                style={{
                  margin: 0,
                  fontSize: this.state.isSmallScreen ? 14 : 16,
                }}
              >
                Portfolio Constructor
              </p>
            </div>
            <div
              style={{
                width: '20%',
                height: '100%',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.activeTab === 'My Strategies'
                    ? brandColor
                    : '#F2F2F999',
                borderRadius: 10,
              }}
              onClick={
                this.state.activeTab === 'My Strategies'
                  ? null
                  : () => this.setState({ activeTab: 'My Strategies' })
              }
            >
              <p
                style={{
                  margin: 0,
                  fontSize: this.state.isSmallScreen ? 14 : 16,
                }}
              >
                My Portfolios & Strategies
              </p>
            </div>

            <div
              style={{
                width: '20%',
                height: '100%',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  this.state.activeTab === 'Wealth Management'
                    ? brandColor
                    : '#F2F2F999',
                borderRadius: '0px 10px 10px 0px',
                borderRadius: 10,
              }}
              onClick={() => this.setState({ activeTab: 'Wealth Management' })}
            >
              <p
                style={{
                  margin: 0,
                  fontSize: this.state.isSmallScreen ? 14 : 16,
                }}
              >
                Wealth Management
              </p>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div
          style={{
            backgroundColor: brandColor,
            width: '60%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 10,
            height: 36,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%',
            }}
            onClick={() =>
              this.setState({ showListOptions: !this.state.showListOptions })
            }
          >
            {/* <div>
                                <p style={{margin:0}}>
                                    {this.state.activeTab}
                                </p>
                            </div> */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {/* <FaBars/> */}
              <p style={{ margin: 0 }}>Dashboard Menu</p>
            </div>
          </div>
        </div>
      )
    }
  }

  addToWl = (item) => {
    let myWatchlist = this.state.myWatchlist.push(item)
    this.setState({ myWatchlist })
  }

  removeFromWl = (symbol) => {
    this.alterWatchlist(symbol, 'delete')
    let filtered = this.state.myWatchlist.filter((ele) => ele.symbol !== symbol)
    this.setState({ myWatchlist: filtered })
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <Header
          hiddenSearch={
            this.state.activeTab === 'Price Trend Predictor' ? true : false
          }
          user={this.props.user}
          signOut={this.props.signOut}
        />
        <div
          style={{
            marginTop: this.state.isSmallScreen ? 100 : 75,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 40,
              width: '90%',
            }}
          >
            <div style={{ width: '20%' }} />
            <div
              style={{
                width: '60%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={ARlogo} style={{ height: 75 }} />
              </div>
              <div style={{ marginTop: 40 }}>
                <p style={{ fontSize: 48 }}>Dashboard</p>
              </div>
            </div>
            <div style={{ width: '20%', marginTop: '8%' }}>
              {this.state.activeTab === 'Portfolio' ||
              this.state.activeTab === 'Portfolio Constructor' ? null : (
                <NavLink
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    backgroundColor: '',
                    borderRadius: 10,
                    padding: 10,
                    textDecoration: 'none',
                  }}
                  to={'/videos'}
                >
                  {/* <p style={{margin:0, color:'rgb(17,17,17)'}}>{this.state.isSmallScreen? 'AR Videos':'AllocateRite Videos'}</p> */}
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FaVideo color={brandColor} size={'7.5vw'} />
                    <p
                      style={{
                        textDecoration: 'none',
                        color: 'rgb(17,17,17)',
                        marginTop: '-10%',
                      }}
                    >
                      AR Videos
                    </p>
                  </div>
                </NavLink>
              )}
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {this.showHeader()}
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 15,
            }}
          >
            {this.showLists()}
          </div>
          <div
            style={{
              marginTop: 20,
              width: '80%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 30,
            }}
          >
            {/* {this.showAllTabs()} */}
            {this.showTabsPaywall()}
          </div>
        </div>
        <Footer t={this.props.t} />
      </div>
    )
  }
}
