import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

class ChineseAdvantageOfPlatformDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Advantages: [
        {
          title: `让投资变得简单`,
          text: `体验从开始到投资组合选择再到税务优化的无缝连接的平台经历。`
        },
        {
          title: `降低您的管理费用`,
          text: `以极低的利率获得机构级的投资策略。`
        },
        {
          title: `提升你自己`,
          text: `将客户帐户留在他们所在的位置，执行客户所想。自主调节份额。`
        },
        {
          title: `动态管理`,
          text: `通过AR的全自动动态资产配置模型，降低风险，保护资本和增加资产。`
        },
        {
          title: `增加您的潜力`,
          text: `为任何规模的客户提供对冲基金策略，不需要最低限度或锁定期。`
        },
        {
          title: `保持完全透明`,
          text: `随时随地为客户提供最新的投资表现，风险，成本等信息。`
        },
        {
          title: `释放时间`,
          text: `开始花更多时间在客户身上，少花时间去管理投资。`
        },
        {
          title: `完整的流动性`,
          text: `由独立账户完全控制的高流动性广泛指数ETF使得资本进出快速简单。`
        }
      ]
    };
  }

  render() {
    return (
      <Container>
        <Row>
          <p className="platform-advantage-title">AllocateRite 的优势</p>
        </Row>
        <Row className="platform-advantage-details">
          {this.state.Advantages.map(advantage => (
            <Col key={advantage.title} sm={6}>
              <p className="platform-advantage-detail-title">
                {advantage.title}
              </p>
              <p className="platform-advantage-details-text">
                {advantage.text}
              </p>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
}

export default ChineseAdvantageOfPlatformDetails;
