import React from 'react'
import MainNavBar from '../components/MainNavBar'
import '../../scss/pages/PromoPage.scss'
import InvestallShellLogo from '../../assets/investall-shell-logo.svg'
import PromoBubbles from '../../assets/promo-bubbles.png'
import { handleAppRedirect } from '../../utility-functions/handleAppRedirect'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PromoIntroPage from './PromoIntroPage'

export default function PromoPage(props) {
  const history = useHistory()
  const { general } = useSelector((state) => state)
  const { isSmallScreen } = general
  const queryParams = new URLSearchParams(history?.location?.search)
  const promoCode = queryParams.get('code')
  if (props.t) {
    if (promoCode) {
      return <PromoIntroPage {...props} promoCode={promoCode} />
    } else {
      return (
        <>
          <div className='promo-page'>
            <div className='background'></div>
            {/* <img src={PromoBubbles} className='backgroundimg' /> */}
            <img src={InvestallShellLogo} className='shell-logo space-bottom' />
            <p className='investall-header space-bottom-big'>
              {props.t('welcome_to_investall')}
            </p>
            <p className='investall-bold space-bottom space-top'>
              {props.t('get_20_free_with_and_initial_deposit_of_100')}
            </p>
            <div className='space-bottom space-top'>
              <ul className='list-elements'>
                <li className='space-bottom'>{props.t('download_the_app')}</li>
                <li className='space-bottom'>{props.t('open_an_account')}</li>
                <li className='space-bottom'>
                  {props.t('make_an_minimum_initial_deposit_of_100')}
                </li>
              </ul>
            </div>
            <p className='investall-small space-bottom-big space-top-big'>
              {props.t('use_faares_code')}
            </p>
            <button
              className='download-app-btn'
              onClick={() => {
                if (isSmallScreen) {
                  handleAppRedirect()
                } else {
                  if (props.user) {
                    history.push('/Dashboard')
                  } else {
                    history.push('/signin')
                  }
                }
              }}
            >
              {props.t('download_the_app')}
            </button>
          </div>
        </>
      )
    }
  } else {
    return null
  }
}
