import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { MultiSelect } from 'primereact/multiselect'
import BaseURL from './Endpoint'
import axios from 'axios'
import { RadioButton } from 'primereact/radiobutton'
import { Checkbox } from 'primereact/checkbox'
import { Toast } from 'primereact/toast'
import '../scss/components/IBQuestionnaire.scss'
import shortTermImg from '../assets/short-term.svg'
import mediumTermImg from '../assets/medium-term.svg'
import longTermImg from '../assets/long-term.svg'
import { Steps } from 'primereact/steps'
import { Panel } from 'primereact/panel'
import { InputText } from 'primereact/inputtext'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { InputMask } from 'primereact/inputmask'
import { InputNumber } from 'primereact/inputnumber'
import { Skeleton } from 'primereact/skeleton'
import { SelectButton } from 'primereact/selectbutton'
import { useHistory } from 'react-router-dom'
import { InputTextarea } from 'primereact/inputtextarea'
import IBKYC from './IBKYC'

import { Tag } from 'primereact/tag'
import '../scss/components/IBKYC.scss'
import PDFImg from '../assets/PDF_file_icon.svg.png'
import { FileUpload } from 'primereact/fileupload'

const SkeletonLoading = () => {
  return (
    <>
      <Skeleton className='mb-2'></Skeleton>
      <Skeleton width='10rem' className='mb-2'></Skeleton>
      <Skeleton width='5rem' className='mb-2'></Skeleton>
      <Skeleton height='2rem' className='mb-2'></Skeleton>
      <Skeleton width='10rem' height='4rem'></Skeleton>
    </>
  )
}

const IBQuestionnaireModel = (props) => {
  const [IBFormInfo, setIBFormInfo] = useState({
    investment_objectives: [],
  })

  // console.log(props?.prefinraData)

  console.log(IBFormInfo)

  console.log('prefinraData', props?.prefinraData)

  // console.log('allCountries', props?.allCountries)

  // console.log('ibformodal', props?.ibFormModel)
  // if (props?.closeable) {
  //   props?.setIbFormModel(true)
  // }

  const toastErrorMsg = useRef()

  //step index for the questionnaire model
  const [activeIndex, setActiveIndex] = useState(0)
  const [lastStepIndex, setLastStepIndex] = useState(0)

  // toggle status for the form to dynamic render or not render components
  const [optionsToggleStatus, setOptionsToggleStatus] = useState('No')
  const [bondsToggleStatus, setBondsToggleStatus] = useState('No')
  const [isSameAddress, setIsSameAddress] = useState(false)
  const [agreeIBPaperwork, setAgreeIBPaperwork] = useState(false)
  const [agreeForescitePaperwork, setAgreeForescitePaperwork] = useState(false)
  const [agreeTaxFormPaperwork, setAgreeTaxFormPaperwork] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [incomeSources, setIncomeSources] = useState([
    { is_used_for_funds: true, source_type: '', percentage: undefined },
  ])

  // dynamic options variable from the api call
  const [countries, setCountries] = useState(null)
  const [allCountries, setAllCountries] = useState(null)
  const [employmentStatusOptions, setEmploymentStatusOptions] = useState(null)
  const [employmentTypeOptions, setEmploymentTypeOptions] = useState(null)
  const [employmentPositionOptions, setEmploymentPositionOptions] =
    useState(null)
  const [americaStates, setAmericaStates] = useState(null)

  // documents variable
  const [optionsDetail, setOptionsDetail] = useState(null)
  const [selectedOptions, setSelectedOptions] = useState({})

  const [documentsExpiration, setDocumentsExpiration] = useState(null)
  const [addressDocExpiration, setAddressDocExpiration] = useState(null)

  const [selectedOptionsDoc, setSelectedOptionsDoc] = useState({})
  const [documentNumber, setDocumentNumber] = useState('')
  const [documentType, setDocumentType] = useState(null)

  // loading state for the fetching the dynamic options from api call
  const [isLoadingData, setIsLoadingData] = useState(true)

  const [isEligibilityWrong, setIsEligibilityWrong] = useState(false)

  const [submitFormButtonClick, setSubmitFormButtonClick] = useState(false)

  // static option list
  const maritalStatusOptions = [
    {
      label: 'Single',
      value: 'S',
    },
    {
      label: 'Married',
      value: 'M',
    },
    {
      label: 'Widowd',
      value: 'W',
    },
    {
      label: 'Divorced',
      value: 'D',
    },
    {
      label: 'Common law partner',
      value: 'C',
    },
  ]
  const sourceOfWealthOptions = [
    {
      label: 'Allowance',
      value: 'SOW-IND-Allowance',
    },
    {
      label: 'Disability',
      value: 'SOW-IND-Disability',
    },
    {
      label: 'Income',
      value: 'SOW-IND-Income',
    },
    {
      label: 'Inheritance',
      value: 'SOW-IND-Inheritance',
    },
    {
      label: 'Interest',
      value: 'SOW-IND-Interest',
    },
    {
      label: 'Market Profit',
      value: 'SOW-IND-MarketProfit',
    },
    {
      label: 'Other',
      value: 'SOW-IND-Other',
    },
    {
      label: 'Pension',
      value: 'SOW-IND-Pension',
    },
    {
      label: 'Property',
      value: 'SOW-IND-Property',
    },
  ]
  const toggleButtonOptions = ['Yes', 'No']
  const knowledgeOptions = [
    {
      label: 'None',
      value: 'None',
    },
    {
      label: 'Limited',
      value: 'Limited',
    },
    {
      label: 'Good',
      value: 'Good',
    },
    {
      label: 'Extensive',
      value: 'Extensive',
    },
  ]
  const InvestmentOptionObjList = [
    // {
    //   label: 'Growth + Trading Profits + Speculation + Hedging',
    //   value: ['Growth', 'Trading', 'Speculation', 'Hedging'],
    // },

    // {
    //   label: 'Growth + Speculation + Hedging',
    //   value: ['Growth', 'Speculation', 'Hedging'],
    // },

    // {
    //   label: 'Speculation + Hedging + Trading Profits',
    //   value: ['Speculation', 'Hedging', 'Trading'],
    // },

    // {
    //   label: 'Growth + Trading Profits',
    //   value: ['Growth', 'Trading'],
    // },

    // {
    //   label: 'Hedging + Trading Profits',
    //   value: ['Hedging', 'Trading'],
    // },

    // {
    //   label: 'Speculation + Hedging',
    //   value: ['Speculation', 'Hedging'],
    // },
    // {
    //   label: 'Growth + Speculation',
    //   value: ['Growth', 'Speculation'],
    // },

    'Growth + Trading Profits + Speculation + Hedging',
    'Growth + Speculation + Hedging',
    'Speculation + Hedging + Trading Profits',
    'Growth + Trading Profits',
    'Hedging + Trading Profits',
    'Speculation + Hedging',
    'Growth + Speculation',
  ]
  const InvestmentNoOptionObjeList = [
    {
      label: 'Preservation of Capital',
      value: 'Preservation',
    },
    {
      label: 'Trading Profits',
      value: 'Trading',
    },
    {
      label: 'Income',
      value: 'Income',
    },
    {
      label: 'Growth',
      value: 'Growth',
    },
    {
      label: 'Hedging',
      value: 'Hedging',
    },
    {
      label: 'Speculation',
      value: 'Speculation',
    },

    // 'Preservation of Capital',
    // 'Trading Profits',
    // 'Income',
    // 'Growth',
    // 'Hedging',
    // 'Speculation',
  ]

  const FinancialNetWorthList = [
    {
      label: 'Less than $5,000',
      value: '1',
    },
    {
      label: '$5,001 - $20,000',
      value: '2',
    },
    {
      label: '$20,001 - $50,000',
      value: '3',
    },
    {
      label: '$50,001 - $75,000',
      value: '4',
    },
    {
      label: '$75,001 - $100,000',
      value: '5',
    },
    {
      label: '$100,001 - $250,000',
      value: '6',
    },
    {
      label: '$250,001 - $500,000',
      value: '7',
    },
    {
      label: '$500,001 - $1,000,000',
      value: '8',
    },
    {
      label: '$1,000,001 - $2,000,000',
      value: '9',
    },
    {
      label: '$2,000,001 - $5,000,000',
      value: '10',
    },
    {
      label: '$5,000,001 - $10,000,000',
      value: '11',
    },
    {
      label: '$10,000,001 - $25,000,000',
      value: '12',
    },
    {
      label: '$25,000,001 - $30,000,000',
      value: '13',
    },
    {
      label: '$30,000,001 - $50,000,000',
      value: '14',
    },
    {
      label: '$50,000,001+',
      value: '15',
    },
  ]

  const FinancialNetLiquidList = [
    {
      label: 'Less than $5,000',
      value: '1',
    },
    {
      label: '$5,001 - $20,000',
      value: '2',
    },
    {
      label: '$20,001 - $50,000',
      value: '3',
    },
    {
      label: '$50,001 - $75,000',
      value: '4',
    },
    {
      label: '$75,001 - $100,000',
      value: '5',
    },
    {
      label: '$100,001 - $250,000',
      value: '6',
    },
    {
      label: '$250,001 - $500,000',
      value: '7',
    },
    {
      label: '$500,001 - $1,000,000',
      value: '8',
    },
    {
      label: '$1,000,001 - $5,000,000',
      value: '9',
    },
    {
      label: '$5,000,001+',
      value: '10',
    },
  ]

  const FinancialAnnualList = [
    // {
    //   label: '$0 - $39,999',
    //   value: '1',
    // },
    // {
    //   label: '$40,000 - $45,000',
    //   value: '2',
    // },
    // {
    //   label: '$45,001 - $50,000',
    //   value: '3',
    // },
    {
      label: 'Less than $50,000',
      value: '3',
    },
    {
      label: '$50,001 - $100,000',
      value: '4',
    },

    {
      label: '$100,001 - $150,000',
      value: '5',
    },
    {
      label: '$150,001 - $200,000',
      value: '6',
    },
    {
      label: '$200,001 - $250,000',
      value: '7',
    },
    {
      label: '$250,001 - $500,000',
      value: '8',
    },
    {
      label: '$500,001 - $1,000,000',
      value: '9',
    },

    {
      label: '$1,000,001+',
      value: '10',
    },
  ]

  const PlanInvestmentList = [
    {
      label: '1 - 5 years',
      value: '1-5',
    },
    {
      label: '5 - 10 years',
      value: '5-10',
    },
    {
      label: '10 - 20 years',
      value: '10-20',
    },
    {
      label: '20 - 30 years',
      value: '20-30',
    },
    {
      label: '30+ years',
      value: '30+',
    },
  ]

  const RiskToleranceList = [
    {
      label: 'Very aggressive',
      value: 'very aggressive',
    },
    {
      label: 'Aggressive',
      value: 'aggressive',
    },
    {
      label: 'Moderate',
      value: 'MEDIUM',
    },
    {
      label: 'Conservative',
      value: 'LOW',
    },
  ]

  const IBFormSteps = [
    {
      label: 'Personal',
    },
    {
      label: 'Employment',
    },
    {
      label: 'Financial',
    },
    {
      label: 'Investment',
    },
    {
      label: 'Acknowledgment',
    },
    {
      label: 'Documents',
    },
    {
      label: 'Review',
    },
  ]

  useEffect(() => {
    fetchPresets()
    fetchIBDocumentsDetails()
  }, [])

  // reset the to empty option when change between option to no option for investment
  useEffect(() => {
    if (optionsToggleStatus) {
      setIBFormInfo((prevData) => ({
        ...prevData,
        ['investment_objectives']: [],
        ['options_knowledge_level']: '',
        ['options_trades_per_year']: '',
        ['options_years_trading']: '',
      }))
    }
  }, [optionsToggleStatus])

  // reset the to empty bonds when change between bonds to no bonds for investment
  useEffect(() => {
    if (bondsToggleStatus) {
      setIBFormInfo((prevData) => ({
        ...prevData,
        ['investment_objectives']: [],
        ['bonds_knowledge_level']: '',
        ['bonds_trades_per_year']: '',
        ['bonds_years_trading']: '',
      }))
    }
  }, [bondsToggleStatus])

  // empty the list when the toggle is off
  useEffect(() => {
    if (!isEligibilityWrong) {
      setIBFormInfo((prevData) => ({
        ...prevData,
        ['affiliations_description']: '',
      }))
    }
  }, [isEligibilityWrong])

  const fetchIBDocumentsDetails = async () => {
    try {
      let res = await axios.get(`${BaseURL}/ibkr/document-preset`, {
        headers: {
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
        },
      })

      console.log('document-preset', res?.data)

      setOptionsDetail(res?.data)
      //  setPageloading(false)
    } catch (error) {
      console.log(error)
    }
  }

  // update the form input field
  const handleIBFormInputChange = (event) => {
    let { name, value } = event.target

    // console.log('name', name)

    if (name !== 'investment_plan' && name !== 'employment_status') {
      value = value?.replace(/[^A-Za-z0-9\s]/g, '')
    }

    // console.log('name', value)

    setIBFormInfo((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleIBIdentificationChange = (event) => {
    let { name, value } = event.target
    setIBFormInfo((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  // handle the birthday value from the date object
  const handleBirthdayInputChange = (name, value) => {
    setIBFormInfo((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  // fetch all the dynamic options from the api
  const fetchPresets = async () => {
    setIsLoadingData(true)
    try {
      let res = await axios.get(`${BaseURL}/drivewealth/preset`)

      // once get the data, get the options from the api
      if (res?.data) {
        let countries = []

        let USATag = null
        let USATag2 = null

        res.data.countries.map((country) => {
          if (country.name !== 'United States') {
            countries.push({
              label: country.name,
              value: country.id,
            })
          } else {
            USATag = country
          }
        })
        countries.unshift({
          label: USATag.name,
          value: USATag.id,
        })
        setCountries(countries)

        let allCountries = []
        res.data.countries_all.map((country) => {
          // the api has "test1" need to exclude from the
          if (country?.name !== 'test1' || country?.name !== 'United States') {
            allCountries.push({
              label: country.name,
              value: country.id,
            })
          }

          if (country?.name === 'United States') {
            USATag2 = country
          }
        })
        allCountries.unshift({
          label: USATag2.name,
          value: USATag2.id,
        })
        setAllCountries(allCountries)

        let employmentStatusOptions = []
        res.data.emp_status.map((status) => {
          employmentStatusOptions.push({
            label: Object.values(status)[0],
            value: Object.keys(status)[0],
          })
        })
        setEmploymentStatusOptions(employmentStatusOptions)

        let employmentTypeOptions = []
        res.data.emp_types.map((type) => {
          employmentTypeOptions.push({
            label: Object.values(type)[0],
            value: Object.keys(type)[0],
          })
        })
        setEmploymentTypeOptions(employmentTypeOptions)

        let employmentPositionOptions = []
        res.data.emp_positions.map((option) => {
          employmentPositionOptions.push({
            label: Object.values(option)[0],
            value: Object.keys(option)[0],
          })
        })
        setEmploymentPositionOptions(employmentPositionOptions)

        let usaStates = []
        res.data.us_states.map((option) => {
          usaStates.push({
            label: Object.values(option)[0],
            value: Object.keys(option)[0],
          })
        })
        setAmericaStates(usaStates)
      }

      setIsLoadingData(false)
    } catch (error) {
      console.log(error)
      setIsLoadingData(false)
    }
  }

  // check if the user is at least 18 years old before create the account
  const isAtLeast18YearsOld = (year, month, day) => {
    const currentDate = new Date()
    const inputDate = new Date(year, month - 1, day) // JavaScript months are 0-based (0 = January, 1 = February, etc.)

    const ageDifferenceInMilliseconds = currentDate - inputDate
    const ageInYears =
      ageDifferenceInMilliseconds / (365.25 * 24 * 60 * 60 * 1000)

    return ageInYears >= 18
  }

  // console.log('allcoutnries', allCountries)
  // console.log('countries', countries)

  console.log('selectedOptions', selectedOptions)
  console.log('selectedOptionsDoc', selectedOptionsDoc)

  // validate all the form fields
  const validateForm = () => {
    const {
      tax_id,
      international_country_address,
      international_region,
      international_employer_country,
      international_employer_region,
      // first_name,
      // last_name,
      // birthdayDay,
      // birthdayMonth,
      // birthdayYear,
      country_of_birth,
      citizenship,
      marital_status,
      ssn,
      no_dependents,
      // email_address,
      // phone_no,
      street_1,
      city,
      state,
      postal_code,
      // mailing_city,
      // mailing_postal_code,
      // mailing_state,
      // mailing_street_1,
      employment_status,
      company_name,
      employment_field,
      employment_position,
      employer_address_street_1,
      employer_address_city,

      employer_address_state,
      employer_address_postal_code,
      annual_net_income,
      net_worth,
      liquid_net_worth,

      investment_objectives,
      knowledge_level,
      trades_per_year,
      years_trading,
      options_knowledge_level,
      options_trades_per_year,
      options_years_trading,
      bonds_knowledge_level,
      bonds_trades_per_year,
      bonds_years_trading,
      affiliations_description,
      investment_plan,
      risk_tolerance,
      // regulatory_question_1,
      // regulatory_question_2,
      // regulatory_question_3,
      // signedFullName,
    } = IBFormInfo

    const isInternationalUser = !props?.prefinraData?.payload?.ustaxpayer

    // step 1 form validateion for empty fields
    if (
      // !first_name ||
      // first_name?.trim() === '' ||
      // !last_name ||
      // last_name?.trim() === '' ||
      // !birthdayDay ||
      // !birthdayMonth ||
      // !birthdayYear ||
      !country_of_birth ||
      !citizenship ||
      !marital_status ||
      !no_dependents ||
      // !email_address ||
      // !phone_no ||
      !street_1 ||
      !city ||
      !postal_code
    ) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Step 1 - Incompleted',
        detail: 'Please make sure all required fields are filled',
        life: 3000,
      })
      return false
    }

    const identificationNum = isInternationalUser ? tax_id : ssn

    if (!identificationNum) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Step 1 - Incompleted',
        detail: 'Please make sure all required fields are filled',
        life: 3000,
      })
      return false
    }

    const hasState = isInternationalUser ? international_region : state

    if (!hasState) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Step 1 - Incompleted',
        detail: 'Please make sure all required fields are filled',
        life: 3000,
      })
      return false
    }

    if (isInternationalUser) {
      if (!international_country_address) {
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Step 1 - Incompleted',
          detail: 'Please make sure all required fields are filled',
          life: 3000,
        })
        return false
      }
    }

    // // not age 18 or older
    // if (!isAtLeast18YearsOld(birthdayYear, birthdayMonth, birthdayDay)) {
    //   toastErrorMsg.current.show({
    //     severity: 'error',
    //     summary: 'Step 1 - Birthday Invalid',
    //     detail: 'You need to be at least 18 years old',
    //     life: 3000,
    //   })
    //   return false
    // }

    // if email address is not correct syntax
    // let validRegex =
    //   /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

    // if (!email_address.match(validRegex)) {
    //   toastErrorMsg.current.show({
    //     severity: 'error',
    //     summary: 'Step 1 - Email Invalid',
    //     detail: 'Please provide a valid email address',
    //     life: 3000,
    //   })
    //   return false
    // }

    // if (!isSameAddress) {
    //   // has different mailing address
    //   if (
    //     !mailing_city ||
    //     !mailing_postal_code ||
    //     !mailing_state ||
    //     !mailing_street_1
    //   ) {
    //     toastErrorMsg.current.show({
    //       severity: 'error',
    //       summary: 'Step 1 - Incompleted',
    //       detail: 'Please make sure all required fields are filled',
    //       life: 3000,
    //     })
    //     return false
    //   }
    // }

    // step 2 form validation

    // if not filled
    if (!employment_status) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Step 2 - Incompleted',
        detail: 'Please choose your employment status',
        life: 3000,
      })
      return false
    } else if (
      employment_status === 'EMPLOYED' || // if user choose one of these type, then need to check following fields are filled
      employment_status === 'SELF_EMPLOYED'
    ) {
      //it has other fields to check
      if (
        !company_name ||
        !employment_field ||
        !employment_position ||
        !employer_address_street_1 ||
        !employer_address_city ||
        !employer_address_postal_code
      ) {
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Step 2 - Incompleted',
          detail: 'Please make sure all required fields are filled',
          life: 3000,
        })
        return false
      }

      if (isInternationalUser) {
        if (!international_employer_country) {
          toastErrorMsg.current.show({
            severity: 'error',
            summary: 'Step 2 - Incompleted',
            detail: 'Please make sure all required fields are filled',
            life: 3000,
          })
          return false
        }
      }

      const hasRegion = isInternationalUser
        ? international_employer_region
        : employer_address_state

      if (!hasRegion) {
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Step 2 - Incompleted',
          detail: 'Please make sure all required fields are filled',
          life: 3000,
        })
        return false
      }
    }

    //step 3 form validation for empty fields
    if (!annual_net_income || !net_worth || !liquid_net_worth) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Step 3 - Incompleted',
        detail: 'Please make sure all required fields are filled',
        life: 3000,
      })
      return false
    }

    const isIncomeSourcesEmpty = () => {
      for (const obj of incomeSources) {
        if (obj.source_type === '' || !obj.percentage) {
          return true
        }

        if (obj.source_type === 'SOW-IND-Other') {
          if (!obj.description) {
            return true
          }
        }
      }
      return false
    }

    const isPercentageValid = () => {
      let totalPercentage = 0

      for (const obj of incomeSources) {
        const parsedPercentage = parseFloat(obj.percentage)
        if (isNaN(parsedPercentage) || parsedPercentage < 0) {
          return false // If percentage is not a valid number or is negative, return false
        }

        totalPercentage += parsedPercentage
      }

      return totalPercentage === 100 // Return true if the total percentage is equal to 100, otherwise false
    }

    // if it is empty
    if (isIncomeSourcesEmpty()) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Step 3 - Incompleted',
        detail: 'Please make sure "Source of Wealth" are all filled',
        life: 3000,
      })
      return false
    }

    // if not equal to 100 percentage total
    if (!isPercentageValid()) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Step 3 - Source of Wealth Percentage Error',
        detail: 'Please make sure all percentage are added up to 100%',
        life: 3000,
      })
      return false
    }

    if (employment_status === 'EMPLOYED') {
      const hasIncomeSource = incomeSources?.some(
        (item) => item.source_type === 'SOW-IND-Income'
      )

      if (!hasIncomeSource) {
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Step 3 - Source of Wealth Miss',
          detail:
            'Source of Wealth must included "Income" when you are employed',
          life: 3000,
        })
        return false
      }
    }

    //step 4 form validation for empty field
    if (
      !risk_tolerance ||
      !investment_plan ||
      !knowledge_level ||
      !trades_per_year ||
      !years_trading ||
      investment_objectives?.length === 0
    ) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Step 4 - Incompleted',
        detail: 'Please make sure all required fields are filled',
        life: 3000,
      })
      return false
    }

    if (
      investment_objectives?.length === 1 &&
      // investment_objectives[0] === 'Trading Profits'
      investment_objectives[0] === 'Trading'
    ) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Step 4 - Invalid',
        detail: 'You cannot selected only "Trading Profits" alone',
        life: 3000,
      })
      return false
    }

    if (
      investment_objectives?.length === 1 &&
      // investment_objectives[0] === 'Preservation of Capital'
      investment_objectives[0] === 'Preservation'
    ) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Step 4 - Invalid',
        detail: 'You cannot selected only "Preservation of Capital" alone',
        life: 3000,
      })
      return false
    }

    // if user choose add the option, check option fields are filled
    if (optionsToggleStatus === 'Yes') {
      if (
        !options_knowledge_level ||
        !options_trades_per_year ||
        !options_years_trading
      ) {
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Step 4 - Incompleted',
          detail: 'Please make sure all required fields are filled',
          life: 3000,
        })
        return false
      }
    }

    // if user choose add the bonds, check bonds fields are filled
    if (bondsToggleStatus === 'Yes') {
      if (
        !bonds_knowledge_level ||
        !bonds_trades_per_year ||
        !bonds_years_trading
      ) {
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Step 4 - Incompleted',
          detail: 'Please make sure all required fields are filled',
          life: 3000,
        })
        return false
      }
    }

    // step 5 form validation for empty fields
    if (isEligibilityWrong) {
      if (!affiliations_description) {
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Step 5 - Incompleted',
          detail: 'Please make sure all required fields are filled',
          life: 3000,
        })
        return false
      }
    }

    // step 6 document validation
    if (!isAllDocUploaded()) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Step 6 - Incompleted',
        detail: 'Please make sure all documents are uploaded',
        life: 3000,
      })
      return false
    }

    if (
      selectedOptions?.['Proof of identity and date of birth'] === 'Passport' ||
      selectedOptions?.['Proof of identity and date of birth'] ===
        'Driver License'
    ) {
      if (!documentsExpiration) {
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Step 6 - Incompleted',
          detail: 'The expiration date information is required',
          life: 3000,
        })
        return false
      }
    }

    if (
      selectedOptions?.['Proof of address'] === 'Passport' ||
      selectedOptions?.['Proof of address'] === 'Driver License'
    ) {
      if (!addressDocExpiration) {
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Step 6 - Incompleted',
          detail: 'The expiration date information is required',
          life: 3000,
        })
        return false
      }
    }

    // if (
    //   selectedOptions?.['Proof of identity and date of birth'] ===
    //   'Driver License'
    // ) {
    //   if (!documentsExpiration) {
    //     toastErrorMsg.current.show({
    //       severity: 'error',
    //       summary: 'Step 6 - Incompleted',
    //       detail: 'The expiration date information is required',
    //       life: 3000,
    //     })
    //     return false
    //   }
    // }

    if (!documentNumber) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Step 6 - Incompleted',
        detail: 'The document number is required',
        life: 3000,
      })
      return false
    }

    // step 5 form validation for empty fields
    // if (
    //   !regulatory_question_1 ||
    //   !regulatory_question_2 ||
    //   !regulatory_question_3
    //   // ||
    //   // !signedFullName ||
    //   // signedFullName?.trim() === ''
    // ) {
    //   toastErrorMsg.current.show({
    //     severity: 'error',
    //     summary: 'Step 5 - Incompleted',
    //     detail: 'Please make sure all required fields are filled',
    //     life: 3000,
    //   })
    //   return false
    // }

    // let userFullName = ''

    // // if the middle is there and not empty string
    // if (IBFormInfo?.middle_name && IBFormInfo?.middle_name?.trim() !== '') {
    //   userFullName = `${first_name?.trim()} ${IBFormInfo?.middle_name?.trim()} ${last_name?.trim()}`
    // } else {
    //   userFullName = `${first_name?.trim()} ${last_name?.trim()}`
    // }

    // console.log('yuour full name: ' + userFullName)

    // if (signedFullName?.trim() !== userFullName) {
    //   toastErrorMsg.current.show({
    //     severity: 'error',
    //     summary: 'Step 5 - Full name does not match',
    //     detail:
    //       'Please ensure the name you signed matches the name you entered before',
    //     life: 3000,
    //   })
    //   return false
    // }

    // check if user check all the agreement before submit
    // if (
    //   !agreeIBPaperwork ||
    //   !agreeForescitePaperwork ||
    //   !agreeTaxFormPaperwork
    // ) {
    //   toastErrorMsg.current.show({
    //     severity: 'error',
    //     summary: 'Step 5 - Incompleted',
    //     detail: 'Please read and check your agreement before continuing',
    //     life: 3000,
    //   })
    //   return false
    // }

    return true
  }

  // creat the account body for the api payload for create account
  const createIBAcctBody = () => {
    let body = {}
    body = {
      salutation: 'Mr.',
      // first_name: IBFormInfo?.first_name,
      // middle_name: IBFormInfo?.middle_name || '',
      // last_name: IBFormInfo?.last_name,
      // birthdayDay: IBFormInfo?.birthdayDay?.toString(),
      // birthdayMonth: IBFormInfo?.birthdayMonth?.toString(),
      // birthdayYear: IBFormInfo?.birthdayYear?.toString(),

      first_name: props?.prefinraData?.payload?.first_name,
      middle_name: '',
      last_name: props?.prefinraData?.payload?.last_name,
      birthdayDay: props?.prefinraData?.payload?.birth_day
        ?.toString()
        .padStart(2, '0'),
      birthdayMonth: props?.prefinraData?.payload?.birth_month
        ?.toString()
        .padStart(2, '0'),

      birthdayYear: props?.prefinraData?.payload?.birth_year,

      country_of_birth: IBFormInfo?.country_of_birth,
      citizenship: IBFormInfo?.citizenship,
      marital_status: IBFormInfo?.marital_status,
      no_dependents: IBFormInfo?.no_dependents,

      residence_details: {
        city: IBFormInfo?.city,
        country: props?.prefinraData?.payload?.ustaxpayer
          ? 'USA'
          : IBFormInfo?.international_country_address,
        postal_code: IBFormInfo?.postal_code,
        state: props?.prefinraData?.payload?.ustaxpayer
          ? IBFormInfo?.state
          : IBFormInfo?.international_region,
        // street_1: IBFormInfo?.street_2
        //   ? `${IBFormInfo?.street_1} ${IBFormInfo?.street_2}`
        //   : IBFormInfo?.street_1,
        street_1: IBFormInfo?.street_1 ?? '',
        street_2: IBFormInfo?.street_2 ?? '',
      },
      contact_details: {
        // phone_no: `${IBFormInfo?.phone_no?.replace(/\D/g, '')}`,
        // phone_no: props?.prefinraData?.payload?.phone?.substring(2),
        phone_no: props?.prefinraData?.payload?.phone,
        type: 'Mobile',
        country: '',
      },
      international_user: !props?.prefinraData?.payload?.ustaxpayer,
      employment_type: IBFormInfo?.employment_status,

      employment_details: {
        employer: IBFormInfo?.company_name || '',
        occupation: IBFormInfo?.employment_position || '',
        employer_business: IBFormInfo?.employment_field || '',
        employer_address: {
          city: IBFormInfo?.employer_address_city || '',
          country: props?.prefinraData?.payload?.ustaxpayer
            ? 'USA'
            : IBFormInfo?.international_employer_country || '',
          postal_code: IBFormInfo?.employer_address_postal_code || '',
          state: props?.prefinraData?.payload?.ustaxpayer
            ? IBFormInfo?.employer_address_state || ''
            : IBFormInfo?.international_employer_region || '',
          street_1: IBFormInfo?.employer_address_street_1 || '',
          street_2: IBFormInfo?.employer_address_street_2 || '',
        },
      },

      tax_information: {
        document_type: props?.prefinraData?.payload?.ustaxpayer ? 'SSN' : 'TIN',
        ID: props?.prefinraData?.payload?.ustaxpayer
          ? IBFormInfo?.ssn?.replace(/-/g, '')
          : IBFormInfo?.tax_id,
        country: props?.prefinraData?.payload?.ustaxpayer
          ? 'USA'
          : IBFormInfo?.citizenship,
        customer_type: 'INDIVIDUAL',
        form_file_name: props?.prefinraData?.payload?.ustaxpayer
          ? 'Form5003.pdf'
          : 'Form5001.pdf',
      },
      financial_information: {
        annual_net_income: IBFormInfo?.annual_net_income,
        liquid_net_worth: IBFormInfo?.liquid_net_worth,
        net_worth: IBFormInfo?.net_worth,
      },
      investment_experience: {
        STK: {
          knowledge_level: IBFormInfo?.knowledge_level,
          trades_per_year: IBFormInfo?.trades_per_year,
          years_trading: IBFormInfo?.years_trading,
        },
        OPT: {
          knowledge_level: IBFormInfo?.options_knowledge_level || '',
          trades_per_year: IBFormInfo?.options_trades_per_year || '',
          years_trading: IBFormInfo?.options_years_trading || '',
        },
        BOND: {
          knowledge_level: IBFormInfo?.bonds_knowledge_level || '',
          trades_per_year: IBFormInfo?.bonds_trades_per_year || '',
          years_trading: IBFormInfo?.bonds_years_trading || '',
        },
      },
      income_source_details: incomeSources,

      regulatory_information: {
        // 'Selection 1': JSON.parse(IBFormInfo?.regulatory_question_1),
        // 'Selection 2': JSON.parse(IBFormInfo?.regulatory_question_2),
        // 'Selection 3': JSON.parse(IBFormInfo?.regulatory_question_3),
        'Selection 1': true,
        'Selection 2': true,
        'Selection 3': true,
      },
      identification_number: documentNumber,
      identification_type: documentType,
      regulatory_description: {
        AFFILIATION: IBFormInfo?.affiliations_description
          ? { reason: IBFormInfo?.affiliations_description }
          : '',
        ControlPubTraded: IBFormInfo?.affiliations_description
          ? { reason: IBFormInfo?.affiliations_description }
          : '',
        EmployeePubTrade: IBFormInfo?.affiliations_description
          ? { reason: IBFormInfo?.affiliations_description }
          : '',
      },
      //  old one for "xx + xx" logic IBFormInfo?.investment_objectives[0]?.split(/\s*\+\s*/)
      // investment_objectives:
      //   optionsToggleStatus === 'Yes' || bondsToggleStatus === 'Yes'
      //     ? IBFormInfo?.investment_objectives[0]?.split(/\s*\+\s*/)
      //     : IBFormInfo?.investment_objectives,

      investment_objectives:
        optionsToggleStatus === 'Yes' || bondsToggleStatus === 'Yes'
          ? fetchProperInvestmentName(IBFormInfo?.investment_objectives[0])
          : IBFormInfo?.investment_objectives,
    }
    return body
  }

  const fetchProperInvestmentName = (name) => {
    const list = [
      {
        label: 'Growth + Trading Profits + Speculation + Hedging',
        value: ['Growth', 'Trading', 'Speculation', 'Hedging'],
      },
      {
        label: 'Growth + Speculation + Hedging',
        value: ['Growth', 'Speculation', 'Hedging'],
      },
      {
        label: 'Speculation + Hedging + Trading Profits',
        value: ['Speculation', 'Hedging', 'Trading'],
      },
      {
        label: 'Growth + Trading Profits',
        value: ['Growth', 'Trading'],
      },
      {
        label: 'Hedging + Trading Profits',
        value: ['Hedging', 'Trading'],
      },
      {
        label: 'Speculation + Hedging',
        value: ['Speculation', 'Hedging'],
      },
      {
        label: 'Growth + Speculation',
        value: ['Growth', 'Speculation'],
      },
    ]

    const matchedItem = list?.find((item) => item?.label === name)
    return matchedItem ? matchedItem.value : []
  }

  const createAccount = async (body) => {
    // call the api to submit the form
    try {
      let res = await axios.post(
        `${BaseURL}/ibkr/create-user`,
        JSON.stringify(body),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          },
        }
      )

      // let res2 = await axios.get(`${BaseURL}/mobile/account-created`, {
      //   headers: {
      //     'Content-Type': 'application/json',
      //     Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
      //   },
      // })

      // let res3 = await axios.get(
      //   `${BaseURL}/mobile/update/account-creation-request`,
      //   {
      //     headers: {
      //       'Content-Type': 'application/json',
      //       Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
      //     },
      //   }
      // )

      console.log(res)
      // console.log(res2)
      // console.log(res3)
      return true
    } catch (e) {
      console.log(e)
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Submit form failed',
        detail: 'Something went wrong, please try again later',
        life: 3000,
      })
    }
    return false
  }

  const submitFinra = async () => {
    let finraForm = {
      subPackage: 'basic',
      birthdayDay: 0,
      birthdayMonth: 0,
      birthdayYear: 0,
    }

    if (IBFormInfo?.investment_plan === '1-5') {
      finraForm.investmentMainReason = 'SHORT_TERM'
    } else if (IBFormInfo?.investment_plan === '5-10') {
      finraForm.investmentMainReason = 'MEDIUM_TERM'
    } else {
      finraForm.investmentMainReason = 'LONG_TERM'
    }

    if (IBFormInfo?.investment_plan === '1-5') {
      finraForm.investmentPeriod = 5
    } else if (IBFormInfo?.investment_plan === '5-10') {
      finraForm.investmentPeriod = 10
    } else if (IBFormInfo?.investment_plan === '10-20') {
      finraForm.investmentPeriod = 20
    } else if (IBFormInfo?.investment_plan === '20-30') {
      finraForm.investmentPeriod = 30
    } else {
      finraForm.investmentPeriod = 40
    }

    if (
      IBFormInfo?.risk_tolerance === 'very aggressive' ||
      IBFormInfo?.risk_tolerance === 'aggressive'
    ) {
      finraForm.riskTolerance = 'GAINS'
    } else {
      finraForm.riskTolerance = IBFormInfo?.risk_tolerance
    }

    if (IBFormInfo?.knowledge_level === 'None') {
      finraForm.investmentExperience = 'NONE'
    } else if (IBFormInfo?.knowledge_level === 'Limited') {
      finraForm.investmentExperience = 'SOME'
    } else {
      finraForm.investmentExperience = 'CONSIDERABLE'
    }

    let trade = Number(IBFormInfo?.trades_per_year)

    if (trade < 20) {
      finraForm.investmentObjectives = 'NEW'
    } else if (trade < 80) {
      finraForm.investmentObjectives = 'INFREQUENT'
    } else if (trade < 300) {
      finraForm.investmentObjectives = 'FREQUENT'
    } else if (trade < 500) {
      finraForm.investmentObjectives = 'ACTIVE_DAILY'
    } else {
      finraForm.investmentObjectives = 'LONG_TERM'
    }

    try {
      let res = await axios.post(`${BaseURL}/mobile/dw/quiz`, finraForm, {
        headers: {
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
        },
      })

      if (res?.data?.success) {
        return true
      } else {
        return false
      }
    } catch (error) {
      console.log(error)
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Submit Finra Questions failed',
        detail: 'Something went wrong, please try again later',
        life: 3000,
      })
    }
    return false
  }

  const formatDateObj = (date) => {
    const originalDate = new Date(date)

    const year = originalDate.getFullYear()
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0')
    const day = originalDate.getDate().toString().padStart(2, '0')

    const formattedDate = `${year}-${month}-${day}`
    return formattedDate
  }

  const submitForm = async () => {
    // validat the form

    // if all form field are valid
    if (validateForm()) {
      // structure the body

      let body = createIBAcctBody()

      console.log(JSON.stringify(body))
      // console.log('documentNumber', documentNumber)

      setSubmitFormButtonClick(true)

      if ((await submitFinra()) && (await submitDocuments())) {
        if (await createAccount(body)) {
          console.log('after create-user api if statment')
          return true
        } else {
          return false
        }
      } else {
        return false
      }

      // if (createAccount(body)) {
      //   return true
      // } else {
      //   return false
      // }
    } else {
      console.log('failed validate form')
    }

    return false
  }

  const submitDocuments = async () => {
    //  setLoading(true)

    console.log('inside submitDocuments')
    const formData = new FormData()

    const metadataObj = {}

    // formData.append('metadata', JSON.stringify(metadataObj))

    for (const [key, value] of Object.entries(selectedOptionsDoc)) {
      // if it is image, then do the image extension else if pdf do pdf extension
      if (value?.type?.includes('image')) {
        formData.append('file', value, `${key || ''}--${Date.now()}.jpeg`)
      } else if (value?.type?.includes('pdf')) {
        formData.append('file', value, `${key || ''}--${Date.now()}.pdf`)
      }

      // if you can find it is existed then it has options dropdown
      if (selectedOptions?.[key]) {
        metadataObj[key || ''] = selectedOptions?.[key]

        // if it is passport and driver license then add the expiration date

        if (key === 'Proof of identity and date of birth') {
          if (selectedOptions?.[key] === 'Driver License') {
            console.log('inside driver lience pass')
            metadataObj[`${key} expiry` || ''] =
              formatDateObj(documentsExpiration) ?? ''
          } else if (selectedOptions?.[key] === 'Passport') {
            console.log('inside passport pass')
            metadataObj[`${key} expiry` || ''] =
              formatDateObj(documentsExpiration) ?? ''
          }
        } else if (key === 'Proof of address') {
          if (selectedOptions?.[key] === 'Driver License') {
            console.log('inside driver lience pass')
            metadataObj[`${key} expiry` || ''] =
              formatDateObj(addressDocExpiration) ?? ''
          } else if (selectedOptions?.[key] === 'Passport') {
            console.log('inside passport pass')
            metadataObj[`${key} expiry` || ''] =
              formatDateObj(addressDocExpiration) ?? ''
          }
        }
      } else {
        metadataObj[key || ''] = ''
      }
    }
    formData.append('metadata', JSON.stringify(metadataObj))

    // console.log('JSON.stringify(metadataObj)', JSON.stringify(metadataObj))

    // //todo-need to comment out
    // for (let entry of formData.entries()) {
    //   console.log('entry', entry)
    // }
    // return

    try {
      // let res = await axios.post(`${BaseURL}/ibkr/upload-test`, formData, {
      //   headers: {
      //     Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
      //   },
      // })

      let res = await axios.post(`${BaseURL}/ibkr/save-file-as-hex`, formData, {
        headers: {
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
        },
      })

      console.log(res?.data)

      if (res?.data.success) {
        console.log('good to go')
        //  setLoading(false)
        //  await props?.getIBStatus()
        //  localStorage.setItem('isIBUserKYC', 'true')
        //  localStorage.setItem('userId', props?.ibInfoObj?.userID)

        //  props?.toggleUploaded()
        //  props?.closeModal()

        return true
      } else {
        console.log('failed')
        //  setLoading(false)
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Upload Documents failed',
          detail: 'Something wrong with data uploaded. Please try again',
          life: 3000,
        })
        return false
      }
    } catch (error) {
      console.log(error)
      //  setLoading(false)
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Upload Documents failed',
        detail: 'Please upload the files and try again',
        life: 3000,
      })
    }

    return false
  }

  // get the dynamic url link for the IB agreenment based on the if option or bond option is selected or not
  const getCorrectIBAgreement = () => {
    let url =
      'https://www.investall.app/disclosures-ib?disclosures=STK,BOND,OPT'

    if (bondsToggleStatus === 'Yes' && optionsToggleStatus === 'Yes') {
      url = 'https://www.investall.app/disclosures-ib?disclosures=STK,BOND,OPT'
    } else if (bondsToggleStatus === 'Yes' && optionsToggleStatus === 'No') {
      url = 'https://www.investall.app/disclosures-ib?disclosures=STK,BOND'
    } else if (bondsToggleStatus === 'No' && optionsToggleStatus === 'Yes') {
      url = 'https://www.investall.app/disclosures-ib?disclosures=STK,OPT'
    } else {
      url = 'https://www.investall.app/disclosures-ib?disclosures=STK'
    }

    return url
  }

  // handle for the investment options for multiple chekcbox or single checkbox
  const handleInvestmentObjChange = (e) => {
    let investmentObj = []
    if (IBFormInfo?.investment_objectives) {
      investmentObj = [...IBFormInfo?.investment_objectives]
    }

    // if the item is checked
    if (e.checked) {
      // if it is option and bond toggled, then it only allow select one checkbox
      if (optionsToggleStatus === 'Yes' || bondsToggleStatus === 'Yes') {
        if (IBFormInfo?.investment_objectives.length < 1) {
          investmentObj.push(e.value)
        }
      } else {
        // if not option and bond toggled, then select many as user want
        investmentObj.push(e.value)
      }
    } else {
      // if it is unchecked, then filter out the unchecked, only keep the checked item
      investmentObj = investmentObj.filter((category) => category !== e.value)
    }

    setIBFormInfo((prevData) => ({
      ...prevData,
      ['investment_objectives']: investmentObj,
    }))
  }

  // handle source of wealth option change
  const handleIncomeChange = (index, value) => {
    const updatedSources = [...incomeSources]

    // console.log('change')
    // console.log(updatedSources[index].source_type !== 'SOW-IND-Other')
    // console.log(updatedSources[index].hasOwnProperty('description'))

    // if the value is not sow-ind-other and has description then delete that
    if (
      value !== 'SOW-IND-Other' &&
      updatedSources[index].hasOwnProperty('description')
    ) {
      delete updatedSources[index].description
    }

    // console.log(updatedSources[index])

    updatedSources[index] = { ...updatedSources[index], source_type: value }
    setIncomeSources(updatedSources)
  }
  // handle source of wealth percentage change for each source option
  const handlePercentageChange = (index, value) => {
    const updatedSources = [...incomeSources]
    updatedSources[index] = {
      ...updatedSources[index],
      percentage: value?.toString(),
    }
    setIncomeSources(updatedSources)
  }

  // handle source of wealth percentage change for each source option
  const handleDescriptionChange = (index, value) => {
    const updatedSources = [...incomeSources]
    updatedSources[index] = {
      ...updatedSources[index],
      description: value,
    }
    setIncomeSources(updatedSources)
  }

  // add new source content
  const addNewIncomeSource = () => {
    setIncomeSources([
      ...incomeSources,
      { source_type: '', percentage: undefined, is_used_for_funds: true },
    ])
  }

  // handle delete the source
  const handleDelete = (index) => {
    const updatedSources = [...incomeSources]
    updatedSources.splice(index, 1)
    setIncomeSources(updatedSources)
  }

  // check if all the data is filled
  const isAllDocUploaded = () => {
    // get total size for api and the length that user filled
    let totalSize = optionsDetail?.payload?.length
    let totalFilled = Object?.values(selectedOptionsDoc)?.filter(
      (value) => value !== null && value !== ''
    )?.length

    // get the dropdown length from api and user entered
    let totalDropdownCount = optionsDetail?.payload?.filter(
      (item) => item.has_options === true
    )?.length
    let totalDropdownFilled = Object?.keys(selectedOptions)?.length

    if (
      totalSize !== totalFilled ||
      totalDropdownCount !== totalDropdownFilled
    ) {
      return false
    }
    return true
  }

  // personal information form compoennt
  const PersonalForm = () => {
    // month template for the prime react ui calander
    const monthNavigatorTemplate = (e) => {
      return (
        <Dropdown
          value={e.value}
          options={e.options}
          onChange={(event) => e.onChange(event.originalEvent, event.value)}
          style={{ lineHeight: 1 }}
        />
      )
    }

    // year template for the prime react ui calander
    const yearNavigatorTemplate = (e) => {
      return (
        <Dropdown
          value={e.value}
          options={e.options}
          onChange={(event) => e.onChange(event.originalEvent, event.value)}
          className='p-ml-2'
          style={{ lineHeight: 1 }}
        />
      )
    }

    // parse the birthday from 01/01/2023, to three variables with date, year, month separately
    const parseBirthday = (birthday) => {
      const date = new Date(birthday)
      handleBirthdayInputChange('birthday', birthday)
      handleBirthdayInputChange('birthdayDay', date.getDate())
      handleBirthdayInputChange('birthdayMonth', date.getMonth() + 1)
      handleBirthdayInputChange('birthdayYear', date.getFullYear())
    }

    return (
      <>
        <Panel header='Personal Information' toggleable>
          {/* <div className='input-container'>
            <label htmlFor='first_name'>First name</label>
            <InputText
              id='first_name'
              name='first_name'
              value={IBFormInfo?.first_name}
              placeholder='First name '
              onChange={handleIBFormInputChange}
            />
          </div>
          <div className='input-container'>
            <label htmlFor='middle_name'>Middle name</label>
            <InputText
              id='middle_name'
              name='middle_name'
              placeholder='Middle name (Optional)'
              value={IBFormInfo?.middle_name}
              onChange={handleIBFormInputChange}
            />
          </div>
          <div className='input-container'>
            <label htmlFor='last_name'>Last name</label>
            <InputText
              id='last_name'
              name='last_name'
              placeholder='Last name'
              value={IBFormInfo?.last_name}
              onChange={handleIBFormInputChange}
            />
          </div> */}

          {/* <div className='input-container'>
            <label>Date of birth</label>
            <Calendar
              value={IBFormInfo?.birthday}
              onChange={(e) => parseBirthday(e.target.value)}
              dateFormat='mm/dd/yy'
              name='birthday'
              showIcon
              icon='pi pi-calendar'
              placeholder='birthday'
              monthNavigator={true}
              yearNavigator={true}
              monthNavigatorTemplate={monthNavigatorTemplate}
              yearNavigatorTemplate={yearNavigatorTemplate}
              yearRange={`1900:${new Date().getFullYear()}`}
              className='calendar-input'
            ></Calendar>
          </div> */}

          <div className='input-container'>
            <label>Country of birth</label>
            <Dropdown
              value={IBFormInfo?.country_of_birth}
              name='country_of_birth'
              onChange={handleIBFormInputChange}
              placeholder='Country'
              options={allCountries}
            />
          </div>

          <div className='input-container'>
            <label htmlFor='citizenship'>Citizenship</label>

            <Dropdown
              value={IBFormInfo?.citizenship}
              name='citizenship'
              onChange={handleIBFormInputChange}
              options={allCountries}
              placeholder='Country'
            />
          </div>

          <div className='input-container'>
            <label>Marital Status</label>

            <Dropdown
              value={IBFormInfo?.marital_status}
              name='marital_status'
              onChange={handleIBFormInputChange}
              placeholder='Marital status'
              options={maritalStatusOptions}
            />
          </div>

          <div className='input-container'>
            {props?.prefinraData?.payload?.ustaxpayer ? (
              <>
                <label>Social Security Number</label>
                <InputMask
                  mask='999-99-9999'
                  value={IBFormInfo?.ssn}
                  name='ssn'
                  onChange={handleIBIdentificationChange}
                  placeholder='SSN'
                />
              </>
            ) : (
              // international user
              <>
                <label>Tax Identification Number</label>
                <InputText
                  id='tax_id'
                  name='tax_id'
                  value={IBFormInfo?.tax_id}
                  onChange={handleIBFormInputChange}
                  placeholder='Tax ID'
                />
              </>
            )}
          </div>

          <div className='input-container'>
            <label>Number of Dependents</label>
            <InputText
              name='no_dependents'
              value={IBFormInfo?.no_dependents}
              onChange={handleIBFormInputChange}
              keyfilter='pint'
              placeholder='Dependents'
            />
          </div>

          {/* <div className='input-container'>
            <label htmlFor='email'>Email address</label>
            <InputText
              id='email'
              keyfilter='email'
              name='email_address'
              value={IBFormInfo?.email_address}
              onChange={handleIBFormInputChange}
              placeholder='Email'
            />
          </div> */}
          {/* <div className='input-container'>
            <label htmlFor='phone'>Phone Number</label>

            <InputMask
              id='phone'
              mask='(999) 999-9999'
              // placeholder='(999) 999-9999'
              name='phone_no'
              value={IBFormInfo?.phone_no}
              onChange={handleIBFormInputChange}
              placeholder='Phone number'
            />
          </div> */}
        </Panel>
        <Panel header='Contact Address' toggleable>
          <div className='input-container'>
            <InputText
              placeholder='Address 1'
              name='street_1'
              value={IBFormInfo?.street_1}
              onChange={handleIBFormInputChange}
            />
          </div>
          <div className='input-container'>
            <InputText
              placeholder='Apt, Suite, Bldg #'
              name='street_2'
              value={IBFormInfo?.street_2}
              onChange={handleIBFormInputChange}
            />
          </div>

          {!props?.prefinraData?.payload?.ustaxpayer && (
            <div className='input-container'>
              <Dropdown
                value={IBFormInfo?.international_country_address}
                name='international_country_address'
                onChange={handleIBFormInputChange}
                placeholder='Country'
                options={allCountries}
              />
            </div>
          )}

          <div className='input-container'>
            <InputText
              placeholder={
                props?.prefinraData?.payload?.ustaxpayer
                  ? 'City'
                  : 'City / Town'
              }
              name='city'
              value={IBFormInfo?.city}
              onChange={handleIBFormInputChange}
            />
          </div>
          <div className='input-container'>
            {props?.prefinraData?.payload?.ustaxpayer ? (
              <Dropdown
                value={IBFormInfo?.state}
                name='state'
                onChange={handleIBFormInputChange}
                placeholder='State'
                options={americaStates}
              />
            ) : (
              <InputText
                placeholder='State / Province / Region'
                name='international_region'
                value={IBFormInfo?.international_region}
                onChange={handleIBFormInputChange}
              />
            )}
          </div>
          <div className='input-container'>
            <InputText
              keyfilter='pint'
              placeholder={
                props?.prefinraData?.payload?.ustaxpayer
                  ? 'Zip Code'
                  : 'Zip / Postal Code'
              }
              name='postal_code'
              value={IBFormInfo?.postal_code}
              onChange={handleIBFormInputChange}
            />
          </div>
          {/* <div className=''>
            <div
              className='checkbox-container'
              style={isSameAddress ? {} : { paddingBottom: '1rem' }}
            >
              <Checkbox
                onChange={() => setIsSameAddress(!isSameAddress)}
                checked={isSameAddress}
              />
              <label>
                My mailing address is the same as the address of residence
              </label>
            </div>

            {!isSameAddress && (
              <>
                <div className='input-container'>
                  <InputText
                    placeholder='Mailing Address 1'
                    name='mailing_street_1'
                    value={IBFormInfo?.mailing_street_1}
                    onChange={handleIBFormInputChange}
                  />
                </div>
                <div className='input-container'>
                  <InputText
                    placeholder='Apt, Suite, Bldg #'
                    name='mailing_street_2'
                    value={IBFormInfo?.mailing_street_2}
                    onChange={handleIBFormInputChange}
                  />
                </div>
                <div className='input-container'>
                  <InputText
                    placeholder='Mailing City'
                    name='mailing_city'
                    value={IBFormInfo?.mailing_city}
                    onChange={handleIBFormInputChange}
                  />
                </div>
                <div className='input-container'>
                  <Dropdown
                    value={IBFormInfo?.mailing_state}
                    name='mailing_state'
                    onChange={handleIBFormInputChange}
                    placeholder='Mailing State'
                    options={americaStates}
                  />
                </div>
                <div className='input-container'>
                  <InputText
                    keyfilter='pint'
                    placeholder='Mailing Zip Code'
                    name='mailing_postal_code'
                    value={IBFormInfo?.mailing_postal_code}
                    onChange={handleIBFormInputChange}
                  />
                </div>
              </>
            )}
          </div> */}
        </Panel>
      </>
    )
  }

  // employement information form compoennt
  const EmploymentForm = () => {
    return (
      <>
        <Panel header='Employment Information' toggleable>
          <div className='input-container'>
            <label htmlFor='employment_status'>Employment status</label>

            <Dropdown
              value={IBFormInfo?.employment_status}
              name='employment_status'
              onChange={handleIBFormInputChange}
              placeholder='Employment Status'
              options={employmentStatusOptions}
            />
          </div>

          {(IBFormInfo?.employment_status === 'EMPLOYED' ||
            IBFormInfo?.employment_status === 'SELF_EMPLOYED') && (
            <>
              <div className='input-container'>
                <label htmlFor='company_name'>Employer Name</label>
                <InputText
                  id='company_name'
                  name='company_name'
                  value={IBFormInfo?.company_name}
                  onChange={handleIBFormInputChange}
                  placeholder='Employer Name'
                />
              </div>

              <div className='input-container'>
                <label htmlFor='employment_field'>Industry Type </label>
                <Dropdown
                  value={IBFormInfo?.employment_field}
                  name='employment_field'
                  onChange={handleIBFormInputChange}
                  placeholder='Industry Type'
                  options={employmentTypeOptions}
                />
              </div>

              <div className='input-container'>
                <label htmlFor='employment_position'>Employment Position</label>
                <Dropdown
                  value={IBFormInfo?.employment_position}
                  name='employment_position'
                  onChange={handleIBFormInputChange}
                  placeholder='Employment Position'
                  options={employmentPositionOptions}
                />
              </div>
            </>
          )}
        </Panel>

        {(IBFormInfo?.employment_status === 'EMPLOYED' ||
          IBFormInfo?.employment_status === 'SELF_EMPLOYED') && (
          <>
            <Panel header='Employer Address' toggleable>
              <div className='input-container'>
                <InputText
                  placeholder='Address 1'
                  name='employer_address_street_1'
                  value={IBFormInfo?.employer_address_street_1}
                  onChange={handleIBFormInputChange}
                />
              </div>
              <div className='input-container'>
                <InputText
                  placeholder='Apt, Suite, Bldg #'
                  name='employer_address_street_2'
                  value={IBFormInfo?.employer_address_street_2}
                  onChange={handleIBFormInputChange}
                />
              </div>

              {!props?.prefinraData?.payload?.ustaxpayer && (
                <div className='input-container'>
                  <Dropdown
                    value={IBFormInfo?.international_employer_country}
                    name='international_employer_country'
                    onChange={handleIBFormInputChange}
                    placeholder='Country'
                    options={allCountries}
                  />
                </div>
              )}

              <div className='input-container'>
                <InputText
                  placeholder={
                    props?.prefinraData?.payload?.ustaxpayer
                      ? 'City'
                      : 'City / Town'
                  }
                  name='employer_address_city'
                  value={IBFormInfo?.employer_address_city}
                  onChange={handleIBFormInputChange}
                />
              </div>
              <div className='input-container'>
                {props?.prefinraData?.payload?.ustaxpayer ? (
                  <Dropdown
                    value={IBFormInfo?.employer_address_state}
                    name='employer_address_state'
                    onChange={handleIBFormInputChange}
                    placeholder='State'
                    options={americaStates}
                  />
                ) : (
                  <InputText
                    placeholder='State / Province / Region'
                    name='international_employer_region'
                    value={IBFormInfo?.international_employer_region}
                    onChange={handleIBFormInputChange}
                  />
                )}
              </div>
              <div className='input-container'>
                <InputText
                  keyfilter='pint'
                  placeholder={
                    props?.prefinraData?.payload?.ustaxpayer
                      ? 'Zip Code'
                      : 'Zip / Postal Code'
                  }
                  name='employer_address_postal_code'
                  value={IBFormInfo?.employer_address_postal_code}
                  onChange={handleIBFormInputChange}
                />
              </div>
            </Panel>
          </>
        )}
      </>
    )
  }

  //financial information form compoennt
  const FinancialForm = () => {
    return (
      <>
        <Panel header='Income Information' toggleable>
          <div className='input-container'>
            <label>Annual net income ($USD)</label>
            {/* <InputText
              name='annual_net_income'
              value={IBFormInfo?.annual_net_income}
              onChange={handleIBFormInputChange}
              placeholder='Annual Income'
              keyfilter='pnum'
            /> */}

            <Dropdown
              value={IBFormInfo?.annual_net_income}
              name='annual_net_income'
              onChange={handleIBFormInputChange}
              placeholder='Annual Income'
              options={FinancialAnnualList}
            />
          </div>
          <div className='input-container'>
            <label>Net worth ($USD)</label>
            {/* <InputText
              name='net_worth'
              value={IBFormInfo?.net_worth}
              onChange={handleIBFormInputChange}
              placeholder='Net worth'
              keyfilter='num'
            /> */}

            <Dropdown
              value={IBFormInfo?.net_worth}
              name='net_worth'
              onChange={handleIBFormInputChange}
              placeholder='Net worth'
              options={FinancialNetWorthList}
            />
          </div>
          <div className='input-container'>
            <label>Liquid net worth ($USD)</label>
            {/* <InputText
              name='liquid_net_worth'
              value={IBFormInfo?.liquid_net_worth}
              onChange={handleIBFormInputChange}
              placeholder='Liquid net worth'
              keyfilter='num'
            /> */}

            <Dropdown
              value={IBFormInfo?.liquid_net_worth}
              name='liquid_net_worth'
              onChange={handleIBFormInputChange}
              placeholder='Liquid net worth'
              options={FinancialNetLiquidList}
            />
          </div>
        </Panel>

        <Panel header='Source of Wealth' toggleable>
          <div
            className='source-wealth-container'
            style={incomeSources?.length === 1 ? { gap: '0rem' } : {}}
          >
            {incomeSources.map((source, index) => (
              <div key={index}>
                <div className='input-container source-wealth-options-container'>
                  <Dropdown
                    value={source?.source_type}
                    name={`source_type`}
                    onChange={(e) => handleIncomeChange(index, e.target.value)}
                    placeholder={`Source ${index + 1}`}
                    options={sourceOfWealthOptions}
                  />

                  {index !== 0 && (
                    <Button
                      label='Remove'
                      className='p-button-danger p-button-text'
                      onClick={() => handleDelete(index)}
                    />
                  )}
                </div>

                <div className='input-container'>
                  <label>Percentage used to fund the account</label>

                  <InputNumber
                    value={source?.percentage}
                    onValueChange={(e) =>
                      handlePercentageChange(index, e.value)
                    }
                    suffix=' %'
                    min={0}
                    max={100}
                    placeholder='Percentage'
                  />
                </div>

                {source?.source_type === 'SOW-IND-Other' && (
                  <div className='input-container'>
                    <label>Description</label>

                    <InputText
                      value={source?.description}
                      onChange={(e) =>
                        handleDescriptionChange(index, e.target.value)
                      }
                      placeholder='Description'
                    />
                  </div>
                )}
              </div>
            ))}
            <Button
              icon='pi pi-plus'
              label='Add another source'
              onClick={addNewIncomeSource}
            />
          </div>
        </Panel>
      </>
    )
  }

  //investment form component
  const InvestmentForm = () => {
    return (
      <>
        <Panel header='Investment Experience' toggleable>
          <div className='input-container'>
            <label>Investment Plan</label>

            <Dropdown
              value={IBFormInfo?.investment_plan}
              name='investment_plan'
              onChange={handleIBFormInputChange}
              placeholder='Investment Plan'
              options={PlanInvestmentList}
            />
          </div>

          <div className='input-container'>
            <label>Risk Tolerance</label>

            <Dropdown
              value={IBFormInfo?.risk_tolerance}
              name='risk_tolerance'
              onChange={handleIBFormInputChange}
              placeholder='Risk Tolerance'
              options={RiskToleranceList}
            />
          </div>
        </Panel>

        <Panel header='Investment Trading' toggleable>
          <div className='investment-single-group'>
            <label>Stock</label>

            <div className='investment-inputs-container'>
              <div className='input-container'>
                <label>Knowledge Level</label>
                <Dropdown
                  value={IBFormInfo?.knowledge_level}
                  name='knowledge_level'
                  onChange={handleIBFormInputChange}
                  placeholder='Level'
                  options={knowledgeOptions}
                />
              </div>

              <div className='input-container'>
                <label>Trades per year</label>
                <InputText
                  placeholder='Number of trade'
                  name='trades_per_year'
                  value={IBFormInfo?.trades_per_year}
                  onChange={handleIBFormInputChange}
                  keyfilter={'pint'}
                />
              </div>
              <div className='input-container'>
                <label>Years trading</label>
                <InputText
                  placeholder='Trading experience'
                  name='years_trading'
                  value={IBFormInfo?.years_trading}
                  onChange={handleIBFormInputChange}
                  keyfilter={'pint'}
                />
              </div>
            </div>
          </div>
          <div className='investment-single-group'>
            <label>Options</label>

            <SelectButton
              value={optionsToggleStatus}
              onChange={(e) => setOptionsToggleStatus(e.value)}
              options={toggleButtonOptions}
            />

            {optionsToggleStatus === 'Yes' && (
              <div className='investment-inputs-container'>
                <div className='input-container'>
                  <label>Knowledge Level</label>
                  <Dropdown
                    value={IBFormInfo?.options_knowledge_level}
                    name='options_knowledge_level'
                    onChange={handleIBFormInputChange}
                    placeholder='Level'
                    options={knowledgeOptions}
                  />
                </div>

                <div className='input-container'>
                  <label>Trades per year</label>
                  <InputText
                    placeholder='Number of trade'
                    name='options_trades_per_year'
                    value={IBFormInfo?.options_trades_per_year}
                    onChange={handleIBFormInputChange}
                    keyfilter={'pint'}
                  />
                </div>
                <div className='input-container'>
                  <label>Years trading</label>
                  <InputText
                    placeholder='Trading experience'
                    name='options_years_trading'
                    value={IBFormInfo?.options_years_trading}
                    onChange={handleIBFormInputChange}
                    keyfilter={'pint'}
                  />
                </div>
              </div>
            )}
          </div>

          <div className='investment-single-group'>
            <label>Bonds</label>

            <SelectButton
              value={bondsToggleStatus}
              onChange={(e) => setBondsToggleStatus(e.value)}
              options={toggleButtonOptions}
            />

            {bondsToggleStatus === 'Yes' && (
              <div className='investment-inputs-container'>
                <div className='input-container'>
                  <label>Knowledge Level</label>
                  <Dropdown
                    value={IBFormInfo?.bonds_knowledge_level}
                    name='bonds_knowledge_level'
                    onChange={handleIBFormInputChange}
                    placeholder='Level'
                    options={knowledgeOptions}
                  />
                </div>

                <div className='input-container'>
                  <label>Trades per year</label>
                  <InputText
                    placeholder='Number of trade'
                    name='bonds_trades_per_year'
                    value={IBFormInfo?.bonds_trades_per_year}
                    onChange={handleIBFormInputChange}
                    keyfilter={'pint'}
                  />
                </div>
                <div className='input-container'>
                  <label>Years trading</label>
                  <InputText
                    placeholder='Trading experience'
                    name='bonds_years_trading'
                    value={IBFormInfo?.bonds_years_trading}
                    onChange={handleIBFormInputChange}
                    keyfilter={'pint'}
                  />
                </div>
              </div>
            )}
          </div>
        </Panel>

        <Panel header='Investment Objective' toggleable>
          <div className='multiple-checkbox'>
            {optionsToggleStatus === 'Yes' || bondsToggleStatus === 'Yes'
              ? InvestmentOptionObjList.map((optionObj) => {
                  return (
                    <div key={optionObj} className='checkbox-container'>
                      <Checkbox
                        inputId={optionObj}
                        name={optionObj}
                        value={optionObj}
                        onChange={handleInvestmentObjChange}
                        checked={IBFormInfo?.investment_objectives?.some(
                          (item) => item === optionObj
                        )}
                      />
                      <label htmlFor={optionObj} className='ml-2'>
                        {optionObj}
                      </label>
                    </div>
                  )
                })
              : InvestmentNoOptionObjeList.map((optionObj) => {
                  return (
                    <div key={optionObj?.label} className='checkbox-container'>
                      <Checkbox
                        inputId={optionObj?.label}
                        name={optionObj?.label}
                        value={optionObj?.value}
                        onChange={handleInvestmentObjChange}
                        checked={IBFormInfo?.investment_objectives?.some(
                          (item) => item === optionObj?.value
                        )}
                      />
                      <label htmlFor={optionObj?.label} className='ml-2'>
                        {optionObj?.label}
                      </label>
                    </div>
                  )
                })}
          </div>
        </Panel>
      </>
    )
  }

  // agreement and yes/no questions for form components
  const AcknowledgmentForm = () => {
    return (
      <>
        <Panel header='Eligibility Confirmation' toggleable>
          <div className='multiple-radio-buttons'>
            <div>
              {/* <label>
                Are you or any immediate family member who resides in the same
                household, registered as a broker-dealer or an employee,
                director or owner of a securities or commodoties brokerage firm?
              </label> */}

              {/* <div className='radio-button-container'>
                <RadioButton
                  inputId='regulatory_question_1'
                  name='regulatory_question_1'
                  value={'true'}
                  onChange={handleIBFormInputChange}
                  checked={IBFormInfo?.regulatory_question_1 === 'true'}
                />
                <label htmlFor='regulatory_question_1' className='ml-2'>
                  Yes
                </label>
              </div> */}

              <div className='radio-button-container'>
                <RadioButton
                  inputId='regulatory_question_1'
                  name='regulatory_question_1'
                  value={'true'}
                  onChange={handleIBFormInputChange}
                  checked={true}
                />

                {/* <Checkbox
                  inputId='regulatory_question_1'
                  name='regulatory_question_1'
                  value={'true'}
                  onChange={handleIBFormInputChange}
                  checked={true}
                ></Checkbox> */}

                <label htmlFor='regulatory_question_1' className='ml-2'>
                  I’m not a employee of a publicly traded company.
                </label>
              </div>
              {/* <div className='radio-button-container'>
                <RadioButton
                  inputId='regulatory_question_1'
                  name='regulatory_question_1'
                  value={'false'}
                  onChange={handleIBFormInputChange}
                  checked={IBFormInfo?.regulatory_question_1 === 'false'}
                />
                <label htmlFor='regulatory_question_1' className='ml-2'>
                  No
                </label>
              </div> */}
            </div>

            <div>
              {/* <label>Do you control a publicly traded company?</label> */}

              <div className='radio-button-container'>
                <RadioButton
                  inputId='regulatory_question_2'
                  name='regulatory_question_2'
                  value={'true'}
                  onChange={handleIBFormInputChange}
                  checked={true}
                />

                {/* <Checkbox
                  inputId='regulatory_question_2'
                  name='regulatory_question_2'
                  value={'true'}
                  onChange={handleIBFormInputChange}
                  checked={true}
                ></Checkbox> */}

                <label htmlFor='regulatory_question_2' className='ml-2'>
                  I don’t control a publicly traded company.
                </label>
              </div>
              {/* <div className='radio-button-container'>
                <RadioButton
                  inputId='regulatory_question_2'
                  name='regulatory_question_2'
                  value={'false'}
                  onChange={handleIBFormInputChange}
                  checked={IBFormInfo?.regulatory_question_2 === 'false'}
                />
                <label htmlFor='regulatory_question_2' className='ml-2'>
                  No
                </label>
              </div> */}
            </div>

            <div>
              {/* <label>Are you an employee of a publicly traded company?</label> */}

              <div className='radio-button-container'>
                <RadioButton
                  inputId='regulatory_question_3'
                  name='regulatory_question_3'
                  value={'true'}
                  onChange={handleIBFormInputChange}
                  checked={true}
                />

                {/* <Checkbox
                  inputId='regulatory_question_3'
                  name='regulatory_question_3'
                  value={'true'}
                  onChange={handleIBFormInputChange}
                  checked={true}
                ></Checkbox> */}
                <label htmlFor='regulatory_question_3' className='ml-2'>
                  No immediate family member who resides in the same household,
                  or myself, are registered as a broker-dealer or an employee,
                  director or owner of a securities or commodities brokerage
                  firm.
                </label>
              </div>
              {/* <div className='radio-button-container'>
                <RadioButton
                  inputId='regulatory_question_3'
                  name='regulatory_question_3'
                  value={'false'}
                  onChange={handleIBFormInputChange}
                  checked={IBFormInfo?.regulatory_question_3 === 'false'}
                />
                <label htmlFor='regulatory_question_3' className='ml-2'>
                  No
                </label>
              </div> */}
            </div>
          </div>
        </Panel>

        <div
          className='checkbox-container'
          style={!isEligibilityWrong ? {} : { paddingBottom: '1rem' }}
        >
          <Checkbox
            onChange={() => setIsEligibilityWrong(!isEligibilityWrong)}
            checked={isEligibilityWrong}
          />
          <label>No, something's wrong</label>
        </div>

        {isEligibilityWrong && (
          <Panel header='Affiliations disclosure' toggleable>
            <label htmlFor=''>
              Include a detailed description of what is your affiliation with a
              publicly traded company or broker-dealer.
            </label>
            <div className='input-container'>
              <InputTextarea
                value={IBFormInfo?.affiliations_description}
                placeholder='Enter your description'
                onChange={handleIBFormInputChange}
                name='affiliations_description'
                style={{ height: '90px' }}
                // rows={5}
                // cols={10}
              />
            </div>
          </Panel>
        )}

        {/* <Panel header='Agreement' toggleable>
          <div className='multiple-checkbox'>
            <div className='checkbox-container'>
              <Checkbox
                name='agreeIBPaperwork'
                value={agreeIBPaperwork}
                onChange={() => setAgreeIBPaperwork(!agreeIBPaperwork)}
                checked={agreeIBPaperwork}
              />
              <label htmlFor='ingredient1' className='ml-2'>
                I agree to the
                <a
                  href={getCorrectIBAgreement()}
                  target='_blank'
                  rel='noreferrer'
                >
                  {' '}
                  Interactive Brokers LLC Account Agreements
                </a>
              </label>
            </div>
            <div className='checkbox-container'>
              <Checkbox
                name='agreeForescitePaperwork'
                value={agreeForescitePaperwork}
                onChange={() =>
                  setAgreeForescitePaperwork(!agreeForescitePaperwork)
                }
                checked={agreeForescitePaperwork}
              />
              <label htmlFor='ingredient1' className='ml-2'>
                I agree to the
                <a
                  href='https://www.forescite.ai/mobile-disclosures-forescite'
                  target='_blank'
                  rel='noreferrer'
                >
                  {' '}
                  Investall Advisory Agreement
                </a>
              </label>
            </div>
            <div className='checkbox-container'>
              <Checkbox
                name='agreeTaxFormPaperwork'
                value={agreeTaxFormPaperwork}
                onChange={() =>
                  setAgreeTaxFormPaperwork(!agreeTaxFormPaperwork)
                }
                checked={agreeTaxFormPaperwork}
              />
              <label htmlFor='ingredient1' className='ml-2'>
                <a
                  href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form5002.pdf'
                  target='_blank'
                  rel='noreferrer'
                >
                  Tax Form
                </a>
              </label>
            </div>
          </div>
          <br />

          <div className='input-container'>
            <label>
              I have read, understood and accepted the terms and conditions
              provided during account opening. I will comply to all the terms of
              agreements.
            </label>
            {/* <InputText
              placeholder='Signed your full name here'
              name='signedFullName'
              value={IBFormInfo?.signedFullName}
              onChange={handleIBFormInputChange}
            /> */}
        {/* </div> */}
        {/* </Panel> */}
      </>
    )
  }

  //upload documents forms
  const UploadDocumentsForm = () => {
    // document layout template for the uploaded
    const itemListTemplate = (docType) => (file, props) => {
      return (
        <div className='upload-content-container'>
          <div className='upload-content-part1-container'>
            <img
              alt={file.name}
              role='presentation'
              src={file.type === 'application/pdf' ? PDFImg : file.objectURL}
              width={file.type === 'application/pdf' ? 60 : 100}
            />
            <span>{file.name}</span>
          </div>
          <Tag
            value={props.formatSize}
            severity='warning'
            className='px-3 py-2'
          />
          <Button
            type='button'
            icon='pi pi-times'
            className='p-button-outlined p-button-rounded p-button-danger ml-auto'
            onClick={() => {
              setSelectedOptionsDoc({
                ...selectedOptionsDoc,
                [docType]: null,
              })
              props.onRemove()
            }}
            style={{ marginLeft: 'auto' }}
          />
        </div>
      )
    }

    // empty template when no doc is uploaded yet
    const emptyTemplate = (docType) => {
      return selectedOptionsDoc?.[docType] ? (
        <div className='upload-content-container'>
          <div className='upload-content-part1-container'>
            <img
              alt={selectedOptionsDoc?.[docType]?.name}
              role='presentation'
              src={
                selectedOptionsDoc?.[docType]?.type === 'application/pdf'
                  ? PDFImg
                  : selectedOptionsDoc?.[docType]?.objectURL
              }
              width={
                selectedOptionsDoc?.[docType]?.type === 'application/pdf'
                  ? 60
                  : 100
              }
            />
            <span>{selectedOptionsDoc?.[docType]?.name}</span>
          </div>
          <Tag
            value={selectedOptionsDoc?.[docType]?.size}
            severity='warning'
            className='px-3 py-2'
          />
          <Button
            type='button'
            icon='pi pi-times'
            className='p-button-outlined p-button-rounded p-button-danger ml-auto'
            onClick={() => {
              setSelectedOptionsDoc({
                ...selectedOptionsDoc,
                [docType]: null,
              })
              // props.onRemove()
            }}
            style={{ marginLeft: 'auto' }}
          />
        </div>
      ) : (
        <div className='empty-upload-container'>
          <i className='pi pi-image'></i>
          <span className='my-5'>Please upload your document</span>
        </div>
      )
    }

    // check if the doc is more than one or not, if it is 1, then update the list, if not popup the latest one and show error message
    const uploadDocsCheck =
      (docType) =>
      async ({ files }) => {
        if (files) {
          if (files?.length > 1) {
            files.pop()
            toastErrorMsg.current.show({
              severity: 'error',
              summary: 'Upload Documents failed',
              detail: 'Please remove old files and upload new one',
              life: 3000,
            })
          } else {
            setSelectedOptionsDoc({
              ...selectedOptionsDoc,
              [docType]: files[0],
            })
          }
        }
      }

    // update the option value based on the doc_type option
    const handleOptionChange = (docType, selectedOption) => {
      setSelectedOptions({
        ...selectedOptions,
        [docType]: selectedOption,
      })

      // only change when the option title is the following
      if (docType?.toLowerCase() === 'proof of identity and date of birth') {
        setDocumentsExpiration('')
        setDocumentNumber('')
        setDocumentType(selectedOption)
      } else if (docType?.toLowerCase() === 'proof of address') {
        setAddressDocExpiration('')
        // setDocumentType(selectedOption)
      }
    }

    // make single array of string into object
    const transformedOptionObj = (options) => {
      let data = options?.map((option) => ({
        label: option,
        value: option,
      }))

      return data
    }

    const monthNavigatorTemplate = (e) => {
      return (
        <Dropdown
          value={e.value}
          options={e.options}
          onChange={(event) => e.onChange(event.originalEvent, event.value)}
          style={{ lineHeight: 1 }}
        />
      )
    }

    // year template for the prime react ui calander
    const yearNavigatorTemplate = (e) => {
      return (
        <Dropdown
          value={e.value}
          options={e.options}
          onChange={(event) => e.onChange(event.originalEvent, event.value)}
          className='p-ml-2'
          style={{ lineHeight: 1 }}
        />
      )
    }

    return (
      <>
        <div className='upload-documents-container'>
          {optionsDetail?.payload?.map((singleDoc, index) => {
            return (
              <Panel
                header={
                  singleDoc?.doc_type === 'Proof of identity and date of birth'
                    ? 'Proof of identity'
                    : singleDoc?.doc_type
                }
                toggleable
                key={index}
              >
                <div className='single-container' key={index}>
                  {/* <p>{singleDoc?.doc_type}</p> */}

                  {singleDoc?.has_options && (
                    <Dropdown
                      value={selectedOptions?.[singleDoc?.doc_type]}
                      name='Select category'
                      onChange={(e) =>
                        handleOptionChange(singleDoc?.doc_type, e.target.value)
                      }
                      placeholder='Select category'
                      options={transformedOptionObj(singleDoc?.options)}
                    />
                  )}

                  {singleDoc?.is_uploaded ? (
                    <p>
                      <span>&#x2705;</span> Document already uploaded
                    </p>
                  ) : (
                    <FileUpload
                      customUpload={true}
                      uploadHandler={uploadDocsCheck(singleDoc?.doc_type)}
                      auto
                      mode='advanced'
                      chooseLabel={'Upload Document'}
                      accept='.jpg,.png,.pdf'
                      emptyTemplate={emptyTemplate(singleDoc?.doc_type)}
                      itemTemplate={itemListTemplate(singleDoc?.doc_type)}
                    />
                  )}

                  {selectedOptions?.[singleDoc?.doc_type] &&
                    singleDoc?.doc_type ===
                      'Proof of identity and date of birth' && (
                      <>
                        <p style={{ fontSize: 16 }}>
                          {selectedOptions?.[singleDoc?.doc_type]} Document
                          Number
                        </p>

                        <InputText
                          placeholder='Document Number'
                          name='doc_number'
                          value={documentNumber}
                          onChange={(e) => {
                            let value = e?.target?.value?.replace(
                              /[^A-Za-z0-9\s]/g,
                              ''
                            )
                            setDocumentNumber(value)
                          }}
                        />
                      </>
                    )}

                  {(selectedOptions?.[singleDoc?.doc_type] ===
                    'Driver License' ||
                    selectedOptions?.[singleDoc?.doc_type] === 'Passport') &&
                    singleDoc?.doc_type ===
                      'Proof of identity and date of birth' && (
                      <>
                        <p style={{ fontSize: 16 }}>Expiration Date</p>
                        <Calendar
                          value={documentsExpiration}
                          dateFormat='yy/mm/dd'
                          monthNavigator={true}
                          yearNavigator={true}
                          monthNavigatorTemplate={monthNavigatorTemplate}
                          yearNavigatorTemplate={yearNavigatorTemplate}
                          yearRange={`${new Date().getFullYear()}:${
                            new Date().getFullYear() + 50
                          }`}
                          onChange={(e) =>
                            setDocumentsExpiration(e.target.value)
                          }
                          showIcon
                        />
                      </>
                    )}

                  {(selectedOptions?.[singleDoc?.doc_type] ===
                    'Driver License' ||
                    selectedOptions?.[singleDoc?.doc_type] === 'Passport') &&
                    singleDoc?.doc_type === 'Proof of address' && (
                      <>
                        <p style={{ fontSize: 16 }}>Expiration Date</p>
                        <Calendar
                          value={addressDocExpiration}
                          dateFormat='yy/mm/dd'
                          monthNavigator={true}
                          yearNavigator={true}
                          monthNavigatorTemplate={monthNavigatorTemplate}
                          yearNavigatorTemplate={yearNavigatorTemplate}
                          yearRange={`${new Date().getFullYear()}:${
                            new Date().getFullYear() + 50
                          }`}
                          onChange={(e) =>
                            setAddressDocExpiration(e.target.value)
                          }
                          showIcon
                        />
                      </>
                    )}

                  {/* {selectedOptions?.[singleDoc?.doc_type] === 'Passport' && (
                    <>
                      <p style={{ fontSize: 16 }}>Expiration Date</p>

                      <Calendar
                        value={documentsExpiration}
                        dateFormat='yy/mm/dd'
                        monthNavigator={true}
                        yearNavigator={true}
                        monthNavigatorTemplate={monthNavigatorTemplate}
                        yearNavigatorTemplate={yearNavigatorTemplate}
                        yearRange={`${new Date().getFullYear()}:${
                          new Date().getFullYear() + 50
                        }`}
                        onChange={(e) => setDocumentsExpiration(e.target.value)}
                        showIcon
                      />
                    </>
                  )} */}
                </div>
              </Panel>
            )
          })}

          {/* <Button
              disabled={!isAllDataFilled() || loading}
              label={loading ? 'Processing' : 'Submit'}
              icon={`${loading ? 'pi pi-spin pi-spinner' : 'no-icon'}`}
              className='submit-button'
              onClick={() => {
                submitDocuments()
              }}
            /> */}

          {/* <Toast ref={toastErrorMsg} position='top-right' /> */}
        </div>
      </>
    )
  }

  // review all form data before submit
  const ReviewForm = () => {
    return (
      <>
        <Panel header='Review & Complete' toggleable>
          <section className='review-ib-form-section'>
            <div className='review-block-container'>
              <label>About you</label>
              <p>
                Full legal name
                <span>
                  {props?.prefinraData?.payload?.first_name}{' '}
                  {props?.prefinraData?.payload?.last_name}
                </span>
              </p>
              <p>
                Email
                <span>{props?.prefinraData?.payload?.email || '--'}</span>
              </p>
              <p>
                Date of birth
                <span>
                  {props?.prefinraData?.payload?.birth_day}/
                  {props?.prefinraData?.payload?.birth_month}/
                  {props?.prefinraData?.payload?.birth_year}
                </span>
              </p>
              {props?.prefinraData?.payload?.ustaxpayer ? (
                <p>
                  Social Security Number{' '}
                  <span>
                    {IBFormInfo?.ssn
                      ? `xxx-xxx-[${IBFormInfo?.ssn?.substring(
                          IBFormInfo?.ssn?.length - 4
                        )}]`
                      : '--'}
                  </span>
                </p>
              ) : (
                <p>
                  Tax Identification Number{' '}
                  <span>
                    {IBFormInfo?.tax_id
                      ? `xxxxxx${IBFormInfo?.tax_id?.substring(
                          IBFormInfo?.tax_id?.length - 4
                        )}`
                      : '--'}
                  </span>
                </p>
              )}
            </div>

            <div className='review-block-container'>
              <label htmlFor=''>Address</label>
              <p>
                Home address
                <span>
                  {IBFormInfo?.street_1 || '--'}{' '}
                  {IBFormInfo?.street_2 && `, ${IBFormInfo?.street_2}`}
                </span>
                {props?.prefinraData?.payload?.ustaxpayer ? (
                  <>
                    <span>
                      {IBFormInfo?.city || '--'}, {IBFormInfo?.state || '--'}{' '}
                      {IBFormInfo?.postal_code || '--'}
                    </span>
                    <span>{'United States'}</span>
                  </>
                ) : (
                  <>
                    <span>
                      {IBFormInfo?.city || '--'},{' '}
                      {IBFormInfo?.international_region || '--'}{' '}
                      {IBFormInfo?.postal_code || '--'}
                    </span>
                    <span>
                      {allCountries?.find(
                        (item) =>
                          item?.value ===
                          IBFormInfo?.international_country_address
                      )?.label || '--'}
                    </span>
                  </>
                )}
              </p>

              <p>
                Citizenship{' '}
                <span>
                  {allCountries?.find(
                    (item) => item?.value === IBFormInfo?.citizenship
                  )?.label || '--'}
                </span>
              </p>
            </div>

            <div className='review-block-container'>
              <label htmlFor=''>Employment</label>
              <p>
                Employment status{' '}
                <span>{IBFormInfo?.employment_status || '--'}</span>
              </p>

              {(IBFormInfo?.employment_status === 'EMPLOYED' ||
                IBFormInfo?.employment_status === 'SELF_EMPLOYED') && (
                <>
                  <p>
                    Industry <span>{IBFormInfo?.employment_field || '--'}</span>
                  </p>
                  <p>
                    Position{' '}
                    <span>{IBFormInfo?.employment_position || '--'}</span>
                  </p>
                  <p>
                    Employer Name{' '}
                    <span>{IBFormInfo?.company_name || '--'}</span>
                  </p>
                  <p>
                    Employment address{' '}
                    <span>
                      {IBFormInfo?.employer_address_street_1 || '--'}{' '}
                      {IBFormInfo?.employer_address_street_2 &&
                        `, ${IBFormInfo?.employer_address_street_2}`}
                    </span>
                    {props?.prefinraData?.payload?.ustaxpayer ? (
                      <>
                        <span>
                          {IBFormInfo?.employer_address_city || '--'},{' '}
                          {IBFormInfo?.employer_address_state || '--'}{' '}
                          {IBFormInfo?.employer_address_postal_code || '--'}
                        </span>
                        <span>{'United States'}</span>
                      </>
                    ) : (
                      <>
                        <span>
                          {IBFormInfo?.employer_address_city || '--'},{' '}
                          {IBFormInfo?.international_employer_region || '--'}{' '}
                          {IBFormInfo?.employer_address_postal_code || '--'}
                        </span>

                        <span>
                          {allCountries?.find(
                            (item) =>
                              item?.value ===
                              IBFormInfo?.international_employer_country
                          )?.label || '--'}
                        </span>
                      </>
                    )}
                  </p>
                </>
              )}
            </div>

            <div className='review-block-container'>
              <label htmlFor=''>Financial profile</label>

              <p>
                Funding source
                {incomeSources?.length > 0
                  ? incomeSources?.map((incomeSources, index) => {
                      return (
                        <span key={index}>
                          {incomeSources?.source_type?.substring(8)}
                        </span>
                      )
                    })
                  : '--'}
              </p>

              <p>
                Income range{' '}
                <span>
                  {FinancialAnnualList?.find(
                    (item) => item.value === IBFormInfo?.annual_net_income
                  )?.label || '--'}
                </span>
              </p>

              <p>
                Net worth range{' '}
                <span>
                  {FinancialNetWorthList?.find(
                    (item) => item.value === IBFormInfo?.net_worth
                  )?.label || '--'}
                </span>
              </p>

              <p>
                Liquid net worth range{' '}
                <span>
                  {FinancialNetLiquidList?.find(
                    (item) => item.value === IBFormInfo?.liquid_net_worth
                  )?.label || '--'}
                </span>
              </p>

              <p>
                Investing timeline{' '}
                <span>
                  {PlanInvestmentList?.find(
                    (item) => item.value === IBFormInfo?.investment_plan
                  )?.label || '--'}
                </span>
              </p>

              <p>
                Risk tolerance{' '}
                <span>
                  {RiskToleranceList?.find(
                    (item) => item.value === IBFormInfo?.risk_tolerance
                  )?.label || '--'}
                </span>
              </p>
            </div>

            <div className='agreement-container'>
              <label htmlFor=''>Agreements</label>
              <p>
                By clicking 'Accept & Continue' I acknowledge that I am a US
                resident, 18 years or older, have read the Disclosures, agree to
                open a brokerage account and have read & agree to the
                Interactive Brokers LLC Account Agreements, and the Tax Form.
              </p>
              <p>
                I have read, understood, and agree to be bound by Investall
                account terms, and all other terms, disclosures and disclaimers
                applicable to me, as referenced in the Interactive Brokers LLC
                Agreement.
              </p>
              <p>
                I understand I am signing this agreement electronically, and
                that my electronic signature will have the same effect as
                physically signing and returning the Application Agreement.
              </p>

              <a
                href='https://www.forescite.ai/mobile-disclosures-forescite'
                target='_blank'
                rel='noreferrer'
              >
                {' '}
                Disclosures
              </a>

              <a
                href={getCorrectIBAgreement()}
                target='_blank'
                rel='noreferrer'
              >
                {' '}
                Interactive Brokers LLC Account Agreements
              </a>

              <a
                href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form5002.pdf'
                target='_blank'
                rel='noreferrer'
              >
                Tax Form
              </a>
            </div>
          </section>

          {/* <div className='multiple-checkbox'>
            <div className='checkbox-container'>
              <Checkbox
                name='agreeIBPaperwork'
                value={agreeIBPaperwork}
                onChange={() => setAgreeIBPaperwork(!agreeIBPaperwork)}
                checked={agreeIBPaperwork}
              />
              <label htmlFor='ingredient1' className='ml-2'>
                I agree to the
                <a
                  href={getCorrectIBAgreement()}
                  target='_blank'
                  rel='noreferrer'
                >
                  {' '}
                  Interactive Brokers LLC Account Agreements
                </a>
              </label>
            </div>
            <div className='checkbox-container'>
              <Checkbox
                name='agreeForescitePaperwork'
                value={agreeForescitePaperwork}
                onChange={() =>
                  setAgreeForescitePaperwork(!agreeForescitePaperwork)
                }
                checked={agreeForescitePaperwork}
              />
              <label htmlFor='ingredient1' className='ml-2'>
                I agree to the
                <a
                  href='https://www.forescite.ai/mobile-disclosures-forescite'
                  target='_blank'
                  rel='noreferrer'
                >
                  {' '}
                  Investall Advisory Agreement
                </a>
              </label>
            </div>
            <div className='checkbox-container'>
              <Checkbox
                name='agreeTaxFormPaperwork'
                value={agreeTaxFormPaperwork}
                onChange={() =>
                  setAgreeTaxFormPaperwork(!agreeTaxFormPaperwork)
                }
                checked={agreeTaxFormPaperwork}
              />
              <label htmlFor='ingredient1' className='ml-2'>
                <a
                  href='https://investallfaqs.s3.amazonaws.com/ib-disclosures/Form5002.pdf'
                  target='_blank'
                  rel='noreferrer'
                >
                  Tax Form
                </a>
              </label>
            </div>
          </div> */}
          {/* <br /> */}

          {/* <div className='input-container'> */}
          {/* <label>
              I have read, understood and accepted the terms and conditions
              provided during account opening. I will comply to all the terms of
              agreements.
            </label> */}
          {/* <InputText
              placeholder='Signed your full name here'
              name='signedFullName'
              value={IBFormInfo?.signedFullName}
              onChange={handleIBFormInputChange}
            /> */}
          {/* </div> */}
        </Panel>
      </>
    )
  }

  // the component when finished the submiited the form
  const SubmittedSuccessComp = () => {
    return (
      <div className='post-submitted-container'>
        <p>Account Pending Approval</p>

        <span>
          Your account creation request has been submitted and is currently
          pending. Please allow up to 48 hours for approval
        </span>

        <Button
          label='Go to dashboard'
          onClick={async () => {
            await props?.getIBStatus()
            if (props?.closeSubscriptionDialog) {
              props?.closeSubscriptionDialog()
            }
            props?.setIbFormModel(false)
            // This should only go off if account has been rejected already
            if (props?.closeModal) {
              console.log('Finished retrying account creation')
              props?.closeModal()
            }
            window.location.reload()
          }}
        />
      </div>
    )
  }

  const IBFooterContent = () => {
    //get the label name dynamically from the step index
    const getLabletName = () => {
      switch (activeIndex) {
        case 0:
          return 'Next: Employment'
        case 1:
          return 'Next: Financial'
        case 2:
          return 'Next: Investment'
        case 3:
          return 'Next: Acknowledgement'
        case 4:
          return 'Next: Documents'
        case 5:
          return 'Next: Review & Complete'
        case 6:
          if (submitFormButtonClick) {
            return 'Processing'
          } else {
            return 'Accept & Continue'
          }

        default:
          return 'Next: Employment'
      }
    }

    return (
      <div className='ib-dialog-footer-content'>
        <Button
          label={getLabletName()}
          onClick={async () => {
            // mean it is last stepm and the button is clicked to submitted
            if (activeIndex === 6) {
              if (await submitForm()) {
                setSubmitFormButtonClick(false)
                console.log('if valid')
                // await props?.getIBStatus()
                setIsFormSubmitted(true)

                // if (props?.closeSubscriptionDialog) {
                //   props?.closeSubscriptionDialog()
                // }
              }
              setSubmitFormButtonClick(false)
            } else {
              // if not +1 for current index
              setLastStepIndex((prev) => prev + 1)
              setActiveIndex((prev) => prev + 1)
            }
          }}
          // disabled={submitFormButtonClick ? true : false}
          loading={submitFormButtonClick}
        />
      </div>
    )
  }

  return (
    <Dialog
      header={
        <>
          {!isFormSubmitted && (
            <div className='unremoveable-header-container'>
              <p className='unremoveable-header'>Create Investing Account</p>

              {!props?.closeModal && (
                <p
                  onClick={() => {
                    props?.signOut()
                  }}
                  className='unremoveable-signout'
                >
                  Sign out
                </p>
              )}
            </div>
          )}

          {!isFormSubmitted && (
            <div>
              <Steps
                model={IBFormSteps}
                activeIndex={activeIndex}
                onSelect={(e) => {
                  if (lastStepIndex >= e.index) {
                    setActiveIndex(e.index)
                  }
                }}
                readOnly={false}
              />
            </div>
          )}
        </>
      }
      footer={!isFormSubmitted && IBFooterContent}
      visible={props?.ibFormModel}
      // style={{ width: props?.isSmallScreen ? '90vw' : '60vw' }}
      closable={props?.closeModal ? true : false} // no "x" button
      onHide={() => (props?.closeModal ? props?.closeModal() : {})}
      className='IB-form-dialog'
    >
      <>
        {isLoadingData ? (
          <SkeletonLoading />
        ) : (
          // dynamically load the corresponding form component based on current index step or if user clicked submit button
          <div className='ib-questionnaire-container'>
            {activeIndex === 0 && !isFormSubmitted && <>{PersonalForm()}</>}
            {activeIndex === 1 && !isFormSubmitted && <>{EmploymentForm()}</>}
            {activeIndex === 2 && !isFormSubmitted && <>{FinancialForm()}</>}
            {activeIndex === 3 && !isFormSubmitted && <>{InvestmentForm()}</>}
            {activeIndex === 4 && !isFormSubmitted && (
              <>{AcknowledgmentForm()}</>
            )}
            {activeIndex === 5 && !isFormSubmitted && (
              <>{UploadDocumentsForm()}</>
            )}
            {activeIndex === 6 && !isFormSubmitted && <>{ReviewForm()}</>}
            {isFormSubmitted && <>{SubmittedSuccessComp()}</>}
          </div>
        )}
        <Toast ref={toastErrorMsg} position='top-right' />
      </>
    </Dialog>
  )
}

const IBQuestionnaire = (props) => {
  const toastErrorMsg = useRef()

  //personalization states
  const [personalModel, setPersonalModel] = useState(false)
  const [selectedSectors, setSelectedSectors] = useState(null)
  const [sectorLists, setSectorLists] = useState(null)
  const [selectedGoal, setSelectedGoal] = useState(null)
  const [selectedGoalText, setSelectedGoalText] = useState(null)
  const [selectedCauses, setSelectedCauses] = useState([])

  const [isPersonalButtonClicked, setIsPersonalButtonClicked] = useState(false)
  const [personalSetupError, setPersonalSetupError] = useState('')

  //ib form states
  const [ibFormModel, setIbFormModel] = useState(true)

  const [prefinraData, setPrefinraData] = useState(null)
  const [countriesData, setCountriesData] = useState(null)
  // const [activeIndex, setActiveIndex] = useState(1)

  const shortTerm = [
    { label: 'Savings', text: 'Short Term - Savings', value: 'Savings' },
    {
      label: 'Emergencies',
      text: 'Short Term - Emergencies',
      value: 'Emergencies',
    },
  ]
  const mediumTerm = [
    {
      label: 'Save for vacation',
      text: 'Medium Term - Save for vacation',
      value: 'Save for vacation',
    },
    {
      label: 'Education fund',
      text: 'Medium Term - Education fund',
      value: 'Education fund',
    },
  ]
  const longTerm = [
    {
      label: 'Buy a home',
      text: 'Long Term - Buy a home',
      value: 'Buy a home',
    },
    {
      label: 'Save for retirement',
      text: 'Long Term - Save for retirement',
      value: 'Save for retirement',
    },
  ]
  const personalCauses = [
    { label: 'Environment', value: 'Environment' },
    {
      label: 'Social Responsibility',
      value: 'Social Responsibility',
    },
    {
      label: 'Fairness',
      value: 'Fairness',
    },
    {
      label: 'Gender Equality',
      value: 'Gender Equality',
    },
    {
      label: 'Racial Equality',
      value: 'Racial Equality',
    },
  ]

  useEffect(() => {
    // fetchAllCountries()
    getPreFinraInfo()
    fetchSectors()
  }, [])

  const fetchAllCountries = async () => {
    try {
      let res = await axios.get(`${BaseURL}/drivewealth/preset`, {
        headers: {
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
        },
      })

      console.log(res?.data)

      setCountriesData(res?.data?.countries_all)

      return
    } catch (error) {
      console.log('error', error)
      return
    }
  }

  const getPreFinraInfo = async () => {
    try {
      let res = await axios.get(`${BaseURL}/mobile/get-saved-information`, {
        headers: {
          Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
        },
      })

      console.log(res?.data)

      setPrefinraData(res?.data)

      return
    } catch (error) {
      console.log('error', error)
      return
    }
  }

  // fetch secotors options from api call
  const fetchSectors = async () => {
    setPersonalSetupError('')
    let body = {
      filters: {},
      range_filters: [],
    }

    try {
      let res = await axios.post(
        `${BaseURL}/search/ticker-filter-with-forecast`,
        body,
        { withCredentials: false }
      )

      // get sectos dropdown
      const sectorsLists = Array.from({
        length: res?.data?.content?.sector?.length,
      })?.map((_, i) => ({
        label: `${res?.data?.content?.sector?.[i]}`,
        value: res?.data?.content?.sector?.[i],
      }))

      setSectorLists(sectorsLists)
      setPersonalSetupError('')
    } catch (error) {
      console.log(error)
      setPersonalSetupError('Something went wrong. Please try again later')
    }
  }

  // validate form and submit the form after valdiated
  const submitPersonalizationInfo = async () => {
    setIsPersonalButtonClicked(true)

    if (
      !selectedSectors ||
      !selectedGoalText ||
      !selectedCauses ||
      selectedCauses.length === 0
    ) {
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Form submission failed',
        detail: 'Please make sure all fields are filled',
        life: 3000,
      })
      setIsPersonalButtonClicked(false)

      return
    }

    let body = {
      filters: {
        sector: selectedSectors,
        financial_goals: selectedGoalText,
        causes: selectedCauses,
      },
    }

    try {
      let res = await axios.post(
        `${BaseURL}/search/personalization-questionnaire`,
        JSON.stringify(body),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + props?.user?.idToken?.jwtToken,
          },
        }
      )

      if (res?.data?.success) {
        setIsPersonalButtonClicked(false)
        setPersonalModel(false)
        setIbFormModel(true)
      } else {
        // is false
        setIsPersonalButtonClicked(false)
        toastErrorMsg.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Setup personalization failed, please try again',
          life: 3000,
        })
      }
    } catch (error) {
      console.log(error)
      setIsPersonalButtonClicked(false)
      toastErrorMsg.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Setup personalization failed, please try again',
        life: 3000,
      })
    }
  }

  const PersonalFooterContent = (
    <div className='dialog-footer-content'>
      <Button
        label='Skip for now'
        onClick={() => {
          setPersonalModel(false)
          setIbFormModel(true)
        }}
        className='p-button-danger p-button-text'
        disabled={isPersonalButtonClicked}
      />
      <Button
        onClick={() => submitPersonalizationInfo()}
        autoFocus
        label={isPersonalButtonClicked ? 'Processing' : 'CONTINUE'}
        icon={`${
          isPersonalButtonClicked ? 'pi pi-spin pi-spinner' : 'no-icon'
        }`}
        disabled={isPersonalButtonClicked}
      />
    </div>
  )

  // for onchange event of multiple checkbox selected or not selected
  const onCausesChange = (e) => {
    let _selectedCauses = [...selectedCauses]

    if (e.checked) {
      _selectedCauses.push(e.value)
    } else {
      _selectedCauses = _selectedCauses.filter((cause) => cause !== e.value)
    }

    setSelectedCauses(_selectedCauses)
  }

  return (
    <>
      <Dialog
        header={
          <div className='unremoveable-header-container'>
            <p className='unremoveable-header'>Personalization Setup</p>

            <p
              onClick={() => {
                props?.signOut()
              }}
              className='unremoveable-signout'
            >
              Sign out
            </p>
          </div>
        }
        visible={personalModel}
        style={{ width: props?.isSmallScreen ? '90vw' : '60vw' }}
        closable={false} // no "x" button
        footer={PersonalFooterContent}
      >
        {!personalSetupError ? (
          <div className='personalization-content'>
            <div className='question-container'>
              <label className='label-question'>
                what sectors are you interested to invest in?
                <span>(select up to 5)</span>
              </label>

              <MultiSelect
                value={selectedSectors}
                onChange={(e) => {
                  if (e.value.length <= 5) {
                    setSelectedSectors(e.value)
                  }
                }}
                options={sectorLists}
                display='chip'
                placeholder='Select Sectors'
                maxSelectedLabels={5}
                className=''
                disabled={isPersonalButtonClicked}
              />
            </div>

            <div className='question-container financial-goals-container'>
              <label className='label-question'>
                what are your financial goals?
                <span>
                  (Choose a goal that best applies to your current situation)
                </span>
              </label>

              <div className='single-term-container'>
                <label className='term-subtitle'>
                  <img src={shortTermImg} alt='' />
                  <span>Short Term</span>
                </label>

                <div className='term-content'>
                  {shortTerm.map((term) => {
                    return (
                      <div key={term.label} className='option-container'>
                        <RadioButton
                          inputId={term.label}
                          value={term.value}
                          onChange={(e) => {
                            setSelectedGoal(e.value)
                            setSelectedGoalText(term.text)
                          }}
                          name='goal'
                          checked={selectedGoal === term.value}
                          disabled={isPersonalButtonClicked}
                        />
                        <label htmlFor={term.label} className='ml-2'>
                          {term.label}
                        </label>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className='single-term-container'>
                <label className='term-subtitle'>
                  <img src={mediumTermImg} alt='' />
                  <span>Medium Term</span>
                </label>

                <div className='term-content'>
                  {mediumTerm.map((term) => {
                    return (
                      <div key={term.label} className='option-container'>
                        <RadioButton
                          inputId={term.label}
                          value={term.value}
                          onChange={(e) => {
                            setSelectedGoal(e.value)
                            setSelectedGoalText(term.text)
                          }}
                          checked={selectedGoal === term.value}
                          name='goal'
                          disabled={isPersonalButtonClicked}
                        />
                        <label htmlFor={term.label} className='ml-2'>
                          {term.label}
                        </label>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className='single-term-container'>
                <label className='term-subtitle'>
                  <img src={longTermImg} alt='' />
                  <span>Long Term</span>
                </label>

                <div className='term-content'>
                  {longTerm.map((term) => {
                    return (
                      <div key={term.label} className='option-container'>
                        <RadioButton
                          inputId={term.label}
                          value={term.value}
                          onChange={(e) => {
                            setSelectedGoal(e.value)
                            setSelectedGoalText(term.text)
                          }}
                          checked={selectedGoal === term.value}
                          name='goal'
                          disabled={isPersonalButtonClicked}
                        />
                        <label htmlFor={term.label} className='ml-2'>
                          {term.label}
                        </label>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>

            <div className='question-container'>
              <label className='label-question'>
                Select the causes that are important to you
                <span>(select as many as you like)</span>
              </label>

              {personalCauses.map((cause) => {
                return (
                  <div key={cause.label} className='option-container'>
                    <Checkbox
                      inputId={cause.label}
                      name='causes'
                      value={cause.value}
                      onChange={onCausesChange}
                      checked={selectedCauses.some(
                        (item) => item === cause.value
                      )}
                      disabled={isPersonalButtonClicked}
                    />
                    <label htmlFor={cause.label} className='ml-2'>
                      {cause.label}
                    </label>
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          <div>{personalSetupError}</div>
        )}
        <Toast ref={toastErrorMsg} position='top-right' />
      </Dialog>

      <IBQuestionnaireModel
        signOut={props?.signOut}
        ibFormModel={ibFormModel}
        isSmallScreen={props?.isSmallScreen}
        user={props?.user}
        setIbFormModel={setIbFormModel}
        // closeModal={() => {
        //   setIbFormModel(false)
        // }}
        getIBStatus={props?.getIBStatus}
        prefinraData={prefinraData}
        // allCountries={countriesData}
        closeModal={props?.closeModal}
        closeSubscriptionDialog={props?.closeSubscriptionDialog}
        // closeable={props?.closeable}
      />
    </>
  )
}

export default IBQuestionnaire
