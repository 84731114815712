import React from 'react'
import { FaUserCircle, FaCog } from "react-icons/fa"
import ENDPOINT from '../Endpoint'
import Spinner from 'react-bootstrap/Spinner'
import MoneyTransfer from '../../assets/newUI/money-transfer.png'
import { PlaidLink } from 'react-plaid-link';

const brandColor = "#00A99D"
const GREEN = '#3b8e8c'


export default class Transfers extends React.Component{
    state={
        allAccounts:this.props.allAccounts,
        itemsResp:[],
        from:'select account',
        to:this.props.acc.accountId,
        depositAmount:'0.00',
        recurringTime: 'ONE',
        comment:'',
        transferStatus:false,
        spinner:false,
        type:false,
        error:'',
        showSummary:false,
        pendingTotal:0,
        pendingCount:0,
        youSure:false,
        yn:'No',
        notSure:false,

    }

    componentDidMount(){
        if(this.props.user && !this.props.allAccounts){
            this.getAllAccountInfo()
        }
    }


    getOrders = (accountId)=>{
        console.log('----------getting orders---------')
        fetch(`${ENDPOINT}/mobile/order-history?account_id=${accountId}`,{
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken
            },
        })
        .then(response => response.json())
        .then(resp=> {
            console.log('ordersResp', resp)
           this.setState({pendingTotal:resp.total_pending_deposits,pendingCount:resp.count_pending_deposits})
        })
    }

    singleDepositACH = ()=>{

        let body = {
            "dwAccountID": this.state.to.accountId,
            "sourceType": "manual",
            "plaidAccountID": this.state.from.accountId,
            "amount": this.state.depositAmount
        }

        // fetch(`${ENDPOINT}/dw/deposits/onetime`,{
        fetch(`${ENDPOINT}/dw/deposits/onetime?sandbox=true`,{
            method: "POST",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          })
        .catch(err => console.log('single deposit error message: ', err))
        .then(res => res.json())
        .then(responseJson => {
            console.log('ach single resp: ', responseJson)
            console.log('ach single resp.success: ', responseJson.success)
            if(responseJson.success){
                this.setState({step:4})
            }
            else{
              this.setState({transferStatus:responseJson.payload})
            }
          }
      )
    }


    singleDepositPlaid = ()=>{
        console.log('running single!')
        this.setState({transferStatus:'Submitting Transfer...',})
        let body = {
            "dwAccountID": this.state.to,
            "sourceType": "plaid",
            "plaidAccountID": this.state.from,
            "amount": this.state.depositAmount,
        }
        // console.log('depo body',body )
        fetch(`${ENDPOINT}/dw/deposits/onetime`,{
            method: "POST",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          })
          .then(res => res.json())
          .catch(err => console.log('single deposit error message: ', err))
          .then(responseJson => {
              if(responseJson.success){
                  this.setState({showSummary:true})
              }
              else{
                this.setState({transferStatus:responseJson.payload})
              }
            }
        )
    }

    getAllAccountInfo = ()=>{
        fetch(`${ENDPOINT}/mobile/user/holdings`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
        //   .then(responseJson => this.setState({holdings: responseJson}))
          .then(responseJson =>{
              let allAccounts = []
            //   console.log('neten all resp: ',responseJson)
            responseJson.items.forEach(inst=>inst.accounts.forEach(acc=>{
                //   console.log(acc.name,acc.type )
                  if(acc.type === 'depository' || acc.type === 'investment' || acc.type === 'credit'){
                      allAccounts.push({...acc, inst:inst.instName})
                  }
              }))
            //   console.log('neten all parsed: ',allAccounts)
              this.setState({allAccounts, itemsResp:responseJson.items})
            })
          .catch(err=>console.log(`catching errors`, err))
    }

    addCommas = (x)=>{
        x = x.toString()
        if(x === ''){
            return ''
        }else{
            let y = x.split('.')
            let z = y[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
            // if(typeof y[1] === 'undefined'){
            //     return z + '.00'
            // }
            // return z + '.' + y[1]
            return z
        }
    }

    removeCommas = (x)=>{
        if(x === ''){
            return ''
        }else{
            return parseFloat(x.replace(/,/g, ''))
        }
    }

    filterAccounts = (tofrom)=>{
        if(this.state.type === 'transfer'){
            return this.state.allAccounts.filter(acc=>acc.inst === 'DriveWealth' && !acc.product && acc.status !== 'PENDING')
        }
        else if (this.state.type === 'deposit' && tofrom === 'to'){
            return this.state.allAccounts.filter(acc=>acc.inst === 'DriveWealth' && acc.type === 'investment' && acc.status !== 'PENDING')
        }
        else if (this.state.type === 'deposit' && tofrom === 'from'){
            return this.state.allAccounts.filter(acc=>acc.inst !== 'DriveWealth' && acc.type !== 'investment' && acc.status !== 'PENDING')
        }
        else if (this.state.type === 'withdraw' && tofrom === 'to'){
            return this.state.allAccounts.filter(acc=>acc.inst !== 'DriveWealth' && acc.type !== 'investment' && acc.status !== 'PENDING')
        }
        else if (this.state.type === 'withdraw' && tofrom === 'from'){
            return this.state.allAccounts.filter(acc=>acc.inst === 'DriveWealth' && acc.type === 'investment' && acc.status !== 'PENDING')
        }
        else{
            return this.state.allAccounts
        }
    }

    createDropdown = (fromTo)=>{
        let list = []
        let mapped = [<option value={'select account'} selected={fromTo === 'from'?'':'to'}>{'Select an Account Below'}</option>]
        // if (this.state.allAccounts){
        //     list = this.filterAccounts(fromTo)
        // }
        list = this.state.allAccounts
        list.forEach(acc=>{
            let title = ''

            // WITH ACC BALANCE 
            // if(acc.inst === 'DriveWealth'){
            //     title = acc.inst + ' ' + acc.mask_full + ' $'  /*acc.balances[0].current ?this.addCommas(acc.balances[0].current.toFixed(2)):'0.00'*/
            // }
            // else{
            //     title = acc.inst + ' ' + acc.mask + ' $'  /*acc.balances[0].current ?this.addCommas(acc.balances[0].current.toFixed(2)):'0.00'*/
            //     // title = acc.inst + ' ' + acc.mask + ' $' + /*acc.balances[0].current ?this.addCommas(acc.balances[0].current.toFixed(2)):'0.00'*/
            // }
            // title +=  acc.balances[0].current ?this.addCommas(acc.balances[0].current.toFixed(2)):'0.00'

            //WITHOUT ACC BALANCE
            if(acc.inst === 'DriveWealth'){
                title = acc.inst + ' ' + acc.mask_full   
            }
            else{
                title = acc.inst + ' ' + acc.mask 
            }
            if((acc.inst === 'DriveWealth' && acc.status === 'OPEN')|| acc.type === 'depository'){
                mapped.push(
                    <option value={acc.accountId} selected={fromTo === 'from'?'':'to'}>{title}</option>
                ) 
            }
        })
        return(
            <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:this.props.isSmallScreen? '95%':'60%'}}>

                <select placeholder={fromTo === 'from'?'':'to'}  value={this.state[fromTo]} style={{border:'none',textAlign:'left', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent', width:'100%'}}
                    onChange={(e)=>{
                        let from = 'plaid'
                        let to = 'plaid'
                        let type = false
                        if(fromTo === 'to'){
                            this.getOrders(e.target.value)
                            if(this.state.from !== 'select account' && e.target.value !== 'select account'){
                                if (this.state.from.includes('.')){
                                    from = 'dw'
                                }
                                if (e.target.value.includes('.')){
                                    to = 'dw'
                                }
                            }
                        }
                        else {
                            if(this.state.to !== 'select account' && e.target.value !== 'select account'){
                                if (this.state.to.includes('.')){
                                    to = 'dw'
                                }
                                if (e.target.value.includes('.')){
                                    from = 'dw'
                                }
                            }
                        }
                        if (from === 'dw' && to === 'dw'){
                            type = 'transfer'
                        }
                        else if (from === 'dw' && to === 'plaid'){
                            type = 'withdraw'
                        }
                        else if(from === 'plaid' && to === 'dw'){
                            type = 'deposit'
                        }
                        this.setState({[fromTo]:e.target.value, type, transferStatus:false})
                    }}
                >
                    {mapped}
                </select>
            </div>
        )
    }

    dwWithdrawal =()=>{
        console.log('dw redeem')
        fetch(`${ENDPOINT}/mobile/withdraw-plaid`,{
            method:'POST',
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                'Content-Type': 'application/json'
            },
            body:JSON.stringify({
                "dwAccountID": this.state.from,
                "plaidAccountID": this.state.to,
                "amount": this.state.depositAmount,
                "note": this.state.comment?this.state.comment:"Withdrawal from DriveWealth"
            })
        })
        .then(res => res.json())
        .then(responseJson => {
            console.log('withdraw resp',responseJson)
            
            if(responseJson.Withdraw.request_status === 200){
                this.setState({showSummary:true})
            }
            else{
                this.setState({transferStatus:responseJson.Withdraw.message,spinner:false })
                
            }
            
        })
        .catch(err=>this.setState({transferStatus:err}))
    }

    dwToDwTransfer =()=>{
        fetch(`${ENDPOINT}/mobile/internal/transfer`,{
            method:'POST',
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                'Content-Type': 'application/json'
            },
            body:JSON.stringify({
                    "accountFrom": this.state.from,
                    "accountTo": this.state.to,
                    "amount": this.state.depositAmount,
                    "batch": true,
                    "comment": this.state.comment?this.state.comment:'Drivewealth to Drivewealth transfer'
            })


        })
        .then(res => res.json())
        //   .then(responseJson => this.setState({holdings: responseJson}))
        .then(responseJson => {
            console.log('dw<->dw', responseJson)
            if(responseJson.status === 'Failed'){
                // ERRORS FROM NETEN
                this.setState({transferStatus:responseJson.Error, spinner:false})
            }
            else{
                if(
                    responseJson.payload&& 
                    responseJson.payload.request_status === 200 && 
                    responseJson.payload.cash && 
                    responseJson.payload.cash.status === "SUCCESS"
                    ){
                    //SUCCESS FROM DW
                    this.setState({transferStatus:'Success', showSummary:true,spinner:false})
                }
                else{
                    // ERRORS FROM DW
                    this.setState({transferStatus:responseJson.payload.cash.statusMsg, spinner:false})
                }
            }
        })
        .catch(err=>this.setState({transferStatus:err, spinner:false}))
    }

    depositIntoDw =()=>{
        console.log('deposit to dw')
       
    }

    plaidToPlaidTransfer =()=>{
        console.log('plaid to plaid')
        this.setState({transferStatus:'We do not currently support transfers between these institutions'})
    }

    plaidHandleOnSuccess = (public_token, metadata)=> {
        // send token to client server
        // fetch("/auth/public_token", {
        //   public_token: public_token
        // });
        let accountId = metadata.account_id
        let itemId = metadata.institution.institution_id
        this.linkPlaidAccount(public_token, metadata)
        // let resp = this.findOtherPortfoliosSameInstitution(itemId)
        // if (resp.length > 0){
        //     this.addOtherPortSameInstituion(accountId, itemId) 
        // }
        // this.setState({respMeta:metadata, respToken:public_token})
    }

    linkPlaidAccount = (token, meta)=>{
        // console.log('plaid token' ,token)
        // console.log('plaid meta' ,meta)
        let id = meta.account_id
        let mask = meta.account.mask
        let name = meta.account.name
        let subType = meta.account.subtype

        let inst_id = meta.institution.institution_id
        let inst_name = meta.institution.name
        let public_token = token

        let body = { 
            accounts:[ {  id, mask, name, subType, type:this.state.filter === 'bank' ? "depository":"investment",} ],
            inst_id,
            inst_name,
            public_token,
            type:this.state.filter === 'bank' ? "depository":"investment",
        }
        // console.log('body: ', body)

        // fetch(`${ENDPOINT}/plaid/link`,{
        fetch(`${ENDPOINT}/mobile-plaid/plaid/link`,{
            method:'POST',
            headers:{
                Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(body)
        })
        .then(res => res.json())
        .then(respJSON=>console.log('respJSON: ', respJSON))
        .catch(err=>console.log('catching: ', err))
    }

    roboOrGoalsRedemption = ()=>{
        let percent =  parseFloat(this.state.depositAmount)/parseFloat(this.state.from.balances[0].current)*1.0025
        fetch(`${ENDPOINT}/rebalance-mobile/redeem-robo?dw_account_id=${this.state.from.accountId}&percent=${percent}`,{
            method: "GET",
            headers:{
              Authorization: 'Bearer ' + this.props.user.idToken.jwtToken,
              'Content-Type': 'application/json'
            },
          })
          .then(res => res.json())
          .then(responseJson => console.log(responseJson))
    }

    groupRedemption = ()=>{
        console.log('do a group redemption this')
    }

    hitTransfer = ()=>{
        if(!this.state.type){
            this.setState({transferStatus:'Pick what type of transfer above'})
        }
        else if(this.state.from ==='select account'){
            this.setState({transferStatus:'Pick where you would like to transfer money from'})
        }
        else if(this.state.to ==='select account'){
            this.setState({transferStatus:'Pick where you would like to transfer money to'})
        }
        else if(this.state.depositAmount ==='0.00'){
            this.setState({transferStatus:'Please set a transfer amount'})
        }
        else if(this.state.spinner){
            console.log('already spinning')
        }else{
            console.log('Hit Transfer')
            this.setState({transferStatus:'Submitting Transfer...',spinner:true})
            let from = 'plaid'
            if (this.state.from.includes('.')){
                from = 'dw'
            }
            let to = 'plaid'
            if (this.state.to.includes('.')){
                to = 'dw'
            }
            // console.log('from',from)
            // console.log('to',to)
            if (from === 'dw' && to === 'dw'){
                let fromProd = this.findAccbyId(this.state.from).product
                let toProd = this.findAccbyId(this.state.to).product
                if (fromProd){
                   let transferStatus = 'You cannot transfer FROM this account because it is a '
                   if(fromProd ==='group'){
                       transferStatus += 'trade group'
                   }
                   else if(fromProd ==='robo'){
                       transferStatus += 'robo account'
                   }
                   else if(fromProd ==='goal'){
                       transferStatus += 'goal account'
                   }
                   this.setState({transferStatus,spinner:false})
               }
                else if(toProd){
                    let transferStatus = 'You cannot transfer TO this account because it is '
                    if(toProd ==='group'){
                        transferStatus += 'a trade group'
                    }
                    else if(toProd ==='robo'){
                        transferStatus += 'linked to a robo strategy'
                    }
                    else if(toProd ==='goal'){
                        transferStatus += 'a goal account'
                    }
                    this.setState({transferStatus,spinner:false})
                }
                else{
                    this.dwToDwTransfer()
                }
            }
            else if (from === 'dw' && to === 'plaid'){
                let fromProd = this.findAccbyId(this.state.from).product
                if (fromProd === 'goal'){
                    let transferStatus = 'You cannot withdraw FROM this account because it is a linked to a goal. If you would like to withdraw please redeem funds first'
                    this.setState({transferStatus,spinner:false})
                 }
                // else if (fromProd === 'group'){
                //    let transferStatus = 'You cannot withdraw FROM this account because it is a trade group. If you would like to withdraw please unlink your trade group first'
                //    this.setState({transferStatus,spinner:false})
                // }
                // else if (fromProd === 'robo'){
                //     let transferStatus = 'You cannot withdraw FROM this account because it is a linked to a robo strategy. If you would like to withdraw please redeem funds first'
                //     this.setState({transferStatus,spinner:false})
                //  }
                else{
                    this.dwWithdrawal()
                }
                // if(this.state.depositAmount <= this.state.from.balances[0].current){
                //     this.dwWithdrawal()
                // }
                // else if (this.state.from.product === 'goal' ||this.state.from.product === 'robo'){
                //     this.roboOrGoalsRedemption()
                // }
                // else if (this.state.from.product === 'group'){
                //     this.groupRedemption()
                // }
                // else{
                //     this.setState({error:'You do not have enough settled cash to withdraw that much money'})
                // }
            }
            else if(from === 'plaid' && to === 'dw'){
                if((this.state.pendingCount > 0 && this.state.yn === 'Yes') || this.state.pendingCount === 0){
                    this.singleDepositPlaid()
                }
                else{
                    this.setState({notSure:true})
                }
            }
            else if(from === 'plaid' && to === 'plaid'){
                this.plaidToPlaidTransfer()
            }
        }
    }

    sureCheck=()=>{
        return(
            <div style={{display: 'flex', justifyContent:'center', alignItems:'center', flexDirection:'column',marginTop:10, marginBottom:10}}>
                <p>
                    You already have {this.state.pendingCount} deposit{this.state.pendingCount === 1 ? '':'s'} pending on this account totaling ${this.state.pendingTotal}. Are you sure you want to do an another deposit? 
                </p>
                <div style={{display:'flex', justifyContent:'flex-start',alignItems:'center',width:'100%'}}>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start', }}>
                        <input type="radio" checked={this.state.yn==='Yes'} onChange={()=>{this.setState({yn:'Yes',notSure:false})}}/> 
                        <p style={{margin:0, marginLeft:10, color:this.state.notSure? 'rgb(250,0,0)':''}}>Yes, I am sure.</p>
                    </div>
                </div>
            </div>
        )
    }

    successErrorLoadingBackground = ()=>{
        if(this.state.transferStatus === 'Transfer submitted'){
            return 'rgb(234,255,219)'
        } else if(this.state.transferStatus === 'Submitting Transfer...'){
            return 'rgb(225,225,235)'
        }else{
            return 'rgb(253,180,181)'
        }
     }

     summary = ()=>{
         let text = ''
         if(this.state.type === 'withdraw'){
             text = `Your withdrawl of $${this.state.depositAmount} may take up to 3 business days.`
         }
         else if(this.state.type === 'transfer'){
            text = `Your transfer of $${this.state.depositAmount} may take up to 3 minutes.`
         }
         else if(this.state.type === 'deposit'){
            text = `Your deposit of $${this.state.depositAmount} may take up to 2 business days.`
         }
         return(
             <div style={{width:'100%'}}>
                <div style={{display:'flex', flexDirection:'column', backgroundColor:'rgb(255,255,255)', padding:20, borderRadius:12, marginBottom:10   }}>
                    <h3 style={{color:'rgb(17,17,17)', textAlign:'center'}}>Transfer Initiated</h3>
                    <hr></hr>
                    <div style={{width:'95%', margin:'auto'}}>
                        <div>
                        </div>
                        <div style={{display:'flex',justifyContent:'center', alignItems:'center'}}>
                            <img
                                src={MoneyTransfer}
                                style={{width:300,}}

                            />
                        </div>
                        <div>
                            <p style={{textAlign:'center', fontSize:20}}>{text}</p>
                        </div>
                    </div>
                 </div>
             </div>
         )
     }
     findAccbyId = (id)=>{
         let acc
         for (let i = 0; i < this.state.allAccounts.length; i++) { 
            //  console.log('loop at:', i)
            //  console.log(this.state.allAccounts[i].accountId, this.state.from)
            if(this.state.allAccounts[i].accountId === id){
                acc = this.state.allAccounts[i]
                i+=this.state.allAccounts.length
            }
         }
        //  console.log('found acc: ',acc)
         return acc
     }

     findMax = ()=>{
         let acc = this.findAccbyId(this.state.from)
         if(this.state.type === 'withdraw'){
             return acc.balances[0].cashAvailableForWithdrawal
         }
         else{
             return acc.balances[0].buyingPower
         }
     }

    render(){
        // let user = this.state.userInfo
        
            return(
                <div style={{
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    position: 'fixed',
                    height: '100%',
                    width: '100%',
                    top: 0,
                    left: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'auto',
                    zIndex: 99999,
                    }}>
                    <div style={{display:'flex', flexDirection: 'column', margin:'auto', backgroundColor:'rgb(255,255,255)', borderRadius: 10, width:this.props.isSmallScreen?'90%' :'40%', height: '90%', paddingBottom:25}}>
                        <div style={{width: '100%', display: 'flex', justifyContent:'space-between', backgroundColor:'rgb(37,42,55)', paddingLeft:20, paddingRight:20, alignItems:'center', paddingTop:10, paddingBottom:10, borderRadius:'10px 10px 0px 0px'}}>
                            <p style={{color:'rgb(255,255,255)', fontWeight:'bold', fontSize:20, margin:0,}}> Re-Open Your Account</p>
                            <p style={{margin: 0, fontWeight: 'bold', color: 'rgb(255,255,255)', cursor:'pointer'}} onClick={()=>this.props.closeModal()}> X </p>
                        </div>
                        <div style={{width:'100%'}}>
                            
                           
                        
                <div>
                    <div style={{display:'flex', flexDirection:'column', backgroundColor:'rgb(255,255,255)', padding:20, borderRadius:12, marginBottom:10   }}>
                        <div style={{width:'95%', margin:'auto',marginBottom:10, display:'flex', justifyContent:'space-evenly', alignItems:'center'}}>
                            {/* <h3 style={{color:'rgb(17,17,17)', textAlign:'center'}}>Account Transfer</h3> */}
                            {/* <div  style={{borderRight:'solid',cursor:'pointer', borderColor:'rgb(255,255,255)', borderWidth:'0.5px',width:'30%', display:'flex',justifyContent:'center', alignItems:'center',backgroundColor:this.state.type === 'deposit'? 'rgb(52,131,129)':'rgb(200,200,200)', padding: 5, borderRadius:8 }}
                            onClick={()=>this.setState({type:'deposit'})}>
                                <p style={{margin:0, color: this.state.type === 'deposit'? 'rgb(255,255,255)':'', fontSize:20}}>Deposit</p>
                            </div>
                            <div  style={{borderRight:'solid',cursor:'pointer', borderColor:'rgb(255,255,255)', borderWidth:'0.5px',width:'30%', display:'flex',justifyContent:'center', alignItems:'center',backgroundColor:this.state.type === 'transfer'? 'rgb(52,131,129)':'rgb(200,200,200)', padding: 5, borderRadius:8 }}
                            onClick={()=>this.setState({type:'transfer'})}>
                                <p style={{margin:0, color: this.state.type === 'transfer'? 'rgb(255,255,255)':'', fontSize:20}}>Transfer</p>
                            </div>
                            <div  style={{borderRight:'solid',cursor:'pointer', borderColor:'rgb(255,255,255)', borderWidth:'0.5px',width:'30%', display:'flex',justifyContent:'center', alignItems:'center',backgroundColor:this.state.type === 'withdraw'? 'rgb(52,131,129)':'rgb(200,200,200)', padding: 5, borderRadius:8 }}
                            onClick={()=>this.setState({type:'withdraw'})}>
                                <p style={{margin:0, color: this.state.type === 'withdraw'? 'rgb(255,255,255)':'', fontSize:20}}>Withdraw</p>
                            </div> */}
                        </div>
                        <div>
                            {this.state.type === 'transfer'?
                                <div>
                                    <p style={{margin:0, fontSize:16}}>This feature is only avaiable between DriveWealth accounts from Monday - Friday between the hours of 4:30am EST to 1:30pm EST </p>
                                    <p style={{margin:0, fontSize:16}}>You must deposit enough money to get your account value above or equal to $0.</p>
                                </div>
                            :null}
                             {this.state.type === 'deposit'?
                                <div>
                                    <p style={{margin:0, fontSize:16}}>You cannot deposit into pending accounts.</p>
                                    <p style={{margin:0, fontSize:16}}>You can only deposit 5 times per day across all of your DriveWealth Accounts.</p>
                                    <p style={{margin:0, fontSize:16}}>You will only have access to at most $5,000 a day in instant buying power per account.</p>
                                    <p style={{margin:0, fontSize:16}}>You must deposit enough money to get your account value above or equal to $0.</p>
                                </div>
                            :null}
                           
                           
                        </div>
                        <hr></hr>
                        <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                            <div style={{/*paddingTop:25,*/  width:'95%',margin:'auto', paddingLeft:20,paddingRight:20,paddingBottom:20 }}>
                                <p style={{margin:0,fontSize:20}}>From:</p>
                                <div style={{marginBottom:10}}>
                                    {this.state.allAccounts.length > 0 ? this.createDropdown('from'): <p>You have no accounts linked</p>}
                                </div>
                                <p style={{margin:0,fontSize:20}}>To:</p>
                                <div style={{marginBottom:10}}>
                                    <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:this.props.isSmallScreen? '95%':'60%'}}>
                                        <p  value={this.state.to} style={{border:'none',textAlign:'left', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent', width:'100%'}}>{this.props.acc.inst + ' ' + this.props.acc.mask_full}   </p>
                                   </div>
                                   </div>
                                <div>
                                    <p style={{margin:0,fontSize:20}}>
                                        Amount: <strong style={{fontSize:'14px', fontWeight:'normal'}}>{(this.state.type === 'withdraw' || this.state.type === 'transfer')&&this.state.from !== 'select account' ?`Maximum $${this.findMax()}`:null}</strong>
                                    </p>
                                    <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:this.props.isSmallScreen? '95%':'60%',marginBottom:10}}>
                                        <div style={{width:'100%', display:'flex',justifyContent:'space-between'}}>
                                            <input 
                                                style={{border:'none',textAlign:'left', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent', }}
                                                type="text" 
                                                value={this.addCommas(this.state.depositAmount)} 
                                                onChange={(e)=>{
                                                    let depositAmount = this.removeCommas(e.target.value)
                                                    this.setState({depositAmount,transferStatus:false})
                                                }}
                                            />
                                            <p style={{margin:0,color:'rgb(52,131,129)',textAlign:'right'}}>(USD)</p>
                                        </div>
                                    </div>
                                </div>

                                {this.state.type !== 'deposit'? null:
                                    <p style={{fontSize:20, margin:0}}>
                                        How often would you like these payments to occur?
                                    </p>
                                }
                                {this.state.type !== 'deposit'? null :
                                <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:this.props.isSmallScreen? '95%':'60%',marginBottom:10}}>
                                    <select placeholder="Select Below" onChange={(e)=>this.setState({recurringTime:e.target.value})} value={this.state.recurringTime} style={{ width:'100%',border:'none',textAlign:'left', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent',}}>
                                        <option value='ONE' selected={this.state.recurringTime == 'ONE'}>One Time Deposit</option>
                                        <option value='MONTHLY' selected={this.state.recurringTime == 'MONTHLY'}>Monthly</option>
                                        <option value='YEARLY' selected={this.state.recurringTime == 'YEARLY'}>Yearly</option>                            
                                    </select>
                                </div>
                                }
                                <p style={{fontSize:20, margin:0}}>
                                    Memo (optional)
                                </p>
                                <div style={{display:'flex',justifyContent:'flex-start',alignItems:'center', padding:10, background:'rgb(225,238,238)', borderRadius:5, width:this.props.isSmallScreen? '95%':'60%',marginBottom:10}}>
                                    <input 
                                            style={{width:'100%', border:'none',textAlign:'left', margin:0,color:'rgb(52,131,129)', outline:'none', background:'transparent', }}
                                            type="textarea" 
                                            value={this.state.comment} 
                                            onChange={(e)=>{
                                                this.setState({comment:e.target.value})
                                            }}
                                        />
                                </div>
                                {this.state.pendingCount > 0 && this.state.type === 'deposit' ? this.sureCheck() :null}
                                <div style={{width:"100%", }}>
                                    { this.state.transferStatus?
                                        <div style={{display:'flex', width:'80%', backgroundColor:this.successErrorLoadingBackground(), padding:12, marginBottom:10, borderRadius:8, }}>
                                            <p style={{margin:'auto'}}>
                                                {this.state.transferStatus}
                                            </p> 
                                        </div>
                                    :null}
                                    <div style={{padding:10, border:'solid',borderWidth:'0.25px', borderRadius:10, width:this.props.isSmallScreen?'95%':'50%',cursor:'pointer',display:'flex',justifyContent:'center',alignItems:'center'}} onClick={()=>this.hitTransfer()}>
                                        {this.state.spinner? 
                                         <Spinner animation="border" id="spinner" variant="dark" role="status" >
                                             <span className="sr-only">Loading...</span>
                                        </Spinner>:
                                        <p style={{margin:0, textAlign:'center'}}>
                                            Transfer Funds
                                        </p>}
                                    </div>
                                </div>
                                <div style={{marginTop:20}}>
                                    <p style={{margin:0}}>Need to link another account?</p>
                                    <div onClick={()=>this.setState({filter:'bank'})}>
                                    <PlaidLink
                                        clientName="ForeSCITE"
                                        // env="sandbox"
                                        env="production"
                                        product={["transactions"]}
                                        publicKey="3e65a03171afbab180fa7198f87cef"
                                        onExit={this.handleOnExit}
                                        onSuccess={this.plaidHandleOnSuccess}
                                        linkCustomizationName='bank'
                                        // className="test"
                                        style={{marginRight:'5%', cursor:'pointer', backgroundColor:'transparent',border:'none'}}
                                        // style={{border:'none', margin: 0,padding:0, color: brandColor, fontWeight:'bold'}}
                                        // style={{display:'flex', alignItems:'center', justifyContent: 'center', border:'solid', borderColor:'#3b8e8c', borderRadius: 8, paddingTop: 10,paddingBottom:10, paddingLeft:20, paddingRight:20, width: '100%', cursor:'pointer',}}
                                    >
                                        <a href='#' style={{margin:0,color:GREEN}}>Click here to link a bank account</a>
                                    </PlaidLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                </div>
                    </div>
                </div>
            )
        
    }    
}